export enum OptaCommercialPlumbingTypeType {
  ABS = 'ABS',
  COPPER = 'Copper',
  GALVANIZED = 'Galvanized',
  LEAD = 'Lead',
  OTHER = 'Other',
  PEX = 'PEX',
  PLASTIC = 'Plastic',
  POLY_B = 'POLY-B',
  PVC = 'PVC',
  UNKNOWN = 'Unknown',
}
