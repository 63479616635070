import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationAggregate } from '@fundmoreai/models';
import { environment } from 'src/environments/environment';
import { ApplicationAggregateComputeInput } from './application-aggregates.action';

@Injectable({
  providedIn: 'root',
})
export class ApplicationAggregatesService {
  constructor(private http: HttpClient) {}

  getApplicationAggregates(applicationId: string) {
    return this.http.get<{ aggregates: ApplicationAggregate }>(
      `${environment.fundmore_v2_api_url}/application/${applicationId}/aggregates`,
    );
  }

  refreshBusinessBankingRelationship(applicationId: string) {
    return this.http.post<{ computedResult: ApplicationAggregate }>(
      // eslint-disable-next-line max-len
      `${environment.fundmore_v2_api_url}/application/${applicationId}/aggregates/business-banking-relationship/refresh`,
      {},
    );
  }

  computeApplicationAggregate(applicationId: string, input?: ApplicationAggregateComputeInput) {
    return this.http.put<{ computedResult: ApplicationAggregate }>(
      `${environment.fundmore_v2_api_url}/application/${applicationId}/aggregates/compute`,
      input,
    );
  }
}
