import { Injectable } from '@angular/core';
import { CmhcApplicationData, CmhcFieldViewModel, InsuranceQuote } from '@fundmoreai/models';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import {
  BorrowerBankingInfo,
  CmhcApplicationOptions,
  CmhcSubmitApplicationPayload,
} from '../cmhc.model';
import { MortgageInsuranceService } from '../../mortgage-insurance.service';
import { tap, finalize, EMPTY } from 'rxjs';
import { LoadingStart, LoadingEnd } from 'src/app/core/loading.state';
import { MortgagesV2State } from 'src/app/portal/mortgages-v2/mortgages-v2.state';
import { SummaryState } from 'src/app/portal/summary.state';

interface CmhcInsuranceRequestStateModel {
  insuranceQuoteRes: InsuranceQuote | undefined;
  borrowersBankingInfos: BorrowerBankingInfo[];
  responseReviewMappings: CmhcApplicationData | undefined;
}

export class CmhcRequestSetInsuranceQuoteRes {
  static readonly type = '@cmhcInsuranceRequest.setInsuranceQuoteRes';
  constructor(public insuranceQuote: InsuranceQuote) {}
}

export class CmhcRequestSetBorrowersBankingInfos {
  static readonly type = '@cmhcInsuranceRequest.setBorrowersBankingInfos';
  constructor(public bankingInfos: BorrowerBankingInfo[]) {}
}

export class CmhcRequestResetState {
  static readonly type = '@cmhcInsuranceRequest.resetState';
}

export class MortgageInsuranceCmhcApplicationMappingsReview {
  static readonly type = '@mortgageInsurance.CmhcApplicationMappingsReview';
  constructor(
    public applicationId: string,
    public applicationOptions: CmhcApplicationOptions,
    public borrowersBankingInfos: BorrowerBankingInfo[],
  ) {}
}

@State<CmhcInsuranceRequestStateModel>({
  name: 'cmhcInsuranceRequestState',
  defaults: {
    insuranceQuoteRes: undefined,
    borrowersBankingInfos: [],
    responseReviewMappings: undefined,
  },
})
@Injectable()
export class CmhcInsuranceRequestState {
  constructor(private store: Store, private mortgageInsuranceService: MortgageInsuranceService) {}

  @Selector()
  static insuranceQuoteRes(state: CmhcInsuranceRequestStateModel) {
    return state.insuranceQuoteRes;
  }

  @Selector()
  static borrowersBankingInfo(state: CmhcInsuranceRequestStateModel) {
    return state.borrowersBankingInfos;
  }

  @Selector()
  static responseReviewFieldMappings(
    state: CmhcInsuranceRequestStateModel,
  ): CmhcFieldViewModel[] | undefined {
    return state.responseReviewMappings?.viewModels;
  }

  @Action(CmhcRequestSetInsuranceQuoteRes)
  private setInsuranceQuoteRes(
    ctx: StateContext<CmhcInsuranceRequestStateModel>,
    action: CmhcRequestSetInsuranceQuoteRes,
  ) {
    ctx.patchState({
      insuranceQuoteRes: action.insuranceQuote,
    });
  }

  @Action(CmhcRequestSetBorrowersBankingInfos)
  private setBorrowersBankingInfos(
    ctx: StateContext<CmhcInsuranceRequestStateModel>,
    action: CmhcRequestSetBorrowersBankingInfos,
  ) {
    ctx.patchState({
      borrowersBankingInfos: action.bankingInfos,
    });
  }

  @Action(CmhcRequestResetState)
  private resetState(ctx: StateContext<CmhcInsuranceRequestStateModel>) {
    ctx.setState({
      borrowersBankingInfos: [],
      insuranceQuoteRes: undefined,
      responseReviewMappings: undefined,
    });
  }

  @Action(MortgageInsuranceCmhcApplicationMappingsReview)
  reviewApplicationMappings(
    ctx: StateContext<CmhcInsuranceRequestStateModel>,
    action: MortgageInsuranceCmhcApplicationMappingsReview,
  ) {
    const requestedMortgage = this.store.selectSnapshot(
      MortgagesV2State.firstFoundRequestedMortgage,
    );

    if (!requestedMortgage) {
      return EMPTY;
    }

    const payload: CmhcSubmitApplicationPayload = {
      applicationOptions: action.applicationOptions,
      bankingInfos: action.borrowersBankingInfos,
    };
    this.store.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageInsuranceService
      .reviewCmhcApplicationMappings(action.applicationId, payload)
      .pipe(
        tap((response) =>
          ctx.patchState({
            responseReviewMappings: response,
          }),
        ),
        finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }
}
