import { ApplicationStage } from './application';

export interface Task {
  id: string;
  tenantId: string;
  description: string;
  longDescription?: string | null;
  priority: TaskPriority;
  dueDate: string;
  stage: ApplicationStage | null;
  role: string;
  createdAt: string;
  applicationId: string;
  isCompleted: boolean;
  assignedTo: string;
  new?: boolean;
  completedAt: string;
  completedByUserId: string;
  createdBy: string;
}

export enum TaskPriority {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export enum TaskKey {
  STAGE = 'stage',
  ASSIGNED_TO = 'assignedTo',
}
