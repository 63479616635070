import { FCTNoteRecipient } from '../../enums';
import { FCTError } from '../error';
import { FCTNote } from '../note';

export interface FCTSyncNotesResponse {
  ResponseStatus: number;
  ValidationErrors?: FCTError[];
  OrderNotes: FCTNote[];
}

export interface FCTAddNoteCommand {
  fctReferenceId: string;
  noteMessage: string;
  recipients: FCTNoteRecipient[];
  tenantCode: string;
}

export interface FCTCreateNotesResponse {
  ResponseStatus: number;
  ValidationErrors?: FCTError[];
}
