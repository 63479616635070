import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap, finalize } from 'rxjs/operators';
import { UserAccountsService } from './user-accounts.service';
import { UserAccount, ResetState } from '../shared';
import { RoleState } from '../features/shared/user';
import { ExternalAuthSystemType, Role, User } from '../shared/model';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import { FetchUserAccounts, RemoveUserAccount } from './user-accounts.actions';
import { AuthState } from '../auth/auth.state';

interface UserAccountsStateModel {
  userAccounts?: {
    [key: string]: UserAccount;
  };
}

@State<UserAccountsStateModel>({
  name: 'userAccounts',
  defaults: {
    userAccounts: undefined,
  },
})
@Injectable()
export class UserAccountsState {
  @Selector()
  private static userAccounts(state: UserAccountsStateModel): UserAccount[] | undefined {
    return state.userAccounts ? Object.values(state.userAccounts) : undefined;
  }

  @Selector([UserAccountsState.userAccounts])
  static assignableUserAccountList(userAccounts: UserAccount[] | undefined): User[] {
    return (
      userAccounts
        ?.filter((userAccount) => userAccount.user.roles.some((role) => role.assignable))
        .map((userAccount) => userAccount.user) ?? []
    );
  }

  @Selector([UserAccountsState.userList, AuthState.currentUser])
  static directManagerTeamList(users: User[] | undefined, currentUser: UserAccount) {
    return users?.filter((user) => user.directManager === currentUser.user.id);
  }

  @Selector([UserAccountsState.userAccounts])
  static userAccountsList(userAccounts: UserAccount[] | undefined) {
    return userAccounts;
  }

  @Selector([UserAccountsState.userAccounts])
  static userList(userAccounts: UserAccount[] | undefined): User[] | undefined {
    if (!userAccounts) {
      return;
    }

    return userAccounts
      .filter(
        (userAccount) =>
          userAccount.user?.externalAuthSystemType === ExternalAuthSystemType.COGNITO ||
          userAccount.user.externalAuthSystemType === ExternalAuthSystemType.ACTIVE_DIRECTORY,
      )
      ?.map((x) => x.user);
  }

  @Selector([UserAccountsState.userAccountsList])
  static usersListIncludingApiKeys(userAccounts: UserAccount[] | undefined): User[] | undefined {
    if (!userAccounts) {
      return;
    }

    return userAccounts
      .filter(
        (userAccount) =>
          userAccount.user?.externalAuthSystemType &&
          [
            ExternalAuthSystemType.COGNITO,
            ExternalAuthSystemType.ACTIVE_DIRECTORY,
            ExternalAuthSystemType.API_KEY,
          ].includes(userAccount.user.externalAuthSystemType),
      )
      .map((x) => x.user);
  }

  @Selector([UserAccountsState.userAccounts, RoleState.assignableRoles])
  static underwriterAccounts(
    userAccounts: UserAccount[] | undefined,
    roles: Role[],
  ): UserAccount[] {
    const underwriterRole = roles.find((x) => x.originalName === 'Underwriter');

    return (
      userAccounts?.filter(
        (userAccount) =>
          userAccount.user.roles?.findIndex((role) => role.id === underwriterRole?.id) !== -1,
      ) ?? []
    );
  }

  constructor(private userAccountsService: UserAccountsService) {}

  @Action(FetchUserAccounts)
  fetchUserAccounts(ctx: StateContext<UserAccountsStateModel>) {
    ctx.patchState({ userAccounts: undefined });

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.userAccountsService.getUserAccounts().pipe(
      tap((userAccounts: UserAccount[]) => {
        const userAccountsEntities = userAccounts.reduce(
          (entities: { [key: string]: UserAccount }, userAccount) => {
            entities[userAccount.user.id] = userAccount;

            return entities;
          },
          {},
        );
        ctx.patchState({ userAccounts: userAccountsEntities });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(ResetState)
  resetState(ctx: StateContext<UserAccountsStateModel>) {
    ctx.patchState({ userAccounts: undefined });
  }

  @Action(RemoveUserAccount)
  removeUserAccount(ctx: StateContext<UserAccountsStateModel>, action: RemoveUserAccount) {
    const state = ctx.getState();
    const userAccounts = { ...state.userAccounts };

    delete userAccounts[action.userId];

    ctx.patchState({
      userAccounts,
    });
  }
}
