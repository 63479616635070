import { Mortgage } from '../mortgage';
import { Property } from '../property';

export type ExistingMortgageCalculation = Pick<Mortgage, 'payoffPaydown' | 'mortgageBalance'>;

export type SubjectPropertyCalculation = Pick<
  Property,
  'id' | 'estimatedValue' | 'purchasePrice'
> & {
  existingMortgages: ExistingMortgageCalculation[] | null;
};
