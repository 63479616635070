import { HistoryLogFilters } from './history-log.model';

export class ClearHistoryLogsApplicationId {
  static readonly type = '@historyLogs.ClearHistoryLogsApplicationId';
}

export class ExportHistoryLogsAsPdf {
  static readonly type = '@historyLogs.ExportHistoryLogsAsPdf';

  constructor(public applicationName: string) {}
}

export class FetchHistoryLogs {
  static readonly type = '@historyLogs.FetchHistoryLogs';

  constructor(public applicationId?: string, public pubnubApplicationId?: string) {}
}

export class SetHistoryLogFilters {
  static readonly type = '@historyLogs.SetHistoryLogFilters';

  constructor(public filters: HistoryLogFilters) {}
}

export class ToggleHistoryLogsMerge {
  static readonly type = '@historyLogs.ToggleHistoryLogsMerge';
}
