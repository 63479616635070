import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ConstructionApplicationItem } from '../../shared';

@Injectable({
  providedIn: 'root',
})
export class ConstructionApplicationItemsService {
  constructor(private http: HttpClient) {}

  public getConstructionApplicationItems(
    applicationId: string,
  ): Observable<ConstructionApplicationItem[]> {
    return this.http.get<ConstructionApplicationItem[]>(
      `${environment.api_url}/constructionApplicationItems?applicationId=${applicationId}`,
    );
  }

  public patchConstructionApplicationItem(
    id: string,
    item: Partial<ConstructionApplicationItem>,
  ): Observable<void> {
    return this.http.patch<void>(`${environment.api_url}/constructionApplicationItems/${id}`, item);
  }
}
