import { ApplicationPurposeType } from '../../application';

export enum FCTLoanPurpose {
  FORECLOSURE_POWER_OF_SALES = '001',
  PURCHASE = '002',
  REFINANCE = '003',
  TRANSFER_SWITCH = '004',
}

export const fctLoanPurposeMapping: Record<ApplicationPurposeType, FCTLoanPurpose | undefined> = {
  [ApplicationPurposeType.PURCHASE]: FCTLoanPurpose.PURCHASE,
  [ApplicationPurposeType.REFINANCE]: FCTLoanPurpose.REFINANCE,
  [ApplicationPurposeType.SWITCH_TRANSFER]: FCTLoanPurpose.TRANSFER_SWITCH,
  [ApplicationPurposeType.BRIDGE_FINANCING]: undefined,
  [ApplicationPurposeType.CONSTRUCTION]: undefined,
  [ApplicationPurposeType.DEFICIENCY_SALE]: undefined,
  [ApplicationPurposeType.ETO]: undefined,
  [ApplicationPurposeType.OTHER]: undefined,
  [ApplicationPurposeType.RENEWAL]: undefined,
  [ApplicationPurposeType.WORKOUT]: undefined,
};
