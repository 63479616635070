export enum MMSDocumentType {
  AGGREEMENT_OF_MORTGAGE_LOAN = 965,
  AGGREEMENT_OF_PURCHASE_AND_SALE = 966,
  AMENDED_COST_OF_BORROWING_DISCLOSURE = 968,
  AMENDMENT_OF_COMMITMENT_LETTER = 973,
  AMORTIZATION_SCHEDULE = 975,
  APPRAISAL_CHECKLIST = 1341,
  APPRAISAL = 976,
  BRIDGELOAN_DOCUMENTS_AMENDED = 980,
  BRIDGELOAN_DOCUMENTS = 979,
  COMMITMENT_LETTER = 989,
  COST_OF_BORROWING_DISCLOSURE = 995,
  CREDIT_AGREEMENT = 1294,
  CSP_APPLICATION = 997,
  DETAILS_OF_MORTGAGE_DISBURSEMENT = 1003,
  DISCLOSURE_STATEMENT_AMENDED = 1007,
  DISCLOSURE_STATEMENT = 1005,
  INSTRUCTIONS_TO_SOLICITOR = 1049,
  LAWYER_INFORMATION = 1051,
  MODIFICATION_TO_COMMITMENT = 1063,
  MORTGAGE_INSURANCE_INFO_SHEET = 1334,
  OTHER_DOCUMENTS_AS_REQUIRED = 948,
  POUT_STATEMENT = 1072,
  PRE_AUTHORIZED_PAYMENT_FORM = 1075,
  PRIVACY_AGREEMENT_1_2 = 1076,
  REALTY_TAX_FORM = 1080,
  REQUEST_FOR_INSURANCE_AMENDED = 1087,
  REQUEST_FOR_INSURANCE = 1086,
  SIGNED_COMMITMENT = 1093,
  STATEMENT_OF_ADVANCE = 1096,
}
