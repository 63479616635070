import { AssignedUserTask, Task, TaskComplete } from './model';

export class UpsertTask {
  static readonly type = '@tasks.upsertTask';
  constructor(
    public applicationId: string,
    public value: Task,
    public currentStage?: string | null,
  ) {}
}

export class UpsertCompleteTask {
  static readonly type = '@tasks.upsertCompleteTask';
  constructor(public value: TaskComplete, public currentStage?: string | null) {}
}

export class DeleteTask {
  static readonly type = '@tasks.deleteTask';
  constructor(public value: Task, public currentStage?: string | null) {}
}

export class GetTasks {
  static readonly type = '@tasks.getTasks';
  constructor(public applicationId: string) {}
}

export class BypassTasks {
  static readonly type = '@tasks.bypassTasks';
  constructor(public applicationId: string, public stage?: string) {}
}

export class TasksUpdateAssignedUsers {
  static readonly type = '@tasks.updateAssignedUsers';
  constructor(public applicationId: string, public assignedUserTasksPayload: AssignedUserTask[]) {}
}

export class TasksBulkInsert {
  static readonly type = '@tasks.insertBulkTask';
  constructor(public tasks: Task[]) {}
}

export class CreateSignPaymentChangeFormTask {
  static readonly type = '@tasks.createSignPaymentChangeFormTask';
  constructor(public applicationId: string) {}
}

export class CreateReissueCOBTask {
  static readonly type = '@tasks.createReissueCOBTask';
  constructor(public applicationId: string) {}
}
