import { PropertyInsurance } from '@fundmoreai/models';

export class FetchInsurances {
  static readonly type = '@propertyInsurances.FetchInsurances';
  constructor(public applicationId: string) {}
}

export class CreateInsurance {
  static readonly type = '@propertyInsurances.CreateInsurance';
  constructor(
    public propertyId: string,
    public applicationId: string,
    public insuranceToCreate: Partial<PropertyInsurance>,
  ) {}
}

export class UpdateInsurance {
  static readonly type = '@propertyInsurances.UpdateInsurance';
  constructor(public insurance: PropertyInsurance) {}
}

export class RemoveInsurance {
  static readonly type = '@propertyInsurances.RemoveInsurance';
  constructor(public propertyId: string, public insurance: PropertyInsurance) {}
}
