import { MMSDealStatus, MMSTransactionStatus } from '../../enums';
import { MMSMilestone } from './milestone';

export interface MMSDealStatusResponse {
  milestone: MMSMilestone[];
  dealStatus: MMSDealStatus;
  dealstatusdatepublished?: string; // date-time
  transactionStatus: {
    transactionStatus: MMSTransactionStatus;
    transactionDateTime: string; // date-time
  };
}

export interface MMSDealStatusViewModel {
  dealStatus: MMSDealStatus;
  transactionStatus: MMSTransactionStatus;
}
