export enum OptaRoofType {
  ASPHALT = 'Asphalt',
  CLAY_TILE = 'Clay Tile',
  CONCRETE = 'Concrete',
  COPPER = 'Copper',
  RUBBER = 'Rubber',
  SLATE_TILE = 'Slate Tile',
  STEEL = 'Steel',
  TAR_AND_GRAVEL = 'Tar and Gravel',
  TIN = 'Tin',
  WOOD_SHINGLES = 'Wood Shingles',
}
