import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import { ClosingInstructionsService } from './closing-instruction.service';
import { ClosingInstruction } from '../../shared/model';
import { LoadingEnd, LoadingStart } from '../../core/loading.state';
import { ApplicationResetState } from '../../shared/state.model';

export type ClosingInstructionStateModel = ClosingInstruction[];

export class UpdateClosingInstruction {
  static readonly type = '@closingInstructions.updateClosingInstruction';
  constructor(
    public mortgageId: string,
    public closingInstructionId: string,
    public updates: Partial<ClosingInstruction>,
  ) {}
}

export class DeleteClosingInstruction {
  static readonly type = '@closingInstructions.deleteClosingInstruction';
  constructor(public mortgageId: string, public closingInstructionId: string) {}
}

export class CreateClosingInstruction {
  static readonly type = '@closingInstructions.createClosingInstruction';
  constructor(
    public mortgageId: string,
    public applicationId: string,
    public closingInstruction: ClosingInstruction,
  ) {}
}

export class FetchClosingInstructions {
  static readonly type = '@closingInstructions.fetchClosingInstructions';
  constructor(public applicationId: string) {}
}

export class AddClosingInstruction {
  static readonly type = '@closingInstructions.addClosingInstruction';
  constructor(public closingInstruction: ClosingInstruction) {}
}

@State<ClosingInstructionStateModel>({
  name: 'closingInstructions',
  defaults: [],
})
@Injectable()
export class ClosingInstructionState {
  constructor(private propertyClosingInstructionsService: ClosingInstructionsService) {}

  @Action(ApplicationResetState)
  reset(ctx: StateContext<ClosingInstruction[]>) {
    ctx.setState([]);
  }

  @Selector()
  static closingInstructions(state: ClosingInstruction[]) {
    return state ?? [];
  }

  @Action(CreateClosingInstruction)
  createClosingInstruction(
    ctx: StateContext<ClosingInstruction[]>,
    action: CreateClosingInstruction,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();
    return this.propertyClosingInstructionsService
      .postClosingInstruction(action.mortgageId, action.applicationId, action.closingInstruction)
      .pipe(
        tap((newClosingInstruction) => ctx.setState([...state, newClosingInstruction])),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(FetchClosingInstructions)
  fetchClosingInstructions(
    ctx: StateContext<ClosingInstruction[]>,
    action: FetchClosingInstructions,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.propertyClosingInstructionsService
      .fetchMortgagesClosingInstructions(action.applicationId)
      .pipe(
        tap((closingInstructions) => {
          ctx.setState(closingInstructions);
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(UpdateClosingInstruction)
  updateClosingInstruction(
    ctx: StateContext<ClosingInstruction[]>,
    action: UpdateClosingInstruction,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();

    return this.propertyClosingInstructionsService
      .patchClosingInstruction(
        action.mortgageId,
        action.closingInstructionId,
        action.updates as ClosingInstruction,
      )
      .pipe(
        tap((updatedClosingInstruction) => {
          ctx.setState([
            ...state.filter((a) => a.id !== action.closingInstructionId),
            updatedClosingInstruction,
          ]);
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(DeleteClosingInstruction)
  deleteClosingInstruction(
    ctx: StateContext<ClosingInstruction[]>,
    action: DeleteClosingInstruction,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();
    return this.propertyClosingInstructionsService
      .deleteClosingInstruction(action.mortgageId, action.closingInstructionId)
      .pipe(
        tap(() => ctx.setState([...state.filter((a) => a.id !== action.closingInstructionId)])),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(AddClosingInstruction)
  addClosingInstruction(ctx: StateContext<ClosingInstruction[]>, action: AddClosingInstruction) {
    const state = ctx.getState();
    ctx.setState([...state, action.closingInstruction]);
  }
}
