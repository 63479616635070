import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { ConstructionApplicationItem } from '../../shared';
import { ConstructionApplicationItemsService } from './construction-application-items.service';
import { patch, updateItem } from '@ngxs/store/operators';
import { GetConstructionApplicationItem, UpdateConstructionItem } from './construction.actions';
import { ApplicationResetState } from '../../shared/state.model';
import { LoadingStart, LoadingEnd } from '../../core/loading.state';

interface ConstructionApplicationItemStateModel {
  constructionApplicationItems: ConstructionApplicationItem[];
}

@State<ConstructionApplicationItemStateModel>({
  name: 'constructionApplicationItem',
  defaults: { constructionApplicationItems: [] },
})
@Injectable()
export class ConstructionApplicationItemState {
  constructor(private constructionApplicationItemsService: ConstructionApplicationItemsService) {}

  @Action(ApplicationResetState)
  reset(ctx: StateContext<ConstructionApplicationItemStateModel>) {
    ctx.setState({ constructionApplicationItems: [] });
  }

  @Selector() static constructionApplicationItems(state: ConstructionApplicationItemStateModel) {
    const arrayInAlphabetical = [...state.constructionApplicationItems];
    arrayInAlphabetical.sort((a, b) =>
      a.constructionItem.toLowerCase() > b.constructionItem.toLowerCase() ? 1 : -1,
    );

    return arrayInAlphabetical;
  }

  @Selector([ConstructionApplicationItemState.constructionApplicationItems])
  static totalAmountAllowed(constructionApplicationItems: ConstructionApplicationItem[]) {
    return FundmoreCalculator.constructionTotalAmountAllowed(constructionApplicationItems);
  }

  @Selector([ConstructionApplicationItemState.constructionApplicationItems])
  static totalBudgetPercentageUsed(constructionApplicationItems: ConstructionApplicationItem[]) {
    return FundmoreCalculator.constructionTotalBudgetPercentageUsed(constructionApplicationItems);
  }

  @Action(GetConstructionApplicationItem)
  getConstructionApplicationItem(
    ctx: StateContext<ConstructionApplicationItemStateModel>,
    { applicationId }: GetConstructionApplicationItem,
  ): Observable<ConstructionApplicationItem[]> {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.constructionApplicationItemsService
      .getConstructionApplicationItems(applicationId)
      .pipe(
        tap((constructionApplicationItems) => ctx.patchState({ constructionApplicationItems })),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(UpdateConstructionItem)
  updateConstructionItem(
    ctx: StateContext<ConstructionApplicationItemStateModel>,

    { item }: UpdateConstructionItem,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const { id, ...updatedItem } = item;
    return this.constructionApplicationItemsService
      .patchConstructionApplicationItem(id, updatedItem)
      .pipe(
        tap(() => {
          ctx.setState(
            patch({
              constructionApplicationItems: updateItem<ConstructionApplicationItem>(
                (item) => item?.id === id,
                patch(updatedItem),
              ),
            }),
          );
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }
}
