import { ConfigurationState } from '../../configs/configuration-state';

export function isInApprovedPostalCodes(
  postalCode: string,
  approvedPostalCodes: string[],
): boolean {
  if (approvedPostalCodes) {
    // Approved Postal codes contain the FSA (first 3 characters)
    return approvedPostalCodes.includes(postalCode.slice(0, 3));
  }
  return true;
}

export function isInApprovedPrimaryPostalCodes(postalCode: string): boolean {
  const approvedPostalCodes = ConfigurationState.rocketExtraConfig?.approvedPostalCodesPrimary;
  return isInApprovedPostalCodes(postalCode, approvedPostalCodes);
}

export function isInApprovedSecondaryPostalCodes(postalCode: string): boolean {
  const approvedPostalCodes = ConfigurationState.rocketExtraConfig?.approvedPostalCodesSecondary;
  return (
    isInApprovedPostalCodes(postalCode, approvedPostalCodes) &&
    !postalCode.slice(0, 3).includes('0')
  );
}

export function isInRuralZone(postalCode: string): boolean {
  const ruralZoneCodes = ConfigurationState.rocketExtraConfig?.ruralCodes;
  if (ruralZoneCodes) {
    return ruralZoneCodes.includes(postalCode) || postalCode.slice(0, 3).includes('0');
  }
  return true;
}
