import {
  Action,
  Actions,
  Selector,
  State,
  StateContext,
  Store,
  ofActionDispatched,
  createSelector,
} from '@ngxs/store';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { combineLatest } from 'rxjs';
import { PropertiesState } from './properties.state';
import { FinancialLiabilityState } from '../features/application/widgets/credit/financial-liability.state';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import { BOCState } from './boc.state';
import {
  CalculationResult,
  ComputedRatioRateType,
  Fee,
  LoanType,
  Mortgage,
  MortgageCalculationAutomationSettings,
  PaymentFrequency,
  Property,
  PropertyType,
  Summary,
} from '@fundmoreai/models';
import { ApplicationResetState } from '../shared/state.model';
import { FeesState } from './fees.state';
import { MortgagesV2State } from 'src/app/portal/mortgages-v2/mortgages-v2.state';
import { IncomeState } from '../features/application/widgets/income/income.state';
import { BOCModel } from '../shared/model';
import { ComputeSummaryAllRequestedMortgages } from './summary.actions';
import { AppFeaturesState } from '../shared/app-features.state';

@State<Summary>({
  name: 'summary',
})
@Injectable()
export class SummaryState {
  constructor(private store: Store, private actions$: Actions) {}

  static summaryByRequestedMortgage(requestedMortgageId: string) {
    return createSelector(
      [
        SummaryState.ltvByRequestedMortgage(requestedMortgageId),
        SummaryState.gdsAndTdsByRequestedMortgage(requestedMortgageId),
        SummaryState.cltvByRequestedMortgage(requestedMortgageId),
      ],
      (ltv, { gds, qgds, tds, qtds, bgds, btds, qualifyingRate, bocRate }, cLtv) => {
        return { ltv, gds, tds, qgds, qtds, bgds, btds, cLtv, qualifyingRate, bocRate };
      },
    );
  }

  @Selector([
    FeesState.feesList,
    MortgagesV2State.capFeesMaxPercentage,
    MortgagesV2State.requestedMortgages,
    PropertiesState.primaryProperty,
    MortgagesV2State.primaryPropertyExistingMortgageAmount,
    MortgagesV2State.primaryPropertyExistingMortgagePayment,
    MortgagesV2State.borrowerExistingMortgages,
    IncomeState.totalMonthlyIncomeWithoutPropertyRental,
    PropertiesState.otherProperties,
    FinancialLiabilityState.debt,
    BOCState.boc,
    MortgagesV2State.mortgageCalculationAutomationSettings,
    PropertiesState.propertiesListWithOwners,
    MortgagesV2State.existingMortgages,
    AppFeaturesState.isBlanketEnabled,
    PropertiesState.propertiesList,
    MortgagesV2State.existingMortgages,
  ])
  static individualSummaryAllRequestedMortgages(
    fees: Fee[],
    capFeesMaxPercentage: number,
    requestedMortgages: Mortgage[],
    primaryProperty: Property | undefined,
    primaryPropertyExistingMortgageAmount: number,
    primaryPropertyExistingMortgagePayment: number,
    borrowerExistingMortgages: Mortgage[],
    totalIncomeWithoutPropertyRental: number,
    otherProperties: Property[] | undefined,
    debt: number,
    boc: BOCModel,
    mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
    properties: Property[],
    existingMortgages: Mortgage[],
    isBlanketEnabled: boolean,
    allProperties: Property[],
    allExistingMortgages: Mortgage[],
  ): (Summary & { mortgageId?: string })[] {
    return requestedMortgages.map((requestedMortgage) => {
      const totalLoanAmount = MortgagesV2State.loanAmountForLTVByMortgage(requestedMortgage.id)(
        requestedMortgage,
        fees,
        capFeesMaxPercentage,
      );
      const ltv = SummaryState.ltvByRequestedMortgage(requestedMortgage.id)(
        MortgagesV2State.linkedPropertyValue(requestedMortgage.id)(
          requestedMortgage,
          allProperties,
          primaryProperty,
          isBlanketEnabled,
        ),
        MortgagesV2State.linkedPropertyExistingMortgageAmount(requestedMortgage.id)(
          requestedMortgages,
          primaryPropertyExistingMortgageAmount,
          isBlanketEnabled,
        ),
        MortgagesV2State.loanAmountForLTVByMortgage(requestedMortgage.id)(
          requestedMortgage,
          fees,
          capFeesMaxPercentage,
        ),
      );

      const { gds, qgds, tds, qtds, bgds, btds, qualifyingRate, bocRate } =
        SummaryState.gdsAndTdsByRequestedMortgage(requestedMortgage.id)(
          requestedMortgage,
          totalLoanAmount,
          primaryPropertyExistingMortgagePayment,
          borrowerExistingMortgages,
          totalIncomeWithoutPropertyRental,
          primaryProperty,
          otherProperties,
          debt,
          boc,
          ltv,
          mortgageCalculationAutomationSettings,
          isBlanketEnabled,
          allProperties,
          allExistingMortgages,
        );
      const cLtv = SummaryState.cltvByRequestedMortgage(requestedMortgage.id)(
        properties,
        requestedMortgage,
        existingMortgages,
        capFeesMaxPercentage,
        fees,
      );
      return {
        mortgageId: requestedMortgage.id,
        ltv,
        gds,
        tds,
        qgds,
        qtds,
        bgds,
        btds,
        cLtv,
        qualifyingRate,
        bocRate,
      };
    });
  }

  @Selector()
  static summaryAllRequestedMortgages(state: Summary) {
    return state;
  }

  @Selector([
    SummaryState.ltvAllRequestedMortgages,
    SummaryState.gdsAndTdsAllRequestedMortgages,
    SummaryState.cltvAllRequestedMortgages,
  ])
  static summaryAllRequestedMortgagesForDocumentContext(
    ltv: CalculationResult,
    {
      gds,
      qgds,
      tds,
      qtds,
      bgds,
      btds,
      qualifyingRate,
      bocRate,
    }: {
      gds: CalculationResult;
      qgds: CalculationResult;
      tds: CalculationResult;
      qtds: CalculationResult;
      bgds: CalculationResult;
      btds: CalculationResult;
      qualifyingRate: number;
      bocRate: number;
    },
    cLtv: CalculationResult,
  ): Summary {
    return {
      ltv,
      gds,
      tds,
      qgds,
      qtds,
      bgds,
      btds,
      cLtv,
      qualifyingRate,
      bocRate,
    };
  }

  static costToCarryForTDSByRequestedMortgage(requestedMortgage: Mortgage) {
    return createSelector(
      [
        PropertiesState.linkedProperty(requestedMortgage.propertyId),
        MortgagesV2State.requestedMortgage(requestedMortgage.id),
      ],
      (linkedProperty: Property | undefined, requestedMortgage: Mortgage | undefined) => {
        return FundmoreCalculator.computeCostToCarryForTDS(
          linkedProperty,
          requestedMortgage?.monthlyPayment,
        );
      },
    );
  }

  /** @deprecated should be replaced by ltvByRequestedMortgage/ltvAllRequestedMortgages */
  @Selector([
    PropertiesState.primaryProperty,
    MortgagesV2State.primaryPropertyExistingMortgageAmount,
    MortgagesV2State.firstFoundRequestedMortgage,
    FeesState.feesList,
    MortgagesV2State.capFeesMaxPercentage,
  ])
  static ltvForFirstRequestedMortgage(
    primaryProperty: Property | undefined,
    primaryPropertyExistingMortgageAmount: number,
    requestedMortgage: Mortgage | undefined,
    fees: Fee[] | undefined,
    capFeesMaxPercentage: number,
  ) {
    const loanAmountForLTV = FundmoreCalculator.computeLoanAmountForLTV(
      [requestedMortgage],
      fees || [],
      capFeesMaxPercentage,
    );
    const propertyValue = FundmoreCalculator.computePropertyValue(primaryProperty);
    const ltv = FundmoreCalculator.computeLTV(
      propertyValue,
      primaryPropertyExistingMortgageAmount,
      loanAmountForLTV,
    );

    return ltv;
  }

  static ltvByRequestedMortgage(requestedMortgageId: string) {
    return createSelector(
      [
        MortgagesV2State.linkedPropertyValue(requestedMortgageId),
        MortgagesV2State.linkedPropertyExistingMortgageAmount(requestedMortgageId),
        MortgagesV2State.loanAmountForLTVByMortgage(requestedMortgageId),
      ],
      (
        linkedPropertyValue: number,
        linkedPropertyExistingMortgageAmount: number,
        loanAmountForLTV: number | undefined,
      ) => {
        const ltv = FundmoreCalculator.computeLTV(
          linkedPropertyValue,
          linkedPropertyExistingMortgageAmount,
          loanAmountForLTV,
        );
        return ltv;
      },
    );
  }

  @Selector([
    MortgagesV2State.linkedPropertyValueAllRequestedMortgages,
    MortgagesV2State.linkedPropertyExistingMortgageAmountAllRequestedMortgages,
    MortgagesV2State.loanAmountForLTVAllRequestedMortgages,
  ])
  static ltvAllRequestedMortgages(
    linkedPropertyValueAllRequestedMortgages: number,
    linkedPropertyExistingMortgageAmountAllRequestedMortgages: number,
    loanAmountForLTVTotal: number | undefined,
  ) {
    const ltv = FundmoreCalculator.computeLTV(
      linkedPropertyValueAllRequestedMortgages,
      linkedPropertyExistingMortgageAmountAllRequestedMortgages,
      loanAmountForLTVTotal,
    );
    return ltv;
  }

  private static readonly EMPTY_CALCULATION_RESULT: CalculationResult = {
    formula: '',
    items: [],
    result: 0,
  };

  static gdsAndTdsByRequestedMortgage(requestedMortgageId: string) {
    return createSelector(
      [
        MortgagesV2State.requestedMortgage(requestedMortgageId),
        MortgagesV2State.totalLoanAmount(requestedMortgageId),
        MortgagesV2State.primaryPropertyExistingMortgagePayment,
        MortgagesV2State.borrowerExistingMortgages,
        IncomeState.totalMonthlyIncomeWithoutPropertyRental,
        PropertiesState.primaryProperty,
        PropertiesState.otherProperties,
        FinancialLiabilityState.debt,
        BOCState.boc,
        SummaryState.ltvByRequestedMortgage(requestedMortgageId),
        MortgagesV2State.mortgageCalculationAutomationSettings,
        AppFeaturesState.isBlanketEnabled,
        PropertiesState.propertiesList,
        MortgagesV2State.existingMortgages,
      ],
      (
        requestedMortgage: Mortgage | undefined,
        totalLoanAmount: number | undefined,
        existingMortgagePaymentsOnPrimaryProperty: number,
        existingMortgages: Mortgage[],
        totalIncomeWithoutPropertyRental: number,
        primaryProperty: Property | undefined,
        otherProperties: Property[] | undefined,
        debt: number,
        boc: BOCModel,
        _ltvByRequestedMortgage: CalculationResult,
        mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
        isBlanketEnabled: boolean,
        allProperties: Property[],
        allExistingMortgages: Mortgage[],
      ) => {
        if (!requestedMortgage) {
          return {
            gds: SummaryState.EMPTY_CALCULATION_RESULT,
            qgds: SummaryState.EMPTY_CALCULATION_RESULT,
            tds: SummaryState.EMPTY_CALCULATION_RESULT,
            qtds: SummaryState.EMPTY_CALCULATION_RESULT,
            bgds: SummaryState.EMPTY_CALCULATION_RESULT,
            btds: SummaryState.EMPTY_CALCULATION_RESULT,
            qualifyingRate: 0,
            bocRate: 0,
          };
        }

        const netRate = requestedMortgage.netRate;
        const qualifyingRate = FundmoreCalculator.computeQualifyingRate(
          netRate ?? null,
          boc?.ConventionalMortgage5Year,
        );
        const benchmarkRate = boc?.ConventionalMortgage5Year;
        let monthlyPayment;
        const isLOC =
          requestedMortgage.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
          requestedMortgage.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX;

        if (isLOC) {
          monthlyPayment = requestedMortgage.amortizedMonthlyPayment;
        } else {
          monthlyPayment =
            !mortgageCalculationAutomationSettings ||
            !mortgageCalculationAutomationSettings[requestedMortgageId]?.isMonthlyPaymentDisabled
              ? FundmoreCalculator.computeMortgageMonthlyPayment(
                  requestedMortgage,
                  totalLoanAmount,
                  netRate,
                )
              : requestedMortgage?.monthlyPayment;
        }

        const requestedMortgageForCalculation = isLOC
          ? {
              ...requestedMortgage,
              ...requestedMortgage.amortizedMonthlyPaymentSnapshot,
            }
          : requestedMortgage;

        const { interest, principal } = FundmoreCalculator.computePrincipalAndInterest({
          ...requestedMortgageForCalculation,
          paymentFrequency: PaymentFrequency.MONTHLY,
          paymentAmount: monthlyPayment || null,
        });

        const securitiesIdsForBlanket = allProperties
          ?.filter((x) => x.type === PropertyType.PRIMARY || x.isCollateralized)
          .map((x) => x.id);

        // Use existing mortgages for the property linked to the requested mortgage if NOT blanket,
        // otherwise use existing mortgages for all securities
        const existingMortgagesForPropertyLinkedToRequestedMortgage = allExistingMortgages.filter(
          (x) =>
            (!isBlanketEnabled && x.propertyId === requestedMortgage.propertyId) ||
            (isBlanketEnabled && x.propertyId && securitiesIdsForBlanket?.includes(x.propertyId)),
        );
        const existingMortgagesForPropertyLinkedToRequestedMortgagePayments =
          FundmoreCalculator.computeExistingMortgagePayments(
            existingMortgagesForPropertyLinkedToRequestedMortgage,
          );

        // Use the linked property if NOT blanket, otherwise use the primary property
        const requestedMortgageProperty = allProperties?.find(
          (x) =>
            (!isBlanketEnabled && x.id === requestedMortgage.propertyId) ||
            (isBlanketEnabled && x.type === PropertyType.PRIMARY),
        );

        const gds = FundmoreCalculator.computeGDS(
          monthlyPayment,
          requestedMortgageProperty,
          otherProperties,
          existingMortgagesForPropertyLinkedToRequestedMortgage,
          existingMortgagesForPropertyLinkedToRequestedMortgagePayments,
          totalIncomeWithoutPropertyRental,
          principal,
          interest,
          mortgageCalculationAutomationSettings,
          ComputedRatioRateType.NET,
          isLOC,
        );

        const tds = FundmoreCalculator.computeTDS(
          monthlyPayment,
          primaryProperty,
          otherProperties,
          existingMortgages,
          existingMortgagePaymentsOnPrimaryProperty,
          totalIncomeWithoutPropertyRental,
          debt,
          principal,
          interest,
          mortgageCalculationAutomationSettings,
          ComputedRatioRateType.NET,
          isLOC,
        );

        const qualifyingMortgageMonthlyPayment = FundmoreCalculator.computeMortgageMonthlyPayment(
          requestedMortgageForCalculation,
          totalLoanAmount,
          qualifyingRate,
        );

        const { interest: qualifyingInterest, principal: qualifyingPrincipal } =
          FundmoreCalculator.computePrincipalAndInterest({
            ...requestedMortgageForCalculation,
            paymentFrequency: PaymentFrequency.MONTHLY,
            paymentAmount: qualifyingMortgageMonthlyPayment || null,
            netRate: qualifyingRate,
          });

        const qgds = FundmoreCalculator.computeGDS(
          qualifyingMortgageMonthlyPayment,
          requestedMortgageProperty,
          otherProperties,
          existingMortgagesForPropertyLinkedToRequestedMortgage,
          existingMortgagesForPropertyLinkedToRequestedMortgagePayments,
          totalIncomeWithoutPropertyRental,
          qualifyingPrincipal,
          qualifyingInterest,
          null,
          ComputedRatioRateType.QUALIFYING,
          isLOC,
        );

        const qtds = FundmoreCalculator.computeTDS(
          qualifyingMortgageMonthlyPayment,
          primaryProperty,
          otherProperties,
          existingMortgages,
          existingMortgagePaymentsOnPrimaryProperty,
          totalIncomeWithoutPropertyRental,
          debt,
          qualifyingPrincipal,
          qualifyingInterest,
          null,
          ComputedRatioRateType.QUALIFYING,
          isLOC,
        );
        const benchmarkMortgageMonthlyPayment = FundmoreCalculator.computeMortgageMonthlyPayment(
          requestedMortgage,
          totalLoanAmount,
          benchmarkRate,
        );

        const { interest: benchmarkInterest, principal: benchmarkPrincipal } =
          FundmoreCalculator.computePrincipalAndInterest({
            ...requestedMortgageForCalculation,
            paymentFrequency: PaymentFrequency.MONTHLY,
            paymentAmount: benchmarkMortgageMonthlyPayment || null,
            netRate: benchmarkRate,
          });

        const bgds = FundmoreCalculator.computeGDS(
          benchmarkMortgageMonthlyPayment,
          requestedMortgageProperty,
          otherProperties,
          existingMortgagesForPropertyLinkedToRequestedMortgage,
          existingMortgagesForPropertyLinkedToRequestedMortgagePayments,
          totalIncomeWithoutPropertyRental,
          benchmarkPrincipal,
          benchmarkInterest,
          null,
          ComputedRatioRateType.BENCHMARK,
          isLOC,
        );

        const btds = FundmoreCalculator.computeTDS(
          benchmarkMortgageMonthlyPayment,
          primaryProperty,
          otherProperties,
          existingMortgages,
          existingMortgagePaymentsOnPrimaryProperty,
          totalIncomeWithoutPropertyRental,
          debt,
          benchmarkPrincipal,
          benchmarkInterest,
          null,
          ComputedRatioRateType.BENCHMARK,
          isLOC,
        );

        return { gds, qgds, tds, qtds, bgds, btds, qualifyingRate, bocRate: benchmarkRate };
      },
    );
  }

  @Selector([
    MortgagesV2State.activeRequestedMortgages,
    MortgagesV2State.primaryPropertyExistingMortgagePayment,
    MortgagesV2State.borrowerExistingMortgages,
    IncomeState.totalMonthlyIncomeWithoutPropertyRental,
    PropertiesState.primaryProperty,
    PropertiesState.otherProperties,
    FinancialLiabilityState.debt,
    BOCState.boc,
    SummaryState.ltvAllRequestedMortgages,
    MortgagesV2State.mortgageCalculationAutomationSettings,
  ])
  static gdsAndTdsAllRequestedMortgages(
    requestedMortgages: Mortgage[],
    existingMortgagePaymentsOnPrimaryProperty: number,
    existingMortgages: Mortgage[],
    totalIncomeWithoutPropertyRental: number,
    primaryProperty: Property | undefined,
    otherProperties: Property[] | undefined,
    debt: number,
    boc: BOCModel,
    _ltvAllRequestedMortgages: CalculationResult,
    mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
  ) {
    let monthlyPaymentTotal = 0,
      qualifyingMortgageMonthlyPaymentTotal = 0,
      benchmarkMortgageMonthlyPaymentTotal = 0;
    let qualifyingRateTotal = 0;
    let principalTotal = 0,
      qualifyingPrincipalTotal = 0,
      benchmarkPrincipalTotal = 0;
    let interestTotal = 0,
      qualifyingInterestTotal = 0,
      benchmarkInterestTotal = 0;
    const benchmarkRate = boc?.ConventionalMortgage5Year || 0;

    requestedMortgages.forEach((requestedMortgage) => {
      const netRate = requestedMortgage?.netRate;
      const qualifyingRate = FundmoreCalculator.computeQualifyingRate(
        netRate,
        boc?.ConventionalMortgage5Year,
      );
      const isLOC =
        requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
        requestedMortgage?.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX;

      const monthlyPayment =
        (isLOC
          ? requestedMortgage?.amortizedMonthlyPayment
          : !mortgageCalculationAutomationSettings ||
            !mortgageCalculationAutomationSettings[requestedMortgage.id]?.isMonthlyPaymentDisabled
          ? FundmoreCalculator.computeMortgageMonthlyPayment(
              requestedMortgage,
              requestedMortgage.totalLoanAmount ?? 0,
              netRate,
            )
          : requestedMortgage?.monthlyPayment) ?? 0;

      monthlyPaymentTotal += monthlyPayment;
      qualifyingRateTotal += qualifyingRate;

      const requestedMortgageForCalculation = isLOC
        ? {
            ...requestedMortgage,
            ...requestedMortgage.amortizedMonthlyPaymentSnapshot,
          }
        : requestedMortgage;

      const qualifyingMortgageMonthlyPayment =
        FundmoreCalculator.computeMortgageMonthlyPayment(
          requestedMortgageForCalculation,
          requestedMortgageForCalculation.totalLoanAmount ?? 0,
          qualifyingRate,
        ) || 0;

      qualifyingMortgageMonthlyPaymentTotal += qualifyingMortgageMonthlyPayment;

      const benchmarkMortgageMonthlyPayment =
        FundmoreCalculator.computeMortgageMonthlyPayment(
          requestedMortgageForCalculation,
          requestedMortgageForCalculation.totalLoanAmount ?? 0,
          benchmarkRate,
        ) || 0;

      benchmarkMortgageMonthlyPaymentTotal += benchmarkMortgageMonthlyPayment;

      const { interest, principal } = FundmoreCalculator.computePrincipalAndInterest({
        ...requestedMortgageForCalculation,
        paymentFrequency: PaymentFrequency.MONTHLY,
        paymentAmount: monthlyPayment || null,
      });
      principalTotal += principal;
      interestTotal += interest;

      const { interest: qualifyingInterest, principal: qualifyingPrincipal } =
        FundmoreCalculator.computePrincipalAndInterest({
          ...requestedMortgageForCalculation,
          paymentFrequency: PaymentFrequency.MONTHLY,
          paymentAmount: qualifyingMortgageMonthlyPayment || null,
          netRate: qualifyingRate,
        });
      qualifyingPrincipalTotal += qualifyingPrincipal;
      qualifyingInterestTotal += qualifyingInterest;

      const { interest: benchmarkInterest, principal: benchmarkPrincipal } =
        FundmoreCalculator.computePrincipalAndInterest({
          ...requestedMortgageForCalculation,
          paymentFrequency: PaymentFrequency.MONTHLY,
          paymentAmount: benchmarkMortgageMonthlyPayment || null,
          netRate: benchmarkRate,
        });
      benchmarkPrincipalTotal += benchmarkPrincipal;
      benchmarkInterestTotal += benchmarkInterest;
    });

    const allReqMortgagesAreLOC = requestedMortgages.every(
      (rm) =>
        rm.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
        rm.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX,
    );

    const gds = FundmoreCalculator.computeGDS(
      monthlyPaymentTotal,
      primaryProperty,
      otherProperties,
      existingMortgages,
      existingMortgagePaymentsOnPrimaryProperty,
      totalIncomeWithoutPropertyRental,
      principalTotal,
      interestTotal,
      mortgageCalculationAutomationSettings,
      undefined,
      allReqMortgagesAreLOC,
    );

    const tds = FundmoreCalculator.computeTDS(
      monthlyPaymentTotal,
      primaryProperty,
      otherProperties,
      existingMortgages,
      existingMortgagePaymentsOnPrimaryProperty,
      totalIncomeWithoutPropertyRental,
      debt,
      principalTotal,
      interestTotal,
      mortgageCalculationAutomationSettings,
      undefined,
      allReqMortgagesAreLOC,
    );

    const qgds = FundmoreCalculator.computeGDS(
      qualifyingMortgageMonthlyPaymentTotal,
      primaryProperty,
      otherProperties,
      existingMortgages,
      existingMortgagePaymentsOnPrimaryProperty,
      totalIncomeWithoutPropertyRental,
      qualifyingPrincipalTotal,
      qualifyingInterestTotal,
      null,
      ComputedRatioRateType.QUALIFYING,
      allReqMortgagesAreLOC,
    );

    const qtds = FundmoreCalculator.computeTDS(
      qualifyingMortgageMonthlyPaymentTotal,
      primaryProperty,
      otherProperties,
      existingMortgages,
      existingMortgagePaymentsOnPrimaryProperty,
      totalIncomeWithoutPropertyRental,
      debt,
      qualifyingPrincipalTotal,
      qualifyingInterestTotal,
      null,
      ComputedRatioRateType.QUALIFYING,
      allReqMortgagesAreLOC,
    );

    const bgds = FundmoreCalculator.computeGDS(
      benchmarkMortgageMonthlyPaymentTotal,
      primaryProperty,
      otherProperties,
      existingMortgages,
      existingMortgagePaymentsOnPrimaryProperty,
      totalIncomeWithoutPropertyRental,
      benchmarkPrincipalTotal,
      benchmarkInterestTotal,
      null,
      ComputedRatioRateType.BENCHMARK,
      allReqMortgagesAreLOC,
    );

    const btds = FundmoreCalculator.computeTDS(
      benchmarkMortgageMonthlyPaymentTotal,
      primaryProperty,
      otherProperties,
      existingMortgages,
      existingMortgagePaymentsOnPrimaryProperty,
      totalIncomeWithoutPropertyRental,
      debt,
      benchmarkPrincipalTotal,
      benchmarkInterestTotal,
      null,
      ComputedRatioRateType.BENCHMARK,
      allReqMortgagesAreLOC,
    );

    return {
      gds,
      qgds,
      tds,
      qtds,
      bgds,
      btds,
      qualifyingRate: qualifyingRateTotal,
      bocRate: benchmarkRate,
    };
  }

  static cltvByRequestedMortgage(requestedMortgageId: string) {
    return createSelector(
      [
        PropertiesState.propertiesListWithOwners,
        MortgagesV2State.requestedMortgage(requestedMortgageId),
        MortgagesV2State.existingMortgages,
        MortgagesV2State.capFeesMaxPercentage,
        FeesState.feesList,
      ],
      (
        properties: Property[],
        requestedMortgage: Mortgage,
        existingMortgages: Mortgage[],
        capFeesMaxPercentage: number,
        fees: Fee[] | undefined,
      ) => {
        const cltv = FundmoreCalculator.computeCltv(
          [requestedMortgage],
          existingMortgages,
          properties ?? [],
          fees || [],
          capFeesMaxPercentage ?? 0.0,
        );
        return cltv;
      },
    );
  }

  @Selector([
    PropertiesState.propertiesListWithOwners,
    MortgagesV2State.activeRequestedMortgages,
    MortgagesV2State.existingMortgages,
    MortgagesV2State.capFeesMaxPercentage,
    FeesState.feesList,
  ])
  static cltvAllRequestedMortgages(
    properties: Property[],
    requestedMortgages: Mortgage[],
    existingMortgages: Mortgage[],
    capFeesMaxPercentage: number,
    fees: Fee[] | undefined,
  ) {
    const cltv = FundmoreCalculator.computeCltv(
      requestedMortgages,
      existingMortgages,
      properties ?? [],
      fees || [],
      capFeesMaxPercentage ?? 0.0,
    );
    return cltv;
  }

  static qualifyingRateByRequestedMortgage(requestedMortgageId: string) {
    return createSelector(
      [MortgagesV2State.requestedMortgage(requestedMortgageId), BOCState.boc],
      (requestedMortgage: Mortgage | undefined, boc: BOCModel) => {
        const qualifyingRate = FundmoreCalculator.computeQualifyingRate(
          requestedMortgage?.netRate,
          boc?.ConventionalMortgage5Year,
        );

        return qualifyingRate;
      },
    );
  }

  @Selector([MortgagesV2State.activeRequestedMortgages, BOCState.boc])
  static qualifyingRateAllRequestedMortgages(requestedMortgages: Mortgage[], boc: BOCModel) {
    const netRateTotal = requestedMortgages?.reduce((sum, x) => sum + x.netRate, 0);

    const qualifyingRate = FundmoreCalculator.computeQualifyingRate(
      netRateTotal,
      boc?.ConventionalMortgage5Year,
    );

    return qualifyingRate;
  }

  @Action(ComputeSummaryAllRequestedMortgages, { cancelUncompleted: true })
  computeSummaryAllRequestedMortgages(ctx: StateContext<Summary>) {
    return combineLatest([
      this.store.select(SummaryState.ltvAllRequestedMortgages),
      this.store.select(SummaryState.gdsAndTdsAllRequestedMortgages),
      this.store.select(SummaryState.cltvAllRequestedMortgages),
    ]).pipe(
      debounceTime(100),
      map(([ltv, { gds, qgds, tds, qtds, bgds, btds, qualifyingRate, bocRate }, cLtv]) => {
        ctx.patchState({
          ltv,
          gds,
          tds,
          qgds,
          qtds,
          bgds,
          btds,
          cLtv,
          qualifyingRate,
          bocRate,
        });
      }),
      takeUntil(this.actions$.pipe(ofActionDispatched(ApplicationResetState))),
    );
  }

  @Action(ApplicationResetState)
  resetState(ctx: StateContext<Summary>) {
    ctx.patchState({});
  }
}
