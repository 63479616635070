export enum ApplicationApprovalStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  CHANGES_REQUESTED = 'CHANGES_REQUESTED',
  CANCELED = 'CANCELED',
  RECOMMENDED_FOR_APPROVAL = 'RECOMMENDED_FOR_APPROVAL',
}

export enum ApplicationApprovalAction {
  REQUEST = 'REQUEST',
  APPROVE = 'APPROVE',
  DECLINE = 'DECLINE',
  REQUEST_CHANGES = 'REQUEST_CHANGES',
  CANCEL = 'CANCEL',
  PENDING = 'PENDING',
  RECOMMEND_FOR_APPROVAL = 'RECOMMEND_FOR_APPROVAL',
}

export const ApplicationApprovalActionToStatusRecord: Record<
  ApplicationApprovalAction,
  ApplicationApprovalStatus
> = {
  [ApplicationApprovalAction.REQUEST]: ApplicationApprovalStatus.PENDING,
  [ApplicationApprovalAction.APPROVE]: ApplicationApprovalStatus.APPROVED,
  [ApplicationApprovalAction.DECLINE]: ApplicationApprovalStatus.DECLINED,
  [ApplicationApprovalAction.REQUEST_CHANGES]: ApplicationApprovalStatus.CHANGES_REQUESTED,
  [ApplicationApprovalAction.CANCEL]: ApplicationApprovalStatus.CANCELED,
  [ApplicationApprovalAction.PENDING]: ApplicationApprovalStatus.PENDING,
  [ApplicationApprovalAction.RECOMMEND_FOR_APPROVAL]:
    ApplicationApprovalStatus.RECOMMENDED_FOR_APPROVAL,
};

export enum ApplicationApprovalKey {
  ID = 'id',
  APPLICATION_ID = 'applicationId',
  APPROVAL_TYPE_ID = 'approvalTypeId',
  DETAILS = 'details',
  STATUS = 'status',
  APPROVER_ID = 'approverId',
  REQUESTED_BY_ID = 'requestedById',
  REQUESTED_ON = 'requestedOn',
}
