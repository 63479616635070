import { Fee, Mortgage } from '@fundmoreai/models';

export function makeCalculatedTotalAmount(
  fees: Fee[],
  insuranceAmount: number | null,
  interestAdjustmentAmount: number,
  outstandingBalance: number,
  payoutDebt: number,
  payoutMortgageBalance: number,
  requestedMortgage: Mortgage,
  totalPayment: number,
) {
  const bValue = (insuranceAmount ?? 0) + payoutMortgageBalance + payoutDebt;
  const cValue = (requestedMortgage.loanAmount ?? 0) - bValue;
  const totalFeesForLoanProcessing = fees
    .filter((x) => !x.type?.startsWith('*'))
    .reduce((a, b) => a + b.amount, 0);
  const cLessCosts = cValue - totalFeesForLoanProcessing;
  const dValue = cValue;
  const eValue = fees.reduce((a, b) => a + b.amount, 0) + interestAdjustmentAmount;
  const fValue = totalPayment + outstandingBalance;
  const gValue = eValue + fValue;

  return {
    bValue,
    cValue,
    cLessCosts,
    dValue,
    eValue,
    fValue,
    gValue,
    totalCostOfCredit: gValue - dValue,
  };
}
