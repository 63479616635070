import { Application } from './application';

export interface IntellifiSendResponse {
  upload: boolean;
}

export interface IntellifiXSDValidationResponse {
  errors?: XSDError[];
  valid: boolean;
}

interface XSDError {
  domain: number;
  code: number;
  level: number;
  column: number;
  line: number;
  message: string;
}

export interface IntellifiJoiValidationResponse {
  value: Application;
  error?: JoiError;
}

interface JoiError {
  _original: Application;
  details: JoiErrorDetail[];
}

interface JoiErrorDetail {
  message: string;
  path: string[];
  type: string;
  context: JoiErrorDetailContext;
}

interface JoiErrorDetailContext {
  key: string;
  label: string;
  value: string;
  valids: string[];
}

export interface ValidationMessage {
  error?: string;
  warning?: string;
  current?: string;
  type: IntellifiValidationMessageType;
}

export enum IntellifiValidationMessageType {
  XSD,
  JOI,
}
