export enum FCTDealStatus {
  NEW_ORDER = '001',
  SUBMITTED = '002',
  AVM_RECEIVED = '003',
  NO_AVM_AVAILABLE = '004',
  UPDATED = '005',
  ERROR = '006',
  FUNDED = '007',
  CLARIFY_ADDRESS = '008',
  CANCELLED = '009',
  REQUIRED = '010',
  EXPIRED = '011',
  POLICY_EXPIRED = '012',
  PRODUCT_DELIVERED = '013',
  COMPLETED = '014',
  ON_HOLD = '015',
  CORRECTION_REQUIRED = '016',
  ON_HOLD_VM = '017',
  REJECTED = '018',
  PASSED_REVIEW = '019',
  PENDING = '020',
  PENDING_THIRD_PARTY = '021',
  REVIEW = '022',
  UNASSIGNED = '023',
  CONDITIONAL_ACCEPTANCE = '024',
  ACCEPTED = '025',
  PENDING_APPEAL = '026',
  ASSIGNED_FOR_REVIEW = '027',
  APPRAISAL_REQUIRED = '028',
  RECEIVED_FOR_REVIEW = '029',
  SUBMITTED_BY_UW = '030',
  REVIEW_IN_PROGRESS = '031',
  PENDING_ELIGIBILITY = '032',
  UPDATE_REQUIRED = '033',
  ASSIGNED_FOR_SELECTION = '034',
  QUOTE_APPROVAL = '035',
}
