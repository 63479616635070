/* eslint-disable max-len */
import { Injectable, NgZone, signal } from '@angular/core';
import { BehaviorSubject, catchError, map, take } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import jwt_decode from 'jwt-decode';
import { Hub } from 'aws-amplify/utils';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthScopeContextService {
  private currentScopeContextSubject: BehaviorSubject<unknown>;
  public loginRedirect = signal(false);
  public ssoError = signal('');
  private returnUrl: string | undefined;

  private readonly SCOPE_KEY = 'scopeContext';

  constructor(private http: HttpClient, private router: Router, private zone: NgZone) {
    Hub.listen('auth', ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
          console.log('user have been signedIn successfully.');
          this.ssoSignedCallback();
          break;
        case 'signedOut':
          console.log('user have been signedOut successfully.');
          break;
        case 'tokenRefresh':
          console.log('auth tokens have been refreshed.');
          break;
        case 'tokenRefresh_failure':
          console.log('failure while refreshing auth tokens.');
          this.ssoError.set(
            'An error occurred while trying to sign in. Please try again. If the problem persist please cleanup the browser cache',
          );
          // throw error to log on sentry for monitoring
          throw payload.data;
          break;
        case 'signInWithRedirect':
          console.log('signInWithRedirect API has successfully been resolved.');
          break;
        case 'signInWithRedirect_failure':
          console.log('failure while trying to resolve signInWithRedirect API.');
          this.ssoError.set(
            'An error occurred while trying to sign in. Please try again. If the problem persist please cleanup the browser cache',
          );
          // throw error to log on sentry for monitoring
          throw payload.data;
          break;
        case 'customOAuthState':
          this.returnUrl = decodeURIComponent(payload.data);
          break;
      }
      console.log(payload);
    });

    const clientStoredScope = localStorage.getItem(this.SCOPE_KEY);
    this.currentScopeContextSubject = new BehaviorSubject<unknown>(
      clientStoredScope ? JSON.parse(clientStoredScope) : null,
    );
  }

  async ssoSignedCallback() {
    this.resolveFederatedADAuthScopeContext()
      .pipe(
        take(1),
        catchError((error) => {
          this.ssoError.set(error?.error?.message ?? error.message);
          throw error;
        }),
      )
      .subscribe(() => {
        this.zone.run(async () => {
          this.loginRedirect.set(true);
          this.ssoError.set('');
          await this.redirectToPortal();
        });
      });
  }

  async redirectToPortal() {
    if (this.returnUrl) {
      await this.router.navigateByUrl(this.returnUrl);
    } else {
      await this.router.navigate(['/portal/pipeline']);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get currentScopeContextToken(): any {
    return (
      this.currentScopeContextSubject?.value || {
        scopeContextToken: undefined,
      }
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public get currentScopeContext(): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { scopeContextToken } = (this.currentScopeContextSubject?.value as any) || {
      scopeContextToken: undefined,
    };
    if (scopeContextToken) {
      const decoded = jwt_decode(scopeContextToken);
      return decoded;
    }
    return null;
  }

  public resolveAuthScopeContext() {
    return this.http.get<unknown>(`${environment.identity_api_url}/scope-context`).pipe(
      map((scopeContext) => {
        localStorage.setItem(this.SCOPE_KEY, JSON.stringify(scopeContext));
        this.currentScopeContextSubject.next(scopeContext);
      }),
    );
  }

  public resolveFederatedADAuthScopeContext() {
    return this.http
      .get<unknown>(`${environment.identity_api_url}/federated-ad/scope-context`)
      .pipe(
        map((scopeContext) => {
          localStorage.setItem(this.SCOPE_KEY, JSON.stringify(scopeContext));
          this.currentScopeContextSubject.next(scopeContext);
        }),
      );
  }

  public clearAuthScopeContext() {
    localStorage.removeItem('scopeContext');
    this.currentScopeContextSubject.next(null);
  }
}
