/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, combineLatest, map, switchMap, EMPTY } from 'rxjs';
import { CompoundPeriod, RepaymentType } from 'src/app/shared/enums';
import { FinancialLiabilityState } from 'src/app/features/application/widgets/credit/financial-liability.state';
import { UserAccountsState } from 'src/app/portal/user-accounts.state';
import { ConditionsDisplayService } from 'src/app/shared/services/conditions-display.service';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import { InsuranceState } from 'src/app/portal/insurances.state';
import { DefaultPackageService } from './default.package.service';
import {
  BaseDocumentContext,
  GentaiDocumentContext,
  GenerateDocumentsPackageServiceBase,
  AmortizationSchedule,
} from '../model';
import {
  CustomerType,
  Mortgage,
  Fee,
  TermType,
  RateType,
  FeeType,
  NetworthContext,
  CommitmentRequestedMortgage,
  MortgageCalculationAutomationSettings,
} from '@fundmoreai/models';
import { ApplicantsState } from 'src/app/portal/applicants.state';
import { Application, UserAccount } from 'src/app/shared';
import { formatAmount } from 'src/app/shared/handlebars.service';
import { MortgagesServices } from 'src/app/portal/mortgages.services';
import { AmortizationScheduleService } from './amortization-schedule.service';
import { Role, RoleState } from '../../user';
import { MortgagesV2State } from 'src/app/portal/mortgages-v2/mortgages-v2.state';
import { DocumentTemplate } from 'src/app/features/manager-portal/documents-management/model';
import { NetworthState } from 'src/app/features/application/widgets/networth/networth.state';
import { CompoundPeriodRecord } from 'src/app/shared/enum-records';
import { AppFeaturesState } from 'src/app/shared/app-features.state';
import { EqCoreState } from '../../../../portal/eq-core.state';

@Injectable()
export class GentaiPackageService
  extends DefaultPackageService
  implements GenerateDocumentsPackageServiceBase
{
  constructor(
    amortizationScheduleService: AmortizationScheduleService,
    applicantsState: ApplicantsState,
    conditionDisplayService: ConditionsDisplayService,
    mortgagesServices: MortgagesServices,
    store: Store,
  ) {
    super(
      amortizationScheduleService,
      applicantsState,
      conditionDisplayService,
      mortgagesServices,
      store,
    );
  }

  getContext$(documentTemplate: DocumentTemplate): Observable<GentaiDocumentContext> {
    return combineLatest([
      super.getContext$(documentTemplate),
      this.store.select(ApplicantsState.broker),
      this.store.select(FinancialLiabilityState.payoutDebt),
      this.store.select(MortgagesV2State.firstFoundRequestedMortgage).pipe(
        switchMap((mortgage) => {
          if (!mortgage) {
            return EMPTY;
          }
          return this.store.select(InsuranceState.insuranceAmount(mortgage.id));
        }),
      ),
      this.store.select(UserAccountsState.userAccountsList),
      this.store.select(RoleState.roles),
      this.store.select(NetworthState.currentNetworth()),
      this.store.select(NetworthState.futureNetworth()),
      this.store.select(MortgagesV2State.mortgageCalculationAutomationSettings),
      this.store.select(AppFeaturesState.iadByFpdEnabled),
    ]).pipe(
      map(
        ([
          defaultContext,
          broker,
          payoutDebt,
          insuranceAmount,
          userAccounts,
          roles,
          currentNetworth,
          futureNetworth,
          mortgageCalculationAutomationSettings,
          iadByFpdEnabled,
        ]) => {
          return this.getGentaiContext(
            defaultContext,
            broker?.brokerage,
            insuranceAmount,
            payoutDebt,
            userAccounts ?? [],
            roles,
            currentNetworth,
            futureNetworth,
            mortgageCalculationAutomationSettings,
            iadByFpdEnabled,
          );
        },
      ),
    );
  }

  private getGentaiContext(
    defaultContext: BaseDocumentContext,
    brokerageName: string | null | undefined,
    insuranceAmount: number | null,
    payoutDebt: number,
    users: UserAccount[],
    roles: Role[],
    currentNetworth: NetworthContext,
    futureNetworth: NetworthContext,
    mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
    iadByFpdEnabled: boolean,
  ): GentaiDocumentContext {
    const {
      amortizationSchedule,
      amountToBeAdvanced,
      applicants,
      application,
      fees,
      mortgage,
      primaryProperty,
      requestedMortgage,
    } = defaultContext;
    const netAmountToAdvance = this.store.selectSnapshot(
      MortgagesV2State.netAmountToBeAdvanced(requestedMortgage.id),
    );
    const payoutMortgageBalance = this.store.selectSnapshot(MortgagesV2State.payoutMortgageBalance);

    const interestAdjustmentAmount = requestedMortgage.interestAdjustmentAmount ?? 0;
    const localRequestedMortgage = mortgage;
    const outstandingBalance = this.amortizationScheduleService
      .getAmortizationSchedule(
        requestedMortgage,
        amountToBeAdvanced,
        mortgageCalculationAutomationSettings,
        iadByFpdEnabled,
      )
      .reduce((a, b) => b.balance, 0);
    const totalLenderFees = fees
      .filter((x) => x.type?.startsWith('Lender') || x.type?.startsWith('Commitment'))
      .reduce((a, b) => a + b.amount, 0)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    const totalPayment = this.makeTotalPayment(
      requestedMortgage,
      localRequestedMortgage,
      amortizationSchedule,
    );

    return {
      ...defaultContext,
      amortizationString: this.makeAmortizationString(requestedMortgage),
      assignedUsers: this.makeAssignedUsersGentai(application, users, roles),
      bdm: this.makeBdm(application, users, roles),
      brokerageName,
      brokerDescriptions: this.makeBrokerDescriptionsGentai(totalLenderFees),
      brokerInfo: {
        name: 'Gentai Capital Corporation',
        address:
          '#805-8400 West Road North Tower, International Trade Centre Richmond, BC, V6X 0S7',
        phone: '604-638-1580',
      },
      brokerNotes: application.brokerNotes,
      calculatedTotalAmount: FundmoreCalculator.makeCalculatedTotalAmount(
        fees,
        insuranceAmount,
        interestAdjustmentAmount,
        outstandingBalance,
        payoutDebt,
        payoutMortgageBalance,
        requestedMortgage,
        totalPayment.amount,
      ),
      coborrowers: applicants?.filter((a) => a.customerType === CustomerType.CO_BORROWER),
      currentNetworth,
      feesDictionary: this.makeFeesDictionary(fees),
      feesTotalAmount: FundmoreCalculator.computeTotalFees(fees),
      futureNetworth,
      insuranceAmount,
      interestAdjustmentAmount,
      interestRate: this.makeInterestRate(requestedMortgage),
      isBCproperty: primaryProperty?.propertyAddress?.includes('BC'),
      isBritishColumbiaProperty: primaryProperty?.propertyAddress?.includes('British Columbia'),
      mortgageBalanceAtEndOfTerm:
        localRequestedMortgage.repaymentType === RepaymentType.INTEREST_ONLY
          ? localRequestedMortgage.loanAmount
          : outstandingBalance,
      netAmountToAdvance,
      networth: {
        ...futureNetworth,
        payoutDebt,
        payoutMortgageBalance,
      },
      outstandingBalance,
      partyDescriptions: this.makePartyDescriptionsGentai(totalLenderFees),
      prepaymentFeeDetails: this.makePrepaymentFeeDetails(requestedMortgage),
      totalPayment: totalPayment.amount,
      totalPaymentAsString: totalPayment.asString,
    };
  }

  private makeAmortizationString(requestedMortgage: Mortgage) {
    return requestedMortgage.repaymentType === RepaymentType.INTEREST_ONLY
      ? 'Not Applicable - Interest Only'
      : requestedMortgage.repaymentType === RepaymentType.PRINCIPAL_AND_INTEREST
      ? 'Not Applicable - Principal and Interest'
      : `${requestedMortgage.amortizationMonths} Months - ${requestedMortgage.repaymentType}`;
  }

  private makeAssignedUsersGentai(application: Application, users: UserAccount[], roles: Role[]) {
    const bdmRole = roles.find((x) => x.originalName === 'BDM');
    const underwriterRole = roles.find((x) => x.originalName === 'Underwriter');
    const assignedUsersIds = (application.ApplicationAssignedUsers ?? []).map((a) => a.userId);
    const filteredUser = users.filter(
      (u) =>
        assignedUsersIds.findIndex((a) => a === u.user.id) !== -1 &&
        u.user.roles.findIndex((r) => r.id === bdmRole?.id || r.id === underwriterRole?.id) !== -1,
    );

    return filteredUser.map((f) => {
      return {
        name: f.user.firstName,
        surname: f.user.lastName,
        specialTitle:
          f.user.firstName === 'Rachel' && f.user.lastName === 'Rogerson'
            ? 'VP, Residential Mortgage'
            : f.user.firstName === 'Arvin' && f.user.lastName === 'Darred'
            ? 'Senior Underwriter'
            : f.user.firstName === 'Adriene' && f.user.lastName === 'Cheung'
            ? 'Underwriter'
            : f.user.roles?.reduce((acc, role) => `${role.name} ${acc ? ',' : ''} ${acc}`, ''),
      };
    });
  }

  private makeBdm(application: Application, users: UserAccount[], roles: Role[]) {
    const bdmRole = roles.find((x) => x.originalName === 'BDM');
    const assignedUserId = (application.ApplicationAssignedUsers ?? []).map((a) => a.userId);

    return users
      .filter(
        (u) =>
          assignedUserId.findIndex((a) => a === u.user.id) !== -1 &&
          u.user.roles.findIndex((r) => r.id === bdmRole?.id) !== -1,
      )
      .map((u) => ({
        name: u.user.firstName,
        surname: u.user.lastName,
      }));

    return [];
  }

  private makeBrokerDescriptionsGentai(totalLenderFees: string) {
    return [
      'Gentai works exclusively for the lender.',

      `Gentai capital will earn the lender fees of $ ${totalLenderFees} to arrange the mortgage.`,
    ];
  }

  private makeFeesDictionary(fees: Fee[]) {
    const feeTypes = Object.values(FeeType);
    const groupFees: { [x: string]: Fee[] } = {};
    const wasSorted: string[] = [];
    fees.forEach((fee) => {
      if (fee.type) {
        feeTypes.forEach((a) => {
          if (fee.type.includes(a)) {
            groupFees[a] = [...(groupFees[a] ?? []), fee];
            wasSorted.push(fee.id);
          }
        });
      }
      if (!wasSorted.includes(fee.id)) {
        groupFees.Custom = [...(groupFees.Custom ?? []), fee];
      }
    });
    return groupFees;
  }

  private makeInterestRate(requestedMortgage: Mortgage) {
    const rbcPrime = 6.95;
    const addToRBCPrime = 15.3;
    let interestRateDetails;
    const compounding = requestedMortgage?.compounding;

    if (requestedMortgage?.rateType === RateType.FIXED) {
      interestRateDetails = `${formatAmount(requestedMortgage?.netRate, '')}% for the first ${
        requestedMortgage?.termMonths ? requestedMortgage?.termMonths - 1 : ' '
      } months. Compounded ${CompoundPeriodRecord[compounding]}.`;
    } else {
      interestRateDetails = `Greater of ${formatAmount(
        requestedMortgage?.netRate,
        '',
      )}% or RBC Prime + ${
        requestedMortgage?.netRate
          ? Math.round((requestedMortgage?.netRate - rbcPrime) * 100) / 100
          : 0
      } for the first ${
        requestedMortgage?.termMonths ? requestedMortgage?.termMonths - 1 : ' '
      } months. Compounded ${CompoundPeriodRecord[compounding]}.`;
    }
    let prepaymentDetails = '';
    if (compounding === CompoundPeriod.MONTHLY) {
      prepaymentDetails = `Should the Loan not be renewed or paid out on or before the end of the first ${
        requestedMortgage?.termMonths ? requestedMortgage?.termMonths - 1 : ' '
      }
    months, the interest rate commencing the ${
      requestedMortgage?.termMonths ? requestedMortgage?.termMonths : ' '
    }th month would be 18.00% per annum.`;
    } else {
      prepaymentDetails = `Should the Loan not be renewed or paid out on or before the end of the first ${
        requestedMortgage?.termMonths ? requestedMortgage?.termMonths - 1 : ' '
      }
    months, the interest rate commencing the ${
      requestedMortgage?.termMonths ? requestedMortgage?.termMonths : ' '
    }th month would be 18.00% compounded ${CompoundPeriodRecord[compounding]}.`;
    }

    return {
      rbcPrime,
      primePlusNumber: (requestedMortgage.netRate ?? 0) - rbcPrime,
      addToRBCPrime,
      interestRateDetails,
      prepaymentDetails,
    };
  }

  private makePartyDescriptionsGentai(totalLenderFees: string) {
    return [
      'Gentai acts as the manager for the lender and may receive remuneration for managing the mortgage.',

      `The Associate / Sub-broker or Agent of the company may be compensated from the lender fees of $ ${totalLenderFees} collected to arrange this mortgage.`,
    ];
  }

  private makePrepaymentFeeDetails(requestedMortgage: Mortgage) {
    const termType = requestedMortgage?.termType?.toLowerCase();
    let prepaymentFeeDetails;

    if (termType === TermType.CLOSED) {
      prepaymentFeeDetails = `The Loan is closed to prepayment for ${requestedMortgage.termMonths}
    months from the interest adjustment date. After ${requestedMortgage.termMonths}
    months the Loan will become open to prepayment, either full payout or partial paydown, subject to 30 days’ prior written
    notice. Should the notice be less than 30 days, the Borrower shall pay interest to the Lender on the difference between the actual
    amount of days’ notice days and the stipulated 30 days notice amount.`;
    } else {
      if (termType === TermType.OPEN) {
        prepaymentFeeDetails = `The Loan is open to prepayment, either full payout or partial paydown, subject to 30 days’ prior written notice.
        Should the notice be less than 30 days, the Borrower shall pay interest to the Lender on the difference between the actual amount of
        days’ notice days and the stipulated 30 days notice amount.`;
      } else {
        prepaymentFeeDetails = '[Please select a term type]';
      }
    }

    return prepaymentFeeDetails;
  }

  private makeTotalPayment(
    requestedMortgage: Mortgage,
    localRequestedMortgage: CommitmentRequestedMortgage,
    amortizationSchedule: AmortizationSchedule[],
  ) {
    let totalPayment = 0;
    let totalPaymentString = '';

    if (
      requestedMortgage.termMonths === 13 &&
      (!requestedMortgage.compounding || requestedMortgage.compounding === CompoundPeriod.MONTHLY)
    ) {
      const totalLoanAmount = this.store.selectSnapshot(
        MortgagesV2State.totalLoanAmountByRequestedMortgage(requestedMortgage.id),
      );

      const rate13th = 18;
      const mortgagePayment13th = FundmoreCalculator.computeMortgagePaymentAmount(
        requestedMortgage,
        totalLoanAmount,
        rate13th,
      );
      const payment13th = FundmoreCalculator.getPayment(mortgagePayment13th, requestedMortgage);
      totalPayment = FundmoreCalculator.computeTotalPaymentsGentai(
        localRequestedMortgage?.monthlyPayment,
        payment13th,
      );
      totalPaymentString = `Total of regular payments to be made (12 X $${localRequestedMortgage?.monthlyPayment} + 1 X $${payment13th})`;
    } else {
      totalPayment = amortizationSchedule.reduce((a, b) => a + b.payment, 0);
      totalPaymentString = `Total of regular payments to be made (${amortizationSchedule.length} X $${localRequestedMortgage?.monthlyPayment})`;
    }

    return { amount: totalPayment, asString: totalPaymentString };
  }
}
