import {
  EmployedAndSelfEmployedIncome,
  EmployedIncome,
  OtherIncome,
  SelfEmployedIncome,
} from 'src/app/features/application/widgets/income/income.model';
import { Applicant, ApplicationAdvancedProduct, Mortgage } from '../../shared/model';
import {
  UpdateEmployedIncome,
  UpdateSelfEmployedIncome,
} from 'src/app/features/application/widgets/income/income.state';
import { CreditReportUpdate } from 'src/app/features/shared/credit-report/request-credit-report/credit-report-requests.state';

export class MortgagePatchAndProductReapply {
  static readonly type = '@PreviewApplyProductChanges.MortgagePatchAndProductReapply';
  constructor(
    public value: Partial<Mortgage>,
    public applicationId: string,
    public requestedMortgageId: string,
    public applicationAdvancedProduct: ApplicationAdvancedProduct | undefined,
    public applyForMortgagesWithProductChanges = false,
  ) {}
}

export class ApplicantPatchAndProductReapply {
  static readonly type = '@PreviewApplyProductChanges.ApplicantPatchAndProductReapply';
  constructor(
    public value: Partial<Applicant>,
    public applicantId: string,
    public applicationId: string,
    public actionToDispatch?: CreditReportUpdate,
    public applyForMortgagesWithProductChanges = false,
  ) {}
}

export class ApplicantDeleteAndProductReapply {
  static readonly type = '@PreviewApplyProductChanges.ApplicantDeleteAndProductReapply';
  constructor(
    public applicant: Applicant,
    public applicationId: string,
    public applyForMortgagesWithProductChanges = false,
  ) {}
}

export class IncomeCreateAndProductReapply {
  static readonly type = '@PreviewApplyProductChanges.IncomeCreateAndProductReapply';
  constructor(
    public value: EmployedIncome | SelfEmployedIncome | OtherIncome,
    public applicationId: string,
    public applyForMortgagesWithProductChanges = false,
  ) {}
}

export class EmployedPatchAndProductReapply {
  static readonly type = '@PreviewApplyProductChanges.EmployedPatchAndProductReapply';
  constructor(
    public value: Partial<EmployedAndSelfEmployedIncome>,
    public applicationId: string,
    public actionToDispatch: UpdateEmployedIncome | UpdateSelfEmployedIncome,
    public applyForMortgagesWithProductChanges = false,
  ) {}
}

export class EmployedDeleteAndProductReapply {
  static readonly type = '@PreviewApplyProductChanges.EmployedDeleteAndProductReapply';
  constructor(
    public value: EmployedIncome | SelfEmployedIncome,
    public applicationId: string,
    public applyForMortgagesWithProductChanges = false,
  ) {}
}

export class OtherIncomePatchAndProductReapply {
  static readonly type = '@PreviewApplyProductChanges.OtherIncomePatchAndProductReapply';
  constructor(
    public value: Partial<OtherIncome>,
    public applicationId: string,
    public applyForMortgagesWithProductChanges = false,
  ) {}
}

export class OtherIncomeDeleteAndProductReapply {
  static readonly type = '@PreviewApplyProductChanges.OtherIncomeDeleteAndProductReapply';
  constructor(
    public value: OtherIncome,
    public applicationId: string,
    public applyForMortgagesWithProductChanges = false,
  ) {}
}
