export enum DefaultApprovalType {
  DLA_APPROVAL = 'DLA_APPROVAL',
  APPLY_DISCRETION = 'APPLY_DISCRETION',
}

// Record to approval type name in DB
export const DefaultApprovalTypeNameRecord: Record<DefaultApprovalType, string> = {
  [DefaultApprovalType.DLA_APPROVAL]: 'DLA Approval',
  [DefaultApprovalType.APPLY_DISCRETION]: 'Apply Discretion',
};
