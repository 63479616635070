import { Pipe, PipeTransform } from '@angular/core';

interface Application {
  name?: string;
}

@Pipe({
  name: 'applicationName',
  standalone: true,
})
export class ApplicationNamePipe implements PipeTransform {
  UNKNOWN_NAME = $localize`Unknown Name`;
  transform(application: Application | null): string {
    if (!application?.name) {
      return this.UNKNOWN_NAME;
    }

    return `${application.name}`.trim();
  }
}
