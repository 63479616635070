export enum OptaCommercialRoofSurfaceType {
  ALUMINUM = 'Aluminum',
  ASPHALT_ROLL = 'Asphalt Roll',
  ASPHALT_SHINGLES = 'Asphalt Shingles',
  CLAY_TILE = 'Clay Tile',
  CONCRETE_TILE = 'Concrete Tile',
  CORRUGATED_STEEL = 'Corrugated Steel',
  GREEN_ROOF = 'Green Roof',
  LAMINATED_SHINGLES = 'Laminated Shingles',
  METAL_TILE = 'Metal Tile',
  MINERAL_FIBER_SHAKES = 'Mineral Fiber Shakes',
  PLASTIC = 'Plastic',
  RUBBER_MEMBRANE = 'Rubber Membrane',
  SLATE_TILE = 'Slate Tile',
  TAR_AND_GRAVEL = 'Tar and Gravel',
  WOOD_SHINGLES = 'Wood Shingles',
  OTHER = 'Other',
  UNKNOWN = 'Unknown',
}
