import { Applicant, AddressExpandedType, ResidentialStatusType } from '@fundmoreai/models';

export function computeTotalRentFromApplicantsAddresses(applicants: Partial<Applicant>[]): number {
  let totalRent = 0;
  for (const applicant of applicants) {
    if (applicant.ApplicantAddressesExpanded) {
      for (const address of applicant.ApplicantAddressesExpanded) {
        if (
          address.type === AddressExpandedType.CURRENT &&
          address.residentialStatusType === ResidentialStatusType.RENT &&
          address.includeRentInCalculations === true
        ) {
          totalRent += address.rent;
        }
      }
    }
  }
  return totalRent;
}
