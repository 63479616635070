import { Mortgage, PaymentFrequency, RepaymentType } from '@fundmoreai/models';
import { compoundingMap, paymentFrequencyMap } from './mortgage-payment.calculator';

export type PrincipalInterestMortgageInput = Pick<
  Mortgage,
  | 'firstRegularPaymentDate'
  | 'maturityDate'
  | 'totalLoanAmount'
  | 'netRate'
  | 'paymentAmount'
  | 'paymentFrequency'
  | 'compounding'
  | 'amortizationMonths'
  | 'repaymentType'
  | 'termMonths'
  | 'closingDate'
  | 'interestAdjustmentDate'
>;

export interface PrincipalInterestMortgageOutput {
  principal: number;
  interest: number;
}

export function computePrincipalAndInterest(
  mortgage: PrincipalInterestMortgageInput | undefined,
): PrincipalInterestMortgageOutput {
  if (mortgage.repaymentType === RepaymentType.INTEREST_ONLY) {
    return {
      principal: 0,
      interest: mortgage.paymentAmount,
    };
  }
  let interest = 0;
  let principal = 0;
  const balance = mortgage.totalLoanAmount;
  const interestRate = mortgage.netRate / 100 ?? 0;
  const paymentFrequency = mortgage.paymentFrequency;
  const compounding = mortgage.compounding;
  const paymentAmount = mortgage.paymentAmount;
  const balanceAfterPrincipal = Math.round(balance * 100) / 100;

  const frequencyNumber =
    paymentFrequencyMap.get(paymentFrequency) ?? paymentFrequencyMap.get(PaymentFrequency.MONTHLY);
  const compoundingNumber = compoundingMap.get(compounding) ?? 2;

  const rate = (1 + interestRate / compoundingNumber) ** (compoundingNumber / frequencyNumber) - 1;

  interest = Math.round(balanceAfterPrincipal * rate * 100) / 100;
  principal = Math.round((paymentAmount - interest) * 100) / 100;

  return {
    principal,
    interest,
  };
}
