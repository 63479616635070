import { ApplicationRiskFlag } from '@fundmoreai/models';

export class AddApplicationRiskFlag {
  static readonly type = '@applicationRiskFlags.AddApplicationRiskFlag';

  constructor(public applicationRiskFlag: Partial<ApplicationRiskFlag>) {}
}

export class DeleteApplicationRiskFlag {
  static readonly type = '@applicationRiskFlags.DeleteApplicationRiskFlag';

  constructor(public applicationRiskFlagId: string) {}
}

export class FetchApplicationRiskFlags {
  static readonly type = '@applicationRiskFlags.FetchApplicationRiskFlags';

  constructor(public applicationId: string) {}
}

export class MarkApplicationRiskFlagAsResolved {
  static readonly type = '@applicationRiskFlags.MarkApplicationRiskFlagAsResolved';

  constructor(public applicationRiskFlagId: string) {}
}

export class SendApplicationRiskFlagMessage {
  static readonly type = '@applicationRiskFlags.SendApplicationRiskFlagMessage';

  constructor(
    public applicationId: string,
    public applicationRiskFlagId: string,
    public message: string,
  ) {}
}

export class SendApplicationToFraudIq {
  static readonly type = '@applicationRiskFlags.SendApplicationToFraudIq';

  constructor(public applicationId: string, public riskFlagId: string) {}
}
