export interface MMSFieldMetadata {
  field: string;
  name: string;
  value: string;
  entity: MMSFieldMetadataEntities;
  required: boolean;
  isMissing: boolean;
}

export enum MMSFieldMetadataEntities {
  NONE = '',
  LENDER_CONTACT = 'Lender Contact',
  BROKER_CONTACT = 'Broker Contact',
  MORTGAGOR = 'Mortgagor',
  MORTGAGOR_CONTACT = 'Mortgagor Contact',
  MORTGAGOR_ADDRESS = 'Mortgagor Address',
  MORTGAGOR_SPOUSE = 'Mortgagor Spouse',
  MORTGAGOR_SIGNATORY = 'Mortgagor Signatory',
  GUARANTOR = 'Guarantor',
  GUARANTOR_ADDRESS = 'Guarantor Address',
  GUARANTOR_SIGNATORY = 'Guarantor Signatory',
  PROPERTY_ADDRESS = 'Property Address',
  PROPERTY = 'Property',
  LAWYER = 'Lawyer',
  LAWYER_CONTACT = 'Lawyer Contact',
  MORTGAGE = 'Mortgage',
  PAYMENT = 'Payment',
  INTEREST_RATE = 'Interest Rate',
  MORTGAGE_DATES = 'Mortgage Dates',
  BRIDGE_LOAN = 'Bridge Loan',
  SALE_PROPERTY = 'Sale Property',
  CLOSING_CONFIRMATION = 'Closing Confirmation',
}

export enum MMSFieldMetadataFields {
  DEAL_SOURCE = 'Deal Source',
  LENDER_REFERENCE_NUMBER = 'Lender Reference Number',
  FCT_URN = 'FCT URN',
  LENDER_NAME = 'Lender Name',
  LENDER_CODE = 'Lender Code',
  LENDER_REMARKS = 'Lender Remarks',
  FIRST_NAME = 'First Name',
  MIDDLE_NAME = 'Middle Name',
  LAST_NAME = 'Last Name',
  COMPANY_NAME = 'Company Name',
  PHONE_1 = 'Phone 1',
  PHONE_2 = 'Phone 2',
  EXTENSION = 'Extension',
  FAX = 'Fax',
  EMAIL = 'Email',
  TYPE = 'Type',
  TITLE = 'Title',
  UNIT_NUMBER = 'Unit Number',
  STREET_NUMBER = 'Street Number',
  STREET_NAME = 'Street Name',
  STREET_NAME_2 = 'Street Name 2',
  CITY = 'City',
  PROVINCE = 'Province',
  POSTAL_CODE = 'Postal Code',
  CONSENTING_SPOUSE = 'Consenting Spouse',
  BALANCE_OWING_AT_MATURITY = 'Balance Owing At Maturity',
  CASHBACK_PERCENTAGE = 'Cashback Percentage',
  CASHBACK_VALUE = 'Cashback Value',
  DOWN_PAYMENT = 'Down Payment',
  SALE_PRICE = 'Sale Price',
  LOAN_TO_VALUE = 'Loan To Value',
  INVESTOR = 'Investor',
  MONTHLY_PAYMENT_AMOUNT = 'Monthly Payment Amount',
  MAXIMUM_LOAN_AMOUNT = 'Maximum Loan Amount',
  PAYMENT_PERIOD = 'Payment Period',
  PAYMENT_AMOUNT = 'Payment Amount',
  TAX_PORTION = 'Tax Portion',
  TOTAL_PAYMENT = 'Total Payment',
  PAYMENT_DAY = 'Payment Day',
  REGISTERED_INTEREST_RATE = 'Registered Interest Rate',
  ACTUAL_MORTGAGE_RATE = 'Actual Mortgage Rate',
  PRIME_PLUS_INTEREST_RATE = 'Prime Plus Interest Rate',
  BASE_RATE = 'Base Rate',
  CALCULATION_PERIOD = 'Calculation Period',
  FIRST_PAYMENT = 'First Payment',
  LAST_PAYMENT = 'Last Payment',
  BALANCE_DUE_DATE = 'Balance Due Date',
  BRIDGE_LOAN_NUMBER = 'Bridge Loan Number',
  BRIDGE_LOAN_AMOUNT = 'Bridge Loan Amount',
  INTEREST_DUE = 'Interest Due',
  PER_DIEM = 'Per Diem',
  CLOSING_DATE_OF_SALE = 'Closing Date of Sale',
  LEGAL_DESCRIPTION = 'Legal Description',
  ADDRESS = 'Address',
  CONFIRMATION_DATE = 'Confirmation Date',
  REGISTRATION_DATE = 'Registration Date',
  INSTRUMENT_NUMBER = 'Instrument Number',
  CONSENTING_SPOUSE_GUARANTOR = 'Consenting Spouse Guarantor',
  GUARANTOR_TYPE = 'Guarantor type',
  BUSINESS_PHONE = 'Business Phone',
  HOME_PHONE = 'Home Phone',
  OCCUPANCY_TYPE = 'Occupancy Type',
  LAW_FIRM = 'Law Firm',
  CLOSING_DATE = 'Closing Date',
  MORTGAGE_NUMBER = 'Mortgage Number',
  MORTGAGE_TYPE = 'Mortgage Type',
  TRANSACTION_TYPE = 'Transaction Type',
  MORTGAGE_PRIORITY = 'Mortgage Priority',
  MORTGAGE_PRODUCT = 'Mortgage Product',
  TERM = 'Term',
  AMORTIZATION = 'Amortization',
  RENT_ASSIGNMENT = 'Rent Assignment',
  INSURER = 'Insurer',
  MORTGAGE_AMOUNT = 'Mortgage Amount',
  REGISTERED_AMOUNT = 'Registered Amount',
  LOAN_AMOUNT = 'Loan Amount',
  TOTAL_INTEREST = 'Total Interest',
  REGISTERED_INTEREST_RATE_TEXT = 'Registered Interest Rate Text',
  INTEREST_ADJUSTMENT_DATE = 'Interest Adjustment Date',
}
