import { SupportedCustomEntity, SupportedTypes } from '../fieldMetadata';

export interface AdvancedProductDefaultValue {
  tableName: SupportedCustomEntity;
  tableDisplayName: string;
  fieldName: string;
  fieldDisplayName: string;
  defaultValue: string;
  type: SupportedTypes;
}
