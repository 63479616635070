import { AddApplicantModel, Applicant } from '@fundmoreai/models';

export interface ManualApplicationStateModel {
  source: string | null;
  customers: AddApplicantModel[];
}

export class SetSource {
  static readonly type = '@manualApplication.SetSource';
  constructor(public source: string) {}
}

export class SetCustomers {
  static readonly type = '@manualApplication.SetCustomers';
  constructor(public applicants: Applicant[]) {}
}

export class UpdateCustomer {
  static readonly type = '@manualApplication.UpdateCustomer';
  constructor(public customer: AddApplicantModel) {}
}

export class RemoveCustomer {
  static readonly type = '@manualApplication.RemoveCustomer';
  constructor(public customerId: string) {}
}

export class AddCustomer {
  static readonly type = '@manualApplication.AddCustomer';
  constructor(public customer: AddApplicantModel) {}
}

export class AddLimitedCustomer {
  static readonly type = '@manualApplication.AddLimitedCustomer';
  constructor(public isPrimary: boolean) {}
}

export class ResetCustomers {
  static readonly type = '@manualApplication.ResetCustomers';
}

export class ResetManualApplicationState {
  static readonly type = '@manualApplication.ResetManualApplicationState';
}
