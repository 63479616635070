import {
  FCTAddOnProduct,
  FCTBorrowerType,
  FCTDealStatus,
  FCTEnums,
  FCTInsuranceStatus,
  FCTLoanPriority,
  FCTLoanPurpose,
  FCTNoteRecipient,
  FCTNotesStatus,
  FCTOccupancyType,
  FCTPropertyInspectionContactType,
  FCTPropertyType,
  FCTPropertyUse,
  FCTProvince,
  FCTRentalType,
  FCTRequestStatus,
  FCTRequestedAppointmentTime,
  FCTSaleType,
  FCTSecondaryServiceType,
  FCTServiceType,
  FCTStreetDirection,
  FCTStreetType,
} from '@fundmoreai/models';

export const FCTAddOnProductRecord: Record<FCTAddOnProduct, string> = {
  [FCTAddOnProduct.MARKET_RENT_ESTIMATE_DESKTOP]: $localize`:@@FCT.ADD_ON_PRODUCT.MARKET_RENT_ESTIMATE_DESKTOP:Market Rent Estimate Desktop`,
  [FCTAddOnProduct.AS_IS_AS_COMPLETE_DB]: $localize`:@@FCT.ADD_ON_PRODUCT.AS_IS_AS_COMPLETE_DB:As Is / As Complete Drive By`,
  [FCTAddOnProduct.MARKET_RENT_ESTIMATE_DRIVE_BY]: $localize`:@@FCT.ADD_ON_PRODUCT.MARKET_RENT_ESTIMATE_DRIVE_BY:Market Rent Estimate Drive By`,
  [FCTAddOnProduct.APPRAISAL_UPDATE_DRIVE_BY]: $localize`:@@FCT.ADD_ON_PRODUCT.APPRAISAL_UPDATE_DRIVE_BY:Appraisal Update Drive By`,
  [FCTAddOnProduct.LETTER_OF_DIRECTION_POST_APPRAISAL_DRIVE_BY]: $localize`:@@FCT.ADD_ON_PRODUCT.LETTER_OF_DIRECTION_POST_APPRAISAL_DRIVE_BY:Letter of Direction Post Appraisal Drive By`,
  [FCTAddOnProduct.PROGRESS_INSPECTION_FULL_APPRAISAL]: $localize`:@@FCT.ADD_ON_PRODUCT.PROGRESS_INSPECTION_FULL_APPRAISAL:Progress Inspection Full Appraisal`,
  [FCTAddOnProduct.AS_IS_AS_COMPLETE]: $localize`:@@FCT.ADD_ON_PRODUCT.AS_IS_AS_COMPLETE:As Is / As Complete`,
  [FCTAddOnProduct.MARKET_RENT_ESTIMATE]: $localize`:@@FCT.ADD_ON_PRODUCT.MARKET_RENT_ESTIMATE:Market Rent Estimate`,
  [FCTAddOnProduct.SCHEDULE_A_INCOME_APPROACH_FULL_APPRAISAL]: $localize`:@@FCT.ADD_ON_PRODUCT.SCHEDULE_A_INCOME_APPROACH_FULL_APPRAISAL:Schedule A Income Approach Full Appraisal`,
  [FCTAddOnProduct.RUSH_REQUEST_SAME_DAY_SERVICE_FULL_APPRAISAL]: $localize`:@@FCT.ADD_ON_PRODUCT.RUSH_REQUEST_SAME_DAY_SERVICE_FULL_APPRAISAL:Rush Request - Same Day Service Full Appraisal`,
  [FCTAddOnProduct.APPRAISAL_UPDATE_FULL_APPRAISAL]: $localize`:@@FCT.ADD_ON_PRODUCT.APPRAISAL_UPDATE_FULL_APPRAISAL:Appraisal Update Full Appraisal`,
  [FCTAddOnProduct.LETTER_OF_DIRECTION_POST_APPRAISAL_FA]: $localize`:@@FCT.ADD_ON_PRODUCT.LETTER_OF_DIRECTION_POST_APPRAISAL_FA:Letter of Direction Post Appraisal FA`,
  [FCTAddOnProduct.RUSH_REQUEST]: $localize`:@@FCT.ADD_ON_PRODUCT.RUSH_REQUEST:Rush Request`,
};

export const FCTBorrowerTypeRecord: Record<FCTBorrowerType, string> = {
  [FCTBorrowerType.BORROWER]: $localize`:@@FCT.BORROWER_TYPE.BORROWER:Borrower`,
  [FCTBorrowerType.CO_BORROWER]: $localize`:@@FCT.BORROWER_TYPE.CO_BORROWER:Co-Borrower`,
};

export const FCTDealStatusRecord: Record<FCTDealStatus, string> = {
  [FCTDealStatus.NEW_ORDER]: $localize`:@@FCT.DEAL_STATUS.NEW_ORDER:New Order`,
  [FCTDealStatus.SUBMITTED]: $localize`:@@FCT.DEAL_STATUS.SUBMITTED:Submitted`,
  [FCTDealStatus.AVM_RECEIVED]: $localize`:@@FCT.DEAL_STATUS.AVM_RECEIVED:AVM Received`,
  [FCTDealStatus.NO_AVM_AVAILABLE]: $localize`:@@FCT.DEAL_STATUS.NO_AVM_AVAILABLE:No AVM Available`,
  [FCTDealStatus.UPDATED]: $localize`:@@FCT.DEAL_STATUS.UPDATED:Updated`,
  [FCTDealStatus.ERROR]: $localize`:@@FCT.DEAL_STATUS.ERROR:Error`,
  [FCTDealStatus.FUNDED]: $localize`:@@FCT.DEAL_STATUS.FUNDED:Funded`,
  [FCTDealStatus.CLARIFY_ADDRESS]: $localize`:@@FCT.DEAL_STATUS.CLARIFY_ADDRESS:Clarify Address`,
  [FCTDealStatus.CANCELLED]: $localize`:@@FCT.DEAL_STATUS.CANCELLED:Cancelled`,
  [FCTDealStatus.REQUIRED]: $localize`:@@FCT.DEAL_STATUS.REQUIRED:Required`,
  [FCTDealStatus.EXPIRED]: $localize`:@@FCT.DEAL_STATUS.EXPIRED:Expired`,
  [FCTDealStatus.POLICY_EXPIRED]: $localize`:@@FCT.DEAL_STATUS.POLICY_EXPIRED:Policy Expired`,
  [FCTDealStatus.PRODUCT_DELIVERED]: $localize`:@@FCT.DEAL_STATUS.PRODUCT_DELIVERED:Product Delivered`,
  [FCTDealStatus.COMPLETED]: $localize`:@@FCT.DEAL_STATUS.COMPLETED:Completed`,
  [FCTDealStatus.ON_HOLD]: $localize`:@@FCT.DEAL_STATUS.ON_HOLD:On Hold`,
  [FCTDealStatus.CORRECTION_REQUIRED]: $localize`:@@FCT.DEAL_STATUS.CORRECTION_REQUIRED:Correction Required`,
  [FCTDealStatus.ON_HOLD_VM]: $localize`:@@FCT.DEAL_STATUS.ON_HOLD_VM:On Hold VM`,
  [FCTDealStatus.REJECTED]: $localize`:@@FCT.DEAL_STATUS.REJECTED:Rejected`,
  [FCTDealStatus.PASSED_REVIEW]: $localize`:@@FCT.DEAL_STATUS.PASSED_REVIEW:Passed Review`,
  [FCTDealStatus.PENDING]: $localize`:@@FCT.DEAL_STATUS.PENDING:Pending`,
  [FCTDealStatus.PENDING_THIRD_PARTY]: $localize`:@@FCT.DEAL_STATUS.PENDING_THIRD_PARTY:Pending Third Party`,
  [FCTDealStatus.REVIEW]: $localize`:@@FCT.DEAL_STATUS.REVIEW:Review`,
  [FCTDealStatus.UNASSIGNED]: $localize`:@@FCT.DEAL_STATUS.UNASSIGNED:Unassigned`,
  [FCTDealStatus.CONDITIONAL_ACCEPTANCE]: $localize`:@@FCT.DEAL_STATUS.CONDITIONAL_ACCEPTANCE:Conditional Acceptance`,
  [FCTDealStatus.ACCEPTED]: $localize`:@@FCT.DEAL_STATUS.ACCEPTED:Accepted`,
  [FCTDealStatus.PENDING_APPEAL]: $localize`:@@FCT.DEAL_STATUS.PENDING_APPEAL:Pending Appeal`,
  [FCTDealStatus.ASSIGNED_FOR_REVIEW]: $localize`:@@FCT.DEAL_STATUS.ASSIGNED_FOR_REVIEW:Assigned for Review`,
  [FCTDealStatus.APPRAISAL_REQUIRED]: $localize`:@@FCT.DEAL_STATUS.APPRAISAL_REQUIRED:Appraisal Required`,
  [FCTDealStatus.RECEIVED_FOR_REVIEW]: $localize`:@@FCT.DEAL_STATUS.RECEIVED_FOR_REVIEW:Received for Review`,
  [FCTDealStatus.SUBMITTED_BY_UW]: $localize`:@@FCT.DEAL_STATUS.SUBMITTED_BY_UW:Submitted by UW`,
  [FCTDealStatus.REVIEW_IN_PROGRESS]: $localize`:@@FCT.DEAL_STATUS.REVIEW_IN_PROGRESS:Review in Progress`,
  [FCTDealStatus.PENDING_ELIGIBILITY]: $localize`:@@FCT.DEAL_STATUS.PENDING_ELIGIBILITY:Pending Eligibility`,
  [FCTDealStatus.UPDATE_REQUIRED]: $localize`:@@FCT.DEAL_STATUS.UPDATE_REQUIRED:Update Required`,
  [FCTDealStatus.ASSIGNED_FOR_SELECTION]: $localize`:@@FCT.DEAL_STATUS.ASSIGNED_FOR_SELECTION:Assigned for Selection`,
  [FCTDealStatus.QUOTE_APPROVAL]: $localize`:@@FCT.DEAL_STATUS.QUOTE_APPROVAL:Quote Approval`,
};

export const FCTInsuranceStatusRecord: Record<FCTInsuranceStatus, string> = {
  [FCTInsuranceStatus.ELIGIBLE_FOR_INSURANCE]: $localize`:@@FCT.INSURANCE_STATUS.ELIGIBLE_FOR_INSURANCE:Eligible for Insurance`,
  [FCTInsuranceStatus.INELIGIBLE_FOR_INSURANCE]: $localize`:@@FCT.INSURANCE_STATUS.INELIGIBLE_FOR_INSURANCE:Ineligible for Insurance`,
  [FCTInsuranceStatus.PENDING]: $localize`:@@FCT.INSURANCE_STATUS.PENDING:Pending`,
  [FCTInsuranceStatus.ORDER_UPDATE_REQUIRED]: $localize`:@@FCT.INSURANCE_STATUS.ORDER_UPDATE_REQUIRED:Order Update Required`,
  [FCTInsuranceStatus.NO_REPORT_AVAILABLE]: $localize`:@@FCT.INSURANCE_STATUS.NO_REPORT_AVAILABLE:No Report Available`,
};

export const FCTLoanPriorityRecord: Record<FCTLoanPriority, string> = {
  [FCTLoanPriority.FIRST_MORTGAGE]: $localize`:@@FCT.LOAN_PRIORITY.FIRST_MORTGAGE:First Mortgage`,
  [FCTLoanPriority.SECOND_MORTGAGE]: $localize`:@@FCT.LOAN_PRIORITY.SECOND_MORTGAGE:Second Mortgage`,
  [FCTLoanPriority.THIRD_MORTGAGE]: $localize`:@@FCT.LOAN_PRIORITY.THIRD_MORTGAGE:Third Mortgage`,
};

export const FCTLoanPurposeRecord: Record<FCTLoanPurpose, string> = {
  [FCTLoanPurpose.FORECLOSURE_POWER_OF_SALES]: $localize`:@@FCT.LOAN_PURPOSE.FORECLOSURE_POWER_OF_SALES:Foreclosure / Power of Sales`,
  [FCTLoanPurpose.PURCHASE]: $localize`:@@FCT.LOAN_PURPOSE.PURCHASE:Purchase`,
  [FCTLoanPurpose.REFINANCE]: $localize`:@@FCT.LOAN_PURPOSE.REFINANCE:Refinance`,
  [FCTLoanPurpose.TRANSFER_SWITCH]: $localize`:@@FCT.LOAN_PURPOSE.TRANSFER_SWITCH:Transfer / Switch`,
};

export const FCTNoteRecipientRecord: Record<FCTNoteRecipient, string> = {
  [FCTNoteRecipient.FCT]: $localize`:@@FCT.NOTE_RECIPIENT.FCT:FCT`,
  [FCTNoteRecipient.LENDER]: $localize`:@@FCT.NOTE_RECIPIENT.LENDER:Lender`,
  [FCTNoteRecipient.APPRAISER]: $localize`:@@FCT.NOTE_RECIPIENT.APPRAISER:Appraiser`,
  [FCTNoteRecipient.UNDERWRITER]: $localize`:@@FCT.NOTE_RECIPIENT.UNDERWRITER:Underwriter`,
};

export const FCTNotesStatusRecord: Record<FCTNotesStatus, string> = {
  [FCTNotesStatus.NO_NEW_NOTES_AVAILABLE]: $localize`:@@FCT.NOTES_STATUS.NO_NEW_NOTES_AVAILABLE:No New Notes Available`,
  [FCTNotesStatus.NEW_NOTES_AVAILABLE]: $localize`:@@FCT.NOTES_STATUS.NEW_NOTES_AVAILABLE:New Notes Available`,
};

export const FCTOccupancyTypeRecord: Record<FCTOccupancyType, string> = {
  [FCTOccupancyType.INVESTMENT]: $localize`:@@FCT.OCCUPANCY_TYPE.INVESTMENT:Investment`,
  [FCTOccupancyType.OWNER_OCCUPIED]: $localize`:@@FCT.OCCUPANCY_TYPE.OWNER_OCCUPIED:Owner Occupied`,
  [FCTOccupancyType.RENTAL]: $localize`:@@FCT.OCCUPANCY_TYPE.RENTAL:Rental`,
  [FCTOccupancyType.VACANT]: $localize`:@@FCT.OCCUPANCY_TYPE.VACANT:Vacant`,
};

export const FCTPropertyInspectionContactTypeRecord: Record<
  FCTPropertyInspectionContactType,
  string
> = {
  [FCTPropertyInspectionContactType.OWNER]: $localize`:@@FCT.PROPERTY_INSPECTION_CONTACT_TYPE.OWNER:Owner`,
  [FCTPropertyInspectionContactType.TENANT]: $localize`:@@FCT.PROPERTY_INSPECTION_CONTACT_TYPE.TENANT:Tenant`,
  [FCTPropertyInspectionContactType.REALTOR]: $localize`:@@FCT.PROPERTY_INSPECTION_CONTACT_TYPE.REALTOR:Realtor`,
  [FCTPropertyInspectionContactType.NEIGHBOR]: $localize`:@@FCT.PROPERTY_INSPECTION_CONTACT_TYPE.NEIGHBOR:Neighbor`,
  [FCTPropertyInspectionContactType.PROPERTY_MANAGER]: $localize`:@@FCT.PROPERTY_INSPECTION_CONTACT_TYPE.PROPERTY_MANAGER:Property Manager`,
  [FCTPropertyInspectionContactType.OTHER]: $localize`:@@FCT.PROPERTY_INSPECTION_CONTACT_TYPE.OTHER:Other`,
};

export const FCTPropertyTypeRecord: Record<FCTPropertyType, string> = {
  [FCTPropertyType.CONDO_UNIT]: $localize`:@@FCT.PROPERTY_TYPE.CONDO_UNIT:Condo Unit`,
  [FCTPropertyType.DUPLEX]: $localize`:@@FCT.PROPERTY_TYPE.DUPLEX:Duplex`,
  [FCTPropertyType.FOURPLEX]: $localize`:@@FCT.PROPERTY_TYPE.FOURPLEX:Fourplex`,
  [FCTPropertyType.MOBILE_MODULAR]: $localize`:@@FCT.PROPERTY_TYPE.MOBILE_MODULAR:Mobile / Modular`,
  [FCTPropertyType.TOWNHOME]: $localize`:@@FCT.PROPERTY_TYPE.TOWNHOME:Townhome`,
  [FCTPropertyType.SEMI_DETACHED]: $localize`:@@FCT.PROPERTY_TYPE.SEMI_DETACHED:Semi Detached`,
  [FCTPropertyType.SINGLE]: $localize`:@@FCT.PROPERTY_TYPE.SINGLE:Single`,
  [FCTPropertyType.STACKED]: $localize`:@@FCT.PROPERTY_TYPE.STACKED:Stacked`,
  [FCTPropertyType.TRIPLEX]: $localize`:@@FCT.PROPERTY_TYPE.TRIPLEX:Triplex`,
};

export const FCTPropertyUseRecord: Record<FCTPropertyUse, string> = {
  [FCTPropertyUse.RESIDENTIAL]: $localize`:@@FCT.PROPERTY_USE.RESIDENTIAL:Residential`,
  [FCTPropertyUse.COMMERCIAL]: $localize`:@@FCT.PROPERTY_USE.COMMERCIAL:Commercial`,
  [FCTPropertyUse.RECREATIONAL]: $localize`:@@FCT.PROPERTY_USE.RECREATIONAL:Recreational`,
  [FCTPropertyUse.AGRICULTURAL]: $localize`:@@FCT.PROPERTY_USE.AGRICULTURAL:Agricultural`,
  [FCTPropertyUse.VACANT_LAND]: $localize`:@@FCT.PROPERTY_USE.VACANT_LAND:Vacant Land`,
};

export const FCTProvinceRecord: Record<FCTProvince, string> = {
  [FCTProvince.AB]: $localize`:@@FCT.PROVINCE.AB:Alberta`,
  [FCTProvince.BC]: $localize`:@@FCT.PROVINCE.BC:British Columbia`,
  [FCTProvince.MB]: $localize`:@@FCT.PROVINCE.MB:Manitoba`,
  [FCTProvince.NB]: $localize`:@@FCT.PROVINCE.NB:New Brunswick`,
  [FCTProvince.NL]: $localize`:@@FCT.PROVINCE.NL:Newfoundland and Labrador`,
  [FCTProvince.NS]: $localize`:@@FCT.PROVINCE.NS:Nova Scotia`,
  [FCTProvince.NT]: $localize`:@@FCT.PROVINCE.NT:Northwest Territories`,
  [FCTProvince.NU]: $localize`:@@FCT.PROVINCE.NU:Nunavut`,
  [FCTProvince.ON]: $localize`:@@FCT.PROVINCE.ON:Ontario`,
  [FCTProvince.PE]: $localize`:@@FCT.PROVINCE.PE:Prince Edward Island`,
  [FCTProvince.QC]: $localize`:@@FCT.PROVINCE.QC:Quebec`,
  [FCTProvince.SK]: $localize`:@@FCT.PROVINCE.SK:Saskatchewan`,
  [FCTProvince.YT]: $localize`:@@FCT.PROVINCE.YT:Yukon`,
};

export const FCTRentalTypeRecord: Record<FCTRentalType, string> = {
  [FCTRentalType.ENTIRE_DWELLING]: $localize`:@@FCT.RENTAL_TYPE.ENTIRE_DWELLING:Entire Dwelling`,
  [FCTRentalType.BASEMENT_ONLY]: $localize`:@@FCT.RENTAL_TYPE.BASEMENT_ONLY:Basement Only`,
};

export const FCTRequestedAppointmentTimeRecord: Record<FCTRequestedAppointmentTime, string> = {
  [FCTRequestedAppointmentTime.AM_8_AM_11]: $localize`:@@FCT.REQUESTED_APPOINTMENT_TIME.AM_8_AM_11:8:00 AM - 11:00 AM`,
  [FCTRequestedAppointmentTime.AM_11_PM_2]: $localize`:@@FCT.REQUESTED_APPOINTMENT_TIME.AM_11_PM_2:11:00 AM - 2:00 PM`,
  [FCTRequestedAppointmentTime.PM_2_PM_5]: $localize`:@@FCT.REQUESTED_APPOINTMENT_TIME.PM_2_PM_5:2:00 PM - 5:00 PM`,
  [FCTRequestedAppointmentTime.OTHER]: $localize`:@@FCT.REQUESTED_APPOINTMENT_TIME.OTHER:Other`,
};

export const FCTRequestStatusRecord: Record<FCTRequestStatus, string> = {
  [FCTRequestStatus.IN_PROCESS]: $localize`:@@FCT.REQUEST_STATUS.IN_PROCESS:In Process`,
  [FCTRequestStatus.READY]: $localize`:@@FCT.REQUEST_STATUS.READY:Ready`,
  [FCTRequestStatus.DELIVERED]: $localize`:@@FCT.REQUEST_STATUS.DELIVERED:Delivered`,
};

export const FCTSaleTypeRecord: Record<FCTSaleType, string> = {
  [FCTSaleType.MLS]: $localize`:@@FCT.SALE_TYPE.MLS:MLS`,
  [FCTSaleType.NEW_CONSTRUCTION]: $localize`:@@FCT.SALE_TYPE.NEW_CONSTRUCTION:New Construction`,
  [FCTSaleType.PRIVATE]: $localize`:@@FCT.SALE_TYPE.PRIVATE:Private`,
};

export const FCTSecondaryServiceTypeRecord: Record<FCTSecondaryServiceType, string> = {
  [FCTSecondaryServiceType.SINGLE_FAMILY_DWELLING_DESKTOP]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.SINGLE_FAMILY_DWELLING_DESKTOP:Single Family Dwelling Desktop`,
  [FCTSecondaryServiceType.DUPLEX_DESKTOP]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.DUPLEX_DESKTOP:Duplex Desktop`,
  [FCTSecondaryServiceType.TRIPLEX_DESKTOP]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.TRIPLEX_DESKTOP:Triplex Desktop`,
  [FCTSecondaryServiceType.FOUR_UNIT_DESKTOP]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.FOUR_UNIT_DESKTOP:Four Unit Desktop`,
  [FCTSecondaryServiceType.VACANT_LAND_DESKTOP]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.VACANT_LAND_DESKTOP:Vacant Land Desktop`,
  [FCTSecondaryServiceType.SINGLE_FAMILY_DWELLING_DRIVE_BY]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.SINGLE_FAMILY_DWELLING_DRIVE_BY:Single Family Dwelling Drive By`,
  [FCTSecondaryServiceType.DUPLEX_DRIVE_BY]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.DUPLEX_DRIVE_BY:Duplex Drive By`,
  [FCTSecondaryServiceType.TRIPLEX_DRIVE_BY]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.TRIPLEX_DRIVE_BY:Triplex Drive By`,
  [FCTSecondaryServiceType.FOUR_UNIT_DRIVE_BY]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.FOUR_UNIT_DRIVE_BY:Four Unit Drive By`,
  [FCTSecondaryServiceType.VACANT_LAND_DRIVE_BY]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.VACANT_LAND_DRIVE_BY:Vacant Land Drive By`,
  [FCTSecondaryServiceType.EXECUTIVE_PROPERTY_DRIVE_BY]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.EXECUTIVE_PROPERTY_DRIVE_BY:Executive Property Drive By`,
  [FCTSecondaryServiceType.FORECLOSURE_DRIVE_BY]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.FORECLOSURE_DRIVE_BY:Foreclosure Drive By`,
  [FCTSecondaryServiceType.SINGLE_FAMILY_DWELLING_FULL_APPRAISAL]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.SINGLE_FAMILY_DWELLING_FULL_APPRAISAL:Single Family Dwelling Full Appraisal`,
  [FCTSecondaryServiceType.DUPLEX]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.DUPLEX:Duplex`,
  [FCTSecondaryServiceType.TRIPLEX]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.TRIPLEX:Triplex`,
  [FCTSecondaryServiceType.FOUR_UNIT_FULL_APPRAISAL]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.FOUR_UNIT_FULL_APPRAISAL:Four Unit Full Appraisal`,
  [FCTSecondaryServiceType.VACANT_LAND_FULL_APPRAISAL]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.VACANT_LAND_FULL_APPRAISAL:Vacant Land Full Appraisal`,
  [FCTSecondaryServiceType.EXECUTIVE_PROPERTY_FULL_APPRAISAL]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.EXECUTIVE_PROPERTY_FULL_APPRAISAL:Executive Property Full Appraisal`,
  [FCTSecondaryServiceType.FORECLOSURE_FULL_APPRAISAL]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.FORECLOSURE_FULL_APPRAISAL:Foreclosure Full Appraisal`,
  [FCTSecondaryServiceType.PROGRESS_INSPECTION]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.PROGRESS_INSPECTION:Progress Inspection`,
  [FCTSecondaryServiceType.MARKET_RENT]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.MARKET_RENT:Market Rent`,
  [FCTSecondaryServiceType.INSURED_AVM]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.INSURED_AVM:Insured AVM`,
  [FCTSecondaryServiceType.RETRO_DRIVE_BY]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.RETRO_DRIVE_BY:Retro Drive By`,
  [FCTSecondaryServiceType.RETRO_DESKTOP]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.RETRO_DESKTOP:Retro Desktop`,
  [FCTSecondaryServiceType.APPRAISAL_ASSISTED_PRODUCT_IAVM]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.APPRAISAL_ASSISTED_PRODUCT_IAVM:Appraisal Assisted Product (IAVM)`,
  [FCTSecondaryServiceType.AVM]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.AVM:AVM`,
  [FCTSecondaryServiceType.INSURED_AVM_2]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.INSURED_AVM_2:Insured AVM`,
  [FCTSecondaryServiceType.FLEX]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.FLEX:Flex`,
  [FCTSecondaryServiceType.AAP_DRIVE_BY]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.AAP_DRIVE_BY:AAP Drive By`,
  [FCTSecondaryServiceType.AAP_DESKTOP]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.AAP_DESKTOP:AAP Desktop`,
  [FCTSecondaryServiceType.AAP_FULL_APPRAISAL]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.AAP_FULL_APPRAISAL:AAP Full Appraisal`,
  [FCTSecondaryServiceType.UNINSURED_FLEX]: $localize`:@@FCT.SECONDARY_SERVICE_TYPE.UNINSURED_FLEX:Uninsured Flex`,
};

export const FCTServiceTypeRecord: Record<FCTServiceType, string> = {
  [FCTServiceType.NVIP]: $localize`:@@FCT.SERVICE_TYPE.NVIP:NVIP`,
};

export const FCTStreetDirectionRecord: Record<FCTStreetDirection, string> = {
  [FCTStreetDirection.EAST]: $localize`:@@FCT.STREET_DIRECTION.EAST:East`,
  [FCTStreetDirection.NORTH]: $localize`:@@FCT.STREET_DIRECTION.NORTH:North`,
  [FCTStreetDirection.NORTHEAST]: $localize`:@@FCT.STREET_DIRECTION.NORTHEAST:North East`,
  [FCTStreetDirection.NORTHWEST]: $localize`:@@FCT.STREET_DIRECTION.NORTHWEST:North West`,
  [FCTStreetDirection.SOUTH]: $localize`:@@FCT.STREET_DIRECTION.SOUTH:South`,
  [FCTStreetDirection.SOUTHEAST]: $localize`:@@FCT.STREET_DIRECTION.SOUTHEAST:South East`,
  [FCTStreetDirection.SOUTHWEST]: $localize`:@@FCT.STREET_DIRECTION.SOUTHWEST:South West`,
  [FCTStreetDirection.WEST]: $localize`:@@FCT.STREET_DIRECTION.WEST:West`,
};

export const FCTStreetTypeRecord: Record<FCTStreetType, string> = {
  [FCTStreetType.Abbey]: `Abbey`,
  [FCTStreetType.Acres]: `Acres`,
  [FCTStreetType.Alley]: `Alley`,
  [FCTStreetType.Autoroute]: `Autoroute`,
  [FCTStreetType.Avenue]: `Avenue`,
  [FCTStreetType.Bay]: `Bay`,
  [FCTStreetType.Beach]: `Beach`,
  [FCTStreetType.Bend]: `Bend`,
  [FCTStreetType.Boulevard]: `Boulevard`,
  [FCTStreetType.ByPass]: `ByPass`,
  [FCTStreetType.Byway]: `Byway`,
  [FCTStreetType.Campus]: `Campus`,
  [FCTStreetType.Cape]: `Cape`,
  [FCTStreetType.Carrefour]: `Carrefour`,
  [FCTStreetType.CulDeSac]: `Cul-de-Sac`,
  [FCTStreetType.Cercle]: `Cercle`,
  [FCTStreetType.Chemin]: `Chemin`,
  [FCTStreetType.Chase]: `Chase`,
  [FCTStreetType.Circuit]: `Circuit`,
  [FCTStreetType.Close]: `Close`,
  [FCTStreetType.Common]: `Common`,
  [FCTStreetType.Concession]: `Concession`,
  [FCTStreetType.Cour]: `Cour`,
  [FCTStreetType.Cours]: `Cours`,
  [FCTStreetType.Cove]: `Cove`,
  [FCTStreetType.Crescent]: `Crescent`,
  [FCTStreetType.Corners]: `Corners`,
  [FCTStreetType.Croissant]: `Croissant`,
  [FCTStreetType.Crossing]: `Crossing`,
  [FCTStreetType.Court]: `Court`,
  [FCTStreetType.Centre]: `Centre`,
  [FCTStreetType.Dale]: `Dale`,
  [FCTStreetType.Dell]: `Dell`,
  [FCTStreetType.Diversion]: `Diversion`,
  [FCTStreetType.Downs]: `Downs`,
  [FCTStreetType.Drive]: `Drive`,
  [FCTStreetType.Echangeur]: `Echangeur`,
  [FCTStreetType.End]: `End`,
  [FCTStreetType.Esplanade]: `Esplanade`,
  [FCTStreetType.Estates]: `Estates`,
  [FCTStreetType.Expressway]: `Expressway`,
  [FCTStreetType.Extension]: `Extension`,
  [FCTStreetType.Farm]: `Farm`,
  [FCTStreetType.Field]: `Field`,
  [FCTStreetType.Forest]: `Forest`,
  [FCTStreetType.Front]: `Front`,
  [FCTStreetType.Freeway]: `Freeway`,
  [FCTStreetType.Gate]: `Gate`,
  [FCTStreetType.Gardens]: `Gardens`,
  [FCTStreetType.Glade]: `Glade`,
  [FCTStreetType.Glen]: `Glen`,
  [FCTStreetType.Green]: `Green`,
  [FCTStreetType.Grounds]: `Grounds`,
  [FCTStreetType.Grove]: `Grove`,
  [FCTStreetType.Harbour]: `Harbour`,
  [FCTStreetType.Heath]: `Heath`,
  [FCTStreetType.Highlands]: `Highlands`,
  [FCTStreetType.Hill]: `Hill`,
  [FCTStreetType.Hollow]: `Hollow`,
  [FCTStreetType.Heights]: `Heights`,
  [FCTStreetType.Highway]: `Highway`,
  [FCTStreetType.Ile]: `Ile`,
  [FCTStreetType.Impasse]: `Impasse`,
  [FCTStreetType.Inlet]: `Inlet`,
  [FCTStreetType.Island]: `Island`,
  [FCTStreetType.Key]: `Key`,
  [FCTStreetType.Knoll]: `Knoll`,
  [FCTStreetType.Landing]: `Landing`,
  [FCTStreetType.Lane]: `Lane`,
  [FCTStreetType.Line]: `Line`,
  [FCTStreetType.Link]: `Link`,
  [FCTStreetType.Lookout]: `Lookout`,
  [FCTStreetType.Limits]: `Limits`,
  [FCTStreetType.Loop]: `Loop`,
  [FCTStreetType.Mall]: `Mall`,
  [FCTStreetType.Manor]: `Manor`,
  [FCTStreetType.Maze]: `Maze`,
  [FCTStreetType.Meadow]: `Meadow`,
  [FCTStreetType.Mews]: `Mews`,
  [FCTStreetType.Montee]: `Montee`,
  [FCTStreetType.Moor]: `Moor`,
  [FCTStreetType.Mount]: `Mount`,
  [FCTStreetType.Mountain]: `Mountain`,
  [FCTStreetType.Orchard]: `Orchard`,
  [FCTStreetType.Parade]: `Parade`,
  [FCTStreetType.Parc]: `Parc`,
  [FCTStreetType.Passage]: `Passage`,
  [FCTStreetType.Path]: `Path`,
  [FCTStreetType.Pines]: `Pines`,
  [FCTStreetType.Park]: `Park`,
  [FCTStreetType.Parkway]: `Parkway`,
  [FCTStreetType.Place]: `Place`,
  [FCTStreetType.Plateau]: `Plateau`,
  [FCTStreetType.Plaza]: `Plaza`,
  [FCTStreetType.Pointe]: `Pointe`,
  [FCTStreetType.Port]: `Port`,
  [FCTStreetType.Promenade]: `Promenade`,
  [FCTStreetType.Point]: `Point`,
  [FCTStreetType.Pathway]: `Pathway`,
  [FCTStreetType.Private]: `Private`,
  [FCTStreetType.Quay]: `Quay`,
  [FCTStreetType.Ramp]: `Ramp`,
  [FCTStreetType.Rang]: `Rang`,
  [FCTStreetType.Road]: `Road`,
  [FCTStreetType.RondPoint]: `Rond Point`,
  [FCTStreetType.Range]: `Range`,
  [FCTStreetType.Rue]: `Rue`,
  [FCTStreetType.Route]: `Route`,
  [FCTStreetType.Ridge]: `Ridge`,
  [FCTStreetType.Rise]: `Rise`,
  [FCTStreetType.Row]: `Row`,
  [FCTStreetType.Run]: `Run`,
  [FCTStreetType.Sentier]: `Sentier`,
  [FCTStreetType.Square]: `Square`,
  [FCTStreetType.Street]: `Street`,
  [FCTStreetType.Terrace]: `Terrace`,
  [FCTStreetType.Townline]: `Townline`,
  [FCTStreetType.Towers]: `Towers`,
  [FCTStreetType.Trail]: `Trail`,
  [FCTStreetType.Turnabout]: `Turnabout`,
  [FCTStreetType.View]: `View`,
  [FCTStreetType.Village]: `Village`,
  [FCTStreetType.Villas]: `Villas`,
  [FCTStreetType.Vista]: `Vista`,
  [FCTStreetType.Voie]: `Voie`,
  [FCTStreetType.Walk]: `Walk`,
  [FCTStreetType.Way]: `Way`,
  [FCTStreetType.Wood]: `Wood`,
  [FCTStreetType.Ruelle]: `Ruelle`,
  [FCTStreetType.Subdivision]: `Subdivision`,
  [FCTStreetType.Thicket]: `Thicket`,
  [FCTStreetType.Terrasse]: `Terrasse`,
  [FCTStreetType.Vale]: `Vale`,
  [FCTStreetType.Via]: `Via`,
  [FCTStreetType.Wharf]: `Wharf`,
  [FCTStreetType.Wynd]: `Wynd`,
  [FCTStreetType.Circle]: `Circle`,
  [FCTStreetType.Cote]: `Cote`,
  [FCTStreetType.Carré]: `Carré`,
  [FCTStreetType.Creek]: `Creek`,
};

export const FCTEnumRecords: Record<FCTEnums, Record<string, string>> = {
  [FCTEnums.FCTAddOnProduct]: FCTAddOnProductRecord,
  [FCTEnums.FCTBorrowerType]: FCTBorrowerTypeRecord,
  [FCTEnums.FCTDealStatus]: FCTDealStatusRecord,
  [FCTEnums.FCTInsuranceStatus]: FCTInsuranceStatusRecord,
  [FCTEnums.FCTLoanPriority]: FCTLoanPriorityRecord,
  [FCTEnums.FCTLoanPurpose]: FCTLoanPurposeRecord,
  [FCTEnums.FCTNoteRecipient]: FCTNoteRecipientRecord,
  [FCTEnums.FCTNotesStatus]: FCTNotesStatusRecord,
  [FCTEnums.FCTOccupancyType]: FCTOccupancyTypeRecord,
  [FCTEnums.FCTPropertyInspectionContactType]: FCTPropertyInspectionContactTypeRecord,
  [FCTEnums.FCTPropertyType]: FCTPropertyTypeRecord,
  [FCTEnums.FCTPropertyUse]: FCTPropertyUseRecord,
  [FCTEnums.FCTProvince]: FCTProvinceRecord,
  [FCTEnums.FCTRentalType]: FCTRentalTypeRecord,
  [FCTEnums.FCTRequestedAppointmentTime]: FCTRequestedAppointmentTimeRecord,
  [FCTEnums.FCTRequestStatus]: FCTRequestStatusRecord,
  [FCTEnums.FCTSaleType]: FCTSaleTypeRecord,
  [FCTEnums.FCTSecondaryServiceType]: FCTSecondaryServiceTypeRecord,
  [FCTEnums.FCTServiceType]: FCTServiceTypeRecord,
  [FCTEnums.FCTStreetDirection]: FCTStreetDirectionRecord,
  [FCTEnums.FCTStreetType]: FCTStreetTypeRecord,
};
