import { PrivateDMDocument, PrivateDMDocumentStatusUpdate } from '@fundmoreai/models';
import { ApplicationDocumentToDocumentType } from '../../../features/shared/documents/model';
import { CreatePrivateDMDocumentCommentDto } from '../private-dm-documents.state';
import { DMDocument } from '../../../shared';

export class FetchPrivateDMDocuments {
  static readonly type = '@privateDMDocuments.FetchPrivateDMDocuments';
  constructor(public applicationId: string) {}
}

export class FetchPrivateDMDocumentComments {
  static readonly type = '@privateDMDocuments.FetchPrivateDMDocumentComments';
  constructor(public privateDMDocumentFileId: string) {}
}

export class CreatePrivateDMDocumentFiles {
  static readonly type = '@privateDMDocuments.CreatePrivateDMDocumentFiles';
  constructor(
    public applicationId: string,
    public stakeholderId: string,
    public applicationDocumentToDocumentType: ApplicationDocumentToDocumentType[],
    public stage?: string,
  ) {}
}

export class DeletePrivateDMDocument {
  static readonly type = '@privateDMDocuments.DeletePrivateDMDocument';
  constructor(public privateDMDocumentId: string, public stakeholderId: string) {}
}

export class UpdatePrivateDMDocumentFileDetails {
  static readonly type = '@privateDMDocuments.UpdatePrivateDMDocumentFileDetails';
  constructor(
    public privateDMDocumentId: string,
    public applicationId: string,
    public documentUpdates: PrivateDMDocument,
  ) {}
}

export class ReviewPrivateDMDocumentFile {
  static readonly type = '@privateDMDocuments.UpdatePrivateDMDocumentFileStatus';
  constructor(
    public selectedConditionId: string | null,
    public stakeholderId: string,
    public privateDMDocumentId: string,
    public applicationId: string,
    public documentUpdates: PrivateDMDocumentStatusUpdate,
  ) {}
}

export class CreatePrivateDMDocumentFileComment {
  static readonly type = '@privateDMDocuments.CreatePrivateDMDocumentFileComment';
  constructor(public commentDTO: CreatePrivateDMDocumentCommentDto) {}
}

export class SetPrivateDMDocumentReview {
  static readonly type = '@privateDMDocument.SetPrivateDMDocumentReview';
  constructor(public documentId: string | null, public defaultChat = false) {}
}

export class MarkPrivateDMDocumentCommentAsSeen {
  static readonly type = '@privateDMDocument.MarkPrivateDMDocumentCommentAsSeen';
  constructor(
    public privateDMDocumentFileStakeholderId: string,
    public privateDMDocumentFileId: string,
    public privateDMDocumentFileCommentId: string,
  ) {}
}

export class SharePrivateDocument {
  static readonly type = '@privateDMDocument.SharePrivateDocument';
  constructor(
    public applicationId: string,
    public document: PrivateDMDocument,
    public category: DMDocument,
  ) {}
}

export class AttachPrivateDMDocument {
  static readonly type = '@privateDMDocument.AttachPrivateDMDocument';
  constructor(public document: PrivateDMDocument) {}
}
