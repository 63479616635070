/* eslint-disable max-classes-per-file */
export class LenderConfig {
  lenders: Lender[];
}

export class Lender {
  name: string;
  product: string;
  type: LenderType;
}

export enum LenderType {
  Prime = 'Prime',
  SubPrime = 'Sub Prime',
  Specialty = 'Specialty',
  CreditUnion = 'Credit Union',
  MIC = 'MIC',
}
