export interface EmbedConfig {
  reportsDetail: any;
  embedToken: any;
}

export interface PowerBiReportDetails {
  reportId: string;
  reportName: string;
  embedUrl: string;
}
