// Computes the total monthly debt based on existing liabilities
// Input: all the existing liabilities for an application
// Output: monthly debt for an application. Used for computing TDS
// This should also take into consideration Joint Debts. These start with J*.
// A Joint Debt should appear on both applicants's credit reports. They should have the same description
// If a Joint Debt appears twice, it should not be counted towards the computation of monthly debt

import { Applicant, FinancialLiability, PayoffPaydownType } from '@fundmoreai/models';
import { computeTotalRentFromApplicantsAddresses } from './address-rent.calculator';

type ComputeDebtLiabilityInput = Pick<
  FinancialLiability,
  'description' | 'payoffPaydown' | 'monthlyPayment' | 'liability' | 'excludeFromCalculation'
>;

export function filterLiabilities(
  financialLiabilities: ComputeDebtLiabilityInput[],
): ComputeDebtLiabilityInput[] {
  const distinctFinancialLiabilities: ComputeDebtLiabilityInput[] = [];
  const map = new Map();
  if (financialLiabilities) {
    for (const item of financialLiabilities) {
      const description = item.description?.replace(/\s/g, '');
      const isJointDebt = description?.startsWith('J*');
      if (!isJointDebt || !map.has(description)) {
        map.set(description, true);
        distinctFinancialLiabilities.push(item);
      }
    }
  }

  return distinctFinancialLiabilities.filter(
    (liability) => liability.payoffPaydown !== PayoffPaydownType.PAYOFF,
  );
}

export function computeLiabilitiesDebt(financialLiabilities: ComputeDebtLiabilityInput[]): number {
  return filterLiabilities(financialLiabilities)
    .filter((fl) => !fl.excludeFromCalculation)
    .reduce((sum, liability) => {
      return sum + (liability.monthlyPayment ? liability.monthlyPayment : 0.0);
    }, 0.0);
}

export function computeDebt(
  financialLiabilities: ComputeDebtLiabilityInput[],
  applicants: Partial<Applicant>[],
): number {
  const totalRent = computeTotalRentFromApplicantsAddresses(applicants);
  const liabilitiesDebt = computeLiabilitiesDebt(financialLiabilities);

  return totalRent + liabilitiesDebt;
}
