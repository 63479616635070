import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FlipCost } from '../../shared/model';

@Injectable({
  providedIn: 'root',
})
export class FlipCostService {
  constructor(private http: HttpClient) {}

  updateFlipCost(flipCostId: string, flipCostPayload: Partial<FlipCost>) {
    return this.http.patch<void>(`${environment.api_url}/flipCosts/${flipCostId}`, flipCostPayload);
  }

  createFlipCost(flipCostPayload: Partial<FlipCost>) {
    return this.http.post<FlipCost>(`${environment.api_url}/flipCosts/`, flipCostPayload);
  }

  deleteFlipCost(flipCostId: string) {
    return this.http.delete<void>(`${environment.api_url}/flipCosts/${flipCostId}`);
  }
}
