import { DwellingType } from '../../enums';

export enum FCTPropertyType {
  CONDO_UNIT = '001',
  DUPLEX = '002',
  FOURPLEX = '003',
  MOBILE_MODULAR = '004',
  TOWNHOME = '006',
  SEMI_DETACHED = '007',
  SINGLE = '008',
  STACKED = '009',
  TRIPLEX = '010',
}

export const fctDwellingTypeToPropertyTypeMap: Record<DwellingType, FCTPropertyType | undefined> = {
  [DwellingType.DETACHED]: FCTPropertyType.SINGLE,
  [DwellingType.SEMI_DETACHED]: FCTPropertyType.SEMI_DETACHED,
  [DwellingType.DUPLEX_DETACHED]: FCTPropertyType.DUPLEX,
  [DwellingType.DUPLEX_SEMI_DETACHED]: FCTPropertyType.DUPLEX,
  [DwellingType.DUPLEX]: FCTPropertyType.DUPLEX,
  [DwellingType.ROW_HOUSING]: FCTPropertyType.TOWNHOME,
  [DwellingType.APARTMENT_LOW_RISE]: FCTPropertyType.CONDO_UNIT,
  [DwellingType.APARTMENT_HIGH_RISE]: FCTPropertyType.CONDO_UNIT,
  [DwellingType.APARTMENT]: FCTPropertyType.CONDO_UNIT,
  [DwellingType.TOWNHOUSE]: FCTPropertyType.TOWNHOME,
  [DwellingType.STRIP]: undefined,
  [DwellingType.HIGH_RISE]: FCTPropertyType.CONDO_UNIT,
  [DwellingType.STACKED]: FCTPropertyType.STACKED,
  [DwellingType.MOBILE]: FCTPropertyType.MOBILE_MODULAR,
  [DwellingType.TRIPLEX_DETACHED]: FCTPropertyType.TRIPLEX,
  [DwellingType.TRIPLEX_SEMI_DETACHED]: FCTPropertyType.TRIPLEX,
  [DwellingType.TRIPLEX]: FCTPropertyType.TRIPLEX,
  [DwellingType.FOURPLEX_DETACHED]: FCTPropertyType.FOURPLEX,
  [DwellingType.FOURPLEX_SEMI_DETACHED]: FCTPropertyType.FOURPLEX,
  [DwellingType.FOURPLEX]: FCTPropertyType.FOURPLEX,
  [DwellingType.FIVEPLEX]: undefined,
  [DwellingType.SIXPLEX]: undefined,
  [DwellingType.MODULAR_HOME_DETACHED]: FCTPropertyType.MOBILE_MODULAR,
  [DwellingType.MODULAR_HOME_SEMI_DETACHED]: FCTPropertyType.MOBILE_MODULAR,
  [DwellingType.MODULAR_HOME]: FCTPropertyType.MOBILE_MODULAR,
  [DwellingType.COOP]: undefined,
  [DwellingType.SINGLE]: FCTPropertyType.SINGLE,
  [DwellingType.CONDOMINIUM]: FCTPropertyType.CONDO_UNIT,
  [DwellingType.OTHER]: undefined,
};
