import { Condition } from '../../shared/documents';

export class AddCondition {
  static readonly type = '@condition.AddCondition';

  constructor(public condition: Partial<Condition>) {}
}

export class DeleteCondition {
  static readonly type = '@condition.DeleteCondition';

  constructor(public conditionId: string) {}
}

export class FetchConditions {
  static readonly type = '@condition.FetchConditions';
}

export class UpdateCondition {
  static readonly type = '@condition.UpdateCondition';

  constructor(public condition: Partial<Condition>) {}
}

export class UpdateConditionIndex {
  static readonly type = '@condition.UpdateConditionIndex';

  constructor(public conditionId: string, public index: number | null) {}
}

export class PublishCondition {
  static readonly type = '@condition.PublishCondition';

  constructor(public conditionId: string) {}
}

export class InactivateCondition {
  static readonly type = '@condition.InactivateCondition';

  constructor(public conditionId: string) {}
}

export class AddAndPublishCondition {
  static readonly type = '@condition.AddAndPublishCondition';

  constructor(public condition: Partial<Condition>) {}
}
