import { ApplicationConditionStatus } from '@fundmoreai/models';
import { ApplicationCondition, Condition } from '../../../shared/documents';

export interface ApplicationConditionStateModel {
  applicationConditions?: { [key: string]: ApplicationCondition };
  conditions: Condition[];
}

export class GetAllConditions {
  static readonly type = '@applicationConditions.GetAllConditions';
  constructor(public applicationId: string) {}
}

export class GetAllApplicationConditions {
  static readonly type = '@applicationConditions.GetAllApplicationConditions';
  constructor(public applicationId: string) {}
}

export class DeleteApplicationCondition {
  static readonly type = '@applicationConditions.DeleteApplicationCondition';
  constructor(public applicationConditionId: string) {}
}

export class UpdateApplicationConditionStatus {
  static readonly type = '@applicationConditions.UpdateApplicationConditionStatus';
  constructor(public applicationConditionId: string, public status: ApplicationConditionStatus) {}
}

export class AddApplicationConditions {
  static readonly type = '@applicationConditions.AddApplicationConditions';
  constructor(
    public applicationId: string,
    public stakeholderId: string,
    public conditionIds: string[],
  ) {}
}

export class AddCustomApplicationCondition {
  static readonly type = '@applicationCondition.AddCustomApplicationCondition';
  constructor(
    public condition: Partial<Condition>,
    public applicationId: string,
    public stakeholderId: string,
  ) {}
}

export class SaveApplicationCondition {
  static readonly type = '@applicationCondition.SaveApplicationCondition';
  constructor(
    public condition: Partial<Condition>,
    public status: ApplicationConditionStatus | null,
    public applicationConditionId: string,
    public applicationId: string,
    public stakeholderId: string,
  ) {}
}
