import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { CompanySettingsService } from '../../shared/services/company-settings.service';
import { TinyColor } from '@ctrl/tinycolor';
import { COMPANY_SETTINGS } from './company-default';
import { LoadingStart, LoadingEnd } from '../loading.state';

export class CompanySettingsLoaded {
  static readonly type = '@companySettings.CompanySettingsLoaded';
  constructor(public companySettings: CompanySettingsModel) {}
}

export class CompanySettingsResetLogos {
  static readonly type = '@companySettings.CompanySettingsResetLogos';
}

export class CompanySettingsResetIqLogos {
  static readonly type = '@companySettings.CompanySettingsResetIqLogos';
}

export class CompanySettingsResetStyle {
  static readonly type = '@companySettings.CompanySettingsResetStyle';
}

export class CompanySettingsResetIqStyle {
  static readonly type = '@companySettings.CompanySettingsResetIqStyle';
}

export class CompanyInfoModel {
  address: string;
  email: string;
  license: string;
  name: string;
  phone: string;
  websiteUrl: string;
}

export class CompanyStyleModel {
  primaryColor: string;
  secondaryColor: string;
  successColor: string;
  reviewColor: string;
  warnColor: string;
}

export class CompanyStylePaletteModel {
  primaryColorPalette: MaterialColor[];
  secondaryColorPalette: MaterialColor[];
  successColorPalette: MaterialColor[];
  reviewColorPalette: MaterialColor[];
  warnColorPalette: MaterialColor[];
}

export class CompanySettingsModel {
  version: number;
  company: CompanyInfoModel;
  style: CompanyStyleModel;
  stylePalette: CompanyStylePaletteModel;
  websiteFavicon: string;
  websiteLogo: string;
  iq?: {
    style?: CompanyStyleModel;
    websiteFavicon?: string;
    websiteLogo?: string;
  };
}

export interface MaterialColor {
  name: string;
  hex: string;
  darkContrast: boolean;
}

export class FetchAndSetCompanySettings {
  static readonly type = '@companySettings.fetchAndSetCompanySettings';
}

export class UpdateCompanySettings {
  static readonly type = '@companySettings.updateCompanySettings';
  constructor(public companySettings: Partial<CompanySettingsModel>) {}
}

type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

@State<CompanySettingsModel>({
  name: CompanySettingsState.NAME,
  defaults: COMPANY_SETTINGS,
})
@Injectable()
export class CompanySettingsState {
  static NAME = 'companySettings';

  @Selector() static companySettings(state: CompanySettingsModel) {
    return {
      ...state,
      stylePalette: {
        primaryColorPalette: this.computeColors(state.style.primaryColor),
        secondaryColorPalette: this.computeColors(state.style.secondaryColor),
        successColorPalette: this.computeColors(state.style.successColor),
        reviewColorPalette: this.computeColors(state.style.reviewColor),
        warnColorPalette: this.computeColors(state.style.warnColor),
      },
    };
  }

  @Selector() static websiteFavicon(state: CompanySettingsModel) {
    return state.websiteFavicon;
  }

  @Selector() static websiteLogo(state: CompanySettingsModel) {
    return state.websiteLogo;
  }

  constructor(private companySettingsService: CompanySettingsService) {}

  @Action(FetchAndSetCompanySettings) fetchCompanySettings(
    ctx: StateContext<CompanySettingsModel>,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.companySettingsService.getCompanySettings().pipe(
      tap((settings: CompanySettingsModel) => {
        ctx.patchState({
          ...settings,
          websiteFavicon: settings?.websiteFavicon || COMPANY_SETTINGS.websiteFavicon,
          websiteLogo: settings?.websiteLogo || COMPANY_SETTINGS.websiteLogo,
          company: { ...COMPANY_SETTINGS.company, ...settings?.company },
          style: { ...COMPANY_SETTINGS.style, ...settings?.style },
          iq: {
            style: { ...COMPANY_SETTINGS.style, ...settings?.iq?.style },
            websiteFavicon: settings?.iq?.websiteFavicon || COMPANY_SETTINGS.websiteFavicon,
            websiteLogo: settings?.iq?.websiteLogo || COMPANY_SETTINGS.websiteLogo,
          },
        });
        // apply company settings across app by implementing this action
        // TODO: implement this action when needed
        // ctx.dispatch(new CompanySettingsLoaded(settings));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateCompanySettings) updateCompanySettings(
    ctx: StateContext<CompanySettingsModel>,
    action: UpdateCompanySettings,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();
    const updateSettings: Partial<CompanySettingsModel> = {
      ...state,
      ...action.companySettings,
      iq: {
        ...state.iq,
        ...(action.companySettings?.iq ?? {}),
      },
      stylePalette: undefined,
      version: undefined,
    };

    this.removeDefaultValuesFromUpdate(updateSettings);

    return this.companySettingsService.updateCompanySettings(updateSettings).pipe(
      tap(() => {
        ctx.patchState({
          ...state,
          ...action.companySettings,
          iq: { ...state.iq, ...(action.companySettings?.iq ?? {}) },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  private removeDefaultValuesFromUpdate(updateSettings: DeepPartial<CompanySettingsModel>) {
    if (updateSettings.websiteFavicon === COMPANY_SETTINGS.websiteFavicon) {
      delete updateSettings.websiteFavicon;
    }
    if (updateSettings.websiteLogo === COMPANY_SETTINGS.websiteLogo) {
      delete updateSettings.websiteLogo;
    }

    if (updateSettings.company) {
      updateSettings.company = { ...updateSettings.company };
      if (updateSettings.company.address === COMPANY_SETTINGS.company.address) {
        delete updateSettings.company.address;
      }
      if (updateSettings.company.email === COMPANY_SETTINGS.company.email) {
        delete updateSettings.company.email;
      }
      if (updateSettings.company.license === COMPANY_SETTINGS.company.license) {
        delete updateSettings.company.license;
      }
      if (updateSettings.company.name === COMPANY_SETTINGS.company.name) {
        delete updateSettings.company.name;
      }
      if (updateSettings.company.phone === COMPANY_SETTINGS.company.phone) {
        delete updateSettings.company.phone;
      }
      if (updateSettings.company.websiteUrl === COMPANY_SETTINGS.company.websiteUrl) {
        delete updateSettings.company.websiteUrl;
      }
      if (Object.keys(updateSettings.company).length === 0) {
        delete updateSettings.company;
      }
    }
    if (updateSettings.style) {
      updateSettings.style = { ...updateSettings.style };
      if (updateSettings.style.primaryColor === COMPANY_SETTINGS.style.primaryColor) {
        delete updateSettings.style.primaryColor;
      }
      if (updateSettings.style.secondaryColor === COMPANY_SETTINGS.style.secondaryColor) {
        delete updateSettings.style.secondaryColor;
      }
      if (updateSettings.style.successColor === COMPANY_SETTINGS.style.successColor) {
        delete updateSettings.style.successColor;
      }
      if (updateSettings.style.reviewColor === COMPANY_SETTINGS.style.reviewColor) {
        delete updateSettings.style.reviewColor;
      }
      if (updateSettings.style.warnColor === COMPANY_SETTINGS.style.warnColor) {
        delete updateSettings.style.warnColor;
      }
      if (Object.keys(updateSettings.style).length === 0) {
        delete updateSettings.style;
      }
    }

    if (updateSettings.iq?.websiteFavicon === COMPANY_SETTINGS.websiteFavicon) {
      delete updateSettings.iq.websiteFavicon;
    }
    if (updateSettings.iq?.websiteLogo === COMPANY_SETTINGS.websiteLogo) {
      delete updateSettings.iq.websiteLogo;
    }

    if (updateSettings.iq?.style) {
      updateSettings.iq.style = { ...updateSettings.iq.style };
      if (updateSettings.iq.style.primaryColor === COMPANY_SETTINGS.style.primaryColor) {
        delete updateSettings.iq.style.primaryColor;
      }
      if (updateSettings.iq.style.secondaryColor === COMPANY_SETTINGS.style.secondaryColor) {
        delete updateSettings.iq.style.secondaryColor;
      }
      if (updateSettings.iq.style.successColor === COMPANY_SETTINGS.style.successColor) {
        delete updateSettings.iq.style.successColor;
      }
      if (updateSettings.iq.style.reviewColor === COMPANY_SETTINGS.style.reviewColor) {
        delete updateSettings.iq.style.reviewColor;
      }
      if (updateSettings.iq.style.warnColor === COMPANY_SETTINGS.style.warnColor) {
        delete updateSettings.iq.style.warnColor;
      }
      if (Object.keys(updateSettings.iq.style).length === 0) {
        delete updateSettings.iq.style;
      }
    }

    if (!updateSettings.iq || Object.keys(updateSettings.iq).length === 0) {
      delete updateSettings.iq;
    }
  }

  @Action(CompanySettingsResetLogos) resetLogosToDefault(ctx: StateContext<CompanySettingsModel>) {
    const logosReset = {
      websiteFavicon: COMPANY_SETTINGS.websiteFavicon,
      websiteLogo: COMPANY_SETTINGS.websiteLogo,
    };
    ctx.patchState(logosReset);
    ctx.dispatch(new UpdateCompanySettings(logosReset));
  }

  @Action(CompanySettingsResetIqLogos) resetIqLogosToDefault(
    ctx: StateContext<CompanySettingsModel>,
  ) {
    const state = ctx.getState();

    const logosReset = {
      ...state,
      iq: {
        style: state.iq?.style || COMPANY_SETTINGS.style,
        websiteFavicon: COMPANY_SETTINGS.websiteFavicon,
        websiteLogo: COMPANY_SETTINGS.websiteLogo,
      },
    };

    ctx.patchState(logosReset);

    ctx.dispatch(new UpdateCompanySettings(logosReset));
  }

  @Action(CompanySettingsResetIqStyle) resetIqStyleToDefault(
    ctx: StateContext<CompanySettingsModel>,
  ) {
    const state = ctx.getState();

    const styleReset = {
      ...state,
      iq: {
        style: COMPANY_SETTINGS.style,
        websiteFavicon: state.iq?.websiteFavicon || COMPANY_SETTINGS.websiteFavicon,
        websiteLogo: state.iq?.websiteLogo || COMPANY_SETTINGS.websiteLogo,
      },
    };

    ctx.patchState(styleReset);

    ctx.dispatch(new UpdateCompanySettings(styleReset));
  }

  @Action(CompanySettingsResetStyle) resetStyleToDefault(ctx: StateContext<CompanySettingsModel>) {
    const styleUpdate = {
      style: {
        warnColor: COMPANY_SETTINGS.style.warnColor,
        primaryColor: COMPANY_SETTINGS.style.primaryColor,
        secondaryColor: COMPANY_SETTINGS.style.secondaryColor,
        successColor: COMPANY_SETTINGS.style.successColor,
        reviewColor: COMPANY_SETTINGS.style.reviewColor,
      },
    };
    ctx.patchState(styleUpdate);
    ctx.dispatch(new UpdateCompanySettings(styleUpdate));
  }

  private static computeColors(hex: string): MaterialColor[] {
    return [
      this.getColorObject(new TinyColor(hex).lighten(52), '50'),
      this.getColorObject(new TinyColor(hex).lighten(37), '100'),
      this.getColorObject(new TinyColor(hex).lighten(26), '200'),
      this.getColorObject(new TinyColor(hex).lighten(12), '300'),
      this.getColorObject(new TinyColor(hex).lighten(6), '400'),
      this.getColorObject(new TinyColor(hex), '500'),
      this.getColorObject(new TinyColor(hex).darken(6), '600'),
      this.getColorObject(new TinyColor(hex).darken(12), '700'),
      this.getColorObject(new TinyColor(hex).darken(18), '800'),
      this.getColorObject(new TinyColor(hex).darken(24), '900'),
      this.getColorObject(new TinyColor(hex).lighten(50).saturate(30), 'A100'),
      this.getColorObject(new TinyColor(hex).lighten(30).saturate(30), 'A200'),
      this.getColorObject(new TinyColor(hex).lighten(10).saturate(15), 'A400'),
      this.getColorObject(new TinyColor(hex).lighten(5).saturate(5), 'A700'),
    ];
  }

  private static getColorObject(value: TinyColor, name: string): MaterialColor {
    const color = new TinyColor(value);

    return {
      name,
      hex: color.toHexString(),
      darkContrast: color.isLight(),
    };
  }
}
