/* eslint-disable max-classes-per-file */
import { FundmoreCalculator } from '@fundmoreai/calculator';
import {
  RepaymentType,
  PropertyAddressDetails,
  Province,
  Property,
  Mortgage,
  Fee,
  Applicant,
  MortgagePosition,
  StreetType,
  StreetDirection,
  FundmoreRecommendationType,
  FundmoreRecommendationModel,
  Country,
  RawGoogleGeocodeResponse,
  Application,
  Summary,
  FinancialLiability,
} from '@fundmoreai/models';
import {
  isInGTA,
  isInGVA,
  isWithinKmFromAMajorCity,
  isWithinKmFromCities,
} from '../helpers/location';
import { Lender, LenderType } from '../interfaces/lender.config';
import {
  isGentaiTier1,
  isGentaiTier3,
  isGentaiTier2,
  getPropertyTier,
} from '../helpers/Gentai/gentai-tiers';
import {
  isCalvertProvinceEligible,
  getAreaCalvert,
  getTownTypeCalvert,
  getHomeType,
  computeCalvertMarketSize,
  getDealType,
} from '../helpers/Calvert/calvert-helper';
import {
  isInApprovedPrimaryPostalCodes,
  isInApprovedSecondaryPostalCodes,
  isInRuralZone,
} from '../helpers/Rocket/rocket-helper';
import { Areas, DealTypes, HomeType, MarketSize, TownTypes } from '../helpers/Calvert/enum';

export enum MortgagePurpose {
  Purchase = 'Purchase',
  Refinance = 'Refinance',
  ETO = 'ETO',
  SwitchTransfer = 'Switch/Transfer',
  Unknown = 'Unknown',
}

export enum MortgageType {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
}

export enum EmploymentType {
  Employed = 'Employed',
  SelfEmployed = 'SelfEmployed',
  Commissioned = 'Commisioned',
}

export enum PaymentType {
  Hourly = 'Hourly',
  Salary = 'Salary',
}

export class EmployeeData {
  employmentType!: EmploymentType;
  timeAtJob!: number;
  paymentType!: PaymentType;
}

export class RenewalMortgage {
  originalPurchaseAmout!: number;
  originalPurchaseDate!: Date;
  originalAmortizationPeriod!: number;
}

export class ExtraApplicant {
  ficoScore!: number;
}

export class SecondMortgage {
  lender: string;
  loanAmount: number | null;
}

export class FundmoreScoreInput {
  context: {
    application: Partial<Application>;
    requestedMortgage: Partial<Mortgage>;
    locRequestedMortgage?: Partial<Mortgage> | undefined;
    primaryApplicant: Partial<Applicant>;
    summary: Partial<Summary>;
    primaryProperty: Partial<Property>;
    borrowers: Partial<Applicant>[];
    financialLiabilities: Partial<FinancialLiability>[];
    allApplicants?: Partial<Applicant>[];
  };
  mortgagePurpose?: MortgagePurpose | null;
  mortgageType: MortgagePosition | null;
  ficoScore: number | null;
  tds: number | null;
  gds: number | null;
  arv: number | undefined | null;
  landCosts: number | undefined | null;
  hardCosts: number | undefined | null;
  location: string;
  purchasePrice: number | null;
  repaymentType: RepaymentType | null;
  ownerOccupied: boolean | null;
  amortizationPeriod: number | null | undefined;
  numberOfUnits: number | null;
  employeeData: EmployeeData | null;
  renewalMortgage?: RenewalMortgage | null;
  extraApplicants?: ExtraApplicant[] | null;
  tenantId: string | null;
  tenantCode: string | null;
  lotSizeSqFt?: number | null;
  constructionType: string | null;
  secondMortgage?: SecondMortgage | null;
  propertyAddress: PropertyAddressDetails | null;

  primaryProperty: Property | null;
  existingMortgages: Mortgage[] | null;
  fees: Fee[] | null;
  requestedMortgage: Mortgage | null;
  capFeesMaxPercentage: number | undefined;
  termMonths: number | null;
  currentStage: string | null;
  totalMortgageAmount: number | null;
  netRate: number | null;
  downPayment: number | null | undefined;
  primaryApplicant: Applicant | null;
}

export class PropertyAddressEnhanced implements PropertyAddressDetails {
  id: string;
  propertyId: string;
  country: Country;
  address: string;
  formattedAddress: string;
  province: Province;
  city: string;
  postalCodePrefix: string;
  lat: number;
  lng: number;
  townType: TownTypes;
  area: Areas;
  marketSize: MarketSize;
  tenantCode: string;
  streetNumber: string;
  streetNumberSuffix: string;
  streetName: string;
  streetType: StreetType;
  streetDirection: StreetDirection;
  unit: string;
  unitType: string;
  rawGoogleGeocodeResponse: RawGoogleGeocodeResponse;
  postalCode: string;

  enhance() {
    if (this.tenantCode === 'CALVERT') {
      this.enhancePropertyAddressCalvert();
    }
    return this;
  }

  isGentaiTier1() {
    return isGentaiTier1(this.province, this.city);
  }

  isGentaiTier2() {
    return isGentaiTier2(this.province, this.city);
  }

  isGentaiTier3() {
    return isGentaiTier3(this.province, this.city);
  }

  isSupportedProvinceCalvert() {
    return isCalvertProvinceEligible(this.province);
  }

  isWithinKmFromAMajorCity(km: number) {
    return isWithinKmFromAMajorCity(this, km);
  }

  isWithinKmFromCities(km: number, cities: string[]) {
    return isWithinKmFromCities(this, km, cities);
  }

  isInGVA() {
    return isInGVA(this.city);
  }

  isInGTA() {
    return isInGTA(this.city);
  }

  enhancePropertyAddressCalvert() {
    this.townType = getTownTypeCalvert(this.province, this.city);
    this.area = getAreaCalvert(this.province, this.city, this.townType);
    this.marketSize = computeCalvertMarketSize(this);
  }
}
export class FundmoreScoreEnhancedInput extends FundmoreScoreInput {
  isInterestOnly: boolean;
  lotSizeAcres: number;
  isNewConstruction?: boolean;
  lenderLowerCase: string;
  sqFtToAcres = 43560;
  propertyAddressEnhanced: PropertyAddressEnhanced;
  ltv: number;
  gentaiSlidingScaleLTV: number;
  firstMortgage?: Mortgage;
  calvertHomeType?: HomeType;
  calvertDealType?: DealTypes;
  calvertMortgagePosition?: MortgagePosition;
  downPaymentPercentage: number;
  ARVPercentage: number;
  LCPercentage: number; // Land Costs Percentage
  HCPercentage: number; // Hard Costs Percentage

  // Rocket
  isInApprovedPrimaryZone: boolean = false;
  isInApprovedSecondaryZone: boolean = false;
  isInRuralZone: boolean = false;

  enhance() {
    this.isInterestOnly = this.repaymentType === RepaymentType.INTEREST_ONLY;
    this.lotSizeAcres = parseFloat((this.lotSizeSqFt / this.sqFtToAcres).toFixed(2));

    if (this.constructionType) {
      this.isNewConstruction = this.constructionType.toLowerCase().includes('new');
    }

    this.lenderLowerCase = this.secondMortgage?.lender.toLowerCase();
    this.propertyAddressEnhanced = Object.assign(
      new PropertyAddressEnhanced(),
      this.propertyAddress,
      { tenantCode: this.tenantCode },
    );

    this.ltv = parseFloat(
      FundmoreCalculator.computeLTVFromObjects(
        this.primaryProperty,
        this.existingMortgages,
        this.requestedMortgage ? [this.requestedMortgage] : [],
        this.fees,
        this.capFeesMaxPercentage,
      ).result.toFixed(2),
    );

    this.gentaiSlidingScaleLTV = parseFloat(
      FundmoreCalculator.computeGentaiSlidingScaleLTVFromObjects(
        getPropertyTier(this.propertyAddress?.province, this.propertyAddress?.city),
        this.primaryProperty,
        this.existingMortgages,
        this.requestedMortgage,
        this.fees,
        this.capFeesMaxPercentage,
      ).result.toFixed(2),
    );

    const exisitingFirstMortgages = this.existingMortgages?.filter((x) => {
      if (x.mortgageType) {
        return x.mortgageType === MortgagePosition.FIRST;
      }
      return false;
    });
    const firstMortgage = exisitingFirstMortgages?.find(
      (m) => m.propertyId === this.primaryProperty.id,
    );
    if (firstMortgage) {
      this.firstMortgage = firstMortgage;
    }

    if (this.tenantCode === 'CALVERT') {
      this.calvertHomeType = getHomeType(this.primaryProperty);
      this.calvertDealType = getDealType(this.requestedMortgage.productName); //TODO : Rewrite this, this won't contain the product name
      this.calvertMortgagePosition = getMortgagePosition(this.requestedMortgage.mortgageType);
      this.downPaymentPercentage = this.downPayment / this.requestedMortgage.loanAmount;
      if (this.arv && this.requestedMortgage.loanAmount) {
        this.ARVPercentage = round2Digits((this.requestedMortgage.loanAmount / this.arv) * 100);
      }
      if (this.hardCosts && this.requestedMortgage.loanAmount) {
        this.HCPercentage = round2Digits(
          (this.requestedMortgage.loanAmount / this.hardCosts) * 100,
        );
      }
      if (this.landCosts && this.requestedMortgage.loanAmount) {
        this.LCPercentage = round2Digits(
          (this.requestedMortgage.loanAmount / this.landCosts) * 100,
        );
      }
    }

    if (this.tenantCode.startsWith('RM')) {
      const postalCode = this.context?.primaryProperty?.propertyAddressExpanded?.postalCode;
      if (postalCode) {
        const parsedPostalCode = postalCode.toString().toUpperCase().replace(/\s/g, '');
        this.isInApprovedPrimaryZone = isInApprovedPrimaryPostalCodes(parsedPostalCode);
        this.isInApprovedSecondaryZone = isInApprovedSecondaryPostalCodes(parsedPostalCode);
        this.isInRuralZone = isInRuralZone(parsedPostalCode);
      }
    }
  }
}

function round2Digits(num: number) {
  return parseFloat(num.toFixed(2));
}

function getMortgagePosition(position: string): MortgagePosition {
  return position === MortgagePosition.FIRST
    ? MortgagePosition.FIRST
    : position === MortgagePosition.SECOND
    ? MortgagePosition.SECOND
    : position === MortgagePosition.THIRD
    ? MortgagePosition.THIRD
    : MortgagePosition.UNKNOWN;
}

export class FundmoreScoreContext {
  application: FundmoreScoreEnhancedInput;
  lenders: LenderContext;
  propertyLocation: PropertyAddressEnhanced;
}

export class LenderContext {
  all: string[];
  institutional: string[];
  constructor(lenders: Lender[]) {
    this.all = lenders.map((x) => x.name.toLowerCase());
    this.institutional = lenders
      .filter((x) => x.type === LenderType.Prime || x.type === LenderType.SubPrime)
      .map((x) => x.name.toLowerCase());
  }
}

export class FundmoreRecommendation implements FundmoreRecommendationModel {
  constructor({
    display,
    type,
    changeToNumber,
    changeToDate,
    changeToProductId,
    mortgageId,
  }: {
    display: string;
    type: FundmoreRecommendationType;
    changeToNumber?: number;
    changeToDate?: Date;
    changeToProductId?: string;
    mortgageId?: string;
  }) {
    this.display = display;
    this.type = type;
    this.changeToNumber = changeToNumber;
    this.changeToDate = changeToDate;
    this.changeToProductId = changeToProductId;
    this.mortgageId = mortgageId;
  }

  display: string;
  type: FundmoreRecommendationType;
  changeToNumber?: number;
  changeToDate?: Date;
  changeToProductId?: string;
  mortgageId?: string;
}
