import { RocketExtraConfig, ScoreConfig } from '../interfaces/score.config';
import { LenderConfig } from '../interfaces/lender.config';
import { CityConfig } from '../interfaces/city.config';

import defaultScoreConfig from '../configs/static/demo.score.config.json';
import defaultLenderConfig from '../configs/static/default.lender.config.json';
import defaultCitiesConfig from '../configs/static/default.cities.config.json';

export interface ConfigurationState {
  scoreConfig: ScoreConfig;
  lenderConfig: LenderConfig;
  cities: CityConfig[];
  rocketExtraConfig?: RocketExtraConfig;
}

export const ConfigurationState: ConfigurationState = {
  scoreConfig: Object.assign(new ScoreConfig(), defaultScoreConfig),
  lenderConfig: Object.assign(new LenderConfig(), defaultLenderConfig),
  cities: Object.assign([] as CityConfig[], defaultCitiesConfig),
  rocketExtraConfig: null,
};
