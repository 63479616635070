export function computeAmountFromPercentage(
  totalAmount: number,
  inputPercentage: number
): number {
  const amount = ((totalAmount ?? 0) * inputPercentage) / 100;
  return Math.round(amount * 100) / 100;
}

export function computePercentageFromAmount(
  totalAmount: number,
  inputAmount: number
): number {
  let percent = 0;
  if (totalAmount && typeof totalAmount === 'number' && totalAmount > 0) {
    percent = (inputAmount / totalAmount) * 100;
  }
  return Math.round(percent * 100) / 100;
}
