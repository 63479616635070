import { PipelineApplication, PriorityType } from '@fundmoreai/models';
import { ApplicationActionsHolder } from '../application/sidebar/manage-duplicates/model';
import {
  PipelineComposedFilter,
  PipelineSortFilter,
  PipelineUserFilter,
  PipelineView,
} from './model';

export class AddUserFilter {
  static readonly type = '@pipeline.AddUserFilter';

  constructor(public userFilter: PipelineUserFilter) {}
}

export class ApplyDefaultPipelineView {
  static readonly type = '@pipeline.ApplyDefaultPipelineView';
}

export class ExportToCsv {
  static readonly type = '@pipeline.ExportToCsv';
}

export class FetchApplications {
  static readonly type = '@pipeline.FetchApplications';
}

export class RemoveUserFilter {
  static readonly type = '@pipeline.RemoveUserFilter';

  constructor(public userFilter: PipelineUserFilter) {}
}

export class SetShowUnassignedDeals {
  static readonly type = '@pipeline.SetShowUnassignedDeals';

  constructor(public showUnassignedDeals: boolean) {}
}

export class SetSearchString {
  static readonly type = '@pipeline.SetSearchString';

  constructor(public searchString: string) {}
}

export class SetShowArchivedApplications {
  static readonly type = '@pipeline.SetShowArchivedApplications';

  constructor(public showArchivedApplications: boolean) {}
}

export class SetShowMoreDetails {
  static readonly type = '@pipeline.SetShowMoreDetails';

  constructor(public showMoreDetails: boolean) {}
}

export class SetSortFilter {
  static readonly type = '@pipeline.SetSortFilter';

  constructor(public sortFilter: PipelineSortFilter) {}
}

export class SetComposedFilter {
  static readonly type = '@pipeline.SetComposedFilter';

  constructor(public composedFilter: PipelineComposedFilter) {}
}

export class SetView {
  static readonly type = '@pipeline.SetView';

  constructor(public view: PipelineView) {}
}

export class UpdatePendingApprovalsList {
  static readonly type = '@pipeline.UpdatePendingApprovalsList';
}

export class UpdateApplication {
  static readonly type = '@pipeline.UpdateApplication';

  constructor(public applicationId: string, public application: Partial<PipelineApplication>) {}
}

export class SetApplicationPriority {
  static readonly type = '@pipeline.SetApplicationPriority';

  constructor(public applicationId: string, public priority?: PriorityType | null) {}
}

export class UpdateApplicationLocallyAfterManagement {
  static readonly type = '@pipeline.UpdateApplicationLocallyAfterManagement';

  constructor(public applicationActionsHolder: ApplicationActionsHolder) {}
}

export class ApplyTeamFilter {
  static readonly type = '@pipeline.ApplyTeamFilter';

  constructor(public team: string[]) {}
}

export class ApplyUnassignedDealsFilter {
  static readonly type = '@pipeline.ApplyUnassignedDealsFilter';
}

export class SetShowTeamApplications {
  static readonly type = '@pipeline.SetShowTeamApplications';

  constructor(public showTeamApplications: boolean, public team?: string[]) {}
}

export class FetchUnassignedDealsCount {
  static readonly type = '@pipeline.FetchUnassignedDealsCount';
}
