import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxsModule } from '@ngxs/store';
import { PubNubState } from './pubnub.state';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [],
  imports: [CommonModule, MatDialogModule, NgxsModule.forFeature([PubNubState])],
})
export class PubNubModule {}
