import {
  Component,
  ChangeDetectionStrategy,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ViewChild,
  ChangeDetectorRef,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { tap } from 'rxjs';
import { FundmoreAITheme, ThemeState } from 'src/app/core/store/theme.state';
import { environment } from 'src/environments/environment';
import { DialogsComponent } from '../../sidebar/dialogs/dialogs.component';
import {
  ClearNoteToEdit,
  PublishDraftNote,
  ResetAINote,
  SaveDraftNote,
  SaveNote,
  SetNoteHasChanges,
  UpdateDraftNote,
} from '../notes.actions';
import { configWithTheme } from './tinymce.config';
import { MatDialog } from '@angular/material/dialog';
import { Note } from '../notes.model';
import { GenerateAiNoteDialogComponent } from '../generate-ai-note-dialog/generate-ai-note-dialog.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AIFeatures } from '@fundmoreai/models';
import { AppFeaturesState } from '../../../../shared/app-features.state';

@Component({
  selector: 'fm-edit-note-form',
  templateUrl: './edit-note-form.component.html',
  styleUrls: ['./edit-note-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    EditorComponent,
    FormsModule,
    MatButtonModule,
    NgIf,
    MatMenuModule,
    MatIconModule,
    MatTooltipModule,
  ],
})
export class EditNoteFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  applicationId: string;

  @Input()
  note: Partial<Note> | null | undefined;

  @Output()
  closeEdit: EventEmitter<void> = new EventEmitter<void>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @ViewChild('editor') tinyMCE: any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initTinyMCE: any;
  noteContent = '';
  aiNotesGenerationEnabled = false;

  private hasChanges = false;

  readonly apiKey = environment.tinymce.apiKey;

  constructor(private dialog: MatDialog, private store: Store, private cdr: ChangeDetectorRef) {
    this.aiNotesGenerationEnabled =
      this.store.selectSnapshot(AppFeaturesState.aiFeatures)?.[AIFeatures.APPLICATION_NOTES] ??
      false;
  }

  ngOnInit(): void {
    this.setupMCE();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.note && this.note) {
      this.noteContent = this.note.content || '';
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(new ClearNoteToEdit());

    if (this.hasChanges) {
      this.store.dispatch(new SetNoteHasChanges(false));
    }
  }

  cancel() {
    if (this.hasChanges) {
      this.confirmDiscardUnsavedChanges().subscribe((result) => {
        if (!result) {
          this.closeEdit.emit();
        }
      });
    } else {
      this.closeEdit.emit();
    }
  }

  onChange() {
    if (!this.hasChanges) {
      this.store.dispatch(new SetNoteHasChanges(true));
      this.hasChanges = true;
    }
  }

  publish() {
    const dialogRef = this.dialog.open(DialogsComponent, {
      data: {
        title: $localize`Saving the note will prevent future editing.`,
        message: $localize`Are you sure you want to continue?`,
        buttonText: {
          yes: $localize`:@@action.publish:Publish`,
          no: $localize`:@@action.cancel:Cancel`,
        },
      },
      minWidth: '400px',
      maxWidth: '400px',
    });
    dialogRef.afterClosed().subscribe((response: boolean) => {
      if (!response || !this.note?.id) {
        return;
      }

      this.store
        .dispatch(new PublishDraftNote(this.note.id, this.noteContent))
        .pipe(tap(() => this.closeEdit.emit()))
        .subscribe();
    });
  }

  save() {
    this.store
      .dispatch(new SaveNote(this.applicationId, this.noteContent))
      .pipe(tap(() => this.closeEdit.emit()))
      .subscribe();
  }

  saveAsDraft() {
    this.store
      .dispatch(new SaveDraftNote(this.applicationId, this.noteContent))
      .pipe(tap(() => this.closeEdit.emit()))
      .subscribe();
  }

  updateDraft() {
    if (!this.note?.id) {
      return;
    }

    this.store
      .dispatch(new UpdateDraftNote(this.note.id, this.noteContent))
      .pipe(tap(() => this.closeEdit.emit()))
      .subscribe();
  }

  private confirmDiscardUnsavedChanges() {
    const dialogRef = this.dialog.open(DialogsComponent, {
      data: {
        title: $localize`You have unsaved notes changes!`,
        message: $localize`Would you like to discard the note?`,
        buttonText: {
          // inversed button labels
          yes: $localize`Continue Editing`,
          no: $localize`Discard`,
        },
      },
      minWidth: '400px',
      maxWidth: '400px',
    });

    return dialogRef.afterClosed();
  }

  generateContentWithAI() {
    const dialogRef = this.dialog.open(GenerateAiNoteDialogComponent, {
      data: {
        applicationId: this.applicationId,
      },
    });

    dialogRef.afterClosed().subscribe((result?: string) => {
      this.store.dispatch(new ResetAINote());

      if (!result) {
        return;
      }

      // preserve generated note newlines in tinyMCE editor
      this.noteContent = result.replace(/\n/g, '<br>');
      this.cdr.markForCheck();
    });
  }

  private setupMCE() {
    const isDarkMode = this.store.selectSnapshot(ThemeState.theme) === FundmoreAITheme.Dark;

    this.initTinyMCE = {
      ...configWithTheme(isDarkMode),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      setup: (editor: any) => {
        if (this.aiNotesGenerationEnabled) {
          editor.ui.registry.addButton('generateWithAI', {
            icon: 'language',
            tooltip: $localize`Generate a note using AI`,
            onAction: () => {
              this.generateContentWithAI();
            },
          });
        }
      },
    };
  }
}
