export * from './addOnProduct';
export * from './borrowerType';
export * from './dealStatus';
export * from './errorCode';
export * from './insuranceStatus';
export * from './loanPriority';
export * from './loanPurpose';
export * from './noteRecipient';
export * from './notesStatus';
export * from './occupancyType';
export * from './propertyInspectionContactType';
export * from './propertyType';
export * from './propertyUse';
export * from './province';
export * from './rentalType';
export * from './requestedAppointmentTime';
export * from './requestStatus';
export * from './saleType';
export * from './secondaryServiceType';
export * from './serviceType';
export * from './streetDirection';
export * from './streetType';
export * from './valueIndicator';

export enum FCTEnums {
  FCTAddOnProduct = 'FCTAddOnProduct',
  FCTBorrowerType = 'FCTBorrowerType',
  FCTDealStatus = 'FCTDealStatus',
  FCTInsuranceStatus = 'FCTInsuranceStatus',
  FCTLoanPriority = 'FCTLoanPriority',
  FCTLoanPurpose = 'FCTLoanPurpose',
  FCTNoteRecipient = 'FCTNoteRecipient',
  FCTNotesStatus = 'FCTNotesStatus',
  FCTOccupancyType = 'FCTOccupancyType',
  FCTPropertyInspectionContactType = 'FCTPropertyInspectionContactType',
  FCTPropertyType = 'FCTPropertyType',
  FCTPropertyUse = 'FCTPropertyUse',
  FCTProvince = 'FCTProvince',
  FCTRentalType = 'FCTRentalType',
  FCTRequestedAppointmentTime = 'FCTRequestedAppointmentTime',
  FCTRequestStatus = 'FCTRequestStatus',
  FCTSaleType = 'FCTSaleType',
  FCTSecondaryServiceType = 'FCTSecondaryServiceType',
  FCTServiceType = 'FCTServiceType',
  FCTStreetDirection = 'FCTStreetDirection',
  FCTStreetType = 'FCTStreetType',
}
