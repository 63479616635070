import { TermType } from '../enums';
import { TermDuration } from './rateMatrixTerm';

export interface BrokerCommission {
  id: string;
  tenantId: string;
  advancedProductId: string;
  duration: TermDuration;
  termType?: TermType;
  amountForFixed: number;
  amountForVariable: number;
  brokerage: string;
}
