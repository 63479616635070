/* eslint-disable max-len */
import { PdfOptions } from 'src/app/shared';

export const PDF_OPTIONS: PdfOptions = {
  format: 'A4',
  displayHeaderFooter: true,
  printBackground: true,
  margin: {
    bottom: 70, // minimum required for footer msg to display
    left: 25,
    right: 35,
    top: 60,
  },
};

export enum EZIDOX_DOCUMENT_ACTION {
  UPLOAD = 'UPLOAD',
  ATTACH = 'ATTACH',
}

export const EZIDOX_DOCUMENT_ACTION_RECORD: Record<EZIDOX_DOCUMENT_ACTION, string> = {
  [EZIDOX_DOCUMENT_ACTION.UPLOAD]: $localize`UPLOAD`,
  [EZIDOX_DOCUMENT_ACTION.ATTACH]: $localize`ATTACH`,
};
