import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { Router, RouterLink } from '@angular/router';
import { AuthService } from '../auth.service';
import { AuthComponent } from '../auth.component';
import { AuthResult, AuthResultStatus } from '../model';
import { MatButtonModule } from '@angular/material/button';

@Component({
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, RouterLink, MatButtonModule],
})
export class ForgotPasswordComponent implements AuthComponent {
  @Input() data: any;
  @Output() authResultEvent: EventEmitter<AuthResult> = new EventEmitter<AuthResult>();
  @Output() formSubmittingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSubmitting = false;
  forgotPasswordForm: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private authApiService: AuthService,
    private router: Router,
  ) {
    this.forgotPasswordForm = this.fb.group({
      email: ['', Validators.required],
    });
  }
  handleGoToLogin() {
    this.authResultEvent.next(new AuthResult(AuthResultStatus.LOGIN));
  }
  submitForgotPasswordForm() {
    this.isSubmitting = true;
    const { email } = this.forgotPasswordForm.value;
    this.authApiService.forgotPassword(email).subscribe(
      (res) => {
        this.authResultEvent.next(res);
      },
      (err) => {
        this.isSubmitting = false;
        this.formSubmittingEvent.next(false);
        this.authResultEvent.next(new AuthResult(AuthResultStatus.ERROR, err));
      },
    );
  }
}
