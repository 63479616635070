import { FCTNoteRecipient } from '../enums/noteRecipient';
import { FCTDocument } from './document';

export interface FCTNote {
  Recipients: FCTNoteRecipient[];
  NoteMessage: string;
  Files: FCTDocument[];
  CreatedTimestamp?: string; // datetime YYYY-MM-DDTHH:mm:ss
  NewNote?: boolean;
  Sender?: string;
}
