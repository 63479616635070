export const computeAnnualShelter = (
  annualPropertyTax: number,
  monthlyRequestedMortgagePayment: number,
  monthlyExistingMortgagesPayments: number,
  monthlyLiabilitiesPayment: number,
) => {
  const annualShelterPayment =
    monthlyExistingMortgagesPayments * 12 +
    monthlyLiabilitiesPayment * 12 +
    monthlyRequestedMortgagePayment * 12 +
    annualPropertyTax;

  return annualShelterPayment;
};
