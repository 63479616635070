import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MCUCreateProductsError,
  MCUCreateProductsResponse,
  MCUCreditCardFulfillResponse,
} from '@fundmoreai/models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MCUService {
  constructor(private http: HttpClient) {}

  createProducts(applicationId: string): Observable<MCUCreateProductsResponse> {
    return this.http.post<MCUCreateProductsResponse>(
      `${environment.fundmore_v2_api_url}/mcu/create-products/${applicationId}`,
      {},
    );
  }

  getCreateProductsError(applicationId: string): Observable<MCUCreateProductsError> {
    return this.http.get<MCUCreateProductsError>(
      `${environment.fundmore_v2_api_url}/mcu/create-products/${applicationId}/error`,
    );
  }

  creditCardFulfill(applicationId: string): Observable<MCUCreditCardFulfillResponse> {
    return this.http.post<MCUCreditCardFulfillResponse>(
      `${environment.fundmore_v2_api_url}/mcu/credit-card-fulfill/${applicationId}`,
      {},
    );
  }

  getCreditCardFulfillError(applicationId: string): Observable<MCUCreditCardFulfillResponse> {
    return this.http.get<MCUCreditCardFulfillResponse>(
      `${environment.fundmore_v2_api_url}/mcu/credit-card-fulfill/${applicationId}/error`,
    );
  }
}
