import { Injectable } from '@angular/core';
import {
  BaseDocumentContext,
  JoinedApplicationCondition,
} from '../../features/shared/documents/model';
import { HandlebarsService } from '../handlebars.service';

@Injectable({
  providedIn: 'root',
})
export class ConditionsDisplayService {
  public assignValuesToObjects(
    conditionText: string | null | undefined,
    documentContext: Partial<BaseDocumentContext>,
  ): string {
    const inputPlaceholder = '_________';
    const handlebarsRegex = /{{.*?}}/g;
    const borrowerName = `${documentContext.primaryApplicant?.name ?? ''} ${
      documentContext.primaryApplicant?.surname ?? ''
    }`.trim();
    // replace(/\d(?=(\d{3})+\.)/g, '$&,') will format numbers with commas
    // '100000000.00'.replace(/\d(?=(\d{3})+\.)/g, '$&,') => '100,000,000.00'
    const firstMortgageAmount = documentContext.firstMortgage?.loanAmount
      ?.toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');
    const primaryPropertyValue = documentContext.primaryProperty?.estimatedValue
      ?.toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, '$&,');

    let conditionsReplaced =
      conditionText
        ?.replace('[FIRST_MORTGAGE_VALUE]', `$${firstMortgageAmount ?? inputPlaceholder}`)
        .replace('[PROPERTY_VALUE]', `$${primaryPropertyValue ?? inputPlaceholder}`)
        .replace('[BORROWER_NAME]', borrowerName ?? inputPlaceholder) || ' ';

    if (handlebarsRegex.test(conditionsReplaced)) {
      try {
        conditionsReplaced = HandlebarsService.compileTemplate(conditionsReplaced, documentContext);
      } catch (error) {
        console.warn('Error compiling handlebars template', conditionText, error);
      }
    }

    return conditionsReplaced.trim();
  }

  public mapConditionNames(conditions: JoinedApplicationCondition[]) {
    const MISSING_DESCRIPTION = $localize`Missing description`;
    const mapConditionName = (a: JoinedApplicationCondition): string => {
      const desc = a?.condition?.text;
      return !desc || desc?.trim() === '' ? MISSING_DESCRIPTION : desc.trim();
    };

    return (
      conditions
        ?.map(mapConditionName)
        // find any block of [ABSdefg] and replace it
        .map((a) => a.replace(/\[(.*?)\]/g, '____________________'))
    );
  }
}
