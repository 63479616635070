import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ConstructionMortgage } from '../../shared';

export interface BuildingBudgetPayload {
  buildingBudget: number;
  applicationId: string;
}
@Injectable({
  providedIn: 'root',
})
export class ConstructionMortgageService {
  constructor(private http: HttpClient) {}

  patchConstructionMortgage(
    constructionMortgageId: string,
    constructionMortgage: Partial<ConstructionMortgage>,
  ): Observable<void> {
    return this.http.patch<void>(
      `${environment.api_url}/constructionMortgages/${constructionMortgageId}`,
      constructionMortgage,
    );
  }

  patchBuildingBudget(
    constructionMortgageId: string,
    buildingBudgetPayload: BuildingBudgetPayload,
  ) {
    return this.http.patch<void>(
      `${environment.api_url}/constructionMortgages/${constructionMortgageId}/buildingBudget`,
      buildingBudgetPayload,
    );
  }

  getConstructionMortgage(mortgageId: string) {
    return this.http.get<ConstructionMortgage | void>(
      `${environment.api_url}/constructionMortgages?mortgageId=${mortgageId}`,
    );
  }
}
