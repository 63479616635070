import {
  FieldMetadata,
  FieldOptionDetails,
  FormattedFieldMetadata,
  SupportedCustomEntity,
  SupportedTypes,
  defaultFieldMetadata,
} from '@fundmoreai/models';

// turn [{id, fieldName, tableName}] into { tableName: { fieldName: { id }}}
export const formatFieldMetadata = (
  unformattedFieldMetadata: FieldMetadata[],
  translationRecord?: Record<
    SupportedCustomEntity,
    Record<string, Record<string, string> | null> | null
  >,
): FormattedFieldMetadata => {
  const defaultFields = unformattedFieldMetadata.filter((f) => !f.id);
  const createdFields = unformattedFieldMetadata.filter((f) => f.id);

  const formattedFieldMetadata = transformMetadataArrayToObject(defaultFields);

  for (const createdField of createdFields) {
    const defaultField = formattedFieldMetadata?.[createdField.tableName]?.[createdField.fieldName];

    if (createdField.isCustom) {
      saveCustomFieldDetails(formattedFieldMetadata, createdField);
    } else if (defaultField) {
      mergeCreatedAndDefaultFieldMetadata(
        formattedFieldMetadata,
        createdField,
        defaultField,
        translationRecord,
      );
    }
  }

  for (const defaultField of defaultFields) {
    const translatedOptions = translationRecord?.[defaultField.tableName]?.[defaultField.fieldName];
    const formattedField = formattedFieldMetadata[defaultField.tableName][defaultField.fieldName];

    if (
      !formattedField.id &&
      translatedOptions &&
      (defaultField.type === SupportedTypes.SINGLESELECT ||
        defaultField.type === SupportedTypes.SINGLESELECT_NUMERIC ||
        defaultField.type === SupportedTypes.MULTISELECT)
    ) {
      translateDefaultFieldOptions(formattedFieldMetadata, defaultField, translatedOptions);
    }
  }

  return formattedFieldMetadata;
};

// turn { tableName: { fieldName: { id }}} into [{id, fieldName, tableName}]
export const unformatFieldMetadata = (fieldMetadata: FormattedFieldMetadata): FieldMetadata[] => {
  const unformatted: FieldMetadata[] = [];
  for (const tableName in fieldMetadata) {
    for (const fieldName in fieldMetadata[tableName]) {
      const field = fieldMetadata[tableName][fieldName];

      if (field.id && !field.isCustom) {
        const defaultField = defaultFieldMetadata.find(
          (f) => f.fieldName === fieldName && f.tableName === tableName,
        );

        if (!defaultField) {
          continue;
        }

        unformatted.push(defaultField as FieldMetadata);
      }

      unformatted.push(field);
    }
  }
  return unformatted;
};

const translateDefaultFieldOptions = (
  formattedFieldMetadata: FormattedFieldMetadata,
  defaultField: FieldMetadata,
  translatedOptions: Record<string, string>,
) => {
  for (const option in defaultField.options) {
    if (!translatedOptions[option]) {
      continue;
    }

    (
      formattedFieldMetadata[defaultField.tableName][defaultField.fieldName].options?.[
        option
      ] as FieldOptionDetails
    ).value = translatedOptions[option];
  }
};

const translateCreatedFieldOptions = (
  createdField: FieldMetadata,
  defaultField: FieldMetadata,
  translatedOptions: Record<string, string> | null,
) => {
  for (const optionKey in createdField.options) {
    const createdOptionValue = createdField.options?.[optionKey];
    const defaultOptionValue = defaultField.options?.[optionKey];
    const localizedOption = translatedOptions?.[optionKey] ?? '';

    const replaceOptionWithLocalizedOption =
      typeof createdOptionValue === 'object' && typeof defaultOptionValue === 'object'
        ? createdOptionValue?.value === defaultOptionValue?.value || !createdOptionValue.updatedAt
        : createdOptionValue === defaultOptionValue;

    if (!replaceOptionWithLocalizedOption) {
      continue;
    }

    if (typeof createdField.options[optionKey] === 'object') {
      (createdField.options[optionKey] as FieldOptionDetails).value = localizedOption;
    } else {
      createdField.options[optionKey] = localizedOption;
    }
  }

  return { ...createdField, options: { ...createdField.options } };
};

const mergeCreatedAndDefaultFieldMetadata = (
  formattedFieldMetadata: FormattedFieldMetadata,
  createdField: FieldMetadata,
  defaultField: FieldMetadata,
  translationRecord?: Record<
    SupportedCustomEntity,
    Record<string, Record<string, string> | null> | null
  >,
) => {
  if (translationRecord?.[defaultField.tableName]?.[defaultField.fieldName]) {
    translateExistingField(formattedFieldMetadata, createdField, defaultField, translationRecord);
  } else {
    formattedFieldMetadata[createdField.tableName][createdField.fieldName] = {
      ...createdField,
      type: defaultField.type,
    };
  }
};

const saveCustomFieldDetails = (
  formattedFieldMetadata: FormattedFieldMetadata,
  fieldToSave: FieldMetadata,
) => {
  formattedFieldMetadata[fieldToSave.tableName][fieldToSave.id] = { ...fieldToSave };
};

const transformMetadataArrayToObject = (fields: FieldMetadata[]) => {
  return fields.reduce((prev: FormattedFieldMetadata, curr: FieldMetadata) => {
    return {
      ...prev,
      [curr.tableName]: {
        ...prev[curr.tableName],
        [curr.fieldName]: curr,
      },
    };
  }, {});
};

const translateExistingField = (
  formattedFieldMetadata: FormattedFieldMetadata,
  createdField: FieldMetadata,
  defaultField: FieldMetadata,
  translationRecord: Record<
    SupportedCustomEntity,
    Record<string, Record<string, string> | null> | null
  >,
) => {
  const tableEntityRecord: Record<string, Record<string, string>> | null =
    translationRecord[createdField.tableName];
  const translatedOptions = tableEntityRecord?.[createdField.fieldName];

  if (
    !translatedOptions ||
    !(
      createdField.type === SupportedTypes.SINGLESELECT ||
      createdField.type === SupportedTypes.SINGLESELECT_NUMERIC ||
      createdField.type === SupportedTypes.MULTISELECT
    )
  ) {
    return;
  }

  const translatedCreatedField = translateCreatedFieldOptions(
    createdField,
    defaultField,
    translatedOptions,
  );

  formattedFieldMetadata[createdField.tableName][createdField.fieldName] = {
    ...translatedCreatedField,
    type: defaultField.type,
  };
};
