/* eslint-disable max-classes-per-file */
import { FundmoreNarrative, FundmoreResultType } from '@fundmoreai/models';
import { buildSecureIfFunctionWithContext, stringTemplateParser } from '../helpers/generic';
import { Treshold } from '../interfaces/score.config';
import { FundmoreRecommendation, FundmoreScoreContext } from '../models/models';
import { RecommendationProcessor } from '../recommendations/recommendation-processor';

export class TresholdProcessor {
  public process = (context: FundmoreScoreContext, treshold: Treshold): TresholdResult => {
    if (treshold.unknownFn) {
      const unknownFunction = buildSecureIfFunctionWithContext(treshold.unknownFn);
      const unknownResult = unknownFunction.call(context);
      if (unknownResult) {
        return new TresholdResult(FundmoreResultType.Unknown, treshold, context);
      }
    }
    const tresholdFunction = buildSecureIfFunctionWithContext(treshold.tresholdFn);
    const tresholdResult = tresholdFunction.call(context);
    if (tresholdResult) {
      return new TresholdResult(FundmoreResultType.Pass, treshold, context);
    } else {
      if (treshold.exceptionFn) {
        const exceptionFunction = buildSecureIfFunctionWithContext(treshold.exceptionFn);
        const exceptionResult = exceptionFunction.call(context);
        if (exceptionResult) {
          return new TresholdResult(FundmoreResultType.ManualReview, treshold, context);
        }
      }
      if (treshold.recommendations) {
        for (let i = 0; i < treshold.recommendations.length; i++) {
          const recommendation = treshold.recommendations[i];
          const recommendationFunction = buildSecureIfFunctionWithContext(
            recommendation.activateFn,
          );
          const recommendationResult = recommendationFunction.call(context);
          if (recommendationResult) {
            const recommendationProcessor = new RecommendationProcessor();

            const recommendationResult = recommendationProcessor.process(
              recommendation,
              context,
              treshold,
            );
            return new TresholdResult(
              FundmoreResultType.Fail,
              treshold,
              context,
              recommendationResult,
            );
          }
        }
      }
      return new TresholdResult(FundmoreResultType.Fail, treshold, context);
    }
  };
}

export class TresholdResult {
  result: FundmoreResultType;
  narrative: FundmoreNarrative;
  recommendation?: FundmoreRecommendation;

  constructor(
    result: FundmoreResultType,
    treshold: Treshold,
    context: FundmoreScoreContext,
    recommendation?: FundmoreRecommendation,
  ) {
    this.result = result;
    const narrativeValue = treshold.narratives.find((x) => x.case === result.toString()).value;
    this.narrative = {
      display: stringTemplateParser(narrativeValue, context),
      sentiment: result,
    };

    if (recommendation) {
      this.recommendation = recommendation;
    }
  }
}
