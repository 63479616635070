import { Mortgage, Fee, MortgageCalculationAutomationSettings } from '@fundmoreai/models';
import { FundmoreCalculator } from '..';

export function computeNetAmountToAdvance(
  requestedMortgage: Mortgage | null,
  fees: Fee[],
  pst: number | null,
  prepaymentAmount: number | null | undefined,
  mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
): number | null {
  if (!requestedMortgage.interestAdjustmentDate) {
    return null;
  }
  const amountToAdvance = FundmoreCalculator.computeAmountToAdvance(
    requestedMortgage,
    fees,
    pst,
    prepaymentAmount,
  );
  if (!amountToAdvance) {
    return null;
  }

  const interestAdjustmentAmount =
    !mortgageCalculationAutomationSettings ||
    !mortgageCalculationAutomationSettings[requestedMortgage.id]?.isInterestAdjustmentAmountDisabled
      ? FundmoreCalculator.interestAdjustmentAmount(requestedMortgage)
      : requestedMortgage.interestAdjustmentAmount ?? 0;
  const netAmountToAdvance = amountToAdvance - interestAdjustmentAmount;

  return netAmountToAdvance;
}
