import { AddressExpanded } from './addressExpanded';
import {
  OccupationType,
  EmployedPaymentType,
  SelfEmployedPaymentType,
  EmploymentType,
  IndustrySectorType,
} from './applicant';
import { IncomePeriod } from './enums';
import { IncomeType } from './income';

export interface Job {
  annualIncome: number;
  applicantId?: string;
  applicationId?: string;
  bonusOvertimeCommissions?: boolean;
  businessType?: string;
  createdAt?: string;
  description: string;
  employerName: string;
  EmployerAddressExpanded?: AddressExpanded;
  employerEmailAddress: string;
  employmentType?: EmploymentType;
  endDate: string | null;
  id: string;
  incomePaymentFrequency: IncomePeriod;
  jobTitle: string;
  occupation?: OccupationType | CustomEQOccupationType;
  paymentType?: EmployedPaymentType | SelfEmployedPaymentType;
  startDate: string | null;
  tenantId?: string;
  timeAtIndustryMonths?: number;
  timeAtJobMonths?: number;
  type?: IncomeType;
  industrySector?: IndustrySectorType;
  isCurrent: boolean;
  phoneNumber?: string;
  updatedAt?: string;
  externalSequenceNumber?: number | null;
  unableToVerify?: boolean;
  probation?: boolean;
}

export enum JobKey {
  IS_CURRENT = 'isCurrent',
  UNABLE_TO_VERIFY = 'unableToVerify',
  PROBATION = 'probation',
  EMPLOYER_NAME = 'employerName',
  EMPLOYER_ADDRESS = 'EmployerAddressExpanded',
  EMPLOYER_EMAIL_ADDRESS = 'employerEmailAddress',
  TIME_AT_JOB_MONTHS = 'timeAtJobMonths',
  OCCUPATION = 'occupation',
  JOB_TITLE = 'jobTitle',
  EMPLOYMENT_TYPE = 'employmentType',
  ANNUAL_INCOME = 'annualIncome',
  INDUSTRY_SECTOR = 'industrySector',
  INCOME_PAYMENT_FREQUENCY = 'incomePaymentFrequency',
  EMPLOYMENT_START_DATE = 'employmentStartDate',
  EMPLOYMENT_END_DATE = 'employmentEndDate',
  BONUS_OVERTIME_COMMISSIONS = 'bonusOvertimeCommissions',
  BUSINESS_TYPE = 'businessType',
  DESCRIPTION = 'description',
  PAYMENT_TYPE = 'paymentType',
  TIME_AT_INDUSTRY_MONTHS = 'timeAtIndustryMonths',
  TYPE = 'type',
  PHONE_NUMBER = 'phoneNumber',
  APPLICANT_ID = 'applicantId',
}

export enum CustomEQOccupationType {
  ASSISTANT = '6e29c92f-5a28-4383-b3b5-94e44edfb8d8',
  ADMINISTRATIVE_ASSISTANT = '49934f48-34d9-4561-9475-0a90307ce108',
  ACCOUNTANT = 'b42bb82e-6c56-40bb-a825-cd94f08048e5',
  ACTRESS = '89f96a84-631e-4eb0-9b02-25081a85cfae',
  INSURANCE_AGENT = 'c7f81a49-837e-47f0-8b1e-9b0015e3fbc3',
  ANALYST = 'b07b72cd-224e-4ed2-8a29-29df86d21f37',
  ARCHITECT = 'fc9e91fb-b40e-4249-8b50-e2824ff23c38',
  ASSEMBLER = 'a2a38e2a-6ccf-47a3-a4c7-8b8162bcf7ca',
  ARTIST = '3257a4e0-40d0-49b1-9355-942d8e9315c2',
  AUDITOR = '5cb7a38c-4d7b-409a-99c3-83f5432a2c1a',
  BAKER = 'dd9cc9b6-e317-4297-97e5-56e44e764580',
  BARTENDER = 'ef307853-1dd9-4287-ba9e-4603b0577bb1',
  BYLAW_ENFORCEMENT_OFFICER = '6379cd17-e706-42c0-9367-30106e96332c',
  BANKER = '736f53ed-3dcb-4f42-8cb3-9d4578c53b68',
  BUILDING_INSPECTOR = 'df8796ac-8314-4ff9-82f1-5aa2188b9c74',
  BOOKKEEPER = 'c77e0bb9-e0c6-4c29-8d98-2c9c55df3a71',
  BROKER = 'bc54f623-0cfd-4d0e-9f65-5de74351d5a3',
  BABYSITTER = 'abce878d-835d-4512-a6d2-3a9e7c5425b6',
  BUTCHER = '5f464e89-ea06-4ad7-a09f-650c97f8e229',
  BUILDER = '9e0e2990-e48b-4569-a1d2-79c576ea4092',
  BUYER = '3fb28e3c-1f53-4d2c-b9f8-df1f6306b50b',
  CORONER = '3e04a63b-18b0-4f1a-bf57-e2d27c8c2e32',
  CARPENTER = '7b7543d3-2b81-4a84-a992-e309f1123e0c',
  TAXI_DRIVER = 'cbade32d-2b8e-4d22-b46a-7dce6a5864bc',
  CASHIER_COOK = '5a5d3d10-4cf6-4cd5-8a28-76c0d228a72d',
  CLEANER = '631d1479-1e68-40dd-8943-5a0fb7ca46ac',
  CHEF = '45f3f80e-44a4-4b67-9a6d-c44db20d72d1',
  CONTROLLER = '5a8789ad-915b-4607-8ab9-810b8a416913',
  CO_ORDINATOR = '537d5b60-af46-4b15-a463-02d0a43a92fb',
  COMPUTER_OPERATOR = '0838d1a9-8965-4155-929f-5c4de6c0ab5e',
  COLLECTOR = '8b7035a2-405e-4713-af69-9a8d308f4d0f',
  CONSULTANT = 'a4eac6de-2cc1-4b80-a065-d2324b48803d',
  CONTRACTOR = '35a4ab45-244c-468c-aa94-6c27f0bcf8b8',
  CUSTOMER_SERVICE = 'c88131e2-df50-4a8b-b6b8-b245a8b46f75',
  CONVEYANCER = 'f33eaf8f-b62f-4b1f-8093-4a1df99da67f',
  DATA_ENTRY_CLERK = '63af4f6b-5e5d-4a1a-9336-34fca5a4ca4d',
  DENTIST = '67ef6a5c-6b17-400b-b04e-7d7f47f94e7d',
  DRAFTSMAN = 'd1a199da-97c3-4b0a-89a5-cb4e6828a5fb',
  DIRECTOR = '18b4d0b5-0e7c-4820-90f0-b866c26d4d20',
  DENTAL_ASSISTANT = 'ed1e80a1-d89b-456d-9a31-8d82388c3da6',
  DRIVER = '956de53d-4b92-404f-9bfb-93e9d2c92394',
  DOCTOR = '40b3e320-3e2b-44b8-9a77-46f2a722f3c7',
  REAL_ESTATE_DEVELOPER = 'a0348b8f-1f9b-42b8-8f48-487c511f88bc',
  DRYWALLER = 'eb6c55a6-66a3-4446-b17c-8e2a5ab92879',
  EDITOR = '22d1a114-4911-438c-89cb-bc7e3f9c576f',
  ENGINEER = 'd30c5760-3b6a-416d-b5c5-55d70d01c725',
  ELECTRICIAN = 'f41e6351-34c3-456b-932f-36f0f258c8cd',
  ENTERTAINER = '38fb1c8e-4a3a-46f3-b153-e2d27c8c2e32',
  ESTHETICIAN = 'c08f56d3-7d71-45db-997e-032c0e68960c',
  EXECUTIVE_VICE_PRESIDENT = '39a1f0d1-9b3a-4e7d-bdb3-1f0378f0d77b',
  EXECUTIVE = '52ed1ff6-1f32-476d-80d5-1da7e09cd679',
  FITNESS_COACH = '6f39024a-9b5f-41a0-95a1-e92b356a4b7c',
  FIREMAN = '7debd1b2-4e8f-41d3-8c26-7b49323e94ed',
  FLIGHT_ATTENDANT = '56f7b0a2-bca5-4a1f-9c4e-dc9a0f5b3f32',
  FORKLIFT_OPERATOR = 'dbba2b68-cf5f-4e98-9986-d23b55c0f57e',
  FINGERPRINT_TECHNICIAN = '2f312558-2c50-4989-9e96-60c2a67d27c9',
  FOREMAN = '6cb59524-e982-42a7-96a2-d3c20a6c7d6f',
  FOSTER_PARENT = '9921d17b-84a0-4294-9b6b-7ea20b5b6f0d',
  GARDENER = 'c7f72d28-2cd1-4c71-9772-b6b718d635b1',
  GENERAL_CLERK = '17e37b43-4e89-4b35-8a94-d28df19708f6',
  GENERAL_MANAGER = '70347036-bbb9-419e-bf59-39468abeb2c0',
  LIMITED_GUARANTEE_ONE_DOLLAR = '2a8e622a-4dd1-4346-9e4f-2381883707d3',
  HAIRDRESSER = 'bd390af9-3a6f-466a-a40f-83f9a2d437a5',
  HOSTESS_CASHIER = 'd8bb77f5-8e5e-49cc-9f99-80fca057c1a4',
  HOUSEKEEPER = '42ff7792-4f45-4286-8392-0b93c0718f6b',
  HOSTESS = '0f4e98cc-0b5c-4a17-ba20-05e8d0ee5d68',
  HUMAN_RESOURCES = '35f87319-4082-444e-8e58-86951ca601a6',
  HOUSEWIFE = '0c3d2c6b-7351-4f82-b0d8-64e3d1dd1a40',
  HYGIENIST = '13e1e144-85c7-41f2-b367-2ee7878b6e7b',
  INSURANCE_ADJUSTOR = '1283c77b-7ce9-44f7-918f-07c9ce000a4f',
  INTERIOR_DECORATOR = 'e122141b-e5e9-4f4e-989e-4f171a080b16',
  INSTALLER = 'd6ca84f2-24bb-428f-bc92-30ff3bf6ab74',
  INVESTIGATOR = '9780a4ad-c0c2-44bb-a70c-50eaa6b0426a',
  INVESTMENT = '2cd45f59-76d7-4c8a-91ac-89b933a07df6',
  INVESTOR = '9be10b85-2501-456a-848d-8421f9a531d2',
  JOURNALIST = '60c26731-10cb-45d5-a95d-1a5d307c91e8',
  JEWELLERY_MAKER = 'eae70c1c-095e-43d2-91a5-9b31915d595d',
  LANDLORD = 'c29f4c51-e3a9-4c7b-88a1-4b96d2cc8b6d',
  LANDSCAPER = 'f9902d0e-100a-4a9a-b829-4571a5a65c7c',
  LITHOGRAPHER = 'b06ee68a-5f05-45b0-994d-86507360349a',
  LEASING_REPRESENTATIVE = 'cb72b47f-1086-4fb0-8e05-e9a7ce574f62',
  LIBRARIAN = 'd3e75a48-7642-4b45-b129-146a04f2ed01',
  LAWYER = 'dc79ce6f-7eab-4534-940c-9f3804f5328b',
  MORTGAGE_OFFICER = '5fc28c6b-0e02-480c-b11a-3a0ebc2766b9',
  MARKETING = '3449d8d9-35a4-48df-95cd-6af81b4e1c19',
  MORTGAGE_BROKER = 'a1788be7-18d5-4d92-86fc-dac9063ac4bf',
  MECHANIC = '4e059662-1c75-4243-86a4-3da54028c838',
  MAILMAN = 'ce7db151-6ed7-4d07-8e5c-c77e4533b7b3',
  MAINTENANCE = '36a65c51-7baf-41f1-94f9-93a86b6a1b1a',
  MACHINE_OPERATOR = '50670753-d535-4a8b-865a-ef25684c38a3',
  MORTICIAN = 'b9db0fcf-8b96-4b50-82d5-5af4c4f4b2bf',
  MTG_DEVELOPMENT_OFFICER = 'b848c18d-307f-42c6-9700-82c99e4cd15d',
  NURSING_AID = '2ff864f3-6c90-4460-9340-9e6a1f8e98b3',
  NET_OPERATING_INCOME = '51f47cbe-7dc4-4cf5-87c6-38b8c2a5b99f',
  NUTRITIONIST = 'd7c1cfa2-6820-4c4d-8f8f-9b79fb80f85d',
  REGISTERED_NURSE = 'aa45c799-4c6b-4e20-aa36-10b08fbf01c4',
  OFFICE_MANAGER = '34c463c1-8f71-4d21-a4e3-5ed10d331418',
  OPERATIONS_MANAGER = 'e4f9ed8e-57a9-409d-8e3f-6c17b03cf340',
  OPERATOR = '37e02cde-36cc-4523-a8f7-d9eb4004f0eb',
  OWNER = '31f27a96-4bfe-473e-b41b-6a2b8f93aa4c',
  CLEANERS_PRESSER = '8865a2a2-1dbb-4d16-a1b2-4a8cb2d39e6e',
  PAINTER = '3f0f94a4-78d2-4f54-8036-245835b94db3',
  PLUMBER = '2e9709f2-6e56-4a43-9c15-d55635c1d3c0',
  POSTAL_CLERK = 'd5a392bb-51fc-42f1-b84a-680773d543ed',
  PARAMEDIC = '9d35cc8c-950f-4880-a4e5-607349a50e1a',
  PROFESSOR = 'be637229-1cbb-44c7-9eb2-6b7df282bb68',
  PHARMACIST = 'ac856ed0-4cbf-4623-a363-249d6ebc70d8',
  PILOT = '50f64fb2-dba5-4099-95c2-ee3a71746d81',
  PRESSMAN = '8f22ec06-c4b6-4f49-93e3-b7c77f54696d',
  PENSIONER = '3cb6e1db-b70b-44e7-bfce-6f1a595a03e4',
  POLICE_OFFICER = '88bc6552-b219-4a94-a92e-52aaf04f334e',
  PARTNER = '55a7da46-0c5b-4564-87a0-240463ea62c1',
  PRESIDENT = 'ba0d8e68-4d8a-444e-a006-d8d7dd3123a3',
  PSYCHIATRIST = '9a4b93a2-cf13-4a77-bdb4-d573bb9a2d25',
  PRINTER = 'a89ff577-849e-448b-9a20-4a793eb68d3b',
  PRODUCTION_WORKER = '9a6a08c8-5ae9-47b8-9865-7f3e1b0ed56e',
  RABBI = '15e1b6d7-7797-4684-b6bb-65473d2f68a6',
  RECEPTIONIST = 'f04565b7-1b8e-47c9-a18a-39544ad2b70c',
  ROOFER = '9b0051a3-b3f8-40d7-88c7-c5c234bfa9c0',
  RECEIVER = '31378943-8c81-4a7e-b3be-05f8f68e9ab1',
  REAL_ESTATE = '96c31ca5-89cc-4e85-b33f-9e7ebf2b30a1',
  RENTAL_INCOME = '897e0e4a-6d59-472c-a378-676d83ca2451',
  REVERAND = 'aebc798c-8e4d-4d79-913e-275df8f85a0e',
  REAL_ESTATE_SALES = 'cc07b54c-cdd6-4e7e-b703-9d0985fc66f0',
  REALTOR = '1a2e69ed-cde9-418a-879a-e3f40d253777',
  SECRETARY = '0b819c07-4e71-4fc0-b661-0d7d85bcaab6',
  SEAMSTRESS = '8e0a9b6b-9cd7-46b8-8c9c-18ca8716f197',
  SECURITY_GUARD = 'cdd94621-0499-42d0-bb9c-8f9e12d47f32',
  SHIPPER = '10026ba2-b22b-45c8-9ea1-c181a3d2e1cc',
  SOCIAL_WORKER = 'd8b71835-15df-47c7-870c-0edfc8103a13',
  PERSONAL_SUPPORT_WORKER = 'a41f90eb-0bb7-4b3e-bfd8-5fc3728ac5c2',
  SALES_REP = '79e5299f-86c3-48b9-89f7-efb0b6c5e1d6',
  SERVICE_STATION_ATTENDANT = '50eaa1b3-6a52-43f8-bc6d-17822ab1cb2d',
  STRIPPER = 'ab8c7b17-2d61-4e34-899c-4d6926aedd8a',
  SUPERVISOR = 'efff3fe2-3d2a-4782-a5a9-5a9ac48ed6cd',
  SERVICE_REPRESENTATIVE = 'c9d2bf16-810d-40e3-9782-d43c33b6b95e',
  TAILOR = '0ecf82e3-298b-40e4-989a-775f8c1a7b44',
  TECHNICIAN = 'ca4bf694-8837-47e5-a4ab-01e5aa4d21a0',
  TRUCK_DRIVER = 'b9f9edc7-18fc-4c46-b153-e2ed6e7266b2',
  TEACHER = '68b90ad1-e80f-4f84-b8fc-63b1d11d90d8',
  TELLER = '2ca823bb-15aa-4635-bb3a-1f6e5e4be3ca',
  TREASURER = 'c5e702ea-2261-4399-a140-64a2f272b3d9',
  TTC_DRIVER = '1f613a97-8610-4b13-9e4e-49314b09c80f',
  TUTOR = '05509809-c77f-4ce7-bde9-93b4214b7f3d',
  TECHNICAL_WRITER = '0662552c-7794-4615-9a17-5964b87788ed',
  DIRECTOR_OF_TAXATION = '35e19a82-58e5-4bf4-8281-4fc0525792e9',
  MORTGAGE_UNDERWRITER = '3fb9b2a3-8d5b-4d43-9d49-9aee69330e5e',
  VETERINARIAN = 'bdf4f7a3-c1bb-4283-8431-bfb7f3943481',
  VICE_PRESIDENT = 'a5abdb0a-94bf-4c60-8e4b-8bea40488e7e',
  WAITER = 'd890da17-6f02-4e68-9f35-8e805efbf123',
  WELDER = '5791c48d-47e5-47dd-954f-76d0a259ceea',
  WORM_PICKER = 'b7ee8717-3fda-4f5e-a5b3-85cfb4a263e7',
  WRAPPER = '8b0b413e-26f9-4f0c-9a04-b99ff4597819',
  WAITRESS = '8c859595-ee3b-4e44-9f3a-10f830b6d0f7',
  ZOOLOGIST = 'e1c7428a-394b-44d5-92a7-4d76e6e08d79',
}
