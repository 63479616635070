import { IndustrySectorType, OccupationType } from './applicant';
import { IncomePeriod } from './enums';
import { IncomeType } from './income';
import { CustomEQOccupationType } from './job';

export interface ApplicantOtherIncome {
  applicantId: string;
  otherIncomeId?: string;
}

export interface ApplicationOtherIncome {
  id: string;
  isCurrent?: boolean;
  unableToVerify?: boolean;
  applicationId: string;
  type: IncomeType;
  description: string;
  period: IncomePeriod;
  amount: number;
  startDate: string | null;
  endDate: string | null;
  createdAt?: string;
  updatedAt?: string;
  occupation?: OccupationType | CustomEQOccupationType | undefined;
  ApplicantOtherIncomes: ApplicantOtherIncome[];
  externalSequenceNumber?: number | null;
  timeAtIndustryMonths?: number;
  timeAtJobMonths?: number;
  industrySector?: IndustrySectorType;
}

export enum OtherIncomeKey {
  TYPE = 'type',
  PERIOD = 'period',
  DESCRIPTION = 'description',
  AMOUNT = 'amount',
}
