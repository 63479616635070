import { Applicant, NetworthContext } from './applicant';
import { ApplicationNote } from './applicationNote';
import { FinancialAsset } from './asset';
import { ApplicationBrokerCommission } from './brokerCommission';
import { ApplicationDecisionEngineFlags } from './decisionEngine/applicationDecisionEngineFlags';
import { DownPayment } from './downPayment';
import {
  ApplicationCreateProductsState,
  ApplicationCreditCardFulfillState,
  ApplicationStatusType,
  ApplicationType,
  MortgageClassificationType,
  SourceOfBusinessType,
} from './enums';
import { EQClosingService, LendingTierType } from './eqMaps';
import { ExternalDeal } from './externalDeal';
import { Fee } from './fee';
import { Fund } from './fund';
import { InsuranceQuote } from './insurance/insuranceQuote';
import { FinancialLiability } from './liability';
import { DrawSchedule, ExpandedLoanNumber, Mortgage } from './mortgage';
import { MortgageComputedData } from './mortgageComputedData';
import { ApplicationOtherIncome } from './otherIncome';
import { Property } from './property';
import { ApplicationAdvancedProduct } from './rateMatrix/applicationAdvancedProduct';
import { Summary } from './summary';
import { Role } from './user';

export interface Application {
  id: string;
  ezidoxApplicationId: number;
  tenantId: string;
  name: string;
  code: string;
  applicationDate: string;
  priority?: PriorityType | null;
  status: DocumentRequestStatus;
  createdAt: string;
  updatedAt: string;
  unread?: boolean;
  currentStage?: ApplicationStage;
  previousStage?: ApplicationStage;
  docsDueDate: string;
  declineComment: string;
  declineReasonId: string;
  declineDate?: string;
  declineSource?: DeclineSource;
  source: ApplicationSource;
  archived: boolean;
  fundId: string;
  clonedApplicationId: string;
  purpose: ApplicationPurposeType;
  mortgageClassification?: MortgageClassificationType | null;
  locked: boolean;
  metadata: ApplicationMetadata;
  filogixDealId: string;
  externalDealId: string;
  dealType: ApplicationType;
  externalApplicationId: string;
  externalApplicationGUID?: string | null;
  sentToIntellifi: boolean;
  createdBy: string;
  archivedByUserId?: string;
  lineOfBusinessId?: string;
  brokerNotes: string; // Deprecated
  brokerNotesDate: Date;
  closestUncompletedTask: ClosestUncompletedTask | null;
  isCombo: boolean;
  closingService: EQClosingService;
  lendingTier: LendingTierType;
  dmNoteCount: number;
  ezidoxStatusId?: number;
  ezidoxStatusString?: string;
  originalData?: string;
  commitmentIssueDate?: string | null;
  commitmentExpiryDate?: string | null;
  quickCloseFlag?: boolean;
  rateHoldExpiryDate?: string | null;
  restricted: boolean;
  sourceOfBusiness?: SourceOfBusinessType | null;
  securedAggregate?: number | null;
  unsecuredAggregate?: number | null;
  mortgageSecuredAggregate?: number | null;
  cashSecuredAggregate?: number | null;
  bridgeAggregate?: number | null;
  purposeCode?: PurposeCode | null;
  totalBusinessConnection?: number | null;
  isLatestSubmission: boolean;
  productMemberOptions?: { [key: string]: string[] }[] | null;
  createProductsState: ApplicationCreateProductsState;
  createProductsError?: string | null;
  creditCardFulfillState: ApplicationCreditCardFulfillState;
  creditCardFulfillError?: string | null;

  ApplicationDecisionEngineFlags: ApplicationDecisionEngineFlags;
  ApplicationDolphinIntegrations: ApplicationDolphinIntegration[];
  ApplicationVerifications: ApplicationVerification[];
  ApplicationAssignedUsers: ApplicationAssignedUser[];
  ApplicationStatus: ApplicationStatus;
  ConstructionSchedules: DrawSchedule[];
  applicants: Applicant[];
  OtherIncomes: ApplicationOtherIncome[];
  DownPayments: DownPayment[];
  FinancialAssets: FinancialAsset[];
  FinancialLiabilities: FinancialLiability[];
  Mortgages: Mortgage[];
  Properties: Property[];
  Fees: Fee[];
  customFields: { [key: string]: string };
  warnings?: ApplicationWarningKeys[];
  ApplicationAdvancedProducts: ApplicationAdvancedProduct[];
  uiAbstractPermissions?: ApplicationPermissions;
  ExternalDeal?: ExternalDeal;
  ApplicationComputedData?: ApplicationComputedData;
  MortgageComputedData?: MortgageComputedData[];
  ApplicationNotes?: ApplicationNote[];
  InsuranceQuotes?: InsuranceQuote[];
  restrictedPartyMember?: boolean;
  staffMember?: boolean;
  connectedParty?: boolean;
  applicationLoanNumber?: string;
  applicationExpandedLoanNumber?: ExpandedLoanNumber;
  ApplicationBrokerCommissions?: ApplicationBrokerCommission[];
  Fund?: Fund;
}

export enum DeclineSource {
  DECLINE_APPLICATION = 'DECLINE_APPLICATION',
  CANCEL_APPLICATION = 'CANCEL_APPLICATION',
}

export interface NetWorth {
  currentNetWorth: NetworthContext;
  futureNetWorth: NetworthContext;
}

export interface ApplicationComputedData {
  id: string;
  applicationId: string;
  calculation: Summary;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  score: any; // FundmoreScoreOutput, it's from score-octopus
  netWorth: NetWorth;
}

export enum ApplicationComputedDataJSONKey {
  GDS = 'gds',
  TDS = 'tds',
  QGDS = 'qgds',
  QTDS = 'qtds',
  LTV = 'ltv',
}

export enum MortgageComputedDataJSONKey {
  GDS = 'gds',
  TDS = 'tds',
  QGDS = 'qgds',
  QTDS = 'qtds',
  LTV = 'ltv',
  QUALIFYING_RATE = 'qualifyingRate',
}

export enum ApplicationNetWorthJSONKey {
  currentNetWorth = 'currentNetWorth',
  futureNetWorth = 'futureNetWorth',
}

export interface ApplicationMetadata {
  mergeIds: string[];
}

export interface ApplicationPermissions {
  hierarchyUserIds: string[];
  applicationStageMovePermissionsMap: {
    [key: string]: ApplicationStage[];
  };
  canAssignBroker: boolean;
  canAddApplicant: boolean;
  canAssignUsers: Role[];
  canBypassTasks: boolean;
  canChat: boolean;
  canCompleteAssignedTasks: boolean;
  canCompleteTasks: boolean;
  canCreateTasks: boolean;
  canUpdateApplicant: boolean;
  canDeleteApplicant: boolean;
  canEdit: boolean;
  canEditAssignedTasks: boolean;
  canEditGeneratedDocument: boolean;
  canEditPriority: boolean;
  canEditBaseRate: boolean;
  canEditBuyDownRate: boolean;
  canEditDiscount: boolean;
  canEditPrepaymentPeriod: boolean;
  canEditTasks: boolean;
  canFund: boolean;
  canIncludeInAPR: boolean;
  canMoveStage: MoveStageModel[];
  canRevertDecision: boolean;

  canArchiveApplication: boolean;
  canDecline: boolean;
  canDeleteApplication: { stage: ApplicationStage[] }[];

  canUploadConditionDocument: boolean;
  canEditConditionDocument: boolean;
  canReviewConditionDocument: boolean;
  canCommentOnConditionDocument: boolean;
  canDeleteConditionDocument: boolean;

  canAddApplicationCondition: boolean;
  canReviewApplicationCondition: boolean;
  canDeleteApplicationCondition: boolean;

  canSendToServicing: boolean;
  canPullEquifaxReport: boolean;
  canCreateApplicationNotes: boolean;

  canCreateAndEditApplicationRiskFlag: boolean;
  canResolveFailApplicationRiskFlag: boolean;
  canResolveManualReviewApplicationRiskFlag: boolean;

  canAdjudicateRestrictedPartyApplications: boolean;
  canAdjudicateStaffMemberApplications: boolean;

  canSubmitToDecisionEngine: boolean;

  canUpdateMortgageIncrements: boolean;
  canCreateCustomIncrements: boolean;

  canUpdateMortgageDecrements: boolean;

  canUpdateMortgageDiscretion: boolean;
}

export interface ApplicationDetails {
  id: string;
  applicationName: string;
  primaryApplicantName: string;
  coborrowerNames: string[];
  currentStage?: ApplicationStage;
  brokerNames: string[];
  primaryPropertyAddress: string;
  mortgagePurpose: string;
  underwriterName: string;
  updatedAt: string;
}

export interface ArchiveStage {
  enabled?: boolean;
  dayInterval?: string;
}

export interface AutomaticArchivingConfig {
  [ApplicationStage.DECLINED]?: ArchiveStage;
  [ApplicationStage.FUNDED]?: ArchiveStage;
  other: ArchiveStage;
}

const NonPostFundingApplicationStage = {
  NEW_APPLICATION: 'NEW_APPLICATION',
  UNDERWRITING: 'UNDERWRITING',
  ADJUDICATION: 'ADJUDICATION',
  DEAL_ACCEPTANCE: 'DEAL_ACCEPTANCE',
  DOCUMENT_REVIEW: 'DOCUMENT_REVIEW',
  OPERATIONS_FULFILLMENT: 'OPERATIONS_FULFILLMENT',
  PRESENT_DEAL: 'PRESENT_DEAL',
  PROPOSE_DEAL: 'PROPOSE_DEAL',
  DEAL_SIGNED: 'DEAL_SIGNED',
  COMPLIANCE: `COMPLIANCE`,
  FINAL_APPROVAL: 'FINAL_APPROVAL',
  PRE_FUND: 'PRE_FUND',
  LAWYER_INSTRUCTED: 'LAWYER_INSTRUCTED',
  FINAL_REVIEW: 'FINAL_REVIEW',
  FUNDED: 'FUNDED',
  DECLINED: 'DECLINED',
} as const;

export type NonPostFundingApplicationStage =
  (typeof NonPostFundingApplicationStage)[keyof typeof NonPostFundingApplicationStage];

export const PostFundingApplicationStage = {
  FORECLOSURE: 'FORECLOSURE',
  PAID_OUT: 'PAID_OUT',
  PAST_DUE_UNDER_90: 'PAST_DUE_UNDER_90',
  PAST_DUE_OVER_90: 'PAST_DUE_OVER_90',
  PAID_IN_FULL: 'PAID_IN_FULL',
  RENEWAL: 'RENEWAL',
} as const;

export type PostFundingApplicationStage =
  (typeof PostFundingApplicationStage)[keyof typeof PostFundingApplicationStage];

export const ApplicationStage = {
  ...NonPostFundingApplicationStage,
  ...PostFundingApplicationStage,
} as const;
export type ApplicationStage = (typeof ApplicationStage)[keyof typeof ApplicationStage];

export const stagesOrder: Record<ApplicationStage, number> = {
  [ApplicationStage.NEW_APPLICATION]: 1,
  [ApplicationStage.UNDERWRITING]: 2,
  [ApplicationStage.ADJUDICATION]: 3,
  [ApplicationStage.DEAL_ACCEPTANCE]: 4,
  [ApplicationStage.DOCUMENT_REVIEW]: 5,
  [ApplicationStage.OPERATIONS_FULFILLMENT]: 6,
  [ApplicationStage.PRESENT_DEAL]: 7,
  [ApplicationStage.PROPOSE_DEAL]: 8,
  [ApplicationStage.DEAL_SIGNED]: 9,
  [ApplicationStage.COMPLIANCE]: 10,
  [ApplicationStage.FINAL_APPROVAL]: 11,
  [ApplicationStage.PRE_FUND]: 12,
  [ApplicationStage.LAWYER_INSTRUCTED]: 13,
  [ApplicationStage.FINAL_REVIEW]: 14,
  [ApplicationStage.FUNDED]: 15,
  [ApplicationStage.DECLINED]: 16,
  [ApplicationStage.FORECLOSURE]: 17,
  [ApplicationStage.PAID_OUT]: 18,
  [ApplicationStage.PAST_DUE_UNDER_90]: 19,
  [ApplicationStage.PAST_DUE_OVER_90]: 20,
  [ApplicationStage.PAID_IN_FULL]: 21,
  [ApplicationStage.RENEWAL]: 22,
};

export enum ApplicationPurposeType {
  PURCHASE = 'PURCHASE',
  REFINANCE = 'REFINANCE',
  ETO = 'ETO',
  SWITCH_TRANSFER = 'SWITCH_TRANSFER',
  RENEWAL = 'RENEWAL',
  CONSTRUCTION = 'CONSTRUCTION',
  BRIDGE_FINANCING = 'BRIDGE_FINANCING',
  OTHER = 'OTHER',
  DEFICIENCY_SALE = 'DEFICIENCY_SALE',
  WORKOUT = 'WORKOUT',
}

export enum PurchaseMortgageClassification {
  PURCHASE = 'PURCHASE',
  PURCHASE_PLUS_IMPROVEMENT = 'PURCHASE_PLUS_IMPROVEMENT',
  REVERSE_MORTGAGE = 'REVERSE_MORTGAGE',
  FLIP = 'FLIP',
  PURCHASE_WITH_HELOC = 'PURCHASE_WITH_HELOC',
  PORT = 'PORT',
  ASSUMPTION = 'ASSUMPTION',
  NEW_BUILD = 'NEW_BUILD',
  PRIVATE = 'PRIVATE',
  RENT_TO_OWN = 'RENT_TO_OWN',
}

export enum RefinanceMortgageClassification {
  REFINANCE = 'REFINANCE',
  REVERSE_MORTGAGE = 'REVERSE_MORTGAGE',
  HELOC = 'HELOC',
}

export enum ETOMortgageClassification {
  ETO = 'ETO',
  HELOC = 'HELOC',
}

export enum DeficiencySaleMortgageClassification {
  DEFICIENCY_SALE = 'DEFICIENCY_SALE',
}

export enum WorkoutMortgageClassification {
  WORKOUT = 'WORKOUT',
}

export enum SwitchTransferClassification {
  SWITCH_TRANSFER_COLLATERAL = 'SWITCH_TRANSFER_COLLATERAL',
  SWITCH_TRANSFER_STANDARD = 'SWITCH_TRANSFER_STANDARD',
}

export enum RenewalClassification {
  RENEWAL = 'RENEWAL',
}

export enum ConstructionClassification {
  CONSTRUCTION = 'CONSTRUCTION',
  CONSTRUCTION_NEW_BUILD = 'CONSTRUCTION_NEW_BUILD',
  CONSTRUCTION_EXISTING_PROPERTY = 'CONSTRUCTION_EXISTING_PROPERTY',
  CONSTRUCTION_TAKEOVER = 'CONSTRUCTION_TAKEOVER',
}

export enum BridgeFinancingClassification {
  BRIDGE_FINANCING_WITH_PURCHASE = 'BRIDGE_FINANCING_WITH_PURCHASE',
  BRIDGE_FINANCING_WITHOUT_PURCHASE = 'BRIDGE_FINANCING_WITHOUT_PURCHASE',
}

export enum OtherClassification {
  OTHER = 'OTHER',
}

export enum TaskUrgency {
  MINOR = 'MINOR',
  URGENT = 'URGENT',
  OVERDUE = 'OVERDUE',
}

export interface ClosestUncompletedTask {
  time?: string;
  urgency?: TaskUrgency;
}

export interface ApplicationDolphinIntegration {
  databaseCode: string;
  createdAt: Date;
  updatedAt: Date;
  dolphinServicingData: unknown;
}

export interface EqMapsHistory {
  applicationId: string;
  operation: MapsSyncOperation;
}

export enum MapsSyncOperation {
  LOAN_DECISION = 'LOAN_DECISION',
  LOAN_UPDATE = 'LOAN_UPDATE',
}

export enum MapsSyncOperationStatus {
  LOAN_UPDATE_STARTED = 'LOAN_UPDATE_STARTED',
  LOAN_UPDATE_COMPLETED = 'LOAN_UPDATE_COMPLETED',
  LOAN_UPDATE_FAILED = 'LOAN_UPDATE_FAILED',
  LOAN_DECISION_STARTED = 'LOAN_DECISION_STARTED',
  LOAN_DECISION_COMPLETED = 'LOAN_DECISION_COMPLETED',
  LOAN_DECISION_FAILED = 'LOAN_DECISION_FAILED',
}

export interface ApplicationVerification {
  id: string;
  tenantId: string;
  applicationId: string;
  applicantId: string;
  field: string;
  source: string;
  createdAt: string;
  updatedAt: string;
  meta: {
    document: {
      name: string;
      category: string;
    };
    confidence: string;
  };
}

export interface ApplicationAssignedUser {
  userId: string;
  roleId: string;
}

export enum ApplicationAssignedUserKey {
  Id = 'id',
  APPLICATION_ID = 'applicationId',
  USER_ID = 'userId',
  ROLE_ID = 'roleId',
}

export interface ApplicationStatus {
  applicationId: string;
  status: ApplicationStatusType;
  underwriterId?: string;
  lenderNotes?: string;
  content:
    | ApplicationApprovedContent
    | ApplicationDeclinedContent
    | ApplicationPendingContent
    | undefined;
  isCommitted: boolean;
  isCreditCardFulfilled: boolean;
  isAwaitingDocs: boolean;
  updatedAt: string;
}

export enum ApplicationStatusKey {
  APPLICATION_ID = 'applicationId',
  STATUS = 'status',
  UNDERWRITER_ID = 'underwriterId',
  LENDESK_NOTES = 'lenderNotes',
  DOCUMENT_NAMES = 'documentNames',
  UPDATED_AT = 'updatedAt',
}

export enum ApplicationAdvancedProductKey {
  NAME = 'name',
  LOCATIONS = 'locations',
  LOC = 'isLOC',
  EDGE = 'isEdge',
  COMBO = 'isCombo',
  INSURED = 'isInsured',
  REAPPLY = 'isReapply',
  INFO = 'info',
}

export interface ApplicationApprovedContent {
  documentId: string;
}

export interface ApplicationDeclinedContent {
  declineReasonId: string;
}
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ApplicationPendingContent {}

export enum ApplicationKey {
  NAME = 'name',
  APPLICATION_NOTES = 'applicationNotes',
  APPLICATION_DATE = 'applicationDate',
  DOWN_PAYMENT_TOTAL = 'downPaymentTotal',
  DOCS_DUE_DATE = 'docsDueDate',
  DECLINE_COMMENT = 'declineComment',
  PURPOSE = 'purpose',
  MORTGAGE_CLASSIFICATION = 'mortgageClassification',
  CURRENT_STAGE = 'currentStage',
  DECLINE_REASON = 'declineReasonId',
  TYPE = 'dealType',
  EXTERNAL_APPLICATION_ID = 'externalApplicationId',
  IS_COMBO = 'isCombo',
  CLOSING_SERVICE = 'closingService',
  LENDING_TIER = 'lendingTier',
  COMMITMENT_ISSUE_DATE = 'commitmentIssueDate',
  COMMITMENT_EXPIRY_DATE = 'commitmentExpiryDate',
  QUICK_CLOSE_FLAG = 'quickCloseFlag',
  RATE_HOLD_EXPIRY_DATE = 'rateHoldExpiryDate',
  SOURCE_OF_BUSINESS = 'sourceOfBusiness',
  PURPOSE_CODE = 'purposeCode',
  TOTAL_BUSINESS_CONNECTION = 'totalBusinessConnection',
  APPLICATION_LOAN_NUMBER = 'applicationLoanNumber',
  FUND_ID = 'fundId',
}

export enum ApplicationWarningKeys {
  NO_UNDERWRITER_AUTO_ASSIGNED_WARNING = 'no_underwriter_auto_assigned_warning',
  LOAN_NUMBER_GENERATION_FAILED_WARNING = 'loan_number_generation_failed_warning',
  LOS_APPROVED_WARNING = 'los_approved_failed_warning',
  LOS_PENDING_WARNING = 'los_pending_failed_warning',
  LOS_DECLINED_WARNING = 'los_declined_failed_warning',
  PRODUCT_CHANGE_WARNING = 'product_change_warning',
}

export enum WarningActions {
  ADDED = 'added',
  RESOLVED = 'resolved',
}

export enum CloseOptions {
  DELETE = 'Delete',
  ARCHIVE = 'Archive',
}

export const CloseOptionsValues: Record<CloseOptions, string> = {
  [CloseOptions.ARCHIVE]: 'Archive',
  [CloseOptions.DELETE]: 'Delete',
};

export interface MoveStageModel {
  from: ApplicationStage[];
  to: ApplicationStage[];
}

export enum ApplicationSource {
  DOLPHIN = 'DOLPHIN',
  UPLOAD = 'UPLOAD',
  MANUAL = 'MANUAL',
  COPY = 'COPY',
  VELOCITY = 'VELOCITY',
  FILOGIX = 'FILOGIX',
  LENDESK = 'LENDESK',
  MERGE = 'MERGE',
  EQ_MAPS_VELOCITY = 'EQ_MAPS_VELOCITY',
  EQ_MAPS_FILOGIX = 'EQ_MAPS_FILOGIX',
  EQ_MAPS_LENDESK = 'EQ_MAPS_LENDESK',
  EQ_MAPS_MBOSS = 'EQ_MAPS_MBOSS',
}

export enum DocumentRequestStatus {
  PROVISIONING = 'PROVISIONING',
  IN_PROGRESS = 'IN PROGRESS',
}

export enum PriorityType {
  CRITICAL = 'CRITICAL',
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export const PriorityTypeOrder: Record<PriorityType, number> = {
  [PriorityType.CRITICAL]: 1,
  [PriorityType.HIGH]: 2,
  [PriorityType.MEDIUM]: 3,
  [PriorityType.LOW]: 4,
};

export enum ApplicationDefaultValues {
  COMMITMENT_EXPIRY_DATE_OFFSET_DAYS = 10,
  RATE_HOLD_EXPIRY_DATE_OFFSET_DAYS = 120,
  RATE_HOLD_EXPIRY_DATE_OFFSET_DAYS_QUICK_CLOSE = 60,
}

export enum PurposeCode {
  CONSOLIDATION_REFINANCE = 'CONSOLIDATION_REFINANCE',
  AUTO_USED_REPAIRS_INSURANCE = 'AUTO_USED_REPAIRS_INSURANCE',
  NEW_AUTO = 'NEW_AUTO',
  RECREATIONAL_VEHICLES = 'RECREATIONAL_VEHICLES',
  BOATS = 'BOATS',
  VACATIONS = 'VACATIONS',
  FURNITURE = 'FURNITURE',
  HOME_REPAIRS_RENOVATIONS = 'HOME_REPAIRS_RENOVATIONS',
  TAXES = 'TAXES',
  BRIDGE_FINANCING = 'BRIDGE_FINANCING',
  INVESTMENT = 'INVESTMENT',
  CONSTRUCTION_MULTIPLE_DRAWS = 'CONSTRUCTION_MULTIPLE_DRAWS',
  PURCHASE_HOUSING_RENTAL = 'PURCHASE_HOUSING_RENTAL',
  PURCHASE_HOUSING_OWNER_OCCUPIED = 'PURCHASE_HOUSING_OWNER_OCCUPIED',
  PURCHASE_REAL_ESTATE_VACANT_LAND = 'PURCHASE_REAL_ESTATE_VACANT_LAND',
  PURCHASE_REAL_ESTATE_OTHER_VACATION = 'PURCHASE_REAL_ESTATE_OTHER_VACATION',
  MANUFACTURED_HOME_NEW = 'MANUFACTURED_HOME_NEW',
  MISCELLANEOUS = 'MISCELLANEOUS',
  RRSP_PURCHASE = 'RRSP_PURCHASE',
  LETTER_OF_CREDIT = 'LETTER_OF_CREDIT',
  MORTGAGE_ASSIGNMENTS = 'MORTGAGE_ASSIGNMENTS',
  FIRST_HOME = 'FIRST_HOME',
  CONDITIONAL_SALES_CONTRACT = 'CONDITIONAL_SALES_CONTRACT',
  RESTRUCTURE = 'RESTRUCTURE',
  EQUITY_DEAL = 'EQUITY_DEAL',
  MOBILE_HOME = 'MOBILE_HOME',
  MERITLINE = 'MERITLINE',
  CREDITLINE = 'CREDITLINE',
  FARM_PURCHASE = 'FARM_PURCHASE',
  FARM_OPERATING = 'FARM_OPERATING',
  FARM_EQUIPMENT = 'FARM_EQUIPMENT',
  COMMERCIAL_PROPERTY = 'COMMERCIAL_PROPERTY',
  COMMERCIAL_OPERATING = 'COMMERCIAL_OPERATING',
  COMMERCIAL_EQUIPMENT = 'COMMERCIAL_EQUIPMENT',
  OTHER_EQUIPMENT = 'OTHER_EQUIPMENT',
  POSTAL_LETTER_OF_GUARANTEE = 'POSTAL_LETTER_OF_GUARANTEE',
  MORTGAGE_ASSUMPTIONS = 'MORTGAGE_ASSUMPTIONS',
  VARIABLE_RATE_DEALER_SPECIAL = 'VARIABLE_RATE_DEALER_SPECIAL',
  FIXED_RATE_AUTO = 'FIXED_RATE_AUTO',
  LOAN_SALE = 'LOAN_SALE',
  PURCHASE_BUILDER_HOME_UPON_COMPLETION = 'PURCHASE_BUILDER_HOME_UPON_COMPLETION',
  RESTORATION_CREDIT = 'RESTORATION_CREDIT',
  NEW_VENTURES = 'NEW_VENTURES',
  SBIL = 'SBIL',
  LIFE_LEASE = 'LIFE_LEASE',
  LEASEHOLD = 'LEASEHOLD',
  FARM_PLUS = 'FARM_PLUS',
  SYNDICATED_LOAN = 'SYNDICATED_LOAN',
  GRAPE_PLANTING = 'GRAPE_PLANTING',
  CORPORATE_OFFICE_APPROVED = 'CORPORATE_OFFICE_APPROVED',
  EXTENDED_AMORTIZATION = 'EXTENDED_AMORTIZATION',
  HYBRID_PROGRAM = 'HYBRID_PROGRAM',
  MEZZANINE_FINANCING = 'MEZZANINE_FINANCING',
  BULK_LEASING = 'BULK_LEASING',
  GREEN_AUTO = 'GREEN_AUTO',
  GREEN_LOAN = 'GREEN_LOAN',
  SPECIAL_CREDIT_EXP_LOCS = 'SPECIAL_CREDIT_EXP_LOCS',
  NON_MANAGEMENT_STAFF = 'NON_MANAGEMENT_STAFF',
  MANAGEMENT_STAFF = 'MANAGEMENT_STAFF',
  NON_PERFORMING_LOAN_STATUS = 'NON_PERFORMING_LOAN_STATUS',
  BANKRUPT_LOAN_STATUS = 'BANKRUPT_LOAN_STATUS',
  CASH_FLOW_SENIOR_TERM = 'CASH_FLOW_SENIOR_TERM',
  HASCAP = 'HASCAP',
  HIGH_RATIO_OWNER_OCCUPIED_COMMERCIAL = 'HIGH_RATIO_OWNER_OCCUPIED_COMMERCIAL',
  FRANCHISING = 'FRANCHISING',
  CEBA_LOAN = 'CEBA_LOAN',
  CEBA_CONVERSION = 'CEBA_CONVERSION',
  BCAP_BDC_CO_LENDING = 'BCAP_BDC_CO_LENDING',
  REFINANCED_CEBA_LOAN = 'REFINANCED_CEBA_LOAN',
  BDC_ACCELERATOR_GUARANTEE_PROGRAM = 'BDC_ACCELERATOR_GUARANTEE_PROGRAM',
  TRAILERS_SNOWMOBILES_ATVS = 'TRAILERS_SNOWMOBILES_ATVS',
  BUILD_A_PROPERTY = 'BUILD_A_PROPERTY',
  BUSINESS_PURPOSES = 'BUSINESS_PURPOSES',
  CONSOLIDATION_OF_EXTERNAL_DEBT = 'CONSOLIDATION_OF_EXTERNAL_DEBT',
  CONSOLIDATION_OF_INTERNAL_DEBT = 'CONSOLIDATION_OF_INTERNAL_DEBT',
  DOWN_PAYMENT_FOR_PROPERTY_PURCHASE = 'DOWN_PAYMENT_FOR_PROPERTY_PURCHASE',
  FUTURE_USE = 'FUTURE_USE',
  MOTORCYCLE = 'MOTORCYCLE',
  PROPERTY_PURCHASE_FROM_EXISTING_EQUITY = 'PROPERTY_PURCHASE_FROM_EXISTING_EQUITY',
  RECREATIONAL_HOME_OR_MOTORHOME = 'RECREATIONAL_HOME_OR_MOTORHOME',
  SECONDARY_RESIDENCE = 'SECONDARY_RESIDENCE',
  TUITION = 'TUITION',
  USED_VEHICLE = 'USED_VEHICLE',
}
