export * from './calculationPeriod';
export * from './dealSource';
export * from './dealStatus';
export * from './documentSource';
export * from './documentType';
export * from './errorId';
export * from './fileFormat';
export * from './guarantorType';
export * from './insurer';
export * from './milestoneCode';
export * from './mortgagePriority';
export * from './mortgageType';
export * from './mortgagorType';
export * from './mortgagorTitle';
export * from './occupancyType';
export * from './paymentPeriod';
export * from './province';
export * from './transactionStatus';
export * from './transactionType';

export enum MMSEnums {
  MMSDealSource = 'MMSDealSource',
  MMSDealStatus = 'MMSDealStatus',
  MMSDocumentSource = 'MMSDocumentSource',
  MMSDocumentType = 'MMSDocumentType',
  MMSErrorId = 'MMSErrorId',
  MMSFileFormat = 'MMSFileFormat',
  MMSGuarantorType = 'MMSGuarantorType',
  MMSInsurer = 'MMSInsurer',
  MMSMilestoneCode = 'MMSMilestoneCode',
  MMSMortgagePriority = 'MMSMortgagePriority',
  MMSMortgageType = 'MMSMortgageType',
  MMSMortgagorType = 'MMSMortgagorType',
  MMSMortgagorTitle = 'MMSMortgagorTitle',
  MMSOccupancyType = 'MMSOccupancyType',
  MMSPaymentPeriod = 'MMSPaymentPeriod',
  MMSProvince = 'MMSProvince',
  MMSTransactionStatus = 'MMSTransactionStatus',
  MMSTransactionType = 'MMSTransactionType',
  MMSCalculationPeriod = 'MMSCalculationPeriod',
}
