import {
  AddressExpanded,
  Applicant,
  ApplicantCreditCardOffer,
  ApplicantCreditCardOfferDetails,
  CustomerType,
  Job,
} from '@fundmoreai/models';

export interface ApplicantTypeUpdate {
  updatedApplicant: Applicant;
  updatedReplacementApplicant?: Applicant;
}

export class AddApplicant {
  static readonly type = '@applicants.AddApplicant';

  constructor(public applicationId: string, public newApplicant: Partial<Applicant>) {}
}

export class DeleteApplicant {
  static readonly type = '@applicants.DeleteApplicant';

  constructor(public applicant: Applicant) {}
}

export class DeleteApplicantJob {
  static readonly type = '@applicants.DeleteApplicantJob';

  constructor(public applicantId: string, public jobId: string) {}
}

export class PatchBulkApplicantsCreditScore {
  static readonly type = '@applicants.PatchBulkApplicantsCreditScore';

  constructor(public applicants: Applicant[]) {}
}

export class SetApplicants {
  static readonly type = '@applicants.SetApplicants';

  constructor(public applicants: Applicant[]) {}
}

export class SetSelectedApplicantId {
  static readonly type = '@applicants.SetSelectedApplicantId';

  constructor(public applicantId: string) {}
}

export class UpdateApplicant {
  static readonly type = '@applicants.UpdateApplicant';

  constructor(public applicant: Partial<Applicant>) {}
}

export class UpdateApplicantCredit {
  static readonly type = '@applicants.UpdateApplicantCredit';

  constructor(public applicant: Partial<Applicant>) {}
}

export class UpdateApplicantJob {
  static readonly type = '@applicants.UpdateApplicantJob';

  constructor(public applicantId: string, public job: Job) {}
}

export class SwitchApplicantJob {
  static readonly type = '@applicants.SwitchApplicantJob';

  constructor(public applicantId: string, public previousApplicantId: string, public job: Job) {}
}

export class UpdateApplicantMemberType {
  static readonly type = '@applicants.UpdateApplicantMemberType';

  constructor(
    public applicantId: string,
    public restrictedPartyMember: boolean,
    public staffMember: boolean,
    public isStudent: boolean | null,
  ) {}
}

export class UpdateStakeholderId {
  static readonly type = '@applicants.UpdateStakeholderId';

  constructor(public applicantId: string, public ezidoxStakeholderId: string) {}
}

export class AddOrUpdateApplicantAddressesExpanded {
  static readonly type = '@applicants.AddOrUpdateApplicantAddressesExpanded';

  constructor(public applicantId: string, public addressExpanded: AddressExpanded) {}
}

export class RemoveApplicantAddressesExpanded {
  static readonly type = '@applicants.RemoveApplicantAddressesExpanded';

  constructor(public applicantId: string, public addressExpandedId: string) {}
}

export class SwapApplicantType {
  static readonly type = '@applicants.SwapApplicantType';
  constructor(
    public applicantId: string,
    public newType: CustomerType,
    public replacementApplicantId?: string,
  ) {}
}

export class GenerateNewClientId {
  static readonly type = '@applicants.GenerateNewClientId';
  constructor(public applicantId: string) {}
}

export class UpdateApplicantClientId {
  static readonly type = '@applicants.UpdateApplicantClientId';
  constructor(public applicantId: string, public clientId: string | null) {}
}

export class MarkAsMemberForStakeholder {
  static readonly type = '@applicants.MarkAsMemberForStakeholder';
  constructor(
    public applicantId: string,
    public existingMemberOptionId: string,
    public memberNumber: string,
  ) {}
}

export class RefreshExistingMemberOptions {
  static readonly type = '@applicants.RefreshExistingMemberOptions';
  constructor(public applicantId: string) {}
}

export class StartClientScreening {
  static readonly type = '@applicants.StartClientScreening';
  constructor(public applicantId: string, public applicationId: string) {}
}

export class FetchApplicantsCreditCardOffers {
  static readonly type = '@applicants.FetchApplicantsCreditCardOffers';

  constructor(public applicationId: string) {}
}

export class UpdateApplicantCreditCardOffer {
  static readonly type = '@applicants.UpdateApplicantCreditCardOffer';

  constructor(
    public applicantId: string,
    public applicantCreditCardOffer: ApplicantCreditCardOffer,
  ) {}
}

export class UpdateApplicantCreditCardOfferDetails {
  static readonly type = '@applicants.UpdateApplicantCreditCardOfferDetails';

  constructor(
    public applicantId: string,
    public applicantCreditCardOfferDetails: ApplicantCreditCardOfferDetails,
  ) {}
}

export class CreateMembershipIds {
  static readonly type = '@applicants.CreateMembershipIds';

  constructor(public applicationId: string) {}
}

export class RetrieveJointMembershipIds {
  static readonly type = '@applicants.RetrieveJointMembershipIds';

  constructor(public applicationId: string) {}
}
