import { IDSGatewayBankingExistingMemberSearch } from './banking';
import { IDSGatewayBlocklist } from './blocklist';
import { IDSGatewayProblemDetails } from './problemDetails';

export enum ApplicantBlocklistScanResultStatus {
  HIT = 'HIT',
  NO_HIT = 'NO_HIT',
  ERROR = 'ERROR',
}

export interface IDSGatewayResponse {
  errorMessage?: string;
  response:
    | IDSGatewayBankingExistingMemberSearch[]
    | IDSGatewayBlocklist[]
    | IDSGatewayProblemDetails
    | null;
}
