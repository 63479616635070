import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageData, MessageInfoComponent } from '../portal/message-info.component';
import { AddNotification, FundmoreNotification } from '../shared';
import { patch, updateItem } from '@ngxs/store/operators';
import {
  ReadNotification,
  MuteNotifications,
  UnreadOnlyNotifications,
  ClearNotifications,
  ReadAllNotifications,
  UnreadNotification,
  ClearNotification,
} from './notification.state.actions';
import { AddNotifications, ResetState } from '../shared/state.model';
import { AddMessageAction, DeletePersistedMessages } from '../pubnub/pubnub.state';

interface FundmoreNotificationModel {
  version: number;
  notifications: FundmoreNotification[];
  mute: boolean;
  unreadOnly: boolean;
}

@State<FundmoreNotificationModel>({
  name: NotificationState.NAME,
  defaults: { ...NotificationState.DEFAULT_STATE_VALUES },
})
@Injectable()
export class NotificationState {
  static NAME = 'notification';
  static DEFAULT_STATE_VALUES: FundmoreNotificationModel = {
    version: 1,
    notifications: [],
    mute: false,
    unreadOnly: false,
  };
  constructor(private snackBar: MatSnackBar, private zone: NgZone) {}

  @Selector()
  static notifications(state: FundmoreNotificationModel) {
    return [...state.notifications].sort((a, b) =>
      a.addedAt && b.addedAt ? new Date(b.addedAt).getTime() - new Date(a.addedAt).getTime() : 0,
    );
  }

  @Selector()
  static hasUnreadNotifications(state: FundmoreNotificationModel) {
    return state.notifications.some((a) => a.seen !== true);
  }

  @Selector()
  static unreadNotificationsCount(state: FundmoreNotificationModel) {
    return state.notifications.filter((a) => a.seen !== true).length;
  }

  @Selector()
  static isMuted(state: FundmoreNotificationModel) {
    return state.mute;
  }

  @Selector()
  static unreadOnly(state: FundmoreNotificationModel) {
    return state.unreadOnly;
  }

  @Action(AddNotification)
  add(ctx: StateContext<FundmoreNotificationModel>, action: AddNotification) {
    const state = ctx.getState();
    ctx.setState({
      ...state,
      notifications: [
        ...state.notifications,
        { ...action.notification, addedAt: action.notification.addedAt ?? new Date() },
      ],
    });

    if (state.mute) {
      return;
    }
    this.showMessage(action.notification);
  }

  @Action(AddNotifications)
  addNotifications(ctx: StateContext<FundmoreNotificationModel>, action: AddNotifications) {
    const state = ctx.getState();

    const newNotifications = action.notifications.filter(
      (n) => !state.notifications.some((f) => this.isTheSameNotification(f, n)),
    );

    // favor the pubnub notifications over the old ones, in case of changes from other sessions
    // replace the same notifications
    const existingNotifications = action.notifications.filter((n) =>
      state.notifications.some((f) => this.isTheSameNotification(f, n)),
    );
    const restoreNotifications = [
      ...state.notifications.filter(
        (n) => !action.notifications.some((f) => this.isTheSameNotification(f, n)),
      ),
      ...newNotifications.map((n) => ({ ...n, addedAt: n.addedAt ?? new Date() })),
      ...existingNotifications,
    ];

    ctx.setState({
      ...state,
      notifications: restoreNotifications,
    });
  }

  @Action(ReadAllNotifications)
  readAll(ctx: StateContext<FundmoreNotificationModel>) {
    const state = ctx.getState();

    state.notifications.forEach((n) => {
      if (n.seen !== true) {
        ctx.setState(
          patch({
            notifications: updateItem<FundmoreNotification>(
              (f) => f?.seen !== true,
              patch({ seen: true, readAt: new Date() }),
            ),
          }),
        );
      }
    });
  }

  @Action(ReadNotification)
  readOne(ctx: StateContext<FundmoreNotificationModel>, action: ReadNotification) {
    ctx.setState(
      patch({
        notifications: updateItem<FundmoreNotification>(
          (f) => f && this.isTheSameNotification(f, action.notification),
          patch({ seen: true, readAt: new Date() }),
        ),
      }),
    );
    action.notification.timetoken &&
      ctx.dispatch(new AddMessageAction(action.notification.timetoken.toString(), 'read'));
  }

  @Action(MuteNotifications)
  muteUnmute(ctx: StateContext<FundmoreNotificationModel>, action: MuteNotifications) {
    ctx.patchState({ mute: action.mute });
  }

  @Action(UnreadOnlyNotifications)
  unreadOnly(ctx: StateContext<FundmoreNotificationModel>, action: UnreadOnlyNotifications) {
    ctx.patchState({ unreadOnly: action.unread });
  }

  @Action(ClearNotifications)
  clear(ctx: StateContext<FundmoreNotificationModel>) {
    ctx.patchState({ notifications: [] });
    ctx.dispatch(new DeletePersistedMessages(undefined));
  }

  @Action(ResetState)
  reset(ctx: StateContext<FundmoreNotificationModel>) {
    ctx.dispatch(new ClearNotifications());
  }

  @Action(UnreadNotification)
  unreadNotification(ctx: StateContext<FundmoreNotificationModel>, action: UnreadNotification) {
    ctx.setState(
      patch({
        notifications: updateItem<FundmoreNotification>(
          (f) => f && this.isTheSameNotification(f, action.notification),
          patch({ seen: false, readAt: undefined }),
        ),
      }),
    );
    action.notification.timetoken &&
      ctx.dispatch(new AddMessageAction(action.notification.timetoken.toString(), 'unread'));
  }

  @Action(ClearNotification)
  clearNotification(ctx: StateContext<FundmoreNotificationModel>, action: ClearNotification) {
    ctx.patchState({
      notifications: ctx.getState().notifications.filter((n) => n !== action.notification),
    });
    action.notification.timetoken &&
      ctx.dispatch(new DeletePersistedMessages(action.notification.timetoken));
  }

  private isTheSameNotification = (state: FundmoreNotification, verify: FundmoreNotification) =>
    state.message === verify.message &&
    state.channel === verify.channel &&
    (state.addedAt instanceof Date ? state.addedAt.toISOString() : state.addedAt) ===
      (verify.addedAt instanceof Date ? verify.addedAt.toISOString() : verify.addedAt) &&
    state.applicationId === verify.applicationId &&
    state.title === verify.title;

  private showMessage(notification: FundmoreNotification) {
    const data = {
      message: notification.message,
      title: notification.title,
      duration: notification.notificationDuration ?? 10000,
    } as MessageData;

    this.zone.run(() =>
      this.snackBar.openFromComponent(MessageInfoComponent, {
        data: data,
        duration: data.duration,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['bg-grey', 'text-dark', 'text-pre-wrap', 'contrast'],
      }),
    );
  }
}
