import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, zip } from 'rxjs';
import { User, UserAccount } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import { Role, RolePermission } from './role.model';
import { ApplicationStage } from '@fundmoreai/models';
@Injectable({
  providedIn: 'root',
})
export class RoleService {
  private base = `${environment.fundmore_api_url}/roles`;

  constructor(private http: HttpClient) {}

  add(role: {
    description: string;
    name: string;
    assignable: boolean;
    ezidoxCollectorType?: number | null;
    notifyOnAssignment: boolean;
    emailAddressToNotify?: string | null;
    stages?: ApplicationStage[] | null;
  }): Observable<Role> {
    const newRole = {
      name: role.name,
      description: role.description,
      // TODO remove group once it's not a mandatory field on the server anymore
      group: ' ',
      assignable: role.assignable,
      ezidoxCollectorType: role.ezidoxCollectorType,
      notifyOnAssignment: role.notifyOnAssignment,
      emailAddressToNotify: role.emailAddressToNotify,
      stages: role.stages,
    };

    return this.http.post<Role>(`${this.base}`, newRole);
  }

  addRolePermission(roleId: string, permissionId: string) {
    return this.http.post<void>(`${environment.fundmore_api_url}/permissions/assign`, {
      roleId,
      permissionId,
    });
  }

  bulkAddRolePermissions(roleId: string, permissions: string[]) {
    return this.http.post<void>(
      `${environment.fundmore_api_url}/permissions/bulkAssign/${roleId}`,
      permissions,
    );
  }

  assignUsersToRole(users: User[], role: Role) {
    return zip(...users.map((user) => this.updateUserRole(user, [...user.roles, role])));
  }

  delete(roleId: string) {
    return this.http.delete<Role>(`${this.base}/${roleId}`);
  }

  getAll(): Observable<Role[]> {
    return this.http.get<Role[]>(this.base);
  }

  getPermissions(): Observable<RolePermission[]> {
    return this.http.get<RolePermission[]>(`${environment.fundmore_api_url}/permissions`);
  }

  getRolePermissions(roleId: string): Observable<RolePermission[]> {
    return this.http
      .get<{ permissions: RolePermission[] }>(`${this.base}/${roleId}`)
      .pipe(map((role) => role.permissions));
  }

  removeRolePermission(roleId: string, permissionId: string) {
    return this.http.delete<void>(`${environment.fundmore_api_url}/permissions/unassign`, {
      body: { roleId, permissionId },
    });
  }

  removeUserFromRole(user: User, role: Role) {
    const roles = [...user.roles];

    roles.splice(
      roles.findIndex((x) => x.id === role.id),
      1,
    );

    return this.updateUserRole(user, roles);
  }

  update(role: Role) {
    const newRole = {
      name: role.name,
      description: role.description,
      // TODO remove group once it's not a mandatory field on the server anymore
      group: ' ',
      assignable: role.assignable,
      ezidoxCollectorType: role.ezidoxCollectorType,
      notifyOnAssignment: role.notifyOnAssignment,
      emailAddressToNotify: role.emailAddressToNotify,
      stages: role.stages,
    };

    return this.http.patch<Role>(`${this.base}/${role.id}`, newRole);
  }

  private updateUserRole(user: User, roles: Role[]) {
    return this.http.put<UserAccount>(
      `${environment.api_url}/account/${user.externalAuthSystemId}/role`,
      { roles: roles.map((x) => x.id) },
    );
  }
}
