import { AddressExpanded } from './addressExpanded';

export enum CreditBureauType {
  EQUIFAX = 'EQUIFAX',
  TRANSUNION = 'TRANSUNION',
}

export enum EquifaxPaymentFrequency {
  SEMI_ANNUAL_PAYMENT = 'SEMI_ANNUAL_PAYMENTS',
  MONTHLY_PAYMENT = 'MONTHLY_PAYMENTS',
  BI_WEEKLY_PAYMENT = 'BI_WEEKLY_PAYMENTS',
  WEEKLY_PAYMENT = 'WEEKLY_PAYMENTS',
  QUARTERLY_PAYMENT = 'QUARTERLY_PAYMENTS',
  ANNUAL_PAYMENT = 'ANNUAL_PAYMENTS',
  BI_MONTHLY_PAYMENT = 'BI_MONTHLY_PAYMENTS',
  SEMI_MONTHLY_PAYMENT = 'SEMI_MONTHLY_PAYMENTS',
}

export enum EquifaxSubjectType {
  MAIN_SUBJECT = 'SUBJ',
  SPOUSE = 'SPOU',
}

export enum EquifaxHitStrengthIndicator {
  NO_HIT = '00',
  POSSIBLE_HIT = '01',
  REGULAR_HIT = '03',
  REGULAR_HIT_2 = '11',
  BIG_HIT = '07',
  BIG_HIT_2 = '15',
}

export enum EquifaxResponseType {
  CREDIT_REPORT = 'creditReport',
  ERROR = 'error',
}

export enum PublicRecordType {
  UNPAID_COLLECTION = 'Unpaid Collection',
  PAID_COLLECTION = 'Paid Collection',
  BANKRUPTCY = 'Bankruptcy',
  SECURED_LINE = 'Secured Line',
  JUDGEMENT = 'Judgment',
}

export enum EquifaxAddressType {
  CURRENT_ADDRESS = 'CA',
  FORMER_ADDRESS = 'FA',
  FORMER_ADDRESS_2ND = 'F2',
}

export enum AMLMappedKeys {
  SingleSourceHit = 'singleSourceHit',
  DualSourceHit = 'dualSourceHit',
  WaterfallProcess = 'waterfallProcessed',
  SingleSourceDecision = 'singleSourceDecision',
  OriginOfCreditFile = 'originOfCreditFile',
  CreditFileCreatedDate = 'creditFileCreatedDate',
  NumberOfTradesOnFile = 'numberOfTradesOnFile',
  OldestTradeDateOpened = 'oldestTradeOnFileOpenDate',
  UniqueNumber = 'uniqueNumberCID',
  InputDateOfBirth = 'inputDOB',
  InputName = 'inputName',
  InputAddress = 'inputAddress',
  LastNameMatch = 'lastNameMatch',
  FirstNameMatch = 'firstNameMatch',
  MiddleNameMatch = 'middleNameMatch',
  SuffixNameMatch = 'suffixMatch',
  AddressCivicMatch = 'civicMatch',
  AddressStreetNameMatch = 'streetNameMatch',
  AddressCityMatch = 'cityMatch',
  AddressPostalCodeMatch = 'postalCodeMatch',
  AddressProvinceMatch = 'provinceMatch',
  DateOfBirthMatch = 'dateOfBirthMatch',
  FirstFormerAddressMatch = 'firstFormerAddressMatch',
  SecondFormerAddressMatch = 'secondFormerAddressMatch',
  CurrentAddressReportedDate = 'currentAddressReportedDate',
  DateOfBirthAsReported = 'dateOfBirthAsReported',
  NameAsReported = 'nameAsReported',
  AddressAsReported = 'currentAddressAsReported',
}

export interface EquifaxRequestApplicantPayload {
  subjectType: EquifaxSubjectType;
  id: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  cellPhoneNumber: string | null;
  address: Partial<AddressExpanded>;
  sin: string | null;
  employer?: string;
  occupation?: string;
}

export interface EquifaxCreditReportRequestPayload {
  applicationId: string;
  applicants: EquifaxRequestApplicantPayload[];
}

export interface Delinquency {
  recordType: string;
  reportedDate: string;
  type: string;
}

export enum TradelineAccountType {
  OVERDRAFT_PROTECTION = 'BY',
  AUTO = 'AO',
  COMMERCIAL = 'AP',
  HOME_IMPROVEMENT_LOAN = 'AS',
  PERSONAL = 'AX',
  SECURED = 'AW',
  STUDENT = 'FZ',
  CONDITIONAL_SALES = 'BO',
  PROMISSORY_NOTE = 'CQ',
  LEASE = 'BL',
  DEMAND_LOAN = 'BZ',
  US_DOLLAR_ACCOUNT = 'FL',
  NOTE_LOAN = 'BG',
  MORTGAGE = 'EQ',
  RSP_LOAN = 'FF',
  SECOND_MORTGAGE = 'HL',
  CORPORATE_CREDIT_CARD = 'IV',
  CONSOLIDATION_LOAN = 'IX',
  HYBRID_ACCOUNT = 'JB',
  HOME_EQUITY = 'JC',
  UNSECURED = 'JK',
}
export interface Tradeline {
  reportedDate: string;
  creditor: {
    id: string;
    name: string;
    telephone: {
      areaCode: string;
      number: string;
      concatenated: string;
    };
  };
  accountId?: string;
  association: string;
  limit: number;
  recentPayment: number;
  balance: number;
  pastDue: number;
  mr: number;
  dateOpened: string;
  dateLastActive: string;
  portfolioType: string;
  paymentRate: string;
  accountStatus: string;
  latePaymentsInPastThirtyDays: number;
  latePaymentsInPastSixtyDays: number;
  latePaymentsInPastNinetyDays: number;
  previousHighPayments: {
    type: string;
    date: string;
  }[];
  updateSource: string;
  accountType: TradelineAccountType;
  delinquencies: Delinquency[];
  hasWrittenOff: boolean;
  description: string;
  status: string;
  paymentFrequency: EquifaxPaymentFrequency;
  monthlyPayment: number;
}

export interface Bankruptcy {
  recordType: PublicRecordType.BANKRUPTCY;
  reportedDate: string;
  institution: {
    // CourtId
    id: string;
    name: string;
  };
  accountId?: string; //CaseNumberAndTrustee
  typeOfBankruptcy: string;
  filer: string;
  intentOrDisposition: {
    data: string;
    date: string;
  };
  balance: number; // LiabilityAmount
  value: number; // AssetAmount
  description: string; //CaseNumberAndTrustee
}

export interface SecuredLine {
  recordType: PublicRecordType.SECURED_LINE;
  reportedDate: string;
  institution: {
    // CourtId
    id: string;
    name: string;
  };
  accountId?: string; // Part of SecuredLoanCreditorId.NameAddressAndAmount
  value?: number; // Part of SecuredLoanCreditorId.NameAddressAndAmount
  description: string; // Same as accountId
  maturityDate?: string; // MaturityDate
}

export interface Collection {
  recordType: PublicRecordType.PAID_COLLECTION | PublicRecordType.UNPAID_COLLECTION;
  reportedDate: string; // AssignedDate
  description: string; // AgencyId.Name
  institution: {
    // AgencyId
    id: string;
    name: string;
  };
  accountId?: string; // LedgerNumber
  account: string; // CollectionCreditor.AccountNumberAndOrName
  reason: string;
  value: number; // OriginalAmount
  balance: number; // BalanceAmount
  dateLastActive: string; // DateOfLastPayment
  paidDate?: string; // DatePaid
  verificationDate?: string; // VerificationDate
  updateSource: string;
}

export interface Judgement {
  recordType: PublicRecordType.JUDGEMENT;
  type: string; // $.code
  reportedDate: string; // DateFiled
  institution: {
    // CourtId
    id: string;
    name: string;
  };
  description: string; // CourtId.Name
  accountId?: string; // CaseNumber
  status: string; // Status.$.code or Narrative
  dateLastActive: string; // DateSatisfied
  value: number; // Amount
  verificationDate: string; // VerificationDate
  defendant: string;
  plaintiff: string;
  lawyerNameAddress: string;
}

export type AntiMoneyLaunderingDetails = Record<AMLMappedKeys, string>;

export type PublicRecord = Bankruptcy | SecuredLine | Collection | Judgement;

export interface EquifaxSubjectAddress {
  type: EquifaxAddressType;
  fullAddress: string;
  civicNumber?: string;
  streetName?: string;
  city?: string;
  province?: string;
  postalCode?: string;
}

export interface CreditReportData {
  ficoScore: number;
  ficoScoreCode?: string | null;
  bniScore: number;
  summary: string;
  antiMoneyLaundering?: AntiMoneyLaunderingDetails;
  tradelines: Tradeline[];
  inquiries: EquifaxInquiry[];
  publicRecords: PublicRecord[];
  addresses: EquifaxSubjectAddress[];
}

export interface EquifaxError {
  errorCode?: string;
  additionalInformation: string;
  description: string;
  actionDescription: string;
}

export interface EquifaxMappedErrorResponse {
  type: EquifaxResponseType.ERROR;
  errors: EquifaxError[];
}

export interface EquifaxReportWithErrorResponse extends EquifaxMappedErrorResponse {
  reports: { applicantId: string; subjectType: EquifaxSubjectType }[];
}

export interface MappedReportCreditFileDetail {
  dateOfRequest?: string;
  hitCode?: string;
  hitCodeDescription?: string;
  hitStrengthIndicatorCode?: EquifaxHitStrengthIndicator;
  hitStrengthIndicatorDescription?: string;
  warnings?: { code: string; description: string }[];
}

export interface MappedReport extends MappedReportCreditFileDetail {
  subjectFirstName?: string;
  subjectLastName?: string;
  subjectType: EquifaxSubjectType;
  dateOfBirth?: string;
  creditReport: Partial<CreditReportData>;
  applicantId: string;
}

export interface EquifaxMappedReportResponse {
  type: EquifaxResponseType.CREDIT_REPORT;
  reports: MappedReport[];
  pdfBase64?: string;
}

export interface CreditReport extends MappedReport {
  applicantId: string;
}

export interface EquifaxReport {
  type: EquifaxResponseType.CREDIT_REPORT;
  reports: CreditReport[];
  pdfBase64?: string;
  applicationDocumentId?: string;
}

export type EquifaxCreditReport = EquifaxReport | EquifaxReportWithErrorResponse;

export interface EquifaxLocalInquiry {
  date: string;
  type: EquifaxInquiryType.LOCAL;
  customer: {
    number: string;
    name: string;
    telephone: {
      areaCode: string;
      number: string;
      concatenated: string;
    };
  };
}

export interface EquifaxForeignInquiry {
  date: string;
  type: EquifaxInquiryType.FOREIGN;
  cityNameOrInquiryNumber: string;
  inquiryProvince: string;
}

export type EquifaxInquiry = EquifaxLocalInquiry | EquifaxForeignInquiry;

export enum EquifaxInquiryType {
  LOCAL = 'LOCAL',
  FOREIGN = 'FOREIGN',
}
