export enum ConstructionKey {
  GROSS_MORTGAGE_AMOUNT = 'grossMortgageAmount',
  LAND_ADVANCE = 'landAdvance',
  NET_LOAN_LESS_ADVANCE = 'netLoanLessLandAdvance',
  LAND_COST = 'landCost',
  BUILDING_BUDGET = 'buildingBudget',
  SQFT_PROJECT = 'sqftOfProject',
  COST_PER_SQFT = 'costPerSqft',
  BUILDER_NAME = 'builderName',
}
