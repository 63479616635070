import { PropertyAddressDetails } from '@fundmoreai/models';
import { Property } from '../shared';

export class AddProperty {
  static readonly type = '@properties.AddProperty';

  constructor(public applicationId: string, public property: Partial<Property>) {}
}

export class DeleteProperty {
  static readonly type = '@properties.DeleteProperty';

  constructor(public applicationId: string, public propertyId: string) {}
}

export class PatchProperty {
  static readonly type = '@properties.PatchProperty';

  constructor(public propertyId: string, public property: Partial<Property>) {}
}

export class PatchPropertyAddress {
  static readonly type = '@properties.PatchPropertyAddress';

  constructor(public propertyAddressDetails: PropertyAddressDetails) {}
}

export class SetProperties {
  static readonly type = '@properties.SetProperties';

  constructor(public properties: Property[]) {}
}

export class UpdateProperty {
  static readonly type = '@properties.UpdateProperty';

  constructor(public applicationId: string, public property: Partial<Property>) {}
}

export class UpdatePropertyCollateralize {
  static readonly type = '@properties.UpdatePropertyCollateralize';

  constructor(public propertyId: string, public isCollateralized: boolean) {}
}
