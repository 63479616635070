/* eslint-disable max-len */
export enum MMSErrorID {}
//     1003	Invalid authentication token.
// 1006	User is not authorized to access deal.
// 1007	Authorization/Access token has expired.
// 1008	Lender Code provided is invalid or does not exist.

// 2000	FCT URN provided is invalid.
// 2001	The FCT URN provided must be exactly 11 characters.
// 2002	Note Details provided must not exceed 1000 characters.
// 2003
// 2004
// 2005	Deal Source is a mandatory field.
// 2006	Deal Source provided must be exactly 2 characters.
// 2007	Deal Source provided must be: 2 - AP.
// 2008	Lender Reference Number provided is a mandatory field.
// 2009	Lender Reference Number provided must not exceed 25 characters.
// 2010	Lender Name is a mandatory field.
// 2011	Lender Name provided must not exceed 25 characters.
// 2012	Lender Code is a mandatory field.
// 2013	Lender Code provided must be 3-10 characters.
// 2014	Broker/Originator Contact First Name is a mandatory field.
// 2015	Broker/Originator Contact First Name provided must not exceed 25 characters.
// 2016
// 2017	Broker/Originator Contact Middle Name provided must not exceed 25 characters.
// 2018	Broker/Originator Contact Last Name is a mandatory field.
// 2019	Broker/Originator Contact Last Name provided must not exceed 50 characters.
// 2020	Broker/Originator Contact Email is a mandatory field.
// 2021	Broker/Originator Contact Email provided must not exceed 200 characters.
// 2022	You are not permitted to add more than 4 lender contacts and 4 broker/originator contacts details. Please contact FCT directly to inform them of the change as the deal may require re-adjudication.
// 2023	Mortgagor Type is a mandatory field.
// 2024
// 2025	Mortgagor Type provided must be: 0 - INDIVIDUAL or 1 – CORPORATION.
// 2026	Mortgagor First Name is a mandatory field.
// 2027	Mortgagor First Name provided must not exceed 25 characters.
// 2028	Mortgagor Last Name is a mandatory field.
// 2029	Mortgagor Last Name provided must not exceed 50 characters.
// 2030	Mortgagor Company Name is a mandatory field.
// 2031	Mortgagor Company Name provided must not exceed 100 characters.
// 2032	Mortgagor Street Name 1 is a mandatory field.
// 2033	Mortgagor Street Name 1 provided must not exceed 100 characters.
// 2034	Mortgagor City is a mandatory field.
// 2035	Mortgagor City provided must not exceed 50 characters.
// 2036	Mortgagor Province is a mandatory field.
// 2037	Mortgagor Province provided must not exceed 2 characters.
// 2038	Mortgagor Province provided must be a valid Canadian Province.
// 2039	Guarantor Type is a mandatory field.
// 2040	Guarantor Type provided must be: 0 - INDIVIDUAL or 1 – CORPORATION.
// 2041
// 2042	Guarantor Company Name is a mandatory field.
// 2043	Guarantor Company Name provided must not exceed 100 characters.
// 2044	Guarantor First Name is a mandatory field.
// 2045	Guarantor First Name provided must not exceed 25 characters.
// 2046	Guarantor Last Name is a mandatory field.
// 2047	Guarantor Last Name provided must not exceed 50 characters.
// 2048	Guarantor Street Name 1 is a mandatory field.
// 2049	Guarantor Street Name 1 provided must not exceed 100 characters.
// 2050	Guarantor City is a mandatory field.
// 2051	Guarantor City provided must not exceed 50 characters.
// 2052	Guarantor Province is a mandatory field.
// 2053	Guarantor Province provided must not exceed 2 characters and must be the valid 2 character Canada Post abbreviation.
// 2054	Guarantor Province provided must be a valid Canadian Province.
// 2055
// 2056	Mortgagor Property Details Address 1 is a mandatory field.
// 2057	Mortgagor Property Details Address 1 provided must not exceed 100 characters.
// 2058	Mortgagor Property Details City  is a mandatory field.
// 2059	Mortgagor Property Details City provided must not exceed 50 characters.
// 2060	Mortgagor Property Details Province is a mandatory field.
// 2061	Mortgagor Property Details Province provided must not exceed 2 characters.
// 2062	Mortgagor Property Details Province provided must be a valid Canadian Province.
// 2063
// 2064	Mortgagor Property Details Postal Code provided must be in a valid format: A1A 1A1 or A1A1A1
// 2065	Mortgagor Property Details Occupancy Type is a mandatory field.
// 2066
// 2067	Mortgagor Property Details Occupancy Type provided must be: 0 - Owner Occupied or 1 - Rental Property.
// 2068	Lawyer Details First Name is a mandatory field.
// 2069	Lawyer Details First Name provided must not exceed 25 characters.
// 2070	Lawyer Details Last Name is a mandatory field.
// 2071	Lawyer Details Last Name provided must not exceed 50 characters.
// 2072	Lawyer Details Law Firm is a mandatory field.
// 2073	Lawyer Details Law Firm provided must not exceed 100 characters.
// 2074	Mortgage Details Closing date is a mandatory field.
// 2075	Mortgage Details Closing date provided must be a valid format – YYYY-MM-DD.
// 2076	Mortgage Details Mortgage Number is a mandatory field.
// 2077	Mortgage Details Mortgage Number provided must not exceed 20 characters.
// 2078	Mortgage Details Mortgage Type is a mandatory field.
// 2079	Mortgage Details Mortgage Type provided must not exceed 50 characters.
// 2080	Mortgage Details Mortgage Type provided must be: 0 - New, 1 - Amended or 2 - Port.
// 2081	Mortgage Details Mortgage Product is a mandatory field.
// 2082	Mortgage Details Mortgage Product provided must not exceed 50 characters.
// 2083	Mortgage Details Transaction Type is a mandatory field.
// 2084
// 2085	Mortgage Details Transaction Type provided must be: 0 - Purchase or 1 - Refinance.
// 2086	Mortgage Details Mortgage Priority is a mandatory field.
// 2087
// 2088	Mortgage Details Mortgage Priority provided must be: 0 – First or 1 - Second or 2 - Third.
// 2089	Mortgage Details Mortgage Amount is a mandatory field.
// 2090	Mortgage Details Mortgage Amount provided must not exceed  17 digits .
// 2091	Mortgage Details Registered Amount is a mandatory field.
// 2092	Mortgage Details Registered Amount provided must not exceed  17 digits .
// 2093	Mortgage Details Investor is a mandatory field.
// 2094	Mortgage Details Investor provided must not exceed 50 characters.
// 2095	You are not permitted to add or remove Mortgagors, Guarantors and/or Properties.  Please contact FCT directly to inform them of the change as the deal may require re-adjudication.
// 2096	Only one lawyer can be added to a deal.
// 2097	The Closing Date cannot fall on a Saturday, Sunday, National holiday or a date in the past.
// 2098	Payment Details Monthly Payment Amount provided must not exceed 17 digits.
// 2099	Payment Details Monthly Payment Amount provided must be a valid data type: Decimal  (17,2).
// 2100	Payment Details Maximum Loan Amount provided must not exceed  17 digits.
// 2101	Payment Details Maximum Loan Amount provided must be a valid data type: Decimal  (17,2).
// 2102	Payment Details Payment Period provided must be: 0 - Weekly or 1 - Biweekly or 2 - Semimonthly or 3 - Monthly or 4 - N/A or 5 - See Schedule
// 2103	Payment Details Payment Amount provided must not exceed 19 characters.
// 2104	Payment Details Payment Amount provided must be a valid data type: Decimal  (17,2).
// 2105	Payment Details Tax Portion Payment Amount provided must not exceed  17 digits.
// 2106	Payment Details Tax Portion Payment Amount provided must be a valid data type: Decimal (17,2).
// 2107	Payment Details Total Payment provided must not exceed  17 digits.
// 2108	Payment Details Total Payment provided must be a valid data type: Decimal  (17,2).
// 2109	Payment Details Payment Day provided must not exceed 150 characters.
// 2110	Lender Contact Last Name is a mandatory field when Lender Contact Name First Name is provided.
// 2111	Lender Contact Email is a mandatory field when Lender Contact Name First Name is provided.
// 2112	Lender Contact First Name provided must not exceed 25 characters.
// 2113	Lender Contact Middle Name provided must not exceed 25 characters.
// 2114	Lender Contact Last Name provided must not exceed 25 characters.
// 2115	Lender Contact Phone provided must not exceed 15 characters.
// 2116	Lender Contact Extension provided must not exceed 6 characters.
// 2117	Lender Contact Fax No. provided must not exceed 15 characters.
// 2118
// 2119	Lender Contact Email provided must not exceed 200 characters.
// 2120	Lender Code provided must be valid as per FCT's specifications.
// 2121	Mortgage Details Mortgage Term provided must not exceed 4 digits.
// 2122	Mortgage Details Mortgage Term provided must be a valid data type: Integer
// 2123	Mortgage Details Amortization Period provided must not exceed   6 digits.
// 2124	Mortgage Details Amortization Period provided must be a valid data type: Integer
// 2125	Mortgage Details Assignment of Rents provided must be a valid data type: Boolean
// 2126	Mortgage Details Insurer provided must not exceed 50 characters.
// 2127	Mortgage Details Insurer provided must be: 0 - CMHC or 1 - Genworth/Sagen or 2 - Canada Guaranty.
// 2128	Mortgage Details Basic Loan Amount provided must not exceed  17 digits.
// 2129	Mortgage Details Basic Loan Amount provided must be a valid data type: Decimal  (17,2).
// 2130	Mortgage Details Total Interest Cost provided must not exceed  17 digits.
// 2131	Mortgage Details Total Interest Cost provided must be a valid data type: Decimal  (17,2).
// 2132	Mortgage Details Balance Owing at Maturity provided must not exceed  17 digits.
// 2133	Mortgage Details Balance Owing at Maturity provided must be a valid data type: Decimal  (17,2).
// 2134	Mortgage Details Cashback Percentage provided must not exceed  6 digits.
// 2135	Mortgage Details Cashback Percentage provided must be a valid data type: Decimal  (6,4).
// 2136	Mortgage Details Cashback Value provided must not exceed  17 digits.
// 2137	Mortgage Details Cashback Value provided must be a valid data type: Decimal (17,2).
// 2138	Mortgage Details Down Payment provided must not exceed  17 digits.
// 2139	Mortgage Details Down Payment provided must be a valid data type: Decimal  (17,2).
// 2140	Mortgage Details Sale Price provided must not exceed  17 digits.
// 2141	Mortgage Details Sale Price provided must be a valid data type: Decimal  (17,2).
// 2142	Mortgage Details Loan to Value provided must not exceed 9 digits.
// 2143	Mortgage Details Loan to Value provided must be a valid data type: Decimal (9,5).
// 2144	Interest Rate Details Registered Interest Rate Text provided must not exceed 1000 characters.
// 2145	Interest Rate Details Actual Mortgage Rate provided must not exceed 9 digits.
// 2146	Interest Rate Details Actual Mortgage Rate provided must be a valid data type: Decimal  (9,4).
// 2147	Interest Rate Details Prime Plus Interest Rate provided must not exceed 9 digits.
// 2148	Interest Rate Details Prime Plus Interest Rate provided must be a valid data type: Decimal  (9,4).
// 2149	Interest Rate Details Base Rate provided must not exceed 9 digits.
// 2150	Interest Rate Details Base Rate provided must be a valid data type: Decimal (9,4).
// 2151	Interest Rate Details Calculation Period provided must be: 0 - Monthly or 1 - Semi-Monthly.
// 2152	Bridge Loan Details Bridge Loan Number provided must not exceed 30 characters.
// 2153	Bridge Loan Details Bridge Loan Amount provided must not exceed  17 digits.
// 2154	Bridge Loan Details Bridge Loan Amount provided must be a valid data type: Decimal (17,2).
// 2155	Bridge Loan Details Interest Due provided must not exceed 5 digits.
// 2156	Bridge Loan Details Interest Due provided must be a valid data type: Decimal  (17,2).
// 2157	Bridge Loan Details Per Diem provided must not exceed 8 digits.
// 2158	Bridge Loan Details Per Diem provided must be a valid data type: Decimal  (17,2).
// 2159	Milestone Code ID provided must be: 1- Broker Conditions Satisfied or 7- Solicitor Document Satisfied or 8- Lender Final Authorization to Relase Funds.
// 2160	FCTURN provided must be exactly 11 characters.
// 2161	Broker/Originator Company Name provided must not exceed 50 characters.
// 2162	Broker/Originator Phone No. provided must not exceed 15 characters.
// 2163	Broker/Originator Fax No. provided must not exceed 15 characters.
// 2164	Mortgagor Title provided must be: 0 - MR or 1 - MRS or 2 - MISS or 3 - MS or 4 - DR, 5 - OTHER.
// 2165	Mortgagor Middle Name provided must not exceed 25 characters.
// 2166	Mortgagor Business Fax Number provided must not exceed 15 characters.
// 2167	Mortgagor Unit Number provided must not exceed 10 characters.
// 2168	Mortgagor Street Number provided must not exceed 10 characters.
// 2169	Mortgagor Street Name  2 provided must not exceed 100 characters.
// 2170	Mortgagor Postal Code provided must be in a valid format: A1A 1A1 or A1A1A1
// 2171	Mortgagor Consenting Spouse provided must be a valid data type: Boolean.
// 2172	Mortgagor Spouse First Name provided must not exceed 25 characters.
// 2173	Mortgagor Spouse First Name is mandatory when Consenting Spouse is true.
// 2174	Mortgagor Spouse Middle Name provided must not exceed 25 characters.
// 2175	Mortgagor Spouse Last Name provided must not exceed 50 characters.
// 2176	Mortgagor Spouse Last Name is mandatory when Consenting Spouse is true.
// 2177	Mortgagor Consenting Spouse Is Guarantor provided must be a valid data type: Boolean.
// 2178	Mortgagor Signatory First Name provided must not exceed 25 characters.
// 2179	Mortgagor Signatory Middle Name provided must not exceed 25 characters.
// 2180	Mortgagor Signatory Last Name provided must not exceed 50 characters.
// 2181	Guarantor Middle Name provided must not exceed 25 characters.
// 2182	Guarantor Unit Number provided must not exceed 10 characters.
// 2183	Guarantor Street Number provided must not exceed 10 characters.
// 2184	Guarantor Street Name  2 provided must not exceed 100 characters.
// 2185	Guarantor Postal Code provided must be in a valid format: A1A 1A1 or A1A1A1
// 2186	Guarantor Signatory First Name provided must not exceed 25 characters.
// 2187	Guarantor Signatory Middle Name provided must not exceed 25 characters.
// 2188	Guarantor Signatory Last Name provided must not exceed 50 characters.
// 2189	Mortgagor Property Details Unit Number provided must not exceed 10 characters.
// 2190	Mortgagor Property Details Street Number provided must not exceed 10 characters.
// 2191	Mortgagor Property Details Street Name 2 provided must not exceed 100 characters.
// 2192	Mortgagor Property Details Phone (H) provided must not exceed 15 characters.
// 2193	Mortgagor Property Details Business provided must not exceed 15 characters.
// 2194	Lawyer Details Middle Name provided must not exceed 25 characters.
// 2195	Lawyer Details Phone provided must not exceed 15 characters.
// 2196	Lawyer Details Email provided must not exceed 200 characters.
// 2197	Confirmation of Closing Confirmation Date provided must be a valid data type: DateTime.
// 2198	Confirmation of Closing Registration Date provided must be a valid data type: Date.
// 2199	Confirmation of Closing Instrument Number provided must not exceed 50 characters.

// 2200	The Document File Format provided must be: PDF, DOC, DOCX, TIFF, TIF, GIF, JPEG, PNG or BMP.
// 2201	The document size provided exceeds the 20 MB limit.
// 2203	Document Type/Name is a mandatory field.
// 2204	Document Type/Name provided must not exceed 150 characters.
// 2205	Document Type/Name provided is invalid according to the Lender's document metadata.
// 2206	Document Type/Name can not include special characters.
// 2207	Document AVS file scanning has detected a problem.

// 2208	Cancellation Request for this deal has already been submitted.
// 2209	Unable to process this request; funding was previously confirmed.
// 2210	The confirmed Amount indicated in the request does not match our records. Expected Amount is <$ insert Net Advance amount>.
// 2211	Mortgage Dates First Payment Date provided must be a valid data type: Date.
// 2212
// 2213	Mortgage Dates Last Payment Date provided must be a valid data type: Date.
// 2214	Mortgage Dates First Interest Adjustment Date provided must be a valid data type: Date.
// 2215
// 2216	Mortgage Dates First Balance Due Date provided must be a valid data type: Date.
// 2217
// 2218	Sale Property Closing Date Of Sale Property provided must be a valid data type: Date.
// 2219	Sale Property Legal Description of Sale Property provided must not exceed 1000 characters.
// 2220	Sale Property Mortgagor Address provided must not exceed 100 characters.
// 2221	Sale Property Address provided must not exceed 50 characters.
// 2222	Sale Property City provided must not exceed 50 characters.
// 2223	Sale Property Province provided must not exceed 2 characters and must be the valid 2 character Canada Post abbreviation.
// 2224	Sale Property Postal Code provided must be in a valid format: A1A 1A1 or A1A1A1
// 2225	Document File Name provided must not exceed 200 characters.
// 2226	Document Version provided must  be: AL or LT.
// 2227	Document Version provided must not exceed 2 characters.
// 2228	Milestone Value provided must be a valid data type: Boolean.
// 2229	Milestone Date Published provided must be a valid data type: DateTime.
// 2230	Note Sent Date provided must be a valid data type: DateTime.
// 2231	Deal Funding  Release provided must be a valid data type: Boolean.
// 2232	Funding  Release Amount provided must not exceed 19 digits.
// 2233	Funding Confirmation Amount provided must be a valid data type: TBD.
// 2234	Funding  Release Completed Date Time must be a valid data type: DateTime.
// 2235	Notification ID --> TBD
// 2236	Source ---> TBD
// 2237	Base URL provided must not exceed 250 characters.
// 2238	Notifications Lender Code --> TBD
// 2239	Deal --> TBD
// 2240	Notification Details ---> TBD
// 2241	Notification Type provided must be: 0 - Deal Updates or 1 - Documents or 2 - Milestones or 3 - Notes or 4 - Statuses or 5 - Disbursements
// 2242	Received TimeStamp provided must be a valid data type: DateTime.
// 2243	Delivered TimeStamp provided must be a valid data type: DateTime.
// 2244	Mortgagor Home Phone provided must not exceed 15 characters.
// 2245	Mortgagor Business Phone provided must not exceed 15 characters.
// 2246	Lawyer Details Email provided must be in a valid format: email, i.e. xxxxxx@gmail.com
// 2247	Broker/Originator Contact Email provided must be in a valid format: email, i.e. xxxxxx@gmail.com
// 2248	Lender Contact Email provided must be in a valid format: email, i.e. xxxxxx@gmail.com
// 2249	No documents were found for the criteria provided.
// 2250	Unable to process Funding  Release: Funding conditions and/or required milestones not completed.
// 2251	Data must be added or changed for successful submission.
// 2252	Notes Details is a mandatory field.
// 2253	Document File Format is a mandatory field.
// 2254	Document content is empty.
// 2255	Mortgage Details Mortgage Product is invalid. Please use GetMortgageProduct function to obtain a list of valid Mortgage. Products.
// 2256	Mortgage Details Mortgage Amount provided must be a valid data type: Decimal (17,2).
// 2257	Mortgage Details Registered Amount provided must be a valid data type: Decimal   (15,2).
// 2258	Mortgage Details Mortgage Amount provided value must be less or equal to 922337203685476.63.
// 2259	Mortgage Details Registered Amount provided value must be less or equal to 922337203685476.63.
// 2260	Mortgage Details Basic Loan Amount provided value must be less or equal to 922337203685476.63.
// 2261	Mortgage Details Total Interest Costs provided value must be less or equal to 922337203685476.63.
// 2262	Mortgage Details Balance Owing at Maturity provided value must be less or equal to 922337203685476.63.
// 2263	Mortgage Details Cashback Value provided value must be less or equal to 922337203685476.63.
// 2264	Mortgage Details Down Payment provided value must be less or equal to 922337203685476.63.
// 2265	Mortgage Details Sale Price provided value must be less or equal to 922337203685476.63.
// 2266	Mortgage Details Monthly Payment Amount provided value must be less or equal to 922337203685476.63.
// 2267	Mortgage Details Maximum Loan Amount provided value must be less or equal to 922337203685476.63.
// 2268	Mortgage Details Payment Amount provided value must be less or equal to 922337203685476.63.
// 2269	Mortgage Details Tax Portion Payment Amount provided value must be less or equal to 922337203685476.63.
// 2270	Mortgage Details Total Payment provided value must be less or equal to 922337203685476.63.
// 2271	Mortgage Details Bridge Loan Amount provided value must be less or equal to 922337203685476.63.
// 2272	Mortgage Details Bridge Loan Interest Due provided value must be less or equal to 922337203685476.63.
// 2273	Mortgage Details Per Diem provided value must be less or equal to 922337203685476.63.
// 2274	Lender Contact Phone provided must be a valid Canadian phone format: 976-323-4323 or (976) 323-4323 or 976.323.4323 or 9763234323 or 976-323-4323 or 976 323 4323.
// 2275	Lender Contact Extension provided must contain numbers only.
// 2276	Lender Contact Fax No. provided must be a valid Canadian phone format: 976-323-4323 or (976) 323-4323 or 976.323.4323 or 9763234323 or 976-323-4323 or 976 323 4323.
// 2277	Broker/Originator Phone No. provided must be a valid Canadian phone format: 976-323-4323 or (976) 323-4323 or 976.323.4323 or 9763234323 or 976-323-4323 or 976 323 4323.
// 2278	Broker/Originator Fax No. provided must be a valid Canadian phone format: 976-323-4323 or (976) 323-4323 or 976.323.4323 or 9763234323 or 976-323-4323 or 976 323 4323.
// 2279	Mortgagor Property Details Phone (H) provided must be a valid Canadian phone format: 976-323-4323 or (976) 323-4323 or 976.323.4323 or 9763234323 or 976-323-4323 or 976 323 4323.3.
// 2280	Mortgagor Property Details Business provided must be a valid Canadian phone format: 976-323-4323 or (976) 323-4323 or 976.323.4323 or 9763234323 or 976-323-4323 or 976 323 4323.
// 2281	Lawyer Details Phone provided must be a valid Canadian phone format: 976-323-4323 or (976) 323-4323 or 976.323.4323 or 9763234323 or 976-323-4323 or 976 323 4323.
// 2282	Mortgagor Home Phone provided must be a valid Canadian phone format: 976-323-4323 or (976) 323-4323 or 976.323.4323 or 9763234323 or 976-323-4323 or 976 323 4323.
// 2283	Mortgagor Business Phone provided must be a valid Canadian phone format: 976-323-4323 or (976) 323-4323 or 976.323.4323 or 9763234323 or 976-323-4323 or 976 323 4323.
// 2284	Mortgagor Business Fax Number provided must be a valid Canadian phone format: 976-323-4323 or (976) 323-4323 or 976.323.4323 or 9763234323 or 976-323-4323 or 976 323 4323.
// 2285	Unable to process Funding Release: Deal Status must be Active.
// 2286	Mortgagor information group is mandatory.
// 2287	Guarantor information group is mandatory.
// 2288	Property Details information group is mandatory.
// 2289	Broker/Originator information group is mandatory.
// 2290	Lawyer Details information group is mandatory.
// 2291	Mortgage Details information group is mandatory.
// 2292	Mortgagor Company Name is not valid for Mortgagor Type = 0 (Individual)
