import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import { PipelineView } from '../features/pipeline/model';
import { UserSettingsService } from './services/user-settings.service';
import { NotificationSettings } from '@fundmoreai/models';
import { FundmoreAITheme } from '../core/store/theme.state';

export class FetchAndSetUserSettings {
  static readonly type = '@userSettings.fetchAndSetUserSettings';
}

export class UpdatePreviewSettings {
  static readonly type = '@userSettings.updatePreviewSettings';

  constructor(public previewSettings: PreviewSettingsModel) {}
}
export class UpdateUserSettings {
  static readonly type = '@userSettings.updateUserSettings';
  constructor(public userSettings: Partial<UserSettingsModel>) {}
}

export class UserSettingsLoaded {
  static readonly type = '@userSettings.UserSettingsLoaded';
  constructor(public userSettings: UserSettingsModel) {}
}

export class UserSettingsModel {
  defaultPipelineView: PipelineView | undefined;
  defaultToReadonly: boolean;
  theme?: FundmoreAITheme;
  notificationSettings: NotificationSettings | undefined;
  previewSettings?: PreviewSettingsModel;
}
export class PreviewSettingsModel {}

@State<UserSettingsModel>({
  name: 'userSettings',
  defaults: {
    defaultPipelineView: undefined,
    defaultToReadonly: false,
    notificationSettings: undefined,
    previewSettings: undefined,
  },
})
@Injectable()
export class UserSettingsState {
  @Selector() static userSettings(state: UserSettingsModel) {
    return state;
  }

  @Selector() static previewFeatures(state: UserSettingsModel) {
    return state.previewSettings;
  }

  @Selector() static defaultToReadonly(state: UserSettingsModel) {
    return state.defaultToReadonly;
  }

  constructor(private userSettingsService: UserSettingsService) {}

  @Action(FetchAndSetUserSettings) fetchUserSettings(ctx: StateContext<UserSettingsModel>) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.userSettingsService.getUserSettings().pipe(
      tap((settings: UserSettingsModel) => {
        ctx.patchState({ ...settings, previewSettings: {} });
        // apply user settings across app by implementing this action
        ctx.dispatch(new UserSettingsLoaded(settings));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdatePreviewSettings)
  updatePreviewSettings(ctx: StateContext<UserSettingsModel>, action: UpdatePreviewSettings) {
    const state = ctx.getState();

    ctx.patchState({ ...state, previewSettings: action.previewSettings });
  }

  @Action(UpdateUserSettings) updateUserSettings(
    ctx: StateContext<UserSettingsModel>,
    action: UpdateUserSettings,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.userSettingsService.updateUserSettings(action.userSettings).pipe(
      tap(() => {
        const state = ctx.getState();
        ctx.patchState({ ...state, ...action.userSettings });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }
}
