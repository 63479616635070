import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { catchError } from 'rxjs/operators';
import { Fee } from '../shared/model';

@Injectable({
  providedIn: 'root',
})
export class FeesService {
  constructor(private http: HttpClient) {}

  patchFee(applicationId: string, fee: Fee): Observable<Fee> {
    return this.http
      .patch<Fee>(`${environment.api_url}/applications/${applicationId}/fee/${fee.id}`, fee)
      .pipe(catchError(this.formatErrors));
  }

  patchIncludeInAPR(applicationId: string, fee: Fee): Observable<Fee> {
    return this.http
      .patch<Fee>(
        `${environment.api_url}/applications/${applicationId}/fee/${fee.id}/includeInApr`,
        { includeInApr: fee.includeInApr },
      )
      .pipe(catchError(this.formatErrors));
  }

  postFee(applicationId: string, fee: Fee): Observable<Fee> {
    return this.http
      .post<Fee>(`${environment.api_url}/applications/${applicationId}/fee`, fee)
      .pipe(catchError(this.formatErrors));
  }

  deleteFee(applicationId: string, feeId: string): Observable<void> {
    return this.http
      .delete<void>(`${environment.api_url}/applications/${applicationId}/fee/${feeId}`)
      .pipe(catchError(this.formatErrors));
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }
}
