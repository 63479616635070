import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CsvService {
  constructor(private http: HttpClient) {}

  private formatErrors(error: any) {
    return throwError(error.error);
  }

  generateApplicationsCsv(applicationIds: string[]): Observable<Blob> {
    return this.http
      .post<Blob>(`${environment.api_url}/csv/generate/applications`, applicationIds, {
        responseType: 'blob' as 'json',
      })
      .pipe(
        map((response: Blob) => {
          return response;
        }),
        catchError(this.formatErrors),
      );
  }
}
