import { Injectable } from '@angular/core';
import { PdfOptions } from '@fundmoreai/models';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import saveAs from 'file-saver';
import { finalize, Observable, tap } from 'rxjs';
import { ApplicantsState } from 'src/app/portal/applicants.state';
import { MortgagesV2State } from 'src/app/portal/mortgages-v2/mortgages-v2.state';
import { PropertiesState } from 'src/app/portal/properties.state';
import { AppFeaturesState } from 'src/app/shared/app-features.state';
import { ApplicantNamePipe } from 'src/app/shared/applicant-name.pipe';
import { DocumentService } from '../../document/document.service';
import { PDF_OPTIONS } from '../../generate-documents/generate-documents.constants';
import {
  ClearNoteToEdit,
  DeleteDraftNote,
  ExportNotesAsPdf,
  FetchNotes,
  GenerateAINote,
  PublishDraftNote,
  ResetAINote,
  SaveDraftNote,
  SaveNote,
  SetNoteHasChanges,
  SetNoteToEdit,
  UpdateDraftNote,
} from './notes.actions';
import { Note } from './notes.model';
import { NotesService } from './notes.service';
import { ApplicationResetState } from '../../../shared/state.model';
import { LoadingStart, LoadingEnd } from '../../../core/loading.state';

export interface NotesStateModel {
  hasChanges: boolean | undefined;
  notes: {
    [key: string]: Note;
  };
  noteToEdit: Partial<Note> | undefined;
  generatedAINote: string | undefined;
}

const defaults: NotesStateModel = {
  hasChanges: undefined,
  notes: {},
  noteToEdit: undefined,
  generatedAINote: undefined,
};

@State<NotesStateModel>({
  name: 'applicationNotes',
  defaults: { ...defaults },
})
@Injectable()
export class ApplicationNotesState {
  static NAME = 'applicationNotes';

  @Action(ApplicationResetState)
  reset(ctx: StateContext<NotesStateModel>) {
    ctx.setState({ ...defaults });
  }

  @Selector()
  static hasChanges(state: NotesStateModel) {
    return state.hasChanges;
  }

  @Selector()
  static notes(state: NotesStateModel) {
    return Object.values(state.notes ?? {});
  }

  @Selector()
  static noteToEdit(state: NotesStateModel) {
    return state.noteToEdit;
  }

  @Selector([ApplicationNotesState.notes])
  static notesList(notes: Note[]) {
    return notes.sort(
      (a, b) => new Date(a.lastModifiedAt).getTime() - new Date(b.lastModifiedAt).getTime(),
    );
  }

  @Selector()
  static generatedAINote(state: NotesStateModel) {
    return state.generatedAINote;
  }

  constructor(
    private applicantNamePipe: ApplicantNamePipe,
    private documentService: DocumentService,
    private notesService: NotesService,
    private store: Store,
  ) {}

  @Action(ClearNoteToEdit)
  clearNoteToEdit(ctx: StateContext<NotesStateModel>) {
    ctx.patchState({ noteToEdit: undefined });
  }

  @Action(DeleteDraftNote)
  deleteDraftNote(ctx: StateContext<NotesStateModel>, action: DeleteDraftNote) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.notesService.deleteDraftNote(action.noteId).pipe(
      tap(() => {
        const state = ctx.getState();
        const notes = { ...state.notes };

        delete notes[action.noteId];
        if (state.noteToEdit?.id === action.noteId) {
          ctx.dispatch(new ClearNoteToEdit());
        }

        ctx.patchState({
          notes,
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(ExportNotesAsPdf)
  exportNotesAsPdf(ctx: StateContext<NotesStateModel>, action: ExportNotesAsPdf) {
    const buyersStakeholders = this.store.selectSnapshot(ApplicantsState.buyersStakeholders);
    const notesList = this.store.selectSnapshot(ApplicationNotesState.notesList);
    const primaryProperty = this.store.selectSnapshot(PropertiesState.primaryProperty);
    const requestedMortgages = this.store.selectSnapshot(MortgagesV2State.requestedMortgages);
    const headerTemplate = ' ';
    const footerTemplate = ' ';
    const htmlContent = this.notesService.generateNotesPdfContent(
      (buyersStakeholders?.map((x) => this.applicantNamePipe.transform(x)) || []).join(', '),
      requestedMortgages
        .map((x) => x.loanNumber)
        .filter((x) => !!x)
        .join(', '),
      notesList,
      primaryProperty?.propertyAddress ?? '',
    );

    return this.saveToPdf(
      htmlContent,
      {
        ...PDF_OPTIONS,
        ...this.store.selectSnapshot(AppFeaturesState.pdfOptions),
        headerTemplate,
        footerTemplate,
      },
      `${action.applicationName}-notes-${new Date().toISOString()}.pdf`,
    );
  }

  @Action(FetchNotes)
  fetchNotes(ctx: StateContext<NotesStateModel>, action: FetchNotes) {
    ctx.patchState({
      notes: undefined,
    });

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.notesService.getAll(action.applicationId).pipe(
      tap((notes) => {
        const noteEntities = notes.reduce((entities: { [key: string]: Note }, note) => {
          entities[note.id] = note;

          return entities;
        }, {});

        ctx.patchState({ notes: noteEntities });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(PublishDraftNote)
  publishDraftNote(ctx: StateContext<NotesStateModel>, action: PublishDraftNote) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.notesService.publishDraftNote(action.noteId, action.content).pipe(
      tap((note) => {
        const state = ctx.getState();

        ctx.patchState({
          notes: {
            ...state.notes,
            [note.id]: note,
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(SaveDraftNote)
  saveDraftNote(ctx: StateContext<NotesStateModel>, action: SaveDraftNote) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.notesService.saveAsDraft(action.applicationId, action.content).pipe(
      tap((note) => {
        const state = ctx.getState();

        ctx.patchState({
          notes: {
            ...state.notes,
            [note.id]: note,
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(SaveNote)
  saveNote(ctx: StateContext<NotesStateModel>, action: SaveNote) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.notesService.save(action.applicationId, action.content).pipe(
      tap((note) => {
        const state = ctx.getState();

        ctx.patchState({
          notes: {
            ...state.notes,
            [note.id]: note,
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(SetNoteHasChanges)
  setNoteHasChanges(ctx: StateContext<NotesStateModel>, action: SetNoteHasChanges) {
    ctx.patchState({
      hasChanges: action.hasChanges,
    });
  }

  @Action(SetNoteToEdit)
  setNoteToEdit(ctx: StateContext<NotesStateModel>, action: SetNoteToEdit) {
    ctx.patchState({ noteToEdit: action.note });
  }

  @Action(UpdateDraftNote)
  updateDraftNote(ctx: StateContext<NotesStateModel>, action: UpdateDraftNote) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.notesService.updateDraftNote(action.noteId, action.content).pipe(
      tap((note) => {
        const state = ctx.getState();

        ctx.patchState({
          notes: {
            ...state.notes,
            [note.id]: note,
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(GenerateAINote) generateAINote(
    ctx: StateContext<NotesStateModel>,
    action: GenerateAINote,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    ctx.patchState({
      generatedAINote: undefined,
    });

    return this.notesService
      .generateAINote(action.applicationId, action.temperature, action.maxTokens)
      .pipe(
        tap((result) => {
          ctx.patchState({
            generatedAINote: result,
          });
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(ResetAINote) resetAINote(ctx: StateContext<NotesStateModel>) {
    ctx.patchState({ generatedAINote: undefined });
  }

  private saveToPdf(htmlContent: string, options: PdfOptions, filename: string): Observable<Blob> {
    this.store.dispatch(new LoadingStart(this.constructor.name));

    return this.documentService.generatePdf(htmlContent, options, filename).pipe(
      tap((response) => saveAs(response, filename)),
      finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }
}
