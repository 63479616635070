import { enableProdMode, APP_INITIALIZER, importProvidersFrom } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { PubNubModule } from './app/pubnub/pubnub.module';
import { AppRoutingModule } from './app/app-routing.module';
import { CoreModule } from './app/core/core.module';
import { Router } from '@angular/router';
import { CustomRouterStateSerializer } from './app/router-state/router-state.serializer';
import { RouterStateSerializer, NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { DatatableColumnsState } from './app/features/shared/edit-columns/datatable-columns.state';
import { NgxsModule } from '@ngxs/store';
import { Amplify } from 'aws-amplify';

if (environment.cognito && environment.cognito.hostedUI && environment.cognito.hostedUI.domain) {
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: environment.cognito.userPoolId,
        userPoolClientId: environment.cognito.clientId,

        loginWith: {
          oauth: {
            domain: environment.cognito.hostedUI.domain,
            scopes: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: [`${window.location.origin}/sso`],
            redirectSignOut: [`${window.location.origin}/sso-logout`],
            responseType: 'code',
          },
        },
      },
    },
  });
}

if (!environment.api_url.includes('localhost') && environment.sentry.dsn) {
  Sentry.init({
    dsn: `${environment.sentry.dsn}`,
    release: `${environment.release.sentry}`,
    environment: `${environment.sentry.environment}`,
    integrations: [
      Sentry.browserTracingIntegration({
        enableInp: true,
        tracingOrigins: ['*'],
        shouldCreateSpanForRequest: (url) => {
          // Do not create spans for outgoing requests
          const shouldIgnoreUrl =
            url.includes('pndsn.com/v2/subscribe') ||
            url.includes('pndsn.com/v3/subscribe') ||
            url.includes('pendo.io') ||
            url.includes('googleapis.com') ||
            url.includes('pendo-static');

          return !shouldIgnoreUrl;
        },
      }),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment.sentry.tracesSampleRate,
    ignoreTransactions: [
      'pndsn.com/v2/subscribe',
      'pndsn.com/v3/subscribe',
      'pendo.io',
      'googleapis.com',
      'pendo-static',
    ],
    beforeSend(event) {
      // Check if the event is a transaction and matches the URL pattern
      if (event.type === 'transaction' && event.request) {
        const url = event.request.url || '';
        if (
          url.includes('pndsn.com/v2/subscribe') ||
          url.includes('pndsn.com/v3/subscribe') ||
          url.includes('pendo.io') ||
          url.includes('googleapis.com') ||
          url.includes('pendo-static')
        ) {
          return null; // Discard the transaction
        }
      }
      return event;
    },
    beforeSendTransaction(event) {
      // Check if the event is a transaction and matches the URL pattern
      if (event.type === 'transaction' && event.request) {
        const url = event.request.url || '';
        if (
          url.includes('pndsn.com/v2/subscribe') ||
          url.includes('pndsn.com/v3/subscribe') ||
          url.includes('pendo.io') ||
          url.includes('googleapis.com') ||
          url.includes('pendo-static')
        ) {
          return null; // Discard the transaction
        }
      }
      return event;
    },
  });
}

if (environment.production) {
  enableProdMode();
}

const bootstrap = () =>
  bootstrapApplication(AppComponent, {
    providers: [
      importProvidersFrom(
        CoreModule,
        AppRoutingModule,
        PubNubModule,
        NgxsRouterPluginModule.forRoot(),
        NgxsModule.forFeature([DatatableColumnsState]),
      ),
      { provide: RouterStateSerializer, useClass: CustomRouterStateSerializer },
      {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
    ],
  });

bootstrap().catch((err: Error) => console.log(err));
