// <Field Name> is mandatory	001
// <Field Name> can only be AlphaNumeric	002
// <Field Name> can only be Numbers, ranging between 1 to 999	003
// <Field Name> can only be Numbers in following formats: XXXXXXXXXX, XXX-XXX-XXXX	004
// <Field name> can only be in the format: name@domain.xxx	005
// <Field name>  can only be in the format: YYYY-MM-DD	006
// <Field Name> can only be AlphaNumeric & Special Characters	007
// <FieldName> can only be Numbers	008
// File size for each document must not exceed 20 MB	009
// <Selected Primary Product> is not available 	010
// <Selected Secondary Product> is not available	011
// System is unable to find a matching address.	012
// System is able to find a partial matching address. <<include address from MPAC>>	013
// System is able to find multiple matching addresses.	014
// Ineligible for Insurance: Credit Score	015
// Ineligible for Insurance: Credit Score	016
// Ineligible for Insurance: Loan Purpose = Foreclosure/Power of Sale	017
// Ineligible for Insurance: Loan Priority = <Loan Priority>	018
// Ineligible for Insurance: Sale Type (MLS/Private) = Private (without MLS)	019
// Ineligible for Insurance: Property Type = Mobile (Other)	020
// Ineligible for Insurance: Property Use = <Property Use>	021
// Ineligible for Insurance: Occupancy Type = <Occupancy Type>	022
// Ineligible for Insurance: LTV greater than 80%	023
// Ineligible for Insurance: Rural properties are not eligible for the selected product	024
// Ineligible for Insurance: Declared Value/ Purchase Exceeds <$600,000> Postal Code Threshold	025
// Ineligible for Insurance: Loan Amount Exceeds <$480,000> Postal Code Threshold	026
// Ineligible for Insurance: Credit Score	027
// Incomplete Address	028
// Street Number, Street Name, Province, Municipality or Postal Code is rquired	029
// <Selected Add-On Product> is not available	030
// Unauthorized	031
// Unauthorized User	032
// <Field name> can only be future date in the format: MM/DD/YYYY	033
// <Field Name> can be in following formats: A1A1A1 or A1A 1A1	034
// Invalid Value	035
// <Field Name> is too long	036
// Response is not ready	037
// Order Not Found	038
// Reference ID is missing	039
// Cannot cancel the deal at this stage, you may consider sending a note to FCT Admin to see if cancellation is possible	040
// Please provide the reason for cancelling the order	041
// Document Name is mandatory	042
// Document Content is mandatory	043
// Invalid Document name	044
// Invalid Document content	045
// File Format Not Supported	046
// Cannot upload document - <DMS error>	047
// Invalid Note Recipient (<List of recipient>)	048
// Invalid Note Recipient (<Lender entered recipient>)	049
// Note text is mandatory	050
// Note Recipient is mandatory	051
// FCT Reference # is Mandatory	052
// No notes available	053
// <insert product> cannot be updated	054
// Order cannot be updated	055
// Cannot perform Value Appeal	056
// The Value Appeal is not available, please contact 1.844.818.3448	057
// Invalid deal status	058
// Ineligible for copy: Product=<ProductName>	059
// Incorrect order no, please re-verify	060
// Existing Order Primary Product should be is Traditional Appraisal Product or NVIP Traditional Product	061
// Unassociated Primary Product 	062
// Unassociated Secondary Product	063
// Secondary Product unassociated to primary product	064
//  Funded status is already set - Cannot update	065
// <FieldName> - Ineligible for funding	066
// Deal Copy failed - Referenced order completion date exceeds configured retention days	067
// IneligibleForRushSetting	068
// Quote approval or Decline fee is not applicable for this deal	069
// Invalid input; Please specify ActionType as 001 in order to approve or deny fee or cancelling your order	070
// Invalid input; Please specify ActionValue as either 001 for Approve Quote or 002 for Decline Fee or 003 for Cancel Order	071
// Province: Invalid or Unsupported Province	072
// Invalid postal code: Postal code cannot start with D, F, I, O, Q, U, W or Z	073
// Acceptable email format is name@domain.xxx. Multiple email addresses should be separated by comma.	074
// Additional Notification Emails shouldhave max characters of 500.	075
// Not Eligible for Insure AVM Product.	076
// Can’t order Market Rent on non rental property.	077
// Postal Code is invalid; Postal code doesn't match the Province specified	078

export enum FCTErrorCode {
  _OO1 = '001',
  _OO2 = '002',
  _OO3 = '003',
  _OO4 = '004',
  _OO5 = '005',
  _OO6 = '006',
  _OO7 = '007',
  _OO8 = '008',
  _OO9 = '009',
  _O1O = '010',
  _O11 = '011',
  _O12 = '012',
  _O13 = '013',
  _O14 = '014',
  _O15 = '015',
  _O16 = '016',
  _O17 = '017',
  _O18 = '018',
  _O19 = '019',
  _O2O = '020',
  _O21 = '021',
  _O22 = '022',
  _O23 = '023',
  _O24 = '024',
  _O25 = '025',
  _O26 = '026',
  _O27 = '027',
  _O28 = '028',
  _O29 = '029',
  _O3O = '030',
  _O31 = '031',
  _O32 = '032',
  _O33 = '033',
  _O34 = '034',
  _O35 = '035',
  _O36 = '036',
  _O37 = '037',
  _O38 = '038',
  _O39 = '039',
  _O4O = '040',
  _O41 = '041',
  _O42 = '042',
  _O43 = '043',
  _O44 = '044',
  _O45 = '045',
  _O46 = '046',
  _O47 = '047',
  _O48 = '048',
  _O49 = '049',
  _O5O = '050',
  _O51 = '051',
  _O52 = '052',
  _O53 = '053',
  _O54 = '054',
  _O55 = '055',
  _O56 = '056',
  _O57 = '057',
  _O58 = '058',
  _O59 = '059',
  _O6O = '060',
  _O61 = '061',
  _O62 = '062',
  _O63 = '063',
  _O64 = '064',
  _O65 = '065',
  _O66 = '066',
  _O67 = '067',
  _O68 = '068',
  _O69 = '069',
  _O7O = '070',
  _O71 = '071',
  _O72 = '072',
  _O73 = '073',
  _O74 = '074',
  _O75 = '075',
  _O76 = '076',
  _O77 = '077',
  _O78 = '078',
}

export enum FCTAddressErrorCode {
  INCOMPLETE_ADDRESS = '001-028',
  NO_MATCHING_ADDRESS = '001-012',
  NO_EXACT_MATCH = '001-079',
  SINGLE_PARTIAL_MATCH_ADDRESS = '001-013',
  MULTIPLE_PARTIAL_MATCH_ADDRESS = '001-014',
}
