import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DOCUMENT } from '@angular/common';
import { UserAccount } from '../model';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const pendo: any;
const API_KEY = environment.pendo;

@Injectable({
  providedIn: 'root',
})
export class PendoService {
  public INITIAL_DELAY_MS = 2000;
  private renderer: Renderer2;
  private retryCount = 0;

  constructor(rendererFactory: RendererFactory2, @Inject(DOCUMENT) private document: Document) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }
  public loadScript(): void {
    if (!API_KEY) {
      return;
    }

    const scriptFunction = `(function(apiKey){
        (function(p,e,n,d,o){var v,w,x,y,z;o=p[d]=p[d]||{};o._q=o._q||[];
        v=['initialize','identify','updateOptions','pageLoad','track'];for(w=0,x=v.length;w<x;++w)(function(m){
            o[m]=o[m]||function(){o._q[m===v[0]?'unshift':'push']([m].concat([].slice.call(arguments,0)));};})(v[w]);
            y=e.createElement(n);y.async=!0;y.src='https://cdn.pendo.io/agent/static/'+apiKey+'/pendo.js';
            z=e.getElementsByTagName(n)[0];z.parentNode.insertBefore(y,z);})(window,document,'script','pendo');
    })('${API_KEY}');`;
    const script = this.renderer.createElement('script') as HTMLScriptElement;
    script.innerHTML = scriptFunction;
    this.renderer.appendChild(document.body, script);
  }

  public initialize(currentUserAccount: UserAccount): void {
    if (!API_KEY) {
      return;
    }
    this.initializeWithRetry(currentUserAccount);
  }

  private setPendoData(currentUserAccount: UserAccount) {
    const visitor = currentUserAccount?.user?.id;
    const account = currentUserAccount?.tenant?.id;
    const roles = currentUserAccount?.user?.roles.map((x) => x.name);
    pendo.initialize({
      visitor: {
        id: visitor,
        role: roles,
        // id: 'VISITOR-UNIQUE-ID', // Required if user is logged in, default creates anonymous ID
        // email:        // Recommended if using Pendo Feedback, or NPS Email
        // full_name:    // Recommended if using Pendo Feedback
        // role:         // Optional
        // You can add any additional visitor level key-values here,
        // as long as it's not one of the above reserved names.
      },
      account: {
        id: account,
        // id: 'ACCOUNT-UNIQUE-ID', // Required if using Pendo Feedback, default uses the value 'ACCOUNT-UNIQUE-ID'
        // name:         // Optional
        // is_paying:    // Recommended if using Pendo Feedback
        // monthly_value:// Recommended if using Pendo Feedback
        // planLevel:    // Optional
        // planPrice:    // Optional
        // creationDate: // Optional
        // You can add any additional account level key-values here,
        // as long as it's not one of the above reserved names.
      },
    });
  }

  public initializeWithRetry(currentUserAccount: UserAccount): void {
    this.retryCount = 0;

    const interval = setInterval(() => {
      this.retryCount++;
      if (this.retryCount > 10) {
        console.error(`Pendo failed to load after ${this.retryCount} retries`);
        clearInterval(interval);
        return;
      }
      try {
        if (pendo) {
          clearInterval(interval);
          this.setPendoData(currentUserAccount);
          return;
        }
      } catch {
        // ignore pendo not loaded
      }
      console.log('Pendo not loaded yet, retrying...', this.retryCount);
    }, 1000);
  }
}
