import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PropertyOwner } from '../../shared';

@Injectable({
  providedIn: 'root',
})
export class PropertyOwnerService {
  constructor(private http: HttpClient) {}

  postOwner(propertyId: string, owner: Partial<PropertyOwner>): Observable<PropertyOwner> {
    return this.http.post<PropertyOwner>(
      `${environment.api_url}/properties/${propertyId}/owners`,
      owner,
    );
  }

  patchOwner(propertyId: string, owner: PropertyOwner): Observable<PropertyOwner> {
    return this.http
      .patch<PropertyOwner>(`${environment.api_url}/properties/${propertyId}/owners/${owner.id}`, {
        ...owner,
        applicationId: undefined,
        id: undefined,
      })
      .pipe(map(() => ({ ...owner })));
  }

  deleteOwner(propertyId: string, ownerId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.api_url}/properties/${propertyId}/owners/${ownerId}`,
    );
  }

  fetchPropertyOwners(propertyId: string): Observable<PropertyOwner[]> {
    return this.http.get<PropertyOwner[]>(`${environment.api_url}/properties/${propertyId}/owners`);
  }
}
