import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserAccount } from 'src/app/shared';
import { environment } from 'src/environments/environment';
import { EzidoxUserAccount, UserAccountManagerPortal } from './model';

@Injectable({
  providedIn: 'root',
})
export class UserManageService {
  constructor(private http: HttpClient) {}

  getUserAccounts(): Observable<UserAccount[]> {
    return this.http.get<UserAccount[]>(`${environment.api_url}/account/full`);
  }

  getUserAccountDetails(userId: string): Observable<UserAccount> {
    if (!userId) {
      throw new Error('User id is required');
    }
    return this.http.get<UserAccount>(`${environment.api_url}/account/${userId}`);
  }

  // Not used for now
  // We are getting User Own Details from login call Cognito
  getOwnUserAccountDetails(): Observable<UserAccount> {
    return this.http.get<UserAccount>(`${environment.api_url}/account/own`);
  }

  createUserAccount(user: any): Observable<UserAccount> {
    return this.http.post<UserAccount>(`${environment.api_url}/account`, user);
  }

  enableDisableUserAccount(externalAuthSystemId: string, enable: boolean): Observable<UserAccount> {
    return this.http.put<UserAccount>(
      `${environment.api_url}/account/${externalAuthSystemId}/active`,
      { active: enable },
    );
  }

  welcomeUserAccount(externalAuthSystemId: string): Observable<UserAccount> {
    return this.http.put<UserAccount>(
      `${environment.api_url}/account/${externalAuthSystemId}/resendwelcome`,
      undefined,
    );
  }

  resetPasswordUserAccount(user: UserAccountManagerPortal): Observable<UserAccount> {
    return this.http.put<UserAccount>(
      `${environment.api_url}/account/${user.externalAuthSystemId}/resetpassword`,
      undefined,
    );
  }

  changeRoleUserAccount(user: UserAccountManagerPortal): Observable<UserAccount> {
    return this.http.put<UserAccount>(
      `${environment.api_url}/account/${user.externalAuthSystemId}/role`,
      { roles: user.roles.map((x) => x.id) },
    );
  }

  createDMUserAccount(user: Partial<UserAccountManagerPortal>): Observable<EzidoxUserAccount> {
    return this.http.post<EzidoxUserAccount>(
      `${environment.api_url}/account/${user.externalAuthSystemId}/collector`,
      {
        roles: user.roles?.map((r) => r.id) ?? [],
      },
    );
  }

  activateMFAUserAccount(externalAuthSystemId: string): Observable<void> {
    return this.http.put<void>(`${environment.api_url}/account/${externalAuthSystemId}/mfa`, {});
  }

  deactivateMFAUserAccount(externalAuthSystemId: string): Observable<void> {
    return this.http.delete<void>(`${environment.api_url}/account/${externalAuthSystemId}/mfa`);
  }

  deleteUserAccount(externalAuthSystemId: string): Observable<void> {
    return this.http.delete<void>(`${environment.api_url}/account/${externalAuthSystemId}`);
  }

  verifyUserAccount(externalAuthSystemId: string): Observable<void> {
    const bodyUser = {
      phoneNumberVerified: true,
      emailVerified: true,
    };
    return this.http.patch<void>(
      `${environment.api_url}/account/${externalAuthSystemId}`,
      bodyUser,
    );
  }
}
