import { RateType, TermType } from './enums';

export enum IncrementCategory {
  STANDARD_INCREMENT = 'STANDARD_INCREMENT',
  NON_INCOME_QUALIFYING = 'NON_INCOME_QUALIFYING',
  HORIZON_CREDIT_PROGRAM = 'HORIZON_CREDIT_PROGRAM',
  BRIDGE_LOAN = 'BRIDGE_LOAN',
}

export enum IncrementType {
  REFINANCE = 'REFINANCE',
  AMORTIZATION_GREATER_THAN_25_YEARS = 'AMORTIZATION_GREATER_THAN_25_YEARS',
  SECOND_MORTGAGE = 'SECOND_MORTGAGE',
  NON_OWNER_OCCUPIED = 'NON_OWNER_OCCUPIED',
  STRESS_TEST = 'STRESS_TEST',
  BEACON_550_TO_599 = 'BEACON_550_TO_599',
  BEACON_600_TO_679 = 'BEACON_600_TO_679',
  BEACON_640_TO_679 = 'BEACON_640_TO_679',
  BEACON_600_TO_639 = 'BEACON_600_TO_639',
  BEACON_680_TO_749 = 'BEACON_680_TO_749',
  BEACON_GREATER_THAN_EQUAL_TO_750 = 'BEACON_GREATER_THAN_EQUAL_TO_750',
  BEACON_LESS_THAN_EQUAL_TO_549 = 'BEACON_LESS_THAN_EQUAL_TO_549',
}

export enum IncrementMortgageTerm {
  SIX_MONTHS = 6,
  ONE_YEAR = 12,
  TWO_YEAR = 24,
  THREE_YEAR = 36,
  FOUR_YEAR = 48,
  FIVE_YEAR = 60,
  SEVEN_YEAR = 84,
  TEN_YEAR = 120,
}

export interface Increment {
  id: string;
  type: IncrementType;
  category: IncrementCategory;
  amount: number;
  incomeStatus?: IncrementIncomeStatus;
  mortgageTerm?: IncrementMortgageTerm; // number of months
  mortgageTermType?: TermType;
  mortgageRateType?: RateType;
  ratioCharacteristics?: IncrementRatioCharacteristics;
}

export interface MortgageIncrement extends Increment {
  isChecked: boolean;
}

export enum IncrementIncomeStatus {
  QUALIFYING_INCOME = 'QUALIFYING_INCOME',
  NON_QUALIFYING_INCOME = 'NON_QUALIFYING_INCOME',
}

export enum IncrementRatioCharacteristics {
  ALL_LTV = 'ALL_LTV',
}

export interface Decrement {
  id: string;
  name: string;
  status: DecrementStatus;
  amount: number;
}

export enum DecrementStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export interface MortgageDecrement extends Decrement {
  isChecked: boolean;
}

export enum DiscretionStatus {
  VALID = 'VALID',
  NEED_APPROVAL = 'NEED_APPROVAL',
}
