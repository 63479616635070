import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Rate } from '@fundmoreai/models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class RateService {
  constructor(private http: HttpClient) {}

  getRates(): Observable<Rate[]> {
    return this.http.get<Rate[]>(`${environment.fundmore_api_url}/rateMatrix/rates`);
  }

  postRate(rate: Partial<Rate>): Observable<Rate> {
    return this.http.post<Rate>(`${environment.fundmore_api_url}/rateMatrix/rates`, rate);
  }

  patchRate(rate: Rate) {
    return this.http.patch<void>(`${environment.fundmore_api_url}/rateMatrix/rates`, rate);
  }

  deleteRate(id: string) {
    return this.http.delete<void>(`${environment.fundmore_api_url}/rateMatrix/rates/${id}`);
  }
}
