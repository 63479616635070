import {
  CmhcBorrowerEmploymentType,
  CmhcBorrowerIndustrySector,
  CmhcBorrowerMaritalStatus,
  CmhcBorrowerOccupationType,
  CmhcBorrowerResidentialStatus,
  CmhcLanguageCode,
  CmhcLoanDetailProcessingIndicator,
  CmhcLoanDetailsInstallmentCode,
  CmhcLoanDetailsInterestType,
  CmhcLoanDetailsLoanPurpose,
  CmhcLoanDetailsRepaymentType,
  CmhcLoanDetailsSecurityType,
  CmhcLoanDetailsSourceOfBusiness,
  CmhcPropertyGarageSize,
  CmhcPropertyGarageType,
  CmhcPropertyHeatingType,
  CmhcPropertyHouseType,
  CmhcPropertyNewExistingIndicator,
  CmhcQualifyingInformationApplicationType,
  CmhcQualifyingInformationOccupancyType,
} from './enums';

export interface CmhcResponseBaseValuePeriod {
  months?: number;
  years?: number;
}

export interface CmhcResponseBaseBorrower {
  name: { first?: string; last?: string };
  birthDate: string;
}

export interface CmhcResponseBasePhoneNumber {
  areaCode: string;
  localNumber: string;
}

export interface CmhcResponseBaseAddress {
  unitNumber?: string;
  streetNumber?: string;
  streetName?: string;
  streetType?: string;
  streetDirection?: string;
  municipality?: string;
  postalCode?: string;
  province?: string;
}

export interface CmhcLenderClientServiceRep {
  name: { first?: string; last?: string };
  phoneNumber?: CmhcResponseBasePhoneNumber;
}

export interface CmhcLenderOriginatingMortRep {
  name: { first?: string; last?: string };
  phoneNumber?: CmhcResponseBasePhoneNumber;
}

export interface CmhcApplicationLender {
  finInst: string;
  transit: string;
  lenderReference: string;
  clientServiceRep?: CmhcLenderClientServiceRep;
  originatingMortRep?: CmhcLenderOriginatingMortRep;
}

export interface CmhcResponseBorrowerEmployment {
  occupation?: CmhcBorrowerOccupationType;
  yearsService?: CmhcResponseBaseValuePeriod;
  yearsWithCurrentEmployer?: CmhcResponseBaseValuePeriod;
  industrySector?: CmhcBorrowerIndustrySector;
}

export interface CmhcApplicationMainCoBorrower extends CmhcResponseBaseBorrower {
  phoneNumber?: CmhcResponseBasePhoneNumber;
  address?: CmhcResponseBaseAddress;
  durationAtAddress?: CmhcResponseBaseValuePeriod;
  nonPermanentResident?: boolean;
  socialInsuranceNumber?: string;
  borrowerEmployment?: CmhcResponseBorrowerEmployment;
}

export interface CmhcApplicationMainBorrower extends CmhcApplicationMainCoBorrower {
  borrowerType: CmhcBorrowerEmploymentType;
  residentialStatusType?: CmhcBorrowerResidentialStatus;
  maritalStatus?: CmhcBorrowerMaritalStatus;
  dependents?: string;
  coBorrower1?: CmhcApplicationMainCoBorrower;
  coBorrower2?: CmhcResponseBaseBorrower;
  coBorrower3?: CmhcResponseBaseBorrower;
  guarantor?: CmhcResponseBaseBorrower;
}

export interface CmhcApplicationProperty {
  age?: string;
  newExistingIndicator?: CmhcPropertyNewExistingIndicator;
  legalDescription?: string;
  numberOfUnits?: string;
  tenure?: string;
  dwellingType?: string;
  houseType?: CmhcPropertyHouseType;
  floorArea?: string;
  isEnvironmentalHazard?: string;
  lotSize?: string;
  garageSize?: CmhcPropertyGarageSize;
  garageType?: CmhcPropertyGarageType;
  heatingType?: CmhcPropertyHeatingType;
  address?: CmhcResponseBaseAddress;
}

export interface CmhcResponseQualifyingInformationSourcesOfEquity {
  cashFromOwnResource?: boolean;
  saleOfOtherProperty?: boolean;
  rrsp?: boolean;
  sweatEquity?: boolean;
  borrowedAgainstAssets?: boolean;
  gift?: boolean;
  other?: boolean;
  selfDirectedRrsp?: boolean;
}

export interface CmhcApplicationQualifyingInformation {
  totalIncome?: string;
  grossDebtService?: string;
  totalDebtService?: string;
  annualShelterPayment?: string;
  propertyTaxes?: string;
  applicationType?: CmhcQualifyingInformationApplicationType;
  equity: string;
  occupancyType: CmhcQualifyingInformationOccupancyType;
  sourceOfEquity?: CmhcResponseQualifyingInformationSourcesOfEquity;
  flexibleDownpayment?: boolean;
}

export interface CmhcApplicationLoanDetails {
  sellingPrice?: string;
  closingDate?: string;
  loanPurpose?: CmhcLoanDetailsLoanPurpose;
  loanAmount?: string;
  sourceOfBusiness?: CmhcLoanDetailsSourceOfBusiness;
  repaymentType?: CmhcLoanDetailsRepaymentType;
  interestRate?: string;
  interestType?: CmhcLoanDetailsInterestType;
  term?: CmhcResponseBaseValuePeriod;
  amortizationPeriod?: CmhcResponseBaseValuePeriod;
  securityType?: CmhcLoanDetailsSecurityType;
  processingIndicator?: CmhcLoanDetailProcessingIndicator;
  otherFinancing?: boolean;
  improvements?: boolean;
  premiumToBeAdded?: boolean;
  blendedAmortization?: boolean;
  balanceExistingMortgage?: string;
  previousSaleClosingDate?: string;
  previousSalePrice?: string;
  asImprovedValue?: string;
  installmentCompletionCode?: CmhcLoanDetailsInstallmentCode;
  loanToValueRatio?: string;
  energyEfficiency?: boolean;
  homeBuilderProcess?: boolean;
  builderName?: string;
  constructionImprovementCosts?: string;
  cmhcSelfEmployedSimplified?: boolean;
  marketValue?: string;
  referralRequested?: boolean;
  loanAdvanceAmount?: string;
  loanAdvanceNumber?: string;
  topUpAmount?: string;
  lendingValue?: string;
}

export interface CmhcApplicationMessage {
  code: string;
  description: string;
}

export interface CmhcApplicationResponseTax {
  due: number;
  received: number;
}

export type CmhcApplicationResponse = {
  cmhcAccountNumber: string;
  language: CmhcLanguageCode;
  lender: CmhcApplicationLender;
  borrower: CmhcApplicationMainBorrower;
  property: CmhcApplicationProperty;
  qualifyingInformation: CmhcApplicationQualifyingInformation;
  loanDetails: CmhcApplicationLoanDetails;
  comment: string;
  messages: CmhcApplicationMessage[];
  productTrackerId: string;
  onReserveTrustAgreement: string;
  previousCMHCAccountNumber: string;
  prequalID: string;
  locApp: string;
  locRepayment: string;
  status: string;
  everApprovedIndicator: string;
  everLowRatio: string;
  fees?: CmhcApplicationResponseTax[];
  premium?: CmhcApplicationResponseTax[];
  tax?: CmhcApplicationResponseTax[];
  document?: string;
};
