import { Fee } from '@fundmoreai/models';

export class SetFees {
  static readonly type = '@fees.SetFees';
  constructor(public fees: Fee[]) {}
}

export class UpdateFee {
  static readonly type = '@fees.UpdateFee';
  constructor(
    public applicationId: string,
    public fee: Fee,
    public manuallyUpdated: boolean = false,
  ) {}
}

export class CreateFee {
  static readonly type = '@fees.CreateFee';
  constructor(
    public applicationId: string,
    public fee: Fee,
    public manuallyUpdated: boolean = false,
  ) {}
}

export class RemoveFee {
  static readonly type = '@fees.RemoveFee';
  constructor(public applicationId: string, public fee: Fee) {}
}
