import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import {
  Application,
  ApplicationAssignedUser,
  Applicant,
  PurchaseMortgageClassification,
  RefinanceMortgageClassification,
  ETOMortgageClassification,
  SwitchTransferClassification,
  RenewalClassification,
  ConstructionClassification,
  BridgeFinancingClassification,
  OtherClassification,
  MortgageClassificationOptions,
  LockHistory,
} from '../shared';
import { AnalysedMortgageApplicationModel } from '../features/pipeline/upload/recognitionModel';
import {
  DuplicateApplication,
  DuplicatesEmail,
  ManageDuplicateApplicationViewModel,
} from '../features/application/sidebar/manage-duplicates/model';
import {
  ApplicationApprove,
  CustomerType,
  ApplicationWarningKeys,
  SupportedCustomEntity,
  ApplicationKey,
  ApplicationPending,
  WorkoutMortgageClassification,
  DeficiencySaleMortgageClassification,
  ApplicationPermissions,
  ApplicationPurposeType,
  PipelineApplication,
  ApplicationStage,
  PriorityType,
  ApplicationDecisionEngineFlags,
  ExpandedLoanNumber,
  VelocityExternalDealContent,
} from '@fundmoreai/models';
import { ApplicationStatus } from '../shared/model';
import { AssignedUserTask, Task } from '../features/tasks/model';
import { ApplicationNamePipe } from '../shared/application-name.pipe';
import { FieldMetadataState } from '../shared/custom-fields/field-metadata.state';
import { Store } from '@ngxs/store';
import { Role } from '../features/shared/user';
import { PermissionsService } from '../auth/permissions.service';
import { ApplicantsService } from './applicants.service';
import { ExternalDealContent } from '../features/application/external-deal-content/external-deal-content.model';
import { AppFeaturesState } from '../shared/app-features.state';
import { OriginalDataContent } from '../features/application/original-data-content/original-data-content.model';
import { ApplicantNamePipe } from '../shared/applicant-name.pipe';

interface AssignUserResponse {
  updatedTasks: AssignedUserTask[];
}

export interface ApplicationCreateResponse {
  id: string;
}

export interface UpdateApplicationLoanNumberMortgageResponse {
  id: string;
  loanNumber: string;
  mortgageProductId: string;
  expandedLoanNumber: ExpandedLoanNumber;
}

interface UpdateApplicationLoanNumberResponse {
  loanNumber: string;
  expandedLoanNumber: ExpandedLoanNumber;
  mortgages: UpdateApplicationLoanNumberMortgageResponse[];
}

@Injectable({
  providedIn: 'root',
})
export class MortgageApplicationService {
  constructor(private http: HttpClient, private store: Store) {}

  getApplication(applicationId: string): Observable<Application> {
    let headers = new HttpHeaders();
    headers = headers.set('x-compression', 'true');

    return this.http
      .get<Application>(`${environment.api_url}/applications/${applicationId}`, { headers })
      .pipe(
        map((application) => {
          this.computeApplicationPermissions(application.uiAbstractPermissions);

          // TODO update once there's server support
          application.applicants.forEach(
            (stakeholder) => (stakeholder.contacts = ApplicantsService.makeContacts(stakeholder)),
          );

          return application;
        }),
      );
  }

  getApplicationLockHistory(applicationId: string): Observable<LockHistory[]> {
    return this.http.get<LockHistory[]>(
      `${environment.api_url}/applications/${applicationId}/locks`,
    );
  }

  getApplicationDecisionEngine(applicationId: string): Observable<ApplicationDecisionEngineFlags> {
    return this.http.get<ApplicationDecisionEngineFlags>(
      `${environment.fundmore_v2_api_url}/application/${applicationId}/decision-engine-flags`,
    );
  }

  patchApplication(
    applicationId: string,
    updatedApplication: Partial<Application>,
  ): Observable<void> {
    return this.http.patch<void>(
      `${environment.api_url}/applications/${applicationId}`,
      updatedApplication,
    );
  }

  updateApplicationDocsDueDate(
    applicationId: string,
    ezidoxApplicationId: number,
    docsDueDate: string,
  ): Observable<void> {
    return this.http.put<void>(
      `${environment.api_url}/applications/${applicationId}/updateDocsDueDate`,
      {
        applicationId,
        ezidoxApplicationId,
        docsDueDate,
      },
    );
  }

  updateFund(applicationId: string, fundId: string): Observable<void> {
    return this.http.put<void>(`${environment.api_url}/applications/${applicationId}/updateFund`, {
      applicationId,
      fundId,
    });
  }

  updateDeclineDate(applicationId: string, declineDate: string | undefined): Observable<void> {
    return this.http.put<void>(
      `${environment.api_url}/applications/${applicationId}/updateDeclineDate`,
      {
        applicationId,
        declineDate,
      },
    );
  }

  updateApplicationPriority(
    applicationId: string,
    priority?: PriorityType | null,
  ): Observable<void> {
    return this.http.patch<void>(
      `${environment.fundmore_v2_api_url}/application/${applicationId}/priority`,
      {
        priority,
      },
    );
  }

  patchApplicationSetArchived(
    applicationId: string,
    updatedApplication?: Partial<Application>,
  ): Observable<void> {
    return this.http.patch<void>(
      `${environment.api_url}/applications/${applicationId}/setArchived`,
      updatedApplication,
    );
  }

  deleteApplication(applicationId: string): Observable<void> {
    return this.http.delete<void>(`${environment.api_url}/applications/${applicationId}`);
  }

  moveToStage(
    applicationId: string,
    newStage: ApplicationStage,
  ): Observable<{ applicationData: Partial<Application>; newApplicationTasks: Task[] }> {
    return this.http.patch<{ applicationData: Partial<Application>; newApplicationTasks: Task[] }>(
      `${environment.api_url}/applications/${applicationId}/moveToStage`,
      {
        newStage,
      },
    );
  }

  declineApplication(
    applicationId: string,
    reason: Partial<Application>,
    localizedDeclinedText: string,
    localizedCommentText: string,
  ): Observable<{ declineApplicationResult: Partial<Application>; newApplicationTasks: Task[] }> {
    return this.http.patch<{
      declineApplicationResult: Partial<Application>;
      newApplicationTasks: Task[];
    }>(`${environment.api_url}/applications/${applicationId}/decline`, {
      declineReason: reason,
      localizedDeclinedText,
      localizedCommentText,
    });
  }

  cancelApplication(
    applicationId: string,
    reason: Partial<Application>,
    localizedCancelledText: string,
  ): Observable<{ cancelApplicationResult: Partial<Application>; newApplicationTasks: Task[] }> {
    return this.http.patch<{
      cancelApplicationResult: Partial<Application>;
      newApplicationTasks: Task[];
    }>(`${environment.api_url}/applications/${applicationId}/cancel`, {
      cancelReason: reason,
      localizedCancelledText,
    });
  }

  createApplication(
    application: Partial<AnalysedMortgageApplicationModel>,
    duplicatesEmail: DuplicatesEmail | undefined,
  ): Observable<ApplicationCreateResponse> {
    const applicationInfo = {
      application: application,
      duplicatesEmail: duplicatesEmail,
    };
    return this.http.post<Application>(`${environment.api_url}/applications`, applicationInfo);
  }

  searchApplication({
    name,
    limit,
    includeArchived,
  }: {
    name: string;
    limit?: number;
    includeArchived?: boolean;
  }): Observable<PipelineApplication[]> {
    return this.http
      .post<PipelineApplication[]>(`${environment.api_url}/applications/search`, {
        name,
        limit,
        includeArchived,
      })
      .pipe(
        map((a) => {
          a.forEach((item) => {
            item.primaryApplicantName = this.getPrimaryApplicantName(item);
            item.otherApplicants = this.getOtherApplicants(item);
          });
          return a;
        }),
      );
  }

  assignUser(applicationId: string, userIds: string[], role: Role): Observable<AssignUserResponse> {
    return this.http.put<AssignUserResponse>(
      `${environment.api_url}/applications/${applicationId}/assignedUsers`,
      { userIds, roleId: role.id },
    );
  }

  assignedUsers(applicationId: string): Observable<ApplicationAssignedUser[]> {
    return this.http.get<ApplicationAssignedUser[]>(
      `${environment.api_url}/applications/${applicationId}/assignedUsers`,
    );
  }

  patchApplicationSetLock(
    applicationId: string,
    updatedApplication: Partial<Application>,
    lockDetails: Partial<LockHistory>,
  ): Observable<LockHistory> {
    return this.http.patch<LockHistory>(
      `${environment.api_url}/applications/${applicationId}/locks`,
      {
        ...updatedApplication,
        ...lockDetails,
      },
    );
  }

  pendingApplication(
    applicationId: string,
    applicationPending: ApplicationPending,
    localizedPendingText: string,
    localizedCommentText: string,
  ): Observable<ApplicationStatus> {
    return this.http.put<ApplicationStatus>(
      `${environment.fundmore_api_url}/applications/${applicationId}/pending`,
      {
        pendingApplication: applicationPending,
        localizedPendingText,
        localizedCommentText,
      },
    );
  }

  approveApplication(
    applicationApprove: ApplicationApprove,
    localizedConditionallyApprovedText: string,
    localizedCommentText: string,
  ): Observable<ApplicationStatus> {
    return this.http.put<ApplicationStatus>(
      `${environment.api_url}/applications/${applicationApprove.applicationId}/approve`,
      {
        approveApplication: applicationApprove,
        localizedConditionallyApprovedText,
        localizedCommentText,
      },
    );
  }

  getDocumentRequestStatus(applicationId: string): Observable<{ documentRequestShared: boolean }> {
    return this.http.get<{ documentRequestShared: boolean }>(
      `${environment.api_url}/ezidox/documentRequestStatus/${applicationId}`,
    );
  }

  private computeApplicationPermissions(uiAbstractPermissions?: ApplicationPermissions) {
    if (!uiAbstractPermissions) {
      return;
    }
    if (uiAbstractPermissions.canEdit) {
      uiAbstractPermissions.applicationStageMovePermissionsMap =
        PermissionsService.getApplicationStageMovePermissionsMap(
          uiAbstractPermissions?.canMoveStage,
          this.store.selectSnapshot(AppFeaturesState.tenantStages),
        );
    } else {
      uiAbstractPermissions.applicationStageMovePermissionsMap = {};
      uiAbstractPermissions.canAssignUsers = [];
      uiAbstractPermissions.canDeleteApplication = [];
    }

    uiAbstractPermissions.canBypassTasks =
      uiAbstractPermissions.canBypassTasks && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canChat = uiAbstractPermissions.canChat && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canDecline =
      uiAbstractPermissions.canDecline && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canUpdateApplicant =
      uiAbstractPermissions.canUpdateApplicant && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canDeleteApplicant =
      uiAbstractPermissions.canDeleteApplicant && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canEditBaseRate =
      uiAbstractPermissions.canEditBaseRate && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canEditBuyDownRate =
      uiAbstractPermissions.canEditBuyDownRate && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canEditDiscount =
      uiAbstractPermissions.canEditDiscount && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canEditPrepaymentPeriod =
      uiAbstractPermissions.canEditPrepaymentPeriod && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canFund = uiAbstractPermissions.canFund && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canIncludeInAPR =
      uiAbstractPermissions.canIncludeInAPR && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canRevertDecision =
      uiAbstractPermissions.canRevertDecision && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canAddApplicant =
      uiAbstractPermissions.canAddApplicant && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canAssignBroker =
      uiAbstractPermissions.canAssignBroker && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canArchiveApplication =
      uiAbstractPermissions.canArchiveApplication && uiAbstractPermissions.canEdit;

    uiAbstractPermissions.canUploadConditionDocument =
      uiAbstractPermissions.canUploadConditionDocument && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canEditConditionDocument =
      uiAbstractPermissions.canEditConditionDocument && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canReviewConditionDocument =
      uiAbstractPermissions.canReviewConditionDocument && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canCommentOnConditionDocument =
      uiAbstractPermissions.canCommentOnConditionDocument && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canDeleteConditionDocument =
      uiAbstractPermissions.canDeleteConditionDocument && uiAbstractPermissions.canEdit;

    uiAbstractPermissions.canAddApplicationCondition =
      uiAbstractPermissions.canAddApplicationCondition && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canReviewApplicationCondition =
      uiAbstractPermissions.canReviewApplicationCondition && uiAbstractPermissions.canEdit;
    uiAbstractPermissions.canDeleteApplicationCondition =
      uiAbstractPermissions.canDeleteApplicationCondition && uiAbstractPermissions.canEdit;

    uiAbstractPermissions.canPullEquifaxReport =
      uiAbstractPermissions.canPullEquifaxReport && uiAbstractPermissions.canEdit;
  }

  public getPrimaryApplicantName(item: Application | PipelineApplication): string {
    const applicationNamePipe = new ApplicationNamePipe();
    let primaryApplicantName = applicationNamePipe.transform(item);
    const primaryApplicant = item.applicants.find((applicant) => applicant.isPrimary);
    if (primaryApplicant) {
      const applicantNamePipe = new ApplicantNamePipe();
      primaryApplicantName = applicantNamePipe.transform(primaryApplicant);
    }
    return primaryApplicantName;
  }

  public getPrimaryApplicantCustomerType(
    item: Application | PipelineApplication,
  ): CustomerType | undefined {
    const primaryApplicant = item.applicants.find((applicant) => applicant.isPrimary);

    return primaryApplicant?.customerType;
  }

  public getOtherApplicants(item: PipelineApplication): Applicant[] {
    const primaryApplicant = item.applicants.find((applicant) => applicant.isPrimary);
    let otherApplicants = item.applicants;
    if (primaryApplicant) {
      otherApplicants = item.applicants.filter((applicant) => applicant !== primaryApplicant);
    }
    return otherApplicants;
  }

  public getApplicationDuplicates(applicationId: string) {
    return this.http.get<ManageDuplicateApplicationViewModel[]>(
      `${environment.api_url}/applications/${applicationId}/duplicates`,
    );
  }

  public findApplicationDuplicates(name: string, surname: string, address?: string | null) {
    const params = new HttpParams()
      .set('name', name)
      .set('surname', surname)
      .set('address', `${address}`);
    return this.http.get<DuplicateApplication[]>(`${environment.api_url}/applications/duplicates`, {
      params,
    });
  }

  public getMortgageClassificationOption(type: ApplicationPurposeType, all?: boolean) {
    let mortgageClassificationOptions: MortgageClassificationOptions;
    switch (ApplicationPurposeType[type]) {
      case ApplicationPurposeType.PURCHASE.toString():
        mortgageClassificationOptions = PurchaseMortgageClassification;
        break;
      case ApplicationPurposeType.REFINANCE.toString():
        mortgageClassificationOptions = RefinanceMortgageClassification;
        break;
      case ApplicationPurposeType.ETO.toString():
        mortgageClassificationOptions = ETOMortgageClassification;
        break;
      case ApplicationPurposeType.DEFICIENCY_SALE.toString():
        mortgageClassificationOptions = DeficiencySaleMortgageClassification;
        break;
      case ApplicationPurposeType.WORKOUT.toString():
        mortgageClassificationOptions = WorkoutMortgageClassification;
        break;
      case ApplicationPurposeType.SWITCH_TRANSFER.toString():
        mortgageClassificationOptions = SwitchTransferClassification;
        break;
      case ApplicationPurposeType.RENEWAL.toString():
        mortgageClassificationOptions = RenewalClassification;
        break;
      case ApplicationPurposeType.CONSTRUCTION.toString():
        mortgageClassificationOptions = ConstructionClassification;
        break;
      case ApplicationPurposeType.BRIDGE_FINANCING.toString():
        mortgageClassificationOptions = BridgeFinancingClassification;
        break;
      case ApplicationPurposeType.OTHER.toString():
        mortgageClassificationOptions = OtherClassification;
        break;
      default:
        mortgageClassificationOptions = {};
        break;
    }

    const customOptions = this.getCustomOptions(mortgageClassificationOptions, all);

    return customOptions;
  }

  public setApplicationWarnings(applicationId: string, warnings: ApplicationWarningKeys[]) {
    return this.http.put<void>(`${environment.api_url}/applications/${applicationId}/warnings`, {
      warnings,
    });
  }

  public getApplicationExternalDealContent(applicationId: string) {
    return this.http.get<ExternalDealContent>(
      `${environment.fundmore_api_url}/applications/${applicationId}/externalDealContent`,
    );
  }

  public getApplicationOriginalDataContent(applicationId: string) {
    return this.http.get<OriginalDataContent>(
      `${environment.fundmore_api_url}/applications/${applicationId}/originalData`,
    );
  }

  private getCustomOptions(currentClassifications: MortgageClassificationOptions, all?: boolean) {
    const customOptions = all
      ? this.store.selectSnapshot(
          FieldMetadataState.getAllFieldOptions(
            SupportedCustomEntity.APPLICATION,
            ApplicationKey.MORTGAGE_CLASSIFICATION,
          ),
        )
      : this.store.selectSnapshot(
          FieldMetadataState.getVisibleFieldOptions(
            SupportedCustomEntity.APPLICATION,
            ApplicationKey.MORTGAGE_CLASSIFICATION,
          ),
        );

    if (!customOptions || Object.keys(customOptions).length === 0) {
      return {};
    }

    if (all) {
      return customOptions;
    }

    const totalExisting: MortgageClassificationOptions = {
      ...PurchaseMortgageClassification,
      ...RefinanceMortgageClassification,
      ...ETOMortgageClassification,
      ...SwitchTransferClassification,
      ...RenewalClassification,
      ...ConstructionClassification,
      ...BridgeFinancingClassification,
      ...OtherClassification,
      ...DeficiencySaleMortgageClassification,
      ...WorkoutMortgageClassification,
    };

    const existingOptions = Object.keys(customOptions).filter((o) =>
      Object.keys(currentClassifications ?? {}).includes(o),
    );

    const newOptions = Object.keys(customOptions).filter(
      (o) => !Object.keys(totalExisting).includes(o),
    );

    const totalOptions: MortgageClassificationOptions = [
      ...(newOptions ?? []),
      ...(existingOptions ?? []),
    ].reduce(
      (prev, curr) => ({
        ...prev,
        [curr]: customOptions[curr],
      }),
      {},
    );

    return totalOptions;
  }

  updateApplicationLoanNumber(applicationId: string, expandedLoanNumber: ExpandedLoanNumber) {
    return this.http.put<UpdateApplicationLoanNumberResponse>(
      `${environment.fundmore_v2_api_url}/application/${applicationId}/loanNumber`,
      {
        expandedLoanNumber,
      },
    );
  }

  markApplicationAsCommitted(applicationId: string): Observable<ApplicationStatus> {
    return this.http.put<ApplicationStatus>(
      `${environment.fundmore_v2_api_url}/application/${applicationId}/mark-as-committed`,
      {},
    );
  }

  markApplicationAsAwaitingDocs(applicationId: string): Observable<ApplicationStatus> {
    return this.http.put<ApplicationStatus>(
      `${environment.fundmore_v2_api_url}/application/${applicationId}/mark-as-awaiting-docs`,
      {},
    );
  }

  createVelocityApplication(payload: string): Observable<string> {
    try {
      payload = JSON.parse(payload);
    } catch (e) {
      console.error('Error parsing Velocity payload', e);
    }
    return this.http.post(
      `${environment.velocity_api_url}/applications`,
      {
        message: payload,
      },
      { responseType: 'text' },
    );
  }

  createLendeskApplication(payload: string): Observable<string> {
    return this.http.post<string>(`${environment.lendesk_api_url}/applications`, {
      base64: payload,
    });
  }
  createFilogixApplication(payload: string): Observable<string> {
    return this.http.post(
      `${environment.filogix_api_url}/applications`,
      {
        message: payload,
      },
      { responseType: 'text' },
    );
  }
}
