import { FeeCalculation } from './fee.calculation';
import { RequestedMortgageCalculation } from './requested-mortgage.calculation';
import { SubjectPropertyCalculation } from './subject-property.calculation';

export interface ApplicationCalculation {
  applicationId: string;
  tenantId: string;
  subjectProperty: SubjectPropertyCalculation;
  requestedMortgages: RequestedMortgageCalculation[] | null;
  fees: FeeCalculation[] | null;
}
