import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { of, finalize, tap } from 'rxjs';
import { ChargesService } from '../services/charges.service';
import { ChargesModel } from '../model';
import { GetCharges } from './charges.actions';
import { LoadingStart, LoadingEnd } from '../../../../core/loading.state';

/** @deprecated This feature does not exist. */
@State<ChargesModel>({
  name: 'Charges',
  defaults: {
    charges: [],
    chargeApplicationId: '',
  },
})
@Injectable()
export class ChargesState {
  constructor(private store: Store, private chargesService: ChargesService) {}

  @Selector() static charges(state: ChargesModel) {
    return state.charges;
  }

  @Action(GetCharges) getCharges(ctx: StateContext<ChargesModel>, { applicationId }: GetCharges) {
    const state = ctx.getState();
    if (state.chargeApplicationId === applicationId) {
      return of(state.charges);
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.chargesService.getCharges(applicationId).pipe(
      tap((charges) => ctx.patchState({ charges, chargeApplicationId: applicationId })),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }
}
