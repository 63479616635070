import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApplicationNoteStatus } from '@fundmoreai/models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Note } from './notes.model';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  private base = `${environment.fundmore_api_url}/applicationNote`;

  constructor(private datePipe: DatePipe, private http: HttpClient) {}

  deleteDraftNote(noteId: string) {
    return this.http.delete(`${this.base}/${noteId}`);
  }

  generateNotesPdfContent(
    buyersStakeholdersNames: string,
    loanNumbers: string,
    notes: Note[],
    propertyAddress: string,
  ) {
    const openInNewIconImg =
      // eslint-disable-next-line max-len
      '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAV5JREFUaEPtmf0NAiEMxd9NoBvoKG6iI7iBOok6mhPoBpomXoInXPksvaT8TeH3+gocx4CFt2Hh/DABvR00B7Q7cAJwBLDOBHUdfmeOMYZ5q2WuhM4ASEBJ6yrgWZB5X9bEHXAnlFzsVwAHj+3JJdRDQAie9KgXMIW/A9g7TqgW4IOnMmKrYK622eCS7cmJDcFTF5aht4A5ePUCOHjVAmLg1QqIhVcpIAVenYBUeFUCcuDVCMiFVyGgBD7qnGx5kDWHD37hfaWzx/hMikTgWwmoCc8msXYJ1YQXX8S14UUFtIAXFUCXDxJBjW5Svjtt1LY46SS6Bgh6VxFe1IGc7MbEiDoQA5TaxwSMGZP8seW6ZA6YA6mrtuc5UMjqDS9aAy8AqxZUGWM+AGx9ca0fODJYvSEXAPTg8te47ZGC6BNhU4skcRzK/C0ET2NxAhLnk+9uAuRz/jujOWAOFGbgA0xvbDHdTx6pAAAAAElFTkSuQmCC">';
    const style = `
      <style>
        .no-page-break {
          break-inside: avoid;
        }

        .bg-grey {
          background-color: #eeeeee;
        }

        .bg-light-grey {
          background-color: #f9f9f9;
        }

        .border-bottom {
          border-bottom: 1px solid #e0e0e0;
        }

        .border-rounded {
          border-radius: 10px;
        }

        .text-dark {
          color: rgba(0, 0, 0, 0.6);
        }

        img {
          width: 18px;
        }

        .note {
          margin-bottom: 20px;
          padding: 10px;
        }

        .note-header {
          display: flex;
          align-items: center;
        }

        .note-header img {
          margin-right: 4px;
        }

        .note-author-role {
          margin: 0 10px;
          padding: 2px 8px;
        }

        .note-content {
          line-height: 1.4;
        }

        .note-content img {
          margin-left: 4px;
        }
      </style>`;
    const title = `<h3>Loan Number: ${loanNumbers}</h3>
    <h3>Applicants: ${buyersStakeholdersNames}</h3>
    <h3>Subject Property Address: ${propertyAddress}</h3>`;
    const htmlContent =
      title +
      notes
        .map(
          (note) =>
            `<div class="note border-bottom ${
              note.status === ApplicationNoteStatus.DRAFT ? 'bg-light-grey' : ''
            }">${this.generateNoteHeader(note)}<div class="note-content">
              ${note.content.replace(/(?:\r\n|\r|\n)/g, '<br>')}
            </div></div>`,
        )
        .join('\n') +
      style;

    return htmlContent
      .replace(
        new RegExp(
          '<mat-icon class="mat-icon material-icons mat-icon-sm">open_in_new</mat-icon>',
          'g',
        ),
        openInNewIconImg,
      )
      .replace(
        new RegExp('href="/portal/review-condition-documents/', 'g'),
        `href="${window.location.origin}/portal/review-condition-documents/`,
      );
  }

  getAll(applicationId: string): Observable<Note[]> {
    return this.http.get<Note[]>(`${this.base}/${applicationId}`);
  }

  publishDraftNote(noteId: string, content: string): Observable<Note> {
    return this.http.patch<Note>(`${this.base}/${noteId}`, {
      content,
      status: ApplicationNoteStatus.PUBLISHED,
    });
  }

  save(applicationId: string, content: string): Observable<Note> {
    return this.http.post<Note>(this.base, {
      applicationId,
      content,
      status: ApplicationNoteStatus.PUBLISHED,
    });
  }

  saveAsDraft(applicationId: string, content: string): Observable<Note> {
    return this.http.post<Note>(this.base, {
      applicationId,
      content,
      status: ApplicationNoteStatus.DRAFT,
    });
  }

  updateDraftNote(noteId: string, content: string): Observable<Note> {
    return this.http.patch<Note>(`${this.base}/${noteId}`, {
      content,
      status: ApplicationNoteStatus.DRAFT,
    });
  }

  private generateNoteHeader(note: Note) {
    const accountCircleIconImg =
      // eslint-disable-next-line max-len
      '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAABM0lEQVQ4y73TP0oDQRTH8U/+kUKFHMImwVNYCiksFu9gFgLeQskB7LPXSO0BkiJkm1Q5QAQhWDyLrMbdLAEFnWnevJkf83tvvsN/j47E1MqrVytTic6p47dyUZm52/rDTY9CmBvrO3eub2wuhEfNY8GTsHNf2Wq6txOejs2EnWvQ82xj41kPXNuJsrGOXBiBlpcv/y9aYCTk38tPhHlh5kbIXbqUCzeFsbmQHASZMC7iB2ECJsJDkR0L2UGwFAZFXHcDA2F5EGyFiyKuq4ELYVsWnH2tql2Cs7JgKfRPItMvW8qE9KQgLRedCAsNtN3JrLx5s5K500bDotzW/cOlhtZH8K0NpdWH26PxXsCXutLVdSUt4HuvovEJX5hpl7JtM1EH33e8j2+oxfvHH+hXX/Qvxgdv7aUh6mD0HAAAAABJRU5ErkJggg==">';
    const todayIconImg =
      // eslint-disable-next-line max-len
      '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAQAAABKfvVzAAAARElEQVQ4jWNgoAj8B0JixMjR4MnwGCyFCz5m8EDV8AivchB8RIJLscmTpYEQpFwD7f2A6rQRpAEXGAANJCdvkjPQIAIAhe+4hioUy7wAAAAASUVORK5CYII=">';
    const editDocumentIconImg =
      // eslint-disable-next-line max-len
      '<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAAX1JREFUaEPtmf1NwzAQxV8moBsURmCCdpPSDbpB2QQ2absBI7ABbEB1SJEcyw6H/eK7SPbf/ni/+3DOlwErH8PK9aMDWHtQ64EzgBOADUHwO4AjYZ/fLTQArwAEgDloEBqAL5LlYwNQIDQAPyTTS9i8RXtVQ7QEkLNe2BCtAcQBVAgLACqEFQANwhKAAmENUA3hAaAKwgtAMYQngBzErEZvACkINwClFUkHYBVz3QMpC7RM4u6B7oHSGKhct+prVJ6h8uzMDs9JHIt/BLAFcAtpvALE4sNn6KQR4BEgFr8HcIli6BnAh7ax1fJLnIt5sfohgHgC8OkNIBU2u6ANOUJM5nkJIW3MSzhdvSXxnPhRa7aDZ+0BjXiByH4PLAGqxVsmMUW8FQBNvAUAVXxrALr41gDhhZFqsc/eNrlyVHMLfQN4qKzpY2OlypM/S+fSFxnrJ19orBigSLw2hGSeQIjbpR4vHTmAYvH/ASgVvfg6TQ4sLqLmgA5QYz3G2jsav3AxMdLCsgAAAABJRU5ErkJggg==">';
    const authorNames = note.authorNames ? note.authorNames.join(', ') : $localize`System`;
    const authorRoles = note.authorRoles ? note.authorRoles.join(', ') : '';
    const date = `${this.datePipe.transform(
      note.lastModifiedAt,
      'mediumDate',
    )} ${$localize`at`} ${this.datePipe.transform(note.lastModifiedAt, 'shortTime')}`;

    // eslint-disable-next-line max-len
    return `<div class="note-header no-page-break">${accountCircleIconImg} ${authorNames} ${
      authorRoles
        ? `<span class="bg-grey border-rounded note-author-role">${authorRoles}</span>`
        : ''
    } ${todayIconImg} ${date} ${
      note.status === ApplicationNoteStatus.DRAFT ? editDocumentIconImg : ''
    }</div>`;
  }

  generateAINote(applicationId: string, temperature?: number, maxTokens?: number) {
    return this.http.post(
      `${environment.fundmore_ai_api_url}/generated-notes/generate-ai-note`,
      {
        applicationId,
        temperature,
        maxTokens,
      },
      {
        responseType: 'text',
      },
    );
  }
}
