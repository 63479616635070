import { BrokerUserType, ContactType, LanguagePreference } from './applicant';
import { ApplicationSource } from './application';

export interface Broker {
  id: string;
  firstName: string;
  lastName: string;
  cellPhoneNumber: string | null;
  email: string;
  firmCode?: string;
  firmName: string | null;
  riskScore?: number | null;
  source: ApplicationSource;
  updatedAt: Date | string | null;
  createdAt: Date | string | null;
  tenantId: string;
  homePhoneNumber?: string;
  workPhoneNumber?: string;
  fax?: string;
  address?: string;
  languagePreference?: LanguagePreference;
  preferredContactMethod?: ContactType;
  userType?: BrokerUserType;
  licenseRegistrationNumber?: string;
  approved?: boolean;
  externalRef?: string;
  deletedAt?: any;
  verified?: boolean;
  verifiedBy?: string;
  externalId?: { [key in ApplicationSource]?: string | null };
}
