import { ChangeUserPasswordCommand, UpsertUserProfile } from '../features/settings/settings.models';
import { UserAccount } from '../shared';

export interface LoginStatus {
  success: boolean;
  message: string;
}

export interface AuthStateModel {
  currentUserAccount: UserAccount;
  loginStatus?: LoginStatus;
  loading: boolean;
}

export class SetCurrentUserAccount {
  static readonly type = '@auth.SetCurrentUserAccount';
  constructor(public currentUserAccount: UserAccount) {}
}

export class UpsertCurrentUserAccount {
  static readonly type = '@auth.UpsertCurrentUserAccount';
  constructor(public profile: UpsertUserProfile) {}
}

export class UpdateCurrentUserAccountProfilePicture {
  static readonly type = '@auth.UpdateCurrentUserAccountProfilePicture';
}

export class ChangePassword {
  static readonly type = '@auth.ChangePassword';
  constructor(public command: ChangeUserPasswordCommand) {}
}

export class GetCurrentUserParameters {
  static readonly type = '@auth.GetCurrentUserParameters';
}
