import { Injectable } from '@angular/core';
import { Action, NgxsAfterBootstrap, Selector, State, StateContext, Store } from '@ngxs/store';
import { UserSettingsLoaded } from 'src/app/shared/user-settings.state';

export class ChangeTheme {
  static readonly type = '@theme.ChangeTheme';
  constructor(public theme: FundmoreAITheme) {}
}

export class ChangeSystemTheme {
  static readonly type = '@theme.ChangeSystemTheme';
  constructor(public systemTheme: FundmoreAITheme) {}
}

interface ThemeStateModel {
  theme: FundmoreAITheme;
  systemTheme: FundmoreAITheme;
}

export enum FundmoreAITheme {
  Auto = 'auto',
  Light = 'light',
  Dark = 'dark',
}

@State<ThemeStateModel>({
  name: 'theme',
  defaults: { theme: FundmoreAITheme.Light, systemTheme: FundmoreAITheme.Light },
})
@Injectable()
export class ThemeState implements NgxsAfterBootstrap {
  constructor(private store: Store) {}

  ngxsAfterBootstrap(ctx?: StateContext<ThemeStateModel>): void {
    if (!window?.matchMedia) {
      return;
    }
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

    mediaQuery.addEventListener('change', (mqle: MediaQueryListEvent) => {
      this.store.dispatch(
        new ChangeSystemTheme(mqle.matches ? FundmoreAITheme.Dark : FundmoreAITheme.Light),
      );
    });
    const systemUsingDarkMode = mediaQuery.matches;
    if (systemUsingDarkMode && ctx?.getState().systemTheme === FundmoreAITheme.Dark) {
      return;
    }
    this.store.dispatch(
      new ChangeSystemTheme(systemUsingDarkMode ? FundmoreAITheme.Dark : FundmoreAITheme.Light),
    );
  }

  @Selector() static theme(state: ThemeStateModel) {
    const theme = state.theme === FundmoreAITheme.Auto ? state.systemTheme : state.theme;
    return theme ?? FundmoreAITheme.Light;
  }

  @Action(ChangeTheme) changeTheme(ctx: StateContext<ThemeStateModel>, action: ChangeTheme) {
    ctx.patchState({ theme: action.theme });
  }

  @Action(ChangeSystemTheme) changeSystemTheme(
    ctx: StateContext<ThemeStateModel>,
    action: ChangeSystemTheme,
  ) {
    ctx.patchState({ systemTheme: action.systemTheme });
  }

  @Action(UserSettingsLoaded) applyUserSettingsTheme(
    ctx: StateContext<ThemeStateModel>,
    action: UserSettingsLoaded,
  ) {
    if (!action.userSettings.theme) {
      return;
    }
    ctx.patchState({ theme: action.userSettings.theme });
  }
}
