/* eslint-disable max-classes-per-file */
import { ConfigurationState } from '../configs/configuration-state';
import { buildSecureIfFunctionWithContext } from '../helpers/generic';
import { Octopus } from '../interfaces/score.config';
import {
  FundmoreScoreContext,
  FundmoreScoreEnhancedInput,
  FundmoreScoreInput,
  LenderContext,
} from '../models/models';
import { OctopusProcessor, OctopusResult } from './octopus-processor';

export class ConfigurationProcessor {
  public static isActiveOctopusFilter = (context: FundmoreScoreContext) => (octopus: Octopus) => {
    if (!octopus.activateFn) {
      return true;
    } else {
      const activateFunction = buildSecureIfFunctionWithContext(octopus.activateFn);
      return activateFunction.call(context);
    }
  };

  public process = (input: FundmoreScoreInput): FundmoreScoreOutput => {
    const context = this.generateContext(input);
    const octopusResult = ConfigurationState.scoreConfig.octopuses
      .filter(ConfigurationProcessor.isActiveOctopusFilter(context))
      .map((octopus) => {
        const octopusProcessor = new OctopusProcessor();
        return octopusProcessor.process(context, octopus);
      });

    return new FundmoreScoreOutput(octopusResult);
  };

  private generateContext = (input: FundmoreScoreInput): FundmoreScoreContext => {
    const context = new FundmoreScoreContext();

    const enhancedInput = Object.assign(new FundmoreScoreEnhancedInput(), input);
    enhancedInput.enhance();
    context.application = enhancedInput;
    context.lenders = new LenderContext(ConfigurationState.lenderConfig.lenders);
    context.propertyLocation = enhancedInput.propertyAddressEnhanced.enhance();

    return context;
  };
}

export class FundmoreScoreOutput {
  results: OctopusResult[];
  constructor(results: OctopusResult[]) {
    this.results = results;
  }
}
