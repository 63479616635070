import { FCTAddressErrorCode, FCTErrorCode } from '../enums/errorCode';

export interface FCTError {
  ErrorCode: FCTErrorCode | FCTAddressErrorCode;
  DataElement: string;
  EnglishError: string;
  FrenchError: string;
  RollNumber: string;
  FCTAddressId: string;
}
