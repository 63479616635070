import { LanguageCode } from '@fundmoreai/models';

export interface Language {
  code: LanguageCode;
  label: string;
  shortLabel: string;
}

export const LANGUAGES: Language[] = [
  { code: LanguageCode['en-CA'], label: 'English', shortLabel: 'EN' },
  { code: LanguageCode['fr-CA'], label: 'Français', shortLabel: 'FR' },
];

export function getAppLanguage(): Language {
  const siteLocale = window.location.pathname.split('/')[1];

  return LANGUAGES.find((f) => f.code === siteLocale) ?? LANGUAGES[0];
}
