export enum OptaResidentialBathroomCountType {
  ONE = '1',
  TWO = '2',
  THREE = '3',
  FOUR = '4',
  ONE_AND_A_HALF = '1.5',
  TWO_AND_A_HALF = '2.5',
  THREE_AND_A_HALF = '3.5',
  FOUR_AND_A_HALF = '4.5',
  MORE = 'More',
}
