export function computeBlendedMortgageRate(
  firstMortgageBalance: number | null | undefined,
  firstMortgageRate: number | null | undefined,
  secondMortgageBalance: number | null | undefined,
  secondMortgageRate: number | null | undefined,
): number | undefined {
  let blendedMortgageRate;

  if (firstMortgageBalance && firstMortgageRate && secondMortgageBalance && secondMortgageRate) {
    blendedMortgageRate =
      (firstMortgageBalance * firstMortgageRate + secondMortgageBalance * secondMortgageRate) /
      (firstMortgageBalance + secondMortgageBalance);
  }
  return blendedMortgageRate;
}
