import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PropertyAddressDetails } from '@fundmoreai/models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  private base = `${environment.integrations_api_url}/google`;

  constructor(private http: HttpClient) {}

  getPropertyAddressExpanded(propertyAddress: string): Observable<PropertyAddressDetails> {
    return this.http.get<PropertyAddressDetails>(
      `${this.base}/expandedAddress?address=${propertyAddress}`,
    );
  }
}
