export enum FCTAddOnProduct {
  MARKET_RENT_ESTIMATE_DESKTOP = '006',
  AS_IS_AS_COMPLETE_DB = '014',
  MARKET_RENT_ESTIMATE_DRIVE_BY = '015',
  APPRAISAL_UPDATE_DRIVE_BY = '016',
  LETTER_OF_DIRECTION_POST_APPRAISAL_DRIVE_BY = '017',
  PROGRESS_INSPECTION_FULL_APPRAISAL = '025',
  AS_IS_AS_COMPLETE = '026',
  MARKET_RENT_ESTIMATE = '027',
  SCHEDULE_A_INCOME_APPROACH_FULL_APPRAISAL = '028',
  RUSH_REQUEST_SAME_DAY_SERVICE_FULL_APPRAISAL = '029',
  APPRAISAL_UPDATE_FULL_APPRAISAL = '030',
  LETTER_OF_DIRECTION_POST_APPRAISAL_FA = '031',
  RUSH_REQUEST = '044',
}
