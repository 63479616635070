export interface CreditTier {
  id: string;
  tenantId?: string;
  tierName: string;
  maximumCreditScore?: number | null;
  minimumCreditScore?: number | null;
  isCustom: boolean;
}

export enum CreditTierInUseError {
  RATE_MATRIX = 'CREDIT_TIER_IN_USE_RATE_MATRIX',
  DEFAULT_FEES = 'CREDIT_TIER_IN_USE_DEFAULT_FEES',
}
