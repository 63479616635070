import { MMSGuarantorType } from '../enums';
import { MMSAddress } from './address';
import { MMSIndividualName } from './contact';

export interface MMSGuarantor {
  guarantorType: MMSGuarantorType;
  companyName: string;
  guarantorName: MMSIndividualName;
  guarantorAddress: MMSAddress;
  signatoryName: MMSIndividualName;
  consentingSpouse?: boolean;
}
