export interface ReportConfig {
  name: string;
  pbiReportId: string;
  pbiGroupId: string;
  pbiApplicationId: string;
  pbiServiceTenantId: string;
  pbiClientId: string;
  pbiClientSecret: string;
}

export interface Report {
  id: string;
  name: string;
  pages: ReportPage[];
}

export interface ReportPage {
  name: string;
  displayName: string;
  order: number;
}
