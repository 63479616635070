import { ClosestUncompletedTask, TaskUrgency } from '@fundmoreai/models';
import {
  differenceInMilliseconds,
  differenceInMinutes,
  formatDuration,
  intervalToDuration,
} from 'date-fns';

const formatDateToTodayDuration = (date: Date | null): string => {
  if (!date) {
    return '';
  }

  const minutes = differenceInMilliseconds(date, new Date());

  const negativeTime = Math.max(0, minutes);
  const duration = intervalToDuration({
    start: 0,
    end: minutes,
  });

  const formattedDuration = formatDuration(duration, { format: ['days', 'hours', 'minutes'] });

  const r1 = formattedDuration.replace(/ days?/, 'd');
  const r2 = r1.replace(/ hours?/, 'h');
  const r3 = r2.replace(/ minutes?/, 'm');

  return negativeTime ? r3 : `- ${r3}`;
};

export const computeClosestUncompletedTaskDueDate = (
  dueDate: string | null,
): ClosestUncompletedTask | null => {
  if (!dueDate) {
    return null;
  }

  const dueDateTask = new Date(dueDate);

  const minutes = differenceInMinutes(dueDateTask, new Date());

  const urgency =
    minutes < 30 && minutes > 0
      ? TaskUrgency.URGENT
      : minutes < 0
      ? TaskUrgency.OVERDUE
      : TaskUrgency.MINOR;

  const time = formatDateToTodayDuration(dueDateTask);

  return { time, urgency };
};
