<div class="auth-form">
  <div class="card">
    <div class="card-body mb-20">
      <ng-template authHost></ng-template>
      <div *ngIf="error !== undefined && error !== null">
        {{ errorMessage }}
      </div>
    </div>
    <img class="auth-logo" width="300" alt="logo" src="../assets/logo_dark.png" />
  </div>
</div>
