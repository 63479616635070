import { ZoningTypes } from '../../property';

export enum FCTPropertyUse {
  RESIDENTIAL = '001',
  COMMERCIAL = '002',
  RECREATIONAL = '003',
  AGRICULTURAL = '004',
  VACANT_LAND = '005',
}

export const fctZoningTypeToPropertyUseMap: Record<ZoningTypes, FCTPropertyUse | undefined> = {
  [ZoningTypes.RESIDENTIAL]: FCTPropertyUse.RESIDENTIAL,
  [ZoningTypes.COMMERCIAL]: FCTPropertyUse.COMMERCIAL,
  [ZoningTypes.RESCOMMERCIAL]: undefined,
  [ZoningTypes.LAND]: FCTPropertyUse.AGRICULTURAL,
  [ZoningTypes.INDUSTRIAL]: FCTPropertyUse.COMMERCIAL,
};
