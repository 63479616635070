import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Inject,
  ChangeDetectorRef,
  Optional,
} from '@angular/core';
import { MatSnackBarRef, MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { interval } from 'rxjs';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

export interface MessageData {
  title?: string;
  message: string;
  duration: number;
  actionText?: string;
}
@UntilDestroy()
@Component({
  template: `
    <h2 *ngIf="data?.title">{{ data?.title }}</h2>
    <span [innerHTML]="data?.message"></span>
    &nbsp;
    <button mat-stroked-button color="primary" (click)="snackBarRef.dismissWithAction()">
      {{ data?.actionText ? data?.actionText : 'Ok' }}
    </button>
    <mat-progress-bar
      color="primary"
      mode="determinate"
      [value]="(progress / total) * 100"></mat-progress-bar>
  `,
  styles: [
    `
      :host {
        align-items: center;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
      span {
        flex: 1;
      }
      h2 {
        flex: 0 0 100%;
        margin-bottom: 0;
      }
      .mat-mdc-progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatButtonModule, MatProgressBarModule],
})
export class MessageInfoComponent implements OnInit {
  progress = 0;
  total = 0;
  constructor(
    private cdr: ChangeDetectorRef,
    @Optional() public snackBarRef: MatSnackBarRef<MessageInfoComponent>,
    @Optional() @Inject(MAT_SNACK_BAR_DATA) public data?: MessageData,
  ) {}

  ngOnInit(): void {
    this.total = (this.data?.duration || 0) / 100;
    interval(100)
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.progress++;
        this.cdr.markForCheck();
      });
  }
}
