import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { catchError, finalize, tap } from 'rxjs/operators';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import { ApplicationResetState } from '../shared/state.model';
import { EqCoreService } from './eq-core.service';
import {
  Mortgage,
  Applicant,
  AddressExpandedType,
  CustomerType,
  ApplicationSource,
  EqLoanUpdateType,
} from '@fundmoreai/models';
import { ApplicantsState } from './applicants.state';
import { MortgagesV2State } from './mortgages-v2/mortgages-v2.state';
import hash from 'object-hash';
import { of } from 'rxjs';
import { RefreshApplication } from './mortgage-application.actions';
import { AppFeaturesState } from '../shared/app-features.state';

export interface EqLoanUpdatePayload {
  applicationId: string;
  type: EqLoanUpdateType;
}
export class EqLoanUpdate {
  static readonly type = '@EqCoreState.eqLoanUpdate';
  constructor(public payload: EqLoanUpdatePayload) {}
}

export class FirstRegularPaymentDateManuallyChanged {
  static readonly type = '@EqCoreState.firstRegularPaymentDateManuallyChanged';
  constructor(public applicationId: string) {}
}

export class FetchEqMessages {
  static readonly type = '@EqCoreState.fetchEqMessages';

  constructor(public applicationId: string) {}
}

export class EqClearLoanUpdateErrors {
  static readonly type = '@EqCoreState.eqClearLoanUpdateErrors';
}

export class EqClearLoanDecisionErrors {
  static readonly type = '@EqCoreState.clearLoanDecisionErrors';
}

export class EqLoanDecisionRetry {
  static readonly type = '@EqCoreState.eqLoanDecisionRetry';
  constructor(public applicationId?: string) {}
}

export interface EqCoreStateModel {
  errors: string[];
  warnings: string[];
  loanDecisionErrors: string[];
}

const defaults = {
  errors: [],
  warnings: [],
  loanDecisionErrors: [],
};

@State<EqCoreStateModel>({
  name: 'EqCoreState',
  defaults: { ...defaults },
})
@Injectable()
export class EqCoreState {
  constructor(private eqCoreService: EqCoreService, private store: Store) {}

  @Selector()
  static getLoanUpdateErrors(state: EqCoreStateModel): string[] {
    return state.errors;
  }

  @Selector()
  static getLoanUpdateWarnings(state: EqCoreStateModel): string[] {
    return state.warnings;
  }

  @Selector()
  static getLoanDecisionErrors(state: EqCoreStateModel): string[] {
    return state.loanDecisionErrors;
  }

  @Selector([ApplicantsState.applicantsList])
  static applicantsListEqCustomerType(
    applicants: Applicant[] | undefined,
  ): Applicant[] | undefined {
    return applicants?.filter((applicant) => EqCoreState.isEqCustomerType(applicant.customerType));
  }

  static isEqCustomerType(customerType: CustomerType): boolean {
    switch (customerType) {
      case CustomerType.CUSTOMER:
      case CustomerType.COMPANY:
      case CustomerType.CO_BORROWER:
      case CustomerType.GUARANTOR:
      case CustomerType.SUBMITTING_AGENT:
      case CustomerType.BROKER:
      case CustomerType.BROKERAGE:
        return true;
      default:
        return false;
    }
  }

  static isEqApplicationSource(source: ApplicationSource | undefined): boolean {
    if (!source) {
      return false;
    }
    switch (source) {
      case ApplicationSource.EQ_MAPS_FILOGIX:
      case ApplicationSource.EQ_MAPS_LENDESK:
      case ApplicationSource.EQ_MAPS_VELOCITY:
      case ApplicationSource.EQ_MAPS_MBOSS:
        return true;
      default:
        return false;
    }
  }

  static computeEqSyncPaymentTriggers(requestedMortgageId: string) {
    return createSelector(
      [MortgagesV2State.requestedMortgage(requestedMortgageId)],
      (requestedMortgage: Mortgage | undefined) => {
        return JSON.stringify({
          compounding: requestedMortgage?.compounding,
          amortizationMonths: requestedMortgage?.amortizationMonths,
          repaymentType: requestedMortgage?.repaymentType,
          paymentFrequency: requestedMortgage?.paymentFrequency,
          prepaymentType: requestedMortgage?.prepaymentType,
          netRate: requestedMortgage?.netRate,
          termMonths: requestedMortgage?.termMonths,
        });
      },
    );
  }

  static computeEqSyncDateTriggers(requestedMortgageId: string) {
    return createSelector(
      [MortgagesV2State.requestedMortgage(requestedMortgageId)],
      (requestedMortgage: Mortgage | undefined) => {
        return JSON.stringify({
          closingDate: requestedMortgage?.closingDate,
        });
      },
    );
  }

  static computeEqSyncApplicantTriggers() {
    return createSelector(
      [EqCoreState.applicantsListEqCustomerType],
      (applicants: Applicant[] | undefined) => {
        const mappedApplicants = applicants?.map((applicant) =>
          JSON.stringify({
            surname: applicant.surname,
            middleName: applicant.middleName,
            name: applicant.name,
            address: applicant.ApplicantAddressesExpanded.find(
              (x) => x.type === AddressExpandedType.CURRENT,
            )?.address,
            dateOfBirth: applicant.dateOfBirth,
          }),
        );
        const options = { unorderedArrays: true, unorderedObjects: true };

        return hash(mappedApplicants ?? {}, options);
      },
    );
  }

  @Action(FirstRegularPaymentDateManuallyChanged)
  firstRegularPaymentDateManuallyChanged(
    ctx: StateContext<EqCoreStateModel>,
    action: FirstRegularPaymentDateManuallyChanged,
  ) {
    if (this.store.selectSnapshot(AppFeaturesState.eqMapsEnabled)) {
      return ctx.dispatch(
        new EqLoanUpdate({
          applicationId: action.applicationId,
          type: EqLoanUpdateType.LOAN_DETAILS_SYNC,
        }),
      );
    }

    return of(null);
  }

  @Action(ApplicationResetState)
  reset(ctx: StateContext<EqCoreStateModel>) {
    ctx.setState({ ...defaults });
  }

  @Action(EqClearLoanUpdateErrors)
  clearEqErrors(ctx: StateContext<EqCoreStateModel>) {
    ctx.patchState({ errors: [] });
  }

  @Action(EqClearLoanDecisionErrors)
  clearEqLoanDecisionErrors(ctx: StateContext<EqCoreStateModel>) {
    ctx.patchState({ loanDecisionErrors: [] });
  }

  @Action(EqLoanUpdate)
  eqLoanUpdate(ctx: StateContext<EqCoreStateModel>, action: EqLoanUpdate) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.eqCoreService.loanUpdate(action.payload).pipe(
      tap(() => {
        if (!action.payload.applicationId) {
          return;
        }
        if (action.payload.type !== EqLoanUpdateType.FULL_ASYNC) {
          this.store.dispatch(new RefreshApplication(action.payload.applicationId));
        }
      }),
      catchError((error: unknown) => {
        if (!action.payload.applicationId) {
          return of();
        }
        if (action.payload.type !== EqLoanUpdateType.FULL_ASYNC) {
          this.store.dispatch(new RefreshApplication(action.payload.applicationId));
        }
        return of(error); // eat the error so we don't display an error message
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(EqLoanDecisionRetry)
  eqLoanDecisionRetry(ctx: StateContext<EqCoreStateModel>, action: EqLoanDecisionRetry) {
    return this.eqCoreService.loanDecisionRetry({ applicationId: action.applicationId }).pipe();
  }

  @Action(FetchEqMessages)
  fetchEqErrors(ctx: StateContext<EqCoreStateModel>, action: FetchEqMessages) {
    ctx.patchState({
      errors: [],
    });

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.eqCoreService.getErrors(action.applicationId).pipe(
      tap((eqErrors) => {
        if (!eqErrors) {
          return;
        }
        const systemNotes = eqErrors.systemNotes?.map((warning) => warning.description) ?? [];
        if (eqErrors.isLoanUpdateSuccessful) {
          ctx.patchState({
            errors: [],
            warnings: systemNotes,
          });
        } else {
          ctx.patchState({
            errors: systemNotes,
            warnings: [],
          });
        }

        if (eqErrors.isLoanDecisionSuccessful) {
          ctx.patchState({
            loanDecisionErrors: [],
          });
        } else {
          ctx.patchState({
            loanDecisionErrors:
              eqErrors.loanDecisionErrors?.map((error) => error.description) ?? [],
          });
        }
      }),
      catchError((error) => {
        console.error('Error fetching EQ Errors:', error);
        return [];
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }
}
