import { Applicant } from './applicant';
import { FinancialAsset } from './asset';
import { FinancialLiability } from './liability';
import { Mortgage } from './mortgage';
import { ApplicationOtherIncome } from './otherIncome';
import { Property } from './property';
import { CreditReportReference } from './creditReport';
import { MortgageType } from './enums';
import { ApplicationSource } from './application';
import { Fee } from './fee';
import { DownPayment } from './downPayment';
import { EQClosingService, LendingTierType } from './eqMaps';

export interface ApplicantData extends Applicant {
  docsDueDate: string;
  ApplicantOtherIncomes?: ApplicationOtherIncome[];
  ApplicantFinancialLiabilities?: FinancialLiability[];
  ApplicantProperties?: ApplicantProperty[];
  ApplicantFinancialAssets?: FinancialAsset[];
  ApplicantCreditReports?: CreditReportReference[];
}

export interface BrokerApplicantData extends ApplicantData {
  firmCode: string;
  preferredContactMethod: string;
  userType: string;
  licenseRegistrationNumber: string;
  profileStatus: string;
  externalId: { [key in ApplicationSource]?: string };
}

export interface DownPaymentData extends DownPayment {
  tableData: unknown;
}

export interface FinancialAssetData extends FinancialAsset {
  tableData: unknown;
}

export interface FinancialLiabilityData extends FinancialLiability {
  tableData: unknown;
}

export interface OtherIncomeData extends ApplicationOtherIncome {
  tableData: unknown;
}

export interface ApplicantProperty {
  propertyId: string;
  mappedProperty: Property;
}

export interface CreateApplicationData {
  name: string;
  broker: string;
  brokerNotes: string;
  brokerNotesDate: string;
  applicationNotes: ApplicationNoteCreate[];
  code: string;
  applicationDate: string;
  status: string;
  currentStage: string;
  purpose: string;
  mortgageClassification: string;
  filogixDealId: string;
  externalDealId: string;
  source: string;
  originalData: string;
  docsDueDate: string;
  fundId: string;
  dealType: string;
  closingService: EQClosingService;
  lendingTier: LendingTierType;
  clonedApplicationId: string;
  metadata: string;
  lineOfBusinessId: string;
  lineOfBusiness: string;
  advancedProductId: string;
  externalApplicationId: string;
  externalApplicationGUID: string;
  isCombo: boolean;
  commitmentIssueDate: string;
  commitmentExpiryDate: string;
  quickCloseFlag: boolean;
  rateHoldExpiryDate: string;
  sourceOfBusiness: string;
  purposeCode: string;
  applicationLoanNumber?: string;

  financialAssets: Partial<FinancialAssetData>[];
  otherIncomes: Partial<OtherIncomeData>[];
  liabilities: Partial<FinancialLiabilityData>[];
  requestedMortgage: Partial<Mortgage>;
  helocRequestedMortgage: Partial<Mortgage>;
  existingMortgage: Partial<Mortgage>[];
  refinanceMortgage: Partial<Mortgage>;
  properties: Partial<Property>[];
  downPayments: Partial<DownPaymentData>[];
  primaryApplicant: Partial<ApplicantData>;
  otherApplicants: Partial<ApplicantData>[];
  brokerApplicants: Partial<BrokerApplicantData>[];
  contactsEmails: {
    email: string;
    externalEmailSequenceNumber: number;
  }[];
  lenderSubmission?: LenderSubmissionCreate;
  Fees?: Partial<Fee>[];
  mortgageCalculationAutomationSettings?: MortgageCalculationAutomationSettings;
  isPrimaryResidenceEnabled?: boolean;
}

export type MortgageCalculationAutomationSettings =
  | Record<string, CalculationAutomationSettings>
  | null
  | undefined;

export interface CalculationAutomationSettings {
  isPaymentAmountDisabled: boolean | null | undefined;
  isMonthlyPaymentDisabled: boolean | null | undefined;
  isInterestAdjustmentDateDisabled: boolean | null | undefined;
  isInterestAdjustmentAmountDisabled: boolean | null | undefined;
  isMaturityDateDisabled: boolean | null | undefined;
  excludeExtraPaymentInTerm: boolean | null | undefined;
  setTDSToZero: boolean | null | undefined;
  setGDSToZero: boolean | null | undefined;
}

export interface LenderSubmissionCreate {
  routingSender: string;
  routingReceiver: string;
  language: string;
  submitRequestTime: string;
  lenderProfile: {
    linkId: string;
    registrationName: string;
    lenderCode: string;
    lenderName: string;
    useLegacyUserId: string;
  };
}

export interface MortgageCreate {
  id: string;
  applicationId: string;
  propertyId: string | null;
  lender?: string;
  productName?: string;
  loanType?: string;
  purpose?: string;
  closingDate?: Date | null;
  fundingDate?: Date | null;
  mortgageType: string;
  paymentFrequency?: string;
  purchaseValue: number | null;
  insuranceAmount: number | null;
  insurancePremium: number | null;
  loanAmount: number | null;
  monthlyPayment: number | null;
  netRate: number | null;
  originalNetRate: number | null;
  termMonths?: number | null;
  amortizationMonths?: number | null;
  repaymentType?: string;
  type: MortgageType;
  rateType?: string;
  termType?: string;
  maturityDate?: string;
  mortgageBalance: number | null;
  compounding: string | null;
  externalLoanNumber?: string | null;
  loanNumber?: string;
  deadlineToAcceptDate?: string;
  interestAdjustmentDate?: Date | null;
  interestAdjustmentAmount: number | null;
  borrowingLimit?: number | null;
  firstRegularPaymentDate?: string | null;
  insurer?: string | null;
  insuranceAccountNum?: string | null;
  mortgageNum: number | null;
  includePremiumInMortgage: boolean | null;
}

export interface ApplicationNoteCreate {
  authorNames: string[];
  authorRoles: string[];
  editableById: string;
  status: string;
  content: string;
  lastModifiedAt: Date | string;
}
