import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { combineLatest, of } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { ApplicantsState } from '../../../../portal/applicants.state';
import { FinancialLiabilitiesService } from './financial-liabilities.service';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import {
  Applicant,
  ApplicantsFilter,
  FinancialLiability,
  FinancialLiabilityKey,
  FinancialLiabilityRecordType,
  FinancialLiabilityRecordTypeState,
  FinancialLiabilitySource,
  LiabilityOverride,
  MCUIntegrationFields,
} from '@fundmoreai/models';
import { groupedFinancialLiabilities } from '../../../shared/credit-report/liability.helper';
import { ApplicantNamePipe } from '../../../../shared/applicant-name.pipe';
import { UpdateApplicantCredit } from '../../../../portal/applicants.actions';
import { ApplicantsService } from '../../../../portal/applicants.service';
import {
  AddFinancialLiability,
  CreateFinancialLiabilityRecordType,
  DeleteFinancialLiability,
  PatchLiabilities,
  SetCreditFinancialLiabilities,
  SetFilterIndex,
  FetchLiabilityOverrides,
  UpdateApplicantCreditInfo,
  UpdateFinancialLiability,
  UpdateFinancialLiabilityAssignedApplicants,
  UpdateFinancialLiabilityRecordType,
  FetchFinancialLiabilities,
} from './financial-liability.actions';
import { ApplicationResetState } from '../../../../shared/state.model';
import { LoadingStart, LoadingEnd } from '../../../../core/loading.state';
import { MortgageApplicationState } from 'src/app/portal/mortgage-application.state';
// eslint-disable-next-line max-len
import { ApplicantPatchAndProductReapply } from 'src/app/portal/preview-apply-product-changes/preview-apply-product-changes.actions';
import { Column } from 'src/app/features/manager-portal/condition-manage/model';
import { FinancialLiabilityKeyRecord } from 'src/app/shared/enum-records-metadata';
import { AppFeaturesState, AppFeaturesStateModel } from '../../../../shared/app-features.state';

const ACTIVE_LIABILITIES_SELECTABLE_COLUMNS: (FinancialLiabilityKey | string)[] = [
  FinancialLiabilityKey.PAYOFF_PAYDOWN,
  FinancialLiabilityKey.EXCLUDE_FROM_CALCULATION,
  FinancialLiabilityKey.PAYOFF,
  FinancialLiabilityKey.DESCRIPTION,
  FinancialLiabilityKey.FINANCIAL_INSTITUTION,
  FinancialLiabilityKey.LIABILITY,
  FinancialLiabilityKey.SOURCE,
  FinancialLiabilityKey.CURRENT_TYPE,
  FinancialLiabilityKey.VALUE,
  FinancialLiabilityKey.BALANCE,
  FinancialLiabilityKey.MONTHLY_PAYMENT,
  FinancialLiabilityKey.PAYMENT_FREQUENCY,
  'ApplicantFinancialLiabilities',
];

const WRITTEN_OFF_LIABILITIES_SELECTABLE_COLUMNS: (FinancialLiabilityKey | string)[] = [
  FinancialLiabilityKey.PAYOFF_PAYDOWN,
  FinancialLiabilityKey.EXCLUDE_FROM_CALCULATION,
  FinancialLiabilityKey.PAYOFF,
  FinancialLiabilityKey.DESCRIPTION,
  FinancialLiabilityKey.FINANCIAL_INSTITUTION,
  FinancialLiabilityKey.LIABILITY,
  FinancialLiabilityKey.CURRENT_TYPE,
  FinancialLiabilityKey.VALUE,
  FinancialLiabilityKey.BALANCE,
  FinancialLiabilityKey.MONTHLY_PAYMENT,
  FinancialLiabilityKey.PAYMENT_FREQUENCY,
  'ApplicantFinancialLiabilities',
];

export const ACTIVE_LIABILITIES_DEFAULT_DISPLAY_COLUMNS: Column[] =
  ACTIVE_LIABILITIES_SELECTABLE_COLUMNS.map((value: FinancialLiabilityKey | string) => {
    const name =
      value === 'ApplicantFinancialLiabilities'
        ? FinancialLiabilityKeyRecord[FinancialLiabilityKey.APPLICANT_ID]
        : FinancialLiabilityKeyRecord[<FinancialLiabilityKey>value];

    return {
      field: value,
      name,
      isSelected: true,
      isFrozen: false,
    };
  });

export const WRITTEN_OFF_LIABILITIES_DEFAULT_DISPLAY_COLUMNS: Column[] =
  WRITTEN_OFF_LIABILITIES_SELECTABLE_COLUMNS.map((value: FinancialLiabilityKey | string) => {
    const name =
      value === 'ApplicantFinancialLiabilities'
        ? FinancialLiabilityKeyRecord[FinancialLiabilityKey.APPLICANT_ID]
        : FinancialLiabilityKeyRecord[<FinancialLiabilityKey>value];

    return {
      field: value,
      name,
      isSelected: true,
      isFrozen: false,
    };
  });

export interface FinancialLiabilityStateModel {
  financialLiabilities?: {
    [key: string]: FinancialLiability;
  };
  liabilityOverrides?: LiabilityOverride[];
  filterIndex: number;
}

export interface GroupedLiabilities {
  active: FinancialLiability[];
  writtenOff: FinancialLiability[];
  inactive: FinancialLiability[];
}

@State<FinancialLiabilityStateModel>({
  name: 'financialLiabilityState',
  defaults: { financialLiabilities: undefined, liabilityOverrides: undefined, filterIndex: 0 },
})
@Injectable()
export class FinancialLiabilityState {
  static editableSources = [
    FinancialLiabilitySource.MANUAL,
    FinancialLiabilitySource.CREDIT_BUREAU,
  ];

  @Selector()
  private static financialLiabilities(
    state: FinancialLiabilityStateModel,
  ): FinancialLiability[] | undefined {
    return state.financialLiabilities ? Object.values(state.financialLiabilities) : undefined;
  }

  @Selector()
  static liabilityOverrides(state: FinancialLiabilityStateModel): LiabilityOverride[] | undefined {
    return state.liabilityOverrides;
  }

  @Selector([AppFeaturesState])
  static liabilitiesOptionalDisplayColumns(appFeaturesState: AppFeaturesStateModel): Column[] {
    const optionalFields = appFeaturesState?.features?.optionalFields ?? [];

    const securityFieldsEnabled = optionalFields?.includes(MCUIntegrationFields.SECURITY);

    const optionalColumns: Column[] = [];
    if (securityFieldsEnabled) {
      optionalColumns.push({
        field: FinancialLiabilityKey.SECURITY_TYPE,
        name: FinancialLiabilityKeyRecord[FinancialLiabilityKey.SECURITY_TYPE],
        isSelected: true,
        isFrozen: false,
        isOptional: true,
      });
    }

    return optionalColumns;
  }

  @Selector([FinancialLiabilityState.financialLiabilities])
  static financialLiabilitiesList(
    financialLiabilities: FinancialLiability[] | undefined,
  ): FinancialLiability[] | undefined {
    return financialLiabilities;
  }

  @Selector([
    FinancialLiabilityState.financialLiabilities,
    FinancialLiabilityState.liabilityOverrides,
    ApplicantsState.applicantsList,
  ])
  static debt(
    financialLiabilities: FinancialLiability[] | undefined,
    liabilityOverrides: LiabilityOverride[] | undefined,
    applicants: Applicant[] | undefined,
  ) {
    return FundmoreCalculator.computeDebt(financialLiabilities || [], applicants || []);
  }

  static debtBalance(applicantId?: string) {
    return createSelector(
      [FinancialLiabilityState.financialLiabilities, ApplicantsState.applicantsList],
      (
        financialLiabilities: FinancialLiability[] | undefined,
        applicants: Applicant[] | undefined,
      ) => {
        return FundmoreCalculator.computeDebtBalance(
          financialLiabilities || [],
          applicants || [],
          applicantId,
        );
      },
    );
  }

  static debtBalanceRegardlessOfPayoff(applicantId?: string) {
    return createSelector(
      [FinancialLiabilityState.financialLiabilities, ApplicantsState.applicantsList],
      (
        financialLiabilities: FinancialLiability[] | undefined,
        applicants: Applicant[] | undefined,
      ) => {
        return FundmoreCalculator.computeDebtBalanceRegardlessOfPayoff(
          financialLiabilities || [],
          applicants || [],
          applicantId,
        );
      },
    );
  }

  @Selector([
    FinancialLiabilityState.financialLiabilities,
    FinancialLiabilityState.selectedApplicant,
  ])
  static filteredFinancialLiabilities(
    financialLiabilities: FinancialLiability[] | undefined,
    applicant: Applicant | undefined,
  ): GroupedLiabilities {
    return groupedFinancialLiabilities(financialLiabilities || [], applicant?.id);
  }

  @Selector([ApplicantsState.applicantsListExcludingThirdParty])
  static filterOptions(applicants: Applicant[] | undefined) {
    const applicantName = new ApplicantNamePipe();
    const filters: ApplicantsFilter[] = [
      {
        name: $localize`Overall`,
        applicantId: null,
      },
    ];

    applicants?.forEach((applicant) => {
      filters.push({
        name: applicantName.transform(applicant),
        applicantId: applicant.id,
      });
    });

    return filters;
  }

  @Selector([FinancialLiabilityState.filterOptions])
  static filterOptionNames(filterOptions: ApplicantsFilter[]) {
    return filterOptions.map((x) => x.name);
  }

  @Selector([FinancialLiabilityState.financialLiabilities, ApplicantsState.applicantsList])
  static payoutDebt(
    financialLiabilities: FinancialLiability[] | undefined,
    applicants: Applicant[] | undefined,
  ): number {
    return FundmoreCalculator.computeDebtFromProceeds(financialLiabilities || [], applicants || []);
  }

  @Selector([
    FinancialLiabilityState,
    FinancialLiabilityState.filterOptions,
    ApplicantsState.applicantsListExcludingThirdParty,
  ])
  static selectedApplicant(
    state: FinancialLiabilityStateModel,
    creditFilterOptions: ApplicantsFilter[],
    applicants: Applicant[] | undefined,
  ): Applicant | undefined {
    const filter = creditFilterOptions[state.filterIndex];
    if (!filter || !filter.applicantId) {
      return undefined;
    }
    const applicant = applicants?.find((applicant) => applicant.id === filter.applicantId);
    return applicant;
  }

  @Selector([FinancialLiabilityState.financialLiabilitiesList])
  static applicationValuesForProductReapplyTriggers(
    financialLiabilities: FinancialLiability[] | undefined,
  ) {
    return financialLiabilities?.map((financialLiability) => ({
      balance: financialLiability.balance,
      description: financialLiability.description,
      payoffPaydown: financialLiability.payoffPaydown,
      monthlyPayment: financialLiability.monthlyPayment,
      liability: financialLiability.liability,
      excludeFromCalculation: financialLiability.excludeFromCalculation,
    }));
  }

  constructor(
    private applicantsService: ApplicantsService,
    private financialLiabilitiesServices: FinancialLiabilitiesService,
    private store: Store,
  ) {}

  @Action(AddFinancialLiability)
  addFinancialLiability(
    ctx: StateContext<FinancialLiabilityStateModel>,
    action: AddFinancialLiability,
  ) {
    if (!action.financialLiability.applicationId) {
      return;
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));

    action.financialLiability.FinancialLiabilityRecordTypes = [
      {
        recordType: action.recordType,
        type: FinancialLiabilityRecordTypeState.CURRENT,
      } as FinancialLiabilityRecordType,
    ];

    return this.financialLiabilitiesServices
      .addFinancialLiabilities(action.financialLiability.applicationId, [action.financialLiability])
      .pipe(
        map(([financialLiability]) => financialLiability),
        tap((financialLiability) => {
          const state = ctx.getState();
          ctx.patchState({
            financialLiabilities: {
              ...state.financialLiabilities,
              [financialLiability.id]: financialLiability,
            },
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(ApplicationResetState)
  reset(ctx: StateContext<FinancialLiabilityStateModel>) {
    ctx.patchState({
      financialLiabilities: undefined,
      filterIndex: 0,
    });
  }

  @Action(DeleteFinancialLiability)
  deleteFinancialLiability(
    ctx: StateContext<FinancialLiabilityStateModel>,
    action: DeleteFinancialLiability,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.financialLiabilitiesServices
      .deleteFinancialLiability(action.applicationId, action.financialLiabilityId)
      .pipe(
        tap(() => {
          const state = ctx.getState();
          const financialLiabilities = { ...state.financialLiabilities };

          delete financialLiabilities[action.financialLiabilityId];

          ctx.patchState({
            financialLiabilities,
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(PatchLiabilities)
  patchLiabilities(ctx: StateContext<FinancialLiabilityStateModel>, action: PatchLiabilities) {
    const state = ctx.getState();
    const financialLiabilities = action.financialLiabilities.reduce(
      (entities: { [key: string]: FinancialLiability }, financialLiability) => {
        entities[financialLiability.id] = financialLiability;

        return entities;
      },
      { ...state.financialLiabilities },
    );

    ctx.patchState({
      financialLiabilities: financialLiabilities,
    });
  }

  @Action(SetCreditFinancialLiabilities)
  setCreditFinancialLiabilities(
    ctx: StateContext<FinancialLiabilityStateModel>,
    action: SetCreditFinancialLiabilities,
  ) {
    const financialLiabilitiesEntities = action.financialLiabilities.reduce(
      (entities: { [key: string]: FinancialLiability }, financialLiability) => {
        entities[financialLiability.id] = financialLiability;

        return entities;
      },
      {},
    );

    ctx.patchState({ financialLiabilities: financialLiabilitiesEntities });
  }

  @Action(FetchFinancialLiabilities)
  fetchFinancialLiabilities(
    ctx: StateContext<FinancialLiabilityStateModel>,
    action: FetchFinancialLiabilities,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.financialLiabilitiesServices.getFinancialLiabilities(action.applicationId).pipe(
      tap((financialLiabilities) => {
        this.setCreditFinancialLiabilities(
          ctx,
          new SetCreditFinancialLiabilities(financialLiabilities),
        );
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(FetchLiabilityOverrides)
  fetchLiabilityOverrides(ctx: StateContext<FinancialLiabilityStateModel>) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.financialLiabilitiesServices.fetchLiabilityOverrides().pipe(
      tap((liabilityOverrides) => {
        ctx.patchState({ liabilityOverrides: liabilityOverrides });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(SetFilterIndex)
  setFilterIndex(ctx: StateContext<FinancialLiabilityStateModel>, action: SetFilterIndex) {
    ctx.patchState({
      filterIndex: action.filterIndex,
    });
  }

  @Action(UpdateApplicantCreditInfo)
  updateApplicantCreditInfo(
    ctx: StateContext<FinancialLiabilityStateModel>,
    action: UpdateApplicantCreditInfo,
  ) {
    const application = this.store.selectSnapshot(MortgageApplicationState.application);
    const currentApplicant = application.applicants.find((a) => a.id === action.applicant.id);

    if (
      currentApplicant &&
      (action.applicant?.crScore !== undefined || action.applicant?.crScoreCode !== undefined) &&
      (currentApplicant?.crScore != action.applicant?.crScore ||
        currentApplicant?.crScoreCode != action.applicant?.crScoreCode)
    ) {
      return this.store.dispatch(
        new ApplicantPatchAndProductReapply(
          action.applicant,
          currentApplicant.id,
          application.id,
          action.actionToDispatch,
        ),
      );
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.store
      .dispatch([
        new UpdateApplicantCredit(action.applicant),
        ...(action.actionToDispatch ? [action.actionToDispatch] : []),
      ])
      .pipe(
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(UpdateFinancialLiability)
  updateFinancialLiability(
    ctx: StateContext<FinancialLiabilityStateModel>,
    action: UpdateFinancialLiability,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();
    const prevFinancialLiability = state.financialLiabilities?.[action.financialLiability.id];

    action.financialLiability = FundmoreCalculator.applyLiabilityOverrides(
      state.liabilityOverrides,
      action.financialLiability,
      prevFinancialLiability,
    ) as FinancialLiability;

    return this.financialLiabilitiesServices
      .updateFinancialLiability(action.applicationId, action.financialLiability.id, {
        ...action.financialLiability,
      })
      .pipe(
        tap(() => {
          const state = ctx.getState();
          const prevFinancialLiability = state.financialLiabilities?.[action.financialLiability.id];

          const financialLiability: FinancialLiability = {
            ...action.financialLiability,
            FinancialLiabilityRecordTypes:
              prevFinancialLiability?.FinancialLiabilityRecordTypes || [],
          };

          ctx.patchState({
            financialLiabilities: {
              ...state.financialLiabilities,
              [action.financialLiability.id]: financialLiability,
            },
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(UpdateFinancialLiabilityRecordType)
  updateFinancialLiabilityRecordType(
    ctx: StateContext<FinancialLiabilityStateModel>,
    action: UpdateFinancialLiabilityRecordType,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.financialLiabilitiesServices
      .updateFinancialLiabilityRecordType(action.financialLiabilityId, action.recordType)
      .pipe(
        tap(() => {
          const state = ctx.getState();
          const financialLiability = state.financialLiabilities?.[action.financialLiabilityId];

          if (!financialLiability) {
            return;
          }

          const financialLiabilityRecordTypes = [
            ...financialLiability.FinancialLiabilityRecordTypes.filter(
              (x) => x.id !== action.recordType.id,
            ),
          ];

          financialLiabilityRecordTypes.push(action.recordType);

          ctx.patchState({
            financialLiabilities: {
              ...state.financialLiabilities,
              [action.financialLiabilityId]: {
                ...financialLiability,
                FinancialLiabilityRecordTypes: financialLiabilityRecordTypes,
              },
            },
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(CreateFinancialLiabilityRecordType)
  createFinancialLiabilityRecordType(
    ctx: StateContext<FinancialLiabilityStateModel>,
    action: CreateFinancialLiabilityRecordType,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.financialLiabilitiesServices
      .addFinancialLiabilityRecordTypes(action.financialLiabilityId, action.applicationId, [
        {
          recordType: action.financialLiabilityRecordType,
          type: FinancialLiabilityRecordTypeState.CURRENT,
        },
      ])
      .pipe(
        tap((recordTypes: FinancialLiabilityRecordType[]) => {
          const state = ctx.getState();
          const financialLiability = state.financialLiabilities?.[action.financialLiabilityId];

          if (!financialLiability) {
            return;
          }

          const financialLiabilityRecordTypes = [
            ...financialLiability.FinancialLiabilityRecordTypes,
            ...recordTypes,
          ];

          ctx.patchState({
            financialLiabilities: {
              ...state.financialLiabilities,
              [action.financialLiabilityId]: {
                ...financialLiability,
                FinancialLiabilityRecordTypes: financialLiabilityRecordTypes,
              },
            },
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(UpdateFinancialLiabilityAssignedApplicants)
  updateFinancialLiabilityAssignedApplicants(
    ctx: StateContext<FinancialLiabilityStateModel>,
    action: UpdateFinancialLiabilityAssignedApplicants,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const state = ctx.getState();
    const financialLiability = action.financialLiability;
    const prevFinancialLiability = state.financialLiabilities?.[action.financialLiability.id];

    if (!prevFinancialLiability) {
      return of(financialLiability);
    }

    const applicantsToAdd = financialLiability.ApplicantFinancialLiabilities.filter(
      (x) => !prevFinancialLiability.ApplicantFinancialLiabilities.find((y) => y.id === x.id),
    );

    const applicantsToRemove = prevFinancialLiability.ApplicantFinancialLiabilities.filter(
      (x) => !financialLiability.ApplicantFinancialLiabilities.find((y) => y.id === x.id),
    );

    return combineLatest([
      applicantsToAdd.length
        ? this.financialLiabilitiesServices.addApplicantFinancialLiabilityLink(
            action.applicationId,
            applicantsToAdd,
          )
        : of([]),
      ...applicantsToRemove.map((x) =>
        this.financialLiabilitiesServices.deleteApplicantFinancialLiabilityLink(
          action.applicationId,
          x.id,
        ),
      ),
    ]).pipe(
      map(([addedApplicants]) => {
        // filter out applicants just added in order to replace them with the db entries
        financialLiability.ApplicantFinancialLiabilities =
          financialLiability.ApplicantFinancialLiabilities.filter((x) => x.id);

        financialLiability.ApplicantFinancialLiabilities.push(...addedApplicants);

        return financialLiability;
      }),
      tap((financialLiability) => {
        ctx.patchState({
          financialLiabilities: {
            ...state.financialLiabilities,
            [action.financialLiability.id]: financialLiability,
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }
}
