import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { InsurancePremium, InsuranceQuote, Insurer } from '../shared/model';

@Injectable({
  providedIn: 'root',
})
export class InsurancesService {
  constructor(private http: HttpClient) {}

  getInsurancePremiumPlans(): Observable<InsurancePremium[]> {
    return this.http.get<InsurancePremium[]>(`${environment.api_url}/insurances`);
  }

  getInsurers(): Observable<Insurer[]> {
    return this.http.get<Insurer[]>(`${environment.api_url}/insurances/insurers`);
  }

  getMIQuote(insurerId: string): Observable<InsuranceQuote> {
    return this.http.get<InsuranceQuote>(`${environment.api_url}/insurances/${insurerId}/quote`);
  }
}
