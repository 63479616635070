export function computeTotalMortgagePayments(
  firstMortgagePayment: number | null | undefined,
  secondMortgagePayment: number | null | undefined,
) {
  let totalMortgagePayments;
  if (firstMortgagePayment && secondMortgagePayment) {
    totalMortgagePayments = firstMortgagePayment + secondMortgagePayment;
  }

  return totalMortgagePayments;
}
