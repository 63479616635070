import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Condition, ApplicationCondition } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ConditionsOfApprovalService {
  constructor(private http: HttpClient) {}

  getConditions(applicationId: string): Observable<Condition[]> {
    return this.http.get<Condition[]>(
      `${environment.fundmore_api_url}/conditions/applicationAvailable/${applicationId}`,
    );
  }

  getApplicationConditions(applicationId: string): Observable<ApplicationCondition[]> {
    return this.http.get<ApplicationCondition[]>(
      `${environment.fundmore_api_url}/applicationCondition/${applicationId}`,
    );
  }

  addApplicationConditions(
    applicationId: string,
    stakeholderId: string,
    conditionIds: string[],
  ): Observable<ApplicationCondition[]> {
    return this.http.post<ApplicationCondition[]>(
      `${environment.fundmore_api_url}/applicationCondition/addApplicationConditions`,
      {
        applicationId,
        stakeholderId,
        conditionIds,
      },
    );
  }

  deleteApplicationCondition(applicationConditionId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.fundmore_api_url}/applicationCondition/${applicationConditionId}`,
    );
  }

  createCustomCondition(condition: Partial<Condition>) {
    return this.http.post<Condition>(
      `${environment.fundmore_api_url}/conditions/custom`,
      condition,
    );
  }

  updateApplicationConditionStatus(
    applicationConditionId: string,
    status: string,
    formattedNote?: string,
    comments?: string,
  ) {
    return this.http.patch<ApplicationCondition>(
      `${environment.fundmore_api_url}/applicationCondition/${applicationConditionId}/status`,
      { status, formattedNote, comments },
    );
  }
}
