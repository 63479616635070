import { ProvinceCode } from '../property';
import { AdvancedProductDefaultValue } from './advancedProductDefaultValue';
import { AdvancedProductParameters } from './advancedProductParameters';
import { BrokerCommission } from './commission';
import { RateMatrix } from './rateMatrix';

export interface AdvancedProduct {
  id: string;
  name?: string;
  locations?: ProvinceCode[];
  parameters?: AdvancedProductParameters;
  isActive: boolean;
  notes?: string;
  defaultValues?: AdvancedProductDefaultValue[];
  externalId: string;
  createdAt?: Date;
  updatedAt?: Date;
  RateMatrices?: RateMatrix[];
  BrokerCommissions?: BrokerCommission[];
}

export enum AdvancedProductInUseError {
  MORTGAGES = 'ADVANCED_PRODUCT_IN_USE_MORTGAGES',
  DEFAULT_FEES = 'ADVANCED_PRODUCT_IN_USE_DEFAULT_FEES',
  DEFAULT_TASKS = 'ADVANCED_PRODUCT_IN_USE_DEFAULT_TASKS',
  CONDITIONS = 'ADVANCED_PRODUCT_IN_USE_CONDITIONS',
}
