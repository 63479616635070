import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Condition } from '../../shared/documents';

@Injectable({
  providedIn: 'root',
})
export class ManageConditionsService {
  constructor(private http: HttpClient) {}

  getConditions(): Observable<Condition[]> {
    return this.http.get<Condition[]>(`${environment.fundmore_api_url}/conditions`);
  }

  createCondition(condition: Partial<Condition>): Observable<Condition> {
    return this.http.post<Condition>(`${environment.fundmore_api_url}/conditions`, condition);
  }

  updateCondition(conditionId: string, condition: Partial<Condition>): Observable<Condition> {
    return this.http.patch<Condition>(
      `${environment.fundmore_api_url}/conditions/${conditionId}`,
      condition,
    );
  }

  updateConditionIndex(conditionId: string, index: number | null): Observable<Condition> {
    return this.http.patch<Condition>(
      `${environment.fundmore_api_url}/conditions/${conditionId}/index`,
      { index },
    );
  }

  deleteCondition(conditionId: string): Observable<void> {
    return this.http.delete<void>(`${environment.fundmore_api_url}/conditions/${conditionId}`);
  }

  publishCondition(conditionId: string): Observable<Condition> {
    return this.http.patch<Condition>(
      `${environment.fundmore_api_url}/conditions/publish/${conditionId}`,
      {},
    );
  }

  inactivateCondition(conditionId: string): Observable<Condition> {
    return this.http.patch<Condition>(
      `${environment.fundmore_api_url}/conditions/inactivate/${conditionId}`,
      {},
    );
  }
}
