import { InsuranceRequestedMortgageData } from '../insuranceQuote';
import {
  CmhcCapturedType,
  CmhcIndicatorType,
  CmhcBorrowerOccupationType,
  CmhcBorrowerIndustrySector,
  CmhcBorrowerEmploymentType,
  CmhcBorrowerResidentialStatus,
  CmhcBorrowerMaritalStatus,
  CmhcPropertyNewExistingIndicator,
  CmhcPropertyTenureType,
  CmhcPropertyDwellingType,
  CmhcPropertyHouseType,
  CmhcPropertyGarageSize,
  CmhcPropertyGarageType,
  CmhcPropertyHeatingType,
  CmhcQualifyingInformationApplicationType,
  CmhcQualifyingInformationOccupancyType,
  CmhcLoanDetailsLoanPurpose,
  CmhcLoanDetailsSourceOfBusiness,
  CmhcLoanDetailsRepaymentType,
  CmhcLoanDetailsInterestType,
  CmhcLoanDetailsSecurityType,
  CmhcLoanDetailProcessingIndicator,
  CmhcLoanDetailsInstallmentCode,
  CmhcLanguageCode,
} from './enums';

export interface CmhcBaseValue {
  $: {
    captured: CmhcCapturedType;
    val: string | number;
  };
}

export interface CmhcBaseCheckboxValue {
  $: { captured: CmhcCapturedType; val: CmhcIndicatorType };
}

export interface CmhcBaseCode<T> {
  $: {
    captured: CmhcCapturedType;
    code?: T;
  };
}

export interface CmhcResponseTaxValue {
  $: { due: number; received: number };
}

export interface CmhcValuePeriodType {
  $: { captured: CmhcCapturedType; months?: number; years?: number };
}

export interface CmhcPhoneNumber {
  $: {
    captured: CmhcCapturedType;
    areaCode: string;
    localNumber: string;
  };
}

export interface CmhcAddress {
  UnitNumber?: CmhcBaseValue[];
  StreetNumber?: CmhcBaseValue[];
  StreetName: CmhcBaseValue[];
  StreetType?: CmhcBaseValue[];
  StreetDirection?: CmhcBaseValue[];
  Municipality: CmhcBaseValue[];
  PostalCode: CmhcBaseValue[];
  Province?: CmhcBaseValue[];
}

export interface CmhcBaseBorrower {
  Name: { $: { captured: CmhcCapturedType; first?: string; last?: string; middle?: string } }[];
  BirthDate: CmhcBaseValue[];
}

export interface CmhcBorrowerEmployment {
  Occupation?: CmhcBaseCode<CmhcBorrowerOccupationType>[];
  YearsService?: CmhcValuePeriodType[];
  YearsWithCurrentEmployer?: CmhcValuePeriodType[];
  IndustrySector?: CmhcBaseCode<CmhcBorrowerIndustrySector>[];
}

export interface CmhcMainCoBorrower extends CmhcBaseBorrower {
  PhoneNumber?: CmhcPhoneNumber[];
  Address?: CmhcAddress[];
  DurationAtAddress?: CmhcValuePeriodType[];
  NonPermanentResident?: CmhcBaseCheckboxValue[];
  SocialInsuranceNumber?: CmhcBaseValue[];
  BorrowerEmployment?: CmhcBorrowerEmployment[];
  BorrowerBanking?: CmhcMainCoBorrowerBanking[];
}

export interface CmhcMainCoBorrowerBanking {
  CreditCard?: CmhcBaseValue[];
  CreditCardNumber?: CmhcBaseValue[];
}

export interface CmhcMainBorrowerBanking extends CmhcMainCoBorrowerBanking {
  BankCard?: CmhcBaseCheckboxValue[];
  BankAccount?: CmhcBaseCheckboxValue[];
  SavingsInvestmentAccount?: CmhcBaseCheckboxValue[];
  YearsWithBank?: CmhcValuePeriodType[];
}

export interface CmhcMainBorrower extends CmhcMainCoBorrower {
  BorrowerType: CmhcBaseCode<CmhcBorrowerEmploymentType>[];
  ResidentialStatus: CmhcBaseCode<CmhcBorrowerResidentialStatus>[];
  MaritalStatus: CmhcBaseCode<CmhcBorrowerMaritalStatus>[];
  Dependents: CmhcBaseValue[];
  Coborrower1?: CmhcMainCoBorrower[];
  Coborrower2?: CmhcBaseBorrower[];
  Coborrower3?: CmhcBaseBorrower[];
  Guarantor?: CmhcBaseBorrower[];
  BorrowerBanking?: CmhcMainBorrowerBanking[];
}

export interface CmhcSubmitApplicationProperty {
  Age: CmhcBaseValue[];
  NewExistingIndicator: CmhcBaseCode<CmhcPropertyNewExistingIndicator>[];
  LegalDescription: CmhcBaseValue[];
  NumberOfUnits: CmhcBaseValue[];
  Tenure: CmhcBaseCode<CmhcPropertyTenureType>[];
  DwellingType: CmhcBaseCode<CmhcPropertyDwellingType>[];
  HouseType: CmhcBaseCode<CmhcPropertyHouseType>[];
  FloorArea: CmhcBaseValue[];
  EnvironmentalHazard: CmhcBaseValue[];
  LotSize?: CmhcBaseValue[];
  GarageSize: CmhcBaseCode<CmhcPropertyGarageSize>[];
  GarageType: CmhcBaseCode<CmhcPropertyGarageType>[];
  HeatingType: CmhcBaseCode<CmhcPropertyHeatingType>[];
  Address: CmhcAddress[];
}

export interface CmhcQualifyingInformationSourcesOfEquity {
  CashFromOwnResource?: CmhcBaseCheckboxValue[];
  SaleOfOtherProperty?: CmhcBaseCheckboxValue[];
  RRSP?: CmhcBaseCheckboxValue[];
  SweatEquity?: CmhcBaseCheckboxValue[];
  BorrowedAgainstAssets?: CmhcBaseCheckboxValue[];
  Gift?: CmhcBaseCheckboxValue[];
  Other?: CmhcBaseCheckboxValue[];
  SelfDirectedRRSP?: CmhcBaseCheckboxValue[];
}

export interface CmhcSubmitApplicationQualifyingInformation {
  TotalIncome: CmhcBaseValue[];
  GrossDebtService: CmhcBaseValue[];
  TotalDebtService: CmhcBaseValue[];
  AnnualShelterPayment?: CmhcBaseValue[];
  PropertyTaxes?: CmhcBaseValue[];
  ApplicationType?: CmhcBaseCode<CmhcQualifyingInformationApplicationType>[];
  Equity: CmhcBaseValue[];
  OccupancyType: CmhcBaseCode<CmhcQualifyingInformationOccupancyType>[];
  SourceOfEquity?: CmhcQualifyingInformationSourcesOfEquity[];
  FlexibleDownpayment?: CmhcBaseCheckboxValue[];
}

export interface CmhcSubmitApplicationLoanDetails {
  SellingPrice: CmhcBaseValue[];
  ClosingDate: CmhcBaseValue[];
  LoanPurpose: CmhcBaseCode<CmhcLoanDetailsLoanPurpose>[];
  LoanAmount: CmhcBaseValue[];
  SourceOfBusiness: CmhcBaseCode<CmhcLoanDetailsSourceOfBusiness>[];
  RepaymentType: CmhcBaseCode<CmhcLoanDetailsRepaymentType>[];
  InterestRate: CmhcBaseValue[];
  InterestType: CmhcBaseCode<CmhcLoanDetailsInterestType>[];
  Term?: CmhcValuePeriodType[];
  AmortizationPeriod: CmhcValuePeriodType[];
  SecurityType: CmhcBaseCode<CmhcLoanDetailsSecurityType>[];
  ProcessingIndicator?: CmhcBaseCode<CmhcLoanDetailProcessingIndicator>[];
  OtherFinancing: CmhcBaseCheckboxValue[];
  Improvements: CmhcBaseCheckboxValue[];
  PremiumToBeAdded: CmhcBaseCheckboxValue[];
  BlendedAmortization: CmhcBaseCheckboxValue[];
  BalanceExistingMortgage?: CmhcBaseValue[];
  PreviousSaleClosingDate?: CmhcBaseValue[];
  PreviousSalePrice?: CmhcBaseValue[];
  AsImprovedValue?: CmhcBaseValue[];
  InstallmentCompletionCode: CmhcBaseCode<CmhcLoanDetailsInstallmentCode>[];
  LoanToValueRatio?: CmhcBaseValue[];
  EnergyEfficiency?: CmhcBaseCheckboxValue[];
  HomeBuilderProcess?: CmhcBaseCheckboxValue[];
  BuilderName?: CmhcBaseValue[];
  ConstructionImprovementCosts?: CmhcBaseValue[];
  CMHCSelfEmployedSimplified?: CmhcBaseCheckboxValue[];
  LoanAdvanceAmount?: CmhcBaseValue[];
  LoanAdvanceNumber?: CmhcBaseValue[];
  MarketValue: CmhcBaseValue[];
  ReferralRequested: CmhcBaseCheckboxValue[];
  TopUpAmount?: CmhcBaseValue[];
  LendingValue?: CmhcBaseValue[];
  LOCApp?: CmhcBaseCheckboxValue[];
}

export interface CmhcInsuranceApplicationDetails {
  borrower?: CmhcMainBorrower;
  property?: CmhcSubmitApplicationProperty;
  qualifyingInformation?: CmhcSubmitApplicationQualifyingInformation;
  loanDetails?: CmhcSubmitApplicationLoanDetails;
  comment?: string;
  previousCMHCAccountNumber?: string;
  lender?: CmhcSubmitApplicationLenderBase;
  productTrackerId?: CmhcBaseValue[];
  language?: CmhcLanguageCode;
  onReserveTrustAgreementNumber?: CmhcBaseValue[];
  LOCApp?: CmhcBaseCheckboxValue[];
  lenderReferenceNumber?: string;
  requestedMortgageData?: InsuranceRequestedMortgageData;
}

export interface CmhcSubmitLenderClientServiceRep {
  Name?: { $: { captured: CmhcCapturedType; first?: string; last?: string } }[];
  PhoneNumber?: CmhcPhoneNumber[];
}

export interface CmhcSubmitLenderOriginatingMortRep {
  Name?: { $: { captured: CmhcCapturedType; first?: string; last?: string } }[];
  PhoneNumber?: CmhcPhoneNumber[];
}

export interface CmhcSubmitApplicationLenderBase {
  ClientServiceRep?: CmhcSubmitLenderClientServiceRep[];
  OriginatingMortRep?: CmhcSubmitLenderOriginatingMortRep[];
}

export interface CmhcSubmitApplicationLender extends CmhcSubmitApplicationLenderBase {
  FinInst: CmhcBaseValue[];
  Transit: CmhcBaseValue[];
  LenderReference: CmhcBaseValue[];
}

export interface CmhcApplication {
  CMHCAccountNumber: CmhcBaseValue[];
  Language: CmhcBaseCode<CmhcLanguageCode>[];
  Lender: CmhcSubmitApplicationLender[];
  Borrower: CmhcMainBorrower[];
  Property: CmhcSubmitApplicationProperty[];
  QualifyingInformation: CmhcSubmitApplicationQualifyingInformation[];
  LoanDetails: CmhcSubmitApplicationLoanDetails[];
  Comments: { $: { captured: CmhcCapturedType }; _: string }[];
  PreviousCMHCAccountNumber?: CmhcBaseValue[];
  ProductTrackerID?: CmhcBaseValue[];
  OnReserveTrustAgreement?: CmhcBaseValue[];
  LOCApp?: CmhcBaseCheckboxValue[];
}

interface SubmissionError {
  $: { code: string };
  English: string[];
  French: string[];
}

interface CmhcMessage {
  $: {
    code: string;
  };
  _: string;
}

interface CmhcApplicationResponse extends CmhcApplication {
  Messages: { Message: CmhcMessage[] }[];
  ProductTrackerID: CmhcBaseValue[];
  PreviousCMHCAccountNumber: CmhcBaseValue[];
  PrequalID: CmhcBaseValue[];
  LOCRepayment: CmhcBaseValue[];
  Status: CmhcBaseValue[];
  EverApprovedIndicator: CmhcBaseValue[];
  EverLowRatio: CmhcBaseValue[];
  Fees?: CmhcResponseTaxValue[];
  Premium?: CmhcResponseTaxValue[];
  Tax?: CmhcResponseTaxValue[];
}

export interface CmhcSubmitApplicationResponse {
  Application?: CmhcApplicationResponse;
  Errors?: { Error: SubmissionError[] };
}

export interface CmhcCertificateResponse {
  CertificateDate: string;
  LenderReferenceNo: string | null;
  CMHCAccountNumber: string;
  Messages: {
    Message:
      | {
          Code: string;
          _: string;
        }[]
      | {
          Code: string;
          _: string;
        };
  };
  EnPDFBase64Data: string | null;
  FrPDFBase64Data: string | null;
}

export interface CmhcSubmitCertificateResponse {
  InsuranceCertificate?: CmhcCertificateResponse;
}
