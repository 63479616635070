import {
  Application,
  ConstructionApplicationItem,
  ConstructionMortgage,
  DrawSchedule,
} from '@fundmoreai/models';
import {
  availableAdvanceAmountPayload,
  DrawDatePayload,
  HardCostToCompletePayload,
} from './construction-draw-schedule.service';
import { BuildingBudgetPayload } from './construction-mortgage.service';

export class InitializeConstructionState {
  static readonly type = '@constructionModule.InitializeConstructionState';
  constructor(public application: Application) {}
}

export class SetConstructionModuleEnabled {
  static readonly type = '@constructionModule.SetConstructionModuleEnabled';
  constructor(public enabled: boolean) {}
}

export class GetDrawSchedules {
  static readonly type = '@drawSchedule.GetDrawSchedules';
  constructor(public applicationId: string) {}
}

export class InsertDrawSchedule {
  static readonly type = '@drawSchedule.InsertDrawSchedule';
  constructor(public drawSchedule: DrawSchedule) {}
}

export class RemoveDrawSchedule {
  static readonly type = '@drawSchedule.RemoveDrawSchedule';
  constructor(public id: string) {}
}

export class UpdateHardCostToComplete {
  static readonly type = '@drawSchedule.UpdateHardCostToComplete';
  constructor(public hardCostToCompletePayload: HardCostToCompletePayload) {}
}

export class UpdateDrawDate {
  static readonly type = '@drawSchedule.UpdateDrawDate';
  constructor(public drawDatePayload: DrawDatePayload) {}
}

export class UpdateAvailableAdvanceAmount {
  static readonly type = '@drawSchedule.UpdateAvailableAdvanceAmount';
  constructor(public availableAdvanceAmountPayload: availableAdvanceAmountPayload) {}
}

export class RecalculateDrawSchedule {
  static readonly type = '@drawSchedule.RecalculateDrawSchedule';
  constructor(public applicationId: string) {}
}

export class GetConstructionMortgage {
  static readonly type = '@constructionMortgage.GetConstructionMortgage';
  constructor(public mortgageId: string) {}
}

export class UpdateConstructionMortgage {
  static readonly type = '@constructionMortgage.updateConstructionMortgage';
  constructor(public constructionMortgage: ConstructionMortgage) {}
}

export class UpdateBuildingBudget {
  static readonly type = '@constructionMortgage.updateBuildingBudget';
  constructor(
    public constructionMortgageId: string,
    public buildingBudgetPayload: BuildingBudgetPayload,
  ) {}
}

export class GetConstructionApplicationItem {
  static readonly type = '@constructionApplicationItem.GetConstructionApplicationItem';
  constructor(public applicationId: string) {}
}

export class UpdateConstructionItem {
  static readonly type = '@constructionApplicationItem.UpdateConstructionItem';
  constructor(public item: ConstructionApplicationItem) {}
}
