import { PropertyOwner } from '@fundmoreai/models';

export class SetOwners {
  static readonly type = '@propertyOwners.SetOwners';
  constructor(public owners: PropertyOwner[]) {}
}

export class CreateOwner {
  static readonly type = '@propertyOwners.CreateOwner';
  constructor(
    public propertyId: string,
    public owner: Partial<PropertyOwner>,
    public applicationId: string,
  ) {}
}

export class UpdateOwner {
  static readonly type = '@propertyOwners.UpdateOwner';
  constructor(public propertyId: string, public owner: PropertyOwner) {}
}

export class RemoveOwner {
  static readonly type = '@propertyOwners.RemoveOwner';
  constructor(public propertyId: string, public owner: PropertyOwner) {}
}
