import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AuthComponent } from '../auth.component';
import { AuthService } from '../auth.service';
import { AuthResult, AuthResultStatus } from '../model';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';

@Component({
  templateUrl: './verify-confirm-password.component.html',
  styleUrls: ['./verify-confirm-password.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, RouterLink, MatButtonModule],
})
export class VerifyConfirmPasswordComponent implements AuthComponent {
  @Input() data: any;
  @Output() authResultEvent: EventEmitter<AuthResult> = new EventEmitter<AuthResult>();
  @Output() formSubmittingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSubmitting = false;
  verifyConfirmPasswordForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private authApiService: AuthService) {
    this.verifyConfirmPasswordForm = this.fb.group(
      {
        verificationCode: ['', Validators.required],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      { validator: this.checkIfMatchingPasswords('password', 'confirmPassword') },
    );
  }
  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: UntypedFormGroup) => {
      const passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }
  handleGoToLogin() {
    this.authResultEvent.next(new AuthResult(AuthResultStatus.LOGIN));
  }
  submitVerifyConfirmPasswordForm() {
    this.isSubmitting = true;
    this.formSubmittingEvent.next(true);
    const { password, verificationCode } = this.verifyConfirmPasswordForm.value;
    const { email } = this.data;
    this.authApiService.confirmNewPassword(email, verificationCode, password).subscribe(
      (res) => {
        if (res.status === AuthResultStatus.SUCCESS) {
          this.authResultEvent.next(new AuthResult(AuthResultStatus.LOGIN));
        } else {
          this.authResultEvent.next(res);
        }
      },
      (err) => {
        this.isSubmitting = false;
        this.formSubmittingEvent.next(false);
        this.authResultEvent.next(new AuthResult(AuthResultStatus.ERROR, err));
      },
    );
  }
}
