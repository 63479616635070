import {
  Component,
  ChangeDetectionStrategy,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ApplicationNoteStatus } from '@fundmoreai/models';
import { Store } from '@ngxs/store';
import { DeleteDraftNote } from '../notes.actions';
import { Note } from '../notes.model';
import { NotesReadMoreComponent } from '../notes-read-more/notes-read-more.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { NgFor, NgIf, DatePipe } from '@angular/common';

@Component({
  selector: 'fm-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgFor,
    MatIconModule,
    NgIf,
    MatTooltipModule,
    MatButtonModule,
    MatMenuModule,
    NotesReadMoreComponent,
    DatePipe,
  ],
})
export class NotesListComponent implements OnChanges {
  @Input()
  canEdit: boolean | null | undefined;

  @Input()
  currentUserId: string;

  @Input()
  notes: Note[] | null;

  @Output()
  editDraftNote: EventEmitter<Note> = new EventEmitter<Note>();

  readonly ApplicationNoteStatus = ApplicationNoteStatus;

  constructor(private el: ElementRef, private store: Store) {}

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.notes && changes.notes.firstChange) || this.el.nativeElement.scrollHeight === 0) {
      this.scrollToBottom();
    }
  }

  deleteDraftNote(note: Note) {
    this.store.dispatch(new DeleteDraftNote(note.id));
  }

  private scrollToBottom() {
    setTimeout(() => {
      this.el.nativeElement.scrollTop = this.el.nativeElement.scrollHeight;
    }, 100); // 100ms in order to give fm-notes-read-more time to execute
  }
}
