import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { AuthComponent } from '../auth.component';
import { AuthService } from '../auth.service';
import { AuthResult, AuthResultStatus } from '../model';
import { MatButtonModule } from '@angular/material/button';
import { RouterLink } from '@angular/router';

@Component({
  templateUrl: './confirm-new-password.component.html',
  styleUrls: ['./confirm-new-password.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, RouterLink, MatButtonModule],
})
export class ConfirmNewPasswordComponent implements AuthComponent {
  @Input() data: any;
  @Output() authResultEvent: EventEmitter<AuthResult> = new EventEmitter<AuthResult>();
  @Output() formSubmittingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  isSubmitting = false;
  confirmNewPasswordForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private authApiService: AuthService) {
    this.confirmNewPasswordForm = this.fb.group(
      {
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      { validator: this.checkIfMatchingPasswords('password', 'confirmPassword') },
    );
  }
  checkIfMatchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: UntypedFormGroup) => {
      const passwordInput = group.controls[passwordKey],
        passwordConfirmationInput = group.controls[passwordConfirmationKey];
      if (passwordInput.value !== passwordConfirmationInput.value) {
        return passwordConfirmationInput.setErrors({ notEquivalent: true });
      } else {
        return passwordConfirmationInput.setErrors(null);
      }
    };
  }
  handleGoToLogin() {
    this.authResultEvent.next(new AuthResult(AuthResultStatus.LOGIN));
  }
  submitConfirmNewPasswordForm() {
    this.isSubmitting = true;
    this.formSubmittingEvent.next(true);
    const { password } = this.confirmNewPasswordForm.value;
    const { cognitoUser, requiredAttributeData } = this.data;
    this.authApiService
      .completeNewPasswordChallenge(cognitoUser, password, requiredAttributeData)
      .subscribe(
        (res) => {
          this.authResultEvent.next(res);
        },
        (err) => {
          this.isSubmitting = false;
          this.formSubmittingEvent.next(false);
          this.authResultEvent.next(new AuthResult(AuthResultStatus.ERROR, err));
        },
      );
  }
}
