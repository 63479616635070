import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserSettingsModel } from '../user-settings.state';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  constructor(private http: HttpClient) {}

  getUserSettings(): Observable<Partial<UserSettingsModel>> {
    return this.http.get<UserSettingsModel>(`${environment.api_url}/account/userSettings`);
  }

  updateUserSettings(userSettings: Partial<UserSettingsModel>): Observable<void> {
    return this.http.patch<void>(`${environment.api_url}/account/userSettings`, userSettings);
  }
}
