import { CalculationResult, Fee, GentaiTiers, Mortgage, Property } from '@fundmoreai/models';
import { computeExistingMortgageAmount } from '../existing-mortgage-amount.calculator';
import { computeLoanAmountForLTV } from '../loan-amount-for-ltv.calculator';
import { computeLTV } from '../ltv.calculator';
import { computePropertyValue } from '../property-value.calculator';

export function computeGentaiTier1SlidingScaleLTV(primaryPropertyValue: number): CalculationResult {
  const loanAmount: number = 1125000 + (primaryPropertyValue - 1500000) * 0.6;
  let ltvValue = 0;

  if (primaryPropertyValue !== 0) {
    ltvValue = (loanAmount / primaryPropertyValue) * 100;
  }

  const data: CalculationResult = {
    formula:
      '($1,125,000 + ((Primary Property Value – $1,500,000) x 60%)) / Primary Property Value',
    items: [{ text: 'Primary Property Value', value: primaryPropertyValue?.toString() }],
    result: ltvValue,
  };
  return data;
}

export function computeGentaiTier2SlidingScaleLTV(primaryPropertyValue: number): CalculationResult {
  let ltvValue = 0;

  const loanAmount: number = 750000 + (primaryPropertyValue - 1000000) * 0.6;
  if (primaryPropertyValue !== 0) {
    ltvValue = (loanAmount / primaryPropertyValue) * 100;
  }

  const data: CalculationResult = {
    formula: '($750,000 + ((Primary Property Value - $1,000,000) x 60%)) / Primary Property Value',
    items: [{ text: 'Primary Property Value', value: primaryPropertyValue?.toString() }],
    result: ltvValue,
  };
  return data;
}

export function computeGentaiSlidingScaleLTVFromObjects(
  propertyTier: number,
  primaryProperty: Property,
  existingMortgages: Mortgage[],
  requestedMortgage: Mortgage | null,
  fees: Fee[],
  capFeesMaxPercentage: number,
): CalculationResult {
  const primaryPropertyValue = computePropertyValue(primaryProperty);
  const existingMortgageAmount = computeExistingMortgageAmount(
    existingMortgages?.filter((x) => !x.propertyId),
  );

  const loanAmount = computeLoanAmountForLTV(
    requestedMortgage ? [requestedMortgage] : [],
    fees,
    capFeesMaxPercentage,
  );

  let ltv;
  if (propertyTier == GentaiTiers.Tier1 && primaryPropertyValue > 1500000) {
    ltv = computeGentaiTier1SlidingScaleLTV(primaryPropertyValue);
  } else if (propertyTier == GentaiTiers.Tier2 && primaryPropertyValue > 1000000) {
    ltv = computeGentaiTier2SlidingScaleLTV(primaryPropertyValue);
  } else {
    ltv = computeLTV(primaryPropertyValue, existingMortgageAmount, loanAmount);
  }
  return ltv;
}
