import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { BOCModel } from '../shared/model';
import { BOCService } from '../shared/services/boc.service';
import { InitializeBOC } from './boc.actions';

@State<BOCModel>({
  name: 'boc',
})
@Injectable()
export class BOCState {
  constructor(private bocService: BOCService) {}

  @Selector() static boc(state: BOCModel) {
    return state;
  }

  @Action(InitializeBOC)
  initialize(ctx: StateContext<BOCModel>): Observable<BOCModel> {
    return this.bocService.getBOCValues().pipe(
      tap((bocValues) => {
        ctx.setState(bocValues);
      }),
    );
  }
}
