import { Mortgage } from '@fundmoreai/models';
import { differenceInCalendarDays } from 'date-fns';
import { fromShortISODateToMiddleLocalDate } from '@fundmoreai/helpers';

export function interestAdjustmentAmount(mortgage: Partial<Mortgage> | undefined) {
  if (!mortgage || !mortgage.closingDate) {
    return null;
  }
  const totalLoanAmount = mortgage.totalLoanAmount;
  const netRate = mortgage.netRate;
  const closingDate = fromShortISODateToMiddleLocalDate(mortgage.closingDate);

  if (!totalLoanAmount || !netRate || !mortgage?.interestAdjustmentDate) {
    return null;
  }
  const interestAdjustmentDate = fromShortISODateToMiddleLocalDate(
    mortgage?.interestAdjustmentDate,
  );

  const diffDays = differenceInCalendarDays(interestAdjustmentDate, closingDate);

  // Calculate the total mortgage interest rate per year
  const yearInterestRate = (totalLoanAmount * netRate) / 100;

  // Calculate the mortgage interest rate per day
  const dayInterestRate = yearInterestRate / 365;

  // Calculate the mortgage interest rate for the number of days in between your closing date and your first mortgage payment date
  const interestAdjustmentAmount = dayInterestRate * diffDays;

  return Math.round(interestAdjustmentAmount * 100) / 100;
}
