import { ApplicationAggregate } from '@fundmoreai/models';

export type ApplicationAggregateComputeInput = Pick<
  ApplicationAggregate,
  'businessBankingRelationCurrent' | 'businessBankingRelationFuture'
>;

export class FetchApplicationAggregates {
  static readonly type = '@applicationAggregates.FetchApplicationAggregates';
  constructor(public applicationId: string) {}
}

export class RefreshBusinessBankingRelationship {
  static readonly type = '@applicationAggregates.RefreshBusinessBankingRelationship';
  constructor(public applicationId: string) {}
}

export class ComputeApplicationAggregates {
  static readonly type = '@applicationAggregates.ComputeApplicationAggregates';
  constructor(public applicationId: string, public input?: ApplicationAggregateComputeInput) {}
}
