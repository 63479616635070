import { CalvertIntegrationFields } from './calvert';
import { RMCCommitmentDatesFields, RMCIntegrationFields } from './rmc';
import { EQIntegrationFields, EQPartnerLoanFields } from './eqMaps';
import { MCUIntegrationFields, MCUPaymentSourceFields, MCUSecurityFields } from './mcu';

export function assertUnreachable(value: never, errorMessage = 'Unhandled') {
  console.error(errorMessage, ' ', value);
}

export enum PaymentFrequency {
  MONTHLY = 'MONTHLY',
  SEMI_MONTHLY = 'SEMI_MONTHLY',
  SEMI_ANNUALLY = 'SEMI_ANNUALLY',
  QUARTERLY = 'QUARTERLY',
  ANNUALLY = 'ANNUALLY',
  BI_WEEKLY = 'BI_WEEKLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  ACCELERATED_BI_WEEKLY = 'ACCELERATED_BI_WEEKLY',
  ACCELERATED_WEEKLY = 'ACCELERATED_WEEKLY',
}

export enum CompoundPeriod {
  SEMI_MONTHLY = 'SEMI_MONTHLY',
  SEMI_ANNUAL = 'SEMI_ANNUAL',
  MONTHLY = 'MONTHLY',
  QUARTERLY = 'QUARTERLY',
  ANNUALLY = 'ANNUALLY',
  BI_WEEKLY = 'BI_WEEKLY',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  SIMPLE_INTEREST = 'SIMPLE_INTEREST',
}

export enum RepaymentType {
  PRINCIPAL_AND_INTEREST = 'PRINCIPAL_AND_INTEREST',
  INTEREST_ONLY = 'INTEREST_ONLY',
  BALLOON_PAYMENT = 'BALLOON_PAYMENT',
  UNKNOWN = 'UNKNOWN',
}

export enum InsurancePaidByType {
  LENDER = 'LENDER',
  BORROWER = 'BORROWER',
}

export enum DatesProcessingType {
  Calvert = 'calvert',
  Default = 'default',
}

export enum RateType {
  FIXED = 'FIXED',
  ADJUSTABLE = 'ADJUSTABLE',
  CAPPED_VARIABLE = 'CAPPED_VARIABLE',
  VARIABLE = 'VARIABLE',
  BUYDOWN = 'BUYDOWN',
}

export enum ApplicationType {
  PREAPPROVAL = 'PREAPPROVAL',
  APPROVAL = 'APPROVAL',
}

export enum PrepaymentType {
  SPREAD_EVENLY = 'SPREAD_EVENLY',
  APPLY_UPFRONT = 'APPLY_UPFRONT',
}

export enum MortgageType {
  REQUESTED = 'REQUESTED',
  EXISTING = 'EXISTING',
  REFINANCE = 'REFINANCE',
}

export enum PropertyType {
  PRIMARY = 'PRIMARY',
  OTHER = 'OTHER',
  EXTERNAL = 'EXTERNAL',
}

export enum CorroborationSource {
  Opta = 'Opta',
}

export enum CorroborationField {
  PropertyValuation = 'propertyValuation',
  PropertyDetails = 'propertyDetails',
}

export enum IncomePeriod {
  BI_WEEKLY = 'BI_WEEKLY',
  WEEKLY = 'WEEKLY',
  SEMI_MONTHLY = 'SEMI_MONTHLY',
  MONTHLY = 'MONTHLY',
  YEARLY = 'YEARLY',
}

export enum Province {
  Alberta = 'Alberta',
  British_Columbia = 'British Columbia',
  Manitoba = 'Manitoba',
  New_Brunswick = 'New Brunswick',
  Newfoundland_Labrador = 'Newfoundland and Labrador',
  Northwest_Territories = 'Northwest Territories',
  Nova_Scotia = 'Nova Scotia',
  Nunavut = 'Nunavut',
  Ontario = 'Ontario',
  Prince_Edward_Island = 'Prince Edward Island',
  Quebec = 'Québec',
  Saskatchewan = 'Saskatchewan',
  Yukon = 'Yukon',
}

export enum State {
  Alabama = 'Alabama',
  Alaska = 'Alaska',
  American_Samoa = 'American Samoa',
  Arizona = 'Arizona',
  Arkansas = 'Arkansas',
  California = 'California',
  Colorado = 'Colorado',
  Connecticut = 'Connecticut',
  Delaware = 'Delaware',
  District_of_Columbia = 'District of Columbia',
  Federated_States_of_Micronesia = 'Federated States of Micronesia',
  Florida = 'Florida',
  Georgia = 'Georgia',
  Guam = 'Guam',
  Hawaii = 'Hawaii',
  Idaho = 'Idaho',
  Illinois = 'Illinois',
  Indiana = 'Indiana',
  Iowa = 'Iowa',
  Kansas = 'Kansas',
  Kentucky = 'Kentucky',
  Louisiana = 'Louisiana',
  Maine = 'Maine',
  Marshall_Islands = 'Marshall Islands',
  Maryland = 'Maryland',
  Massachusetts = 'Massachusetts',
  Michigan = 'Michigan',
  Minnesota = 'Minnesota',
  Mississippi = 'Mississippi',
  Missouri = 'Missouri',
  Montana = 'Montana',
  Nebraska = 'Nebraska',
  Nevada = 'Nevada',
  New_Hampshire = 'New Hampshire',
  New_Jersey = 'New Jersey',
  New_Mexico = 'New Mexico',
  New_York = 'New York',
  North_Carolina = 'North Carolina',
  North_Dakota = 'North Dakota',
  Northern_Mariana_Islands = 'Northern Mariana Islands',
  Ohio = 'Ohio',
  Oklahoma = 'Oklahoma',
  Oregon = 'Oregon',
  Palau = 'Palau',
  Pennsylvania = 'Pennsylvania',
  Puerto_Rico = 'Puerto Rico',
  Rhode_Island = 'Rhode Island',
  South_Carolina = 'South Carolina',
  South_Dakota = 'South Dakota',
  Tennessee = 'Tennessee',
  Texas = 'Texas',
  Utah = 'Utah',
  Vermont = 'Vermont',
  Virgin_Islands = 'Virgin Islands',
  Virginia = 'Virginia',
  Washington = 'Washington',
  West_Virginia = 'West Virginia',
  Wisconsin = 'Wisconsin',
  Wyoming = 'Wyoming',
  Armed_Forces_Americas = 'Armed Forces Americas (except Canada)',
  Armed_Forces_Europe = 'Armed Forces Europe, Africa, The Middle East and Canada',
  Armed_Forces_Pacific = 'Armed Forces Pacific',
}

export enum StreetDirection {
  N = 'N',
  S = 'S',
  E = 'E',
  W = 'W',
  NE = 'NE',
  NW = 'NW',
  SE = 'SE',
  SW = 'SW',
}

export enum GeocodeStreetDirection {
  N = 'N',
  S = 'S',
  E = 'E',
  W = 'W',
  NE = 'NE',
  NW = 'NW',
  SE = 'SE',
  SW = 'SW',
  O = 'O',
  NO = 'NO',
  SO = 'SO',
}

export const geocodeStreetDirectionToStreetDirection: Record<
  GeocodeStreetDirection,
  StreetDirection
> = {
  [GeocodeStreetDirection.N]: StreetDirection.N,
  [GeocodeStreetDirection.S]: StreetDirection.S,
  [GeocodeStreetDirection.E]: StreetDirection.E,
  [GeocodeStreetDirection.W]: StreetDirection.W,
  [GeocodeStreetDirection.NE]: StreetDirection.NE,
  [GeocodeStreetDirection.NW]: StreetDirection.NW,
  [GeocodeStreetDirection.SE]: StreetDirection.SE,
  [GeocodeStreetDirection.SW]: StreetDirection.SW,
  [GeocodeStreetDirection.O]: StreetDirection.W,
  [GeocodeStreetDirection.NO]: StreetDirection.NW,
  [GeocodeStreetDirection.SO]: StreetDirection.SW,
};

export enum StreetType {
  ABBEY = 'Abbey',
  ACRES = 'Acres',
  ALLEE = 'Allée',
  ALLEY = 'Alley',
  ANNEX = 'Annex',
  ARCADE = 'Arcade',
  AUTOROUTE = 'Autoroute',
  AVENUE = 'Avenue',
  BAY = 'Bay',
  BAYOU = 'Bayou',
  BEACH = 'Beach',
  BEND = 'Bend',
  BLUFF = 'Bluff',
  BLUFFS = 'Bluffs',
  BOTTOM = 'Bottom',
  BOULEVARD = 'Boulevard',
  BRANCH = 'Branch',
  BRIDGE = 'Bridge',
  BROOK = 'Brook',
  BROOKS = 'Brooks',
  BURG = 'Burg',
  BURGS = 'Burgs',
  BY_PASS = 'By-Pass',
  BYPASS = 'Bypass',
  BYWAY = 'Byway',
  CAMP = 'Camp',
  CAMPUS = 'Campus',
  CANYON = 'Canyon',
  CAPE = 'Cape',
  CARRE = 'Carré',
  CARREFOUR = 'Carrefour',
  CAUSEWAY = 'Causeway',
  CENTER = 'Center',
  CENTERS = 'Centers',
  CENTRE = 'Centre',
  CERCLE = 'Cercle',
  CHASE = 'Chase',
  CHEMIN = 'Chemin',
  CIRCLE = 'Circle',
  CIRCLES = 'Circles',
  CIRCUIT = 'Circuit',
  CLIFF = 'Cliff',
  CLIFFS = 'Cliffs',
  CLOSE = 'Close',
  CLUB = 'Club',
  COMMON = 'Common',
  COMMONS = 'Commons',
  CONCESSION = 'Concession',
  CORNER = 'Corner',
  CORNERS = 'Corners',
  COTE = 'Côte',
  COUR = 'Cour',
  COURS = 'Cours',
  COURSE = 'Course',
  COURT = 'Court',
  COURTS = 'Courts',
  COVE = 'Cove',
  COVES = 'Coves',
  CREEK = 'Creek',
  CRESCENT = 'Crescent',
  CREST = 'Crest',
  CROISSANT = 'Croissant',
  CROSSING = 'Crossing',
  CROSSROAD = 'Crossroad',
  CROSSROADS = 'Crossroads',
  CUL_DE_SAC = 'Cul-de-sac',
  CURVE = 'Curve',
  DALE = 'Dale',
  DAM = 'Dam',
  DELL = 'Dell',
  DIVERSION = 'Diversion',
  DIVIDE = 'Divide',
  DOWNS = 'Downs',
  DRIVE = 'Drive',
  DRIVES = 'Drives',
  ECHANGEUR = 'Échangeur',
  END = 'End',
  ESPLANADE = 'Esplanade',
  ESTATE = 'Estate',
  ESTATES = 'Estates',
  EXPRESSWAY = 'Expressway',
  EXTENSION = 'Extension',
  EXTENSIONS = 'Extensions',
  FALL = 'Fall',
  FALLS = 'Falls',
  FARM = 'Farm',
  FERRY = 'Ferry',
  FIELD = 'Field',
  FIELDS = 'Fields',
  FLAT = 'Flat',
  FLATS = 'Flats',
  FORD = 'Ford',
  FORDS = 'Fords',
  FOREST = 'Forest',
  FORGE = 'Forge',
  FORGES = 'Forges',
  FORK = 'Fork',
  FORKS = 'Forks',
  FORT = 'Fort',
  FREEWAY = 'Freeway',
  FRONT = 'Front',
  GARDEN = 'Garden',
  GARDENS = 'Gardens',
  GATE = 'Gate',
  GATEWAY = 'Gateway',
  GLADE = 'Glade',
  GLEN = 'Glen',
  GLENS = 'Glens',
  GREEN = 'Green',
  GREENS = 'Greens',
  GROUNDS = 'Grounds',
  GROVE = 'Grove',
  GROVES = 'Groves',
  HARBOR = 'Harbor',
  HARBORS = 'Harbors',
  HARBOUR = 'Harbour',
  HAVEN = 'Haven',
  HEATH = 'Heath',
  HEIGHTS = 'Heights',
  HIGHLANDS = 'Highlands',
  HIGHWAY = 'Highway',
  HILL = 'Hill',
  HILLS = 'Hills',
  HOLLOW = 'Hollow',
  ILE = 'Île',
  IMPASSE = 'Impasse',
  INLET = 'Inlet',
  ISLAND = 'Island',
  ISLANDS = 'Islands',
  ISLE = 'Isle',
  JUNCTION = 'Junction',
  JUNCTIONS = 'Junctions',
  KEY = 'Key',
  KEYS = 'Keys',
  KNOLL = 'Knoll',
  KNOLLS = 'Knolls',
  LAKE = 'Lake',
  LAKES = 'Lakes',
  LAND = 'Land',
  LANDING = 'Landing',
  LANE = 'Lane',
  LIGHT = 'Light',
  LIGHTS = 'Lights',
  LIMITS = 'Limits',
  LINE = 'Line',
  LINK = 'Link',
  LOAF = 'Loaf',
  LOCK = 'Lock',
  LOCKS = 'Locks',
  LODGE = 'Lodge',
  LOOKOUT = 'Lookout',
  LOOP = 'Loop',
  MALL = 'Mall',
  MANOR = 'Manor',
  MANORS = 'Manors',
  MAZE = 'Maze',
  MEADOW = 'Meadow',
  MEADOWS = 'Meadows',
  MEWS = 'Mews',
  MILL = 'Mill',
  MILLS = 'Mills',
  MISSION = 'Mission',
  MONTEE = 'Montée',
  MOOR = 'Moor',
  MOTORWAY = 'Motorway',
  MOUNT = 'Mount',
  MOUNTAIN = 'Mountain',
  MOUNTAINS = 'Mountains',
  NECK = 'Neck',
  ORCHARD = 'Orchard',
  PARADE = 'Parade',
  PARC = 'Parc',
  PARK = 'Park',
  PARKS = 'Parks',
  PARKWAY = 'Parkway',
  PARKWAYS = 'Parkways',
  PASS = 'Pass',
  PASSAGE = 'Passage',
  PATH = 'Path',
  PATHWAY = 'Pathway',
  PIKE = 'Pike',
  PINE = 'Pine',
  PINES = 'Pines',
  PLACE = 'Place',
  PLAIN = 'Plain',
  PLAINS = 'Plains',
  PLATEAU = 'Plateau',
  PLAZA = 'Plaza',
  POINT = 'Point',
  POINTE = 'Pointe',
  POINTS = 'Points',
  PORT = 'Port',
  PORTS = 'Ports',
  PRAIRIE = 'Prairie',
  PRIVATE = 'Private',
  PROMENADE = 'Promenade',
  QUAI = 'Quai',
  QUAY = 'Quay',
  RADIAL = 'Radial',
  RAMP = 'Ramp',
  RANCH = 'Ranch',
  RANG = 'Rang',
  RANGE = 'Range',
  RAPID = 'Rapid',
  RAPIDS = 'Rapids',
  REST = 'Rest',
  RIDGE = 'Ridge',
  RIDGES = 'Ridges',
  RISE = 'Rise',
  RIVER = 'River',
  ROAD = 'Road',
  ROADS = 'Roads',
  ROND_POINT = 'Rond-point',
  ROUTE = 'Route',
  ROW = 'Row',
  RUE = 'Rue',
  RUELLE = 'Ruelle',
  RUN = 'Run',
  SENTIER = 'Sentier',
  SHOAL = 'Shoal',
  SHOALS = 'Shoals',
  SHORE = 'Shore',
  SHORES = 'Shores',
  SKYWAY = 'Skyway',
  SPRING = 'Spring',
  SPRINGS = 'Springs',
  SPUR = 'Spur',
  SPURS = 'Spurs',
  SQUARE = 'Square',
  SQUARES = 'Squares',
  STATION = 'Station',
  STRAVENUE = 'Stravenue',
  STREAM = 'Stream',
  STREET = 'Street',
  STREETS = 'Streets',
  SUBDIVISION = 'Subdivision',
  SUMMIT = 'Summit',
  TERRACE = 'Terrace',
  TERRASSE = 'Terrasse',
  THICKET = 'Thicket',
  THROUGHWAY = 'Throughway',
  TOWERS = 'Towers',
  TOWNLINE = 'Townline',
  TRACE = 'Trace',
  TRACK = 'Track',
  TRAFFICWAY = 'Trafficway',
  TRAIL = 'Trail',
  TRAILER = 'Trailer',
  TUNNEL = 'Tunnel',
  TURNABOUT = 'Turnabout',
  TURNPIKE = 'Turnpike',
  UNDERPASS = 'Underpass',
  UNION = 'Union',
  UNIONS = 'Unions',
  VALE = 'Vale',
  VALLEY = 'Valley',
  VALLEYS = 'Valleys',
  VIA = 'Via',
  VIADUCT = 'Viaduct',
  VIEW = 'View',
  VIEWS = 'Views',
  VILLAGE = 'Village',
  VILLAGES = 'Villages',
  VILLAS = 'Villas',
  VILLE = 'Ville',
  VISTA = 'Vista',
  VOIE = 'Voie',
  WALK = 'Walk',
  WALKS = 'Walks',
  WALL = 'Wall',
  WAY = 'Way',
  WAYS = 'Ways',
  WELL = 'Well',
  WELLS = 'Wells',
  WHARF = 'Wharf',
  WOOD = 'Wood',
  WYND = 'Wynd',
}

export enum GeocodeStreetType {
  ABBEY = 'ABBEY',
  ACRES = 'ACRES',
  ALLÉE = 'ALLÉE',
  ALLEY = 'ALLEY',
  AUT = 'AUT',
  AV = 'AV',
  AVE = 'AVE',
  BAY = 'BAY',
  BEACH = 'BEACH',
  BEND = 'BEND',
  BLVD = 'BLVD',
  BD = 'BD',
  BOULEVARD = 'BOULEVARD',
  BOUL = 'BOUL',
  BYPASS = 'BYPASS',
  BYWAY = 'BYWAY',
  C = 'C',
  CAMPUS = 'CAMPUS',
  CAPE = 'CAPE',
  CAR = 'CAR',
  CARREF = 'CARREF',
  CDS = 'CDS',
  CERCLE = 'CERCLE',
  CH = 'CH',
  CHASE = 'CHASE',
  CIR = 'CIR',
  CIRCT = 'CIRCT',
  CLOSE = 'CLOSE',
  COMMON = 'COMMON',
  CONC = 'CONC',
  CÔTE = 'CÔTE',
  COUR = 'COUR',
  COURS = 'COURS',
  COVE = 'COVE',
  CRES = 'CRES',
  CRNRS = 'CRNRS',
  CROIS = 'CROIS',
  CROSS = 'CROSS',
  CRT = 'CRT',
  CTR = 'CTR',
  DALE = 'DALE',
  DELL = 'DELL',
  DIVERS = 'DIVERS',
  DOWNS = 'DOWNS',
  DR = 'DR',
  ÉCH = 'ÉCH',
  END = 'END',
  ESPL = 'ESPL',
  ESTATE = 'ESTATE',
  EXPY = 'EXPY',
  EXTEN = 'EXTEN',
  FARM = 'FARM',
  FIELD = 'FIELD',
  FOREST = 'FOREST',
  FRONT = 'FRONT',
  FWY = 'FWY',
  GATE = 'GATE',
  GDNS = 'GDNS',
  GLADE = 'GLADE',
  GLEN = 'GLEN',
  GREEN = 'GREEN',
  GRNDS = 'GRNDS',
  GROVE = 'GROVE',
  HARBR = 'HARBR',
  HEATH = 'HEATH',
  HGHLDS = 'HGHLDS',
  HILL = 'HILL',
  HOLLOW = 'HOLLOW',
  HTS = 'HTS',
  HWY = 'HWY',
  ÎLE = 'ÎLE',
  IMP = 'IMP',
  INLET = 'INLET',
  ISLAND = 'ISLAND',
  KEY = 'KEY',
  KNOLL = 'KNOLL',
  LANDING = 'LANDING',
  LANE = 'LANE',
  LINE = 'LINE',
  LKOUT = 'LKOUT',
  LMTS = 'LMTS',
  LOOP = 'LOOP',
  MALL = 'MALL',
  MANOR = 'MANOR',
  MAZE = 'MAZE',
  MEADOW = 'MEADOW',
  MEWS = 'MEWS',
  MOOR = 'MOOR',
  MOUNT = 'MOUNT',
  MTN = 'MTN',
  ORCH = 'ORCH',
  PARADE = 'PARADE',
  PARC = 'PARC',
  PASS = 'PASS',
  PATH = 'PATH',
  PINES = 'PINES',
  PK = 'PK',
  PKY = 'PKY',
  PL = 'PL',
  PLACE = 'PLACE',
  PLAT = 'PLAT',
  PLAZA = 'PLAZA',
  POINT = 'POINT',
  POINTE = 'POINTE',
  PORT = 'PORT',
  PROM = 'PROM',
  PT = 'PT',
  PTWAY = 'PTWAY',
  PVT = 'PVT',
  QUAI = 'QUAI',
  QUAY = 'QUAY',
  RAMP = 'RAMP',
  RANG = 'RANG',
  RD = 'RD',
  RDPT = 'RDPT',
  RG = 'RG',
  RIDGE = 'RIDGE',
  RISE = 'RISE',
  RLE = 'RLE',
  ROW = 'ROW',
  RTE = 'RTE',
  RUE = 'RUE',
  RUN = 'RUN',
  SENT = 'SENT',
  SQ = 'SQ',
  ST = 'ST',
  SUBDIV = 'SUBDIV',
  TERR = 'TERR',
  THICK = 'THICK',
  TLINE = 'TLINE',
  TOWERS = 'TOWERS',
  TRAIL = 'TRAIL',
  TRNABT = 'TRNABT',
  TSSE = 'TSSE',
  VALE = 'VALE',
  VIA = 'VIA',
  VIEW = 'VIEW',
  VILLAS = 'VILLAS',
  VILLGE = 'VILLGE',
  VISTA = 'VISTA',
  VOIE = 'VOIE',
  WALK = 'WALK',
  WAY = 'WAY',
  WHARF = 'WHARF',
  WOOD = 'WOOD',
  WYND = 'WYND',
}

export const geocodeStreetTypeToStreetType: Record<GeocodeStreetType, StreetType> = {
  [GeocodeStreetType.ABBEY]: StreetType.ABBEY,
  [GeocodeStreetType.ACRES]: StreetType.ACRES,
  [GeocodeStreetType.ALLÉE]: StreetType.ALLEE,
  [GeocodeStreetType.ALLEY]: StreetType.ALLEY,
  [GeocodeStreetType.AUT]: StreetType.AUTOROUTE,
  [GeocodeStreetType.AVE]: StreetType.AVENUE,
  [GeocodeStreetType.AV]: StreetType.AVENUE,
  [GeocodeStreetType.BAY]: StreetType.BAY,
  [GeocodeStreetType.BEACH]: StreetType.BEACH,
  [GeocodeStreetType.BEND]: StreetType.BEND,
  [GeocodeStreetType.BLVD]: StreetType.BOULEVARD,
  [GeocodeStreetType.BD]: StreetType.BOULEVARD,
  [GeocodeStreetType.BOULEVARD]: StreetType.BOULEVARD,
  [GeocodeStreetType.BOUL]: StreetType.BOULEVARD,
  [GeocodeStreetType.BYPASS]: StreetType.BYPASS,
  [GeocodeStreetType.BYWAY]: StreetType.BYWAY,
  [GeocodeStreetType.C]: StreetType.COURT,
  [GeocodeStreetType.CAMPUS]: StreetType.CAMPUS,
  [GeocodeStreetType.CAPE]: StreetType.CAPE,
  [GeocodeStreetType.CAR]: StreetType.CARRE,
  [GeocodeStreetType.CARREF]: StreetType.CARREFOUR,
  [GeocodeStreetType.CDS]: StreetType.COUR,
  [GeocodeStreetType.CERCLE]: StreetType.CERCLE,
  [GeocodeStreetType.CH]: StreetType.CHEMIN,
  [GeocodeStreetType.CHASE]: StreetType.CHASE,
  [GeocodeStreetType.CIR]: StreetType.CIRCLE,
  [GeocodeStreetType.CIRCT]: StreetType.CIRCUIT,
  [GeocodeStreetType.CLOSE]: StreetType.CLOSE,
  [GeocodeStreetType.COMMON]: StreetType.COMMON,
  [GeocodeStreetType.CONC]: StreetType.CONCESSION,
  [GeocodeStreetType.CÔTE]: StreetType.COTE,
  [GeocodeStreetType.COUR]: StreetType.COUR,
  [GeocodeStreetType.COURS]: StreetType.COURS,
  [GeocodeStreetType.COVE]: StreetType.COVE,
  [GeocodeStreetType.CRES]: StreetType.CRESCENT,
  [GeocodeStreetType.CRNRS]: StreetType.CORNERS,
  [GeocodeStreetType.CROIS]: StreetType.CROISSANT,
  [GeocodeStreetType.CROSS]: StreetType.CROSSING,
  [GeocodeStreetType.CRT]: StreetType.COURT,
  [GeocodeStreetType.CTR]: StreetType.CENTRE,
  [GeocodeStreetType.DALE]: StreetType.DALE,
  [GeocodeStreetType.DELL]: StreetType.DELL,
  [GeocodeStreetType.DIVERS]: StreetType.DIVERSION,
  [GeocodeStreetType.DOWNS]: StreetType.DOWNS,
  [GeocodeStreetType.DR]: StreetType.DRIVE,
  [GeocodeStreetType.ÉCH]: StreetType.ECHANGEUR,
  [GeocodeStreetType.END]: StreetType.END,
  [GeocodeStreetType.ESPL]: StreetType.ESPLANADE,
  [GeocodeStreetType.ESTATE]: StreetType.ESTATE,
  [GeocodeStreetType.EXPY]: StreetType.EXPRESSWAY,
  [GeocodeStreetType.EXTEN]: StreetType.EXTENSION,
  [GeocodeStreetType.FARM]: StreetType.FARM,
  [GeocodeStreetType.FIELD]: StreetType.FIELD,
  [GeocodeStreetType.FOREST]: StreetType.FOREST,
  [GeocodeStreetType.FRONT]: StreetType.FRONT,
  [GeocodeStreetType.FWY]: StreetType.FREEWAY,
  [GeocodeStreetType.GATE]: StreetType.GATE,
  [GeocodeStreetType.GDNS]: StreetType.GARDENS,
  [GeocodeStreetType.GLADE]: StreetType.GLADE,
  [GeocodeStreetType.GLEN]: StreetType.GLEN,
  [GeocodeStreetType.GREEN]: StreetType.GREEN,
  [GeocodeStreetType.GRNDS]: StreetType.GROUNDS,
  [GeocodeStreetType.GROVE]: StreetType.GROVE,
  [GeocodeStreetType.HARBR]: StreetType.HARBOUR,
  [GeocodeStreetType.HEATH]: StreetType.HEATH,
  [GeocodeStreetType.HGHLDS]: StreetType.HIGHLANDS,
  [GeocodeStreetType.HILL]: StreetType.HILL,
  [GeocodeStreetType.HOLLOW]: StreetType.HOLLOW,
  [GeocodeStreetType.HTS]: StreetType.HEIGHTS,
  [GeocodeStreetType.HWY]: StreetType.HIGHWAY,
  [GeocodeStreetType.ÎLE]: StreetType.ILE,
  [GeocodeStreetType.IMP]: StreetType.IMPASSE,
  [GeocodeStreetType.INLET]: StreetType.INLET,
  [GeocodeStreetType.ISLAND]: StreetType.ISLAND,
  [GeocodeStreetType.KEY]: StreetType.KEY,
  [GeocodeStreetType.KNOLL]: StreetType.KNOLL,
  [GeocodeStreetType.LANDING]: StreetType.LANDING,
  [GeocodeStreetType.LANE]: StreetType.LANE,
  [GeocodeStreetType.LINE]: StreetType.LINE,
  [GeocodeStreetType.LKOUT]: StreetType.LOOKOUT,
  [GeocodeStreetType.LMTS]: StreetType.LIMITS,
  [GeocodeStreetType.LOOP]: StreetType.LOOP,
  [GeocodeStreetType.MALL]: StreetType.MALL,
  [GeocodeStreetType.MANOR]: StreetType.MANOR,
  [GeocodeStreetType.MAZE]: StreetType.MAZE,
  [GeocodeStreetType.MEADOW]: StreetType.MEADOW,
  [GeocodeStreetType.MEWS]: StreetType.MEWS,
  [GeocodeStreetType.MOOR]: StreetType.MOOR,
  [GeocodeStreetType.MOUNT]: StreetType.MOUNT,
  [GeocodeStreetType.MTN]: StreetType.MOUNTAIN,
  [GeocodeStreetType.ORCH]: StreetType.ORCHARD,
  [GeocodeStreetType.PARADE]: StreetType.PARADE,
  [GeocodeStreetType.PARC]: StreetType.PARC,
  [GeocodeStreetType.PASS]: StreetType.PASS,
  [GeocodeStreetType.PATH]: StreetType.PATH,
  [GeocodeStreetType.PINES]: StreetType.PINES,
  [GeocodeStreetType.PK]: StreetType.PARK,
  [GeocodeStreetType.PKY]: StreetType.PARKWAY,
  [GeocodeStreetType.PL]: StreetType.PLACE,
  [GeocodeStreetType.PLACE]: StreetType.PLACE,
  [GeocodeStreetType.PLAT]: StreetType.PLATEAU,
  [GeocodeStreetType.PLAZA]: StreetType.PLAZA,
  [GeocodeStreetType.POINT]: StreetType.POINT,
  [GeocodeStreetType.POINTE]: StreetType.POINTE,
  [GeocodeStreetType.PORT]: StreetType.PORT,
  [GeocodeStreetType.PROM]: StreetType.PROMENADE,
  [GeocodeStreetType.PT]: StreetType.PATH,
  [GeocodeStreetType.PTWAY]: StreetType.PATHWAY,
  [GeocodeStreetType.PVT]: StreetType.PRIVATE,
  [GeocodeStreetType.QUAI]: StreetType.QUAI,
  [GeocodeStreetType.QUAY]: StreetType.QUAY,
  [GeocodeStreetType.RAMP]: StreetType.RAMP,
  [GeocodeStreetType.RANG]: StreetType.RANG,
  [GeocodeStreetType.RD]: StreetType.ROAD,
  [GeocodeStreetType.RDPT]: StreetType.ROND_POINT,
  [GeocodeStreetType.RG]: StreetType.RANGE,
  [GeocodeStreetType.RIDGE]: StreetType.RIDGE,
  [GeocodeStreetType.RISE]: StreetType.RISE,
  [GeocodeStreetType.RLE]: StreetType.RUELLE,
  [GeocodeStreetType.ROW]: StreetType.ROW,
  [GeocodeStreetType.RTE]: StreetType.ROUTE,
  [GeocodeStreetType.RUE]: StreetType.RUE,
  [GeocodeStreetType.RUN]: StreetType.RUN,
  [GeocodeStreetType.SENT]: StreetType.SENTIER,
  [GeocodeStreetType.SQ]: StreetType.SQUARE,
  [GeocodeStreetType.ST]: StreetType.STREET,
  [GeocodeStreetType.SUBDIV]: StreetType.SUBDIVISION,
  [GeocodeStreetType.TERR]: StreetType.TERRACE,
  [GeocodeStreetType.THICK]: StreetType.THICKET,
  [GeocodeStreetType.TLINE]: StreetType.TOWNLINE,
  [GeocodeStreetType.TOWERS]: StreetType.TOWERS,
  [GeocodeStreetType.TRAIL]: StreetType.TRAIL,
  [GeocodeStreetType.TRNABT]: StreetType.TURNABOUT,
  [GeocodeStreetType.TSSE]: StreetType.TERRASSE,
  [GeocodeStreetType.VALE]: StreetType.VALE,
  [GeocodeStreetType.VIA]: StreetType.VIA,
  [GeocodeStreetType.VIEW]: StreetType.VIEW,
  [GeocodeStreetType.VILLAS]: StreetType.VILLAS,
  [GeocodeStreetType.VILLGE]: StreetType.VILLAGE,
  [GeocodeStreetType.VISTA]: StreetType.VISTA,
  [GeocodeStreetType.VOIE]: StreetType.VOIE,
  [GeocodeStreetType.WALK]: StreetType.WALK,
  [GeocodeStreetType.WAY]: StreetType.WAY,
  [GeocodeStreetType.WHARF]: StreetType.WHARF,
  [GeocodeStreetType.WOOD]: StreetType.WOOD,
  [GeocodeStreetType.WYND]: StreetType.WYND,
};

export enum ConformingType {
  CONFORMING = 'CONFORMING',
  NONCONFORMING = 'NONCONFORMING',
}

export enum Country {
  CANADA = 'Canada',
  UNITED_STATES = 'United States',
}

export enum RentalOffsetType {
  NONE = 'NONE',
  REDUCE_RENTAL_EXPENSES = 'REDUCE_RENTAL_EXPENSES',
  ADD_PERCENTAGE_TO_GROSS_INCOME = 'ADD_PERCENTAGE_TO_GROSS_INCOME',
}

export enum FinancialLiabilitySource {
  POS_BROKER = 'POS_BROKER',
  POS_CREDIT_BUREAU = 'POS_CREDIT_BUREAU',
  CREDIT_BUREAU = 'CREDIT_BUREAU',
  EQUIFAX = 'EQUIFAX',
  MANUAL = 'MANUAL',
  MORTGAGE_APPLICATION = 'MORTGAGE_APPLICATION',
  IDS_BANKING = 'IDS_BANKING',
}

export enum GentaiTiers {
  Tier1 = 1,
  Tier2 = 2,
  Tier3 = 3,
  None = 0,
}

export enum ApplicationStatusType {
  APPROVED = 'APPROVED',
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  RE_ADJUDICATION = 'RE_ADJUDICATION',
}

export enum InsuranceStatus {
  APPROVED = 'APPROVED',
  CANCEL_CONFIRMATION = 'CANCEL_CONFIRMATION',
  DECLINED = 'DECLINED',
  ERROR = 'ERROR',
  MANUAL_UNDER_WRITING_REQUIRED = 'MANUAL_UNDER_WRITING_REQUIRED',
  RECEIVED = 'RECEIVED',
  REFERRED_TO_UNDERWRITER = 'REFERRED_TO_UNDERWRITER',
  SUBMITTED = 'SUBMITTED',
  INSURABLE = 'INSURABLE',
  NON_INSURABLE = 'NON-INSUR.',
  FINALIZED = 'FINALIZED',
}

export enum InsurancePremiumProgram {
  CG_STANDARD_PREMIUMS = 'CG_STANDARD_PREMIUMS',
  CG_FLEX_95_ADVANTAGE = 'CG_FLEX_95_ADVANTAGE',
  CG_LOW_DOC_ADVANTAGE = 'CG_LOW_DOC_ADVANTAGE',
  CG_RENTAL_ADVANTAGE = 'CG_RENTAL_ADVANTAGE',
  CG_LOW_RATIO_UNINSURED = 'CG_LOW_RATIO_UNINSURED',
  SAGEN_STANDARD_PREMIUMS = 'SAGEN_STANDARD_PREMIUMS',
  SAGEN_BUSINESS_FOR_SELF_ALT_A = 'SAGEN_BUSINESS_FOR_SELF_ALT_A',
  SAGEN_BORROWED_DOWN_PAYMENT = 'SAGEN_BORROWED_DOWN_PAYMENT',
  SAGEN_VACATION_SECONDARY_HOME_TYPE_B = 'SAGEN_VACATION_SECONDARY_HOME_TYPE_B',
  SAGEN_INVESTMENT_PROPERTY = 'SAGEN_INVESTMENT_PROPERTY',
  SAGEN_LOW_RATIO_UNINSURED = 'SAGEN_LOW_RATIO_UNINSURED',
  CMHC_STANDARD_PREMIUMS = 'CMHC_STANDARD_PREMIUMS',
  CMHC_LOW_RATIO_UNINSURED = 'CMHC_LOW_RATIO_UNINSURED',
}

export enum InsurerStatusUI {
  APPROVED = 'APPROVED',
  PENDING = 'PENDING',
  DECLINED = 'DECLINED',
  ERROR = 'ERROR',
}

export enum FinancialLiabilityRecordTypeState {
  CURRENT = 'current',
  PREVIOUS = 'previous',
}

export enum MortgagePosition {
  FIRST = 'FIRST',
  SECOND = 'SECOND',
  THIRD = 'THIRD',
  UNKNOWN = 'UNKNOWN',
}

export enum TermType {
  OPEN = 'OPEN',
  CLOSED = 'CLOSED',
  CONVERTIBLE = 'CONVERTIBLE',
}

export enum TermMonths {
  ONE_YEAR = 'ONE_YEAR',
  TWO_YEARS = 'TWO_YEARS',
  THREE_YEARS = 'THREE_YEARS',
  FOUR_YEARS = 'FOUR_YEARS',
  FIVE_YEARS = 'FIVE_YEARS',
}

export enum GlobalMatchStrings {
  ALL_STAGES = 'All Stages',
}

export enum DwellingType {
  DETACHED = 'DETACHED',
  SEMI_DETACHED = 'SEMI_DETACHED',
  DUPLEX_DETACHED = 'DUPLEX_DETACHED',
  DUPLEX_SEMI_DETACHED = 'DUPLEX_SEMI_DETACHED',
  DUPLEX = 'DUPLEX',
  ROW_HOUSING = 'ROW_HOUSING',
  APARTMENT_LOW_RISE = 'APARTMENT_LOW_RISE',
  APARTMENT_HIGH_RISE = 'APARTMENT_HIGH_RISE',
  APARTMENT = 'APARTMENT',
  TOWNHOUSE = 'TOWNHOUSE',
  STRIP = 'STRIP',
  HIGH_RISE = 'HIGH_RISE',
  STACKED = 'STACKED',
  MOBILE = 'MOBILE',
  TRIPLEX_DETACHED = 'TRIPLEX_DETACHED',
  TRIPLEX_SEMI_DETACHED = 'TRIPLEX_SEMI_DETACHED',
  TRIPLEX = 'TRIPLEX',
  FOURPLEX_DETACHED = 'FOURPLEX_DETACHED',
  FOURPLEX_SEMI_DETACHED = 'FOURPLEX_SEMI_DETACHED',
  FOURPLEX = 'FOURPLEX',
  FIVEPLEX = 'FIVEPLEX',
  SIXPLEX = 'SIXPLEX',
  MODULAR_HOME_DETACHED = 'MODULAR_HOME_DETACHED',
  MODULAR_HOME_SEMI_DETACHED = 'MODULAR_HOME_SEMI_DETACHED',
  MODULAR_HOME = 'MODULAR_HOME',
  COOP = 'COOP',
  SINGLE = 'SINGLE',
  CONDOMINIUM = 'CONDOMINIUM',
  OTHER = 'OTHER',
}

export enum DwellingStyle {
  ONE_STORY = 'ONE_STORY',
  BI_LEVEL = 'BI_LEVEL',
  TWO_STORY = 'TWO_STORY',
  SPLIT_LEVEL = 'SPLIT_LEVEL',
  ONE_AND_ONE_HALF_STORY = 'ONE_AND_ONE_HALF_STORY',
  THREE_STORY = 'THREE_STORY',
  OTHER = 'OTHER',
}

export enum GarageType {
  NONE = 'NONE',
  ATTACHED_GARAGE = 'ATTACHED_GARAGE',
  BUILT_IN_GARAGE = 'BUILT_IN_GARAGE',
  BASEMENT_GARAGE = 'BASEMENT_GARAGE',
  DETACHED_GARAGE = 'DETACHED_GARAGE',
}

export enum TaxesPaidByType {
  BORROWER = 'BORROWER',
  LENDER = 'LENDER',
  PAID_WITH_ANOTHER_MORTGAGE = 'PAID_WITH_ANOTHER_MORTGAGE',
}

export enum CanadianProvinceCode {
  AB = 'AB',
  BC = 'BC',
  MB = 'MB',
  NB = 'NB',
  NL = 'NL',
  NT = 'NT',
  NS = 'NS',
  NU = 'NU',
  ON = 'ON',
  PE = 'PE',
  QC = 'QC',
  SK = 'SK',
  YT = 'YT',
}
export enum PayoffType {
  PRIOR_TO_ADVANCE = 'PRIOR_TO_ADVANCE',
  FROM_PROCEEDS = 'FROM_PROCEEDS',
  NONE = 'NONE',
}

export enum PayoffPaydownType {
  PAYOFF = 'PAYOFF',
  PAYDOWN = 'PAYDOWN',
  PAYDOWN_INCREASE_LIMIT = 'PAYDOWN_INCREASE_LIMIT',
  PAYDOWN_REDUCE_LIMIT = 'PAYDOWN_REDUCE_LIMIT',
  NONE = 'NONE',
}

export enum USStateCode {
  AA = 'AA',
  AL = 'AL',
  AK = 'AK',
  AZ = 'AZ',
  AR = 'AR',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DE = 'DE',
  DC = 'DC',
  FM = 'FM',
  FL = 'FL',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  IA = 'IA',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  ME = 'ME',
  MH = 'MH',
  MD = 'MD',
  MA = 'MA',
  MI = 'MI',
  MN = 'MN',
  MS = 'MS',
  MO = 'MO',
  MT = 'MT',
  NE = 'NE',
  NV = 'NV',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NY = 'NY',
  NC = 'NC',
  ND = 'ND',
  MP = 'MP',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PW = 'PW',
  PA = 'PA',
  PR = 'PR',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VT = 'VT',
  VI = 'VI',
  VA = 'VA',
  WA = 'WA',
  WV = 'WV',
  WI = 'WI',
  WY = 'WY',
}

export enum LiabilityType {
  CREDIT = 'CREDIT',
  PERSONAL_LOAN = 'PERSONAL_LOAN',
  AUTO_LOAN = 'AUTO_LOAN',
  ALIMONY = 'ALIMONY',
  CHILD_SUPPORT = 'CHILD_SUPPORT',
  STUDENT_LOAN = 'STUDENT_LOAN',
  WAGE_GARNISHMENT = 'WAGE_GARNISHMENT',
  UNSECURED_LINE_OF_CREDIT = 'UNSECURED_LINE_OF_CREDIT',
  INCOME_TAX = 'INCOME_TAX',
  SECURED_LINE_OF_CREDIT = 'SECURED_LINE_OF_CREDIT',
  LEASE = 'LEASE',
  AUTO_LEASE = 'AUTO_LEASE',
  MORTGAGE = 'MORTGAGE',
  JUDGMENT = 'JUDGMENT',
  OVERDUE_TAXES = 'OVERDUE_TAXES',
  CREDIT_CARD = 'CREDIT_CARD',
  RENT = 'RENT',
  PROPERTY_FIRE_INSURANCE = 'PROPERTY_FIRE_INSURANCE',
  PROPERTY_REPAIRS = 'PROPERTY_REPAIRS',
  PROPERTY_HYDRO = 'PROPERTY_HYDRO',
  PROPERTY_MANAGEMENT = 'PROPERTY_MANAGEMENT',
  PROPERTY_GENERAL = 'PROPERTY_GENERAL',
  PROPERTY_INTEREST = 'PROPERTY_INTEREST',
  OTHER = 'OTHER',
}

export enum DownPaymentType {
  SALE_OF_EXISTING_PROPERTY = 'SALE_OF_EXISTING_PROPERTY',
  PERSONAL_CASH = 'PERSONAL_CASH',
  RRSP = 'RRSP',
  SELF_DIRECTED_RRSP = 'SELF_DIRECTED_RRSP',
  BORROWED_AGAINST_LIQUID_ASSETS = 'BORROWED_AGAINST_LIQUID_ASSETS',
  GIFT = 'GIFT',
  SWEAT_EQUITY = 'SWEAT_EQUITY',
  EXISTING_EQUITY = 'EXISTING_EQUITY',
  SECONDARY_FINANCING = 'SECONDARY_FINANCING',
  GRANTS = 'GRANTS',
  LOAN = 'LOAN',
  RENT = 'RENT',
  OTHER = 'OTHER',
}

export enum TenureType {
  FREEHOLD = 'FREEHOLD',
  LEASEHOLD = 'LEASEHOLD',
  CONDO = 'CONDO',
  OTHER = 'OTHER',
}

export enum HeatingType {
  ELECTRIC_BASEBOARD = 'ELECTRIC_BASEBOARD',
  FORCED_AIR_GAS_OIL_ELECTRIC = 'FORCED_AIR_GAS_OIL_ELECTRIC',
  HOT_WATER_HEATING = 'HOT_WATER_HEATING',
  GEOTHERMAL = 'GEOTHERMAL',
  OIL = 'OIL',
  OTHER = 'OTHER',
}

export enum WaterType {
  MUNICIPAL = 'MUNICIPAL',
  CISTERN = 'CISTERN',
  WELL = 'WELL',
  NON = 'NON',
  PRIVATE = 'PRIVATE',
  OTHER = 'OTHER',
}

export enum ConstructionType {
  NEW = 'NEW',
  EXISTING = 'EXISTING',
}

export enum OccupancyType {
  OWNER_OCCUPIED = 'OWNER_OCCUPIED',
  OWNER_OCCUPIED_RENTAL = 'OWNER_OCCUPIED_RENTAL',
  RENTAL = 'RENTAL',
  SECOND_HOME = 'SECOND_HOME',
  LIVE_WITH_PARENT = 'LIVE_WITH_PARENT',
  OTHER = 'OTHER',
}

export enum CustomEQOccupancyType {
  OWN_WORKLIVE = '5d7e4d59-f087-4573-b8d2-11a67e4067b3',
  RENTAL_WORKLIVE = '5d3aa16d-1ea4-4d36-a6ef-d249ea7d02c0',
  OWNOCCUPRENTAL_WORKLIVE = '4796e4a3-e7fc-4065-999f-9e0d7ae7a686',
}

export enum DescriptionType {
  PROPERTY = 'Property',
  RENTAL_PROPERTY = 'Rental Property',
  REAL_ESTATE = 'Real Estate',
  INVESTMENT = 'Investment',
  FURNITURES = 'Furnitures',
  JEWELRY = 'Jewelry',
}

export enum AssetType {
  VEHICLE = 'VEHICLE',
  RRSP = 'RRSP',
  STOCKS_BONDS_MUTUAL = 'STOCKS_BONDS_MUTUAL',
  STOCKS = 'STOCKS',
  MUTUAL_FUNDS = 'MUTUAL_FUNDS',
  HOUSEHOLD_GOODS = 'HOUSEHOLD_GOODS',
  CASH_SAVING = 'CASH_SAVING',
  CASH_CHEQUING = 'CASH_CHEQUING',
  SAVINGS = 'SAVINGS',
  DEPOSIT_ON_PURCHASE = 'DEPOSIT_ON_PURCHASE',
  GIC_TERM_DEPOSITS = 'GIC_TERM_DEPOSITS',
  LIFE_INSURANCE = 'LIFE_INSURANCE',
  GIFT = 'GIFT',
  SWEAT_EQUITY = 'SWEAT_EQUITY',
  PROPERTY = 'PROPERTY',
  RENTAL_PROPERTY = 'RENTAL_PROPERTY',
  OTHER = 'OTHER',
}

export enum ExcludeAssetFromCalculationReasonType {
  PAID_BY_OTHER_SOURCE = 'PAID_BY_OTHER_SOURCE',
  FINAL_PAYMENT = 'FINAL_PAYMENT',
  DUPLICATE_ITEM = 'DUPLICATE_ITEM',
  OMIT_FROM_GDS = 'OMIT_FROM_GDS',
}

export enum ExcludeLiabilityFromCalculationReasonType {
  PAID_BY_OTHER_SOURCE = 'PAID_BY_OTHER_SOURCE',
  FINAL_PAYMENT = 'FINAL_PAYMENT',
  DUPLICATE_ITEM = 'DUPLICATE_ITEM',
  OMIT_FROM_TDS = 'OMIT_FROM_TDS',
}

export enum MortgageClassificationType {
  ASSUMPTION = 'ASSUMPTION',
  BRIDGE_FINANCING_WITH_PURCHASE = 'BRIDGE_FINANCING_WITH_PURCHASE',
  BRIDGE_FINANCING_WITHOUT_PURCHASE = 'BRIDGE_FINANCING_WITHOUT_PURCHASE',
  CONSTRUCTION = 'CONSTRUCTION',
  CONSTRUCTION_EXISTING_PROPERTY = 'CONSTRUCTION_EXISTING_PROPERTY',
  CONSTRUCTION_NEW_BUILD = 'CONSTRUCTION_NEW_BUILD',
  CONSTRUCTION_TAKEOVER = 'CONSTRUCTION_TAKEOVER',
  DEFICIENCY_SALE = 'DEFICIENCY_SALE',
  ETO = 'ETO',
  FLIP = 'FLIP',
  HELOC = 'HELOC',
  PORT = 'PORT',
  PURCHASE = 'PURCHASE',
  PURCHASE_PLUS_IMPROVEMENT = 'PURCHASE_PLUS_IMPROVEMENT',
  NEW_BUILD = 'NEW_BUILD',
  PRIVATE = 'PRIVATE',
  RENT_TO_OWN = 'RENT_TO_OWN',
  PURCHASE_WITH_HELOC = 'PURCHASE_WITH_HELOC',
  REFINANCE = 'REFINANCE',
  RENEWAL = 'RENEWAL',
  REVERSE_MORTGAGE = 'REVERSE_MORTGAGE',
  SWITCH_TRANSFER_COLLATERAL = 'SWITCH_TRANSFER_COLLATERAL',
  SWITCH_TRANSFER_STANDARD = 'SWITCH_TRANSFER_STANDARD',
  OTHER = 'OTHER',
  WORKOUT = 'WORKOUT',
}

export enum FoundationType {
  BASEMENT = 'BASEMENT',
  CRAWLSPACE = 'CRAWLSPACE',
  PIER = 'PIER',
  SLAB_ON_GRADE = 'SLAB_ON_GRADE',
  WALKOUT_BASEMENT = 'WALKOUT_BASEMENT',
}

export enum LocationTierType {
  TIER_1 = 'TIER_1',
  TIER_2 = 'TIER_2',
  TIER_3 = 'TIER_3',
}

export enum LoanType {
  MORTGAGE = 'MORTGAGE',
  SECURE_LINE_OF_CREDIT = 'SECURE_LINE_OF_CREDIT',
  SECURE_LINE_OF_CREDIT_FLEX = 'SECURE_LINE_OF_CREDIT_FLEX',
  LIEN = 'LIEN',
  BRIDGE = 'BRIDGE',
}

export enum FundmoreUserType {
  Unused = 'Unused',
  Support_User = 'Support User',
  Regional_Manager = 'Regional Manager',
  Administrator = 'Administrator',
  Associate = 'Associate',
  Agent = 'Agent',
  Broker_Owner = 'Broker(Owner)',
  Firm_Admin = 'Firm Admin',
  Sys_Admin = 'Sys Admin',
  Passive = 'Passive',
  Assistant = 'Assistant',
}

export enum FundmoreProfileStatus {
  ACTIVE = 'Active',
  SUSPENDED = 'Suspended',
  DEACTIVATED = 'Deactivated',
  DISALLOWED = 'Disallowed',
  CAUTION = 'Caution',
  ON_LEAVE = 'On Leave',
}

export enum FeeType {
  COMMITMENT = 'COMMITMENT',
  BROKER = 'BROKER',
  LENDER = 'LENDER',
  ADMINISTRATOR = 'ADMINISTRATOR',
  TITLE_INSURANCE = 'TITLE_INSURANCE',
  APPRAISAL = 'APPRAISAL',
  LAND_TITLES = 'LAND_TITLES',
  LEGAL = 'LEGAL',
  OTHER = 'OTHER',
}

export enum FeePayer {
  LENDER = 'LENDER',
  BORROWER = 'BORROWER',
}

export enum DocumentManagementType {
  FUNDMORE = 'FUNDMORE',
  EZIDOX = 'EZIDOX',
  NONE = 'NONE',
}

export enum OpenDocumentsStrategy {
  NEW_TAB = 'NEW_TAB',
  NEW_WINDOW = 'NEW_WINDOW',
}

export enum OneTimeLinkGenerationStrategy {
  CACHED = 'CACHED',
  ON_DEMAND = 'ON_DEMAND',
}

export enum DeclineApplicationError {
  NOT_LODGED = 'NOT_LODGED',
}

export enum EqLoanUpdateType {
  LOAN_DETAILS_SYNC = 0,
  FULL_SYNC = 1,
  FULL_ASYNC = 2,
}

export type CustomIntegrationFields =
  | EQIntegrationFields
  | CalvertIntegrationFields
  | RMCIntegrationFields
  | MCUIntegrationFields;

export const CustomIntegrationFieldsList = [
  ...Object.values(EQIntegrationFields),
  ...Object.values(CalvertIntegrationFields),
  ...Object.values(RMCIntegrationFields),
  ...Object.values(MCUIntegrationFields),
];

export const ExpandedIntegrationFieldsDictionary = {
  [RMCIntegrationFields.COMMITMENT_DATES]: Object.values(RMCCommitmentDatesFields),
  [EQIntegrationFields.PARTNER_LOAN]: Object.values(EQPartnerLoanFields),
  [MCUIntegrationFields.PAYMENT_SOURCE]: Object.values(MCUPaymentSourceFields),
  [MCUIntegrationFields.SECURITY]: Object.values(MCUSecurityFields),
};

export enum AIFeatures {
  APPLICATION_NOTES = 'applicationNotes',
}

export enum FetchType {
  PARTIAL = 'PARTIAL',
  COMPLETE = 'COMPLETE',
}

export enum ResponsibleType {
  BROKER = 'BROKER',
  LAWYER = 'LAWYER',
}

export enum ApplicationSnapshotReason {
  FUNDED = 'FUNDED',
  DECLINED = 'DECLINED',
  CANCELLED = 'CANCELLED',
}

export enum SourceOfBusinessType {
  BROKER = 'BROKER',
  RETAIL = 'RETAIL',
  MORTGAGE_SPECIALIST = 'MORTGAGE_SPECIALIST',
  BRANCH = 'BRANCH',
  ON_LINE = 'ON_LINE',
  CALL_CENTER = 'CALL_CENTER',
  OTHER = 'OTHER',
}

export enum ComputedRatioRateType {
  NET = 'NET',
  QUALIFYING = 'QUALIFYING',
  BENCHMARK = 'BENCHMARK',
}

export enum MortgageClosingInstruction {
  EXISTING_COLLATERAL_CHARGE = 'EXISTING_COLLATERAL_CHARGE',
  FCT = 'FCT',
  SOLICITOR = 'SOLICITOR',
}

export enum SecurityType {
  MORTGAGE_SECURED = 'MORTGAGE_SECURED',
  SECURED = 'SECURED',
  UNSECURED = 'UNSECURED',
  CASH_SECURED = 'CASH_SECURED',
}

export enum ApplicationDocumentType {
  EQUIFAX_CREDIT_REPORT = 'EQUIFAX_CREDIT_REPORT',
  FCT = 'FCT',
}

export enum ApplicationCreateProductsState {
  NOT_TRIGGERED = 'NOT_TRIGGERED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}

export enum ApplicationCreditCardFulfillState {
  NOT_TRIGGERED = 'NOT_TRIGGERED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
}
