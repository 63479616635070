import { Province } from '../../enums';

export enum MMSProvince {
  ALBERTA = 'AB',
  BRITISH_COLUMBIA = 'BC',
  MANITOBA = 'MB',
  NEW_BRUNSWICK = 'NB',
  NEWFOUNDLAND_AND_LABRADOR = 'NL',
  NOVA_SCOTIA = 'NS',
  NORTHWEST_TERRITORIES = 'NT',
  NUNAVUT = 'NU',
  ONTARIO = 'ON',
  PRINCE_EDWARD_ISLAND = 'PE',
  QUEBEC = 'QC',
  SASKATCHEWAN = 'SK',
  YUKON_TERRITORY = 'YT',
}

export const mmsProvinceMappings: Record<Province, MMSProvince> = {
  [Province.Alberta]: MMSProvince.ALBERTA,
  [Province.British_Columbia]: MMSProvince.BRITISH_COLUMBIA,
  [Province.Manitoba]: MMSProvince.MANITOBA,
  [Province.New_Brunswick]: MMSProvince.NEW_BRUNSWICK,
  [Province.Newfoundland_Labrador]: MMSProvince.NEWFOUNDLAND_AND_LABRADOR,
  [Province.Northwest_Territories]: MMSProvince.NORTHWEST_TERRITORIES,
  [Province.Nova_Scotia]: MMSProvince.NOVA_SCOTIA,
  [Province.Nunavut]: MMSProvince.NUNAVUT,
  [Province.Ontario]: MMSProvince.ONTARIO,
  [Province.Prince_Edward_Island]: MMSProvince.PRINCE_EDWARD_ISLAND,
  [Province.Quebec]: MMSProvince.QUEBEC,
  [Province.Saskatchewan]: MMSProvince.SASKATCHEWAN,
  [Province.Yukon]: MMSProvince.YUKON_TERRITORY,
};
