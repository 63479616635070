import { Mortgage, Fee, AmortizationSchedule, LoanType } from '@fundmoreai/models';
import { FundmoreCalculator } from '..';

export type APRInput = Pick<
  Mortgage,
  | 'firstRegularPaymentDate'
  | 'maturityDate'
  | 'totalLoanAmount'
  | 'netRate'
  | 'paymentAmount'
  | 'paymentFrequency'
  | 'compounding'
  | 'termMonths'
  | 'amortizationMonths'
  | 'repaymentType'
  | 'loanType'
  | 'closingDate'
  | 'interestAdjustmentDate'
>;

export function computeAPR(
  requestedMortgage: APRInput | undefined,
  totalLoanAmount: number | undefined,
  fees: Fee[],
  tenantCode: string,
  multipleRequestedMortgages = false,
): number {
  if (
    !requestedMortgage ||
    !requestedMortgage.termMonths ||
    !requestedMortgage.netRate ||
    !totalLoanAmount
  ) {
    return undefined;
  }

  const amortizationSchedule = FundmoreCalculator.generateAmortizationSchedule(requestedMortgage);
  let totalRemainingPrincipal;
  const numberOfPayments = amortizationSchedule.length;
  if (numberOfPayments > 0) {
    totalRemainingPrincipal = amortizationSchedule.reduce(
      (sum, entry) => sum + (entry.balance ? entry.balance : 0.0),
      0,
    );
  }

  const totalCostOfBorrowing = getTotalCostOfBorrowing(
    amortizationSchedule,
    requestedMortgage,
    totalLoanAmount,
    fees,
    tenantCode,
    multipleRequestedMortgages,
  );

  let averageRemainingPrincipal;
  if (numberOfPayments > 0) {
    const lastBalance = amortizationSchedule[numberOfPayments - 1].balance;
    averageRemainingPrincipal =
      (totalRemainingPrincipal + totalLoanAmount - lastBalance) / numberOfPayments;
  } else {
    averageRemainingPrincipal = totalLoanAmount;
  }

  const yearsInTerm = requestedMortgage.termMonths / 12;

  const apr = (totalCostOfBorrowing / (averageRemainingPrincipal * yearsInTerm)) * 100;

  return Math.max(apr, requestedMortgage.netRate);
}

export const getTotalCostOfBorrowing = (
  amortizationSchedule: AmortizationSchedule[],
  requestedMortgage: APRInput | undefined,
  totalLoanAmount: number | undefined,
  fees: Fee[],
  tenantCode: string,
  multipleRequestedMortgages = false,
) => {
  if (
    !requestedMortgage ||
    !requestedMortgage.termMonths ||
    !requestedMortgage.netRate ||
    !totalLoanAmount
  ) {
    return undefined;
  }

  const totalInterest = getTotalInterest(
    amortizationSchedule,
    requestedMortgage,
    totalLoanAmount,
    tenantCode,
  );

  if (!multipleRequestedMortgages && requestedMortgage.loanType === LoanType.MORTGAGE) {
    const totalFees = getTotalFeesIncludedInAPR(fees);

    return totalInterest + totalFees;
  }

  return totalInterest;
};

export const getTotalInterest = (
  amortizationSchedule: AmortizationSchedule[],
  requestedMortgage: APRInput | undefined,
  totalLoanAmount: number | undefined,
  tenantCode: string,
) => {
  if (
    !requestedMortgage ||
    !requestedMortgage.termMonths ||
    !requestedMortgage.netRate ||
    !totalLoanAmount
  ) {
    return undefined;
  }

  let totalInterest;
  const numberOfPayments = amortizationSchedule.length;
  if (numberOfPayments > 0) {
    totalInterest = amortizationSchedule.reduce(
      (sum, entry) => sum + (entry.interest ? entry.interest : 0.0),
      0,
    );
  } else {
    totalInterest =
      (((totalLoanAmount * requestedMortgage.netRate) / 100) * requestedMortgage.termMonths) / 12;
  }
  if (tenantCode.toLowerCase() == 'gentai' && requestedMortgage.termMonths == 13) {
    const first12Months = (totalLoanAmount * requestedMortgage.netRate) / 100;
    const last1Month = (totalLoanAmount * 18) / 100 / 12;
    totalInterest = first12Months + last1Month;
  }

  return totalInterest;
};

export const getTotalFeesIncludedInAPR = (fees: Fee[]) => {
  const feesIncludedInApr = fees.filter((fee) => fee.includeInApr);
  const paidUpfrontFees = feesIncludedInApr.filter((f) => f.subtractFromPrincipal === null);
  const cappedFees = feesIncludedInApr.filter((f) => f.subtractFromPrincipal === false);

  let totalFees = 0;

  const paidUpfrontFeesSum = paidUpfrontFees.reduce(
    (sum, fee) => sum + (fee.amount ? fee.amount : 0.0),
    0.0,
  );
  const cappedFeesRemainderSum = cappedFees.reduce(
    (sum, fee) => sum + (fee.amount ? ((fee.capFeesPercentage ?? 100) * fee.amount) / 100.0 : 0.0),
    0.0,
  );

  totalFees += paidUpfrontFeesSum;
  totalFees += cappedFeesRemainderSum;

  return totalFees;
};
