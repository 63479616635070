import { ApplicationPurposeType, PropertyTypes, ZoningTypes } from '@fundmoreai/models';
import { MortgageClassificationOptions } from 'src/app/shared';
import { AnalysedMortgageApplicationModel, AnalysisModel } from './recognitionModel';
import { ButtonOption } from './upload.state';
import { FilogixDeals } from './uploadModel';

export interface UploadComponentStateModel {
  isAnalysing: boolean;
  isNew: boolean;
  applicationFile: Blob;
  isSelected: ButtonOption;
  fileStatus: string;
  dealStatus: string;
  error?: string;
  stepIndex: number;
  analysedApplication: Partial<AnalysedMortgageApplicationModel>;
  clonedApplicationId: string;
  mortgageClassificationOptions: MortgageClassificationOptions;
}

export class ResetUploadState {
  static readonly type = '@uploadComponent.ResetUploadState';
}

export class SetStepIndex {
  static readonly type = '@uploadComponent.SetStepIndex';
  constructor(public stepIndex: number) {}
}

export class SetApplicationFile {
  static readonly type = '@uploadComponent.SetApplicationFile';
  constructor(public applicationFile: Blob) {}
}

export class SetAnalysedApplication {
  static readonly type = '@uploadComponent.SetAnalysedApplication';
  constructor(public analysedApplication: Partial<AnalysedMortgageApplicationModel>) {}
}

export class SetApplicationPurpose {
  static readonly type = '@uploadComponent.SetApplicationPurpose';
  constructor(public applicationPurpose: ApplicationPurposeType) {}
}

export class SetPrimaryPropertyType {
  static readonly type = '@uploadComponent.SetPrimaryPropertyType';
  constructor(public propertyType: PropertyTypes) {}
}

export class SetPrimaryZoningType {
  static readonly type = '@uploadComponent.SetPrimaryZoningType';
  constructor(public zoningType: ZoningTypes) {}
}

export class SetMortgageClassificationOptions {
  static readonly type = '@uploadComponent.SetMortgageClassificationOptions';
  constructor(public applicationPurpose: ApplicationPurposeType) {}
}

export class SetMortgageClassification {
  static readonly type = '@uploadComponent.SetMortgageClassification';
  constructor(public mortgageClassification: string) {}
}

export class SetFileStatus {
  static readonly type = '@uploadComponent.SetFileStatus';
  constructor(public fileStatus: string) {}
}

export class SetIsAnalysing {
  static readonly type = '@uploadComponent.SetIsAnalysing';
  constructor(public analysingStatus: boolean) {}
}

export class SetClonedApplicationId {
  static readonly type = '@uploadComponent.SetClonedApplicationId';
  constructor(public value: string) {}
}

export class SetIsSelected {
  static readonly type = '@uploadComponent.SetIsSelected';
  constructor(public fileUploadingStatus: ButtonOption) {}
}

export class SetDealStatus {
  static readonly type = '@uploadComponent.SetDealStatus';
  constructor(public primaryApplicantFilogix: FilogixDeals) {}
}

export class PostAnalysisProcessing {
  static readonly type = '@uploadComponent.PostAnalysisProcessing';
  constructor(public analysedApplication: AnalysisModel) {}
}

export class PostAnalysisProcessingError {
  static readonly type = '@uploadComponent.PostAnalysisProcessingError';
  constructor(public error: { error: { message: Error } }) {}
}

export class AnalyseMortgageApplication {
  static readonly type = '@uploadComponent.AnalyseMortgageApplication';
}

export class ParseDeal {
  static readonly type = '@uploadComponent.ParseDeal';
  constructor(public deal: FilogixDeals) {}
}
