export function computeNetRate(
  baseRate: number | null,
  discount: number | null,
  buyDownRate: number | null,
  incrementTotal?: number | null,
  customIncrementTotal?: number | null,
  decrementTotal?: number | null,
  discretion?: number | null,
): number {
  if (!baseRate) {
    return 0;
  }

  const discounts =
    (buyDownRate ?? 0) + (discount ?? 0) + (decrementTotal ?? 0) + (discretion ?? 0);
  const rateWithIncrements = baseRate + (incrementTotal ?? 0) + (customIncrementTotal ?? 0);
  if (rateWithIncrements <= discounts) {
    return 0;
  }
  const netRate = rateWithIncrements - discounts;
  const roundedNetRate = Math.round(netRate * 100) / 100;

  return roundedNetRate;
}
