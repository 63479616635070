import { Injectable } from '@angular/core';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import {
  Applicant,
  Application,
  ApplicationPurposeType,
  NetworthContext,
} from '@fundmoreai/models';
import { createSelector, State } from '@ngxs/store';
import { FinancialLiabilityState } from 'src/app/features/application/widgets/credit/financial-liability.state';
import { ApplicantsState } from 'src/app/portal/applicants.state';
import { DownPaymentState } from 'src/app/portal/downpayments.state';
import { MortgageApplicationState } from 'src/app/portal/mortgage-application.state';
import { MortgagesV2State } from 'src/app/portal/mortgages-v2/mortgages-v2.state';
import { PropertiesState } from 'src/app/portal/properties.state';
import { FinancialAssetsState } from './financial-assets.state';

export interface CurrentNetworthCalculationDetails {
  totalAssetsValue: number;
  otherPropertiesTotalValue: number;
  subjectPropertyValue: number | null;
  totalDebt: number;
  existingMortgageAmount: number;
}

export interface FutureNetworthCalculationDetails {
  totalAssetsValue: number;
  otherPropertiesTotalValue: number;
  subjectPropertyValue: number | null;
  totalDebt: number;
  existingMortgageAmount: number;
  totalLoanAmount: number | null;
  totalDownPayment: number;
  saleProceeds: number;
}

@State({
  name: 'networth',
})
@Injectable()
export class NetworthState {
  static NAME = 'networth';

  static currentNetworth(applicantId?: string) {
    return createSelector(
      [
        MortgageApplicationState.application,
        FinancialLiabilityState.debtBalanceRegardlessOfPayoff(applicantId),
        MortgagesV2State.existingAndRefinanceMortgageAmount(applicantId),
        FinancialAssetsState.networth(applicantId),
        PropertiesState.primaryPropertyValue(applicantId),
        PropertiesState.networth(applicantId),
      ],
      (
        application: Application,
        debtBalance: number,
        existingAndRefinanceMortgageAmount: number,
        financialAssetsNetworth: number,
        primaryPropertyValue: number,
        propertiesNetworth: number,
      ): NetworthContext => {
        return FundmoreCalculator.computeCurrentNetworth(
          application.purpose,
          financialAssetsNetworth,
          propertiesNetworth,
          primaryPropertyValue,
          debtBalance,
          existingAndRefinanceMortgageAmount,
        );
      },
    );
  }

  static currentNetworthCalculationDetails(applicantId?: string) {
    return createSelector(
      [
        MortgageApplicationState.application,
        FinancialLiabilityState.debtBalanceRegardlessOfPayoff(applicantId),
        MortgagesV2State.existingAndRefinanceMortgageAmount(applicantId),
        FinancialAssetsState.networth(applicantId),
        PropertiesState.primaryPropertyValue(applicantId),
        PropertiesState.networth(applicantId),
      ],
      (
        application: Application,
        debtBalance: number,
        existingAndRefinanceMortgageAmount: number,
        financialAssetsNetworth: number,
        primaryPropertyValue: number,
        propertiesNetworth: number,
      ): CurrentNetworthCalculationDetails => {
        return {
          existingMortgageAmount: existingAndRefinanceMortgageAmount,
          otherPropertiesTotalValue: propertiesNetworth,
          subjectPropertyValue:
            application.purpose !== ApplicationPurposeType.PURCHASE ? +primaryPropertyValue : 0,
          totalAssetsValue: financialAssetsNetworth,
          totalDebt: debtBalance,
        };
      },
    );
  }

  static futureNetworth(applicantId?: string) {
    return createSelector(
      [
        ApplicantsState.applicantsListExcludingThirdParty,
        MortgageApplicationState.application,
        FinancialLiabilityState.debtBalance(applicantId),
        MortgagesV2State.existingMortgageAmount(applicantId),
        FinancialAssetsState.networth(applicantId),
        PropertiesState.primaryPropertyValue(applicantId),
        PropertiesState.futureNetworth(applicantId),
        PropertiesState.saleProceeds(applicantId),
        MortgagesV2State.totalLoanAmountForAllRequestedMortgages,
        DownPaymentState.totalDownPayment(applicantId),
      ],
      (
        applicants: Applicant[] | undefined,
        application: Application,
        debtBalance: number,
        existingMortgageAmount: number,
        financialAssetsNetworth: number,
        primaryPropertyValue: number,
        propertiesNetworth: number,
        saleProceeds: number,
        totalLoanAmountForAllRequestedMortgages: number,
        totalDownPayment: number,
      ): NetworthContext => {
        return FundmoreCalculator.computeFutureNetworth(
          application.purpose,
          financialAssetsNetworth,
          propertiesNetworth,
          saleProceeds,
          primaryPropertyValue,
          debtBalance,
          totalLoanAmountForAllRequestedMortgages,
          existingMortgageAmount,
          totalDownPayment,
          applicants?.length,
          applicantId,
        );
      },
    );
  }

  static futureNetworthCalculationDetails(applicantId?: string) {
    return createSelector(
      [
        ApplicantsState.applicantsListExcludingThirdParty,
        MortgageApplicationState.application,
        FinancialLiabilityState.debtBalance(applicantId),
        MortgagesV2State.existingMortgageAmount(applicantId),
        FinancialAssetsState.networth(applicantId),
        PropertiesState.primaryPropertyValue(applicantId),
        PropertiesState.futureNetworth(applicantId),
        PropertiesState.saleProceeds(applicantId),
        MortgagesV2State.totalLoanAmountForAllRequestedMortgages,
        DownPaymentState.totalDownPayment(applicantId),
      ],
      (
        applicants: Applicant[] | undefined,
        application: Application,
        debtBalance: number,
        existingMortgageAmount: number,
        financialAssetsNetworth: number,
        primaryPropertyValue: number,
        propertiesNetworth: number,
        saleProceeds: number,
        totalLoanAmountForAllRequestedMortgages: number,
        totalDownPayment: number,
      ): FutureNetworthCalculationDetails => {
        return {
          existingMortgageAmount: existingMortgageAmount,
          totalLoanAmount: applicantId
            ? totalLoanAmountForAllRequestedMortgages / (applicants?.length || 1)
            : totalLoanAmountForAllRequestedMortgages,
          otherPropertiesTotalValue: propertiesNetworth,
          subjectPropertyValue: primaryPropertyValue,
          totalAssetsValue: financialAssetsNetworth,
          totalDebt: debtBalance,
          totalDownPayment:
            application.purpose === ApplicationPurposeType.PURCHASE ? totalDownPayment : 0,
          saleProceeds: saleProceeds,
        };
      },
    );
  }
}
