export const initConfig = {
  menubar: false,
  valid_children: '+body[style]',
  plugins: 'link anchor lists',
  toolbar_mode: 'sliding',
  branding: false,
  toolbar: 'bold italic underline | link | numlist bullist basicitem | generateWithAI',
  paste_as_text: true,
  statusbar: false,
  relative_urls: false,
  remove_script_host: false,
};

export const configWithTheme = (isDarkMode: boolean) => {
  return {
    ...initConfig,
    skin: isDarkMode ? 'oxide-dark' : 'oxide',
    content_css: isDarkMode ? 'dark' : 'default',
  };
};
