import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { ApplicationResetState } from 'src/app/shared/state.model';
import { LoadingEnd, LoadingStart } from '../../core/loading.state';
import { PropertyOwner } from '../../shared';
import { CreateOwner, RemoveOwner, SetOwners, UpdateOwner } from './property-owner.actions';
import { PropertyOwnerService } from './property-owner.service';

export interface PropertyOwnerStateModel {
  owners: {
    [key: string]: PropertyOwner;
  };
}

@State<PropertyOwnerStateModel>({
  name: 'propertyOwners',
  defaults: { owners: {} },
})
@Injectable()
export class PropertyOwnerState {
  constructor(private propertyOwnerService: PropertyOwnerService, private store: Store) {}

  static owner(ownerId: string) {
    return createSelector(
      [PropertyOwnerState.owners],
      (owners: { [key: string]: PropertyOwner }): PropertyOwner | undefined => {
        return owners[ownerId];
      },
    );
  }

  @Selector()
  static owners(state: PropertyOwnerStateModel) {
    return state.owners;
  }

  @Selector([PropertyOwnerState.owners])
  static ownersList(owners: { [key: string]: PropertyOwner }) {
    return Object.values(owners);
  }

  static propertyOwnersList(propertyId: string) {
    return createSelector(
      [PropertyOwnerState.ownersList],
      (owners: PropertyOwner[]): PropertyOwner[] => {
        return owners.filter((x) => x.propertyId === propertyId);
      },
    );
  }

  @Action(SetOwners)
  setOwners(ctx: StateContext<PropertyOwnerStateModel>, { owners }: SetOwners) {
    const ownerEntities = owners.reduce((entities: { [key: string]: PropertyOwner }, owner) => {
      entities[owner.id] = owner;

      return entities;
    }, {});

    ctx.patchState({ owners: ownerEntities });
  }

  @Action(CreateOwner)
  createOwner(ctx: StateContext<PropertyOwnerStateModel>, action: CreateOwner) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.propertyOwnerService.postOwner(action.propertyId, action.owner).pipe(
      tap((owner) => {
        const state = ctx.getState();

        ctx.patchState({
          owners: {
            ...state.owners,
            [owner.id]: owner,
          },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateOwner)
  updateOwner(
    ctx: StateContext<PropertyOwnerStateModel>,
    action: UpdateOwner,
  ): Observable<PropertyOwner> {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.propertyOwnerService.patchOwner(action.propertyId, action.owner).pipe(
      tap((owner) => {
        const state = ctx.getState();

        ctx.patchState({
          owners: {
            ...state.owners,
            [action.owner.id]: owner,
          },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(RemoveOwner)
  removeOwner(ctx: StateContext<PropertyOwnerStateModel>, action: RemoveOwner) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.propertyOwnerService.deleteOwner(action.propertyId, action.owner.id).pipe(
      tap(() => {
        const state = ctx.getState();
        const owners = { ...state.owners };

        delete owners[action.owner.id];

        ctx.patchState({
          owners,
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(ApplicationResetState) applicationReset(ctx: StateContext<PropertyOwnerStateModel>) {
    ctx.patchState({ owners: {} });
  }
}
