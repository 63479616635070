import { TextFieldModule } from '@angular/cdk/text-field';
import { NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ApplicationConditionStatus } from '@fundmoreai/models';

export interface ConditionStatusChangeDialogData {
  title: string;
  message: string;
  confirmText: string;
  status: ApplicationConditionStatus;
}

@Component({
  selector: 'fm-condition-status-change-dialog',
  templateUrl: './condition-status-change-dialog.component.html',
  styleUrls: ['./condition-status-change-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    NgIf,
    MatFormFieldModule,
    MatInputModule,
    TextFieldModule,
    NgSwitch,
    NgSwitchCase,
  ],
})
export class ConditionStatusChangeDialogComponent {
  title: string;
  message: string;
  confirmText: string;
  status: ApplicationConditionStatus;

  comments: string = '';

  readonly ApplicationConditionStatus = ApplicationConditionStatus;

  constructor(
    public dialogRef: MatDialogRef<ConditionStatusChangeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConditionStatusChangeDialogData,
  ) {
    this.title = data.title;
    this.message = data.message;
    this.confirmText = data.confirmText;
    this.status = data.status;
  }

  confirmDialog(): void {
    this.dialogRef.close({ confirm: true, comments: this.comments });
  }

  closeDialog(): void {
    this.dialogRef.close({ confirm: false });
  }
}
