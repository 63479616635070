import { Injectable } from '@angular/core';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import {
  ConstructionMortgage,
  CustomerType,
  DrawSchedule,
  EmploymentType as typesOfEmployment,
  Fee,
  Flip,
  FundmoreNarrative,
  FundmoreResultType,
  IncomeType,
  Mortgage,
  MortgageType as MortgageStatus,
  Property,
  RepaymentType,
  ResultNarrative,
  MortgagePosition,
  Summary,
  FinancialLiability,
  MortgageCalculationAutomationSettings,
} from '@fundmoreai/models';
import {
  FundmoreOctopus,
  FundmoreScoreInput,
  FundmoreScoreOutput,
  EmploymentType,
  ExtraApplicant,
  MortgagePurpose,
  PaymentType,
  OctopusResult,
} from '@fundmoreai/score-octopus';
import {
  Action,
  Actions,
  Selector,
  State,
  StateContext,
  Store,
  ofActionDispatched,
} from '@ngxs/store';
import { combineLatest, EMPTY, forkJoin, from, Observable } from 'rxjs';
import {
  debounceTime,
  filter,
  finalize,
  map,
  mergeMap,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { ScoreProcessingType } from 'src/environments/environment.base';
import { AuthState } from '../auth/auth.state';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import { Applicant, Application, DownPayment } from '../shared';
import { AppFeaturesState } from '../shared/app-features.state';
import { FundmoreScoreSubType, FundmoreScoreType } from '../shared/enums';
import { ApplicationResetState } from '../shared/state.model';
import { ApplicantsState } from './applicants.state';
import { ConstructionDrawScheduleState } from './construction/construction-draw-schedule.state';
import { ConstructionMortgageState } from './construction/construction-mortgage.state';
import { DownPaymentState } from './downpayments.state';
import { FeesState } from './fees.state';
import { FlipState } from './flip/flip.state';
import { FundmoreScoreLenderService } from './fundmore-score-lender.service';
import { MortgageApplicationState } from './mortgage-application.state';
import { PropertiesState } from './properties.state';
import { SummaryState } from './summary.state';
import { DiffPatcher, Config } from 'jsondiffpatch';
import { MortgagesV2State } from 'src/app/portal/mortgages-v2/mortgages-v2.state';
import { FinancialLiabilityState } from '../features/application/widgets/credit/financial-liability.state';
import {
  ComputeLenderScore,
  FetchNarratives,
  FetchProductRecommendations,
  FundmoreScoreLenderStateModel,
  SetScore,
} from './fundmore-score-lender.actions';
import isEqual from 'lodash.isequal';
import { environment } from '../../environments/environment';

const rateMatrixPlaceHolder = {
  score: buildFundmoreScoreOutputFromResultNarratives(
    [
      {
        result: FundmoreResultType.Fail,
        narrative: 'Score not initialized yet. Please wait...',
      },
    ],
    false,
    'Rate Matrix',
  ),
  type: FundmoreScoreType.RateMatrix,
};

@State<FundmoreScoreLenderStateModel>({
  name: 'fundmoreScoreLenderResults',
  defaults: {
    results: [rateMatrixPlaceHolder],
  },
})
@Injectable()
export class FundmoreScoreLenderState {
  constructor(
    private flipState: FlipState,
    private fundmoreScoreLenderService: FundmoreScoreLenderService,
    private store: Store,
    private actions$: Actions,
  ) {
    this.diffpatcher = new DiffPatcher(this.conf);
  }

  prevScore = {};
  prev = {};
  conf: Config = {};
  diffpatcher: DiffPatcher;

  @Selector() static lenderScore(state: FundmoreScoreLenderStateModel) {
    return state;
  }

  @Action(ComputeLenderScore, { cancelUncompleted: true })
  public computeScore(
    ctx: StateContext<FundmoreScoreLenderStateModel>,
  ): Observable<FundmoreScoreOutput> {
    if (this.store.selectSnapshot(AppFeaturesState.processingType) !== ScoreProcessingType.Lender) {
      return EMPTY;
    }
    this.prevScore = {};
    this.prev = {};
    return combineLatest([
      this.store.select(SummaryState.summaryAllRequestedMortgages),
      this.store.select(MortgagesV2State.mortgages),
      this.store.select(ApplicantsState.applicantsListExcludingThirdParty),
      this.store.select(ApplicantsState.primaryApplicant),
      this.store.select(PropertiesState.primaryProperty),
      this.store.select(FeesState.feesList),
      this.store.select(DownPaymentState.downPaymentsList),
      this.store.select(MortgageApplicationState.application),
      this.store.select(ConstructionMortgageState.constructionMortgage),
      this.store.select(ConstructionDrawScheduleState.drawSchedules),
      this.flipState.flip$,
      this.store.select(FinancialLiabilityState.financialLiabilitiesList),
    ]).pipe(
      debounceTime(300),
      mergeMap(
        ([
          summary,
          mortgages,
          applicants,
          primaryApplicant,
          primaryProperty,
          fees,
          downPayments,
          application,
          constructionMortgage,
          drawSchedules,
          flip,
          financialLiabilities,
        ]: [
          Summary,
          Mortgage[],
          Applicant[],
          Applicant,
          Property,
          Fee[] | undefined,
          DownPayment[],
          Application,
          ConstructionMortgage,
          DrawSchedule[],
          Flip,
          FinancialLiability[],
        ]) => {
          const current = {
            summary,
            mortgages,
            applicants,
            primaryApplicant,
            primaryProperty,
            fees,
            downPayments,
            application,
            constructionMortgage,
            drawSchedules,
            flip,
            financialLiabilities,
          };
          const diff = this.diffpatcher.diff(current, this.prev);
          this.prev = current;

          if (
            !primaryApplicant ||
            !primaryProperty ||
            !summary ||
            JSON.stringify(summary) == '{}' ||
            !diff
          ) {
            return EMPTY;
          }

          const hasCustomScore = this.store.selectSnapshot(AppFeaturesState.hasCustomScore);
          const s3BasePath = hasCustomScore ? `${environment.public_bucket_url}` : undefined;
          const fundmoreOctopus = new FundmoreOctopus(s3BasePath);

          const arv = flip?.analysisResult?.afterRepairValue;
          const landCosts = constructionMortgage?.landCost;
          const hardCosts = drawSchedules.reduce((a, b) => a + b.hardCostCompletedToDate, 0);
          const fundmoreScoreInput = this.generateDefaultFundmoreScoreInput(
            summary,
            mortgages,
            this.store.selectSnapshot(MortgagesV2State.firstFoundRequestedLoanTypeMortgage),
            this.store.selectSnapshot(MortgagesV2State.firstFoundRequestedLoanTypeLOC),
            this.store.selectSnapshot(MortgagesV2State.capFeesMaxPercentage),
            applicants,
            { ...primaryApplicant },
            primaryProperty,
            fees || [],
            downPayments,
            application,
            arv,
            landCosts,
            hardCosts,
            financialLiabilities,
            this.store.selectSnapshot(MortgagesV2State.mortgageCalculationAutomationSettings),
          );
          const score$ = from(fundmoreOctopus.getScore(fundmoreScoreInput)).pipe(
            map((score) => {
              this.sortNarratives(score);
              return score;
            }),
            filter((score) => {
              return !isEqual(score, this.prevScore);
            }),
          );
          return score$.pipe(
            switchMap((score) => ctx.dispatch(new SetScore(score)).pipe(map(() => score))),
            switchMap((score) => {
              this.prevScore = score;
              const mortgageToProduct = (application?.Mortgages ?? [])
                .filter((x) => x.type === MortgageStatus.REQUESTED)
                .map((m) => {
                  return {
                    mortgageId: m.id,
                    productId: m.applicationAdvancedProduct?.advancedProductSnapshot.id,
                  };
                });
              return ctx
                .dispatch(new FetchNarratives(application.id, mortgageToProduct))
                .pipe(map(() => score));
            }),
          );
        },
      ),
      takeUntil(this.actions$.pipe(ofActionDispatched(ApplicationResetState))),
    );
  }

  @Action(SetScore)
  public setScore(ctx: StateContext<FundmoreScoreLenderStateModel>, { score }: SetScore) {
    ctx.patchState({
      results: [{ score: score, type: FundmoreScoreType.Default }, rateMatrixPlaceHolder],
    });
  }

  @Action(ApplicationResetState)
  resetState(ctx: StateContext<FundmoreScoreLenderStateModel>) {
    ctx.patchState({ results: [rateMatrixPlaceHolder] });
  }

  @Action(FetchNarratives)
  getNarratives(ctx: StateContext<FundmoreScoreLenderStateModel>, action: FetchNarratives) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    if (!action.mortgageIdProductId || action.mortgageIdProductId.length === 0) {
      return EMPTY;
    }

    return forkJoin(
      action.mortgageIdProductId.map(({ mortgageId, productId }) =>
        this.fundmoreScoreLenderService.getNarratives(action.applicationId, mortgageId, productId),
      ),
    ).pipe(
      tap((results) => {
        const rateMatrixResults = results.map((result) => {
          const score = buildFundmoreScoreOutputFromResultNarratives(
            result.narratives,
            result.rateToApply !== undefined,
            'Rate Matrix',
          );

          const rateMatrixResult: {
            score: FundmoreScoreOutput;
            type: FundmoreScoreType;
            mortgageId?: string;
          } = {
            score,
            type: FundmoreScoreType.RateMatrix,
            mortgageId: result.mortgageId,
          };

          return rateMatrixResult;
        });

        const state = ctx.getState();
        const filteredCurrentResults = state.results.filter(
          (x) =>
            !(
              x.type === FundmoreScoreType.RateMatrix &&
              (results.map((r) => r.mortgageId).includes(x.mortgageId ?? '') ||
                x.mortgageId === undefined)
            ),
        );

        ctx.patchState({
          results: [...filteredCurrentResults, ...rateMatrixResults],
        });
      }),
      switchMap(() => {
        const loadingAction = new LoadingEnd(this.constructor.name);
        const fetchRecommendations = action.mortgageIdProductId.map(
          ({ mortgageId, productId }) =>
            new FetchProductRecommendations(action.applicationId, mortgageId, productId),
        );

        return ctx.dispatch([loadingAction, ...fetchRecommendations]);
      }),
    );
  }

  @Action(FetchProductRecommendations)
  getProductRecommendations(
    ctx: StateContext<FundmoreScoreLenderStateModel>,
    action: FetchProductRecommendations,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.fundmoreScoreLenderService
      .getProductRecommendations(action.applicationId, action.mortgageId, action.productId)
      .pipe(
        tap((recommendationsResult) => {
          const state = ctx.getState();
          const result: OctopusResult | undefined = state.results?.find(
            (x) =>
              x.type === FundmoreScoreType.RateMatrix &&
              x.mortgageId === recommendationsResult.mortgageId,
          )?.score.results[0];
          if (result) {
            ctx.patchState({
              results: state.results.map((x) => {
                if (
                  x.type === FundmoreScoreType.RateMatrix &&
                  x.mortgageId === recommendationsResult.mortgageId
                ) {
                  return {
                    ...x,
                    score: {
                      ...x.score,
                      results: [
                        {
                          ...result,
                          recommendations: recommendationsResult.recommendations,
                        },
                      ],
                    },
                  };
                }
                return x;
              }),
            });
          }
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  private generateDefaultFundmoreScoreInput(
    summary: Summary,
    mortgagesInput: Mortgage[],
    baseRequestedMortgage: Mortgage | undefined,
    locRequestedMortgage: Mortgage | undefined,
    capFeesMaxPercentage: number | undefined,
    allApplicants: Applicant[],
    primaryApplicant: Applicant,
    primaryProperty: Property,
    fees: Fee[],
    downPayments: DownPayment[],
    application: Application,
    arv: number | undefined,
    landCosts: number | undefined,
    hardCosts: number | undefined,
    financialLiabilities: FinancialLiability[],
    mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
  ): FundmoreScoreInput {
    const mortgages = mortgagesInput.map((x) => {
      const mortgage = Object.assign({}, x);
      if (
        !mortgage.maturityDate &&
        (!mortgageCalculationAutomationSettings ||
          !mortgageCalculationAutomationSettings[mortgage.id]?.isMaturityDateDisabled)
      ) {
        if (mortgageCalculationAutomationSettings?.ANY_MORTGAGE?.excludeExtraPaymentInTerm) {
          mortgage.maturityDate =
            FundmoreCalculator.computeMaturityDateWithoutExtraPayment(mortgage);
        } else {
          mortgage.maturityDate = FundmoreCalculator.computeMaturityDate(mortgage);
        }
      }
      return mortgage;
    });

    const requestedMortgage = Object.assign({}, baseRequestedMortgage);
    if (
      !requestedMortgage.maturityDate &&
      (!mortgageCalculationAutomationSettings ||
        !mortgageCalculationAutomationSettings[requestedMortgage.id]?.isMaturityDateDisabled)
    ) {
      if (mortgageCalculationAutomationSettings?.ANY_MORTGAGE?.excludeExtraPaymentInTerm) {
        requestedMortgage.maturityDate =
          FundmoreCalculator.computeMaturityDateWithoutExtraPayment(requestedMortgage);
      } else {
        requestedMortgage.maturityDate = FundmoreCalculator.computeMaturityDate(requestedMortgage);
      }
    }

    const mortgagePurpose = requestedMortgage?.purpose?.toLowerCase().includes('purchase')
      ? MortgagePurpose.Purchase
      : requestedMortgage?.purpose?.toLowerCase().includes('refinance')
      ? MortgagePurpose.Refinance
      : requestedMortgage?.purpose?.toLowerCase().includes('eto')
      ? MortgagePurpose.ETO
      : requestedMortgage?.purpose?.toLowerCase().includes('switch') ||
        requestedMortgage?.purpose?.toLowerCase().includes('transfer')
      ? MortgagePurpose.SwitchTransfer
      : MortgagePurpose.Unknown;

    const mortgageType =
      requestedMortgage?.mortgageType === MortgagePosition.FIRST
        ? MortgagePosition.FIRST
        : requestedMortgage?.mortgageType === MortgagePosition.SECOND
        ? MortgagePosition.SECOND
        : requestedMortgage?.mortgageType === MortgagePosition.THIRD
        ? MortgagePosition.THIRD
        : MortgagePosition.FIRST;

    const propertyLocation = primaryProperty?.propertyAddress;
    const noOfUnits = primaryProperty?.noOfUnits || 1;
    const ownerOccupied = primaryProperty?.occupancy?.toLowerCase().includes('owner-occupied')
      ? true
      : false;
    const purchasePrice = primaryProperty?.estimatedValue || 0;
    const fico = primaryApplicant.crScore || 0;
    const job = primaryApplicant.Jobs?.find((x) => x.isCurrent && !x.unableToVerify);
    const tenantId = this.store.selectSnapshot(AuthState.tenantId);
    const tenantCode = this.store.selectSnapshot(AuthState.tenantCode);
    const amortizationMonths = requestedMortgage?.amortizationMonths
      ? Math.ceil(requestedMortgage.amortizationMonths)
      : undefined;

    const repaymentType = requestedMortgage?.repaymentType || RepaymentType.UNKNOWN;

    const propertyAddress = primaryProperty?.propertyAddressExpanded;

    primaryApplicant.isCompany = primaryApplicant.customerType === CustomerType.COMPANY;

    const fundmoreScoreInput: FundmoreScoreInput = {
      tenantId: tenantId,
      tenantCode: tenantCode,
      context: {
        application: { ...application, DownPayments: downPayments },
        requestedMortgage,
        locRequestedMortgage,
        primaryApplicant,
        summary,
        primaryProperty,
        borrowers: allApplicants.filter((x) =>
          [
            CustomerType.CUSTOMER,
            CustomerType.CO_BORROWER,
            CustomerType.GUARANTOR,
            CustomerType.DIRECTOR,
          ].includes(x.customerType),
        ),
        financialLiabilities,
        allApplicants,
      },
      gds: parseFloat(summary.gds?.result?.toFixed(2)),
      tds: parseFloat(summary.tds?.result?.toFixed(2)),
      arv: arv,
      landCosts: landCosts,
      hardCosts: hardCosts,
      ficoScore: fico,
      mortgageType: mortgageType,
      mortgagePurpose: mortgagePurpose,
      repaymentType: repaymentType,
      lotSizeSqFt: primaryProperty.lotSize,
      location: propertyLocation ?? '',
      amortizationPeriod: amortizationMonths,
      numberOfUnits: noOfUnits,
      ownerOccupied: ownerOccupied,
      purchasePrice: purchasePrice,
      constructionType: primaryProperty?.constructionType || 'Unknown',
      employeeData: {
        employmentType:
          job?.type == IncomeType.SELF_EMPLOYED
            ? EmploymentType.SelfEmployed
            : EmploymentType.Employed,
        paymentType:
          job?.employmentType === typesOfEmployment.FULL_TIME
            ? PaymentType.Salary
            : PaymentType.Hourly,
        timeAtJob: job?.timeAtJobMonths ? job?.timeAtJobMonths / 12 : 0,
      },
      propertyAddress: propertyAddress,
      primaryProperty: primaryProperty,
      existingMortgages: mortgages.filter((x) => x.type === MortgageStatus.EXISTING),
      requestedMortgage: requestedMortgage,
      fees: fees,
      capFeesMaxPercentage: capFeesMaxPercentage,
      termMonths: requestedMortgage.termMonths,
      downPayment: downPayments[0]?.amount,
      currentStage: application.currentStage || '',
      totalMortgageAmount: requestedMortgage.loanAmount,
      netRate: requestedMortgage.netRate,
      primaryApplicant: primaryApplicant,
    };

    const extraApplicants = allApplicants
      .filter((x) => !x.isPrimary)
      .map(
        (x: Applicant): ExtraApplicant => ({
          ficoScore: x.crScore || 0,
        }),
      );

    if (extraApplicants) {
      fundmoreScoreInput.extraApplicants = extraApplicants;
    }
    const secondMortgage = mortgages.find((x) => x.mortgageType === MortgagePosition.SECOND);
    if (secondMortgage) {
      fundmoreScoreInput.secondMortgage = {
        lender: secondMortgage.lender || 'Unknown',
        loanAmount: secondMortgage.loanAmount,
      };
    }

    return fundmoreScoreInput;
  }

  private sortNarratives(fso: FundmoreScoreOutput) {
    if (!fso || !fso.results) {
      return;
    }

    fso.results.forEach((result) => {
      result.narratives.sort((a, b) => {
        const aPower = this.getSentimentPower(a);
        const bPower = this.getSentimentPower(b);

        return bPower - aPower;
      });
    });
  }

  private getSentimentPower(sentiment: FundmoreNarrative): number {
    let power = 0;
    switch (sentiment.sentiment) {
      case FundmoreResultType.Fail:
        power = 4;
        break;
      case FundmoreResultType.Unknown:
        power = 3;
        break;
      case FundmoreResultType.ManualReview:
        power = 2;
        break;
      case FundmoreResultType.Pass:
        power = 1;
        break;
    }

    return power;
  }
}

function buildResultNarrative(result: OctopusResult): OctopusResult {
  const PASSED = $localize`Passed`;
  const NEED_MANUAL_REVIEW = $localize`Identified risks`;
  const UNKNOWN = $localize`Unknown`;
  const FAIL = $localize`Fail`;

  if (result.passedTresholds != 0) {
    result.resultNarrative.push({
      display: `${PASSED}: ${result.passedTresholds}/${result.tresholdLength}`,
      sentiment: FundmoreResultType.Pass,
    });
  }
  if (result.manualReviewTresholds != 0) {
    result.resultNarrative.push({
      display: `${NEED_MANUAL_REVIEW}: ${result.manualReviewTresholds}/${result.tresholdLength}`,
      sentiment: FundmoreResultType.ManualReview,
    });
  }
  if (result.unknownTresholds != 0) {
    result.resultNarrative.push({
      display: `${UNKNOWN}: ${result.unknownTresholds}/${result.tresholdLength}`,
      sentiment: FundmoreResultType.Unknown,
    });
  }
  if (result.failedTresholds != 0) {
    result.resultNarrative.push({
      display: `${FAIL}: ${result.failedTresholds}/${result.tresholdLength}`,
      sentiment: FundmoreResultType.Fail,
    });
  }
  return result;
}

function buildFundmoreScoreOutputFromResultNarratives(
  narratives: ResultNarrative[],
  hasRateToApply: boolean,
  octopusName: string,
): FundmoreScoreOutput {
  let octopusResult: OctopusResult = {
    name: octopusName,
    type: FundmoreScoreSubType.Default,
    result: hasRateToApply ? FundmoreResultType.Pass : FundmoreResultType.Fail,
    resultNarrative: [],
    recommendations: [],
    narratives: narratives.map((x) => {
      return {
        sentiment: x.result,
        display: x.narrative,
      };
    }),
    passedTresholds: narratives.filter((x) => x.result === FundmoreResultType.Pass).length,
    failedTresholds: narratives.filter((x) => x.result === FundmoreResultType.Fail).length,
    manualReviewTresholds: narratives.filter((x) => x.result === FundmoreResultType.ManualReview)
      .length,
    unknownTresholds: narratives.filter((x) => x.result === FundmoreResultType.Unknown).length,
    tresholdLength: narratives.length,
  };

  octopusResult = buildResultNarrative(octopusResult);

  const score: FundmoreScoreOutput = {
    results: [octopusResult],
  };

  return score;
}
