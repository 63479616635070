import { ApplicationType, ApplicationStatusType, LoanType } from './enums';

export class ApplicationSubmissionsApiResponse {
  submissions: ApplicationSubmission[];
}

export class ApplicationSubmission {
  applicationId: string;
  loanNumber: string;
  status: ApplicationStatusType | null;
  createdAt: Date;
  dealType: ApplicationType | null;
  product: ApplicationSubmissionProduct;
  rateHoldDate: Date | null;
  baseRate: number | null;
  netRate: number | null;
}

export class ApplicationSubmissionProduct {
  mortgageId: string;
  loanType: LoanType;
  loanAmount: number;
}
