<mat-sidenav-container hasBackdrop="false">
  <mat-sidenav
    class="sidenav-details"
    autoFocus="false"
    disableClose="true"
    fixedInViewport="true"
    [fixedTopGap]="120"
    [fixedBottomGap]="70"
    mode="over"
    [opened]="true"
    position="end">
    <div class="inner-content">
      <div
        class="d-flex align-items-center justify-content-between border-bottom pl-10 bg-light-grey">
        <span i18n class="text-heading">Notes</span>

        <div>
          <button class="text-dark" mat-icon-button [matMenuTriggerFor]="optionsMenu">
            <mat-icon>more_vert</mat-icon>
          </button>

          <mat-menu #optionsMenu="matMenu">
            <button mat-menu-item (click)="exportAsPdf()">
              <ng-container i18n>Export as pdf</ng-container>
            </button>
          </mat-menu>

          <a mat-icon-button target="_blank" (click)="openInNewWindow()">
            <mat-icon>open_in_new</mat-icon>
          </a>

          <button (click)="closeNotes()" class="text-dark" mat-icon-button>
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>

      <fm-notes
        [applicationId]="applicationId"
        [canEdit]="
          (application$ | async)?.uiAbstractPermissions?.canCreateApplicationNotes
        "></fm-notes>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
