import { Property } from '@fundmoreai/models';

export function computePropertyValue(
  property: Property | undefined,
  filterByApplicantId?: string,
): number {
  const propertyValues = [property?.estimatedValue, property?.purchasePrice].filter(Boolean);

  let propertyValue = propertyValues.length != 0 ? Math.min(...propertyValues) : 0;

  if (filterByApplicantId) {
    if (property.ApplicantProperties.find((x) => x.applicantId === filterByApplicantId)) {
      propertyValue = propertyValue / property.ApplicantProperties.length;
    } else {
      propertyValue = 0;
    }
  }

  return +propertyValue;
}
