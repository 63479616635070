export interface DocumentRequestNote {
  id?: string | null;
  applicationId?: string | null;
  authorNames?: string[];
  authorRoles?: string[];
  editableById?: string | null;
  status?: DocumentRequestNoteStatus | null;
  content?: string | null;
  metadata?: any;
  lastModifiedAt?: string | null;
  external?: boolean | null;
  createdAt?: string | null;
  updatedAt?: string | null;
}

export enum DocumentRequestNoteStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
}
