import { Injectable } from '@angular/core';
import { DocumentManagementType } from '@fundmoreai/models';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { LoadingEnd, LoadingStart } from 'src/app/core/loading.state';
import { User, UserAccount } from 'src/app/shared';
import { AppFeaturesState } from 'src/app/shared/app-features.state';
import { RemoveUserAccount } from '../../../portal/user-accounts.actions';
import { RoleState } from '../../shared/user';
import { EzidoxUserAccount, UserAccountManagerPortal } from './model';
import { UserManageService } from './user-manage.service';

export class FetchFullUserAccounts {
  static readonly type = '@userManage.FetchFullUserAccounts';
}
export class CreateUserAccount {
  static readonly type = '@userManage.CreateUserAccount';
  constructor(public user: UserAccountManagerPortal) {}
}

export class CreateUserAccountStep {
  static readonly type = '@userManage.CreateUserAccountStep';
  constructor(public step?: UserCreationStep) {}
}

export class ActivateUserAccount {
  static readonly type = '@userManage.ActivateUserAccount';
  constructor(
    public externalAuthSystemId: string,
    public enable: boolean,
    public skipLoading?: boolean,
  ) {}
}

export class WelcomeUserAccount {
  static readonly type = '@userManage.WelcomeUserAccount';
  constructor(public externalAuthSystemId: string, public skipLoading?: boolean) {}
}

export class ResetPasswordUserAccount {
  static readonly type = '@userManage.ResetPasswordUserAccount';
  constructor(public user: UserAccountManagerPortal, public skipLoading?: boolean) {}
}

export class RoleChangeUserAccount {
  static readonly type = '@userManage.RoleChangeUserAccount';
  constructor(public user: UserAccountManagerPortal, public skipLoading?: boolean) {}
}

export class DirectManagerChangeUserAccount {
  static readonly type = '@userManage.DirectManagerChangeUserAccount';
  constructor(public userId: string, public directManager: string | undefined) {}
}

export class CreateDMUserAccount {
  static readonly type = '@userManage.CreateDMUserAccount';
  constructor(public user: Partial<UserAccountManagerPortal>, public skipLoading?: boolean) {}
}

export class MFAActivateUserAccount {
  static readonly type = '@userManage.MFAActivateUserAccount';
  constructor(public externalAuthSystemId: string, public skipLoading?: boolean) {}
}
export class MFADeActivateUserAccount {
  static readonly type = '@userManage.MFADeActivateUserAccount';
  constructor(public externalAuthSystemId: string, public skipLoading?: boolean) {}
}
export class DeleteUserAccount {
  static readonly type = '@userManage.DeleteUserAccount';
  constructor(public externalAuthSystemId: string, public skipLoading?: boolean) {}
}

export class MarkAsVerifiedUserAccount {
  static readonly type = '@userManage.MarkAsVerifiedUserAccount';
  constructor(public externalAuthSystemId: string, public skipLoading?: boolean) {}
}

export class GetDetailsUserAccount {
  static readonly type = '@userManage.GetDetailsUserAccount';
  constructor(public userId: string, public skipLoading?: boolean) {}
}

interface UserManageStateModel {
  userAccounts: UserAccount[] | undefined;
  step?: UserCreationStep;
}
export interface UserCreationStep {
  stepText: string;
  completeValue: number;
  errorText?: string;
}

@State<UserManageStateModel>({ name: 'userManage', defaults: { userAccounts: [] } })
@Injectable()
export class UserManageState {
  constructor(private uaService: UserManageService, private store: Store) {}

  @Selector() static userAccounts(state: UserManageStateModel): UserAccount[] | undefined {
    return state.userAccounts;
  }

  @Selector() static users(state: UserManageStateModel): User[] {
    return state.userAccounts?.map((ua) => ua.user) ?? [];
  }

  @Selector() static assignableUsers(state: UserManageStateModel): User[] {
    return (
      state.userAccounts
        ?.filter((userAccount) => userAccount.user.roles.some((role) => role.assignable))
        .map((userAccount) => userAccount.user) ?? []
    );
  }

  @Selector() static step(state: UserManageStateModel) {
    return state.step;
  }

  private sortUsers = (a: UserAccount, b: UserAccount) => {
    const afirstName = (a.user?.firstName ?? '').toLowerCase();
    const bfirstName = (b.user?.firstName ?? '').toLowerCase();
    const firstNameCompare = bfirstName.localeCompare(afirstName);
    if (firstNameCompare !== 0) {
      return firstNameCompare;
    }
    const aLastName = (a.user?.lastName ?? '').toLowerCase();
    const bLastName = (b.user?.lastName ?? '').toLowerCase();

    return bLastName.localeCompare(aLastName);
  };

  @Action(FetchFullUserAccounts) public getUsers(ctx: StateContext<UserManageStateModel>) {
    ctx.patchState({ userAccounts: undefined });

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.uaService.getUserAccounts().pipe(
      tap((u) => {
        const sortedUsers = [...u].sort(this.sortUsers);

        ctx.setState({ userAccounts: sortedUsers });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreateUserAccount) createUser(
    ctx: StateContext<UserManageStateModel>,
    action: CreateUserAccount,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const bodyUser = {
      email: action.user.email,
      roles: action.user.roles,
      phoneNumber: action.user.phoneNumber,
      firstName: action.user.firstName,
      lastName: action.user.lastName,
      // create as verified
      emailVerified: true,
      phoneNumberVerified: true,
    };

    const ACTIVATE_USER_ACCOUNT = $localize`Activate User Account`;
    const SEND_WELCOME = $localize`Send Welcome`;
    const ACTIVATE_MFA = $localize`Activate MFA`;
    const CREATE_DM_ACCOUNT = $localize`Create DM Account`;
    const UNKNOWN_ERROR = $localize`Unknown error occurred!`;

    return this.uaService.createUserAccount(bodyUser).pipe(
      tap((newUser: UserAccount) =>
        ctx.patchState({ userAccounts: [...(ctx.getState().userAccounts || []), newUser] }),
      ),
      switchMap((newUser: UserAccount) => {
        return ctx
          .dispatch([
            new CreateUserAccountStep({ stepText: ACTIVATE_USER_ACCOUNT, completeValue: 70 }),
            new ActivateUserAccount(newUser.user.externalAuthSystemId, true, true),
          ])
          .pipe(switchMap(() => of(newUser)));
      }),
      tap((newUser: UserAccount) => {
        const currentUsers = [
          ...(ctx.getState().userAccounts?.filter((a) => a.user.id !== newUser.user.id) || []),
        ];

        ctx.patchState({
          userAccounts: [
            ...currentUsers,
            { ...newUser, userData: { ...newUser.userData, enabled: true } },
          ],
        });
      }),
      switchMap((newUser: UserAccount) => {
        return ctx
          .dispatch([
            new LoadingStart(this.constructor.name),
            new CreateUserAccountStep({ stepText: SEND_WELCOME, completeValue: 85 }),
            new WelcomeUserAccount(newUser.user.externalAuthSystemId, true),
          ])
          .pipe(switchMap(() => of(newUser)));
      }),
      switchMap((newUser: UserAccount) => {
        if (!action.user.enableMFA) {
          return of(newUser);
        }
        return ctx
          .dispatch([
            new LoadingStart(this.constructor.name),
            new CreateUserAccountStep({ stepText: ACTIVATE_MFA, completeValue: 92 }),
            new MFAActivateUserAccount(newUser.user.externalAuthSystemId, true),
          ])
          .pipe(switchMap(() => of(newUser)));
      }),
      switchMap((newUser: UserAccount) => {
        return ctx
          .dispatch([
            new LoadingStart(this.constructor.name),
            new CreateUserAccountStep({ stepText: CREATE_DM_ACCOUNT, completeValue: 98 }),
            new CreateDMUserAccount(
              {
                externalAuthSystemId: newUser.user.externalAuthSystemId,
                id: newUser.user.id,
                roles: newUser.user.roles,
              },
              true,
            ),
          ])
          .pipe(switchMap(() => of(newUser)));
      }),
      catchError((e) => {
        const currentState = ctx.getState();
        const errorMessage = e?.error?.message ?? e?.message ?? UNKNOWN_ERROR;
        ctx.patchState({
          step: {
            completeValue: currentState.step?.completeValue ?? 0,
            stepText: `${currentState.step?.stepText}`,
            errorText: errorMessage,
          },
        });
        throw e;
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreateUserAccountStep) createUserAccountStep(
    ctx: StateContext<UserManageStateModel>,
    action: CreateUserAccountStep,
  ) {
    ctx.patchState({ step: action.step });
  }

  @Action(ActivateUserAccount) enableDisabledUserAccount(
    ctx: StateContext<UserManageStateModel>,
    action: ActivateUserAccount,
  ) {
    if (!action.skipLoading) {
      ctx.dispatch(new LoadingStart(this.constructor.name));
    }
    if (!action.externalAuthSystemId) {
      const MISSING_AUTH_ID = $localize`Missing auth id`;
      throw new Error(MISSING_AUTH_ID);
    }
    return this.uaService.enableDisableUserAccount(action.externalAuthSystemId, action.enable).pipe(
      finalize(() => {
        if (!action.skipLoading) {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }
      }),
    );
  }

  @Action(WelcomeUserAccount) welcomeUserAccount(
    ctx: StateContext<UserManageStateModel>,
    action: WelcomeUserAccount,
  ) {
    if (!action.skipLoading) {
      ctx.dispatch(new LoadingStart(this.constructor.name));
    }
    return this.uaService.welcomeUserAccount(action.externalAuthSystemId).pipe(
      finalize(() => {
        if (!action.skipLoading) {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }
      }),
    );
  }

  @Action(ResetPasswordUserAccount) resetPasswordUserAccount(
    ctx: StateContext<UserManageStateModel>,
    action: ResetPasswordUserAccount,
  ) {
    if (!action.skipLoading) {
      ctx.dispatch(new LoadingStart(this.constructor.name));
    }
    return this.uaService.resetPasswordUserAccount(action.user).pipe(
      finalize(() => {
        if (!action.skipLoading) {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }
      }),
    );
  }

  @Action(DirectManagerChangeUserAccount) directManagerChangeUserAccount(
    ctx: StateContext<UserManageStateModel>,
    action: DirectManagerChangeUserAccount,
  ) {
    const state = ctx.getState();
    const userAccount = state.userAccounts?.find((x) => x.user.id === action.userId);

    if (!userAccount) {
      return;
    }

    const updatedUserAccount = {
      ...userAccount,
      user: {
        ...userAccount.user,
        directManager: action.directManager,
      },
    };
    const users = state.userAccounts?.map((userAccount) =>
      userAccount.user.id === action.userId ? updatedUserAccount : userAccount,
    );

    ctx.patchState({
      userAccounts: users,
    });
  }

  @Action(RoleChangeUserAccount) changeRoleUserAccount(
    ctx: StateContext<UserManageStateModel>,
    action: RoleChangeUserAccount,
  ) {
    if (!action.skipLoading) {
      ctx.dispatch(new LoadingStart(this.constructor.name));
    }

    return this.uaService.changeRoleUserAccount(action.user).pipe(
      tap(() => {
        const state = ctx.getState();
        const userAccount = state.userAccounts?.find((x) => x.user.id === action.user.id);

        if (!userAccount) {
          return;
        }

        const roles = this.store.selectSnapshot(RoleState.roles);
        const updatedUserAccount = {
          ...userAccount,
          user: {
            ...userAccount.user,
            roles: roles.filter((role) => action.user.roles.some((x) => x.id === role.id)),
          },
        };
        const users = state.userAccounts?.map((userAccount) =>
          userAccount.user.id === action.user.id ? updatedUserAccount : userAccount,
        );

        ctx.patchState({
          userAccounts: users,
        });
      }),
      finalize(() => {
        if (!action.skipLoading) {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }
      }),
    );
  }

  @Action(CreateDMUserAccount) createDMUserAccount(
    ctx: StateContext<UserManageStateModel>,
    action: CreateDMUserAccount,
  ) {
    if (!action.skipLoading) {
      ctx.dispatch(new LoadingStart(this.constructor.name));
    }
    // verify if The user that requested the create has a collector Id, otherwise skip this step
    const createCollectorAccount = this.store.selectSnapshot(
      AppFeaturesState.createCollectorAccount,
    );
    const isFundmoreDMEnabled =
      this.store.selectSnapshot(AppFeaturesState.documentManagementType) ===
      DocumentManagementType.EZIDOX;

    if (!createCollectorAccount || !isFundmoreDMEnabled) {
      if (!action.skipLoading) {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }

      return of(null);
    }

    return this.uaService.createDMUserAccount(action.user).pipe(
      tap((ezidoxUser: EzidoxUserAccount) => {
        const state = ctx.getState();
        const existingUser = state.userAccounts?.find(
          (a) => a.user.externalAuthSystemId === action.user.externalAuthSystemId,
        );
        if (!existingUser) {
          return;
        }
        const updatedUser = { ...existingUser, user: { ...existingUser.user } };
        updatedUser.user.ezidoxCollectorId = ezidoxUser.Id.toString();
        ctx.patchState({
          userAccounts: [
            ...(state.userAccounts?.filter(
              (a) => a.user.externalAuthSystemId !== action.user.externalAuthSystemId,
            ) || []),
            updatedUser,
          ],
        });
      }),
      finalize(() => {
        if (!action.skipLoading) {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }
      }),
    );
  }

  @Action(MFAActivateUserAccount) activateMFA(
    ctx: StateContext<UserManageStateModel>,
    action: MFAActivateUserAccount,
  ) {
    if (!action.skipLoading) {
      ctx.dispatch(new LoadingStart(this.constructor.name));
    }
    return this.uaService.activateMFAUserAccount(action.externalAuthSystemId).pipe(
      finalize(() => {
        if (!action.skipLoading) {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }
      }),
    );
  }

  @Action(MFADeActivateUserAccount) deactivateMFA(
    ctx: StateContext<UserManageStateModel>,
    action: MFADeActivateUserAccount,
  ) {
    if (!action.skipLoading) {
      ctx.dispatch(new LoadingStart(this.constructor.name));
    }
    return this.uaService.deactivateMFAUserAccount(action.externalAuthSystemId).pipe(
      finalize(() => {
        if (!action.skipLoading) {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }
      }),
    );
  }

  @Action(DeleteUserAccount) deleteUserAccount(
    ctx: StateContext<UserManageStateModel>,
    action: DeleteUserAccount,
  ) {
    if (!action.skipLoading) {
      ctx.dispatch(new LoadingStart(this.constructor.name));
    }
    return this.uaService.deleteUserAccount(action.externalAuthSystemId).pipe(
      tap(() => {
        const deletedUser = ctx
          .getState()
          .userAccounts?.find(
            (user) => user.user.externalAuthSystemId === action.externalAuthSystemId,
          );
        const updatedSetOfUsers = [
          ...(ctx
            .getState()
            .userAccounts?.filter(
              (a) => a.user.externalAuthSystemId !== action.externalAuthSystemId,
            ) || []),
        ];
        ctx.patchState({ userAccounts: updatedSetOfUsers });
        this.store.dispatch(new RemoveUserAccount(deletedUser!.user.id));
      }),
      finalize(() => {
        if (!action.skipLoading) {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }
      }),
    );
  }

  @Action(MarkAsVerifiedUserAccount) verifyAccount(
    ctx: StateContext<UserManageStateModel>,
    action: MarkAsVerifiedUserAccount,
  ) {
    if (!action.skipLoading) {
      ctx.dispatch(new LoadingStart(this.constructor.name));
    }
    return this.uaService.verifyUserAccount(action.externalAuthSystemId).pipe(
      finalize(() => {
        if (!action.skipLoading) {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }
      }),
    );
  }

  @Action(GetDetailsUserAccount) getAccountDetails(
    ctx: StateContext<UserManageStateModel>,
    action: GetDetailsUserAccount,
  ) {
    if (!action.skipLoading) {
      ctx.dispatch(new LoadingStart(this.constructor.name));
    }
    return this.uaService.getUserAccountDetails(action.userId).pipe(
      tap((user: UserAccount) => {
        const state = ctx.getState();
        const existingUser = state.userAccounts?.find((a) => a.user.id === action.userId);
        if (!existingUser) {
          return;
        }
        const updatedUser = { ...existingUser, userData: user.userData };
        const users = [...(state.userAccounts || [])];
        users.splice(users.indexOf(existingUser), 1, updatedUser);
        ctx.patchState({ userAccounts: users });
      }),
      finalize(() => {
        if (!action.skipLoading) {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }
      }),
    );
  }
}
