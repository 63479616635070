import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { ApplicationNotesState } from '../notes.state';
import { Observable } from 'rxjs';
import { GenerateAINote } from '../notes.actions';
import { LoadingState } from '../../../../core/loading.state';
import { MatButtonModule } from '@angular/material/button';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AsyncPipe, NgClass, NgIf } from '@angular/common';
import { MatSliderModule } from '@angular/material/slider';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'fm-generate-ai-note-dialog',
  templateUrl: './generate-ai-note-dialog.component.html',
  styleUrls: ['./generate-ai-note-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    MatProgressBarModule,
    MatSliderModule,
    MatIconModule,
    NgClass,
    NgIf,
    AsyncPipe,
    FormsModule,
  ],
})
export class GenerateAiNoteDialogComponent {
  @Select(ApplicationNotesState.generatedAINote) generatedAINote$: Observable<string | undefined>;
  @Select(LoadingState.isLoading(ApplicationNotesState.name)) loading$: Observable<boolean>;

  readonly temperatureText: { [key: number]: string } = {
    [0.1]: $localize`Less`,
    [0.5]: $localize`Average`,
    [0.9]: $localize`More`,
  };

  applicationId: string;
  maxTokens = 500;
  temperature = 0.5;

  constructor(
    public dialogRef: MatDialogRef<GenerateAiNoteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { applicationId: string },
    private store: Store,
  ) {
    this.applicationId = data.applicationId;

    this.generateAINote();
  }

  onDismiss() {
    this.dialogRef.close();
  }

  generateAINote() {
    if (!this.applicationId) {
      return;
    }

    this.store.dispatch(new GenerateAINote(this.applicationId, this.temperature, this.maxTokens));
  }

  acceptNote() {
    this.dialogRef.close(this.store.selectSnapshot(ApplicationNotesState.generatedAINote));
  }
}
