import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Note } from './model';

@Injectable({
  providedIn: 'root',
})
export class NotesService {
  constructor(private http: HttpClient) {}

  getNotes(): Observable<Note[]> {
    return this.http.get<Note[]>(`${environment.api_url}/notes`);
  }

  createNote(note: Partial<Note>): Observable<Note> {
    return this.http.post<Note>(`${environment.api_url}/notes`, note);
  }

  updateNote(noteId: string, note: Partial<Note>): Observable<Note> {
    return this.http.patch<Note>(`${environment.api_url}/notes/${noteId}`, note);
  }

  deleteNote(noteId: string): Observable<Note> {
    return this.http.delete<Note>(`${environment.api_url}/notes/${noteId}`);
  }
}
