import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import { ApplicationResetState } from '../shared/state.model';
import {
  Application,
  ApplicationCreateProductsState,
  ApplicationCreditCardFulfillState,
  MCUCreateProductsResponse,
  MCUCreditCardFulfillResponse,
} from '@fundmoreai/models';
import { MCUService } from './mcu.service';
import { AppFeaturesState } from '../shared/app-features.state';
import { AddUploadedStatusToRequestedMortgages } from './mortgages-v2/mortgages-v2.actions';
import { PatchApplicationStatus } from './mortgage-application.actions';

export class MCUCreateProducts {
  static readonly type = '@mcu.CreateProducts';

  constructor(public applicationId: string) {}
}

export class MCUCreateProductsPostback {
  static readonly type = '@mcu.CreateProductsPostback';

  constructor(public response?: MCUCreateProductsResponse) {}
}

export class MCUFetchCreateProductsError {
  static readonly type = '@mcu.FetchCreateProductsError';

  constructor(public applicationId: string) {}
}

export class MCUCreditCardFulfill {
  static readonly type = '@mcu.CreditCardFulfill';

  constructor(public applicationId: string) {}
}

export class MCUCreditCardFulfillPostback {
  static readonly type = '@mcu.CreditCardFulfillPostback';

  constructor(public response?: MCUCreditCardFulfillResponse) {}
}

export class MCUFetchCreditCardFulfillError {
  static readonly type = '@mcu.FetchCreditCardFulfillError';

  constructor(public applicationId: string) {}
}

export class SetMCUState {
  static readonly type = '@mcu.SetState';

  constructor(
    public application: Pick<Application, 'createProductsState' | 'creditCardFulfillState' | 'id'>,
  ) {}
}

export interface MCUStateModel {
  createProductsState: ApplicationCreateProductsState;
  creditCardFulfillState: ApplicationCreditCardFulfillState;
  createProductsError?: string;
  creditCardFulfillError?: string;
}

const defaults = {
  createProductsState: ApplicationCreateProductsState.NOT_TRIGGERED,
  creditCardFulfillState: ApplicationCreditCardFulfillState.NOT_TRIGGERED,
};

@State<MCUStateModel>({
  name: 'MCUState',
  defaults: { ...defaults },
})
@Injectable()
export class MCUState {
  constructor(private mcuService: MCUService, private store: Store) {}

  @Selector()
  static createProductsError(state: MCUStateModel): string | undefined {
    return state.createProductsError;
  }

  @Selector()
  static creditCardFulfillError(state: MCUStateModel): string | undefined {
    return state.creditCardFulfillError;
  }

  @Selector()
  static createProductsState(state: MCUStateModel): ApplicationCreateProductsState {
    return state.createProductsState;
  }

  @Selector()
  static creditCardFulfillState(state: MCUStateModel): ApplicationCreditCardFulfillState {
    return state.creditCardFulfillState;
  }

  @Selector([AppFeaturesState.isMemberIdIntegrationEnabled, MCUState.createProductsState])
  static isFundingBlockedByCreateProductsState(
    isMemberIdIntegrationEnabled: boolean,
    createProductsState: ApplicationCreateProductsState,
  ) {
    return (
      isMemberIdIntegrationEnabled && createProductsState !== ApplicationCreateProductsState.SUCCESS
    );
  }

  @Action(ApplicationResetState)
  reset(ctx: StateContext<MCUStateModel>) {
    ctx.setState({ ...defaults });
  }

  @Action(SetMCUState)
  setMCUState(ctx: StateContext<MCUStateModel>, action: SetMCUState) {
    ctx.setState({
      createProductsState: action.application.createProductsState,
      creditCardFulfillState: action.application.creditCardFulfillState,
    });
    if (action.application.createProductsState === ApplicationCreateProductsState.FAILED) {
      ctx.dispatch(new MCUFetchCreateProductsError(action.application.id));
    }
    if (action.application.creditCardFulfillState === ApplicationCreditCardFulfillState.FAILED) {
      ctx.dispatch(new MCUFetchCreditCardFulfillError(action.application.id));
    }
  }

  @Action(MCUCreateProducts)
  createProducts(ctx: StateContext<MCUStateModel>, action: MCUCreateProducts) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.mcuService.createProducts(action.applicationId).pipe(
      tap((result) => {
        ctx.patchState({ createProductsState: result.createProductsState });
        if (result.error) {
          ctx.patchState({ createProductsError: JSON.stringify(result.error, null, 2) });
        }
        if (result.createProductsState === ApplicationCreateProductsState.SUCCESS) {
          this.store.dispatch(new AddUploadedStatusToRequestedMortgages());
        }
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(MCUCreateProductsPostback)
  createProductsPostback(ctx: StateContext<MCUStateModel>, action: MCUCreateProductsPostback) {
    if (!action.response) {
      return;
    }
    ctx.patchState({ createProductsState: action.response.createProductsState });
    if (action.response.error) {
      ctx.patchState({ createProductsError: JSON.stringify(action.response.error, null, 2) });
    }
    if (action.response.createProductsState === ApplicationCreateProductsState.SUCCESS) {
      this.store.dispatch(new AddUploadedStatusToRequestedMortgages());
    }
  }

  @Action(MCUFetchCreateProductsError)
  fetchCreateProductsError(ctx: StateContext<MCUStateModel>, action: MCUFetchCreateProductsError) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.mcuService.getCreateProductsError(action.applicationId).pipe(
      tap((error) => {
        ctx.patchState({ createProductsError: JSON.stringify(error, null, 2) });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(MCUCreditCardFulfill)
  creditCardFulfill(ctx: StateContext<MCUStateModel>, action: MCUCreditCardFulfill) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.mcuService.creditCardFulfill(action.applicationId).pipe(
      tap((result) => {
        ctx.patchState({ creditCardFulfillState: result.creditCardFulfillState });
        if (result.error) {
          ctx.patchState({ creditCardFulfillError: JSON.stringify(result.error, null, 2) });
        }
        if (
          result.creditCardFulfillState === ApplicationCreditCardFulfillState.SUCCESS &&
          result.applicationStatus
        ) {
          this.store.dispatch(new PatchApplicationStatus(result.applicationStatus));
        }
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(MCUCreditCardFulfillPostback)
  creditCardFulfillPostback(
    ctx: StateContext<MCUStateModel>,
    action: MCUCreditCardFulfillPostback,
  ) {
    if (!action.response) {
      return;
    }
    ctx.patchState({ creditCardFulfillState: action.response.creditCardFulfillState });
    if (action.response.error) {
      ctx.patchState({ creditCardFulfillError: JSON.stringify(action.response.error, null, 2) });
    }
    if (
      action.response.creditCardFulfillState === ApplicationCreditCardFulfillState.SUCCESS &&
      action.response.applicationStatus
    ) {
      this.store.dispatch(new PatchApplicationStatus(action.response.applicationStatus));
    }
  }

  @Action(MCUFetchCreditCardFulfillError)
  fetchCreditCardFulfillError(
    ctx: StateContext<MCUStateModel>,
    action: MCUFetchCreditCardFulfillError,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.mcuService.getCreditCardFulfillError(action.applicationId).pipe(
      tap((error) => {
        ctx.patchState({ creditCardFulfillError: JSON.stringify(error, null, 2) });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }
}
