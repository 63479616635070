import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import { finalize, tap } from 'rxjs/operators';
import { LoadingEnd, LoadingStart } from '../../core/loading.state';
import { FlipCostType, FlipCost } from '../../shared';
import { FlipCostService } from './flip-cost.service';
import { CreateFlipCost, DeleteFlipCost, UpdateFlipCost } from './flip.state.action';
import { ApplicationResetState } from '../../shared/state.model';

interface FlipCostStateModel {
  flipCosts: FlipCost[];
}

@State<FlipCostStateModel>({
  name: 'flipCostState',
  defaults: { flipCosts: [] },
})
@Injectable()
export class FlipCostState {
  constructor(private flipCostService: FlipCostService) {}

  @Action(ApplicationResetState)
  reset(ctx: StateContext<FlipCostStateModel>) {
    ctx.setState({ flipCosts: [] });
  }
  @Selector() static flipCosts(state: FlipCostStateModel) {
    return state.flipCosts;
  }

  @Selector([FlipCostState.flipCosts]) static flipPurchaseCosts(costs: FlipCost[]) {
    return costs.filter((c) => c.type === FlipCostType.PURCHASE_COST);
  }

  @Selector([FlipCostState.flipPurchaseCosts]) static flipPurchaseCostsTotal(costs: FlipCost[]) {
    return FundmoreCalculator.calculateTotalFlipCost(costs);
  }

  @Selector([FlipCostState.flipCosts]) static flipSellingCosts(costs: FlipCost[]) {
    return costs.filter((c) => c.type === FlipCostType.SELLING_COST);
  }

  @Selector([FlipCostState.flipSellingCosts]) static flipSellingCostsTotal(costs: FlipCost[]) {
    return FundmoreCalculator.calculateTotalFlipCost(costs);
  }

  @Selector([FlipCostState.flipCosts]) static flipOperatingCosts(costs: FlipCost[]) {
    return costs.filter((c) => c.type === FlipCostType.OPERATING_COST);
  }

  @Selector([FlipCostState.flipOperatingCosts]) static flipOperatingCostsTotal(costs: FlipCost[]) {
    return FundmoreCalculator.calculateTotalFlipCost(costs);
  }

  @Action(UpdateFlipCost)
  public updateFlipCost(ctx: StateContext<FlipCostStateModel>, { flipCost }: UpdateFlipCost) {
    const { id } = flipCost;
    const flipCostPayload = { item: flipCost.item, amount: flipCost.amount };
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.flipCostService.updateFlipCost(id, flipCostPayload).pipe(
      tap(() => {
        ctx.setState(
          patch({
            flipCosts: updateItem<FlipCost>((cost) => cost?.id === id, patch(flipCostPayload)),
          }),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreateFlipCost)
  public createFlipCost(ctx: StateContext<FlipCostStateModel>, { flipCost }: CreateFlipCost) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...flipCostPayload } = flipCost;
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.flipCostService.createFlipCost(flipCostPayload).pipe(
      tap((newflipCost) => {
        const state = ctx.getState();
        ctx.patchState({
          flipCosts: [newflipCost, ...state.flipCosts],
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(DeleteFlipCost)
  public deleteFlipCost(ctx: StateContext<FlipCostStateModel>, { flipCostId }: DeleteFlipCost) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.flipCostService.deleteFlipCost(flipCostId).pipe(
      tap(() => {
        const state = ctx.getState();
        ctx.patchState({
          flipCosts: state.flipCosts.filter((cost) => cost.id !== flipCostId),
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }
}
