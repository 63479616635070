import { RiskFlagSeverity } from './applicationRiskFlag';

export interface RiskFlagTemplate {
  id: string;
  tenantId: string;
  type: string;
  severity: RiskFlagSeverity;
  description?: string | null;
  createdAt: Date;
  updatedAt?: Date | null;
}
