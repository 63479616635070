import { Injectable } from '@angular/core';
import { NavigationEnd, ResolveEnd, Router } from '@angular/router';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { EMPTY } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { LoadingEnd, LoadingStart } from 'src/app/core/loading.state';
import { Note } from './model';
import { NotesService } from './notes.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

export class ToggleNotes {
  static readonly type = '@notes.toggleNotes';
}

export class CloseNotes {
  static readonly type = '@notes.closeNotes';
}

export class CloseMappedValuesEditor {
  static readonly type = '@notes.closeMappedValuesEditor';
}

export class OpenMappedValuesEditor {
  static readonly type = '@notes.openMappedValuesEditor';
}

export class SetEditNote {
  static readonly type = '@notes.setEditNote';
  constructor(public editNote: Note | null) {}
}

export class FetchNotes {
  static readonly type = '@notes.FetchNotes';
}

export class DeleteNote {
  static readonly type = '@notes.deleteNote';
  constructor(public id: string) {}
}

export class UpdateNote {
  static readonly type = '@notes.updateNote';
  constructor(public note: Note, public id: string) {}
}

export class CreateNote {
  static readonly type = '@notes.createNote';
}

export class ConvertNote {
  static readonly type = '@notes.convertNote';
  constructor(public note: Note) {}
}

export class ClearConvertNote {
  static readonly type = '@notes.clearConvertNote';
}

export class AttachNote {
  static readonly type = '@notes.attachNote';
  constructor(public note: Note, public id: string, public applicationId: string) {}
}

export class SetApplicationNote {
  static readonly type = '@notes.setApplicationNote';
  constructor(public content: string) {}
}

export interface NoteStateModel {
  notes: Note[];
  isTakingNotes: boolean;
  isEditingMappedValues: boolean;
  editNote: Note | null;
  convertNote: Note | null;
  canAttachNote: boolean;
  applicationNote: string | null;
}

@UntilDestroy()
@State<NoteStateModel>({
  name: 'notes',
  defaults: {
    notes: [],
    isTakingNotes: false,
    isEditingMappedValues: false,
    editNote: null,
    convertNote: null,
    canAttachNote: false,
    applicationNote: null,
  },
})
@Injectable()
export class NotesState {
  constructor(private notesService: NotesService, private router: Router) {}

  ngxsOnInit(ctx: StateContext<NoteStateModel>) {
    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof ResolveEnd && this.router.url.includes('upload')) {
        ctx.dispatch(new ClearConvertNote());
      }
    });

    this.router.events.pipe(untilDestroyed(this)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        ctx.patchState({ canAttachNote: event.url.includes('application') });
      }
    });
  }

  @Selector() static isTakingNotes(state: NoteStateModel) {
    return state.isTakingNotes;
  }

  @Selector() static notes(state: NoteStateModel) {
    return state.notes;
  }

  @Selector() static editNote(state: NoteStateModel) {
    return state.editNote;
  }

  @Selector() static isEditingMappedValues(state: NoteStateModel) {
    return state.isEditingMappedValues;
  }

  @Selector() static convertNote(state: NoteStateModel) {
    return state.convertNote;
  }

  @Selector() static applicationNote(state: NoteStateModel) {
    return state.applicationNote;
  }

  @Selector() static canAttachNote(state: NoteStateModel) {
    return state.canAttachNote;
  }

  static attachedNotes(id: string) {
    return createSelector([NotesState], (state: NoteStateModel) => {
      return state.notes.filter((n) => n.applicationIds?.filter((a) => a === id));
    });
  }

  @Action(SetEditNote) setEditNote(ctx: StateContext<NoteStateModel>, action: SetEditNote) {
    ctx.patchState({ editNote: action.editNote });
    if (action.editNote === null) {
      ctx.patchState({ isEditingMappedValues: false });
    }
  }

  @Action(ToggleNotes)
  toggleNotes(ctx: StateContext<NoteStateModel>) {
    const state = ctx.getState();
    ctx.patchState({ isTakingNotes: !state.isTakingNotes });
  }

  @Action(CloseNotes)
  closeNotes(ctx: StateContext<NoteStateModel>) {
    ctx.patchState({ isTakingNotes: false });
  }

  @Action(OpenMappedValuesEditor) openMappedValuesEditor(ctx: StateContext<NoteStateModel>) {
    ctx.patchState({ isEditingMappedValues: true });
  }

  @Action(CloseMappedValuesEditor) closeMappedValuesEditor(ctx: StateContext<NoteStateModel>) {
    ctx.patchState({ isEditingMappedValues: false });
  }

  @Action(FetchNotes) getNotes(ctx: StateContext<NoteStateModel>) {
    ctx.patchState({ notes: [] });

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.notesService.getNotes().pipe(
      tap((notes) => {
        ctx.patchState({ notes });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(DeleteNote) deleteNote(ctx: StateContext<NoteStateModel>, action: DeleteNote) {
    const state = ctx.getState();
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.notesService.deleteNote(action.id).pipe(
      tap(() => {
        ctx.patchState({ notes: [...state.notes.filter((n) => n.id !== action.id)] });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateNote) updateNote(ctx: StateContext<NoteStateModel>, action: UpdateNote) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.notesService.updateNote(action.id, { ...action.note, id: undefined }).pipe(
      tap(() => {
        const state = ctx.getState();
        ctx.patchState({
          notes: [
            ...state.notes.filter((n) => n.id !== action.id),
            { ...action.note, id: action.id },
          ],
        });
        if (state.editNote) {
          ctx.patchState({ editNote: { ...action.note, id: action.id } });
        }
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreateNote) createNote(ctx: StateContext<NoteStateModel>) {
    const note = {
      name: '',
      content: '',
      createdAt: new Date(),
      mappedValues: null,
      applicationIds: null,
    };
    const state = ctx.getState();
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.notesService.createNote(note).pipe(
      tap((createdNote) => {
        ctx.patchState({
          notes: [
            ...state.notes,
            { ...note, id: createdNote.id, createdAt: createdNote.createdAt },
          ],
          editNote: { ...note, id: createdNote.id, createdAt: createdNote.createdAt },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(ConvertNote) convertNote(ctx: StateContext<NoteStateModel>, action: ConvertNote) {
    this.router.navigate(['/portal/pipeline/upload']);
    ctx.patchState({ convertNote: action.note, applicationNote: action.note.content });
    ctx.dispatch(new CloseNotes());
  }

  @Action(ClearConvertNote) clearConvertNote(ctx: StateContext<NoteStateModel>) {
    ctx.patchState({ convertNote: null, applicationNote: null });
  }

  @Action(AttachNote) attachNote(ctx: StateContext<NoteStateModel>, action: AttachNote) {
    const state = ctx.getState();
    let updatedApplicationIds = action.note.applicationIds ? [...action.note.applicationIds] : null;
    if (!action.note.applicationIds?.find((a) => a === action.applicationId)) {
      updatedApplicationIds
        ? updatedApplicationIds.push(action.applicationId)
        : (updatedApplicationIds = [action.applicationId]);

      const attachedNote = {
        ...action.note,
        applicationIds: updatedApplicationIds,
        id: undefined,
      };

      ctx.dispatch(new LoadingStart(this.constructor.name));
      return this.notesService.updateNote(action.id, attachedNote).pipe(
        tap(() => {
          ctx.patchState({
            notes: [
              ...state.notes.filter((n) => n.id !== action.id),
              { ...attachedNote, id: action.id },
            ],
          });
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
    }
    return EMPTY;
  }

  @Action(SetApplicationNote) setApplicationNote(
    ctx: StateContext<NoteStateModel>,
    action: SetApplicationNote,
  ) {
    ctx.patchState({ applicationNote: action.content });
  }
}
