import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { Charge } from '../model';

@Injectable({
  providedIn: 'root',
})
export class ChargesService {
  constructor(private http: HttpClient) {}

  getCharges(applicationId: string): Observable<Charge[]> {
    return this.http.get<Charge[]>(`${environment.api_url}/charges?applicationId=${applicationId}`);
  }
}
