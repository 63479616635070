/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, ErrorHandler, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { AuthService } from '../auth/auth.service';
import { DialogsComponent } from '../features/application/sidebar/dialogs/dialogs.component';

@Injectable({
  providedIn: 'root',
})
export class FmErrorHandlerService extends ErrorHandler implements ErrorHandler {
  constructor(
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    private authService: AuthService,
    private router: Router,
  ) {
    super();
  }
  async handleError(error: any) {
    if (this.isErrorHandled(error)) {
      console.log('[ERROR handled!] ', error);
      return;
    }

    if (this.handleAuthRelatedErrors(error)) {
      // do not log auth errors since these are caught on the backend also
      return;
    }

    if (this.handleChunkErrors(error)) {
      // do not log chunk errors
      return;
    }

    const messagePrefix = 'Something went wrong!';
    const message = await this.parseError(error);
    this.zone.run(() => {
      this.snackBar?.open(message ? `${messagePrefix} \n\r${message}` : messagePrefix, 'Ok', {
        duration: 5000, // autoclose in 5 seconds
        verticalPosition: 'top',
        horizontalPosition: 'center',
      });
    });

    super.handleError(error);

    if (error instanceof HttpErrorResponse) {
      const httpError = new Error();

      httpError.message = error?.error?.message || error?.message || error;
      httpError.name = error?.error?.name || error?.name || error;
      httpError.stack = error?.error?.stack;

      Sentry.captureException(httpError, (scope) => {
        scope.setExtra('rawOriginalError', JSON.stringify(error));
        scope.setExtra('rawSyntheticError', JSON.stringify(httpError));
        scope.setTransactionName(`${httpError.name}: ${httpError.message}`);

        return scope;
      });
    } else {
      Sentry.captureException(error.error || error);
    }
  }

  handleHttpInterceptorError(error: any) {
    this.handleForbidden(error);

    this.handleNoHttpResponse(error);

    // Do not trigger UI error from interceptor
    // If we have an http call where we want to catch the error inside the service and handle it there
    // We don't want in this case that the global interceptor to show an error
    // eg: service.getId().pipe(catchError() => customLogic)
    // Unhandled errors will enter in the custom error handler and show the errors
    // We are gonna still keep the interceptor for a while for custom generic errors on API calls

    // this.handleError(error);
  }

  private isErrorHandled(error: any) {
    if (!error) {
      // do not log empty error
      return true;
    }
    return (
      error?.status === 0 ||
      error?.status === 403 ||
      error?.currentTarget?.status === 0 ||
      error?.currentTarget?.status === 403 ||
      ((error?.status === 404 || error?.currentTarget?.status === 404) &&
        error?.error?.message === 'Application not found')
    );
  }

  private handleNoHttpResponse(error: any) {
    if (error?.status === 0 || error?.currentTarget?.status === 0) {
      this.zone.run(() => {
        this.snackBar?.open(
          `Could not connect to the server! Please check internet connection and reload the application!`,
          'Ok',
          {
            duration: 5000,
            verticalPosition: 'top',
            horizontalPosition: 'center',
          },
        );
      });
    }
  }

  private handleChunkErrors(error: { name?: string; message?: string }) {
    if (
      error &&
      !(error.name === 'ChunkLoadError' || (error.message?.indexOf('ChunkLoadError') ?? -1) >= 0)
    ) {
      return false;
    }

    this.zone.run(() => {
      const reloadOnClose = this.snackBar?.open(
        // eslint-disable-next-line max-len
        `Update Available: The application has been improved! \r\n To ensure you have the latest features and security updates, we will refresh your browser automatically. This will only take a moment.
        `,
        'Ok',
        {
          duration: 10000, // autoclose in 10 seconds
          verticalPosition: 'top',
          horizontalPosition: 'center',
        },
      );
      reloadOnClose.afterDismissed().subscribe(() => {
        window.location.reload();
      });
    });

    return true;
  }

  private handleAuthRelatedErrors(error: any) {
    if (!error?.rejection) {
      return false;
    }
    const invalidRefreshTokenExceptions = [
      'PasswordResetRequiredException',
      'NotAuthorizedException',
    ];
    const { code } = error.rejection;
    if (invalidRefreshTokenExceptions.indexOf(code) >= 0) {
      this.authService.signOut();
      this.router.navigate(['auth']).then(() => window.location.reload());
      return true;
    }
    return false;
  }

  private handleForbidden(error: any) {
    const isApplicationPageOpen = window.location.pathname.startsWith('/portal/application/');
    const is403StatusCode = error?.status === 403 || error?.currentTarget?.status === 403;
    const is404StatusCode = error?.status === 404 || error?.currentTarget?.status === 404;
    const noApplicationPermissions =
      isApplicationPageOpen &&
      ((is404StatusCode && error?.error?.message === 'Application not found') || is403StatusCode);

    if (noApplicationPermissions) {
      this.zone.run(() => {
        const dialogRef = this.dialog.open(DialogsComponent, {
          data: {
            title: $localize`Can't open the application`,
            message: $localize`You don't have permission to view the application.`,
            buttonText: {
              no: $localize`:@@action.goBackToPipelineView:Go Back to Pipeline View`,
            },
            warningModalClass: true,
            modalType: 'review',
          },
          minWidth: '400px',
          maxWidth: '400px',
        });

        dialogRef.afterClosed().subscribe(() => {
          this.router.navigateByUrl('/portal/pipeline');
        });
      });
    } else if (is403StatusCode) {
      this.router.navigate(['auth']).then(() => window.location.reload());
      this.authService.signOut();
    }
  }

  private async parseError(error: any) {
    if (!error) {
      return 'Can not determine the error message.';
    }
    if (typeof error === 'string') {
      return error;
    }
    if (error?.error instanceof ErrorEvent) {
      return error.error.message;
    }
    if (error instanceof HttpErrorResponse) {
      return this.getHttpMessage(error);
    }
    if (typeof error === 'object') {
      return this.parseObjectMessage(error);
    }

    return error?.message;
  }

  private blobToText(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsText(blob);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  private async getHttpMessage(error: HttpErrorResponse) {
    if (typeof error.error?.message === 'string') {
      return error.error.message;
    }
    if (error.error?.message?.isJoi) {
      return error.error.message.details[0]?.message;
    }
    if (error.error?.message?.details[0]?.message) {
      return error.error.message.details[0]?.message;
    }
    if (error.error instanceof Blob) {
      const blobError = error.error;
      if (blobError.type === 'application/json') {
        const json = await this.blobToText(blobError);
        const parsedBlobError = JSON.parse(json);
        if (parsedBlobError && parsedBlobError.message) {
          return parsedBlobError.message;
        }
      }
    }
    return error.statusText;
  }

  private parseObjectMessage(error: any) {
    if (error.rejection) {
      return error.rejection?.message ?? error.message;
    }
    if (typeof error?.message === 'string') {
      return error.message;
    }
    if (error.message?.isJoi) {
      return error.message.details[0]?.message;
    }
    return error;
  }
}
