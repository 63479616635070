export interface GoogleExpandedAddress {
  rawGoogleGeocodeResponse: RawGoogleGeocodeResponse;
  formattedAddress: string;
  streetNumber: string;
  streetName: string;
  streetType: string;
  streetDirection: string;
  postalCodePrefix: string;
  postalCode: string;
  city: string;
  province: string;
  provinceCode: string;
  country: string;
  lat: number;
  lng: number;
}

export interface RawGoogleGeocodeResponse {
  address_components: AddressComponent[];
  formatted_address: string;
  geometry: Geometry;
  place_id: string;
  types: string[];
}

export interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface Geometry {
  bounds: Bounds;
  location: Location;
  location_type: string;
  viewport: Bounds;
}

export interface Bounds {
  northeast: Location;
  southwest: Location;
}

export interface Location {
  lat: number;
  lng: number;
}
