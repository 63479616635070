import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { GenerateDocumentsPackageServiceBase } from 'src/app/features/shared/documents';
import { DocumentPackageServiceType } from '../../../../../environments/environment.base';
import { AppFeaturesState } from '../../../../shared/app-features.state';
import { CalvertPackageService } from './calvert.package.service';
import { DefaultPackageService } from './default.package.service';
import { EQPackageService } from './eq.package.service';
import { GentaiPackageService } from './gentai.package.service';

@Injectable({
  providedIn: 'root',
})
export class DocumentPackageService {
  constructor(
    private store: Store,
    private calvertPackageService: CalvertPackageService,
    private defaultPackageService: DefaultPackageService,
    private gentaiPackageService: GentaiPackageService,
    private eqPackageService: EQPackageService,
  ) {}

  getDocumentPackageService(): GenerateDocumentsPackageServiceBase {
    switch (this.store.selectSnapshot(AppFeaturesState.documentPackageService)) {
      case DocumentPackageServiceType.EQ:
        return this.eqPackageService;

      case DocumentPackageServiceType.Calvert:
        return this.calvertPackageService;

      case DocumentPackageServiceType.Gentai:
        return this.gentaiPackageService;

      case DocumentPackageServiceType.Default:
        return this.defaultPackageService;

      default:
        return this.defaultPackageService;
    }
  }
}
