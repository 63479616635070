import { format, formatISO, parseISO } from 'date-fns';

export const dateOnlyRegex = /^\d{4}-\d{2}-\d{2}$/i;

function fromDatePickerToShortISODate(date: Date | null | undefined) {
  if (!date || isNaN(date.getTime())) {
    return undefined;
  }

  return formatISO(date, { representation: 'date' });
}

function fromLocalDateToShortISODate(date: Date | null | undefined): string | undefined {
  if (!date || isNaN(date.getTime())) {
    return undefined;
  }

  return format(date, 'yyyy-MM-dd');
}

function fromShortISODateToDatePicker(shortISO: string | null) {
  if (!shortISO) {
    return undefined;
  }
  return parseISO(shortISO);
}

function fromLendeskToShortISODate(date: Date | null | undefined) {
  if (!date || isNaN(date.getTime())) {
    return undefined;
  }

  return formatISO(date, { representation: 'date' });
}

function fromFilogixToShortISODate(date: Date | null | undefined) {
  if (!date || isNaN(date.getTime())) {
    return undefined;
  }

  return formatISO(date, { representation: 'date' });
}

function fromShortISODateToMiddleLocalDate(date: string) {
  if (!date) {
    return undefined;
  }

  const d = new Date(date);
  d.setTime(d.getTime() + 12 * 60 * 60 * 1000);
  return d;
}

function toMiddleDate(date: Date) {
  if (!date) {
    return undefined;
  }

  date.setTime(date.getTime() + 12 * 60 * 60 * 1000);
  return date;
}

export {
  fromDatePickerToShortISODate,
  fromShortISODateToDatePicker,
  fromLendeskToShortISODate,
  fromFilogixToShortISODate,
  fromLocalDateToShortISODate,
  fromShortISODateToMiddleLocalDate,
  toMiddleDate,
};
