import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileImageService {
  constructor(private http: HttpClient) {}

  uploadImage(image: Blob) {
    const formData = new FormData();
    formData.append('document', image);

    return this.http.post<void>(`${environment.api_url}/profile/image/upload`, formData);
  }

  getImageUrl(userId: string) {
    return `${environment.public_bucket_url}/images/profile/${userId}.png`;
  }

  getImageUrlFromKey(imageKey: string | null | undefined) {
    if (!imageKey) {
      return null;
    }
    return `${environment.public_bucket_url}/${imageKey}`;
  }
}
