import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { of } from 'rxjs';
import { LoadingState, LoadingStateModel } from 'src/app/core/loading.state';
import { ApplicationResetState } from 'src/app/shared/state.model';
import { MortgageType } from '../../shared';
import { AppFeaturesState } from '../../shared/app-features.state';
import { ConstructionApplicationItemState } from './construction-application-items.state';
import { ConstructionDrawScheduleState } from './construction-draw-schedule.state';
import { ConstructionMortgageState } from './construction-mortgage.state';
import {
  GetConstructionApplicationItem,
  GetConstructionMortgage,
  GetDrawSchedules,
  InitializeConstructionState,
  SetConstructionModuleEnabled,
} from './construction.actions';

interface ConstructionModuleStateModel {
  applicationId: string | undefined;
  enableConstructionModule: boolean;
  loading: boolean;
}

@State<ConstructionModuleStateModel>({
  name: 'constructionModule',
  defaults: { applicationId: undefined, enableConstructionModule: false, loading: false },
  children: [
    ConstructionApplicationItemState,
    ConstructionMortgageState,
    ConstructionDrawScheduleState,
  ],
})
@Injectable()
export class ConstructionModuleState {
  constructor(private store: Store) {}

  @Action(ApplicationResetState) applicationReset(ctx: StateContext<ConstructionModuleStateModel>) {
    ctx.patchState({ applicationId: undefined, enableConstructionModule: false, loading: false });
  }

  @Selector() static constructionModuleEnabled(state: ConstructionModuleStateModel) {
    return state.enableConstructionModule;
  }

  @Selector([LoadingState])
  static constructionModuleLoading(state: LoadingStateModel) {
    return (
      state[ConstructionApplicationItemState.name] ||
      state[ConstructionMortgageState.name] ||
      state[ConstructionDrawScheduleState.name]
    );
  }

  @Action(InitializeConstructionState)
  initializeState(
    ctx: StateContext<ConstructionModuleStateModel>,
    { application }: InitializeConstructionState,
  ) {
    const constructionModuleTenantSettingEnable = this.store.selectSnapshot(
      AppFeaturesState.isConstructionModuleEnabled,
    );
    if (!constructionModuleTenantSettingEnable) {
      return of(undefined);
    }
    const requestedMortgage = application.Mortgages.find(
      (mortgage) => mortgage.type === MortgageType.REQUESTED,
    );
    if (!requestedMortgage) {
      return of(undefined);
    }

    return ctx.dispatch([
      new GetConstructionMortgage(requestedMortgage.id),
      new GetDrawSchedules(application.id),
      new GetConstructionApplicationItem(application.id),
    ]);
  }

  @Action(SetConstructionModuleEnabled) setEnabled(
    ctx: StateContext<ConstructionModuleStateModel>,
    { enabled }: SetConstructionModuleEnabled,
  ) {
    ctx.patchState({ enableConstructionModule: enabled });
  }
}
