export enum MMSFileFormat {
  PDF = 0,
  TIFF = 1,
  TIF = 2,
  DOC = 3,
  DOCX = 4,
  JPEG = 5,
  PNG = 6,
  BMP = 7,
  GIF = 8,
}
