import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ChatMessage } from 'src/app/features/chat/chat-message.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApplicationChatService {
  constructor(private http: HttpClient) {}

  createMessage(id: string, content: string, applicationId: string, mentions: string[]) {
    return this.http.post<ChatMessage>(`${environment.api_url}/chat`, {
      message: {
        id,
        content,
        applicationId,
      },
      mentions,
    });
  }

  getMessages(applicationId: string) {
    return this.http.get<ChatMessage[]>(`${environment.api_url}/chat/${applicationId}`);
  }

  editMessage(id: string, content: string, applicationId: string) {
    return this.http.patch<Date>(`${environment.api_url}/chat`, { id, content, applicationId });
  }

  deleteMessage(messageId: string) {
    return this.http.delete<void>(`${environment.api_url}/chat/${messageId}`);
  }

  markNotificationsAsSeen(notifications: string[]) {
    return this.http.patch<void>(`${environment.api_url}/chat/notifications`, { notifications });
  }

  getUnreadCount(applicationId: string) {
    return this.http.get<number>(`${environment.api_url}/chat/${applicationId}/getUnreadCount`);
  }

  updateLastRead(applicationId: string, lastRead: Date) {
    return this.http.patch<void>(`${environment.api_url}/chat/${applicationId}/setLastReadDate`, {
      lastRead,
    });
  }
}
