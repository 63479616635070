import { FundmoreScoreOutput } from '@fundmoreai/models';
import { FundmoreScoreType } from '../shared/enums';

export interface FundmoreScoreLenderStateModel {
  results: { score: FundmoreScoreOutput; type: FundmoreScoreType; mortgageId?: string }[];
}

export class FetchNarratives {
  static readonly type = '@score.fetchNarratives';
  constructor(
    public applicationId: string,
    public mortgageIdProductId: { mortgageId: string; productId?: string }[],
  ) {}
}

export class FetchProductRecommendations {
  static readonly type = '@score.fetchProductRecommendations';
  constructor(public applicationId: string, public mortgageId: string, public productId?: string) {}
}

export class SetScore {
  static readonly type = '@score.SetScore';
  constructor(public score: FundmoreScoreOutput) {}
}

export class ComputeLenderScore {
  static readonly type = '@score.ComputeLenderScore';
}
