import { LoanType, Mortgage, PayoffPaydownType } from '@fundmoreai/models';

export type LtvMortgageInput = Pick<
  Partial<Mortgage>,
  'mortgageBalance' | 'payoffPaydown' | 'ApplicantMortgages' | 'loanType' | 'loanAmount'
>;

export function computeExistingMortgageAmount(
  existingMortgages: LtvMortgageInput[],
  filterByApplicantId?: string,
): number {
  const considerFuturePayoff = true;

  return _computeExistingMortgageAmount(
    existingMortgages,
    considerFuturePayoff,
    filterByApplicantId,
  );
}

export function computeExistingMortgageAmountRegardlessOfPayoff(
  existingMortgages: LtvMortgageInput[],
  filterByApplicantId?: string,
): number {
  const considerFuturePayoff = false;

  return _computeExistingMortgageAmount(
    existingMortgages,
    considerFuturePayoff,
    filterByApplicantId,
  );
}

function _computeExistingMortgageAmount(
  existingMortgages: LtvMortgageInput[],
  considerFuturePayoff: boolean,
  filterByApplicantId?: string,
) {
  let existingMortgageAmount = 0;

  if (existingMortgages) {
    for (const existingMortgage of existingMortgages) {
      if (
        !considerFuturePayoff ||
        !existingMortgage.payoffPaydown ||
        existingMortgage.payoffPaydown === PayoffPaydownType.NONE
      ) {
        let mortgageBalance = existingMortgage.mortgageBalance || 0;

        if (
          existingMortgage.loanType === LoanType.SECURE_LINE_OF_CREDIT ||
          existingMortgage.loanType === LoanType.SECURE_LINE_OF_CREDIT_FLEX
        ) {
          mortgageBalance = existingMortgage.loanAmount || 0;
        }

        if (filterByApplicantId) {
          if (
            existingMortgage.ApplicantMortgages?.find((x) => x.applicantId === filterByApplicantId)
          ) {
            existingMortgageAmount += mortgageBalance / existingMortgage.ApplicantMortgages.length;
          }
        } else {
          existingMortgageAmount += mortgageBalance;
        }
      }
    }
  }
  return existingMortgageAmount;
}
