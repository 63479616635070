import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DownPayment, FinancialAsset } from '@fundmoreai/models';

@Injectable({
  providedIn: 'root',
})
export class FinancialAssetsService {
  constructor(private http: HttpClient) {}

  postFinancialAssets(
    applicationId: string,
    financialAssets: Partial<FinancialAsset>[],
  ): Observable<FinancialAsset[]> {
    return this.http.post<FinancialAsset[]>(
      `${environment.api_url}/applications/${applicationId}/financialAssets`,
      financialAssets,
    );
  }

  patchFinancialAsset(
    applicationId: string,
    financialAssetId: string,
    financialAsset: FinancialAsset,
  ): Observable<FinancialAsset> {
    return this.http.patch<FinancialAsset>(
      `${environment.api_url}/applications/${applicationId}/financialAssets/${financialAssetId}`,
      financialAsset,
    );
  }

  deleteFinancialAsset(applicationId: string, financialAssetId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.api_url}/applications/${applicationId}/financialAssets/${financialAssetId}`,
    );
  }

  addFinancialAssetToDownPayment(financialAssetId: string): Observable<DownPayment> {
    return this.http.post<DownPayment>(
      `${environment.api_url}/financialAssets/${financialAssetId}/useAsDownPayment`,
      {},
    );
  }
  removeFinancialAssetFromDownPayment(financialAssetId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.api_url}/financialAssets/${financialAssetId}/useAsDownPayment`,
    );
  }
}
