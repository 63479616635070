import { ChannelConstants } from './model';

export const getUserChannel = (tenantCode: string, userId: string) => {
  return `${tenantCode}.${ChannelConstants.USER}.${userId}`;
};

export const getApplicationChannel = (tenantCode: string, applicationId: string) => {
  return `${tenantCode}.${ChannelConstants.APPLICATION}.${applicationId}`;
};

export const getApplicationPresenceChannel = (tenantCode: string, applicationId: string) => {
  return `${tenantCode}.${ChannelConstants.APPLICATION}.${applicationId}-presence`;
};

export const getChatChannel = (tenantCode: string, applicationId: string) => {
  return `${tenantCode}.${ChannelConstants.CHAT}.${applicationId}`;
};
