import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CustomerType, UserAccount } from 'src/app/shared';
import {
  PipelineComposedFilter,
  PipelineSortFilter,
  PipelineUserFilter,
  PipelineView,
} from './model';
import { PipelineService } from './pipeline.service';
import { CsvService } from '../../portal/csv.service';
import { ResetState } from 'src/app/shared/state.model';
import { AppFeaturesState } from '../../shared/app-features.state';
import { saveAs } from 'file-saver';
import { DuplicateOptions } from '../application/sidebar/manage-duplicates/model';
import { PropertyType } from '../../shared/enums';
import { UpdateUserSettings, UserSettingsState } from 'src/app/shared/user-settings.state';
import { PermissionsState } from 'src/app/auth/permissions.state';
import { AuthState } from 'src/app/auth/auth.state';
import {
  ApplicationStage,
  assertUnreachable,
  MortgageKey,
  PipelineApplication,
  PriorityType,
  stagesOrder,
  TaskUrgency,
} from '@fundmoreai/models';
import {
  AddUserFilter,
  ApplyDefaultPipelineView,
  ApplyTeamFilter,
  ApplyUnassignedDealsFilter,
  ExportToCsv,
  FetchApplications,
  FetchUnassignedDealsCount,
  RemoveUserFilter,
  SetApplicationPriority,
  SetComposedFilter,
  SetSearchString,
  SetShowArchivedApplications,
  SetShowMoreDetails,
  SetShowTeamApplications,
  SetShowUnassignedDeals,
  SetSortFilter,
  SetView,
  UpdateApplication,
  UpdateApplicationLocallyAfterManagement,
  UpdatePendingApprovalsList,
} from './pipeline.actions';
import { Router } from '@angular/router';
import { LoadingStart, LoadingEnd } from '../../core/loading.state';
import { MortgageApplicationService } from 'src/app/portal/mortgage-application.service';
import { computeClosestUncompletedTaskDueDate } from '@fundmoreai/helpers';
import { merge } from 'rxjs';
import {
  filterByAnyMortgageDate,
  filterByDate,
  filterByMortgageStateStatus,
  filterByPriority,
  filterByProduct,
  filterByPurpose,
  filterByStage,
} from './composed-filters.helper';

interface PipelineStateModel {
  version: number;
  applications: PipelineApplication[];
  approvalApplications: PipelineApplication[];
  showArchivedApplications: boolean;
  sortFilter: PipelineSortFilter;
  userFilter: PipelineUserFilter[];
  searchString: string;
  view: PipelineView;
  loading: boolean;
  composedFilter: PipelineComposedFilter;
  teamFilter: string[];
  showMoreDetails: boolean;
  showTeamApplications: boolean;
  unassignedDealsCount: number;
  showUnassignedDeals: boolean;
}

@State<PipelineStateModel>({
  name: PipelineState.NAME,
  defaults: PipelineState.DEFAULT_STATE_VALUES,
})
@Injectable()
export class PipelineState {
  constructor(
    private applicationService: MortgageApplicationService,
    private pipelineService: PipelineService,
    private csvService: CsvService,
    private router: Router,
    private store: Store,
  ) {}

  static NAME = 'pipeline';
  static DEFAULT_STATE_VALUES: PipelineStateModel = {
    version: 7,
    applications: [],
    approvalApplications: [],
    showArchivedApplications: false,
    sortFilter: PipelineSortFilter.Default,
    userFilter: [],
    searchString: '',
    view: PipelineView.Board,
    loading: false,
    composedFilter: {
      stageFilter: [],
      priorityFilter: [],
      purposeFilter: [],
      productFilter: [],
      closingDateFilter: undefined,
      deadlineDateFilter: undefined,
      cofDateFilter: undefined,
      creationDateFilter: undefined,
      lastModifiedDateFilter: undefined,
    },
    teamFilter: [],
    showMoreDetails: false,
    showTeamApplications: false,
    unassignedDealsCount: 0,
    showUnassignedDeals: false,
  };

  ngxsOnInit(): void {
    this.store.dispatch(new ApplyDefaultPipelineView());
  }

  @Selector()
  static applications(state: PipelineStateModel) {
    return [
      ...state.applications.filter(
        (app) => !state.approvalApplications.some((a) => a.id === app.id),
      ),
      ...state.approvalApplications,
    ];
  }

  @Selector()
  static searchString(state: PipelineStateModel) {
    return state.searchString;
  }

  @Selector()
  static showArchivedApplications(state: PipelineStateModel) {
    return state.showArchivedApplications;
  }

  @Selector()
  static showTeamApplications(state: PipelineStateModel) {
    return state.showTeamApplications;
  }

  @Selector()
  static teamFilter(state: PipelineStateModel) {
    return state.teamFilter;
  }

  @Selector()
  static showMoreDetails(state: PipelineStateModel) {
    return state.showMoreDetails;
  }

  @Selector() static unassignedDealsCount(state: PipelineStateModel) {
    return state.unassignedDealsCount;
  }

  @Selector() static showUnassignedDeals(state: PipelineStateModel) {
    return state.showUnassignedDeals;
  }

  @Selector() static pendingApprovalsCount(state: PipelineStateModel) {
    return state.approvalApplications.filter((a) => a.approval).length;
  }

  static sortedApplications() {
    return createSelector(
      [
        PipelineState,
        PipelineState.applications,
        AppFeaturesState.tenantStages,
        AuthState.currentUser,
      ],
      (
        state: PipelineStateModel,
        applications: PipelineApplication[],
        tenantStages: ApplicationStage[] | undefined,
        currentUser: UserAccount,
      ) => {
        return this.sortApplications(state, applications, tenantStages, currentUser?.user?.id);
      },
    );
  }

  @Selector()
  static sortFilter(state: PipelineStateModel) {
    return state.sortFilter;
  }

  @Selector()
  static composedFilter(state: PipelineStateModel) {
    return state.composedFilter;
  }

  @Selector()
  static userFilter(state: PipelineStateModel) {
    return state.userFilter;
  }

  @Selector()
  static view(state: PipelineStateModel) {
    return state.view;
  }

  @Action(ResetState)
  resetState(ctx: StateContext<PipelineStateModel>) {
    ctx.setState(PipelineState.DEFAULT_STATE_VALUES);
  }

  @Action(UpdatePendingApprovalsList)
  updatePendingApprovalsList(ctx: StateContext<PipelineStateModel>) {
    if (this.router.url === '/portal/pipeline') {
      ctx.dispatch(new FetchApplications());
    }
  }

  @Action(FetchApplications, { cancelUncompleted: true })
  getApplications(ctx: StateContext<PipelineStateModel>) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const state = ctx.getState();
    const permissions = this.store.selectSnapshot(PermissionsState.permissions);
    const showArchivedApplications = state.showArchivedApplications || false;
    const showTeamApplications = state.showTeamApplications || false;
    const showUnassignedDeals = state.showUnassignedDeals || false;

    if (showTeamApplications) {
      return ctx.dispatch(new ApplyTeamFilter(state.teamFilter ?? []));
    }

    if (showUnassignedDeals) {
      return ctx.dispatch(new ApplyUnassignedDealsFilter());
    }

    return merge(
      this.pipelineService.getApplications(false, showArchivedApplications, permissions).pipe(
        tap((applications) => {
          ctx.patchState({
            applications: this.mapApplications(applications),
          });
        }),
      ),
      this.pipelineService.getApplications(true, showArchivedApplications, permissions).pipe(
        tap((approvalApplications) => {
          ctx.patchState({
            approvalApplications: this.mapApplications(approvalApplications),
          });
        }),
      ),
    ).pipe(finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))));
  }

  @Action(SetShowArchivedApplications)
  setShowArchivedApplications(
    ctx: StateContext<PipelineStateModel>,
    action: SetShowArchivedApplications,
  ): void {
    ctx.patchState({
      showArchivedApplications: action.showArchivedApplications,
      showUnassignedDeals: false,
    });

    ctx.dispatch(new FetchApplications());
  }

  @Action(SetShowUnassignedDeals) setShowUnassignedDeals(
    ctx: StateContext<PipelineStateModel>,
    { showUnassignedDeals }: SetShowUnassignedDeals,
  ) {
    ctx.patchState({
      showUnassignedDeals,
    });

    return showUnassignedDeals
      ? ctx.dispatch(new ApplyUnassignedDealsFilter())
      : ctx.dispatch(new FetchApplications());
  }

  @Action(SetShowMoreDetails)
  setShowMoreDetails(ctx: StateContext<PipelineStateModel>, action: SetShowMoreDetails): void {
    ctx.patchState({
      showMoreDetails: action.showMoreDetails,
    });
  }

  @Action(SetSortFilter)
  setSortFilter(ctx: StateContext<PipelineStateModel>, action: SetSortFilter): void {
    ctx.patchState({
      sortFilter: action.sortFilter,
    });
  }

  @Action(SetComposedFilter)
  setComposedFilter(ctx: StateContext<PipelineStateModel>, action: SetComposedFilter): void {
    ctx.patchState({
      composedFilter: action.composedFilter,
    });
  }

  @Action(AddUserFilter)
  addUserFilter(ctx: StateContext<PipelineStateModel>, action: AddUserFilter): void {
    const state = ctx.getState();

    ctx.patchState({
      userFilter: [...state.userFilter, action.userFilter],
    });
  }

  @Action(RemoveUserFilter)
  removeUserFilter(ctx: StateContext<PipelineStateModel>, action: RemoveUserFilter): void {
    const state = ctx.getState();

    ctx.patchState({
      userFilter: [...state.userFilter.filter((x) => x !== action.userFilter)],
    });
  }

  @Action(SetSearchString)
  setSearchString(ctx: StateContext<PipelineStateModel>, action: SetSearchString): void {
    ctx.patchState({
      searchString: action.searchString,
    });
  }

  @Action(SetView)
  setView(ctx: StateContext<PipelineStateModel>, action: SetView): void {
    ctx.patchState({
      view: action.view,
    });

    this.store.dispatch(new UpdateUserSettings({ defaultPipelineView: action.view }));
  }

  @Action(UpdateApplicationLocallyAfterManagement)
  updateApplicationLocallyAfterManagement(
    ctx: StateContext<PipelineStateModel>,
    action: UpdateApplicationLocallyAfterManagement,
  ) {
    const state = ctx.getState();
    const pipelineApplications = [...state.applications];

    this.updateApplicationLocally(action, pipelineApplications);

    ctx.patchState({ applications: pipelineApplications });

    const pipelineApprovalApplications = [...state.approvalApplications];

    this.updateApplicationLocally(action, pipelineApprovalApplications);

    ctx.patchState({ approvalApplications: pipelineApprovalApplications });
  }

  private updateApplicationLocally(
    action: UpdateApplicationLocallyAfterManagement,
    pipelineApplications: PipelineApplication[],
  ) {
    for (const actionHolder of Object.entries(action.applicationActionsHolder ?? {})) {
      const [applicationId, action] = actionHolder ?? [];
      const applicationAction = action?.action;
      const pipelineApplicationIndex = pipelineApplications.findIndex(
        (application) => application.id === applicationId,
      );
      if (pipelineApplicationIndex < 0) {
        continue;
      }
      const pipelineApplication = { ...pipelineApplications[pipelineApplicationIndex] };
      if (applicationAction === DuplicateOptions.DECLINE) {
        pipelineApplication.previousStage = pipelineApplication.currentStage;
        pipelineApplication.currentStage = ApplicationStage.DECLINED;
        pipelineApplications[pipelineApplicationIndex] = pipelineApplication;
      } else if (
        applicationAction === DuplicateOptions.ARCHIVE ||
        applicationAction === DuplicateOptions.DELETE
      ) {
        pipelineApplications.splice(pipelineApplicationIndex, 1);
      }
    }
  }

  @Action(UpdateApplication)
  updateApplication(ctx: StateContext<PipelineStateModel>, action: UpdateApplication) {
    const state = ctx.getState();
    const pipelineApplication = state.applications.find((a) => a.id === action.applicationId);

    if (pipelineApplication) {
      const updatedApplication = Object.assign(
        {},
        {
          ...pipelineApplication,
          ...action.application,
        },
      );

      ctx.patchState({
        applications: [
          ...state.applications.filter((a) => a.id !== action.applicationId),
          updatedApplication,
        ],
      });
    }

    const approvalApplication = state.approvalApplications.find(
      (a) => a.id === action.applicationId,
    );

    if (approvalApplication) {
      const updatedApplication = Object.assign(
        {},
        {
          ...approvalApplication,
          ...action.application,
        },
      );

      ctx.patchState({
        approvalApplications: [
          ...state.approvalApplications.filter((a) => a.id !== action.applicationId),
          updatedApplication,
        ],
      });
    }
  }

  @Action(SetApplicationPriority)
  patchApplication(
    ctx: StateContext<PipelineStateModel>,
    { applicationId, priority }: SetApplicationPriority,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationService.updateApplicationPriority(applicationId, priority).pipe(
      switchMap(() => {
        return ctx.dispatch(new UpdateApplication(applicationId, { priority }));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(ExportToCsv)
  exportToCsv(ctx: StateContext<PipelineStateModel>) {
    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    const tenantStages = this.store.selectSnapshot(AppFeaturesState.tenantStages);
    const currentUserId = this.store.selectSnapshot(AuthState.currentUser).user.id;
    const applicationsWithApprovals = this.store.selectSnapshot(PipelineState.applications);
    const applications = PipelineState.sortApplications(
      state,
      applicationsWithApprovals,
      tenantStages,
      currentUserId,
    );
    const applicationIds = applications.map((x) => x.id);

    return this.csvService.generateApplicationsCsv(applicationIds).pipe(
      tap((response) => saveAs(response, 'applications.csv')),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  private static sortApplications(
    state: PipelineStateModel,
    applications: PipelineApplication[],
    tenantStages: ApplicationStage[] | undefined,
    currentUserId: string,
  ): PipelineApplication[] {
    let applicationList = [
      ...applications.filter((application) =>
        application.currentStage && tenantStages
          ? tenantStages.some((a) => a === application.currentStage)
          : true,
      ),
    ];

    applicationList = this.filterApplications(
      state.userFilter,
      applicationList,
      state,
      currentUserId,
    );

    if (state.sortFilter === PipelineSortFilter.Default) {
      return this.sortByPriority(applicationList);
    }

    if (state.sortFilter === PipelineSortFilter.ApplicantName) {
      return this.sortByApplicantName(applicationList);
    }

    if (state.sortFilter === PipelineSortFilter.UpdatedDate) {
      return this.sortByUpdateDate(applicationList);
    }

    if (state.sortFilter === PipelineSortFilter.CreationDate) {
      return this.sortByCreationDate(applicationList);
    }

    if (state.sortFilter === PipelineSortFilter.MortgageBroker) {
      return this.sortByBroker(applicationList);
    }

    if (state.sortFilter === PipelineSortFilter.ApplicationStage) {
      return this.sortByApplicationStage(applicationList);
    }

    if (state.sortFilter === PipelineSortFilter.LoanNumber) {
      return this.sortByLoanNumber(applicationList);
    }

    if (state.sortFilter === PipelineSortFilter.ClosingDate) {
      return this.sortByClosingDate(applicationList);
    }

    if (state.sortFilter === PipelineSortFilter.DeadlineToAccept) {
      return this.sortByDeadlineToAccept(applicationList);
    }

    if (state.sortFilter === PipelineSortFilter.ExternalApplicationId) {
      return this.sortByExternalApplicationId(applicationList);
    }
    return applicationList;
  }

  private static filterApplications(
    userFilter: PipelineUserFilter[],
    applicationListInput: PipelineApplication[],
    state: PipelineStateModel,
    currentUserId: string,
  ) {
    let applicationList = [...applicationListInput];

    // Apply user filters
    for (const filter of userFilter) {
      switch (filter) {
        case PipelineUserFilter.Assigned:
          applicationList = this.filterByOnlyMyApplications(applicationList, currentUserId);
          break;
        case PipelineUserFilter.Overdue:
          applicationList = this.filterByOverdueApplicationTask(applicationList);
          break;
        case PipelineUserFilter.Approvals:
          applicationList = this.filterByApprovals(applicationList);
          break;
        default:
          assertUnreachable(filter);
          break;
      }
    }

    // Apply search string filter
    if (state.searchString) {
      applicationList = this.filterApplicationsBySearch(applicationList, state);
    }

    // Apply composed filters
    if (state.composedFilter) {
      applicationList = this.filterByComposedFilters(applicationList, state.composedFilter);
    }

    return applicationList;
  }

  private static filterByComposedFilters(
    applicationList: PipelineApplication[],
    composedFilter: PipelineComposedFilter,
  ) {
    return applicationList.filter((app) => {
      return (
        filterByStage(app, composedFilter.stageFilter) &&
        filterByMortgageStateStatus(app, composedFilter.mortgageStateStatusFilter) &&
        filterByPriority(app, composedFilter.priorityFilter) &&
        filterByPurpose(app, composedFilter.purposeFilter) &&
        filterByProduct(app, composedFilter.productFilter) &&
        filterByAnyMortgageDate(app, MortgageKey.CLOSING_DATE, composedFilter.closingDateFilter) &&
        filterByAnyMortgageDate(app, MortgageKey.DEADLINE, composedFilter.deadlineDateFilter) &&
        filterByAnyMortgageDate(app, MortgageKey.COF_DATE, composedFilter.cofDateFilter) &&
        filterByDate(app.createdAt, composedFilter.creationDateFilter) &&
        filterByDate(app.updatedAt, composedFilter.lastModifiedDateFilter)
      );
    });
  }

  private static filterApplicationsBySearch(
    applicationListInput: PipelineApplication[],
    state: PipelineStateModel,
  ) {
    let applicationList = [...applicationListInput];
    applicationList = applicationList.filter((a) => {
      const propertySearchMatch = a.properties?.some(
        (x) => containsStringIgnoringDiacritics(x.address ?? '', state.searchString ?? ''),
        0,
      );

      const phoneSearchMatch = this.searchByApplicantPhone(state, a);

      const isOtherApplicant = a.otherApplicants?.some((x) =>
        `${x.name} ${x.surname}`.toLowerCase().includes(state.searchString?.toLowerCase()),
      );

      const isPrimaryApplicant = containsStringIgnoringDiacritics(
        a.primaryApplicantName ?? '',
        state.searchString ?? '',
      );

      const externalApplicationIdMatch = a.externalApplicationId
        ?.toLowerCase()
        .includes(state.searchString?.toLowerCase());

      const loanNumberMatch = (a.requestedMortgages ?? [])
        .map((x) => x.loanNumber?.toLowerCase())
        .some((x) => x?.includes(state.searchString?.toLowerCase()));

      const clientIdMatch = a.applicants.some((x) =>
        x.clientId?.toLowerCase().includes(state.searchString?.toLowerCase()),
      );

      return (
        isPrimaryApplicant ||
        isOtherApplicant ||
        propertySearchMatch ||
        phoneSearchMatch ||
        externalApplicationIdMatch ||
        loanNumberMatch ||
        clientIdMatch
      );
    });
    return applicationList;
  }

  private static searchByApplicantPhone(state: PipelineStateModel, a: PipelineApplication) {
    let phoneSearchMatch = false;
    const regexPhoneNr =
      /(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/g;
    const phoneMatch = state.searchString.match(regexPhoneNr);

    if (phoneMatch && phoneMatch.length > 0) {
      const phone = phoneMatch[0].replace(/\D*/g, '');
      phoneSearchMatch = a.applicants.some((x) => {
        const cellPhone = x.cellPhone ? x.cellPhone.replace(/\D*/g, '').includes(phone) : false;
        const workPhone = x.workPhone ? x.workPhone.replace(/\D*/g, '').includes(phone) : false;
        const homePhone = x.homePhone ? x.homePhone.replace(/\D*/g, '').includes(phone) : false;

        return cellPhone || workPhone || homePhone;
      }, 0);
    }
    return phoneSearchMatch;
  }

  private static sortByPriority(applications: PipelineApplication[]): PipelineApplication[] {
    const noPriorityApplications = applications.filter((applications) => {
      return !applications.priority;
    });
    const criticalPriorityApplications = applications.filter((applications) => {
      return applications.priority === PriorityType.CRITICAL;
    });

    const highPriorityApplications = applications.filter((applications) => {
      return applications.priority === PriorityType.HIGH;
    });

    const mediumPriorityApplications = applications.filter((applications) => {
      return applications.priority === PriorityType.MEDIUM;
    });

    const lowPriorityApplications = applications.filter((applications) => {
      return applications.priority === PriorityType.LOW;
    });

    const noPriorityApplicationsSorted = this.sortByCreationDate(noPriorityApplications);
    const criticalPriorityApplicationsSorted = this.sortByCreationDate(
      criticalPriorityApplications,
    );
    const highPriorityApplicationsSorted = this.sortByCreationDate(highPriorityApplications);
    const mediumPriorityApplicationsSorted = this.sortByCreationDate(mediumPriorityApplications);
    const lowPriorityApplicationsSorted = this.sortByCreationDate(lowPriorityApplications);

    const result: PipelineApplication[] = [
      ...criticalPriorityApplicationsSorted,
      ...highPriorityApplicationsSorted,
      ...mediumPriorityApplicationsSorted,
      ...lowPriorityApplicationsSorted,
      ...noPriorityApplicationsSorted,
    ];

    return result;
  }

  private static sortByApplicantName(applications: PipelineApplication[]): PipelineApplication[] {
    return applications.sort((a, b) =>
      (a.primaryApplicantName ?? '') > (b.primaryApplicantName ?? '') ? 1 : -1,
    );
  }

  private static sortByApplicationStage(
    applications: PipelineApplication[],
  ): PipelineApplication[] {
    return applications.sort((a, b) => {
      return a.currentStage && b.currentStage
        ? stagesOrder[a.currentStage] - stagesOrder[b.currentStage]
        : -1;
    });
  }

  private static sortByUpdateDate(applications: PipelineApplication[]): PipelineApplication[] {
    return applications.sort(
      (b, a) =>
        new Date(a.updatedAt ?? a.createdAt).getTime() -
        new Date(b.updatedAt ?? b.createdAt).getTime(),
    );
  }

  private static sortByCreationDate(applications: PipelineApplication[]): PipelineApplication[] {
    return applications.sort(
      (b, a) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
    );
  }

  private static sortByBroker(applications: PipelineApplication[]): PipelineApplication[] {
    return applications.sort((a, b) => {
      const brokerA = a.otherApplicants?.find(
        (applicant) => applicant.customerType === CustomerType.BROKER,
      );
      const brokerB = b.otherApplicants?.find(
        (applicant) => applicant.customerType === CustomerType.BROKER,
      );
      if (!brokerA) {
        return -1;
      }

      if (!brokerB) {
        return 1;
      }

      const brokerAFullName = brokerA?.name ?? '' + brokerA?.surname ?? '';
      const brokerBFullName = brokerB?.name ?? '' + brokerB?.surname ?? '';

      return brokerAFullName > brokerBFullName ? 1 : -1;
    });
  }

  private static sortByLoanNumber(applications: PipelineApplication[]): PipelineApplication[] {
    return applications.sort((a, b) => {
      const bFirstAlphabeticalLoanNumber = b.requestedMortgages
        ?.map((x) => x.loanNumber)
        .sort((a, b) => (a ?? '').localeCompare(b ?? ''))
        .find((x) => x);
      const aFirstAlphabeticalLoanNumber = a.requestedMortgages
        ?.map((x) => x.loanNumber)
        .sort((a, b) => (a ?? '').localeCompare(b ?? ''))
        .find((x) => x);

      if (!bFirstAlphabeticalLoanNumber) {
        return -1;
      }
      if (!aFirstAlphabeticalLoanNumber) {
        return 1;
      }

      return aFirstAlphabeticalLoanNumber.localeCompare(bFirstAlphabeticalLoanNumber) ?? 0;
    });
  }

  private static sortByExternalApplicationId(
    applications: PipelineApplication[],
  ): PipelineApplication[] {
    return applications.sort((a, b) => {
      if (b.externalApplicationId === null) {
        return -1;
      }
      if (a.externalApplicationId === null) {
        return 1;
      }
      return a.externalApplicationId?.localeCompare(b.externalApplicationId) ?? 0;
    });
  }

  private static sortByClosingDate(applications: PipelineApplication[]): PipelineApplication[] {
    return applications.sort((a, b) => {
      const bEarliestClosingDate = b.requestedMortgages
        ?.map((x) => (x.closingDate ? new Date(x.closingDate).getTime() : null))
        .filter((x) => x)
        .sort((a, b) => (a ?? 0) - (b ?? 0))
        .find((x) => x);

      const aEarliestClosingDate = a.requestedMortgages
        ?.map((x) => (x.closingDate ? new Date(x.closingDate).getTime() : null))
        .filter((x) => x)
        .sort((a, b) => (a ?? 0) - (b ?? 0))
        .find((x) => x);

      if (!bEarliestClosingDate) {
        return -1;
      }
      if (!aEarliestClosingDate) {
        return 1;
      }

      return aEarliestClosingDate - bEarliestClosingDate;
    });
  }

  private static sortByDeadlineToAccept(
    applications: PipelineApplication[],
  ): PipelineApplication[] {
    return applications.sort((a, b) => {
      const bEarliestDeadlineToAccept = b.requestedMortgages
        ?.map((x) => (x.deadlineToAcceptDate ? new Date(x.deadlineToAcceptDate).getTime() : null))
        .filter((x) => x)
        .sort((a, b) => (a ?? 0) - (b ?? 0))
        .find((x) => x);

      const aEarliestDeadlineToAccept = a.requestedMortgages
        ?.map((x) => (x.deadlineToAcceptDate ? new Date(x.deadlineToAcceptDate).getTime() : null))
        .filter((x) => x)
        .sort((a, b) => (a ?? 0) - (b ?? 0))
        .find((x) => x);

      if (!bEarliestDeadlineToAccept) {
        return -1;
      }
      if (!aEarliestDeadlineToAccept) {
        return 1;
      }

      return aEarliestDeadlineToAccept - bEarliestDeadlineToAccept;
    });
  }

  private static filterByOnlyMyApplications(
    applicationList: PipelineApplication[],
    currentUserId: string,
  ): PipelineApplication[] {
    return applicationList.filter((a) =>
      (a.applicationAssignedUser ?? []).find((aU) => aU.userId === currentUserId),
    );
  }

  filterByUserId(applicationList: PipelineApplication[], userId: string): PipelineApplication[] {
    return applicationList.filter((a) => a.createdBy?.id === userId);
  }

  private static filterByOverdueApplicationTask(
    applicationList: PipelineApplication[],
  ): PipelineApplication[] {
    return applicationList.filter((b) => b.closestUncompletedTask?.urgency === TaskUrgency.OVERDUE);
  }

  private static filterByApprovals(applicationList: PipelineApplication[]): PipelineApplication[] {
    return applicationList.filter((b) => b.approval);
  }

  @Action(ApplyDefaultPipelineView)
  applyDefaultPipelineView(ctx: StateContext<PipelineStateModel>) {
    return this.store.select(UserSettingsState.userSettings).pipe(
      tap((userSettings) => {
        const defaultPipelineView = userSettings.defaultPipelineView;
        const state = ctx.getState();

        if (defaultPipelineView && state.view !== defaultPipelineView) {
          ctx.patchState({
            view: defaultPipelineView,
          });
        }
      }),
    );
  }

  @Action(ApplyUnassignedDealsFilter) applyUnassignedDealsFilter(
    ctx: StateContext<PipelineStateModel>,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const permissions = this.store.selectSnapshot(PermissionsState.permissions);

    if (!permissions.applicationList.listUnassignedApplicationsInPipeline) {
      return;
    }

    return this.pipelineService.getUnassignedDeals(permissions).pipe(
      tap((applications) => {
        ctx.patchState({
          applications: this.mapApplications(applications),
          showArchivedApplications: false,
          showTeamApplications: false,
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(ApplyTeamFilter) applyTeamFilter(
    ctx: StateContext<PipelineStateModel>,
    { team }: ApplyTeamFilter,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const permissions = this.store.selectSnapshot(PermissionsState.permissions);

    return this.pipelineService.getTeamApplications(team, permissions).pipe(
      tap((applications) => {
        const state = ctx.getState();

        ctx.patchState({
          applications: this.mapApplications(applications),
          approvalApplications: [],
          teamFilter: team,
          userFilter: state.userFilter.filter(
            (f) => f !== PipelineUserFilter.Assigned && f !== PipelineUserFilter.Approvals,
          ),
          showArchivedApplications: false,
          showUnassignedDeals: false,
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(SetShowTeamApplications) setShowTeamApplications(
    ctx: StateContext<PipelineStateModel>,
    { showTeamApplications, team }: SetShowTeamApplications,
  ) {
    ctx.patchState({
      showTeamApplications,
    });

    return showTeamApplications
      ? ctx.dispatch(new ApplyTeamFilter(team ?? []))
      : ctx.dispatch(new FetchApplications());
  }

  @Action(FetchUnassignedDealsCount) fetchUnassignedDealsCount(
    ctx: StateContext<PipelineStateModel>,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const permissions = this.store.selectSnapshot(PermissionsState.permissions);

    if (!permissions.applicationList.listUnassignedApplicationsInPipeline) {
      return;
    }

    return this.pipelineService.getUnassignedDealsCount().pipe(
      tap((unassignedDealsCount) => {
        ctx.patchState({ unassignedDealsCount });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  private mapApplications(applicationInput: PipelineApplication[]) {
    const applications = applicationInput;

    applications.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    applications.forEach((a) => {
      a.primaryPropertyAddress = a.properties?.find((p) => p?.type === PropertyType.PRIMARY);
      a.externalPropertiesAddresses = a.properties
        ? a.properties.filter((p) => p?.type === PropertyType.EXTERNAL)
        : [];
      a.closestUncompletedTask = computeClosestUncompletedTaskDueDate(a.dueDate);

      a.requestedMortgages?.forEach((m) => {
        m.propertyAddress = a.properties?.find((p) => p?.id === m.propertyId);
      });
    });

    return applications;
  }
}

function containsStringIgnoringDiacritics(value: string, searchText: string): boolean {
  // Convert both to lowercase, and remove diacritics from both
  const normalizedString = value
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
  const normalizedSubstring = searchText
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');

  return normalizedString.indexOf(normalizedSubstring) !== -1;
}
