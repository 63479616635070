import { addBusinessDays } from 'date-fns';

/**  number of days */
const DEADLINE_TO_ACCEPT_AFTER_APPLICATION_CREATION = 7;

export function computeDeadlineToAcceptDate(
  mortgageCreatedAtDate: Date | undefined = undefined
) {
  const createdAtDate = mortgageCreatedAtDate || new Date();
  const deadlineToAccept = addBusinessDays(
    createdAtDate,
    DEADLINE_TO_ACCEPT_AFTER_APPLICATION_CREATION
  ).toISOString();
  return deadlineToAccept;
}
