import {
  Component,
  ChangeDetectionStrategy,
  Input,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Select, Store } from '@ngxs/store';
import { Observable, tap } from 'rxjs';
import { AuthState } from 'src/app/auth/auth.state';
import { SetNoteToEdit } from './notes.actions';
import { Note } from './notes.model';
import { ApplicationNotesState } from './notes.state';
import { EditNoteFormComponent } from './edit-note-form/edit-note-form.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf, AsyncPipe } from '@angular/common';
import { NotesListComponent } from './notes-list/notes-list.component';

@UntilDestroy()
@Component({
  selector: 'fm-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NotesListComponent,
    NgIf,
    MatButtonModule,
    MatIconModule,
    EditNoteFormComponent,
    AsyncPipe,
  ],
})
export class NotesComponent implements OnInit {
  @Input()
  applicationId: string;

  @Input()
  canEdit: boolean | null | undefined;

  @Select(ApplicationNotesState.notesList)
  notes$: Observable<Note[]>;

  @Select(ApplicationNotesState.noteToEdit)
  noteToEdit$: Observable<Partial<Note> | undefined>;

  currentUserId: string;
  isEditNoteVisible: boolean;

  constructor(private cdr: ChangeDetectorRef, private store: Store) {}

  ngOnInit(): void {
    this.currentUserId = this.store.selectSnapshot(AuthState.currentUser).user.id;
    this.noteToEdit$
      .pipe(
        tap((noteToEdit) => {
          if (noteToEdit) {
            this.isEditNoteVisible = true;
            this.cdr.markForCheck();
          }
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  closeEditForm() {
    this.isEditNoteVisible = false;
  }

  openEditDraftNote(draftNote: Note) {
    this.store.dispatch(new SetNoteToEdit(draftNote));
  }
}
