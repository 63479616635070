import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ClosingInstruction } from '../../shared/model';

@Injectable({
  providedIn: 'root',
})
export class ClosingInstructionsService {
  constructor(private http: HttpClient) {}

  postClosingInstruction(
    mortgageId: string,
    applicationId: string,
    closingInstruction: ClosingInstruction,
  ): Observable<ClosingInstruction> {
    return this.http.post<ClosingInstruction>(
      `${environment.fundmore_v2_api_url}/mortgages/${mortgageId}/closing-instruction`,
      { ...closingInstruction, applicationId, mortgageId },
    );
  }

  patchClosingInstruction(
    mortgageId: string,
    closingInstructionId: string,
    closingInstruction: ClosingInstruction,
  ): Observable<ClosingInstruction> {
    return this.http.patch<ClosingInstruction>(
      `${environment.fundmore_v2_api_url}/mortgages/${mortgageId}/closing-instruction/${closingInstructionId}`,
      closingInstruction,
    );
  }

  deleteClosingInstruction(mortgageId: string, closingInstructionId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.fundmore_v2_api_url}/mortgages/${mortgageId}/closing-instruction/${closingInstructionId}`,
    );
  }

  fetchMortgagesClosingInstructions(applicationId: string): Observable<ClosingInstruction[]> {
    return this.http
      .get<ClosingInstruction[]>(
        `${environment.fundmore_v2_api_url}/mortgages/closing-instructions`,
        {
          params: { applicationId },
        },
      )
      .pipe(
        map((closingInstructions) => {
          closingInstructions.forEach((closingInstruction) => {
            closingInstruction.createdAt = new Date(closingInstruction.createdAt);
            closingInstruction.updatedAt = closingInstruction.updatedAt
              ? new Date(closingInstruction.updatedAt)
              : undefined;
          });
          return closingInstructions;
        }),
      );
  }
}
