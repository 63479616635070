import { FCTError } from '../error';

export interface FCTCancelRequestCommand {
  fctReferenceId: string;
  cancellationNote: string;
  tenantCode: string;
}

export interface FCTCancelRequestResponse {
  ResponseStatus: number;
  ValidationErrors: FCTError[];
  DealStatuses: any[];
}
