import { CmhcInsuranceApplicationDetails } from './submitApplication';
import { CmhcViewModelFieldCategory } from './viewModelCategories';
import { CmhcViewModelField } from './viewModelFields';

export interface CmhcFieldViewModel {
  field: CmhcViewModelField;
  category: CmhcViewModelFieldCategory;
  required: boolean;
  isMissing: boolean;
  value: string | boolean | number;
}

export interface CmhcApplicationData extends CmhcInsuranceApplicationDetails {
  viewModels: CmhcFieldViewModel[];
}
