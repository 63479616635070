export interface FCTMetadata {
  fctReferenceID?: string;
  responseTime?: number;
  responseStatus?: number;
}

export interface AuditLogDto {
  type: string;
  requestPayload?: any;
  responsePayload?: any;
  errorPayload?: any;
  isSuccessful: boolean;
  isCanceled: boolean;
  metadata?: FCTMetadata;
  applicationId?: string | undefined;
  sourceIp?: string | undefined;
  destinationIp?: string;
  requestUrl?: string;
}
