import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MessageData, MessageInfoComponent } from 'src/app/portal/message-info.component';

@Injectable({
  providedIn: 'root',
})
export class DetectWindowPopupBlockService {
  constructor(private snackBar: MatSnackBar, private zone: NgZone) {}

  detectBlockedPopup(poppedWindow: Window | null) {
    if (poppedWindow === null) {
      this.showMessage();
    }
  }

  private showMessage() {
    const data: MessageData = {
      message: $localize`The page in a new window could not be opened. Please allow popups for the FundMore application and check the browser address bar for notifications.`,
      title: $localize`Popups are blocked`,
      duration: 15000,
    };

    this.zone.run(() =>
      this.snackBar.openFromComponent(MessageInfoComponent, {
        data: data,
        duration: data.duration,
        verticalPosition: 'top',
        horizontalPosition: 'right',
        panelClass: ['bg-grey', 'text-dark', 'text-pre-wrap', 'contrast'],
      }),
    );
  }
}
