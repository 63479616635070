export class DetermineIframeStrategy {
  static readonly type = '@ezidoxApplication.DetermineIframeStrategy';
}

export class EzidoxIncrementLoading {
  static readonly type = '@ezidoxApplication.EzidoxIncrementLoading';
}

export class EzidoxDecrementLoading {
  static readonly type = '@ezidoxApplication.EzidoxDecrementLoading';
}

export class GetEzidoxUrl {
  static readonly type = '@ezidoxApplication.GetEzidoxUrl';
  constructor(public applicationId: string) {}
}

export class CreateEzidoxApplication {
  static readonly type = '@ezidoxApplication.CreateEzidoxApplication';
  constructor(public applicationId: string) {}
}

export class UpdateEzidoxApplication {
  static readonly type = '@ezidoxApplication.UpdateEzidoxApplication';
  constructor(public applicationId: string) {}
}

export class GetDocumentTemplateTypes {
  static readonly type = '@ezidoxApplication.GetDocumentTemplateTypes';
}

export class EzidoxUrlReset {
  static readonly type = '@ezidoxApplication.EzidoxUrlReset';
}

export class EzidoxRequestStatusReset {
  static readonly type = '@ezidoxApplication.EzidoxRequestStatusReset';
}
