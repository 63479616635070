import { CustomerType } from '../../applicant';

export enum FCTBorrowerType {
  BORROWER = '001',
  CO_BORROWER = '002',
}

export const fctBorrowerTypeMapping: Record<CustomerType, FCTBorrowerType | undefined> = {
  [CustomerType.CUSTOMER]: FCTBorrowerType.BORROWER,
  [CustomerType.CO_BORROWER]: FCTBorrowerType.CO_BORROWER,
  [CustomerType.GUARANTOR]: undefined,
  [CustomerType.THIRD_PARTY]: undefined,
  [CustomerType.COMPANY]: undefined,
  [CustomerType.BROKER]: undefined,
  [CustomerType.LAWYER]: undefined,
  [CustomerType.SUBMITTING_AGENT]: undefined,
  [CustomerType.SIGNING_OFFICER]: undefined,
  [CustomerType.DIRECTOR]: undefined,
  [CustomerType.BENEFICIAL_OWNER]: undefined,
  [CustomerType.BROKERAGE]: undefined,
  [CustomerType.POA]: undefined,
};
