import { Column } from '../../manager-portal/condition-manage/model';
import { DatatableName } from './datatable-columns.state';

export class UpdateDatatableSelectedDisplayColumns {
  static readonly type = '@datatableColumns.UpdateDatatableSelectedDisplayColumns';

  constructor(public datatableName: DatatableName, public selectedColumns: Column[]) {}
}

export class OrderDatatableColumns {
  static readonly type = '@datatableColumns.OrderDatatableColumns';

  constructor(public datatableName: DatatableName, public columns: Column[]) {}
}
