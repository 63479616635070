import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Rate, RATE_IN_USE } from '@fundmoreai/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { catchError, EMPTY, finalize, tap } from 'rxjs';
import { DialogsComponent } from 'src/app/features/application/sidebar/dialogs/dialogs.component';
import { RateService } from './rate.service';
import { LoadingStart, LoadingEnd } from '../../core/loading.state';

export class FetchRates {
  static readonly type = '@rates.fetchRates';
}

export class CreateRate {
  static readonly type = '@rates.createRate';
  constructor(public rate: Rate) {}
}

export class UpdateRate {
  static readonly type = '@rates.updateRate';
  constructor(public rate: Rate) {}
}

export class DeleteRate {
  static readonly type = '@rates.deleteRate';
  constructor(public id: string) {}
}

@State<Rate[]>({
  name: 'rates',
  defaults: [],
})
@Injectable()
export class RateState {
  @Selector() static visibleRates(state: Rate[]) {
    return state.filter((r) => !r.isCustom);
  }

  @Selector() static allRates(state: Rate[]) {
    return state;
  }

  constructor(private rateService: RateService, private dialog: MatDialog) {}

  @Action(FetchRates) fetchRates(ctx: StateContext<Rate[]>) {
    ctx.setState([]);

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.rateService.getRates().pipe(
      tap((rates) => {
        ctx.setState(rates);
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(CreateRate) createRate(ctx: StateContext<Rate[]>, action: CreateRate) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.rateService.postRate({ ...action.rate, id: undefined }).pipe(
      tap((rate) => {
        const rates = ctx.getState();
        ctx.setState([...rates, { ...rate }]);
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(UpdateRate) updateRate(ctx: StateContext<Rate[]>, action: UpdateRate) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.rateService.patchRate(action.rate).pipe(
      tap(() => {
        const rates = ctx.getState();
        ctx.setState([...rates.filter((r) => r.id !== action.rate.id), action.rate]);
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(DeleteRate) deleteRate(ctx: StateContext<Rate[]>, action: DeleteRate) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.rateService.deleteRate(action.id).pipe(
      tap(() => {
        const rates = ctx.getState();
        ctx.setState([...rates.filter((r) => r.id !== action.id)]);
      }),
      catchError((e) => {
        if (e.error.message === RATE_IN_USE) {
          const noNameString = $localize`No name`;
          const noValueString = $localize`No value`;

          const rates = ctx.getState();
          const rate = rates.find((r) => r.id === action.id);

          const rateString = $localize`Name: ${rate?.name ?? noNameString}, Value: ${
            rate?.value ?? noValueString
          }`;

          this.dialog.open(DialogsComponent, {
            data: {
              title: $localize`This rate cannot be deleted as it is in use by at least one rate matrix!`,
              message: rateString,
              buttonText: {
                yes: $localize`:@@action.close:Close`,
                no: $localize`:@@action.cancel:Cancel`,
              },
              declineModalClass: true,
            },
            minWidth: '400px',
            maxWidth: '400px',
          });
          return EMPTY;
        }
        return EMPTY;
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }
}
