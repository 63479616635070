import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FundmoreRecommendationModel, ResultNarrative } from '@fundmoreai/models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FundmoreScoreLenderService {
  constructor(private http: HttpClient) {}

  getNarratives(
    applicationId: string,
    mortgageId: string,
    advancedProductId?: string,
  ): Observable<{ narratives: ResultNarrative[]; rateToApply?: number; mortgageId: string }> {
    let params = new HttpParams().set('mortgageId', mortgageId);
    if (advancedProductId) {
      params = params.append('advancedProductId', advancedProductId);
    }

    return this.http.get<{
      narratives: ResultNarrative[];
      rateToApply?: number;
      mortgageId: string;
    }>(`${environment.fundmore_api_url}/rateMatrix/engine/getNarratives/${applicationId}`, {
      params,
    });
  }

  getProductRecommendations(
    applicationId: string,
    mortgageId: string,
    advancedProductId?: string,
  ): Observable<{ recommendations: FundmoreRecommendationModel[]; mortgageId: string }> {
    let params = new HttpParams().set('mortgageId', mortgageId);
    if (advancedProductId) {
      params = params.append('advancedProductId', advancedProductId);
    }

    return this.http.get<{ recommendations: FundmoreRecommendationModel[]; mortgageId: string }>(
      `${environment.fundmore_api_url}/rateMatrix/engine/getRecommendations/${applicationId}`,
      { params },
    );
  }
}
