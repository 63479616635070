export enum OptaPrimaryHeatingType {
  GEOTHERMAL = 'Geothermal',
  RADIANT = 'Radiant',
  ELECTRIC = 'Electric',
  NATURAL_GAS_FORCED_AIR = 'Natural Gas Forced Air',
  OIL = 'Oil',
  WATER = 'Water',
  WOOD = 'Wood',
  NONE = 'None',
  UNKNOWN = 'Unknown',
}
