import { MMSProvince } from '../enums';

export interface MMSAddress {
  unitNumber?: string;
  streetNumber?: string;
  streetName: string;
  streetName2?: string;
  city: string;
  province: MMSProvince;
  postalCode?: string;
}
