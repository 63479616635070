import { MMSMortgagorTitle, MMSMortgagorType } from '../enums';
import { MMSAddress } from './address';
import { MMSCommunication, MMSIndividualName } from './contact';

export interface MMSMortgagor {
  mortgagorType: MMSMortgagorType;
  title?: MMSMortgagorTitle;
  mortgagorName: MMSIndividualName;
  communication: MMSCommunication;
  companyName?: string;
  mortgagorAddress: MMSAddress;
  consentingSpouse?: boolean;
  spouseName?: MMSIndividualName;
  signatoryName?: MMSIndividualName;
  consentingSpouseGuarantor?: boolean;
}
