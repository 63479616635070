export const MfaStrategies = {
  SMS: 'SMS_MFA',
  TOTP: 'SOFTWARE_TOKEN_MFA',
};

export const DateFormats = {
  ISO_WITHOUT_TIMEZONE: 'yyyy-MM-dd',
};

export const RegexEscapeCharacters = /[.*+?^${}()|[\]\\]/g;
