export interface SagenInsuranceAcknowledgeResponse {
  status: string;
  submitDate: string;
  localApplId: string;
}

export interface SagenInsuranceResponseHeader {
  localApplId?: string;
  filler1?: string;
  transactionSubmitDateTime?: string;
  filler2?: string;
}

export interface SagenInsuranceResponseMessageTable {
  messageTableCode?: string;
  messageTableText?: string;
}

export interface SagenInsuranceResponseTrailer {
  returnCode?: string;
  messageCode?: string;
  messageTextLength?: string;
  messageText?: string;
}

export const GenworthReferenceEmptyNumber = '0000000000';
export interface SagenInsuranceResponse {
  header?: SagenInsuranceResponseHeader;
  responseCode?: string;
  genworthReferenceNumber?: string;
  institutionCode?: string;
  transitNumber?: string;
  lenderLoanNumber?: string;
  borrowerLastName?: string;
  borrowerFirstName?: string;
  borrowerMidInitial?: string;
  premiumAmount?: string;
  feeAmount?: string;
  taxAmount?: string;
  totalAmount?: string;
  insuredAmount?: string;
  coverage?: string;
  expiryDate?: string;
  message?: string; // probably an error message
  messageTable?: Array<SagenInsuranceResponseMessageTable>;
  fillerPart1?: string;
  amortMonths?: string;
  expiryInterestDate?: string;
  propertyRiskRating?: string;
  marketRiskRating?: string;
  covenantRiskRating?: string;
  insuranceIndicator?: string;
  neighborhoodRiskIndicator?: string;
  fillerPart2?: string;
  trailer?: SagenInsuranceResponseTrailer;
  documents?: Array<CertifaxDocument>;
  fieldErrorMessages?: SagenFieldErrorMessage[];
}

export interface SagenFieldErrorMessage {
  message: string;
  field: string;
}

export interface CertifaxDocument {
  documentType?: CertifaxDocumentType;
  documentContent?: string;
}

export enum CertifaxDocumentType {
  ENUS = 'ENUS',
  FRCA = 'FRCA',
  FREN = 'FREN',
}
