import {
  ConstructionApplicationItem,
  ConstructionDefault,
  DrawSchedule,
} from '@fundmoreai/models';
import { differenceInDays } from 'date-fns';

/**
 * Related to Construction Mortgage table
 * @param grossMortgageAmount
 * @param landAdvance
 * @returns netLoanLessLandAdvance
 */
export function calculateNetLoanLessLandAdvance(
  grossMortgageAmount: number,
  landAdvance: number
): number {
  const netLoanLessLandAdvance = grossMortgageAmount - landAdvance;

  if (netLoanLessLandAdvance) {
    return netLoanLessLandAdvance;
  }

  return 0;
}

/**
 * Related to Construction Mortgage table
 * @param buildingBudget
 * @param sqftOfProject
 * @returns costPerSqft
 */
export function calculateCostPerSqft(
  buildingBudget: number,
  sqftOfProject: number
): number {
  if (sqftOfProject === 0) {
    return 0;
  }
  const costPerSqft = parseFloat((buildingBudget / sqftOfProject).toFixed(2));

  if (!costPerSqft) {
    return 0;
  }

  return costPerSqft;
}

/**
 * related to ConstructionApplicationItems
 * @param constructionApplicationItems
 * @returns total amount allowed
 */
export function constructionTotalAmountAllowed(
  constructionApplicationItems: ConstructionApplicationItem[]
): number {
  const total = constructionApplicationItems.reduce(
    (sum, constructionApplicationItem) =>
      (sum += constructionApplicationItem.amountAllowed),
    0
  );
  return total;
}

/**
 * related to ConstructionApplicationItems
 * @param constructionApplicationItems
 * @returns total budget percentage used. The total percentage of the budgetPercentage that has been used
 */
export function constructionTotalBudgetPercentageUsed(
  constructionApplicationItems: ConstructionApplicationItem[]
): number {
  const total = constructionApplicationItems.reduce(
    (sum, constructionApplicationItem) =>
      (sum += constructionApplicationItem.budgetPercentageUsed),
    0
  );
  return total;
}

/**
 * related to ConstructionApplicationItems
 * @param totalBudget
 * @param budgetPercentage
 * @returns budget amount for a constructionApplicationItem
 */
export function constructionPerItemBudgetAmount(
  totalBudget: number,
  budgetPercentage: number
): number {
  const budgetAmount = (budgetPercentage / 100) * totalBudget;
  const roundedBudgetAmount = Math.round(budgetAmount * 100) / 100;
  return roundedBudgetAmount;
}

/**
 * related to ConstructionApplicationItems
 * @param constructionItemBudgetAmount
 * @param completedConstructionItemPercentage
 * @returns amount allowed for a constructionApplicationItem
 */
export function constructionPerItemAmountAllowed(
  constructionItemBudgetAmount: number,
  completedConstructionItemPercentage
): number {
  const amountAllowed =
    constructionItemBudgetAmount * completedConstructionItemPercentage * 0.01;
  const roundedAmountAllowed = Math.round(amountAllowed * 100) / 100;
  return roundedAmountAllowed;
}

/**
 * related to ConstructionApplicationItems
 * @param budgetPercentage
 * @param completedConstructionItemPercentage
 * @returns budget percentage used(completed).
 */
export function constructionPerItemBudgetPercentageUsed(
  budgetPercentage: number,
  completedConstructionItemPercentage: number
): number {
  const budgetPercentageUsed =
    (budgetPercentage * completedConstructionItemPercentage) / 100;
  const roundedBudgetPercentageUsed =
    Math.round(budgetPercentageUsed * 100) / 100;
  return roundedBudgetPercentageUsed;
}

/**
 * Related to ConstructionDrawSchedule
 * @param currentScheduleHardCostComplete current schecule hardCostCompleteToDate field
 * @param previousScheduleHardCostComplete previous schecule hardCostCompleteToDate field
 * @returns draw schedule available advance amount
 */
export function constructionScheduleAvailableAdvanceAmount(
  currentScheduleHardCostComplete,
  previousScheduleHardCostComplete
): number {
  return currentScheduleHardCostComplete - previousScheduleHardCostComplete;
}

/**
 * Related to ConstructionDrawSchedule
 * @param landAdvance from construction Mortgage table
 * @param previousDrawSchedule
 * @param netRate from mortgage
 * @param currentScheduleDaysFromPreviousAdvance
 * @returns estimated interest deduction
 */
export function constructionEstimateInterestDeduction(
  landAdvance: number,
  previousDrawSchedule: DrawSchedule[],
  netRate: number,
  currentScheduleDaysFromPreviousAdvance: number
) {
  if (currentScheduleDaysFromPreviousAdvance === 0) {
    return 0;
  }
  const availableAdvanceAmount = previousDrawSchedule.reduce(
    (sum, drawSchedule) => (sum += drawSchedule.availableAdvanceAmount),
    landAdvance
  );
  const estimatedInterestDeduction =
    availableAdvanceAmount *
    (((netRate * 0.01) / 365) * currentScheduleDaysFromPreviousAdvance);
  return Math.round(estimatedInterestDeduction * 100) / 100;
}

/**
 * related to construction draw schedule
 * @param netLoanLessLandAdvance from Construction Mortgage
 * @param previousDrawSchedules
 * @param currentDrawSchedule
 * @returns funds held back amount
 */
export function constructionFundsHeldBack(
  netLoanLessLandAdvance: number,
  previousDrawSchedules: DrawSchedule[],
  currentDrawSchedule: DrawSchedule
) {
  const netLoanSubtractedPreviousAdvanceAmounts = previousDrawSchedules.reduce(
    (amount, schedule) =>
      (amount -=
        schedule.availableAdvanceAmount -
        schedule.estimatedInterestToBeDeducted),
    netLoanLessLandAdvance
  );
  const fundsHeldBack =
    netLoanSubtractedPreviousAdvanceAmounts -
    currentDrawSchedule.availableAdvanceAmount -
    currentDrawSchedule.estimatedInterestToBeDeducted;
  return Math.round(fundsHeldBack * 100) / 100;
}

/**
 * related to construction draw schedule
 * @param currentScheduleDrawDateAsString draw date
 * @param previousScheduleDrawDateAsString draw date
 * @returns difference in number of days from previous schedule draw date
 */
export function constructionScheduleDaysFromPreviousAdvance(
  currentScheduleDrawDateAsString: string,
  previousScheduleDrawDateAsString: string
) {
  const currentScheduleDrawDate = new Date(currentScheduleDrawDateAsString);
  const previousScheduleDrawDate = new Date(previousScheduleDrawDateAsString);
  const difference = differenceInDays(
    currentScheduleDrawDate,
    previousScheduleDrawDate
  );
  return Math.floor(difference);
}

export function constructionScheduleCtc(
  buildingBudget: number,
  hardCostCompleteToDate: number
) {
  return buildingBudget - hardCostCompleteToDate;
}

/**
 * related to ConstructionDefault
 * @param constructionDefault
 * @returns total percentage used. The total percentage of the construction defaults
 */
export function constructionDefaultsPercentageUsed(
  constructionDefaults: ConstructionDefault[]
): number {
  const total = constructionDefaults.reduce(
    (sum, constructionDefault) => (sum += constructionDefault.percentage),
    0
  );
  return Math.round(total * 10) / 10;
}
