import {
  AddressExpandedKey,
  ApplicantKey,
  ApplicationKey,
  ClosingInstructionKey,
  ConditionKey,
  DownPaymentKey,
  FeeKey,
  FinancialAssetKey,
  FinancialLiabilityKey,
  JobKey,
  MortgageKey,
  PropertyAppraisalKey,
  PropertyInsuranceKey,
  PropertyKey,
  PropertyOwnerKey,
  SupportedCustomEntity,
} from '@fundmoreai/models';
import {
  CitizenshipStatusTypeRecord,
  ApplicationPurposeRecord,
  ApplicationTypeRecord,
  AppraisalTypeRecord,
  AppraisalSourceRecord,
  AssetTypeRecord,
  CompoundPeriodRecord,
  CustomerTypeRecord,
  DownPaymentTypeRecord,
  DwellingStyleRecord,
  DwellingTypeRecord,
  EmploymentTypeRecord,
  FeePayerRecord,
  FeeTypeRecord,
  FoundationTypeRecord,
  GarageSizeRecord,
  GarageTypeRecord,
  GenderRecord,
  HeatingTypeRecord,
  IncomeTypeRecord,
  IndustrySectorTypeRecord,
  InsurerRecord,
  LiabilityTypeRecord,
  LoanTypeRecord,
  LocationTierTypeRecord,
  MaritalTypeRecord,
  MortgageClassificationTypeRecord,
  MortgagePositionTypeRecord,
  OccupancyTypeRecord,
  OccupationTypeRecord,
  PaymentFrequencyRecord,
  PaymentTypeRecord,
  PayoffRecord,
  PrepaymentTypeRecord,
  PropertyInsuranceTypeRecord,
  PropertyTypesRecord,
  RateTypeRecord,
  RelationshipToPrimaryApplicantRecord,
  RepaymentTypeRecord,
  SalutationRecord,
  SewageTypeRecord,
  TaxesPaidByTypeRecord,
  TenureTypeRecord,
  TermMonthsRecord,
  TermTypeRecord,
  WaterTypeRecord,
  ZoningTypesRecord,
  ConstructionTypeRecord,
  IncomePeriodRecord,
  FinancialLiabilitySourceRecord,
  RentalOffsetOptionsRecord,
  PoliticallyExposedPersonTypeRecord,
  FraudAMLRiskTypeRecord,
  ThirdPartyDeterminationTypeRecord,
  RequestNameScreeningTypeRecord,
  AccountTypeRecord,
  FinancialInstitutionTypeRecord,
  ProvinceRecord,
  CountryRecord,
  StreetTypeRecord,
  StreetDirectionRecord,
  AddressExpandedTypeRecord,
  ResidentialTypeRecord,
  ResidentialStatusTypeRecord,
  EQFraudAMLRiskRecord,
  IncomeVerificationRecord,
  EQClosingServiceRecord,
  LendingTierRecord,
  ResponsibleTypeRecord,
  SourceOfBusinessRecord,
  PayoffPaydownRecord,
  ConformingTypeRecord,
  ProgramCodeTypeRecord,
  PurposeCodeRecord,
  PaymentSourceRecord,
  MortgageClosingInstructionRecord,
  SecurityTypeRecord,
  TypeOfClosingInstructionRecord,
} from '../enum-records';
import { FCTInsuranceStatusRecord } from '../fct-enum-records';
// Applicant
export type ApplicantColumnWithOptions =
  | ApplicantKey.CUSTOMER_TYPE
  | ApplicantKey.SALUTATION
  | ApplicantKey.GENDER
  | ApplicantKey.RELATIONSHIP_TO_PRIMARY_APPLICANT
  | ApplicantKey.MARITAL_STATUS
  | ApplicantKey.CITIZENSHIP_STATUS
  | ApplicantKey.POLITICALLY_EXPOSED_PERSON_STATUS
  | ApplicantKey.FRAUD_AML_RISK
  | ApplicantKey.THIRD_PARTY_DETERMINATION
  | ApplicantKey.REQUEST_NAME_SCREENING
  | ApplicantKey.ACCOUNT_TYPE
  | ApplicantKey.FINANCIAL_INSTITUTION
  | ApplicantKey.EQ_FRAUD_AML_RISK
  | ApplicantKey.INCOME_VERIFICATION
  | ApplicantKey.PAYMENT_SOURCE;

export type ApplicantOptionType =
  | typeof CustomerTypeRecord
  | typeof SalutationRecord
  | typeof GenderRecord
  | typeof RelationshipToPrimaryApplicantRecord
  | typeof MaritalTypeRecord
  | typeof CitizenshipStatusTypeRecord
  | typeof PoliticallyExposedPersonTypeRecord
  | typeof FraudAMLRiskTypeRecord
  | typeof ThirdPartyDeterminationTypeRecord
  | typeof AccountTypeRecord
  | typeof FinancialInstitutionTypeRecord
  | typeof RequestNameScreeningTypeRecord
  | typeof EQFraudAMLRiskRecord
  | typeof IncomeVerificationRecord
  | typeof PaymentSourceRecord;

export const ApplicantOptionRecord: Record<ApplicantColumnWithOptions, ApplicantOptionType> = {
  [ApplicantKey.CUSTOMER_TYPE]: CustomerTypeRecord,
  [ApplicantKey.SALUTATION]: SalutationRecord,
  [ApplicantKey.GENDER]: GenderRecord,
  [ApplicantKey.RELATIONSHIP_TO_PRIMARY_APPLICANT]: RelationshipToPrimaryApplicantRecord,
  [ApplicantKey.MARITAL_STATUS]: MaritalTypeRecord,
  [ApplicantKey.CITIZENSHIP_STATUS]: CitizenshipStatusTypeRecord,
  [ApplicantKey.POLITICALLY_EXPOSED_PERSON_STATUS]: PoliticallyExposedPersonTypeRecord,
  [ApplicantKey.FRAUD_AML_RISK]: FraudAMLRiskTypeRecord,
  [ApplicantKey.THIRD_PARTY_DETERMINATION]: ThirdPartyDeterminationTypeRecord,
  [ApplicantKey.REQUEST_NAME_SCREENING]: RequestNameScreeningTypeRecord,
  [ApplicantKey.ACCOUNT_TYPE]: AccountTypeRecord,
  [ApplicantKey.FINANCIAL_INSTITUTION]: FinancialInstitutionTypeRecord,
  [ApplicantKey.EQ_FRAUD_AML_RISK]: EQFraudAMLRiskRecord,
  [ApplicantKey.INCOME_VERIFICATION]: IncomeVerificationRecord,
  [ApplicantKey.PAYMENT_SOURCE]: PaymentSourceRecord,
};

// Application
export type ApplicationColumnWithOptions =
  | ApplicationKey.PURPOSE
  | ApplicationKey.MORTGAGE_CLASSIFICATION
  | ApplicationKey.TYPE
  | ApplicationKey.CLOSING_SERVICE
  | ApplicationKey.LENDING_TIER
  | ApplicationKey.SOURCE_OF_BUSINESS
  | ApplicationKey.PURPOSE_CODE;

export type ApplicationOptionType =
  | typeof ApplicationPurposeRecord
  | typeof MortgageClassificationTypeRecord
  | typeof ApplicationTypeRecord
  | typeof EQClosingServiceRecord
  | typeof LendingTierRecord
  | typeof SourceOfBusinessRecord
  | typeof PurposeCodeRecord;

export const ApplicationOptionRecord: Record<ApplicationColumnWithOptions, ApplicationOptionType> =
  {
    [ApplicationKey.PURPOSE]: ApplicationPurposeRecord,
    [ApplicationKey.MORTGAGE_CLASSIFICATION]: MortgageClassificationTypeRecord,
    [ApplicationKey.TYPE]: ApplicationTypeRecord,
    [ApplicationKey.CLOSING_SERVICE]: EQClosingServiceRecord,
    [ApplicationKey.LENDING_TIER]: LendingTierRecord,
    [ApplicationKey.SOURCE_OF_BUSINESS]: SourceOfBusinessRecord,
    [ApplicationKey.PURPOSE_CODE]: PurposeCodeRecord,
  };

// Down Payment
export type DownPaymentColumnWithOptions = DownPaymentKey.SOURCE;

export type DownPaymentOptionType = typeof DownPaymentTypeRecord;

export const DownPaymentOptionRecord: Record<DownPaymentColumnWithOptions, DownPaymentOptionType> =
  {
    [DownPaymentKey.SOURCE]: DownPaymentTypeRecord,
  };

// Financial Asset
export type FinancialAssetColumnWithOptions = FinancialAssetKey.ASSET;

export type FinancialAssetOptionType = typeof AssetTypeRecord;

export const FinancialAssetOptionRecord: Record<
  FinancialAssetColumnWithOptions,
  FinancialAssetOptionType
> = {
  [FinancialAssetKey.ASSET]: AssetTypeRecord,
};

// Job
export type JobColumnWithOptions =
  | JobKey.OCCUPATION
  | JobKey.EMPLOYMENT_TYPE
  | JobKey.INDUSTRY_SECTOR
  | JobKey.PAYMENT_TYPE
  | JobKey.TYPE
  | JobKey.INCOME_PAYMENT_FREQUENCY;

export type JobOptionType =
  | typeof OccupationTypeRecord
  | typeof EmploymentTypeRecord
  | typeof IndustrySectorTypeRecord
  | typeof PaymentTypeRecord
  | typeof IncomeTypeRecord
  | typeof IncomePeriodRecord;

export const JobOptionRecord: Record<JobColumnWithOptions, JobOptionType> = {
  [JobKey.OCCUPATION]: OccupationTypeRecord,
  [JobKey.EMPLOYMENT_TYPE]: EmploymentTypeRecord,
  [JobKey.INDUSTRY_SECTOR]: IndustrySectorTypeRecord,
  [JobKey.PAYMENT_TYPE]: PaymentTypeRecord,
  [JobKey.TYPE]: IncomeTypeRecord,
  [JobKey.INCOME_PAYMENT_FREQUENCY]: IncomePeriodRecord,
};

// Property
export type PropertyColumnWithOptions =
  | PropertyKey.PROPERTY_TYPE
  | PropertyKey.DWELLING_STYLE
  | PropertyKey.DWELLING_TYPE
  | PropertyKey.FOUNDATION_TYPE
  | PropertyKey.GARAGE_SIZE
  | PropertyKey.GARAGE_TYPE
  | PropertyKey.SEWAGE_INFO
  | PropertyKey.WATER_TYPE
  | PropertyKey.TENURE
  | PropertyKey.HEATING_TYPE
  | PropertyKey.OCCUPANCY
  | PropertyKey.ZONING_TYPE
  | PropertyKey.LOCATION_TIER
  | PropertyKey.TAXES_PAID_BY
  | PropertyKey.CONSTRUCTION_TYPE
  | PropertyKey.RPE_RENTAL_OFFSET_OPTION;

export type PropertyOptionType =
  | typeof PropertyTypesRecord
  | typeof DwellingStyleRecord
  | typeof DwellingTypeRecord
  | typeof FoundationTypeRecord
  | typeof GarageSizeRecord
  | typeof GarageTypeRecord
  | typeof SewageTypeRecord
  | typeof WaterTypeRecord
  | typeof TenureTypeRecord
  | typeof HeatingTypeRecord
  | typeof OccupancyTypeRecord
  | typeof ZoningTypesRecord
  | typeof LocationTierTypeRecord
  | typeof TaxesPaidByTypeRecord
  | typeof ConstructionTypeRecord
  | typeof RentalOffsetOptionsRecord;

export const PropertyOptionRecord: Record<PropertyColumnWithOptions, PropertyOptionType> = {
  [PropertyKey.PROPERTY_TYPE]: PropertyTypesRecord,
  [PropertyKey.DWELLING_STYLE]: DwellingStyleRecord,
  [PropertyKey.DWELLING_TYPE]: DwellingTypeRecord,
  [PropertyKey.FOUNDATION_TYPE]: FoundationTypeRecord,
  [PropertyKey.GARAGE_SIZE]: GarageSizeRecord,
  [PropertyKey.GARAGE_TYPE]: GarageTypeRecord,
  [PropertyKey.SEWAGE_INFO]: SewageTypeRecord,
  [PropertyKey.WATER_TYPE]: WaterTypeRecord,
  [PropertyKey.TENURE]: TenureTypeRecord,
  [PropertyKey.HEATING_TYPE]: HeatingTypeRecord,
  [PropertyKey.OCCUPANCY]: OccupancyTypeRecord,
  [PropertyKey.ZONING_TYPE]: ZoningTypesRecord,
  [PropertyKey.LOCATION_TIER]: LocationTierTypeRecord,
  [PropertyKey.TAXES_PAID_BY]: TaxesPaidByTypeRecord,
  [PropertyKey.CONSTRUCTION_TYPE]: ConstructionTypeRecord,
  [PropertyKey.RPE_RENTAL_OFFSET_OPTION]: RentalOffsetOptionsRecord,
};

// Property Appraisal
export type PropertyAppraisalColumnWithOptions =
  | PropertyAppraisalKey.APPRAISAL_TYPE
  | PropertyAppraisalKey.SOURCE
  | PropertyAppraisalKey.FCT_INSURANCE_STATUS;

export type PropertyAppraisalOptionType =
  | typeof AppraisalTypeRecord
  | typeof AppraisalSourceRecord
  | typeof FCTInsuranceStatusRecord;

export const PropertyAppraisalOptionRecord: Record<
  PropertyAppraisalColumnWithOptions,
  PropertyAppraisalOptionType
> = {
  [PropertyAppraisalKey.APPRAISAL_TYPE]: AppraisalTypeRecord,
  [PropertyAppraisalKey.SOURCE]: AppraisalSourceRecord,
  [PropertyAppraisalKey.FCT_INSURANCE_STATUS]: FCTInsuranceStatusRecord,
};

// Property Owner
export type PropertyOwnerColumnWithOptions = PropertyOwnerKey.MARTIAL_STATUS;

export type PropertyOwnerOptionType = typeof MaritalTypeRecord;

export const PropertyOwnerOptionRecord: Record<
  PropertyOwnerColumnWithOptions,
  PropertyOwnerOptionType
> = {
  [PropertyOwnerKey.MARTIAL_STATUS]: MaritalTypeRecord,
};

// Property Insurance
export type PropertyInsuranceColumnWithOptions = PropertyInsuranceKey.TYPE;

export type PropertyInsuranceOptionType = typeof PropertyInsuranceTypeRecord;

export const PropertyInsuranceOptionRecord: Record<
  PropertyInsuranceColumnWithOptions,
  PropertyInsuranceOptionType
> = {
  [PropertyInsuranceKey.TYPE]: PropertyInsuranceTypeRecord,
};

// Mortgage
export type MortgageColumnWithOptions =
  | MortgageKey.LOAN_TYPE
  | MortgageKey.COMPOUNDING
  | MortgageKey.CONFORMING
  | MortgageKey.MORTGAGE_TYPE
  | MortgageKey.RATE_TYPE
  | MortgageKey.PAYMENT_FREQUENCY
  | MortgageKey.REPAYMENT_TYPE
  | MortgageKey.PREPAYMENT_TYPE
  | MortgageKey.TERM_TYPE
  | MortgageKey.TERM
  | MortgageKey.INSURER
  | MortgageKey.PAYOFF
  | MortgageKey.PAYOFF_PAYDOWN
  | MortgageKey.PROGRAM_CODE
  | MortgageKey.TYPE_OF_CLOSING_INSTRUCTION;

export type MortgageOptionType =
  | typeof LoanTypeRecord
  | typeof CompoundPeriodRecord
  | typeof ConformingTypeRecord
  | typeof MortgagePositionTypeRecord
  | typeof RateTypeRecord
  | typeof PaymentFrequencyRecord
  | typeof RepaymentTypeRecord
  | typeof PrepaymentTypeRecord
  | typeof TermTypeRecord
  | typeof TermMonthsRecord
  | typeof InsurerRecord
  | typeof PayoffRecord
  | typeof PayoffPaydownRecord
  | typeof ProgramCodeTypeRecord
  | typeof MortgageClosingInstructionRecord;

export const MortgageOptionRecord: Record<MortgageColumnWithOptions, MortgageOptionType> = {
  [MortgageKey.LOAN_TYPE]: LoanTypeRecord,
  [MortgageKey.COMPOUNDING]: CompoundPeriodRecord,
  [MortgageKey.CONFORMING]: ConformingTypeRecord,
  [MortgageKey.MORTGAGE_TYPE]: MortgagePositionTypeRecord,
  [MortgageKey.RATE_TYPE]: RateTypeRecord,
  [MortgageKey.PAYMENT_FREQUENCY]: PaymentFrequencyRecord,
  [MortgageKey.REPAYMENT_TYPE]: RepaymentTypeRecord,
  [MortgageKey.PREPAYMENT_TYPE]: PrepaymentTypeRecord,
  [MortgageKey.TERM_TYPE]: TermTypeRecord,
  [MortgageKey.TERM]: TermMonthsRecord,
  [MortgageKey.INSURER]: InsurerRecord,
  [MortgageKey.PAYOFF]: PayoffRecord,
  [MortgageKey.PAYOFF_PAYDOWN]: PayoffPaydownRecord,
  [MortgageKey.PROGRAM_CODE]: ProgramCodeTypeRecord,
  [MortgageKey.TYPE_OF_CLOSING_INSTRUCTION]: MortgageClosingInstructionRecord,
};

// Fee
export type FeeColumnWithOptions = FeeKey.TYPE | FeeKey.PAID_BY;

export type FeeOptionType = typeof FeeTypeRecord | typeof FeePayerRecord;

export const FeeOptionRecord: Record<FeeColumnWithOptions, FeeOptionType> = {
  [FeeKey.TYPE]: FeeTypeRecord,
  [FeeKey.PAID_BY]: FeePayerRecord,
};

// Financial Liability
export type FinancialLiabilityColumnWithOptions =
  | FinancialLiabilityKey.LIABILITY
  | FinancialLiabilityKey.PAYOFF
  | FinancialLiabilityKey.SOURCE
  | FinancialLiabilityKey.SECURITY_TYPE;

export type FinancialLiabilityOptionType =
  | typeof LiabilityTypeRecord
  | typeof PayoffRecord
  | typeof FinancialLiabilitySourceRecord
  | typeof SecurityTypeRecord;

export const FinancialLiabilityOptionRecord: Record<
  FinancialLiabilityColumnWithOptions,
  FinancialLiabilityOptionType
> = {
  [FinancialLiabilityKey.LIABILITY]: LiabilityTypeRecord,
  [FinancialLiabilityKey.PAYOFF]: PayoffRecord,
  [FinancialLiabilityKey.SOURCE]: FinancialLiabilitySourceRecord,
  [FinancialLiabilityKey.SECURITY_TYPE]: SecurityTypeRecord,
};

// Address Expanded
export type AddressExpandedColumnWithOptions =
  | AddressExpandedKey.PROVINCE
  | AddressExpandedKey.COUNTRY
  | AddressExpandedKey.STREET_TYPE
  | AddressExpandedKey.STREET_DIRECTION
  | AddressExpandedKey.TYPE
  | AddressExpandedKey.RESIDENTIAL_TYPE
  | AddressExpandedKey.RESIDENTIAL_STATUS_TYPE;

export type AddressExpandedOptionType =
  | typeof ProvinceRecord
  | typeof CountryRecord
  | typeof StreetTypeRecord
  | typeof StreetDirectionRecord
  | typeof AddressExpandedTypeRecord
  | typeof ResidentialTypeRecord
  | typeof ResidentialStatusTypeRecord;

export const AddressExpandedOptionRecord: Record<
  AddressExpandedColumnWithOptions,
  AddressExpandedOptionType
> = {
  [AddressExpandedKey.PROVINCE]: ProvinceRecord,
  [AddressExpandedKey.COUNTRY]: CountryRecord,
  [AddressExpandedKey.STREET_TYPE]: StreetTypeRecord,
  [AddressExpandedKey.STREET_DIRECTION]: StreetDirectionRecord,
  [AddressExpandedKey.TYPE]: AddressExpandedTypeRecord,
  [AddressExpandedKey.RESIDENTIAL_TYPE]: ResidentialTypeRecord,
  [AddressExpandedKey.RESIDENTIAL_STATUS_TYPE]: ResidentialStatusTypeRecord,
};

// Condition of Approval
export const ConditionOfApprovalOptionRecord: Record<
  ConditionOfApprovalColumnWithOptions,
  ConditionOfApprovalOptionType
> = {
  [ConditionKey.RESPONSIBLE]: ResponsibleTypeRecord,
};

export type ConditionOfApprovalColumnWithOptions = ConditionKey.RESPONSIBLE;

export type ConditionOfApprovalOptionType = typeof ResponsibleTypeRecord;

// Closing Instruction
export const ClosingInstructionOptionRecord: Record<
  ClosingInstructionColumnWithOptions,
  ClosingInstructionOptionType
> = {
  [ClosingInstructionKey.TYPE_OF_CLOSING_INSTRUCTION]: TypeOfClosingInstructionRecord,
};

export type ClosingInstructionColumnWithOptions = ClosingInstructionKey.TYPE_OF_CLOSING_INSTRUCTION;

export type ClosingInstructionOptionType = typeof TypeOfClosingInstructionRecord;

// Entities with default options
export type EntityOptionType =
  | typeof ApplicantOptionRecord
  | typeof ApplicationOptionRecord
  | typeof DownPaymentOptionRecord
  | typeof FinancialAssetOptionRecord
  | typeof JobOptionRecord
  | typeof PropertyOptionRecord
  | typeof PropertyAppraisalOptionRecord
  | typeof MortgageOptionRecord
  | typeof PropertyOwnerOptionRecord
  | typeof FeeOptionRecord
  | typeof PropertyInsuranceOptionRecord
  | typeof FinancialLiabilityOptionRecord
  | typeof AddressExpandedOptionRecord
  | typeof ConditionOfApprovalOptionRecord
  | typeof ClosingInstructionOptionRecord;

export const CustomEntityRecord: Record<SupportedCustomEntity, EntityOptionType | null> = {
  [SupportedCustomEntity.APPLICANT]: ApplicantOptionRecord,
  [SupportedCustomEntity.APPLICATION]: ApplicationOptionRecord,
  [SupportedCustomEntity.DOWN_PAYMENT]: DownPaymentOptionRecord,
  [SupportedCustomEntity.FEE]: FeeOptionRecord,
  [SupportedCustomEntity.FINANCIAL_LIABILITY]: FinancialLiabilityOptionRecord,
  [SupportedCustomEntity.FINANCIAL_ASSET]: FinancialAssetOptionRecord,
  [SupportedCustomEntity.JOB]: JobOptionRecord,
  [SupportedCustomEntity.MORTGAGE]: MortgageOptionRecord,
  [SupportedCustomEntity.PROPERTY]: PropertyOptionRecord,
  [SupportedCustomEntity.PROPERTY_APPRAISAL]: PropertyAppraisalOptionRecord,
  [SupportedCustomEntity.PROPERTY_INSURANCE]: PropertyInsuranceOptionRecord,
  [SupportedCustomEntity.PROPERTY_OWNER]: PropertyOwnerOptionRecord,
  [SupportedCustomEntity.ADDRESS_EXPANDED]: AddressExpandedOptionRecord,
  [SupportedCustomEntity.CONDITION_OF_APPROVAL]: ConditionOfApprovalOptionRecord,
  [SupportedCustomEntity.CONSTRUCTION_MORTGAGE]: null,
  [SupportedCustomEntity.BROKER_COMMISSION]: null,
  [SupportedCustomEntity.CLOSING_INSTRUCTION]: ClosingInstructionOptionRecord,
};
