import { Injectable } from '@angular/core';
import { Property, Mortgage } from '../../shared';
import { MortgageType, PropertyViewModel, PropertyMortgages } from '@fundmoreai/models';

@Injectable({
  providedIn: 'root',
})
export class PropertyViewModelService {
  public propertiesMortgagesViewModel(
    properties: Property[] | undefined,
    allMortgages: Mortgage[],
    appraisals?: boolean[],
  ): PropertyViewModel[] {
    const propertiesViewModel: PropertyViewModel[] = [];

    properties?.forEach((property, i) => {
      const propertyMortgages = allMortgages.filter(
        (mortgage) =>
          mortgage.propertyId === property.id && mortgage.type !== MortgageType.REQUESTED,
      );
      const propertyMortgagesSummary: PropertyMortgages = {} as PropertyMortgages;

      propertyMortgages.forEach((mortgage) => {
        if (mortgage.monthlyPayment) {
          if (propertyMortgagesSummary.monthlyPayment) {
            propertyMortgagesSummary.monthlyPayment += mortgage.monthlyPayment;
          } else {
            propertyMortgagesSummary.monthlyPayment = mortgage.monthlyPayment;
          }
        }

        if (mortgage.mortgageBalance) {
          if (propertyMortgagesSummary.balance) {
            propertyMortgagesSummary.balance += mortgage.mortgageBalance;
          } else {
            propertyMortgagesSummary.balance = mortgage.mortgageBalance;
          }
        }
      });

      const propertyViewModel = {
        ...this.propertyViewModel(property)!,
        ...{ mortgages: propertyMortgagesSummary },
        Mortgages: propertyMortgages, // overriding `property.Mortgages` because that data could be oudated. mortgages.state.ts should have the latest updates though
        isAppraised: appraisals ? appraisals[i] : false,
      };
      propertiesViewModel.push(propertyViewModel);
    });

    return propertiesViewModel.sort(
      (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
    );
  }

  private propertyViewModel(property: Property | undefined | null): PropertyViewModel | undefined {
    if (!property) {
      return undefined;
    }
    const propertyViewModel: PropertyViewModel = {} as PropertyViewModel;

    Object.assign(propertyViewModel, {
      ...property,
    });

    return propertyViewModel;
  }
}
