import { Property } from '@fundmoreai/models';
import {
  computeAnnualExpensesForGDS,
  computeAnnualExpensesForTDS,
} from './property-expenses.calculator';

export type ComputeCostToCarryPropertyInput = Pick<
  Property,
  | 'annualTaxes'
  | 'condoFees'
  | 'condoFeesIncludeHeating'
  | 'condoFeesPercentInCalculation'
  | 'includeExpensesInTDS'
  | 'includeAnnualTaxesInTDS'
  | 'includeCondoFeesInTDS'
  | 'includeHeatingCostInTDS'
  | 'heatingCost'
  | 'rpeGeneralExpenses'
  | 'rpeHydro'
  | 'rpeInsurance'
  | 'rpeInterestCharges'
  | 'rpeManagementExpenses'
  | 'rpeRepairs'
>;

export function computeCostToCarryForGDS(
  property: ComputeCostToCarryPropertyInput | undefined,
  monthlyPayments: number | undefined,
): number {
  let costToCarry = 0;

  if (monthlyPayments) {
    costToCarry += monthlyPayments;
  }
  if (property) {
    costToCarry += computeAnnualExpensesForGDS(property) / 12;
  }
  return costToCarry;
}

export function computeCostToCarryForTDS(
  property: ComputeCostToCarryPropertyInput | undefined,
  monthlyPayments: number | undefined,
): number {
  let costToCarry = 0;

  if (monthlyPayments) {
    costToCarry += monthlyPayments;
  }

  if (property) {
    costToCarry += computeAnnualExpensesForTDS(property) / 12;
  }
  return costToCarry;
}
