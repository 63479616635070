export enum CmhcBorrowerIndustrySector {
  CONSTRUCTION = '01',
  GOVERNMENT = '02',
  HEALTH = '03',
  EDUCATION = '04',
  HI_TECH = '05',
  RETAIL_SALES = '06',
  LEISURE_ENTERTAINMENT = '07',
  BANKING_FINANCE = '08',
  TRANSPORT = '09',
  SERVICES = '10',
  MANUFACTURING = '11',
  FARMING_NAT_RESOURCES = '12',
  OTHER = '99',
}
