import { CmhcApplicationResponse } from './cmhc/applicationResponse';
import { CmhcInsuranceApplicationDetails } from './cmhc/submitApplication';

import { SagenInsuranceRequest } from './sagen/sagenInsuranceQuoteRequest';
import {
  SagenInsuranceAcknowledgeResponse,
  SagenInsuranceResponse,
} from './sagen/sagenInsuranceQuoteResponse';
import { CGInsuranceResponseData } from './cg/cgInsuranceQuoteResponse';
import { CGInsuranceRequest } from './cg/cgInsuranceQuoteRequest';
import { LoanType } from '../enums';

export * from './sagen/sagenInsuranceQuoteRequest';
export * from './sagen/sagenInsuranceQuoteResponse';

export * from './cg/cgInsuranceQuoteRequest';
export * from './cg/cgInsuranceQuoteResponse';

export interface CmhcInsuranceRequest {
  tenantCode: string;
  cmhcAccountNumber: string;
  insuranceApplication: CmhcInsuranceApplicationDetails;
  document?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CanadaGuarantyInsuranceRequest {}

export enum InsurerType {
  SAGEN = 'SAGEN',
  CANADA_GUARANTY = 'CANADA_GUARANTY',
  CMHC = 'CMHC',
}

export interface InsuranceRequestedMortgageData {
  mortgageId: string;
  loanType: LoanType;
  loanAmount: number;
}

export interface InsuranceQuote {
  id: string;
  externalSystemId: string;
  externalBusinessId: string;
  applicationId: string;
  insurerType: InsurerType;
  status: string;
  request?: SagenInsuranceRequest | CmhcInsuranceRequest | CGInsuranceRequest;
  response?:
    | SagenInsuranceAcknowledgeResponse
    | SagenInsuranceResponse
    | CmhcApplicationResponse
    | CGInsuranceResponseData;
  createdAt: string;
  updatedAt: string;
  resubmissionCount: number;
  requestedMortgageData?: InsuranceRequestedMortgageData;
}

export enum InsuranceQuoteKey {
  ID = 'id',
  INSURANCE_TYPE = 'insurerType',
  QUOTE_NUMBER = 'quoteNumber',
  FORCE_UW_REVIEW = 'forceUwReview',
  SPECIAL_PROGRAM_CODE = 'specialProgramCode',
  ADVANCE_TYPE = 'advanceType',
  PROGRESS_ADVANCE_TYPE = 'progressAdvanceType',
  INSPECTION_FLAG = 'inspectionFlag',
  INSURANCE_INDICATOR = 'insuranceIndicator',
  COMMENTS = 'comments',
}
