import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EqLoanUpdatePayload } from './eq-core.state';

export interface EqLoanUpdateModel {
  applicationId?: string;
}

export interface EqMapsErrorAttribute {
  name: string;
  value: string;
  description: string;
}
export interface EqError {
  systemNotes?: EqMapsErrorAttribute[];
  isLoanUpdateSuccessful: boolean;
  loanDecisionErrors?: EqMapsErrorAttribute[];
  isLoanDecisionSuccessful: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class EqCoreService {
  constructor(private http: HttpClient) {}

  loanUpdate(payload: EqLoanUpdatePayload) {
    return this.http.post(
      `${environment.eqMaps_api_url}/applications/${payload.applicationId}/loanUpdate`,
      {
        type: payload.type,
      },
    );
  }

  loanDecisionRetry(command: EqLoanUpdateModel) {
    return this.http.post(
      `${environment.eqMaps_api_url}/applications/${command.applicationId}/loanDecisionRetry`,
      command,
    );
  }

  getErrors(applicationId: string): Observable<EqError | undefined> {
    return this.http.get<EqError>(
      `${environment.fundmore_api_url}/applications/${applicationId}/systemNotes`,
    );
  }
}
