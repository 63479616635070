export enum FCTSecondaryServiceType {
  SINGLE_FAMILY_DWELLING_DESKTOP = '001',
  DUPLEX_DESKTOP = '002',
  TRIPLEX_DESKTOP = '003',
  FOUR_UNIT_DESKTOP = '004',
  VACANT_LAND_DESKTOP = '005',
  SINGLE_FAMILY_DWELLING_DRIVE_BY = '007',
  DUPLEX_DRIVE_BY = '008',
  TRIPLEX_DRIVE_BY = '009',
  FOUR_UNIT_DRIVE_BY = '010',
  VACANT_LAND_DRIVE_BY = '011',
  EXECUTIVE_PROPERTY_DRIVE_BY = '012',
  FORECLOSURE_DRIVE_BY = '013',
  SINGLE_FAMILY_DWELLING_FULL_APPRAISAL = '018',
  DUPLEX = '019',
  TRIPLEX = '020',
  FOUR_UNIT_FULL_APPRAISAL = '021',
  VACANT_LAND_FULL_APPRAISAL = '022',
  EXECUTIVE_PROPERTY_FULL_APPRAISAL = '023',
  FORECLOSURE_FULL_APPRAISAL = '024',
  PROGRESS_INSPECTION = '032',
  MARKET_RENT = '033',
  INSURED_AVM = '034',
  RETRO_DRIVE_BY = '035',
  RETRO_DESKTOP = '036',
  APPRAISAL_ASSISTED_PRODUCT_IAVM = '037',
  AVM = '038',
  INSURED_AVM_2 = '039',
  FLEX = '040',
  AAP_DRIVE_BY = '041',
  AAP_DESKTOP = '042',
  AAP_FULL_APPRAISAL = '043',
  UNINSURED_FLEX = '049',
}
