import { DocumentRequest } from '@fundmoreai/models';

export class AddDocumentRequest {
  static readonly type = '@documentRequest.AddDocumentRequest';

  constructor(public documentRequest: Partial<DocumentRequest>) {}
}

/** @deprecated */
export class RemoveLinkedDocumentType {
  static readonly type = '@documentRequest.RemoveLinkedDocumentType';

  constructor(public applicationId: string, public documentTypeId: string) {}
}

export class DeleteDocumentRequest {
  static readonly type = '@documentRequest.DeleteDocumentRequest';

  constructor(public documentRequestId: string) {}
}

export class BulkDeleteDocumentRequests {
  static readonly type = '@documentRequest.BulkDeleteDocumentRequests';

  constructor(public documentRequestIds: string[]) {}
}

export class FetchDocumentRequests {
  static readonly type = '@documentRequest.FetchDocumentRequests';

  constructor(public applicationId: string) {}
}

export class CreateDocumentSignatureRequest {
  static readonly type = '@documentRequest.CreateDocumentSignatureRequest';

  constructor(
    public documentRequest: Partial<DocumentRequest>,
    public documentToSignIds: string[],
  ) {}
}

export class ShareDocumentRequest {
  static readonly type = '@documentRequest.ShareDocumentRequest';

  constructor(
    public applicationId: string,
    public documentTypeIds: string[],
    public email: string,
    public comments: string,
  ) {}
}
