import { FCTBorrowerType } from '../enums/borrowerType';
import { FCTRequestedAppointmentTime } from '../enums/requestedAppointmentTime';

export interface FCTBorrowerInfo {
  BorrowerType: FCTBorrowerType;
  FirstName: string;
  LastName: string;
  CreditScore: number; // int
  Phone: string; // xxx-xxx-xxxx,xxxxxxxxxx
  Cell: string; // xxx-xxx-xxxx,xxxxxxxxxx
  Email: string; //name@domain.xxx
  RequestedAppointmentDate: string; // datetime YYYY-MM-DD
  RequestedAppointmentTime: FCTRequestedAppointmentTime;
  RequestedAppointmentReason: string;
}
