<editor
  class="m-8"
  #editor
  [apiKey]="apiKey"
  [(ngModel)]="noteContent"
  (ngModelChange)="onChange()"
  [init]="initTinyMCE">
</editor>

<div class="d-flex justify-content-end action-buttons">
  <button type="button" mat-button [disableRipple]="true" (click)="cancel()" class="button-group">
    <ng-container i18n="@@action.cancel">Cancel</ng-container>
  </button>

  <button
    class="ml-8 button-group"
    color="accent"
    mat-raised-button
    type="button"
    i18n
    (click)="generateContentWithAI()"
    *ngIf="aiNotesGenerationEnabled"
    i18n-matTooltip
    [matTooltip]="'Generate a note using AI'">
    Generate <mat-icon>language</mat-icon>
  </button>

  <div
    class="button-group ml-8"
    i18n-matTooltip
    matTooltip="Note is empty"
    [matTooltipDisabled]="noteContent.length !== 0">
    <ng-container *ngIf="!note?.id; else draftNoteActions">
      <button
        mat-raised-button
        color="primary"
        [disableRipple]="true"
        (click)="save()"
        [disabled]="noteContent.length === 0">
        <ng-container i18n="@@action.save">Save</ng-container>
      </button>

      <button
        mat-raised-button
        color="primary"
        [matMenuTriggerFor]="saveOptionsMenu"
        [disabled]="noteContent.length === 0">
        <mat-icon>expand_more</mat-icon>
      </button>

      <mat-menu #saveOptionsMenu="matMenu">
        <button mat-menu-item (click)="saveAsDraft()" [disabled]="noteContent.length === 0">
          <ng-container i18n="@@action.saveAsDraft">Save as draft</ng-container>
        </button>
      </mat-menu>
    </ng-container>

    <ng-template #draftNoteActions>
      <button
        mat-raised-button
        color="primary"
        [disableRipple]="true"
        (click)="publish()"
        [disabled]="noteContent.length === 0">
        <ng-container i18n="@@action.publish">Publish</ng-container>
      </button>

      <button
        mat-raised-button
        color="primary"
        [matMenuTriggerFor]="saveOptionsMenu"
        [disabled]="noteContent.length === 0">
        <mat-icon>expand_more</mat-icon>
      </button>

      <mat-menu #saveOptionsMenu="matMenu">
        <button mat-menu-item (click)="updateDraft()" [disabled]="noteContent.length === 0">
          <ng-container i18n>Update draft</ng-container>
        </button>
      </mat-menu>
    </ng-template>
  </div>
</div>
