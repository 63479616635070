export enum CmhcStreetDirection {
  N = 'N',
  E = 'E',
  S = 'S',
  W = 'W',
  O = 'O',
  NE = 'NE',
  NW = 'NW',
  NO = 'NO',
  SE = 'SE',
  SW = 'SW',
  SO = 'SO',
}
