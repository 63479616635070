import { MMSDocumentSource, MMSFileFormat } from '../../../enums';
import { MMSDocumentType } from '../../../enums/documentType';

export interface MMSDocument {
  documentId: string;
  documentType: MMSDocumentType | string;
  version?: string;
  content: string; // base64 encoded
  documentFormat: MMSFileFormat;
  source: MMSDocumentSource;
  submissionDate: string; // date-time
}
