import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, catchError, retry, timer } from 'rxjs';
import { FmErrorHandlerService } from './fm-error-handler.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(private errorHandlerService: FmErrorHandlerService) {}

  intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      retry({
        count: 3,
        delay: (error, attempt) => {
          if (
            error.status === 429 ||
            error.status === 502 ||
            error.status === 503 ||
            error.status === 504
          ) {
            return timer((attempt + 2) * 1000);
          }
          throw error;
        },
      }),
      catchError((error) => {
        this.errorHandlerService.handleHttpInterceptorError(error);
        return throwError(() => error);
      }),
    );
  }
}
