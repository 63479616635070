import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { NgIf } from '@angular/common';

@Component({
  selector: 'fm-notes-read-more',
  templateUrl: './notes-read-more.component.html',
  styleUrls: ['./notes-read-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, MatButtonModule, MatIconModule],
})
export class NotesReadMoreComponent implements OnInit, AfterContentInit, AfterContentChecked {
  collapsed: boolean;
  collapsible: boolean;

  @ViewChild('textElement', { static: true })
  textElement: ElementRef;

  private content: string;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.computeCollapse();
  }

  ngAfterContentInit(): void {
    this.content = this.textElement.nativeElement.innerText.trim();
  }

  ngAfterContentChecked(): void {
    const c = this.textElement.nativeElement.innerText.trim();

    if (c !== this.content) {
      this.content = c;
      this.computeCollapse();
    }
  }

  toggleReadMore(event: MouseEvent) {
    event.stopImmediatePropagation();

    this.collapsed = !this.collapsed;
  }

  private computeCollapse() {
    this.collapsed = true;
    this.collapsible = false;

    setTimeout(() => {
      if (
        this.textElement.nativeElement.offsetHeight === this.textElement.nativeElement.scrollHeight
      ) {
        this.collapsed = false;
      } else {
        this.collapsible = true;
      }

      this.cdr.markForCheck();
    }, 0);
  }
}
