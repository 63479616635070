import { Injectable } from '@angular/core';
import { ApplicationAggregate, FutureAggregate } from '@fundmoreai/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs';
import { LoadingEnd, LoadingStart } from 'src/app/core/loading.state';
import {
  ComputeApplicationAggregates,
  FetchApplicationAggregates,
  RefreshBusinessBankingRelationship,
} from './application-aggregates.action';
import { ApplicationAggregatesService } from './application-aggregates.service';

export interface ApplicationAggregatesStateModel {
  aggregates?: ApplicationAggregate;
}

@State<ApplicationAggregatesStateModel>({
  name: 'applicationAggregates',
  defaults: {
    aggregates: undefined,
  },
})
@Injectable()
export class ApplicationAggregatesState {
  constructor(private applicationAggregatesService: ApplicationAggregatesService) {}

  @Selector() static aggregates(state: ApplicationAggregatesStateModel) {
    return state.aggregates;
  }
  @Selector() static futureAggregates(
    state: ApplicationAggregatesStateModel,
  ): FutureAggregate | undefined {
    if (!state.aggregates) {
      return undefined;
    }
    return {
      id: state.aggregates.id,
      applicationId: state.aggregates.applicationId,
      bridgeSecuredFutureInput: state.aggregates.bridgeSecuredFutureInput,
      computedBridgeSecuredFuture: state.aggregates.computedBridgeSecuredFuture,
      computedMortgageSecuredFuture: state.aggregates.computedMortgageSecuredFuture,
      computedNonMortgageLiabilitiesFuture: state.aggregates.computedNonMortgageLiabilitiesFuture,
      computedTotalConnectionFuture: state.aggregates.computedTotalConnectionFuture,
      mortgageSecuredFutureInput: state.aggregates.mortgageSecuredFutureInput,
      nonMortgageLiabilitiesFutureInput: state.aggregates.nonMortgageLiabilitiesFutureInput,
      totalConnectionFutureInput: state.aggregates.totalConnectionFutureInput,
    };
  }

  @Action(FetchApplicationAggregates) fetchApplicationAggregates(
    ctx: StateContext<ApplicationAggregatesStateModel>,
    action: FetchApplicationAggregates,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationAggregatesService.getApplicationAggregates(action.applicationId).pipe(
      tap(({ aggregates }) => {
        ctx.patchState({ aggregates });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(RefreshBusinessBankingRelationship) refreshBusinessBankingRelationship(
    ctx: StateContext<ApplicationAggregatesStateModel>,
    action: RefreshBusinessBankingRelationship,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationAggregatesService
      .refreshBusinessBankingRelationship(action.applicationId)
      .pipe(
        tap(({ computedResult }) => {
          if (!computedResult) {
            return;
          }
          ctx.patchState({ aggregates: computedResult });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(ComputeApplicationAggregates) computeApplicationAggregates(
    ctx: StateContext<ApplicationAggregatesStateModel>,
    action: ComputeApplicationAggregates,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.applicationAggregatesService
      .computeApplicationAggregate(action.applicationId, action.input)
      .pipe(
        tap(({ computedResult }) => ctx.patchState({ aggregates: computedResult })),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }
}
