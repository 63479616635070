import { Injectable } from '@angular/core';
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import { CognitoService } from './cognito.service';
import { Observable, ReplaySubject } from 'rxjs';
import { AuthResult } from '.';
import { AuthResultStatus } from './model';
import { signOut, signInWithRedirect } from 'aws-amplify/auth';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(public cognitoService: CognitoService, private router: Router) {}

  authenticate(_username: string, password: string): Observable<AuthResult> {
    const username = _username?.trim();
    const result = new ReplaySubject<AuthResult>(1);

    const authenticationData = {
      Username: username,
      Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);

    const userData = {
      Username: username,
      Pool: this.cognitoService.getUserPool(),
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.setAuthenticationFlowType('USER_PASSWORD_AUTH');
    cognitoUser.authenticateUser(authenticationDetails, {
      newPasswordRequired: (userAttributes, requiredAttributes) => {
        result.next(
          new AuthResult(AuthResultStatus.NEW_PASSWORD_REQUIRED, {
            userAttributes,
            requiredAttributes,
            cognitoUser,
          }),
        );
      },
      onSuccess: (session, userConfirmationNecessary) =>
        result.next(
          new AuthResult(AuthResultStatus.SUCCESS, { session, userConfirmationNecessary }),
        ),
      onFailure: (err) => result.error(err),
      mfaRequired: (challengeName, challengeParameters) => {
        result.next(
          new AuthResult(AuthResultStatus.MFA_REQUIRED, {
            challengeName,
            challengeParameters,
            cognitoUser,
          }),
        );
      },
      totpRequired: (challengeName, challengeParameters) => {
        result.next(
          new AuthResult(AuthResultStatus.TOTP_REQUIRED, {
            challengeName,
            challengeParameters,
            cognitoUser,
          }),
        );
      },

      customChallenge: (challengeParameters) => {
        result.next(
          new AuthResult(AuthResultStatus.CUSTOM_CHALLENGE, { challengeParameters, cognitoUser }),
        );
      },

      mfaSetup: (challengeName, challengeParameters) => {
        result.next(
          new AuthResult(AuthResultStatus.MFA_SETUP, {
            challengeName,
            challengeParameters,
            cognitoUser,
          }),
        );
      },

      selectMFAType: (challengeName, challengeParameters) => {
        result.next(
          new AuthResult(AuthResultStatus.SELECT_MFA_TYPE, {
            challengeName,
            challengeParameters,
            cognitoUser,
          }),
        );
      },
    });

    return result.asObservable();
  }

  forgotPassword(username: string): Observable<AuthResult> {
    const result = new ReplaySubject<AuthResult>(1);

    const userData = {
      Username: username,
      Pool: this.cognitoService.getUserPool(),
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
      onSuccess: (data) => result.next(new AuthResult(AuthResultStatus.SUCCESS, data)),
      onFailure: (err) => result.error(err),
      inputVerificationCode: (data) => {
        data.email = username;
        result.next(new AuthResult(AuthResultStatus.INPUT_VERIFICATION_CODE, data));
      },
    });
    return result.asObservable();
  }

  confirmNewPassword(
    email: string,
    verificationCode: string,
    password: string,
  ): Observable<AuthResult> {
    const result = new ReplaySubject<AuthResult>(1);
    const userData = {
      Username: email,
      Pool: this.cognitoService.getUserPool(),
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.confirmPassword(verificationCode, password, {
      onSuccess: () => result.next(new AuthResult(AuthResultStatus.SUCCESS)),
      onFailure: (err) => result.error(err),
    });
    return result.asObservable();
  }

  completeNewPasswordChallenge(
    cognitoUser: CognitoUser,
    password: string,
    requiredAttributeData: unknown,
  ) {
    const result = new ReplaySubject<AuthResult>(1);
    cognitoUser.completeNewPasswordChallenge(password, requiredAttributeData, {
      onSuccess: () => result.next(new AuthResult(AuthResultStatus.SUCCESS)),
      onFailure: (err) => result.error(err),
      mfaRequired: (challengeName, challengeParameters) =>
        result.next(
          new AuthResult(AuthResultStatus.MFA_REQUIRED, {
            challengeName,
            challengeParameters,
            cognitoUser,
          }),
        ),

      customChallenge: (challengeParameters) =>
        result.next(
          new AuthResult(AuthResultStatus.CUSTOM_CHALLENGE, { challengeParameters, cognitoUser }),
        ),
      mfaSetup: (challengeName, challengeParameters) =>
        result.next(
          new AuthResult(AuthResultStatus.MFA_SETUP, {
            challengeName,
            challengeParameters,
            cognitoUser,
          }),
        ),
    });

    return result.asObservable();
  }

  sendMFACode(cognitoUser: CognitoUser, confirmationCode: string) {
    const result = new ReplaySubject<AuthResult>(1);
    cognitoUser.sendMFACode(confirmationCode, {
      onSuccess: () => result.next(new AuthResult(AuthResultStatus.SUCCESS)),
      onFailure: (err) => result.error(err),
    });

    return result.asObservable();
  }

  sendTOTPCode(cognitoUser: CognitoUser, confirmationCode: string) {
    const result = new ReplaySubject<AuthResult>(1);
    cognitoUser.sendMFACode(
      confirmationCode,
      {
        onSuccess: () => result.next(new AuthResult(AuthResultStatus.SUCCESS)),
        onFailure: (err) => result.error(err),
      },
      'SOFTWARE_TOKEN_MFA',
    );

    return result.asObservable();
  }

  async signOut() {
    if (
      environment.cognito &&
      environment.cognito.hostedUI &&
      environment.cognito.hostedUI.domain
    ) {
      await this.router.navigate(['sso-logout']);
      await signOut({ global: true });
    } else {
      const currentUser = this.cognitoService.getCurrentUser();
      if (currentUser) {
        currentUser.signOut();
      }
    }
  }

  async reLogin() {
    let returnUrl: string | undefined;
    const navigation = this.router.getCurrentNavigation();
    if (navigation) {
      returnUrl = navigation.extractedUrl.toString();
    }

    if (
      environment.cognito &&
      environment.cognito.hostedUI &&
      environment.cognito.hostedUI.domain
    ) {
      if (returnUrl) {
        signInWithRedirect({ customState: encodeURIComponent(returnUrl) });
      } else {
        signInWithRedirect();
      }
    } else {
      this.signOut();

      this.router.navigate(['auth'], { queryParams: { returnUrl: returnUrl } });
    }
  }

  isAuthenticated(): Observable<boolean | undefined> {
    const result = new ReplaySubject<boolean | undefined>(1);
    const cognitoUser = this.cognitoService.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession((err: unknown, session: { isValid: () => boolean | undefined }) => {
        if (err) {
          // sign out user on error to clear invalid data storage
          cognitoUser.signOut();
          result.error(err);
        } else {
          result.next(session.isValid());
        }
      });
    } else {
      result.next(false);
    }
    return result.asObservable();
  }

  getUserParameters(): Observable<CognitoUserAttribute[] | null | undefined> {
    const result = new ReplaySubject<CognitoUserAttribute[] | null | undefined>(1);

    const cognitoUser = this.cognitoService.getCurrentUser();

    if (cognitoUser != null) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      cognitoUser.getSession((err: unknown, session: unknown) => {
        if (err) {
          result.error(err);
        } else {
          cognitoUser?.getUserAttributes((error, userAttributes) => {
            if (error) {
              result.error(error);
            } else {
              result.next(userAttributes);
            }
          });
        }
      });
    } else {
      result.next(null);
    }
    return result.asObservable();
  }

  getUserIdentity() {
    const result = new ReplaySubject<unknown>(1);
    this.cognitoService.getDecodedIdToken().subscribe(
      (decodedTokenPayload) => {
        result.next(decodedTokenPayload);
      },
      (err) => {
        result.error(err);
      },
    );
    return result.asObservable();
  }
}
