import { OccupancyType } from '../../enums';

export enum FCTOccupancyType {
  INVESTMENT = '001',
  OWNER_OCCUPIED = '002',
  RENTAL = '003',
  VACANT = '004',
}

export const fctOccupancyTypeMap: Record<OccupancyType, FCTOccupancyType | undefined> = {
  [OccupancyType.OWNER_OCCUPIED]: FCTOccupancyType.OWNER_OCCUPIED,
  [OccupancyType.OWNER_OCCUPIED_RENTAL]: FCTOccupancyType.RENTAL,
  [OccupancyType.RENTAL]: FCTOccupancyType.RENTAL,
  [OccupancyType.SECOND_HOME]: FCTOccupancyType.VACANT,
  [OccupancyType.LIVE_WITH_PARENT]: undefined,
  [OccupancyType.OTHER]: undefined,
};
