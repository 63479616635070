import {
  FCTServiceType,
  FCTSecondaryServiceType,
  FCTRequestStatus,
  FCTInsuranceStatus,
  FCTDealStatus,
  FCTNotesStatus,
} from '../../enums';
import { FCTError } from '../error';
import { FCTStatus } from '../status';

export interface FCTDeltaResponse {
  ResponseStatus: number;
  DealStatuses: FCTDelta[];
  ValidationErrors: FCTError[];
}

export interface FCTDelta {
  FCTReferenceID: string;
  ClientReferenceID: string;
  CMHCReference: string;
  ServiceType: FCTServiceType;
  SecondaryServiceType: FCTSecondaryServiceType;
  AddOnProduct: any;
  RequestStatus: FCTStatus<FCTRequestStatus>;
  InsuranceStatus: FCTStatus<FCTInsuranceStatus>;
  DealStatus: FCTStatus<FCTDealStatus>;
  NotesStatus: FCTStatus<FCTNotesStatus>;
  ValidationError: any;
  InspectionDate: any;
  InspectionTime: any;
  ETA: any;
  LenderFee: any;
  ProductDetails: any;
}
