import {
  SagenApplicationData,
  SagenFieldErrorMessage,
  SagenFieldMetadata,
} from '@fundmoreai/models';
import { SagenFieldMetadataViewModel } from './sagen/sagen-application.model';

export class SagenInsuranceService {
  static skippedFields = [
    'forceSagenUnderwriterReview',
    'genworthSpecialProgramCode',
    'advanceType',
    'insuranceIndicator',
  ];

  static parseOptions(field: SagenFieldMetadata) {
    if (!field.options) {
      return undefined;
    }

    let options = 'Sagen allowed values: ';

    for (const [, value] of Object.entries(field.options)) {
      options += `${value}, `;
    }

    return options.substring(0, options.length - 2);
  }

  static toSagenFieldMetadataViewModel(
    entity: string,
    sagenFieldMetadata: SagenFieldMetadata,
    fieldErrorMessages: SagenFieldErrorMessage[],
  ) {
    const message = fieldErrorMessages?.find((x) => x.field === sagenFieldMetadata.field)?.message;

    const vm: SagenFieldMetadataViewModel = {
      field: sagenFieldMetadata.field,
      required: sagenFieldMetadata.required,
      isMissing: sagenFieldMetadata.isMissing,
      name: sagenFieldMetadata.name,
      value: sagenFieldMetadata.value,
      options: sagenFieldMetadata.options,
      parsedOptions: this.parseOptions(sagenFieldMetadata),
      message,
      entity,
    };

    return vm;
  }

  static toMetadata(
    sagenApplicationData: SagenApplicationData | undefined,
    skipConfirmFormFields = false,
  ) {
    if (!sagenApplicationData) {
      return undefined;
    }

    const vms: SagenFieldMetadataViewModel[] = [];

    vms.push(
      ...sagenApplicationData.applicationInfo.metadata.map((m) =>
        this.toSagenFieldMetadataViewModel(
          'Application Details',
          m,
          sagenApplicationData.fieldErrorMessages,
        ),
      ),
    );

    for (const borrowerInfo of sagenApplicationData.borrowerInfo) {
      const borrowerType = borrowerInfo.metadata.find((m) => m.name === 'Borrower Type')?.value;
      vms.push(
        ...borrowerInfo.metadata.map((m) =>
          this.toSagenFieldMetadataViewModel(
            `Applicant (${borrowerType})`,
            m,
            sagenApplicationData.fieldErrorMessages,
          ),
        ),
      );
    }

    vms.push(
      ...sagenApplicationData.loanInfo.metadata.map((m) =>
        this.toSagenFieldMetadataViewModel('Mortgage', m, sagenApplicationData.fieldErrorMessages),
      ),
    );

    vms.push(
      ...sagenApplicationData.propertyInfo.metadata.map((m) =>
        this.toSagenFieldMetadataViewModel('Property', m, sagenApplicationData.fieldErrorMessages),
      ),
    );

    if (skipConfirmFormFields) {
      return vms.filter((vm) => !this.skippedFields.includes(vm.field));
    }

    return vms;
  }
}
