import {
  FCTServiceType,
  FCTSecondaryServiceType,
  FCTAddOnProduct,
  FCTRequestStatus,
  FCTInsuranceStatus,
  FCTDealStatus,
  FCTNotesStatus,
  FCTValueIndicator,
} from '../../enums';
import { FCTDealInfo } from '../dealInfo';
import { FCTError } from '../error';
import { FCTProduct } from '../product';
import { FCTPropertyInfo } from '../propertyInfo';
import { FCTStatus } from '../status';
import { FCTRequest } from './fctRequest';

export interface FCTResponse {
  ResponseStatus: number; // int
  FCTReferenceID: string;
  ValidationErrors: FCTError[];
  ServiceType: FCTServiceType;
  SecondaryServiceType: FCTSecondaryServiceType;
  AddOnProduct: FCTAddOnProduct[];
  RequestStatus: FCTStatus<FCTRequestStatus>;
  InsuranceStatus: FCTStatus<FCTInsuranceStatus>;
  DealStatus: FCTStatus<FCTDealStatus>;
  NotesStatus: FCTStatus<FCTNotesStatus>;
  EligibilityIndicator: string;
  ProductDetails: FCTProduct;
  ValueIndicator: FCTStatus<FCTValueIndicator>;
  SupportedValue: number;
  ETA: string; // datetime YYYY-MM-DDThh:mm:ss
}

export interface FCTCreateResponse {
  fctResponse: FCTResponse;
  mappedRequest: FCTRequest;
  applicationId: string;
}

export interface FCTSyncResponse {
  ResponseStatus: number; // int
  FCTReferenceID: string;
  ClientReferenceID: string;
  CMHCReferenceID: string;
  ValidationErrors: FCTError[];
  ServiceType: FCTServiceType;
  SecondaryServiceType: FCTSecondaryServiceType;
  AddOnProduct: FCTAddOnProduct[];
  RequestStatus: FCTStatus<FCTRequestStatus>;
  InsuranceStatus: FCTStatus<FCTInsuranceStatus>;
  DealStatus: FCTStatus<FCTDealStatus>;
  NotesStatus: FCTStatus<FCTNotesStatus>;
  Deal: FCTDealInfo;
  SubjectProperty: FCTPropertyInfo;
  ProductDetails: FCTProduct;
  InspectionDate: string; // date YYYY-MM-DD
  InspectionTime: string;
  ETA: string; // datetime YYYY-MM-DDThh:mm:ss
  completedAt: string; // datetime YYYY-MM-DDThh:mm:ss, added by us
}
