import { Applicant, FinancialLiability } from '@fundmoreai/models';
import {
  includeLiabilityInNetworthCalculation,
  shouldIncludeLiability,
} from './helpers/networth.helper';

export function computeDebtBalance(
  financialLiabilities: Partial<FinancialLiability>[],
  applicants: Partial<Applicant>[],
  filterByApplicantId?: string,
): number {
  const considerFuturePayoff = true;

  return _computeDebtBalance(
    financialLiabilities,
    considerFuturePayoff,
    applicants,
    filterByApplicantId,
  );
}

export function computeDebtBalanceRegardlessOfPayoff(
  financialLiabilities: Partial<FinancialLiability>[],
  applicants: Partial<Applicant>[],
  filterByApplicantId?: string,
): number {
  const considerFuturePayoff = false;

  return _computeDebtBalance(
    financialLiabilities,
    considerFuturePayoff,
    applicants,
    filterByApplicantId,
  );
}

function hasMatchingApplicantFinancialLiability(
  applicants: Partial<Applicant>[],
  liability: Partial<FinancialLiability>,
) {
  return !!liability.ApplicantFinancialLiabilities.find((applicantFinancialLiability) =>
    applicants.some((applicant) => applicant.id === applicantFinancialLiability.applicantId),
  );
}

function calculateLiabilityBalance(
  liability: Partial<FinancialLiability>,
  filterByApplicantId: string,
) {
  const balance = liability.balance ?? 0;

  return filterByApplicantId ? balance / liability.ApplicantFinancialLiabilities.length : balance;
}

function _computeDebtBalance(
  financialLiabilities: Partial<FinancialLiability>[],
  considerFuturePayoff: boolean,
  applicants: Partial<Applicant>[],
  filterByApplicantId: string,
) {
  const filteredApplicants = filterByApplicantId
    ? applicants.filter((x) => x.id === filterByApplicantId)
    : applicants;

  const debt = financialLiabilities
    .filter(
      (liability) =>
        shouldIncludeLiability(considerFuturePayoff, liability) &&
        (!filterByApplicantId ||
          hasMatchingApplicantFinancialLiability(filteredApplicants, liability)) &&
        includeLiabilityInNetworthCalculation(liability),
    )
    .reduce(
      (sum, liability) => sum + calculateLiabilityBalance(liability, filterByApplicantId),
      0.0,
    );

  return debt;
}
