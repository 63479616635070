export interface CGInsuranceResponseMessageTable {
  messageTableCode?: string;
  messageTableText?: string;
}

export interface CGInsuranceResponse {
  genworthReferenceNumber?: string;
  message?: string;
  messageTable?: Array<CGInsuranceResponseMessageTable>;
}

export interface Header {
  userID?: string;
  password?: string;
  codLang?: string;
  datTime?: string;
  callbackUrl?: string;
}

export interface CGInsuranceResponseData {
  applicationResponse: ApplicationResponse;
  header: Header;
}

export interface ApplicationResponse {
  appID?: string;
  codResponse?: string;
  lender?: Lender1;
  insuranceInfo?: InsuranceInfo;
  messages?: Message[];
}

export interface Message {
  codMessage?: string;
  messageText?: string;
}

export interface Lender1 {
  fiCode?: number;
  transit?: number;
  loanNumber?: string;
}
export interface InsuranceInfo {
  certNo?: string;
  premiumRate?: number;
  premium?: number;
  fee?: number;
  tax?: number;
  totalDue?: number;
  insuredAmount?: number;
  expiryDate?: string;
  enCertificate?: string;
  frCertificate?: string;
}
