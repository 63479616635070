import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Lawyer } from '../shared';

@Injectable({
  providedIn: 'root',
})
export class LawyersService {
  constructor(private http: HttpClient) {}

  getLawyers(): Observable<Lawyer[]> {
    return this.http.get<Lawyer[]>(`${environment.api_url}/lawyers`);
  }

  addLawyer(lawyer: Partial<Lawyer>) {
    return this.http.post<Lawyer>(`${environment.api_url}/lawyers`, lawyer);
  }

  patchLawyer(lawyerId: string, lawyer: Partial<Lawyer>): Observable<void> {
    return this.http.patch<void>(`${environment.api_url}/lawyers/${lawyerId}`, lawyer);
  }

  deleteLawyer(lawyerId: string) {
    return this.http.delete<void>(`${environment.api_url}/lawyers/${lawyerId}`);
  }
}
