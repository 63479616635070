import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BOCModel } from '../model';

@Injectable({
  providedIn: 'root',
})
export class BOCService {
  constructor(private http: HttpClient) {}

  getBOCValues() {
    return this.http.get<BOCModel>(`${environment.api_url}/external/boc`);
  }
}
