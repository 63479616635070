export interface FCTSyncMessage {
  fctReferenceId: string;
  tenantCode: string;
  tenantId: string;
  operation: FCTSyncMessageOperation;
  applicationId?: string;
}

export enum FCTSyncMessageOperation {
  COMPLETE = 'COMPLETE',
  REQUEST_ONLY = 'REQUEST_ONLY',
  NOTES_ONLY = 'NOTES_ONLY',
  STATUS_ONLY = 'STATUS_ONLY',
}
