import { StreetType } from '../../enums';

export enum FCTStreetType {
  Abbey = '1',
  Acres = '2',
  Alley = '3',
  Autoroute = '4',
  Avenue = '5',
  Bay = '6',
  Beach = '7',
  Bend = '8',
  Boulevard = '9',
  ByPass = '10',
  Byway = '11',
  Campus = '12',
  Cape = '13',
  Carrefour = '14',
  CulDeSac = '15',
  Cercle = '16',
  Chemin = '17',
  Chase = '18',
  Circuit = '19',
  Close = '20',
  Common = '21',
  Concession = '22',
  Cour = '23',
  Cours = '24',
  Cove = '25',
  Crescent = '26',
  Corners = '27',
  Croissant = '28',
  Crossing = '29',
  Court = '30',
  Centre = '31',
  Dale = '32',
  Dell = '33',
  Diversion = '34',
  Downs = '35',
  Drive = '36',
  Echangeur = '37',
  End = '38',
  Esplanade = '39',
  Estates = '40',
  Expressway = '41',
  Extension = '42',
  Farm = '43',
  Field = '44',
  Forest = '45',
  Front = '46',
  Freeway = '47',
  Gate = '48',
  Gardens = '49',
  Glade = '50',
  Glen = '51',
  Green = '52',
  Grounds = '53',
  Grove = '54',
  Harbour = '55',
  Heath = '56',
  Highlands = '57',
  Hill = '58',
  Hollow = '59',
  Heights = '60',
  Highway = '61',
  Ile = '62',
  Impasse = '63',
  Inlet = '64',
  Island = '65',
  Key = '66',
  Knoll = '67',
  Landing = '68',
  Lane = '69',
  Line = '70',
  Link = '71',
  Lookout = '72',
  Limits = '73',
  Loop = '74',
  Mall = '75',
  Manor = '76',
  Maze = '77',
  Meadow = '78',
  Mews = '79',
  Montee = '80',
  Moor = '81',
  Mount = '82',
  Mountain = '83',
  Orchard = '84',
  Parade = '85',
  Parc = '86',
  Passage = '87',
  Path = '88',
  Pines = '89',
  Park = '90',
  Parkway = '91',
  Place = '92',
  Plateau = '93',
  Plaza = '94',
  Pointe = '95',
  Port = '96',
  Promenade = '97',
  Point = '98',
  Pathway = '99',
  Private = '100',
  Quay = '101',
  Ramp = '102',
  Rang = '103',
  Road = '104',
  RondPoint = '105',
  Range = '106',
  Ridge = '107',
  Rise = '108',
  Ruelle = '109',
  Row = '110',
  Route = '111',
  Rue = '112',
  Run = '113',
  Sentier = '114',
  Square = '115',
  Street = '116',
  Subdivision = '117',
  Terrace = '118',
  Thicket = '119',
  Townline = '120',
  Towers = '121',
  Trail = '122',
  Turnabout = '123',
  Terrasse = '124',
  Vale = '125',
  Via = '126',
  View = '127',
  Villas = '128',
  Village = '129',
  Vista = '130',
  Voie = '131',
  Walk = '132',
  Way = '133',
  Wharf = '134',
  Wood = '135',
  Wynd = '136',
  Circle = '137',
  Cote = '138',
  Carré = '139',
  Creek = '140',
}

export const fctStreetTypeMap: Record<StreetType, FCTStreetType | undefined> = {
  [StreetType.ABBEY]: FCTStreetType.Abbey,
  [StreetType.ACRES]: FCTStreetType.Acres,
  [StreetType.ALLEE]: undefined,
  [StreetType.ALLEY]: FCTStreetType.Alley,
  [StreetType.ANNEX]: undefined,
  [StreetType.ARCADE]: undefined,
  [StreetType.AUTOROUTE]: FCTStreetType.Autoroute,
  [StreetType.AVENUE]: FCTStreetType.Avenue,
  [StreetType.BAY]: FCTStreetType.Bay,
  [StreetType.BAYOU]: undefined,
  [StreetType.BEACH]: FCTStreetType.Beach,
  [StreetType.BEND]: FCTStreetType.Bend,
  [StreetType.BLUFF]: undefined,
  [StreetType.BLUFFS]: undefined,
  [StreetType.BOTTOM]: undefined,
  [StreetType.BOULEVARD]: FCTStreetType.Boulevard,
  [StreetType.BRANCH]: undefined,
  [StreetType.BRIDGE]: undefined,
  [StreetType.BROOK]: undefined,
  [StreetType.BROOKS]: undefined,
  [StreetType.BURG]: undefined,
  [StreetType.BURGS]: undefined,
  [StreetType.BY_PASS]: undefined,
  [StreetType.BYPASS]: undefined,
  [StreetType.BYWAY]: FCTStreetType.Byway,
  [StreetType.CAMP]: undefined,
  [StreetType.CAMPUS]: FCTStreetType.Campus,
  [StreetType.CANYON]: undefined,
  [StreetType.CAPE]: FCTStreetType.Cape,
  [StreetType.CARRE]: FCTStreetType.Carré,
  [StreetType.CARREFOUR]: FCTStreetType.Carrefour,
  [StreetType.CAUSEWAY]: undefined,
  [StreetType.CENTER]: undefined,
  [StreetType.CENTERS]: undefined,
  [StreetType.CENTRE]: FCTStreetType.Centre,
  [StreetType.CERCLE]: FCTStreetType.Cercle,
  [StreetType.CHASE]: FCTStreetType.Chase,
  [StreetType.CHEMIN]: FCTStreetType.Chemin,
  [StreetType.CIRCLE]: FCTStreetType.Circle,
  [StreetType.CIRCLES]: undefined,
  [StreetType.CIRCUIT]: FCTStreetType.Circuit,
  [StreetType.CLIFF]: undefined,
  [StreetType.CLIFFS]: undefined,
  [StreetType.CLOSE]: FCTStreetType.Close,
  [StreetType.CLUB]: undefined,
  [StreetType.COMMON]: FCTStreetType.Common,
  [StreetType.COMMONS]: undefined,
  [StreetType.CONCESSION]: FCTStreetType.Concession,
  [StreetType.CORNER]: undefined,
  [StreetType.CORNERS]: FCTStreetType.Corners,
  [StreetType.COTE]: FCTStreetType.Cote,
  [StreetType.COUR]: FCTStreetType.Cour,
  [StreetType.COURS]: FCTStreetType.Cours,
  [StreetType.COURSE]: undefined,
  [StreetType.COURT]: FCTStreetType.Court,
  [StreetType.COURTS]: undefined,
  [StreetType.COVE]: FCTStreetType.Cove,
  [StreetType.COVES]: undefined,
  [StreetType.CREEK]: FCTStreetType.Creek,
  [StreetType.CRESCENT]: FCTStreetType.Crescent,
  [StreetType.CREST]: undefined,
  [StreetType.CROISSANT]: FCTStreetType.Croissant,
  [StreetType.CROSSING]: FCTStreetType.Crossing,
  [StreetType.CROSSROAD]: undefined,
  [StreetType.CROSSROADS]: undefined,
  [StreetType.CUL_DE_SAC]: undefined,
  [StreetType.CURVE]: undefined,
  [StreetType.DALE]: FCTStreetType.Dale,
  [StreetType.DAM]: undefined,
  [StreetType.DELL]: FCTStreetType.Dell,
  [StreetType.DIVERSION]: FCTStreetType.Diversion,
  [StreetType.DIVIDE]: undefined,
  [StreetType.DOWNS]: FCTStreetType.Downs,
  [StreetType.DRIVE]: FCTStreetType.Drive,
  [StreetType.DRIVES]: undefined,
  [StreetType.ECHANGEUR]: undefined,
  [StreetType.END]: FCTStreetType.End,
  [StreetType.ESPLANADE]: FCTStreetType.Esplanade,
  [StreetType.ESTATE]: undefined,
  [StreetType.ESTATES]: FCTStreetType.Estates,
  [StreetType.EXPRESSWAY]: FCTStreetType.Expressway,
  [StreetType.EXTENSION]: FCTStreetType.Extension,
  [StreetType.EXTENSIONS]: undefined,
  [StreetType.FALL]: undefined,
  [StreetType.FALLS]: undefined,
  [StreetType.FARM]: FCTStreetType.Farm,
  [StreetType.FERRY]: undefined,
  [StreetType.FIELD]: FCTStreetType.Field,
  [StreetType.FIELDS]: undefined,
  [StreetType.FLAT]: undefined,
  [StreetType.FLATS]: undefined,
  [StreetType.FORD]: undefined,
  [StreetType.FORDS]: undefined,
  [StreetType.FOREST]: FCTStreetType.Forest,
  [StreetType.FORGE]: undefined,
  [StreetType.FORGES]: undefined,
  [StreetType.FORK]: undefined,
  [StreetType.FORKS]: undefined,
  [StreetType.FORT]: undefined,
  [StreetType.FREEWAY]: FCTStreetType.Freeway,
  [StreetType.FRONT]: FCTStreetType.Front,
  [StreetType.GARDEN]: undefined,
  [StreetType.GARDENS]: FCTStreetType.Gardens,
  [StreetType.GATE]: FCTStreetType.Gate,
  [StreetType.GATEWAY]: undefined,
  [StreetType.GLADE]: FCTStreetType.Glade,
  [StreetType.GLEN]: FCTStreetType.Glen,
  [StreetType.GLENS]: undefined,
  [StreetType.GREEN]: FCTStreetType.Green,
  [StreetType.GREENS]: undefined,
  [StreetType.GROUNDS]: FCTStreetType.Grounds,
  [StreetType.GROVE]: FCTStreetType.Grove,
  [StreetType.GROVES]: undefined,
  [StreetType.HARBOR]: undefined,
  [StreetType.HARBORS]: undefined,
  [StreetType.HARBOUR]: undefined,
  [StreetType.HAVEN]: undefined,
  [StreetType.HEATH]: FCTStreetType.Heath,
  [StreetType.HEIGHTS]: FCTStreetType.Heights,
  [StreetType.HIGHLANDS]: FCTStreetType.Highlands,
  [StreetType.HIGHWAY]: FCTStreetType.Highway,
  [StreetType.HILL]: FCTStreetType.Hill,
  [StreetType.HILLS]: undefined,
  [StreetType.HOLLOW]: FCTStreetType.Hollow,
  [StreetType.ILE]: FCTStreetType.Ile,
  [StreetType.IMPASSE]: FCTStreetType.Impasse,
  [StreetType.INLET]: FCTStreetType.Inlet,
  [StreetType.ISLAND]: FCTStreetType.Island,
  [StreetType.ISLANDS]: undefined,
  [StreetType.ISLE]: undefined,
  [StreetType.JUNCTION]: undefined,
  [StreetType.JUNCTIONS]: undefined,
  [StreetType.KEY]: FCTStreetType.Key,
  [StreetType.KEYS]: undefined,
  [StreetType.KNOLL]: FCTStreetType.Knoll,
  [StreetType.KNOLLS]: undefined,
  [StreetType.LAKE]: undefined,
  [StreetType.LAKES]: undefined,
  [StreetType.LAND]: undefined,
  [StreetType.LANDING]: FCTStreetType.Landing,
  [StreetType.LANE]: FCTStreetType.Lane,
  [StreetType.LIGHT]: undefined,
  [StreetType.LIGHTS]: undefined,
  [StreetType.LIMITS]: FCTStreetType.Limits,
  [StreetType.LINE]: FCTStreetType.Line,
  [StreetType.LINK]: FCTStreetType.Link,
  [StreetType.LOAF]: undefined,
  [StreetType.LOCK]: undefined,
  [StreetType.LOCKS]: undefined,
  [StreetType.LODGE]: undefined,
  [StreetType.LOOKOUT]: FCTStreetType.Lookout,
  [StreetType.LOOP]: FCTStreetType.Loop,
  [StreetType.MALL]: FCTStreetType.Mall,
  [StreetType.MANOR]: FCTStreetType.Manor,
  [StreetType.MANORS]: undefined,
  [StreetType.MAZE]: FCTStreetType.Maze,
  [StreetType.MEADOW]: FCTStreetType.Meadow,
  [StreetType.MEADOWS]: undefined,
  [StreetType.MEWS]: FCTStreetType.Mews,
  [StreetType.MILL]: undefined,
  [StreetType.MILLS]: undefined,
  [StreetType.MISSION]: undefined,
  [StreetType.MONTEE]: FCTStreetType.Montee,
  [StreetType.MOOR]: FCTStreetType.Moor,
  [StreetType.MOTORWAY]: undefined,
  [StreetType.MOUNT]: FCTStreetType.Mount,
  [StreetType.MOUNTAIN]: FCTStreetType.Mountain,
  [StreetType.MOUNTAINS]: undefined,
  [StreetType.NECK]: undefined,
  [StreetType.ORCHARD]: FCTStreetType.Orchard,
  [StreetType.PARADE]: FCTStreetType.Parade,
  [StreetType.PARC]: FCTStreetType.Parc,
  [StreetType.PARK]: FCTStreetType.Park,
  [StreetType.PARKS]: undefined,
  [StreetType.PARKWAY]: FCTStreetType.Parkway,
  [StreetType.PARKWAYS]: undefined,
  [StreetType.PASS]: undefined,
  [StreetType.PASSAGE]: FCTStreetType.Passage,
  [StreetType.PATH]: FCTStreetType.Path,
  [StreetType.PATHWAY]: FCTStreetType.Pathway,
  [StreetType.PIKE]: undefined,
  [StreetType.PINE]: undefined,
  [StreetType.PINES]: FCTStreetType.Pines,
  [StreetType.PLACE]: FCTStreetType.Place,
  [StreetType.PLAIN]: undefined,
  [StreetType.PLAINS]: undefined,
  [StreetType.PLATEAU]: FCTStreetType.Plateau,
  [StreetType.PLAZA]: FCTStreetType.Plaza,
  [StreetType.POINT]: FCTStreetType.Point,
  [StreetType.POINTE]: FCTStreetType.Pointe,
  [StreetType.POINTS]: undefined,
  [StreetType.PORT]: FCTStreetType.Port,
  [StreetType.PORTS]: undefined,
  [StreetType.PRAIRIE]: undefined,
  [StreetType.PRIVATE]: FCTStreetType.Private,
  [StreetType.PROMENADE]: FCTStreetType.Promenade,
  [StreetType.QUAI]: FCTStreetType.Quay,
  [StreetType.QUAY]: FCTStreetType.Quay,
  [StreetType.RADIAL]: undefined,
  [StreetType.RAMP]: FCTStreetType.Ramp,
  [StreetType.RANCH]: undefined,
  [StreetType.RANG]: FCTStreetType.Rang,
  [StreetType.RANGE]: FCTStreetType.Range,
  [StreetType.RAPID]: undefined,
  [StreetType.RAPIDS]: undefined,
  [StreetType.REST]: undefined,
  [StreetType.RIDGE]: FCTStreetType.Ridge,
  [StreetType.RIDGES]: undefined,
  [StreetType.RISE]: FCTStreetType.Rise,
  [StreetType.RIVER]: undefined,
  [StreetType.ROAD]: FCTStreetType.Road,
  [StreetType.ROADS]: undefined,
  [StreetType.ROND_POINT]: undefined,
  [StreetType.ROUTE]: FCTStreetType.Route,
  [StreetType.ROW]: FCTStreetType.Row,
  [StreetType.RUE]: FCTStreetType.Rue,
  [StreetType.RUELLE]: FCTStreetType.Ruelle,
  [StreetType.RUN]: FCTStreetType.Run,
  [StreetType.SENTIER]: FCTStreetType.Sentier,
  [StreetType.SHOAL]: undefined,
  [StreetType.SHOALS]: undefined,
  [StreetType.SHORE]: undefined,
  [StreetType.SHORES]: undefined,
  [StreetType.SKYWAY]: undefined,
  [StreetType.SPRING]: undefined,
  [StreetType.SPRINGS]: undefined,
  [StreetType.SPUR]: undefined,
  [StreetType.SPURS]: undefined,
  [StreetType.SQUARE]: FCTStreetType.Square,
  [StreetType.SQUARES]: undefined,
  [StreetType.STATION]: undefined,
  [StreetType.STRAVENUE]: undefined,
  [StreetType.STREAM]: undefined,
  [StreetType.STREET]: FCTStreetType.Street,
  [StreetType.STREETS]: undefined,
  [StreetType.SUBDIVISION]: FCTStreetType.Subdivision,
  [StreetType.SUMMIT]: undefined,
  [StreetType.TERRACE]: FCTStreetType.Terrace,
  [StreetType.TERRASSE]: FCTStreetType.Terrasse,
  [StreetType.THICKET]: FCTStreetType.Thicket,
  [StreetType.THROUGHWAY]: undefined,
  [StreetType.TOWERS]: FCTStreetType.Towers,
  [StreetType.TOWNLINE]: FCTStreetType.Townline,
  [StreetType.TRACE]: undefined,
  [StreetType.TRACK]: undefined,
  [StreetType.TRAFFICWAY]: undefined,
  [StreetType.TRAIL]: FCTStreetType.Trail,
  [StreetType.TRAILER]: undefined,
  [StreetType.TUNNEL]: undefined,
  [StreetType.TURNABOUT]: FCTStreetType.Turnabout,
  [StreetType.TURNPIKE]: undefined,
  [StreetType.UNDERPASS]: undefined,
  [StreetType.UNION]: undefined,
  [StreetType.UNIONS]: undefined,
  [StreetType.VALE]: FCTStreetType.Vale,
  [StreetType.VALLEY]: undefined,
  [StreetType.VALLEYS]: undefined,
  [StreetType.VIA]: FCTStreetType.Via,
  [StreetType.VIADUCT]: undefined,
  [StreetType.VIEW]: FCTStreetType.View,
  [StreetType.VIEWS]: undefined,
  [StreetType.VILLAGE]: FCTStreetType.Village,
  [StreetType.VILLAGES]: undefined,
  [StreetType.VILLAS]: FCTStreetType.Villas,
  [StreetType.VILLE]: undefined,
  [StreetType.VISTA]: FCTStreetType.Vista,
  [StreetType.VOIE]: FCTStreetType.Voie,
  [StreetType.WALK]: FCTStreetType.Walk,
  [StreetType.WALKS]: undefined,
  [StreetType.WALL]: undefined,
  [StreetType.WAY]: FCTStreetType.Way,
  [StreetType.WAYS]: undefined,
  [StreetType.WELL]: undefined,
  [StreetType.WELLS]: undefined,
  [StreetType.WHARF]: FCTStreetType.Wharf,
  [StreetType.WOOD]: FCTStreetType.Wood,
  [StreetType.WYND]: FCTStreetType.Wynd,
};
