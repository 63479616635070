<fm-notes-list
  [canEdit]="canEdit"
  [currentUserId]="currentUserId"
  [notes]="notes$ | async"
  (editDraftNote)="openEditDraftNote($event)"></fm-notes-list>

<button
  *ngIf="!isEditNoteVisible"
  mat-mini-fab
  color="accent"
  class="floating-add-button"
  (click)="isEditNoteVisible = true"
  [disabled]="!canEdit">
  <mat-icon>add</mat-icon>
</button>

<fm-edit-note-form
  *ngIf="isEditNoteVisible"
  [applicationId]="applicationId"
  [note]="noteToEdit$ | async"
  (closeEdit)="closeEditForm()"></fm-edit-note-form>
