import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MortgageApplicationService } from '../../portal/mortgage-application.service';
import { GeneralAbstractPermissions } from 'src/app/auth/model';
import { ApplicationStage, PipelineApplication } from '@fundmoreai/models';

@Injectable({
  providedIn: 'root',
})
export class PipelineService {
  constructor(
    private http: HttpClient,
    private mortgageApplicationService: MortgageApplicationService,
  ) {}

  getApplications(
    approvalRequested: boolean,
    archived: boolean,
    permissions: GeneralAbstractPermissions,
  ): Observable<PipelineApplication[]> {
    let headers = new HttpHeaders();
    headers = headers.set('x-compression', 'true');

    const endpoint = approvalRequested
      ? `${environment.fundmore_api_url}/applications/approvalRequested?archived=${archived}`
      : `${environment.api_url}/applications?archived=${archived}`;
    return this.http.get<PipelineApplication[]>(endpoint, { headers }).pipe(
      map((applications) => {
        return this.mapApplication(applications, permissions, approvalRequested);
      }),
    );
  }

  getUnassignedDealsCount() {
    return this.http.get<number>(
      `${environment.fundmore_v2_api_url}/application/unassigned-deals-count`,
    );
  }

  getUnassignedDeals(permissions: GeneralAbstractPermissions) {
    let headers = new HttpHeaders();
    headers = headers.set('x-compression', 'true');

    return this.http
      .get<PipelineApplication[]>(
        `${environment.fundmore_v2_api_url}/application/unassigned-deals`,
        { headers },
      )
      .pipe(
        map((applications) => {
          return this.mapApplication(applications, permissions);
        }),
      );
  }

  getTeamApplications(team: string[], permissions: GeneralAbstractPermissions) {
    let headers = new HttpHeaders();
    headers = headers.set('x-compression', 'true');

    return this.http
      .get<PipelineApplication[]>(
        `${environment.fundmore_api_url}/applications/team?team=${JSON.stringify(team)}`,
        { headers },
      )
      .pipe(
        map((applications) => {
          return this.mapApplication(applications, permissions);
        }),
      );
  }

  private mapApplication(
    applicationInput: PipelineApplication[],
    permissions: GeneralAbstractPermissions,
    approvalRequested = false,
  ) {
    // TODO: add logic for assigned on
    const applications = applicationInput;

    const userHierarchyIds = permissions.applicationList.hierarchyUserIds;
    applications.forEach((application) => {
      const canEdit =
        permissions.applicationList.canEdit ||
        (permissions.applicationList.canEditOwn &&
          userHierarchyIds?.includes(application.createdBy?.id ?? '')) ||
        (permissions.applicationList.canEditAssigned &&
          (application.applicationAssignedUser ?? []).some((assignedUser) =>
            userHierarchyIds?.includes(assignedUser.userId),
          )) ||
        (permissions.applicationList.canEditInNewStage &&
          application.currentStage === ApplicationStage.NEW_APPLICATION) ||
        (permissions.applicationList.canEditInClosedStage &&
          (application.currentStage === ApplicationStage.FUNDED ||
            application.currentStage === ApplicationStage.DECLINED));

      application.canEdit = canEdit;
      application.primaryApplicantName =
        this.mortgageApplicationService.getPrimaryApplicantName(application);
      application.primaryApplicantCustomerType =
        this.mortgageApplicationService.getPrimaryApplicantCustomerType(application);
      application.otherApplicants = this.mortgageApplicationService.getOtherApplicants(application);
      application.approval = approvalRequested;
    });

    return applications;
  }
}
