import { ApplicationDecisionEngineFlags } from '@fundmoreai/models';

export class SetDecisionEngineFlags {
  static readonly type = '@decisionEngine.SetDecisionEngineFlags';

  constructor(public applicationDecisionEngineFlags: ApplicationDecisionEngineFlags) {}
}

export class SubmitToDecisionEngine {
  static readonly type = '@decisionEngine.SubmitToDecisionEngine';

  constructor(public applicationId: string) {}
}

export class SubmitToDecisionEngineOnStageTransition {
  static readonly type = '@decisionEngine.SubmitToDecisionEngineOnStageTransition';

  constructor(public applicationId: string) {}
}
