import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Fee, FeeType } from '../shared/model';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, tap } from 'rxjs/operators';
import { FeesService } from './fees.services';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import { CreateFee, RemoveFee, SetFees, UpdateFee } from './fees.actions';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import { ApplicationResetState } from '../shared/state.model';

export interface FeesStateModel {
  fees?: {
    [key: string]: Fee;
  };
}

export interface IndexedFees {
  [key: string]: Fee;
}

@State<FeesStateModel>({
  name: 'fees',
  defaults: { fees: undefined },
})
@Injectable()
export class FeesState {
  constructor(private feesService: FeesService) {}

  @Selector()
  private static fees(state: FeesStateModel): Fee[] | undefined {
    return state.fees ? Object.values(state.fees) : undefined;
  }

  @Selector([FeesState.fees])
  static feesList(fees: Fee[] | undefined) {
    return fees
      ? fees.sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime())
      : undefined;
  }

  @Selector([FeesState.feesList])
  static feesToSubtractTotal(fees: Fee[] | undefined) {
    // Deducted Fees
    return FundmoreCalculator.computeFeesToSubtract(fees || []);
  }

  @Selector([FeesState.feesList])
  static indexedFees(fees: Fee[] | undefined) {
    return fees
      ? fees.reduce((indexedFees, fee) => {
          indexedFees[fee.type] = fee;
          return indexedFees;
        }, {} as IndexedFees)
      : {};
  }

  @Selector([FeesState.feesList])
  static feesSubtractedFromLoanAmount(fees: Fee[] | undefined) {
    return fees?.filter((f) => f.subtractFromPrincipal);
  }

  @Selector([FeesState.feesList])
  static totalFeeAmount(fees: Fee[] | undefined) {
    return fees?.reduce((sum, fee) => sum + (fee && fee.amount ? fee.amount : 0.0), 0.0);
  }

  @Selector([FeesState.feesList])
  static brokerFee(fees: Fee[] | undefined) {
    return fees?.find((fee) => fee.type === FeeType.BROKER) || ({} as Fee);
  }

  @Selector([FeesState.feesList])
  static lenderFee(fees: Fee[] | undefined) {
    return fees?.find((fee) => fee.type === FeeType.LENDER) || ({} as Fee);
  }

  @Selector([FeesState.feesList])
  static administratorFee(fees: Fee[] | undefined) {
    return fees?.find((fee) => fee.type === FeeType.ADMINISTRATOR) || ({} as Fee);
  }

  @Selector([FeesState.feesList])
  static feesValuesForProductReapplyTriggers(fees: Fee[] | undefined) {
    return fees?.map((fee) => ({
      amount: fee.amount,
      capFeesPercentage: fee.capFeesPercentage,
      paidBy: fee.paidBy,
      percent: fee.percent,
      subtractFromPrincipal: fee.subtractFromPrincipal,
      type: fee.type,
    }));
  }

  @Action(UpdateFee)
  updateFee(ctx: StateContext<FeesStateModel>, { applicationId, fee, manuallyUpdated }: UpdateFee) {
    const state = ctx.getState();
    const prevFee = state.fees?.[fee.id];

    if (!prevFee) {
      return;
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));

    const hasIncludeInAprChanged = prevFee?.includeInApr !== fee.includeInApr;
    const observable = hasIncludeInAprChanged
      ? this.feesService.patchIncludeInAPR(applicationId, { ...fee, manuallyUpdated })
      : this.feesService.patchFee(applicationId, { ...fee, manuallyUpdated });

    return observable.pipe(
      tap(() => {
        const state = ctx.getState();

        ctx.patchState({
          fees: {
            ...state.fees,
            [fee.id]: fee,
          },
        });
      }),
      catchError((error) => {
        const state = ctx.getState();

        ctx.patchState({
          fees: {
            ...state.fees,
            [prevFee.id]: prevFee,
          },
        });

        return throwError(() => error);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreateFee)
  postFee(
    ctx: StateContext<FeesStateModel>,
    { applicationId, fee, manuallyUpdated }: CreateFee,
  ): Observable<Fee> {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.feesService.postFee(applicationId, { ...fee, manuallyUpdated }).pipe(
      tap((insertedFee) => {
        const state = ctx.getState();

        ctx.patchState({
          fees: {
            ...state.fees,
            [insertedFee.id]: insertedFee,
          },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(RemoveFee)
  removeFee(
    ctx: StateContext<FeesStateModel>,
    { applicationId, fee }: RemoveFee,
  ): Observable<void> {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.feesService.deleteFee(applicationId, fee.id).pipe(
      tap(() => {
        const state = ctx.getState();
        const fees = { ...state.fees };

        delete fees[fee.id];

        ctx.patchState({
          fees,
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(SetFees)
  setFees(ctx: StateContext<FeesStateModel>, { fees }: SetFees) {
    const feeEntities = fees.reduce((entities: { [key: string]: Fee }, fee) => {
      entities[fee.id] = fee;

      return entities;
    }, {});

    ctx.patchState({ fees: feeEntities });
  }

  @Action(ApplicationResetState) applicationReset(ctx: StateContext<FeesStateModel>) {
    ctx.patchState({ fees: undefined });
  }
}
