import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs';
import { DMDocumentsStatusStateModel, DMDocumentWithStatus } from './dm-documents-status.model';
import { DMDocumentsStatusService } from './dm-documents-status.service';
import { LoadingStart, LoadingEnd } from '../../../../../core/loading.state';

export class FetchStatus {
  static readonly type = '@dmDocumentsStatus.FetchStatus';
  constructor(public applicationId: string) {}
}

@State<DMDocumentsStatusStateModel>({
  name: 'dmDocumentsStatus',
  defaults: { linkedDocuments: [], unlinkedDocuments: [] },
})
@Injectable()
export class DMDocumentsStatusState {
  constructor(private service: DMDocumentsStatusService) {}

  @Selector()
  static linkedDMDocumentsWithStatus(state: DMDocumentsStatusStateModel) {
    return state.linkedDocuments;
  }

  @Selector()
  static unlinkedDMDocumentsWithStatus(state: DMDocumentsStatusStateModel) {
    return state.unlinkedDocuments;
  }

  @Selector()
  static allDMDocumentsWithStatus(state: DMDocumentsStatusStateModel) {
    const allDocumentStatusesByStakeholderObject = [
      ...state.linkedDocuments,
      ...state.unlinkedDocuments,
    ].reduce((pv, cv) => {
      return {
        ...pv,
        [cv.stakeholderId]: [...(pv[cv.stakeholderId] ?? []), ...cv.documentStatuses],
      };
    }, {} as { [key: string]: DMDocumentWithStatus[] });

    const allDocumentStatusesByStakeholderArray = [];
    for (const stakeholderId of Object.keys(allDocumentStatusesByStakeholderObject)) {
      const returnArray = [];
      const dmTemplateIdSet = new Set<string>();
      for (const documentStatus of allDocumentStatusesByStakeholderObject[stakeholderId]) {
        if (!dmTemplateIdSet.has(documentStatus.dmTemplateId)) {
          dmTemplateIdSet.add(documentStatus.dmTemplateId);
          returnArray.push(documentStatus);
        }
      }

      allDocumentStatusesByStakeholderArray.push({
        stakeholderId,
        documentStatuses: returnArray,
      });
    }

    return allDocumentStatusesByStakeholderArray;
  }

  @Action(FetchStatus)
  getDMDocumentsStatuses(ctx: StateContext<DMDocumentsStatusStateModel>, action: FetchStatus) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.service.getDMDocumentsStatus(action.applicationId).pipe(
      tap((statuses) => {
        ctx.setState(statuses);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }
}
