export interface ApplicantCreditCardOffer {
  id: string;
  tenantId: string;
  applicationId: string;
  applicantId: string;
  presentOffer?: boolean;
  offerAccepted?: boolean;
  ApplicantCreditCardOfferDetails?: ApplicantCreditCardOfferDetails[];
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export interface CardDetails {
  status: string;
  approvedLoanAmount: number;
  amountToRequest?: number;
  currency: string;
  subType?: string;
  cardName: string;
}

export interface ApplicantCreditCardOfferDetails extends CardDetails {
  id: string;
  tenantId: string;
  applicationId: string;
  applicantId: string;
  applicantCreditCardOfferId: string;
  selected?: boolean;
  createdAt: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}

export enum ApplicantCreditCardsKey {
  OFFER_ACCEPTED = 'offerAccepted',
  PRESENT_OFFER = 'presentOffer',
}

export enum ApplicantCreditCardDetailsKey {
  AMOUNT_TO_REQUEST = 'amountToRequest',
  SELECTED = 'selected',
}
