import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { ApplicationOtherIncome } from '../shared';

@Injectable({
  providedIn: 'root',
})
export class OtherIncomesService {
  constructor(private http: HttpClient) {}

  postOtherIncomes(
    applicationId: string,
    otherIncomes: Partial<ApplicationOtherIncome>[],
  ): Observable<ApplicationOtherIncome[]> {
    return this.http
      .post<ApplicationOtherIncome[]>(
        `${environment.api_url}/applications/${applicationId}/otherIncomes`,
        otherIncomes,
      )
      .pipe(catchError(this.formatErrors));
  }

  patchOtherIncome(
    applicationId: string,
    otherIncomeId: string,
    otherIncome: Partial<ApplicationOtherIncome>,
  ): Observable<void> {
    return this.http
      .patch<void>(
        `${environment.api_url}/applications/${applicationId}/otherIncomes/${otherIncomeId}`,
        otherIncome,
      )
      .pipe(catchError(this.formatErrors));
  }

  deleteOtherIncome(applicationId: string, otherIncomeId: string): Observable<void> {
    return this.http
      .delete<void>(
        `${environment.api_url}/applications/${applicationId}/otherIncomes/${otherIncomeId}`,
      )
      .pipe(catchError(this.formatErrors));
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }
}
