import { Injectable, NgZone } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { EMPTY, combineLatest, finalize, map, of, switchMap, tap } from 'rxjs';
import { ApplicationResetState } from 'src/app/shared/state.model';
import { ResetState } from '../../../../shared';
import {
  ApplicationCondition,
  Condition,
  ConditionsOfApprovalService,
} from '../../../shared/documents';
import {
  ConditionOfApprovalTableKeyRecord,
  ConditionTableKey,
  JoinedApplicationCondition,
} from '../../../shared/documents/model';
import { FetchStatus } from './dm-document-status/dm-documents-status.state';
import {
  AddApplicationConditions,
  AddCustomApplicationCondition,
  ApplicationConditionStateModel,
  DeleteApplicationCondition,
  GetAllApplicationConditions,
  GetAllConditions,
  SaveApplicationCondition,
  UpdateApplicationConditionStatus,
} from './application-conditions.state.actions';
import { AppFeaturesState } from 'src/app/shared/app-features.state';
import {
  ApplicationConditionStatus,
  DocumentManagementType,
  assertUnreachable,
} from '@fundmoreai/models';
import { LoadingStart, LoadingEnd } from '../../../../core/loading.state';
import { MatDialog } from '@angular/material/dialog';
// eslint-disable-next-line max-len
import { ConditionStatusChangeDialogComponent } from './condition-status-change-dialog/condition-status-change-dialog.component';
import { ApplicationConditionStatusRecord } from './enum.record';
import { AuthState } from 'src/app/auth/auth.state';

export const APPLICATION_CONDITIONS_DEFAULT_DISPLAY_COLUMNS = Object.values(ConditionTableKey).map(
  (value: ConditionTableKey, index) => {
    return {
      field: value,
      name: ConditionOfApprovalTableKeyRecord[value],
      isSelected: index < 5,
      isFrozen: value === ConditionTableKey.NAME,
    };
  },
);

@State<ApplicationConditionStateModel>({
  name: 'applicationConditions',
  defaults: {
    applicationConditions: undefined,
    conditions: [],
  },
})
@Injectable()
export class ApplicationConditionsState {
  static NAME = 'applicationConditions';

  @Selector() static joinedApplicationConditions(
    state: ApplicationConditionStateModel,
  ): JoinedApplicationCondition[] {
    return ApplicationConditionsState.makeApplicationConditions(
      state.applicationConditions ?? {},
      state.conditions,
    );
  }

  @Selector()
  static conditionTypes(state: ApplicationConditionStateModel): string[] {
    const types = state.conditions
      .map((condition: Condition) => condition?.conditionType)
      .filter((type): type is string => !!type);
    return [...new Set(types)].sort();
  }

  static joinedApplicationCondition(applicationConditionId: string) {
    return createSelector(
      [ApplicationConditionsState],
      (state: ApplicationConditionStateModel): JoinedApplicationCondition | undefined => {
        const applicationCondition = state.applicationConditions?.[applicationConditionId];
        const condition = state.conditions.find((c) => c.id === applicationCondition?.conditionId);

        if (!applicationCondition || !condition) {
          return;
        }

        return { ...applicationCondition, condition };
      },
    );
  }

  @Selector([ApplicationConditionsState])
  static applicationConditions(
    state: ApplicationConditionStateModel,
  ): ApplicationCondition[] | undefined {
    if (!state.applicationConditions) {
      return;
    }

    const applicationConditions = Object.values(state.applicationConditions);

    return applicationConditions;
  }

  @Selector() static conditions(state: ApplicationConditionStateModel) {
    return state.conditions;
  }

  constructor(
    private applicationConditionService: ConditionsOfApprovalService,
    private store: Store,
    private zone: NgZone,
    private dialog: MatDialog,
  ) {}

  @Action(GetAllConditions) getAllConditions(
    ctx: StateContext<ApplicationConditionStateModel>,
    { applicationId }: GetAllConditions,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationConditionService.getConditions(applicationId).pipe(
      tap((conditions) => ctx.patchState({ conditions })),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(GetAllApplicationConditions) getAllApplicationConditions(
    ctx: StateContext<ApplicationConditionStateModel>,
    { applicationId }: GetAllApplicationConditions,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return ctx.dispatch(new GetAllConditions(applicationId)).pipe(
      switchMap(() => this.applicationConditionService.getApplicationConditions(applicationId)),
      tap((applicationConditions) => {
        const applicationConditionsEntities = applicationConditions?.reduce(
          (entities: { [key: string]: ApplicationCondition }, applicationCondition) => {
            entities[applicationCondition.id] = applicationCondition;

            return entities;
          },
          {},
        );

        ctx.patchState({ applicationConditions: applicationConditionsEntities });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(DeleteApplicationCondition) deleteApplicationCondition(
    ctx: StateContext<ApplicationConditionStateModel>,
    { applicationConditionId }: DeleteApplicationCondition,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationConditionService.deleteApplicationCondition(applicationConditionId).pipe(
      tap(() => {
        const state = ctx.getState();
        const applicationConditions = { ...(state.applicationConditions ?? {}) };

        delete applicationConditions[applicationConditionId];

        ctx.patchState({
          applicationConditions,
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateApplicationConditionStatus) updateApplicationConditionStatus(
    ctx: StateContext<ApplicationConditionStateModel>,
    { applicationConditionId, status }: UpdateApplicationConditionStatus,
  ) {
    let title: string, message: string, confirmText: string;
    switch (status) {
      case ApplicationConditionStatus.FULFILLED: {
        title = $localize`Mark Condition as Fulfilled`;
        message = $localize`Are you sure you want to fulfill this condition?`;
        confirmText = $localize`Mark as fulfilled`;
        break;
      }
      case ApplicationConditionStatus.NOT_ACCEPTABLE: {
        title = $localize`Mark Condition as Not Acceptable`;
        message = $localize`Are you sure you want to mark this condition as not acceptable?`;
        confirmText = $localize`Mark as not acceptable`;
        break;
      }
      case ApplicationConditionStatus.NOT_RELEVANT: {
        title = $localize`Mark Condition as Not Relevant`;
        message = $localize`Are you sure you want to mark this condition as not relevant?`;
        confirmText = $localize`Mark as not relevant`;
        break;
      }
      default: {
        assertUnreachable(status, 'Unhandled condition status!');

        break;
      }
    }

    return this.zone.run(() => {
      const df = this.dialog.open(ConditionStatusChangeDialogComponent, {
        data: {
          title,
          message,
          confirmText,
          status,
        },
      });

      return df.afterClosed().pipe(
        switchMap((result) => {
          if (!result?.confirm) {
            ctx.dispatch(new LoadingEnd(this.constructor.name));

            return EMPTY;
          }
          ctx.dispatch(new LoadingStart(this.constructor.name));

          const applicationNoteContent = this.formatReviewForNote(
            applicationConditionId,
            status,
            result.comments,
          );

          return this.applicationConditionService
            .updateApplicationConditionStatus(
              applicationConditionId,
              status,
              applicationNoteContent,
              result.comments,
            )
            .pipe(
              tap((updatedApplicationCondition) => {
                const state = ctx.getState();

                ctx.patchState({
                  applicationConditions: {
                    ...(state.applicationConditions ?? {}),
                    [applicationConditionId]: updatedApplicationCondition,
                  },
                });
              }),
              finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
            );
        }),
      );
    });
  }

  @Action(AddApplicationConditions) addApplicationConditions(
    ctx: StateContext<ApplicationConditionStateModel>,
    { applicationId, stakeholderId, conditionIds }: AddApplicationConditions,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationConditionService
      .addApplicationConditions(applicationId, stakeholderId, conditionIds)
      .pipe(
        switchMap((createdApplicationConditions) =>
          this.store.selectSnapshot(AppFeaturesState.documentManagementType) ===
          DocumentManagementType.EZIDOX
            ? ctx
                .dispatch(new FetchStatus(applicationId))
                .pipe(map(() => createdApplicationConditions))
            : of(createdApplicationConditions),
        ),
        tap((createdApplicationConditions) => {
          const state = ctx.getState();
          const applicationConditions = { ...(state.applicationConditions ?? {}) };

          createdApplicationConditions.forEach((createdApplicationCondition) => {
            applicationConditions[createdApplicationCondition.id] = createdApplicationCondition;
          });

          ctx.patchState({
            applicationConditions,
          });
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(AddCustomApplicationCondition) addCustomApplicationCondition(
    ctx: StateContext<ApplicationConditionStateModel>,
    { condition, applicationId, stakeholderId }: AddCustomApplicationCondition,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationConditionService.createCustomCondition(condition).pipe(
      switchMap((createdCondition) => {
        ctx.patchState({ conditions: [...ctx.getState().conditions, createdCondition] });
        return ctx.dispatch(
          new AddApplicationConditions(applicationId, stakeholderId, [createdCondition.id]),
        );
      }),
    );
  }

  @Action(SaveApplicationCondition) saveApplicationCondition(
    ctx: StateContext<ApplicationConditionStateModel>,
    {
      condition,
      status,
      applicationConditionId,
      applicationId,
      stakeholderId,
    }: SaveApplicationCondition,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationConditionService.createCustomCondition(condition).pipe(
      switchMap((createdCondition) => {
        ctx.patchState({ conditions: [...ctx.getState().conditions, createdCondition] });

        return this.applicationConditionService.addApplicationConditions(
          applicationId,
          stakeholderId,
          [createdCondition.id],
        );
      }),
      switchMap(([createdApplicationCondition]) => {
        if (!status) {
          return of(createdApplicationCondition);
        }

        return this.applicationConditionService.updateApplicationConditionStatus(
          createdApplicationCondition.id,
          status,
        );
      }),
      switchMap((createdApplicationCondition) => {
        return combineLatest([
          of(createdApplicationCondition),
          ctx.dispatch(new DeleteApplicationCondition(applicationConditionId)),
        ]);
      }),
      switchMap(([createdApplicationCondition]) => {
        const state = ctx.getState();
        const applicationConditions = {
          ...(state.applicationConditions ?? {}),
          [createdApplicationCondition.id]: createdApplicationCondition,
        };

        ctx.patchState({
          applicationConditions,
        });

        return of(createdApplicationCondition);
      }),
      switchMap((createdApplicationCondition) => {
        const documentManagementType = this.store.selectSnapshot(
          AppFeaturesState.documentManagementType,
        );

        if (documentManagementType === DocumentManagementType.EZIDOX) {
          return this.store
            .dispatch(new FetchStatus(applicationId))
            .pipe(map(() => createdApplicationCondition));
        }
        return of(createdApplicationCondition);
      }),
    );
  }

  @Action(ResetState)
  resetState(ctx: StateContext<ApplicationConditionStateModel>) {
    ctx.setState({
      conditions: [],
      applicationConditions: undefined,
    });
  }

  @Action(ApplicationResetState)
  resetApplicationState(ctx: StateContext<ApplicationConditionStateModel>) {
    ctx.setState({
      conditions: [],
      applicationConditions: undefined,
    });
  }

  private static makeApplicationConditions(
    applicationConditions: { [key: string]: ApplicationCondition },
    conditions: Condition[],
  ) {
    return Object.values(applicationConditions).map((applicationCondition) => {
      const condition = conditions.find((c) => c.id === applicationCondition.conditionId);

      return {
        ...applicationCondition,
        condition,
      };
    });
  }

  private formatReviewForNote(
    selectedConditionId: string,
    status: ApplicationConditionStatus,
    comments?: string,
  ): string {
    const condition = this.store.selectSnapshot(
      ApplicationConditionsState.joinedApplicationCondition(selectedConditionId),
    );

    const currentUser = this.store.selectSnapshot(AuthState.currentUser);

    const conditionName = condition?.condition?.name ?? $localize`Unknown Name`;
    const conditionStatus = ApplicationConditionStatusRecord[status];
    const reviewComments = comments ?? '&mdash;';
    const userName = currentUser?.user?.displayName ?? $localize`Unknown User`;

    const textLines: string[] = [];
    textLines.push(`${userName} ` + $localize`reviewed a condition` + `: ${conditionName}`);
    textLines.push('');
    textLines.push($localize`Status` + `: ${conditionStatus}`);
    if (reviewComments) {
      textLines.push($localize`Comments` + `: ${comments}`);
    }

    return `<div>${textLines.map((text) => (text ? text : '&nbsp;')).join('</div><div>')}</div>`;
  }
}
