import { CompoundPeriod, Fee, Mortgage } from '@fundmoreai/models';
// Total Cost of Credit Calculation
export function computeTCCCalvert(
  requestedMortgage: Mortgage,
  fees: Fee[],
  monthlyPayment: number | undefined,
) {
  const simpleInterest = requestedMortgage.compounding === CompoundPeriod.SIMPLE_INTEREST;
  const rate = requestedMortgage.netRate / 100;
  const effectiveRate = Math.pow(1 + rate / 2, 2) - 1;
  const totalFees = fees
    .filter((fee) => fee.includeInApr)
    .reduce((sum, fee) => sum + (fee.amount ? fee.amount : 0.0), 0.0);
  let monthlyPeriodicRate = 0;
  const principalMortgageAmount = requestedMortgage.loanAmount + totalFees;
  const mortgageTerm = requestedMortgage.termMonths;

  if (simpleInterest) {
    monthlyPeriodicRate = (rate / 365) * 31;

    const returnTcc =
      principalMortgageAmount *
        requestedMortgage.termMonths *
        (Math.pow(Math.pow(1 + effectiveRate / 2, 2), 1 / 12) - 1) +
      totalFees;

    return returnTcc;
  } else {
    monthlyPeriodicRate = Math.pow(Math.pow(1 + rate / 2, 2), 1 / 12) - 1;

    const returnTcc =
      monthlyPayment * mortgageTerm -
      (principalMortgageAmount -
        (principalMortgageAmount * Math.pow(1 + monthlyPeriodicRate, mortgageTerm) -
          monthlyPayment *
            ((Math.pow(1 + monthlyPeriodicRate, mortgageTerm) - 1) / monthlyPeriodicRate))) +
      totalFees;

    return returnTcc;
  }
}
