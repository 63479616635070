export class FetchRequestedDocumentNotes {
  static readonly type = '@requestedDocumentNote.FetchRequestedDocumentNotes';

  constructor(public applicationId: string) {}
}

export class SaveRequestedDocumentNote {
  static readonly type = '@requestedDocumentNote.SaveRequestedDocumentNote';

  constructor(public applicationId: string, public content: string) {}
}
