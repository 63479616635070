import { FinancialLiability, LiabilityActiveStatus } from '@fundmoreai/models';
import { GroupedLiabilities } from '../../application/widgets/credit/financial-liability.state';

const isLiabilityActive = (liability: FinancialLiability): boolean => {
  return (
    !liability.crStatus?.includes(LiabilityActiveStatus.CLOSED) &&
    !liability.crStatus?.includes(LiabilityActiveStatus.INACTIVE)
  );
};

const groupLiabilities = (financialLiabilities: FinancialLiability[]): GroupedLiabilities => {
  const groupedLiabilities: GroupedLiabilities = {
    active: [],
    writtenOff: [],
    inactive: [],
  };
  for (const liability of financialLiabilities) {
    if (liability.crHasWrittenOff === true) {
      groupedLiabilities.writtenOff.push(liability);
    } else if (isLiabilityActive(liability)) {
      groupedLiabilities.active.push(liability);
    }
  }
  return groupedLiabilities;
};

export const groupedFinancialLiabilities = (
  financialLiabilities: FinancialLiability[],
  applicantId: string | undefined,
): GroupedLiabilities => {
  if (!applicantId) {
    return groupLiabilities(financialLiabilities);
  }

  const applicantLiabilities = financialLiabilities.filter((liability) => {
    const applicantLiability = liability.ApplicantFinancialLiabilities.find(
      (applicantLiability) => applicantLiability.applicantId === applicantId,
    );
    if (!applicantLiability) {
      return false;
    }
    return true;
  });

  return groupLiabilities(applicantLiabilities);
};
