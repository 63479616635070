export enum CmhcViewModelField {
  LANGUAGE = 'language',
  PREVIOUS_CMHC_ACCOUNT_NUMBER = 'previousCMHCAccountNumber',
  PRODUCT_TRACKER_ID = 'productTrackerId',
  ON_RESERVE_TRUST_AGREEMENT_NUMBER = 'onReserveTrustAgreementNumber',
  COMMENT = 'comment',
  UNIT_NUMBER = 'UnitNumber',
  STREET_NUMBER = 'StreetNumber',
  STREET_NAME = 'StreetName',
  STREET_TYPE = 'StreetType',
  STREET_DIRECTION = 'StreetDirection',
  MUNICIPALITY = 'Municipality',
  POSTAL_CODE = 'PostalCode',
  PROVINCE = 'Province',
  BIRTH_DATE = 'BirthDate',
  NAME = 'Name',
  CREDIT_CARD = 'CreditCard',
  CREDIT_CARD_NUMBER = 'CreditCardNumber',
  BANK_ACCOUNT = 'BankAccount',
  BANK_CARD = 'BankCard',
  SAVINGS_INVESTMENT_ACCOUNT = 'SavingsInvestmentAccount',
  YEARS_WITH_BANK = 'YearsWithBank',
  OCCUPATION = 'Occupation',
  YEARS_SERVICE = 'YearsService',
  YEARS_WITH_CURRENT_EMPLOYER = 'YearsWithCurrentEmployer',
  INDUSTRY_SECTOR = 'IndustrySector',
  BORROWER_TYPE = 'BorrowerType',
  RESIDENTIAL_STATUS = 'ResidentialStatus',
  MARITAL_STATUS = 'MaritalStatus',
  DEPENDENTS = 'Dependents',
  PHONE_NUMBER = 'PhoneNumber',
  DURATION_AT_ADDRESS = 'DurationAtAddress',
  NON_PERMANENT_RESIDENT = 'NonPermanentResident',
  SOCIAL_INSURANCE_NUMBER = 'SocialInsuranceNumber',
  SELLING_PRICE = 'SellingPrice',
  CLOSING_DATE = 'ClosingDate',
  LOAN_PURPOSE = 'LoanPurpose',
  LOAN_AMOUNT = 'LoanAmount',
  SOURCE_OF_BUSINESS = 'SourceOfBusiness',
  REPAYMENT_TYPE = 'RepaymentType',
  INTEREST_RATE = 'InterestRate',
  INTEREST_TYPE = 'InterestType',
  TERM = 'Term',
  AMORTIZATION_PERIOD = 'AmortizationPeriod',
  SECURITY_TYPE = 'SecurityType',
  PROCESSING_INDICATOR = 'ProcessingIndicator',
  OTHER_FINANCING = 'OtherFinancing',
  IMPROVEMENTS = 'Improvements',
  PREMIUM_TO_BE_ADDED = 'PremiumToBeAdded',
  BLENDED_AMORTIZATION = 'BlendedAmortization',
  BALANCE_EXISTING_MORTGAGE = 'BalanceExistingMortgage',
  PREVIOUS_SALE_CLOSING_DATE = 'PreviousSaleClosingDate',
  PREVIOUS_SALE_PRICE = 'PreviousSalePrice',
  AS_IMPROVED_VALUE = 'AsImprovedValue',
  INSTALLMENT_COMPLETION_CODE = 'InstallmentCompletionCode',
  LOAN_TO_VALUE_RATIO = 'LoanToValueRatio',
  ENERGY_EFFICIENCY = 'EnergyEfficiency',
  HOME_BUILDER_PROCESS = 'HomeBuilderProcess',
  BUILDER_NAME = 'BuilderName',
  CONSTRUCTION_IMPROVEMENT_COSTS = 'ConstructionImprovementCosts',
  CMHC_SELF_EMPLOYED_SIMPLIFIED = 'CmhCSelfEmployedSimplified',
  LOAN_ADVANCE_AMOUNT = 'LoanAdvanceAmount',
  LOAN_ADVANCE_NUMBER = 'LoanAdvanceNumber',
  MARKET_VALUE = 'MarketValue',
  REFERRAL_REQUESTED = 'ReferralRequested',
  TOP_UP_AMOUNT = 'TopUpAmount',
  AGE = 'Age',
  NEW_EXISTING_INDICATOR = 'NewExistingIndicator',
  LEGAL_DESCRIPTION = 'LegalDescription',
  NUMBER_OF_UNITS = 'NumberOfUnits',
  TENURE = 'Tenure',
  DWELLING_TYPE = 'DwellingType',
  HOUSE_TYPE = 'HouseType',
  FLOOR_AREA = 'FloorArea',
  ENVIRONMENTAL_HAZARD = 'EnvironmentalHazard',
  LOT_SIZE = 'LotSize',
  GARAGE_SIZE = 'GarageSize',
  GARAGE_TYPE = 'GarageType',
  HEATING_TYPE = 'HeatingType',
  TOTAL_INCOME = 'TotalIncome',
  GROSS_DEBT_SERVICE = 'GrossDebtService',
  TOTAL_DEBT_SERVICE = 'TotalDebtService',
  ANNUAL_SHELTER_PAYMENT = 'AnnualShelterPayment',
  PROPERTY_TAXES = 'PropertyTaxes',
  APPLICATION_TYPE = 'ApplicationType',
  EQUITY = 'Equity',
  OCCUPANCY_TYPE = 'OccupancyType',
  FLEXIBLE_DOWNPAYMENT = 'FlexibleDownpayment',
  CASH_FROM_OWN_RESOURCE = 'CashFromOwnResource',
  SALE_OF_OTHER_PROPERTY = 'SaleOfOtherProperty',
  RRSP = 'RRSP',
  SWEAT_EQUITY = 'SweatEquity',
  BORROWED_AGAINST_ASSETS = 'BorrowedAgainstAssets',
  GIFT = 'Gift',
  OTHER = 'Other',
  SELF_DIRECTED_RRSP = 'SelfDirectedRRSP',
  LOC_APP = 'LOCApp',
  FIN_INST = 'FinInst',
  TRANSIT = 'Transit',
  LENDER_REFERENCE = 'LenderReference',
}
