export enum FCTServiceType {
  // INSURED_AVM = '001',
  // DESKTOP = '002',
  // DRIVE_BY = '003',
  // FULL_APPRAISAL = '004',
  // PROGRESS_INSPECTION = '005',
  // MARKET_RENT = '006',
  // APPRAISAL_UPDATE = '007',
  // AVM = '008',
  // APPRAISAL_ASSISTED_INSURED_AVM = '009',
  // RETRO_DRIVE_BY = '010',
  // RETRO_DESKTOP = '011',
  NVIP = '012',
  // UNINSURED_FLEX = '013',
}
