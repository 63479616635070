/* eslint-disable max-len */

import { Injectable } from '@angular/core';
import {
  CGApplicationAdd,
  CGApplicationUpdate,
  InsuranceQuote,
  InsuranceStatus,
  InsurerType,
} from '@fundmoreai/models';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { EMPTY, finalize, Observable, tap } from 'rxjs';
import { MortgagesV2State } from 'src/app/portal/mortgages-v2/mortgages-v2.state';
import { SummaryState } from '../../../../portal/summary.state';
import { ApplicationResetState } from '../../../../shared/state.model';
import { CmhcRequestSetInsuranceQuoteRes } from './cmhc/cmhc-insurance-request/cmhc-insurance-request.state';
import {
  BorrowerBankingInfo,
  CmhcApplicationOptions,
  CmhcSubmitApplicationPayload,
} from './cmhc/cmhc.model';
import { MortgageInsuranceService } from './mortgage-insurance.service';
import { SagenApplicationAdd, SagenApplicationUpdate } from './sagen/sagen-application.model';
import { MortgageInsuranceSagenApplicationSetQuote } from './sagen/sagen-insurance-request/sagen-insurance-request.state';
import { MortgageInsuranceCGApplicationSetQuote } from './cg/cg-insurance-request/cg-insurance-request.state';
import { LoadingStart, LoadingEnd } from '../../../../core/loading.state';
export class MortgageInsuranceApplicationCancel {
  static readonly type = '@mortgageInsurance.applicationCancel';
  constructor(public insuranceQuote: InsuranceQuote) {}
}

export class MortgageInsuranceInquireStatus {
  static readonly type = '@mortgageInsurance.inquireStatus';
  constructor(public insuranceQuote: InsuranceQuote) {}
}

export class MortgageInsuranceInquireTestApproveStatus {
  static readonly type = '@mortgageInsurance.inquireTestApproveStatus';
  constructor(public insuranceQuote: InsuranceQuote) {}
}

export class MortgageInsuranceDownloadCertifax {
  static readonly type = '@mortgageInsurance.downloadCertifax';
  constructor(public insuranceQuote: InsuranceQuote) {}
}

export class MortgageInsuranceSagenApplicationAdd {
  static readonly type = '@mortgageInsurance.SagenApplicationAdd';
  constructor(public applicationId: string, public sagenApplicationAdd: SagenApplicationAdd) {}
}

export class MortgageInsuranceSagenApplicationUpdate {
  static readonly type = '@mortgageInsurance.SagenApplicationUpdate';
  constructor(public quoteId: string, public sagenApplicationUpdate: SagenApplicationUpdate) {}
}

class MortgageInsuranceSagenApplicationCancel {
  static readonly type = '@mortgageInsurance.SagenApplicationCancel';
  constructor(public quoteId: string) {}
}

class MortgageInsuranceSagenApplicationInquireStatus {
  static readonly type = '@mortgageInsurance.SagenApplicationInquireStatus';
  constructor(public quoteId: string) {}
}

class MortgageInsuranceSagenApplicationInquireTestApproveStatus {
  static readonly type = '@mortgageInsurance.SagenApplicationInquireTestApproveStatus';
  constructor(public quoteId: string) {}
}

export class MortgageInsuranceCmhcSubmitApplication {
  static readonly type = '@mortgageInsurance.CmhcSubmitApplication';
  constructor(
    public applicationId: string,
    public applicationOptions: CmhcApplicationOptions,
    public borrowersBankingInfos: BorrowerBankingInfo[],
  ) {}
}

class MortgageInsuranceCmhcGetApplication {
  static readonly type = '@mortgageInsurance.CmhcGetApplication';
  constructor(public insuranceQuoteId: string) {}
}

class MortgageInsuranceCmhcGetTestApplication {
  static readonly type = '@mortgageInsurance.CmhcGetTestApplication';
  constructor(public insuranceQuoteId: string) {}
}

class MortgageInsuranceCmhcCancelApplication {
  static readonly type = '@mortgageInsurance.CmhcCancelApplication';
  constructor(public insuranceQuoteId: string) {}
}

export class MortgageInsuranceCmhcUpdateApplication {
  static readonly type = '@mortgageInsurance.CmhcUpdateApplication';
  constructor(
    public insuranceQuoteId: string,
    public applicationOptions: CmhcApplicationOptions,
    public borrowersBankingInfos: BorrowerBankingInfo[],
  ) {}
}

export class MortgageInsuranceApplicationQuotesGet {
  static readonly type = '@mortgageInsurance.ApplicationQuotesGet';
  constructor(public applicationId: string) {}
}

export class MortgageInsuranceCGApplicationAdd {
  static readonly type = '@mortgageInsurance.CGApplicationAdd';
  constructor(public applicationId: string, public cgApplicationAdd: CGApplicationAdd) {}
}

export class MortgageInsuranceCGApplicationUpdate {
  static readonly type = '@mortgageInsurance.CGApplicationUpdate';
  constructor(public quoteId: string, public cgApplicationUpdate: CGApplicationUpdate) {}
}

export class MortgageInsuranceCGApplicationInquire {
  static readonly type = '@mortgageInsurance.CGApplicationInquire';
  constructor(public quoteId: string) {}
}

export class MortgageInsuranceCGApplicationInquireTestApprove {
  static readonly type = '@mortgageInsurance.CGApplicationInquireTestApprove';
  constructor(public quoteId: string) {}
}
export class MortgageInsuranceCGApplicationCancel {
  static readonly type = '@mortgageInsurance.CGApplicationCancel';
  constructor(public quoteId: string) {}
}

interface InsuranceStateModel {
  insuranceQuotes: InsuranceQuote[];
}

@State<InsuranceStateModel>({
  name: 'MortgageInsuranceState',
  defaults: {
    insuranceQuotes: [],
  },
})
@Injectable()
export class MortgageInsuranceState {
  constructor(private store: Store, private mortgageInsuranceService: MortgageInsuranceService) {}

  @Selector()
  static insuranceQuotes(state: InsuranceStateModel): InsuranceQuote[] {
    return state.insuranceQuotes;
  }

  @Selector()
  static latestInsuranceQuote(state: InsuranceStateModel): InsuranceQuote | undefined {
    const copyOfQuotes = [...state.insuranceQuotes];
    const sortedQuotes = copyOfQuotes
      .filter((q) => q.status !== InsuranceStatus.CANCEL_CONFIRMATION)
      .sort((a, b) => new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime());
    return sortedQuotes.pop();
  }

  static insuranceQuote(quoteId: string) {
    const selectorFn = (insuranceQuotes: InsuranceQuote[]) => {
      const quote = insuranceQuotes.find((q) => q.id === quoteId);
      return quote;
    };
    const selector = createSelector([MortgageInsuranceState.insuranceQuotes], selectorFn);
    return selector;
  }

  @Action(MortgageInsuranceSagenApplicationAdd)
  addSagenApplication(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceSagenApplicationAdd,
  ) {
    const requestedMortgage = this.store.selectSnapshot(
      MortgagesV2State.firstFoundRequestedMortgage,
    );

    if (!requestedMortgage) {
      return EMPTY;
    }

    const payload = {
      ...action.sagenApplicationAdd,
      mortgage: { monthlyPayment: requestedMortgage.monthlyPayment },
    };
    this.store.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageInsuranceService.addSagenApplication(action.applicationId, payload).pipe(
      tap((insuranceQuote: InsuranceQuote) =>
        ctx.patchState({
          insuranceQuotes: [...ctx.getState().insuranceQuotes, insuranceQuote],
        }),
      ),
      tap((insuranceQuote: InsuranceQuote) =>
        this.store.dispatch(new MortgageInsuranceSagenApplicationSetQuote(insuranceQuote)),
      ),
      finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  public static applyUpdates(updates: InsuranceQuote, quotes: InsuranceQuote[]) {
    const existing = quotes.find((q) => q.externalSystemId === updates.externalSystemId);
    const updated = Object.assign({}, existing, updates);

    return [...quotes.filter((q) => q.externalSystemId !== updates.externalSystemId), updated];
  }

  @Action(MortgageInsuranceSagenApplicationUpdate)
  updateSagenApplication(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceSagenApplicationUpdate,
  ) {
    const requestedMortgage = this.store.selectSnapshot(
      MortgagesV2State.firstFoundRequestedMortgage,
    );

    if (!requestedMortgage) {
      return EMPTY;
    }

    const payload = {
      ...action.sagenApplicationUpdate,
      mortgage: { monthlyPayment: requestedMortgage.monthlyPayment },
    };

    this.store.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageInsuranceService.updateSagenApplication(action.quoteId, payload).pipe(
      tap((insuranceQuote: InsuranceQuote) =>
        ctx.patchState({
          insuranceQuotes: MortgageInsuranceState.applyUpdates(
            insuranceQuote,
            ctx.getState().insuranceQuotes,
          ),
        }),
      ),
      tap((insuranceQuote: InsuranceQuote) =>
        this.store.dispatch(new MortgageInsuranceSagenApplicationSetQuote(insuranceQuote)),
      ),
      finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(MortgageInsuranceSagenApplicationCancel)
  cancelSagenApplication(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceSagenApplicationCancel,
  ) {
    this.store.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageInsuranceService
      .cancelSagenApplication(action.quoteId)
      .pipe(finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))));
  }

  @Action(MortgageInsuranceSagenApplicationInquireStatus)
  inquireSagenApplicationStatus(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceSagenApplicationInquireStatus,
  ) {
    this.store.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageInsuranceService
      .inquireSagenApplicationStatus(action.quoteId)
      .pipe(finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))));
  }

  @Action(MortgageInsuranceSagenApplicationInquireTestApproveStatus)
  inquireSagenApplicationTestApproveStatus(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceSagenApplicationInquireTestApproveStatus,
  ) {
    this.store.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageInsuranceService
      .inquireSagenApplicationTestApproveStatus(action.quoteId)
      .pipe(finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))));
  }

  @Action(MortgageInsuranceApplicationQuotesGet)
  getApplicationQuotes(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceApplicationQuotesGet,
  ) {
    this.store.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageInsuranceService.getApplicationQuotes(action.applicationId).pipe(
      tap((insuranceQuotes: InsuranceQuote[]) =>
        ctx.patchState({
          insuranceQuotes,
        }),
      ),
      finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(MortgageInsuranceCmhcSubmitApplication)
  private submitCmhcApplication(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceCmhcSubmitApplication,
  ) {
    const requestedMortgage = this.store.selectSnapshot(
      MortgagesV2State.firstFoundRequestedMortgage,
    );

    if (!requestedMortgage) {
      return EMPTY;
    }

    const payload: CmhcSubmitApplicationPayload = {
      applicationOptions: action.applicationOptions,
      bankingInfos: action.borrowersBankingInfos,
    };
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageInsuranceService.submitCmhcApplication(action.applicationId, payload).pipe(
      tap((quote: InsuranceQuote) => {
        const quotes = ctx.getState().insuranceQuotes;
        ctx.patchState({
          insuranceQuotes: [...quotes, quote],
        });
        ctx.dispatch(new CmhcRequestSetInsuranceQuoteRes(quote));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(MortgageInsuranceCmhcCancelApplication)
  private cancelCmhcApplication(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceCmhcCancelApplication,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageInsuranceService.cancelCmhcApplication(action.insuranceQuoteId).pipe(
      tap((quote: InsuranceQuote) => {
        ctx.setState(
          patch({
            insuranceQuotes: updateItem<InsuranceQuote>(
              (insuranceQuote) => insuranceQuote?.id === quote.id,
              patch(quote),
            ),
          }),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(MortgageInsuranceCmhcUpdateApplication)
  private updateCmhcApplication(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceCmhcUpdateApplication,
  ) {
    const requestedMortgage = this.store.selectSnapshot(
      MortgagesV2State.firstFoundRequestedMortgage,
    );

    if (!requestedMortgage) {
      return EMPTY;
    }

    const payload: CmhcSubmitApplicationPayload = {
      applicationOptions: action.applicationOptions,
      bankingInfos: action.borrowersBankingInfos,
    };
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageInsuranceService
      .resubmitCmhcApplication(action.insuranceQuoteId, payload)
      .pipe(
        tap((quote: InsuranceQuote) => {
          ctx.setState(
            patch({
              insuranceQuotes: updateItem<InsuranceQuote>(
                (insuranceQuote) => insuranceQuote?.id === quote.id,
                patch(quote),
              ),
            }),
          );
          ctx.dispatch(new CmhcRequestSetInsuranceQuoteRes(quote));
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(MortgageInsuranceCmhcGetTestApplication)
  private getCmhcTestApplication(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceCmhcGetTestApplication,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageInsuranceService.getCmhcTestApplication(action.insuranceQuoteId).pipe(
      tap((quote: InsuranceQuote) => {
        ctx.setState(
          patch({
            insuranceQuotes: updateItem<InsuranceQuote>(
              (insuranceQuote) => insuranceQuote?.id === quote.id,
              patch(quote),
            ),
          }),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(MortgageInsuranceCmhcGetApplication)
  private getCmhcApplication(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceCmhcGetApplication,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageInsuranceService.getCmhcApplication(action.insuranceQuoteId).pipe(
      tap((quote: InsuranceQuote) => {
        ctx.setState(
          patch({
            insuranceQuotes: updateItem<InsuranceQuote>(
              (insuranceQuote) => insuranceQuote?.id === quote.id,
              patch(quote),
            ),
          }),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(MortgageInsuranceInquireStatus)
  private mortgageInsuranceInquireStatus(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceInquireStatus,
  ) {
    const { insuranceQuote } = action;

    let action$: Observable<void>;
    switch (insuranceQuote.insurerType) {
      case InsurerType.SAGEN:
        action$ = ctx.dispatch(
          new MortgageInsuranceSagenApplicationInquireStatus(insuranceQuote.id),
        );
        break;
      case InsurerType.CMHC:
        action$ = ctx.dispatch(new MortgageInsuranceCmhcGetApplication(insuranceQuote.id));
        break;
      case InsurerType.CANADA_GUARANTY:
        action$ = ctx.dispatch(new MortgageInsuranceCGApplicationInquire(insuranceQuote.id));
        break;
      default:
        action$ = EMPTY;
        break;
    }

    return action$;
  }

  @Action(MortgageInsuranceInquireTestApproveStatus)
  private mortgageInsuranceInquireTestApproveStatus(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceInquireTestApproveStatus,
  ) {
    const { insuranceQuote } = action;

    switch (insuranceQuote.insurerType) {
      case InsurerType.SAGEN:
        return ctx.dispatch(
          new MortgageInsuranceSagenApplicationInquireTestApproveStatus(insuranceQuote.id),
        );
      case InsurerType.CANADA_GUARANTY:
        return ctx.dispatch(
          new MortgageInsuranceCGApplicationInquireTestApprove(insuranceQuote.id),
        );
      case InsurerType.CMHC:
        return ctx.dispatch(new MortgageInsuranceCmhcGetTestApplication(insuranceQuote.id));
      default:
        return EMPTY;
    }
  }

  @Action(MortgageInsuranceDownloadCertifax)
  private mortgageInsuranceDownloadCertifax(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceDownloadCertifax,
  ) {
    const { insuranceQuote } = action;

    this.store.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageInsuranceService
      .downloadCertifax(insuranceQuote.id, insuranceQuote.insurerType)
      .pipe(finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))));
  }

  @Action(MortgageInsuranceApplicationCancel)
  private mortgageInsuranceCancelApplication(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceApplicationCancel,
  ) {
    const { insuranceQuote } = action;
    let action$: Observable<void>;
    switch (insuranceQuote.insurerType) {
      case InsurerType.SAGEN:
        action$ = ctx.dispatch(new MortgageInsuranceSagenApplicationCancel(insuranceQuote.id));
        break;
      case InsurerType.CMHC:
        action$ = ctx.dispatch(new MortgageInsuranceCmhcCancelApplication(insuranceQuote.id));
        break;
      case InsurerType.CANADA_GUARANTY:
        action$ = ctx.dispatch(new MortgageInsuranceCGApplicationCancel(insuranceQuote.id));
        break;

      default:
        action$ = EMPTY;
        break;
    }

    return action$;
  }

  @Action(ApplicationResetState)
  resetState(ctx: StateContext<InsuranceStateModel>) {
    ctx.setState({
      insuranceQuotes: [],
    });
  }

  @Action(MortgageInsuranceCGApplicationAdd)
  addCGApplication(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceCGApplicationAdd,
  ) {
    this.store.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageInsuranceService
      .addCGApplication(action.applicationId, action.cgApplicationAdd)
      .pipe(
        tap((insuranceQuote: InsuranceQuote) =>
          ctx.patchState({
            insuranceQuotes: [...ctx.getState().insuranceQuotes, insuranceQuote],
          }),
        ),
        tap((insuranceQuote: InsuranceQuote) =>
          this.store.dispatch(new MortgageInsuranceCGApplicationSetQuote(insuranceQuote)),
        ),
        finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(MortgageInsuranceCGApplicationUpdate)
  updateCGApplication(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceCGApplicationUpdate,
  ) {
    this.store.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageInsuranceService
      .updateCGApplication(action.quoteId, action.cgApplicationUpdate)
      .pipe(
        tap((insuranceQuote: InsuranceQuote) =>
          ctx.patchState({
            insuranceQuotes: MortgageInsuranceState.applyUpdates(
              insuranceQuote,
              ctx.getState().insuranceQuotes,
            ),
          }),
        ),
        tap((insuranceQuote: InsuranceQuote) =>
          this.store.dispatch(new MortgageInsuranceCGApplicationSetQuote(insuranceQuote)),
        ),
        finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(MortgageInsuranceCGApplicationInquire)
  inquireCGApplication(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceCGApplicationInquire,
  ) {
    this.store.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageInsuranceService.inquireCGApplication(action.quoteId).pipe(
      tap((insuranceQuote: InsuranceQuote) =>
        ctx.patchState({
          insuranceQuotes: MortgageInsuranceState.applyUpdates(
            insuranceQuote,
            ctx.getState().insuranceQuotes,
          ),
        }),
      ),
      tap((insuranceQuote: InsuranceQuote) =>
        this.store.dispatch(new MortgageInsuranceCGApplicationSetQuote(insuranceQuote)),
      ),
      finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(MortgageInsuranceCGApplicationInquireTestApprove)
  inquireCGApplicationTestApprove(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceCGApplicationInquireTestApprove,
  ) {
    this.store.dispatch(new LoadingStart(this.constructor.name));
    return this.mortgageInsuranceService.inquireCGApplicationTestApproveStatus(action.quoteId).pipe(
      tap((insuranceQuote: InsuranceQuote) =>
        ctx.patchState({
          insuranceQuotes: MortgageInsuranceState.applyUpdates(
            insuranceQuote,
            ctx.getState().insuranceQuotes,
          ),
        }),
      ),
      tap((insuranceQuote: InsuranceQuote) =>
        this.store.dispatch(new MortgageInsuranceCGApplicationSetQuote(insuranceQuote)),
      ),
      finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(MortgageInsuranceCGApplicationCancel)
  cancelCGApplication(
    ctx: StateContext<InsuranceStateModel>,
    action: MortgageInsuranceCGApplicationCancel,
  ) {
    this.store.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageInsuranceService.cancelCGApplication(action.quoteId).pipe(
      tap((insuranceQuote: InsuranceQuote) =>
        ctx.patchState({
          insuranceQuotes: MortgageInsuranceState.applyUpdates(
            insuranceQuote,
            ctx.getState().insuranceQuotes,
          ),
        }),
      ),
      tap((insuranceQuote: InsuranceQuote) =>
        this.store.dispatch(new MortgageInsuranceCGApplicationSetQuote(insuranceQuote)),
      ),
      finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }
}
