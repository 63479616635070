import { ApprovalType } from './approval-type.model';

export class AddApprovalType {
  static readonly type = '@approvalType.AddApprovalType';

  constructor(public approvalType: Partial<ApprovalType>) {}
}

export class DeleteApprovalType {
  static readonly type = '@approvalType.DeleteApprovalType';

  constructor(public approvalTypeId: string) {}
}

export class FetchApprovalTypes {
  static readonly type = '@approvalType.FetchApprovalTypes';
}

export class UpdateApprovalType {
  static readonly type = '@approvalType.UpdateApprovalType';

  constructor(public approvalType: Partial<ApprovalType>) {}
}
