export enum OptaCommercialHeatingTypeType {
  ACORN_STOVE_BOX_FRANKLIN_POT_BELLY_STOVE = 'ACORN Stove / Box / Franklin / Pot Belly Stove',
  BASEBOARD_HEATER = 'Baseboard Heater',
  BI_ENERGY_COMBINATION = 'Bi-Energy Combination',
  COMBINED = 'Combined',
  ELECTRIC_FURNACE = 'Electric Furnace',
  FIREPLACE = 'Fireplace',
  FLOOR_FURNACE = 'Floor Furnace',
  FURNACE_CENTRAL = 'Furnace (Central)',
  FURNACE_CENTRAL_WITH_ADD_ON_WOODBURNING_UNIT = 'Furnace (Central) with Add-On Woodburning Unit',
  GEOTHERMAL = 'Geothermal',
  HOT_WATER_STEAM_BOILER_UNIT = 'Hot Water / Steam / Boiler Unit',
  PELLET_STOVE = 'Pellet Stove',
  RADIANT_CEILING = 'Radiant Ceiling',
  RADIANT_FLOOR = 'Radiant Floor',
  SLOW_WOOD_BURNING_AIR_TIGHT_STOVE = 'Slow Wood Burning / Air Tight Stove',
  SOLAR_PANELS = 'Solar Panels',
  SOLID_FUEL_HEATING_UNIT = 'Solid Fuel Heating Unit',
  SPACE_HEATER = 'Space Heater',
  WALL_FURNACE = 'Wall Furnace',
  OTHER = 'Other',
  PARTIALLY_UNHEATED = 'Partially Unheated',
  NONE = 'None',
  UNKNOWN = 'Unknown',
}
