export enum RMCIntegrationFields {
  COMMITMENT_DATES = 'commitmentDates',
  QUICK_CLOSE_FLAG = 'quickCloseFlag',
  RATE_HOLD_EXPIRY_DATE = 'rateHoldExpiryDate',
}

export enum RMCCommitmentDatesFields {
  COMMITMENT_ISSUE_DATE = 'commitmentIssueDate',
  COMMITMENT_EXPIRY_DATE = 'commitmentExpiryDate',
}
