import { CreateApplicationData } from './applicationData';

export interface ExternalDeal {
  source: string;
  content: LendeskExternalDealContent | VelocityExternalDealContent | any;
}

export interface LendeskExternalDealContent {
  applicationId: string;
  submissionId: string;
  applicationDealId: string;
  dealId: string;
  language: string;
  lenderName: string;
  lenderCode: string;
  linkId: string;
  registrationName: string;
  routingReceiver: string;
  routingSender: string;
  submitRequestTime: string;
  messageBase64: string;
  messageXML: string;
  messageJSON: string;
  messageParsed: CreateApplicationData;
}

export interface VelocityExternalDealContent {
  messageId: string;
  receiver: string;
  sender: string;
  tenantId: string;
  version: string;
  timestamp: Date;
  transaction: string;
  documentContentParsed: unknown;
}

export interface FilogixExternalDealContent {
  applicationId: string;
  submissionId: string;
  applicationDealId: string;
  dealId: string;
  language: string;
  lenderName: string;
  lenderCode: string;
  linkId: string;
  registrationName: string;
  routingReceiver: string;
  routingSender: string;
  submitRequestTime: string;
  messageBase64: string;
  messageXML: string;
  messageJSON: unknown;
  messageParsed: Partial<CreateApplicationData>;
}
