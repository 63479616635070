import {
  fromLocalDateToShortISODate,
  fromShortISODateToMiddleLocalDate,
} from '@fundmoreai/helpers';
import { Mortgage, PaymentFrequency } from '@fundmoreai/models';
import { addDays, addMonths, addWeeks, differenceInCalendarDays, endOfMonth } from 'date-fns';

export function computeMaturityDate(
  mortgage:
    | Pick<Mortgage, 'termMonths' | 'closingDate' | 'interestAdjustmentDate' | 'paymentFrequency'>
    | undefined,
) {
  let maturityDate: Date | undefined;
  if (mortgage.termMonths && mortgage.closingDate) {
    const startingDateForMaturityDateCalculation = !mortgage.interestAdjustmentDate
      ? fromShortISODateToMiddleLocalDate(mortgage.closingDate)
      : fromShortISODateToMiddleLocalDate(mortgage.interestAdjustmentDate);
    const maturityDateBasedOnMonthsInTerm = addMonths(
      startingDateForMaturityDateCalculation,
      mortgage.termMonths,
    );
    const paymentFrequency = mortgage.paymentFrequency;

    switch (paymentFrequency) {
      case PaymentFrequency.MONTHLY:
      case PaymentFrequency.SEMI_MONTHLY:
      case PaymentFrequency.ANNUALLY:
      case PaymentFrequency.SEMI_ANNUALLY:
      case PaymentFrequency.QUARTERLY:
      case PaymentFrequency.DAILY:
        {
          maturityDate = maturityDateBasedOnMonthsInTerm;
        }
        break;
      case PaymentFrequency.BI_WEEKLY:
      case PaymentFrequency.ACCELERATED_BI_WEEKLY:
        {
          const twoWeeksInDays = 14;
          const maturityDateBasedOnNumberOfPayments = addWeeks(
            startingDateForMaturityDateCalculation,
            (mortgage.termMonths / 12) * 26 * 2, // 26 payments per year, 2 weeks per payment
          );
          const daysBetweenMaturityDateBasedOnMonthsAndNumberOfPayments = differenceInCalendarDays(
            maturityDateBasedOnMonthsInTerm,
            maturityDateBasedOnNumberOfPayments,
          );
          if (daysBetweenMaturityDateBasedOnMonthsAndNumberOfPayments === twoWeeksInDays) {
            maturityDate = maturityDateBasedOnMonthsInTerm;
          } else {
            maturityDate = maturityDateBasedOnNumberOfPayments;
          }
        }
        break;
      case PaymentFrequency.WEEKLY:
      case PaymentFrequency.ACCELERATED_WEEKLY:
        {
          const oneWeekInDays = 7;
          const maturityDateBasedOnNumberOfPayments = addWeeks(
            startingDateForMaturityDateCalculation,
            (mortgage.termMonths / 12) * 52,
          );
          const daysBetweenMaturityDateBasedOnMonthsAndNumberOfPayments = differenceInCalendarDays(
            maturityDateBasedOnMonthsInTerm,
            maturityDateBasedOnNumberOfPayments,
          );
          if (daysBetweenMaturityDateBasedOnMonthsAndNumberOfPayments === oneWeekInDays) {
            maturityDate = maturityDateBasedOnMonthsInTerm;
          } else {
            maturityDate = maturityDateBasedOnNumberOfPayments;
          }
        }
        break;
      default:
        maturityDate = maturityDateBasedOnMonthsInTerm;
        break;
    }
  }
  return fromLocalDateToShortISODate(maturityDate);
}

export function computeMaturityDateWithoutExtraPayment(
  mortgage:
    | Pick<
        Mortgage,
        | 'termMonths'
        | 'closingDate'
        | 'interestAdjustmentDate'
        | 'paymentFrequency'
        | 'firstRegularPaymentDate'
      >
    | undefined,
) {
  let maturityDate: Date | undefined;
  if (mortgage.termMonths && mortgage.firstRegularPaymentDate) {
    const startingDateForMaturityDateCalculation = fromShortISODateToMiddleLocalDate(
      mortgage.firstRegularPaymentDate,
    );
    let maturityDateBasedOnMonthsInTerm = addMonths(
      startingDateForMaturityDateCalculation,
      mortgage.termMonths - 1,
    );
    if (
      startingDateForMaturityDateCalculation.getDate() ===
      endOfMonth(startingDateForMaturityDateCalculation).getDate()
    ) {
      maturityDateBasedOnMonthsInTerm = endOfMonth(maturityDateBasedOnMonthsInTerm);
    }
    const paymentFrequency = mortgage.paymentFrequency;

    switch (paymentFrequency) {
      case PaymentFrequency.MONTHLY:
      case PaymentFrequency.ANNUALLY:
      case PaymentFrequency.SEMI_ANNUALLY:
      case PaymentFrequency.QUARTERLY:
      case PaymentFrequency.DAILY:
        {
          maturityDate = maturityDateBasedOnMonthsInTerm;
        }
        break;
      case PaymentFrequency.SEMI_MONTHLY:
        {
          maturityDate = addDays(maturityDateBasedOnMonthsInTerm, 14);
        }
        break;
      case PaymentFrequency.BI_WEEKLY:
      case PaymentFrequency.ACCELERATED_BI_WEEKLY:
        {
          maturityDate = addWeeks(
            startingDateForMaturityDateCalculation,
            ((mortgage.termMonths / 12) * 26 - 1) * 2, // 26 payments per year, 2 weeks per payment
          );
        }
        break;
      case PaymentFrequency.WEEKLY:
      case PaymentFrequency.ACCELERATED_WEEKLY:
        {
          // const oneWeekInDays = 7;
          maturityDate = addWeeks(
            startingDateForMaturityDateCalculation,
            (mortgage.termMonths / 12) * 52 - 1,
          );
        }
        break;
      default:
        maturityDate = maturityDateBasedOnMonthsInTerm;
        break;
    }
  }
  return fromLocalDateToShortISODate(maturityDate);
}
