<div mat-dialog-title>
  <h3 class="title">{{ title }}</h3>
  <button type="button" mat-icon-button [disableRipple]="false" (click)="closeDialog()">
    <mat-icon>close</mat-icon>
  </button>
</div>
<mat-dialog-content class="overflow-hidden pb-0">
  <div class="d-flex justify-content-center pb-16 pt-16">
    <ng-container [ngSwitch]="status">
      <span *ngSwitchCase="ApplicationConditionStatus.FULFILLED">
        <mat-icon color="primary" class="large-icon">check_circle</mat-icon>
      </span>

      <span *ngSwitchCase="ApplicationConditionStatus.NOT_ACCEPTABLE">
        <mat-icon class="text-warn large-icon">cancel</mat-icon>
      </span>

      <span *ngSwitchCase="ApplicationConditionStatus.NOT_RELEVANT">
        <mat-icon class="text-muted large-icon">block</mat-icon>
      </span>
    </ng-container>
  </div>

  <p>{{ message }}</p>

  <p i18n>Comments:</p>
  <mat-form-field class="w-100">
    <textarea
      matInput
      name="comments"
      [(ngModel)]="comments"
      cdkTextareaAutosize
      cdkAutosizeMinRows="4"
      cdkAutosizeMaxRows="8">
    </textarea>
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button class="ml-10" mat-button (click)="closeDialog()"><span i18n>Cancel</span></button>

  <button
    mat-raised-button
    name="confirm-status-change"
    color="primary"
    (click)="confirmDialog()"
    [disableRipple]="true">
    <span>{{ confirmText }}</span>
  </button>
</mat-dialog-actions>
