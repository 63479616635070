import { Injectable } from '@angular/core';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import { Mortgage, MortgageCalculationAutomationSettings } from '@fundmoreai/models';
import { MortgagesServices } from 'src/app/portal/mortgages.services';

@Injectable({ providedIn: 'root' })
export class AmortizationScheduleService {
  constructor(private mortgagesServices: MortgagesServices) {}

  getAmortizationSchedule(
    mortgage: Mortgage | undefined,
    amountToAdvance: number | null,
    mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings,
    iadByFpdEnabled: boolean,
  ) {
    const requestedMortgage = this.mortgagesServices.toCommitmentRequestedMortgage(
      mortgage,
      amountToAdvance,
      mortgageCalculationAutomationSettings,
      iadByFpdEnabled,
    );

    return FundmoreCalculator.generateAmortizationSchedule(requestedMortgage);
  }
}
