// eslint-disable-next-line max-classes-per-file
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import { PulledCreditReportResponse, Stakeholder } from './model';
import { CreditReportRequestsService } from './credit-report-requests.service';
import {
  EquifaxCreditReportRequestPayload,
  EquifaxSubjectType,
  EquifaxCreditReport,
  EquifaxRequestApplicantPayload,
  Applicant,
  CreditReportData,
} from '@fundmoreai/models';
import { finalize, forkJoin, Observable, switchMap, tap } from 'rxjs';
import { patch, updateItem } from '@ngxs/store/operators';
import { PatchBulkApplicantsCreditScore } from '../../../../portal/applicants.actions';
import { MortgageApplicationState } from '../../../../portal/mortgage-application.state';
import { format } from 'date-fns';
import { DateFormats } from '../../constants';
import { PatchLiabilities } from 'src/app/features/application/widgets/credit/financial-liability.actions';
import { ApplicationResetState } from '../../../../shared/state.model';
import { LoadingStart, LoadingEnd } from '../../../../core/loading.state';

export class RequestStakeholdersCreditReport {
  static readonly type = '@creditReportRequests.requestStakeholdersCreditReport';
}

export class CreditReportRequestGetAllExistingRequests {
  static readonly type = '@creditReportRequests.getAllExistingRequest';
  constructor(public applicationId: string) {}
}

export class CreditReportUpdate {
  static readonly type = '@creditReportRequests.creditReportUpdate';
  constructor(
    public applicationId: string,
    public creditReportId: string,
    public creditReport: Partial<CreditReportData>,
  ) {}
}

export class CreditReportSetSelectedStakeholders {
  static readonly type = '@creditReportRequests.setSelectedStakeholders';
  constructor(public applicants: Applicant[]) {}
}

export class CreditReportUpdateStakeholder {
  static readonly type = '@creditReportRequests.updateStakeholder';
  constructor(public stakeholder: Partial<Stakeholder>) {}
}

export class CreditReportResetNewReportRequest {
  static readonly type = '@creditReportRequests.resetNewReportRequest';
}

export class CreditReportApplyCreditReports {
  static readonly type = '@creditReportRequests.applyCreditReport';
  constructor(public applicationId: string) {}
}

interface CreditReportRequestStateModel {
  newCreditReportsResponses: PulledCreditReportResponse[];
  requestedCreditReports: PulledCreditReportResponse[];
  selectedStakeholders: Stakeholder[];
}
const defaults = {
  selectedStakeholders: [],
  newCreditReportsResponses: [],
  requestedCreditReports: [],
};
@State<CreditReportRequestStateModel>({
  name: 'CreditReportRequests',
  defaults: { ...defaults },
})
@Injectable()
export class CreditReportRequestState {
  constructor(
    private store: Store,
    private creditReportRequestServices: CreditReportRequestsService,
  ) {}

  @Action(ApplicationResetState)
  reset(ctx: StateContext<CreditReportRequestStateModel>) {
    ctx.setState({ ...defaults });
  }

  @Selector()
  static newCreditReports(state: CreditReportRequestStateModel): EquifaxCreditReport[] {
    return state.newCreditReportsResponses.map((res) => res.reports);
  }

  @Selector()
  static requestedCreditReports(
    state: CreditReportRequestStateModel,
  ): PulledCreditReportResponse[] {
    return state.requestedCreditReports;
  }

  static latestRequestedCreditReportsByApplicant(applicantId: string | undefined) {
    return createSelector(
      [CreditReportRequestState.requestedCreditReports],
      (
        requestedCreditReports: PulledCreditReportResponse[] | undefined,
      ): PulledCreditReportResponse[] | null => {
        if (!requestedCreditReports || !applicantId) {
          return null;
        }

        const sortedReportsByApplicant = requestedCreditReports
          .filter(
            (report) =>
              report.mappedApplicationEntities &&
              report.mappedApplicationEntities.applicants &&
              report.mappedApplicationEntities.applicants[0]?.id === applicantId,
          )
          .sort((response1, response2) => {
            const response1Date = new Date(response1.createdAt);
            const response2Date = new Date(response2.createdAt);
            return response2Date.getTime() - response1Date.getTime();
          });

        return sortedReportsByApplicant.length > 0 ? sortedReportsByApplicant : null;
      },
    );
  }

  @Selector()
  static selectedStakeholders(state: CreditReportRequestStateModel) {
    return [...state.selectedStakeholders].sort((applicant1, applicant2) => {
      if (applicant1.isPrimary) {
        return -1;
      }
      if (applicant2.isPrimary) {
        return 1;
      }
      if (applicant1.name < applicant2.name) {
        return -1;
      }
      if (applicant1.name > applicant2.name) {
        return 1;
      }
      return 0;
    });
  }

  @Action(CreditReportUpdate)
  private updateCreditReport(
    ctx: StateContext<CreditReportRequestStateModel>,
    action: CreditReportUpdate,
  ) {
    const { applicationId, creditReportId, creditReport } = action;
    this.store.dispatch(new LoadingStart(this.constructor.name));
    return this.creditReportRequestServices
      .updateCreditReport(creditReportId, {
        applicationId,
        creditReport,
      })
      .pipe(
        switchMap(() => {
          return ctx.dispatch(new CreditReportRequestGetAllExistingRequests(applicationId));
        }),
        finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(CreditReportRequestGetAllExistingRequests)
  private creditReportRequestGetAll(
    ctx: StateContext<CreditReportRequestStateModel>,
    action: CreditReportRequestGetAllExistingRequests,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.creditReportRequestServices.getAllCreditReportRequest(action.applicationId).pipe(
      tap((creditReportRequests) => {
        ctx.patchState({ requestedCreditReports: creditReportRequests });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreditReportSetSelectedStakeholders)
  private setSelectedStakeholders(
    ctx: StateContext<CreditReportRequestStateModel>,
    action: CreditReportSetSelectedStakeholders,
  ) {
    const stakeholders: Stakeholder[] = action.applicants.map((applicant) => {
      const currentEmployment = applicant.Jobs?.find((job) => job.isCurrent && !job.unableToVerify);

      const stakeholder: Stakeholder = {
        ...applicant,
        spouseStakeholderId: undefined,
        pullJointCreditReport: false,
        employerName: currentEmployment?.employerName,
        occupation: currentEmployment?.occupation,
      };
      return stakeholder;
    });
    ctx.patchState({ selectedStakeholders: stakeholders });
  }

  @Action(CreditReportUpdateStakeholder)
  private updateStakeholder(
    ctx: StateContext<CreditReportRequestStateModel>,
    action: CreditReportUpdateStakeholder,
  ) {
    const { stakeholder } = action;

    ctx.setState(
      patch({
        selectedStakeholders: updateItem<Stakeholder>(
          (f) => f?.id === stakeholder.id,
          patch(stakeholder),
        ),
      }),
    );
  }
  private buildApplicantPayload(
    stakeholder: Stakeholder,
    subjectType: EquifaxSubjectType,
  ): EquifaxRequestApplicantPayload {
    const payload: EquifaxRequestApplicantPayload = {
      subjectType,
      id: stakeholder.id,
      address: {
        type: stakeholder.currentResidence?.type,
        streetNumber: stakeholder.currentResidence?.streetNumber,
        streetName: stakeholder.currentResidence?.streetName,
        unit: stakeholder.currentResidence?.unit,
        city: stakeholder.currentResidence?.city,
        province: stakeholder.currentResidence?.province,
        postalCode: stakeholder.currentResidence?.postalCode,
      },
      dateOfBirth: format(
        stakeholder.dateOfBirth ? new Date(stakeholder.dateOfBirth) : new Date(),
        DateFormats.ISO_WITHOUT_TIMEZONE,
      ),
      firstName: stakeholder.name,
      lastName: stakeholder.surname,
      sin: stakeholder.sin,
      cellPhoneNumber: stakeholder.cellPhone,
      employer: stakeholder.employerName,
      occupation: stakeholder.occupation,
    };
    return payload;
  }

  private getSpouseStakeholder(
    stakeholder: Stakeholder,
  ): EquifaxRequestApplicantPayload | undefined {
    if (!stakeholder.pullJointCreditReport) {
      return;
    }
    const selectedStakeholders = this.store.selectSnapshot(
      CreditReportRequestState.selectedStakeholders,
    );
    const spouse = selectedStakeholders?.find((s) => s.id === stakeholder.spouseStakeholderId);
    if (!spouse) {
      return;
    }
    const spousePayload = this.buildApplicantPayload(spouse, EquifaxSubjectType.SPOUSE);
    return spousePayload;
  }

  @Action(RequestStakeholdersCreditReport)
  private getCreditReports(ctx: StateContext<CreditReportRequestStateModel>) {
    ctx.patchState({ newCreditReportsResponses: [] });
    const selectedStakeholders = this.store.selectSnapshot(
      CreditReportRequestState.selectedStakeholders,
    );
    const applicationId = this.store.selectSnapshot(MortgageApplicationState.application)?.id;
    const requests: Observable<PulledCreditReportResponse>[] = [];
    const addedStakeholderIds: string[] = [];
    for (const stakeholder of selectedStakeholders ?? []) {
      if (addedStakeholderIds.includes(stakeholder.id)) {
        continue;
      }
      const payload: EquifaxCreditReportRequestPayload = {
        applicationId,
        applicants: [this.buildApplicantPayload(stakeholder, EquifaxSubjectType.MAIN_SUBJECT)],
      };
      addedStakeholderIds.push(stakeholder.id);

      const spousePayload = this.getSpouseStakeholder(stakeholder);
      if (!spousePayload) {
        requests.push(this.creditReportRequestServices.requestCreditReportEquifax(payload));
        continue;
      }
      payload.applicants.push(spousePayload);
      addedStakeholderIds.push(spousePayload.id);

      requests.push(this.creditReportRequestServices.requestCreditReportEquifax(payload));
    }
    this.store.dispatch(new LoadingStart(this.constructor.name));
    return forkJoin(requests).pipe(
      tap((res) => {
        ctx.patchState({
          newCreditReportsResponses: res,
        });
      }),
      finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreditReportResetNewReportRequest)
  private resetState(ctx: StateContext<CreditReportRequestStateModel>) {
    ctx.patchState({
      newCreditReportsResponses: [],
      selectedStakeholders: [],
    });
  }

  @Action(CreditReportApplyCreditReports)
  private applyCreditReportChanges(
    ctx: StateContext<CreditReportRequestStateModel>,
    action: CreditReportApplyCreditReports,
  ) {
    const applyCreditReportsIds = ctx.getState().newCreditReportsResponses.map((res) => res.id);
    this.store.dispatch(new LoadingStart(this.constructor.name));
    return this.creditReportRequestServices
      .applyCreditReports({
        applicationId: action.applicationId,
        creditReportRequestsIds: applyCreditReportsIds,
      })
      .pipe(
        tap((appliedChanges) => {
          if (!appliedChanges) {
            return;
          }
          const state = ctx.getState();
          ctx.patchState({
            requestedCreditReports: [
              ...state.requestedCreditReports,
              ...state.newCreditReportsResponses,
            ],
          });
          ctx.dispatch(new PatchBulkApplicantsCreditScore(appliedChanges.applicants));
          ctx.dispatch(new PatchLiabilities(appliedChanges.liabilities));
        }),
        finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }
}
