import { GentaiTiers } from '@fundmoreai/models';

export function isGentaiProvinceEligible(province: string): boolean {
  const eligibleProvinces = [
    'British Columbia',
    'Alberta',
    'Ontario',
    'Quebec',
  ];
  return eligibleProvinces.includes(province);
}

export function isGentaiTier1(province: string, city: string): boolean {
  const GentaiGtaAndGvaCities = [
    'Toronto',
    'Ajax',
    'Aurora',
    'Brampton',
    'Burlington',
    'Georgetown',
    'Markham',
    'Mississauga',
    'Milton',
    'Oakville',
    'Oshawa',
    'Pickering',
    'Richmond Hill',
    'Vaughan',
    'Whitby',
    'Vancouver',
    'Anmore',
    'Burnaby',
    'Coquitlam',
    'Port Coquitlam',
    'Delta',
    'Langley',
    'Maple Ridge',
    'New Westminster',
    'North Vancouver',
    'Pitt Meadows',
    'Port Moody',
    'Richmond',
    'Surrey',
    'Tsawwassen',
    'West Vancouver',
    'White Rock',
  ];
  return (
    isGentaiProvinceEligible(province) && GentaiGtaAndGvaCities.includes(city)
  );
}

export function isGentaiTier2(province: string, city: string): boolean {
  const eligibleTier2Cities = [
    'Victoria',
    'Kelowna',
    'Abbottsford',
    'Ottawa',
    'Hamilton',
    'Kitchener-Waterloo',
    'Cambridge',
    'Windsor',
    'St. Catharines-Niagara',
    'London',
    'Barrie',
    'Guelph',
    'Kingston',
    'Kanata',
    'Calgary',
    'Edmonton',
    'Lethbridge',
    'Red Deer',
    'Montreal',
    'Quebec City',
    'Gatineau',
  ];
  return (
    isGentaiProvinceEligible(province) && eligibleTier2Cities.includes(city)
  );
}

export function isGentaiTier3(province: string, city: string): boolean {
  const eligibleTier3Cities = [
    'Nanalmo',
    'Kamloops',
    'Chiliwack',
    'Prince George',
    'Vernon',
    'Courtney',
    'Penticton',
    'Mission',
    'Brantford',
    'Belleville',
    'Woodstock',
  ];
  return (
    isGentaiProvinceEligible(province) && eligibleTier3Cities.includes(city)
  );
}

export function getPropertyTier(province: string, city: string): number {
  if (isGentaiTier1(province, city)) {
    return GentaiTiers.Tier1;
  } else if (isGentaiTier2(province, city)) {
    return GentaiTiers.Tier2;
  } else if (isGentaiTier3(province, city)) {
    return GentaiTiers.Tier3;
  }
  return GentaiTiers.None;
}
