import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { DownPayment } from '../shared';

@Injectable({
  providedIn: 'root',
})
export class DownpaymentsService {
  constructor(private http: HttpClient) {}

  getDownPayments(applicationId: string): Observable<DownPayment[]> {
    return this.http.get<DownPayment[]>(
      `${environment.api_url}/applications/${applicationId}/downPayments`,
    );
  }

  postDownPayments(applicationId: string, downPayments: DownPayment[]): Observable<DownPayment[]> {
    return this.http.post<DownPayment[]>(
      `${environment.api_url}/applications/${applicationId}/downPayments`,
      downPayments,
    );
  }

  patchDownPayment(
    applicationId: string,
    downPaymentId: string,
    downPayment: DownPayment,
  ): Observable<void> {
    return this.http.patch<void>(
      `${environment.api_url}/applications/${applicationId}/downPayments/${downPaymentId}`,
      downPayment,
    );
  }

  deleteDownPayment(applicationId: string, downPaymentId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.api_url}/applications/${applicationId}/downPayments/${downPaymentId}`,
    );
  }
}
