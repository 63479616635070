import { assertUnreachable, Mortgage, PaymentFrequency } from '@fundmoreai/models';

export type ComputePrepaymentAmountMortgage = Pick<
  Partial<Mortgage>,
  | 'monthlyPayment'
  | 'paymentFrequency'
  | 'prepaymentAmount'
  | 'prepaymentAmountInPayments'
  | 'isPrepaymentAmountInPayments'
>;

export function calculatePrepaymentAmount(
  requestedMortgage: ComputePrepaymentAmountMortgage | undefined,
): number | undefined {
  if (!requestedMortgage.isPrepaymentAmountInPayments) {
    return requestedMortgage.prepaymentAmount;
  }

  if (!requestedMortgage.monthlyPayment) {
    return undefined;
  }

  let prepaymentAmount: number;
  const paymentFrequency: PaymentFrequency =
    (requestedMortgage && requestedMortgage.paymentFrequency) || PaymentFrequency.MONTHLY;
  prepaymentAmount = (requestedMortgage && requestedMortgage.prepaymentAmount) || 0.0;
  const prepaymentAmountInPayments =
    (requestedMortgage && requestedMortgage.prepaymentAmountInPayments) || null;
  const isPrepaymentAmountInPayments: boolean =
    (requestedMortgage && requestedMortgage.isPrepaymentAmountInPayments) || false;

  prepaymentAmount = prepaymentAmountByFrequency(
    requestedMortgage.monthlyPayment,
    paymentFrequency,
    prepaymentAmount,
    prepaymentAmountInPayments,
    isPrepaymentAmountInPayments,
  );
  return Math.round((prepaymentAmount ?? 0) * 100) / 100;
}

export function prepaymentAmountByFrequency(
  monthlyPayment: number,
  paymentFrequency: PaymentFrequency,
  prepaymentAmount: number,
  prepaymentAmountInPayments: number | undefined,
  isPrepaymentAmountInPayments: boolean,
): number {
  const payment = monthlyPayment;
  let calculatedPrepaymentAmount = prepaymentAmount;

  if (isPrepaymentAmountInPayments) {
    const paymentPerFrequencyValue = paymentPerFrequency(paymentFrequency, payment);

    calculatedPrepaymentAmount = prepaymentAmountInPayments * paymentPerFrequencyValue;
  }

  return calculatedPrepaymentAmount;
}
export function paymentPerFrequency(paymentFrequency: PaymentFrequency, payment: number) {
  switch (paymentFrequency) {
    case PaymentFrequency.SEMI_MONTHLY:
      return (payment * 12) / 24;
    case PaymentFrequency.BI_WEEKLY:
      return (payment * 12) / 26;
    case PaymentFrequency.ACCELERATED_BI_WEEKLY:
      return (payment * 13) / 26;
    case PaymentFrequency.WEEKLY:
      return (payment * 12) / 52;
    case PaymentFrequency.ACCELERATED_WEEKLY:
      return (payment * 13) / 52;
    case PaymentFrequency.MONTHLY:
      return payment;
    case PaymentFrequency.ANNUALLY:
      return payment * 12;
    case PaymentFrequency.DAILY:
      return (payment * 12) / 365;
    case PaymentFrequency.QUARTERLY:
      return (payment * 12) / 4;
    case PaymentFrequency.SEMI_ANNUALLY:
      return (payment * 12) / 2;
    default:
      assertUnreachable(paymentFrequency, 'Unhandled PaymentFrequency');
      return payment;
  }
}
