export interface PrivateDMDocument {
  id: string;
  applicationId: string;
  stakeholderId: string;
  applicationDocumentId: string;
  documentTypeId: string | null;
  status: PrivateDMDocumentStatus | null;
  rejectReason: PrivateDMDocumentRejectReasons | null;
  comment: string | null;
  verifiedOn: Date | null;
  verifiedBy: string | null;
  displayName: string;
  applicationDocument: {
    id: string;
    applicationId: string;
    type: string;
    source: string;
    context: {
      name: string | null;
      type: string | null;
    };
    status: string | null;
    createdAt: Date;
  };
  comments: PrivateDMDocumentComment[];
  sharedExternally: boolean;
  stage: string | null;
  attached: boolean;
  createdAt: Date;
}

export interface PrivateDMDocumentStatusUpdate {
  status: PrivateDMDocumentStatus;
  rejectReason?: PrivateDMDocumentRejectReasons;
  comment?: string;
}

export interface PrivateDMDocumentComment {
  id: string;
  applicationId: string;
  applicationConditionDocumentFileId: string;
  reviewStatus: string | null;
  reviewReason: string | null;
  reviewComment: string | null;
  text: string | null;
  commentBy: string;
  seenHistory: PrivateDMDocumentCommentSeenHistory[];
  createdAt: Date;
  shareExternally?: boolean;
  authorName?: string;
}

export interface PrivateDMDocumentCommentSeenHistory {
  id: string;
  applicationConditionDocumentFileCommentId: string;
  seenBy: string;
  seenAt: Date;
  metadata?: PrivateDMDocumentCommentSeenHistoryMetadata;
}

export interface PrivateDMDocumentCommentSeenHistoryMetadata {
  seenFromURL?: string;
}

export enum PrivateDMDocumentKey {
  ID = 'id',
  APPLICATION_ID = 'applicationId',
  STAKEHOLDER_ID = 'stakeholderId',
  APPLICATION_DOCUMENT_ID = 'applicationDocumentId',
  DOCUMENT_TYPE_ID = 'documentTypeId',
  STATUS = 'status',
  REJECT_REASON = 'rejectReason',
  COMMENT = 'comment',
  VERIFIED_ON = 'verifiedOn',
  VERIFIED_BY = 'verifiedBy',
  DISPLAY_NAME = 'displayName',
}

export enum PrivateDMDocumentCommentKey {
  TEXT = 'text',
}

export enum PrivateDMDocumentStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum PrivateDMDocumentRejectReasons {
  INCORRECT_DOCUMENT = 'INCORRECT_DOCUMENT',
  TOO_OLD = 'TOO_OLD',
  MISSING_PAGES = 'MISSING_PAGES',
  LOW_QUALITY = 'LOW_QUALITY',
  OTHER = 'OTHER',
}
