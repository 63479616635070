import { StreetDirection } from '../../enums';

export enum FCTStreetDirection {
  EAST = '001',
  NORTH = '002',
  NORTHEAST = '003',
  NORTHWEST = '004',
  SOUTH = '005',
  SOUTHEAST = '006',
  SOUTHWEST = '007',
  WEST = '008',
}

export const fctStreetDirectionMap: Record<StreetDirection, FCTStreetDirection> = {
  [StreetDirection.N]: FCTStreetDirection.NORTH,
  [StreetDirection.S]: FCTStreetDirection.SOUTH,
  [StreetDirection.E]: FCTStreetDirection.EAST,
  [StreetDirection.W]: FCTStreetDirection.WEST,
  [StreetDirection.NE]: FCTStreetDirection.NORTHEAST,
  [StreetDirection.NW]: FCTStreetDirection.NORTHWEST,
  [StreetDirection.SE]: FCTStreetDirection.SOUTHEAST,
  [StreetDirection.SW]: FCTStreetDirection.SOUTHWEST,
};
