import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { DrawSchedule } from '../../shared';
import { ConstructionDrawScheduleService } from './construction-draw-schedule.service';
import {
  GetDrawSchedules,
  InsertDrawSchedule,
  RecalculateDrawSchedule,
  RemoveDrawSchedule,
  UpdateAvailableAdvanceAmount,
  UpdateDrawDate,
  UpdateHardCostToComplete,
} from './construction.actions';
import { ApplicationResetState } from '../../shared/state.model';
import { LoadingStart, LoadingEnd } from '../../core/loading.state';

interface DrawScheduleModel {
  drawSchedules: DrawSchedule[];
}

@State<DrawScheduleModel>({
  name: 'drawSchedule',
  defaults: {
    drawSchedules: [],
  },
})
@Injectable()
export class ConstructionDrawScheduleState {
  constructor(private drawScheduleService: ConstructionDrawScheduleService, private store: Store) {}

  @Action(ApplicationResetState)
  reset(ctx: StateContext<DrawScheduleModel>) {
    ctx.setState({ drawSchedules: [] });
  }

  @Selector() static drawSchedules(state: DrawScheduleModel) {
    const schedule = [...state.drawSchedules];
    return schedule.sort((a, b) => {
      if (a && b) {
        const aScheduleDrawDateTime: number = new Date(a.drawDate).getTime();
        const bScheduleDrawDateTime: number = new Date(b.drawDate).getTime();
        return aScheduleDrawDateTime - bScheduleDrawDateTime;
      }
      return 0;
    });
  }

  @Action(GetDrawSchedules)
  public getDrawSchedules(
    ctx: StateContext<DrawScheduleModel>,
    { applicationId }: GetDrawSchedules,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.drawScheduleService.getConstructionSchedules(applicationId).pipe(
      tap((drawSchedules) => ctx.patchState({ drawSchedules })),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(InsertDrawSchedule)
  public insertDrawSchedule(
    ctx: StateContext<DrawScheduleModel>,
    { drawSchedule }: InsertDrawSchedule,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...scheduleToCreate } = drawSchedule;
    return this.drawScheduleService.postConstructionSchedule(scheduleToCreate).pipe(
      tap((newSchedule) => {
        const scheduleSnapshot = ctx.getState().drawSchedules;
        ctx.patchState({ drawSchedules: [...scheduleSnapshot, newSchedule] });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(RemoveDrawSchedule)
  public removeDrawSchedule(ctx: StateContext<DrawScheduleModel>, { id }: RemoveDrawSchedule) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.drawScheduleService.deleteConstructionSchedule(id).pipe(
      tap((drawSchedules) => {
        ctx.patchState({ drawSchedules });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateHardCostToComplete)
  public updateHardCostToComplete(
    ctx: StateContext<DrawScheduleModel>,
    { hardCostToCompletePayload }: UpdateHardCostToComplete,
  ): Observable<DrawSchedule[]> {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.drawScheduleService.updateHardCostToComplete(hardCostToCompletePayload).pipe(
      tap((drawSchedules: DrawSchedule[]) => {
        ctx.patchState({ drawSchedules });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateDrawDate)
  public updateDrawDate(ctx: StateContext<DrawScheduleModel>, { drawDatePayload }: UpdateDrawDate) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.drawScheduleService.updateDrawDate(drawDatePayload).pipe(
      tap((drawSchedules: DrawSchedule[]) => {
        ctx.patchState({ drawSchedules });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateAvailableAdvanceAmount)
  public updateAvailableAdvanceAmount(
    ctx: StateContext<DrawScheduleModel>,
    { availableAdvanceAmountPayload }: UpdateAvailableAdvanceAmount,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.drawScheduleService
      .updateAvailableAdvanceAmount(availableAdvanceAmountPayload)
      .pipe(
        tap((drawSchedules: DrawSchedule[]) => {
          ctx.patchState({ drawSchedules });
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(RecalculateDrawSchedule)
  public recalculateDrawSchedule(
    ctx: StateContext<DrawScheduleModel>,
    { applicationId }: RecalculateDrawSchedule,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.drawScheduleService.recalculateDrawSchedule(applicationId).pipe(
      tap((drawSchedules: DrawSchedule[]) => {
        ctx.patchState({ drawSchedules });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }
}
