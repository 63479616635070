import { Mortgage, PayoffPaydownType } from '@fundmoreai/models';

export type ComputeExistingMortgagePaymentsInput = Pick<
  Partial<Mortgage>,
  'monthlyPayment' | 'payoffPaydown'
>;

export function computeExistingMortgagePayments(
  existingMortgages: ComputeExistingMortgagePaymentsInput[],
): number {
  let existingMortgagePayments = 0;

  for (const existingMortgage of existingMortgages) {
    if (
      existingMortgage.monthlyPayment &&
      (!existingMortgage.payoffPaydown || existingMortgage.payoffPaydown === PayoffPaydownType.NONE)
    ) {
      existingMortgagePayments += existingMortgage.monthlyPayment;
    }
  }
  return existingMortgagePayments;
}
