import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PropertyAddressDetails } from '@fundmoreai/models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AddressDetailsService {
  private base = `${environment.fundmore_api_url}/propertyAddress`;

  constructor(private http: HttpClient) {}

  getPropertyAddresses(): Observable<PropertyAddressDetails[]> {
    return this.http.get<PropertyAddressDetails[]>(this.base);
  }

  getPropertyAddress(propertyId: string): Observable<PropertyAddressDetails> {
    return this.http.get<PropertyAddressDetails>(`${this.base}/${propertyId}`);
  }

  updatePropertyAddress(propertyAddress: Partial<PropertyAddressDetails>) {
    return this.http.patch<PropertyAddressDetails>(`${this.base}/${propertyAddress.id}`, {
      ...propertyAddress,
    });
  }

  createPropertyAddress(propertyAddress: Partial<PropertyAddressDetails>) {
    return this.http.post<PropertyAddressDetails>(`${this.base}`, {
      ...propertyAddress,
      id: undefined,
    });
  }

  delete(propertyAddressId: string) {
    return this.http.delete<PropertyAddressDetails>(`${this.base}/${propertyAddressId}`);
  }
}
