import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { FinancialAssetsService } from './financial-assets.service';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import {
  AddFinancialAsset,
  AddFinancialAssetToDownPayment,
  DeleteFinancialAsset,
  RemoveFinancialAssetToDownPayment,
  SetFinancialAssets,
  UpdateFinancialAsset,
} from './financial-assets.actions';
import { FinancialAsset } from '@fundmoreai/models';
import { ApplicationResetState } from '../../../../shared/state.model';
import { LoadingStart, LoadingEnd } from '../../../../core/loading.state';
import { AddDownPaymentLocal, RemoveAssetDownPayment } from 'src/app/portal/downpayments.actions';

interface FinancialAssetsStateModel {
  financialAssets?: {
    [key: string]: FinancialAsset;
  };
}

const defaults = { financialAssets: undefined };
@State({
  name: 'financialAssets',
  defaults: { ...defaults },
})
@Injectable()
export class FinancialAssetsState {
  @Selector()
  private static financialAssets(state: FinancialAssetsStateModel): FinancialAsset[] | undefined {
    return state.financialAssets ? Object.values(state.financialAssets) : undefined;
  }

  @Selector([FinancialAssetsState.financialAssets])
  static financialAssetsList(financialAssets: FinancialAsset[] | undefined) {
    return financialAssets?.sort(
      (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
    );
  }

  static networth(applicantId?: string) {
    return createSelector(
      [FinancialAssetsState.financialAssets],
      (financialAssets: FinancialAsset[] | undefined) => {
        const filteredFinancialAssets = financialAssets?.filter((fa) => !fa.excludeFromCalculation);

        if (applicantId) {
          return filteredFinancialAssets
            ?.filter((asset) => {
              return asset.ApplicantFinancialAssets.find((x) => x.applicantId === applicantId);
            })
            .reduce(
              (sum, asset) =>
                sum + (asset.value ? +asset.value / asset.ApplicantFinancialAssets.length : 0.0),
              0.0,
            );
        }

        return filteredFinancialAssets?.reduce(
          (sum, asset) => sum + (asset.value ? +asset.value : 0.0),
          0.0,
        );
      },
    );
  }

  constructor(private financialAssetsService: FinancialAssetsService) {}

  @Action(AddFinancialAsset)
  addFinancialAsset(ctx: StateContext<FinancialAssetsStateModel>, action: AddFinancialAsset) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.financialAssetsService
      .postFinancialAssets(action.applicationId, [action.financialAsset])
      .pipe(
        map(([financialAsset]) => {
          const state = ctx.getState();

          ctx.patchState({
            financialAssets: {
              ...state.financialAssets,
              [financialAsset.id]: financialAsset,
            },
          });

          return financialAsset;
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(AddFinancialAssetToDownPayment)
  addFinancialAssetToDownPayment(
    ctx: StateContext<FinancialAssetsStateModel>,
    action: AddFinancialAssetToDownPayment,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.financialAssetsService.addFinancialAssetToDownPayment(action.financialAssetId).pipe(
      switchMap((downPayment) => {
        return ctx.dispatch(new AddDownPaymentLocal(downPayment));
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(DeleteFinancialAsset)
  deleteFinancialAsset(ctx: StateContext<FinancialAssetsStateModel>, action: DeleteFinancialAsset) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.financialAssetsService
      .deleteFinancialAsset(action.applicationId, action.financialAsset.id)
      .pipe(
        tap(() => {
          const state = ctx.getState();
          const financialAssets = { ...state.financialAssets };

          delete financialAssets[action.financialAsset.id];

          ctx.patchState({
            financialAssets,
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(RemoveFinancialAssetToDownPayment)
  removeFinancialAssetFromDownPayment(
    ctx: StateContext<FinancialAssetsStateModel>,
    action: RemoveFinancialAssetToDownPayment,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.financialAssetsService
      .removeFinancialAssetFromDownPayment(action.financialAssetId)
      .pipe(
        switchMap(() => {
          return ctx.dispatch(new RemoveAssetDownPayment(action.financialAssetId));
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(ApplicationResetState)
  resetState(ctx: StateContext<FinancialAssetsStateModel>) {
    ctx.setState({ ...defaults });
  }

  @Action(SetFinancialAssets)
  setFinancialAssets(ctx: StateContext<FinancialAssetsStateModel>, action: SetFinancialAssets) {
    const financialAssetsEntities = action.financialAssets.reduce(
      (entities: { [key: string]: FinancialAsset }, financialAsset) => {
        entities[financialAsset.id] = financialAsset;

        return entities;
      },
      {},
    );

    ctx.setState({ financialAssets: financialAssetsEntities });
  }

  @Action(UpdateFinancialAsset)
  updateFinancialAsset(ctx: StateContext<FinancialAssetsStateModel>, action: UpdateFinancialAsset) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.financialAssetsService
      .patchFinancialAsset(action.applicationId, action.financialAssetId, action.financialAsset)
      .pipe(
        tap(() => {
          const state = ctx.getState();
          const prevFinancialAsset = state.financialAssets?.[action.financialAssetId];

          ctx.patchState({
            financialAssets: {
              ...state.financialAssets,
              [action.financialAssetId]: { ...prevFinancialAsset, ...action.financialAsset },
            },
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }
}
