<div
  class="modal"
  [class.center]="actionModal"
  [class.active]="declineModalClass || cancelModalClass || warningModalClass"
  [ngClass]="modalType">
  <mat-dialog-content>
    <span class="material-icons mb-20">{{ modalIcon }}</span>
    <h2>{{ title }}</h2>
    <p class="mb-20" [innerHTML]="messageHTML" *ngIf="messageHTML"></p>
    <p class="mb-20" [innerText]="message" *ngIf="!messageHTML"></p>
  </mat-dialog-content>

  <mat-dialog-actions [align]="alignButtonsCenter ? 'center' : 'end'">
    <button class="ml-10" mat-button (click)="onDismiss()">{{ noButtonText }}</button>

    <button
      name="dialog-confirm"
      mat-raised-button
      [color]="declineModalClass || cancelModalClass || warningModalClass ? 'warn' : 'primary'"
      *ngIf="this.yesButtonText"
      (click)="onConfirm()">
      {{ yesButtonText }}
    </button>
  </mat-dialog-actions>
</div>
