import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { FieldMetadata } from '@fundmoreai/models';

@Injectable({
  providedIn: 'root',
})
export class FieldMetadataService {
  constructor(private http: HttpClient) {}

  getAllFieldMetadata(): Observable<FieldMetadata[]> {
    return this.http.get<FieldMetadata[]>(`${environment.api_url}/fieldMetadata/all`);
  }

  createFieldMetadata(payload: Partial<FieldMetadata>): Observable<FieldMetadata> {
    return this.http.post<FieldMetadata>(`${environment.api_url}/fieldMetadata`, payload);
  }

  updateFieldMetadata(id: string, payload: Partial<FieldMetadata>) {
    return this.http.patch<void>(`${environment.api_url}/fieldMetadata/${id}`, payload);
  }

  deleteFieldMetadata(id: string) {
    return this.http.delete<void>(`${environment.api_url}/fieldMetadata/${id}`);
  }
}
