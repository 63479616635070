import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Property } from '../shared/model';

@Injectable({
  providedIn: 'root',
})
export class PropertiesService {
  constructor(private http: HttpClient) {}

  postProperties(applicationId: string, properties: Partial<Property>[]): Observable<Property[]> {
    return this.http.post<Property[]>(
      `${environment.api_url}/applications/${applicationId}/properties`,
      properties,
    );
  }

  patchProperty(
    applicationId: string,
    propertyId: string,
    property: Partial<Property>,
  ): Observable<Property> {
    return this.http.patch<Property>(
      `${environment.api_url}/applications/${applicationId}/properties/${propertyId}`,
      property,
    );
  }

  deleteProperty(applicationId: string, propertyId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.api_url}/applications/${applicationId}/properties/${propertyId}`,
    );
  }

  patchCollateralize(propertyId: string, isCollateralized: boolean): Observable<void> {
    return this.http.patch<void>(`${environment.api_url}/properties/${propertyId}/collateralize`, {
      isCollateralized,
    });
  }
}
