export interface MMSContact {
  name: MMSIndividualName | MMSCompanyName;
  communication: MMSCommunication;
}

export interface MMSIndividualName {
  firstName: string;
  middleName?: string;
  lastName: string;
  companyName?: string;
}

export interface MMSCompanyName {
  companyName: string;
}

export interface MMSCommunication {
  phone1?: string;
  phone2?: string;
  extension?: string;
  fax?: string;
  Email?: string;
}
