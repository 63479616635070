import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotesComponent } from './notes.component';
import { NotesListComponent } from './notes-list/notes-list.component';
import { EditNoteFormComponent } from './edit-note-form/edit-note-form.component';
import { NotesSidenavComponent } from './notes-sidenav/notes-sidenav.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { EditorModule } from '@tinymce/tinymce-angular';
import { FormsModule } from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { ApplicationNotesState } from './notes.state';
import { NgxsModule } from '@ngxs/store';
import { RouterModule } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotesReadMoreComponent } from './notes-read-more/notes-read-more.component';
import { NotesService } from './notes.service';
import { ApplicantNamePipe } from 'src/app/shared/applicant-name.pipe';
import { MatSidenavModule } from '@angular/material/sidenav';
import { GenerateAiNoteDialogComponent } from './generate-ai-note-dialog/generate-ai-note-dialog.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSliderModule } from '@angular/material/slider';

@NgModule({
  exports: [NotesComponent, NotesSidenavComponent],
  imports: [
    CommonModule,
    EditorModule,
    FormsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatSidenavModule,
    MatTooltipModule,
    NgxsModule.forFeature([ApplicationNotesState]),
    RouterModule,
    MatDialogModule,
    MatProgressBarModule,
    MatSliderModule,
    NotesComponent,
    NotesListComponent,
    EditNoteFormComponent,
    NotesSidenavComponent,
    NotesReadMoreComponent,
    GenerateAiNoteDialogComponent,
  ],
  providers: [ApplicantNamePipe, NotesService],
})
export class NotesModule {}
