export interface ResultNarrative {
  result: FundmoreResultType;
  narrative: string;
  recommendation?: FundmoreRecommendationModel;
}

export interface FundmoreRecommendationModel {
  display: string;
  type: FundmoreRecommendationType;
  mortgageId?: string;
  changeToNumber?: number;
  changeToDate?: Date;
  changeToProductId?: string;
}
export type FundmoreRecommendation = FundmoreRecommendationModel;

export interface FundmoreNarrative {
  display: string;
  sentiment: FundmoreResultType;
}

export interface OctopusResult {
  name: string;
  type: string;
  result: FundmoreResultType;
  resultNarrative: FundmoreNarrative[];
  narratives: FundmoreNarrative[];
  recommendations: FundmoreRecommendationModel[];

  passedTresholds: number;
  manualReviewTresholds: number;
  unknownTresholds: number;
  failedTresholds: number;
  tresholdLength: number;
}

export interface FundmoreScoreOutput {
  results: OctopusResult[];
}

export interface OctopusResultModel {
  name: string;
  type: string;
  result: FundmoreResultType;
  resultNarrative: FundmoreNarrative[];
  narratives: FundmoreNarrative[];
  recommendations: FundmoreRecommendationModel[];

  passedTresholds: number;
  manualReviewTresholds: number;
  unknownTresholds: number;
  failedTresholds: number;
  tresholdLength: number;
}

export enum FundmoreResultType {
  Pass = 'pass',
  Fail = 'fail',
  Unknown = 'unknown',
  ManualReview = 'manualReview',
}

export enum FundmoreRecommendationType {
  UploadCreditReport = 'UploadCreditReport',
  SwitchToRefinance = 'SwitchToRefinance',
  IncreaseDownPayment = 'IncreaseDownPayment',
  DecreaseMortgageAmount = 'DecreaseMortgageAmount',
  RefinanceSecondMortgage = 'RefinanceSecondMortgage',
  ChangeRequestedMortgageMaturityDate = 'ChangeRequestedMortgageMaturityDate',
  ChangeProduct = 'ChangeProduct',
}
