import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DMDocumentStatusesOnStakeholder } from './dm-documents-status.model';

@Injectable({
  providedIn: 'root',
})
export class DMDocumentsStatusService {
  constructor(private http: HttpClient) {}

  getDMDocumentsStatus(applicationId: string): Observable<{
    linkedDocuments: DMDocumentStatusesOnStakeholder[];
    unlinkedDocuments: DMDocumentStatusesOnStakeholder[];
  }> {
    return this.http.get<{
      linkedDocuments: DMDocumentStatusesOnStakeholder[];
      unlinkedDocuments: DMDocumentStatusesOnStakeholder[];
    }>(`${environment.fundmore_api_url}/dmDocuments/${applicationId}/status`);
  }

  getDocumentContentFromEzidox(documentFileId: number): Observable<{ base64: string }> {
    return this.http.get<{ base64: string }>(
      `${environment.fundmore_api_url}/dmDocuments/${documentFileId}/content`,
    );
  }
}
