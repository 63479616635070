import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AnalysisModel } from './recognitionModel';
import { FilogixDeals } from './uploadModel';

@Injectable({
  providedIn: 'root',
})
export class FilogixDealService {
  constructor(private http: HttpClient) {}

  deals(): Observable<FilogixDeals[]> {
    return this.http.get<FilogixDeals[]>(`${environment.api_url}/filogixDealParsed`);
  }

  parseDeal(id: string): Observable<AnalysisModel> {
    return this.http.get<AnalysisModel>(`${environment.api_url}/filogixDealParsed/${id}`);
  }
}
