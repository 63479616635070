import { Component, ChangeDetectionStrategy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Application } from '@fundmoreai/models';
import { Select, Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { CanComponentDeactivate } from 'src/app/core/unsavedChanges.guard';
import { DetectWindowPopupBlockService } from 'src/app/features/shared/detect-window-popup-block.service';
import { ApplicantsState } from 'src/app/portal/applicants.state';
import { MortgageApplicationState } from 'src/app/portal/mortgage-application.state';
import { getApplicantName } from 'src/app/shared/applicant-name.pipe';
import { DialogsComponent } from '../../sidebar/dialogs/dialogs.component';
import { ExportNotesAsPdf } from '../notes.actions';
import { ApplicationNotesState } from '../notes.state';
import { AsyncPipe } from '@angular/common';
import { NotesComponent } from '../notes.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';

@Component({
  selector: 'fm-notes-sidenav',
  templateUrl: './notes-sidenav.component.html',
  styleUrls: ['./notes-sidenav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    MatSidenavModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule,
    NotesComponent,
    AsyncPipe,
  ],
})
export class NotesSidenavComponent implements CanComponentDeactivate {
  @Select(MortgageApplicationState.application)
  application$: Observable<Application>;

  applicationId: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private detectWindowPopupBlockService: DetectWindowPopupBlockService,
    private dialog: MatDialog,
    private router: Router,
    private store: Store,
  ) {
    this.applicationId = this.activatedRoute.parent?.snapshot.params.id;
  }

  canDeactivate(): Observable<boolean> | boolean {
    if (!this.store.selectSnapshot(ApplicationNotesState.hasChanges)) {
      return true;
    }

    const dialogRef = this.dialog.open(DialogsComponent, {
      data: {
        title: $localize`You have unsaved notes changes!`,
        message: $localize`Would you like to discard the note?`,
        buttonText: {
          // inverted button labels
          yes: $localize`Continue Editing`,
          no: $localize`Discard`,
        },
      },
      minWidth: '400px',
      maxWidth: '400px',
    });

    return dialogRef.afterClosed().pipe(map((result) => !result));
  }

  closeNotes() {
    this.router.navigate([{ outlets: { dropup: null } }], {
      queryParams: this.activatedRoute.parent?.snapshot.queryParams,
      relativeTo: this.activatedRoute.parent,
    });
  }

  exportAsPdf() {
    const application = this.store.selectSnapshot(MortgageApplicationState.application);
    const primaryApplicant = this.store.selectSnapshot(ApplicantsState.primaryApplicant);
    const name = `${getApplicantName(primaryApplicant)}` || application.name;

    this.store.dispatch(new ExportNotesAsPdf(name));
  }

  openInNewWindow() {
    const url = `/portal/notes/${this.applicationId}`;

    const poppedWindow = window.open(
      url,
      undefined,
      // eslint-disable-next-line max-len
      `toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=${screen.availWidth},height=${screen.availHeight}`,
    );

    this.detectWindowPopupBlockService.detectBlockedPopup(poppedWindow);
  }
}
