import {
  ExcludeLiabilityFromCalculationReasonType,
  FinancialLiability,
  PayoffPaydownType,
} from '@fundmoreai/models';

const includeInNetworthCalculations: Set<ExcludeLiabilityFromCalculationReasonType | null> =
  new Set([
    null,
    ExcludeLiabilityFromCalculationReasonType.PAID_BY_OTHER_SOURCE,
    ExcludeLiabilityFromCalculationReasonType.OMIT_FROM_TDS,
  ]);

export function includeLiabilityInNetworthCalculation(
  liability: Pick<FinancialLiability, 'excludeFromCalculation'>,
): boolean {
  return includeInNetworthCalculations.has(liability.excludeFromCalculation);
}

export function shouldIncludeLiability(
  considerFuturePayoff: boolean,
  liability: Partial<FinancialLiability>,
) {
  if (!considerFuturePayoff) {
    return true; //Current NetWorth
  }

  if (considerFuturePayoff && liability.payoffPaydown !== PayoffPaydownType.PAYOFF) {
    return true; //Future NetWorth
  }

  return liability.payoffPaydown === PayoffPaydownType.NONE; //Include all liabilities with PayoffPaydown type NONE
}
