export interface ApplicationDocument {
  id: string;
  applicationId: string;
  type: string;
  source: string;
  bucket: string;
  key: string;
  context: ApplicationDocumentContext;
  status: string;
  metadata: string;
}

export type ClamavScanStatus = 'CLEAN' | 'INPROGRESS' | 'INFECTED' | 'ERROR' | 'SKIP';

export enum DocumentActions {
  SAVE = 'SAVE',
  SEND = 'SEND',
  DOWNLOAD = 'DOWNLOAD',
}

export interface ApplicationDocumentContext {
  type: string;
  name: string;
}

export interface ApplicationDocumentActionHistory {
  id?: string;
  actionById: string;
  applicationId: string;
  applicationDocumentId: string;
  originalDocumentType: string;
  originalDocumentName: string;
  action: DocumentActions;
  actionData: unknown;
  ApplicationDocument?: ApplicationDocument;
}

export enum ApplicationDocumentActionHistoryKey {
  ACTION = 'action',
  ACTION_DATA = 'actionData',
  ORIGINAL_DOCUMENT_TYPE = 'originalDocumentType',
  ORIGINAL_DOCUMENT_NAME = 'originalDocumentName',
  APPLICATION_DOCUMENT_ID = 'applicationDocumentId',
  SAVE_AS_NAME = 'saveAsName',
  SAVE_OPTIONS_LAWYERS = 'saveOptionsLawyers',
  SAVE_OPTIONS_PROPERTIES = 'saveOptionsProperties',
  SAVE_OPTIONS_APPLICANTS = 'saveOptionsApplicants',
  SAVE_OPTIONS_CUSTOMER_TYPE = 'saveOptionsCustomerType',
  SAVE_OPTIONS_LABEL = 'saveOptionsLabel',
  SAVE_OPTIONS_IS_COMMITMENT_LETTER = 'saveOptionsIsCommitmentLetter',
  SENT_TO_APPLICANT_ID = 'sentToApplicantId',
  SENT_TO_APPLICANT_NAME = 'sentToApplicantName',
  SENT_OPTIONAL_MESSAGE = 'sentOptionalMessage',
  DOWNLOADED_FILE_NAME = 'downloadedFileName',
}
