/* eslint-disable @typescript-eslint/no-explicit-any */

import { ApplicationStage } from '@fundmoreai/models';

/* eslint-disable max-len */
export {
  PaymentFrequency,
  CompoundPeriod,
  RepaymentType,
  PrepaymentType,
  MortgageType,
  PropertyType,
  ApplicationStatusType,
  PurchaseMortgageClassification,
  RefinanceMortgageClassification,
  ETOMortgageClassification,
  DeficiencySaleMortgageClassification,
  WorkoutMortgageClassification,
  SwitchTransferClassification,
  RenewalClassification,
  ConstructionClassification,
  BridgeFinancingClassification,
  OtherClassification,
  DownPaymentType,
  ApplicationPurposeType,
} from '@fundmoreai/models';

export const stageString: Record<ApplicationStage, string> = {
  [ApplicationStage.NEW_APPLICATION]: $localize`New Application`,
  [ApplicationStage.UNDERWRITING]: $localize`Underwriting`,
  [ApplicationStage.ADJUDICATION]: $localize`Adjudication`,
  [ApplicationStage.DEAL_ACCEPTANCE]: $localize`Deal Acceptance`,
  [ApplicationStage.DOCUMENT_REVIEW]: $localize`Document Review`,
  [ApplicationStage.OPERATIONS_FULFILLMENT]: $localize`Operations Fulfillment`,
  [ApplicationStage.PRESENT_DEAL]: $localize`Present Deal`,
  [ApplicationStage.PROPOSE_DEAL]: $localize`Propose Deal`,
  [ApplicationStage.DEAL_SIGNED]: $localize`Deal Signed`,
  [ApplicationStage.COMPLIANCE]: $localize`Compliance`,
  [ApplicationStage.FINAL_APPROVAL]: $localize`Final Approval`,
  [ApplicationStage.PRE_FUND]: $localize`Pre-Fund`,
  [ApplicationStage.LAWYER_INSTRUCTED]: $localize`Lawyer Instructed`,
  [ApplicationStage.FINAL_REVIEW]: $localize`Final Review`,
  [ApplicationStage.FUNDED]: $localize`Funded`,
  [ApplicationStage.DECLINED]: $localize`Declined`,
  [ApplicationStage.FORECLOSURE]: $localize`Foreclosure`,
  [ApplicationStage.PAID_OUT]: $localize`Paid Out`,
  [ApplicationStage.PAST_DUE_UNDER_90]: $localize`Past due < 90 days`,
  [ApplicationStage.PAST_DUE_OVER_90]: $localize`Past Due > 90 days`,
  [ApplicationStage.PAID_IN_FULL]: $localize`Paid in Full`,
  [ApplicationStage.RENEWAL]: $localize`Renewal`,
};

export enum DocumentVerificationField {
  CREDIT_REPORT = 'creditReport',
}

export enum EzidoxViewType {
  DETAILS = 'details',
}

export enum ClosedType {
  YES = 'Yes',
  NO = 'No',
}

export enum FundmoreScoreType {
  Default = 'Application',
  RateMatrix = 'Rate Matrix',
}

export enum FundmoreScoreSubType {
  Default = 'Default',
}

export enum UploadDocumentType {
  CREDIT_REPORT_PRIMARY = 'creditReportPrimary',
}

export enum FlipCostType {
  PURCHASE_COST = 'purchaseCost',
  SELLING_COST = 'sellingCost',
  OPERATING_COST = 'operatingCost',
}

export enum FlipPaymentType {
  ONE_TIME = 'oneTime',
  MONTHLY = 'monthly',
}

export enum LockReasonType {
  LOCK = 'Lock',
  UNLOCK = 'Unlock',
}

export enum ApplicationEntity {
  APPLICATION = 'Application',
  APPLICATION_TASK = 'Task',
  EXISTING_MORTGAGE = 'Existing Mortgage',
  REQUESTED_MORTGAGE = 'Requested Mortgage',
  FINANCIAL_ASSET = 'Financial Asset',
  DOWN_PAYMENT = 'Down Payment',
  FEE = 'Fee',
  PROPERTY = 'Property',
  PROPERTY_OWNER = 'Property Owner',
  PRIMARY = 'Primary',
  EXTERNAL = 'External',
  OTHER = 'Other',
  APPLICANT = 'Stakeholder',
  APPLICATION_CONDITION = 'Condition',
  APPLICATION_CONDITION_DOCUMENT_FILE = 'Document',
  APPLICATION_NOTE = 'Note',
  APPLICATION_APPROVAL = 'Approval',
  APPLICATION_COMPUTED_DATA = 'Ratios',
  APPLICATION_STATUS = 'Status',
  APPLICATION_ASSIGNED_USER = 'Team',
  JOB = 'Job',
  OTHER_INCOME = 'Other Income',
  APPLICATION_INSURANCE_QUOTE = 'Insurance Quote',
  ACTIVE_TRADELINES = 'Active Tradelines',
  COLLECTIONS_JUDGEMENTS = 'Collections/Judgements',
}

export enum ApplicationLogAction {
  ACTIVE_TRADELINES_CREATE = 'ACTIVE_TRADELINES_CREATE',
  ACTIVE_TRADELINES_UPDATE = 'ACTIVE_TRADELINES_UPDATE',
  ACTIVE_TRADELINES_DELETE = 'ACTIVE_TRADELINES_DELETE',
  ACTIVE_TRADELINES_ASSIGN_APPLICANT = 'ACTIVE_TRADELINES_ASSIGN_APPLICANT',
  ACTIVE_TRADELINES_UNASSIGN_APPLICANT = 'ACTIVE_TRADELINES_UNASSIGN_APPLICANT',
  ADDRESS_EXPANDED_CREATE = 'ADDRESS_EXPANDED_CREATE',
  ADDRESS_EXPANDED_UPDATE = 'ADDRESS_EXPANDED_UPDATE',
  ADDRESS_EXPANDED_DELETE = 'ADDRESS_EXPANDED_DELETE',
  APPLICATION_CREATE = 'APPLICATION_CREATE',
  APPLICATION_UPDATE = 'APPLICATION_UPDATE',
  APPLICATION_DELETE = 'APPLICATION_DELETE',
  APPLICATION_UPDATE_DOCUMENT_DUE_DATE = 'APPLICATION_UPDATE_DOCUMENT_DUE_DATE',
  APPLICATION_UPDATE_LOCK_STATUS = 'APPLICATION_UPDATE_LOCK_STATUS',
  APPLICANT_CREATE = 'APPLICANT_CREATE',
  APPLICANT_UPDATE = 'APPLICANT_UPDATE',
  APPLICANT_DELETE = 'APPLICANT_DELETE',
  COLLECTIONS_OR_JUDGEMENTS_CREATE = 'COLLECTIONS_OR_JUDGEMENTS_CREATE',
  COLLECTIONS_OR_JUDGEMENTS_UPDATE = 'COLLECTIONS_OR_JUDGEMENTS_UPDATE',
  COLLECTIONS_OR_JUDGEMENTS_DELETE = 'COLLECTIONS_OR_JUDGEMENTS_DELETE',
  COLLECTIONS_OR_JUDGEMENTS_ASSIGN_APPLICANT = 'COLLECTIONS_OR_JUDGEMENTS_ASSIGN_APPLICANT',
  COLLECTIONS_OR_JUDGEMENTS_UNASSIGN_APPLICANT = 'COLLECTIONS_OR_JUDGEMENTS_UNASSIGN_APPLICANT',
  DOWN_PAYMENT_CREATE = 'DOWN_PAYMENT_CREATE',
  DOWN_PAYMENT_UPDATE = 'DOWN_PAYMENT_UPDATE',
  DOWN_PAYMENT_DELETE = 'DOWN_PAYMENT_DELETE',
  FINANCIAL_ASSET_CREATE = 'FINANCIAL_ASSET_CREATE',
  FINANCIAL_ASSET_UPDATE = 'FINANCIAL_ASSET_UPDATE',
  FINANCIAL_ASSET_DELETE = 'FINANCIAL_ASSET_DELETE',
  JOB_CREATE = 'JOB_CREATE',
  JOB_UPDATE = 'JOB_UPDATE',
  JOB_DELETE = 'JOB_DELETE',
  MORTGAGE_CREATE = 'MORTGAGE_CREATE',
  MORTGAGE_UPDATE = 'MORTGAGE_UPDATE',
  MORTGAGE_DELETE = 'MORTGAGE_DELETE',
  OTHER_INCOME_CREATE = 'OTHER_INCOME_CREATE',
  OTHER_INCOME_UPDATE = 'OTHER_INCOME_UPDATE',
  OTHER_INCOME_DELETE = 'OTHER_INCOME_DELETE',
  PROPERTY_CREATE = 'PROPERTY_CREATE',
  PROPERTY_UPDATE = 'PROPERTY_UPDATE',
  PROPERTY_DELETE = 'PROPERTY_DELETE',
  PROPERTY_OWNER_CREATE = 'PROPERTY_OWNER_CREATE',
  PROPERTY_OWNER_UPDATE = 'PROPERTY_OWNER_UPDATE',
  PROPERTY_OWNER_DELETE = 'PROPERTY_OWNER_DELETE',
  CREATED_DEFAULT = 'CREATED_DEFAULT',
  UPDATED_DEFAULT = 'UPDATED_DEFAULT',
  DELETED_DEFAULT = 'DELETED_DEFAULT',
  APPLICATION_ARCHIVED = 'APPLICATION_ARCHIVED',
  APPLICATION_CHANGE_STAGE = 'APPLICATION_CHANGE_STAGE',
  APPLICATION_DECLINE = 'APPLICATION_DECLINE',
  APPLICATION_UPDATE_ASSIGNED_USERS = 'APPLICATION_UPDATE_ASSIGNED_USERS',
  TASK_CREATE = 'TASK_CREATE',
  TASK_UPDATE = 'TASK_UPDATE',
  TASK_DELETE = 'TASK_DELETE',
  TASK_BYPASS = 'TASK_BYPASS',
  TASK_UPDATE_COMPLETION = 'TASK_UPDATE_COMPLETION',
  FINANCIAL_ASSET_USE_AS_DOWN_PAYMENT = 'FINANCIAL_ASSET_USE_AS_DOWN_PAYMENT',
  FINANCIAL_ASSET_DELETE_USE_AS_DOWN_PAYMENT = 'FINANCIAL_ASSET_DELETE_USE_AS_DOWN_PAYMENT',
  CREDIT_REPORT_REQUEST_EQUIFAX = 'CREDIT_REPORT_REQUEST_EQUIFAX',
  CREDIT_REPORT_APPLY_CHANGES = 'CREDIT_REPORT_APPLY_CHANGES',
  CREDIT_REPORT_UPDATE_SCORE = 'CREDIT_REPORT_UPDATE_SCORE',
  FEE_CREATE = 'FEE_CREATE',
  FEE_UPDATE = 'FEE_UPDATE',
  FEE_DELETE = 'FEE_DELETE',
  CONDITION_CREATE = 'CONDITION_CREATE',
  CONDITION_UPDATE = 'CONDITION_UPDATE',
  CONDITION_DELETE = 'CONDITION_DELETE',
  CONDITION_STATUS_CHANGE = 'CONDITION_STATUS_CHANGE',
  DOCUMENT_CREATE = 'DOCUMENT_CREATE',
  DOCUMENT_UPDATE = 'DOCUMENT_UPDATE',
  DOCUMENT_DELETE = 'DOCUMENT_DELETE',
  DOCUMENT_STATUS_CHANGE = 'DOCUMENT_STATUS_CHANGE',
  DOCUMENT_COMMENT_CREATE = 'DOCUMENT_COMMENT_CREATE',
  NOTE_CREATE = 'NOTE_CREATE',
  NOTE_UPDATE = 'NOTE_UPDATE',
  NOTE_DELETE = 'NOTE_DELETE',
  APPROVAL_REQUESTED = 'APPROVAL_REQUESTED',
  APPROVAL_RE_REQUESTED = 'APPROVAL_RE_REQUESTED',
  APPROVAL_REVIEWED = 'APPROVAL_REVIEWED',
  APPROVAL_CANCEL = 'APPROVAL_CANCEL',
  APPLICATION_CONDITIONALLY_APPROVE = 'APPLICATION_CONDITIONALLY_APPROVE',
  APPLICATION_SEND_TO_INTELLIFI = 'APPLICATION_SEND_TO_INTELLIFI',
  APPLICATION_MARK_PENDING = 'APPLICATION_MARK_PENDING',
  APPLICATION_ASSIGNED_USER = 'APPLICATION_ASSIGNED_USER',
  PROPERTY_MARKET_VALUATION_REQUESTED = 'PROPERTY_MARKET_VALUATION_REQUESTED',
  PROPERTY_RESIDENTIAL_PREFILL_REQUESTED = 'PROPERTY_RESIDENTIAL_PREFILL_REQUESTED',
  APPLICATION_INSURANCE_QUOTE_CMHC_CREATED = 'APPLICATION_INSURANCE_QUOTE_CMHC_CREATED',
  APPLICATION_INSURANCE_QUOTE_CMHC_RESUBMISSION = 'APPLICATION_INSURANCE_QUOTE_CMHC_RESUBMISSION',
  APPLICATION_INSURANCE_QUOTE_CMHC_REMOVED = 'APPLICATION_INSURANCE_QUOTE_CMHC_REMOVED',
  APPLICATION_INSURANCE_QUOTE_CMHC_STATUS_UPDATE = 'APPLICATION_INSURANCE_QUOTE_CMHC_STATUS_UPDATE',
  APPLICATION_INSURANCE_QUOTE_SAGEN_CREATED = 'APPLICATION_INSURANCE_QUOTE_SAGEN_CREATED',
  APPLICATION_INSURANCE_QUOTE_SAGEN_RESUBMISSION = 'APPLICATION_INSURANCE_QUOTE_SAGEN_RESUBMISSION',
  APPLICATION_INSURANCE_QUOTE_SAGEN_REMOVED = 'APPLICATION_INSURANCE_QUOTE_SAGEN_REMOVED',
  APPLICATION_INSURANCE_QUOTE_SAGEN_STATUS_UPDATE = 'APPLICATION_INSURANCE_QUOTE_SAGEN_STATUS_UPDATE',
  APPLICATION_INSURANCE_QUOTE_CG_CREATED = 'APPLICATION_INSURANCE_QUOTE_CG_CREATED',
  APPLICATION_INSURANCE_QUOTE_CG_RESUBMISSION = 'APPLICATION_INSURANCE_QUOTE_CG_RESUBMISSION',
  APPLICATION_INSURANCE_QUOTE_CG_REMOVED = 'APPLICATION_INSURANCE_QUOTE_CG_REMOVED',
  APPLICATION_INSURANCE_QUOTE_CG_STATUS_UPDATE = 'APPLICATION_INSURANCE_QUOTE_CG_STATUS_UPDATE',
  COMPUTED_DATA_UPDATE = 'COMPUTED_DATA_UPDATE',
  NET_WORTH_UPDATE = 'NET_WORTH_UPDATE',
  MORTGAGE_COMPUTED_DATA_UPDATE = 'MORTGAGE_COMPUTED_DATA_UPDATE',
  APPRAISAL_CREATE = 'APPRAISAL_CREATE',
  APPRAISAL_UPDATE = 'APPRAISAL_UPDATE',
  APPRAISAL_DELETE = 'APPRAISAL_DELETE',
  PROPERTY_INSURANCE_CREATE = 'PROPERTY_INSURANCE_CREATE',
  PROPERTY_INSURANCE_UPDATE = 'PROPERTY_INSURANCE_UPDATE',
  PROPERTY_INSURANCE_DELETE = 'PROPERTY_INSURANCE_DELETE',
  APPRAISAL_SET_AS_PROPERTY_VALUE = 'APPRAISAL_SET_AS_PROPERTY_VALUE',
  DOCUMENT_LOG_ACTION = 'DOCUMENT_LOG_ACTION',
  APPLICATION_MARK_COMMITTED = 'APPLICATION_MARK_COMMITTED',
  APPLICATION_MARK_CREDIT_CARD_FULFILLED = 'APPLICATION_MARK_CREDIT_CARD_FULFILLED',
  APPLICATION_MARK_AWAITING_DOCS = 'APPLICATION_MARK_AWAITING_DOCS',
  APPLICATION_BROKER_COMMISSION_UPSERT = 'APPLICATION_BROKER_COMMISSION_UPSERT',
}

/**
 * [column name in database]: <string shown on UI>
 */
export enum ApplicationHumanizedKey {
  archived = 'Archived',
  code = 'code',
  currentStage = 'Current Stage',
  brokerNotes = 'Broker Notes',
  docsDueDate = 'Docs Due Date',
  declineComment = 'Decline Comment',
  declineReasonId = 'Decline/Cancel reason',
  locked = 'Locked',
  mortgageClassification = 'Mortgage Classification',
  name = 'Application name',
  productId = 'Product',
  purpose = 'Application Purpose',
  dealType = 'Deal Type',
  isCombo = 'Application Is Combo',
  lendingTier = 'Lending Tier',
  closingService = 'Closing Service',
  sourceOfBusiness = 'Source Of Business',
  priority = 'Priority',
  purposeCode = 'Purpose Code',
  totalBusinessConnection = 'Total Business Connection',
  applicationLoanNumber = 'Loan Number',
}

export enum MortgageHumanizedKey {
  loanType = 'Loan Type',
  loanAmount = 'Loan Amount',
  repaymentType = 'Repayment Type',
  termMonths = 'Term (months)',
  termType = 'Term Type',
  amortizationMonths = 'Amortization (Months)',
  netRate = 'Net Rate',
  rateType = 'Rate Type',
  closingDate = 'Closing Date',
  cofDate = 'Condition of Financing Date',
  paymentFrequency = 'Payment Frequency',
  insurer = 'Insurers',
  insuranceAmount = 'Insurance Amount($)',
  insurancePremium = 'Insurance Premium(%)',
  insurancePremiumProgram = 'Insurance Premium Program',
  includePremiumInMortgage = 'Insurance Premium Applicable',
  interestAdjustmentDate = 'Interest Adjustment Date',
  interestAdjustmentAmount = 'Interest Adjustment Amount',
  firstRegularPaymentDate = 'First Regular Payment Date',
  totalLoanAmount = 'Total Loan Amount',
  pst = 'PST',
  amountToBeAdvanced = 'Amount To Be Advanced',
  insurancePremiumPlan = 'Insurance Premium Plan',
  baseRate = 'Base Rate',
  buyDownRate = 'Buydown',
  discount = 'Discount',
  payoff = 'Payoff',
  payoffPaydown = 'Payoff Paydown',
  maturityDate = 'Maturity Date',
  purpose = 'Purpose',
  mortgageBalance = 'Mortgage Balance',
  payoutBalance = 'Pay Out Balance',
  refiBlendedAmortization = 'Is Blended Amortization',
  monthlyPayment = 'Monthly Payment',
  lender = 'Mortgage Holder',
  mortgageType = 'Mortgage Position',
  prepaymentType = 'Holdback/Prepayment',
  isPrepaymentAmountInPayments = 'Prepayment in number Of Payments',
  prepaymentAmount = 'Prepayment Amount',
  prepaymentAmountInPayments = 'Prepayment Amount In Payments',
  prepaymentPenaltyPeriod = 'Prepayment Penalty Period',
  compounding = 'Compounding',
  loanNumber = 'Loan Number',
  deadlineToAcceptDate = 'Deadline To Accept',
  paymentAmount = 'Payment Amount',
  totalRegularPayment = 'Total Regular Payment',
  fundingDate = 'Funding Date',
  partnerLoanName = 'Partner Loan Name',
  partnerLoanPortion = 'Partner Loan Portion',
  partnerLoanEQPortion = 'Partner Loan EQ Portion',
  partnerLoanYield = 'Partner Loan Yield',
  propertyId = 'Property',
  constructionCash = 'Construction Cash',
  constructionCost = 'Construction Cost',
  collateralAmount = 'Collateral Amount ($)',
  registrationNumber = 'Registration Number',
  typeOfClosingInstruction = 'Type of Closing',
  securityType = 'Security Type',
  security = 'Security',
  mortgageProductId = 'Product ID',
  rateHoldDate = 'Rate Hold Date',
  status = 'Status',
  state = 'State',
  stateChangeComment = 'State Change Comment',
  stateChangeDate = 'State Change Date',
  productMemberNumber = 'Product Member Number',
  incrementCategoryApplied = 'Selected Increment',
  incrementTotal = 'Increments (%)',
  customIncrementAmount = 'Custom Increment (%)',
  customIncrementNote = 'Notes',
  decrementTotal = 'Decrements (%):',
  discretion = 'Discretion',
}

export enum ApplicationTaskHumanizedKey {
  description = 'Description',
  priority = 'Priority',
  dueDate = 'Due Date',
  assignedTo = 'Assigned To',
  stage = 'Stage',
  isCompleted = 'Completed',
  completedAt = 'Completed At',
  longDescription = 'Long Description',
}

export enum FinancialAssetHumanizedKey {
  description = 'Description',
  asset = 'Type',
  value = 'Value',
  purchasePrice = 'Purchase Price',
  useAsDownPayment = 'Use as Down Payment',
  excludeFromCalculation = 'Exclude from Calculation',
}

export enum FinancialLiabilityHumanizedKey {
  excludeFromCalculation = 'Exclude from Calculation',
  payoff = 'Pay From',
  payoffPaydown = 'Payoff Paydown',
  securityType = 'Security Type',
}

export enum DownPaymentHumanizedKey {
  source = 'Down Payment Source',
  description = 'Description',
  amount = 'Amount',
}

export enum FeeHumanizedKey {
  type = 'Type',
  percent = 'Percent',
  amount = 'Amount',
  paidBy = 'Paid By',
  subtractFromPrincipal = 'Subtract from Principal',
  includeInApr = 'APR',
  capFeesPercentage = 'Cap Fees Percentage',
}

export enum CreditReportHumanizedKey {
  bniScore = 'BNI Score',
  errorDescription = 'Error',
  hitStrengthIndicatorDescription = 'Hit Strength',
}

export enum ApplicantHumanizedKey {
  name = 'Name',
  workPhone = 'Work Phone',
  cellPhone = 'Cell Phone',
  homePhone = 'Home Phone',
  faxNumber = 'Fax Number',
  email = 'Email',
  maritalStatus = 'Marital Status',
  dateOfBirth = 'Date Of Birth',
  placeOfBirth = 'Place Of Birth',
  countryOfResidence = 'Country Of Residence',
  registeredCountry = 'Registered Country',
  nationality = 'Nationality',
  dependents = 'Dependents',
  sin = 'SIN',
  surname = 'Surname',
  templateSetId = 'Document Template',
  isUploader = 'UI Notifications',
  crScore = 'Credit Score',
  crScoreCode = 'Reject Code',
  bniScore = 'BNI Score',
  crDescription = 'Credit Summary',
  customerType = 'Customer Type',
  type = 'Type',
  brokerage = 'Brokerage',
  company = 'Company',
  companyId = 'Company Id',
  isFirstTimeHomeBuyer = 'First Time Home Buyer',
  citizenshipStatus = 'Citizenship Status',
  ficoScoreOverride = 'FICO Score Override',
  salutation = 'Salutation',
  relationshipToPrimaryApplicant = 'Relationship to Primary Applicant',
  gender = 'Gender',
  clientId = 'Client Id',
  eqFraudAMLRisk = 'Eq Fraud AML Risk',
  relatedCIFs = 'Related CIFs',
  incomeVerification = 'Income Verification',
}

export enum FlipHumanizedType {
  SALES_PRICE_FOCUSED = 'Sales Price Focused',
  PROFIT_FOCUSED = 'Profit focused',
}

export enum ClosingInstructionHumanizedKey {
  registrationNumber = 'Registration Number',
  collateralAmount = 'Collateral Amount',
  typeOfClosingInstruction = 'Type of Closing',
}

export enum SideBarTabNames {
  CHAT = 'chat',
  TEAM = 'team',
}

export enum FeesAction {
  DEDUCT = 'deduct',
  CAP = 'cap',
  DO_NOT_SUBTRACT = 'do_not_subtract',
}

export enum GenworthSpecialProgramCode {
  ALAU = 'ALAU',
  BDWN = 'BDWN',
  CHBK = 'CHBK',
  FAMP = 'FAMP',
  FE01 = 'FE01',
  VACP = 'VACP',
}

export const genworthSpecialProgramCodeRecords: Record<GenworthSpecialProgramCode, string> = {
  [GenworthSpecialProgramCode.ALAU]: 'ALTA Upfront',
  [GenworthSpecialProgramCode.BDWN]: 'Borrowed Down',
  [GenworthSpecialProgramCode.CHBK]: 'Cash Back',
  [GenworthSpecialProgramCode.FAMP]: 'Family Plan',
  [GenworthSpecialProgramCode.FE01]: 'New to Canada',
  [GenworthSpecialProgramCode.VACP]: 'Vacation',
};

export enum InsuranceIndicator {
  MortgageInsuranceRequest = 'I',
  LRURequest_BulkInsurancePreApproval = 'B',
}

export const insuranceIndicatorRecords: Record<InsuranceIndicator, string> = {
  [InsuranceIndicator.MortgageInsuranceRequest]: 'Mortgage Insurance request',
  [InsuranceIndicator.LRURequest_BulkInsurancePreApproval]:
    'LRU+ request / Bulk Insurance Pre-Approval',
};

export enum AdvanceType {
  MultipleAdvance = '01',
  SingleAdvance = '02',
}

export const advanceTypeRecords: Record<AdvanceType, string> = {
  [AdvanceType.MultipleAdvance]: 'Multiple Advance',
  [AdvanceType.SingleAdvance]: 'Single Advance',
};

export enum ProgressAdvanceType {
  Builder = 'B',
  Contractor = 'C',
  SelfBuild = 'S',
  PreFabricatedHomes = 'P',
}

export const progressAdvanceTypeRecords: Record<ProgressAdvanceType, string> = {
  [ProgressAdvanceType.Builder]: 'Builder',
  [ProgressAdvanceType.Contractor]: 'Contractor',
  [ProgressAdvanceType.SelfBuild]: 'Self Build',
  [ProgressAdvanceType.PreFabricatedHomes]: 'Pre-Fabricated homes',
};

export enum ProductFeatures {
  isEdge = 'isEdge',
  isCombo = 'isCombo',
  isInsured = 'isInsured',
  isLOC = 'isLOC',
}

export enum EzidoxStatusLocalizationOptions {
  EQ = 'EQ',
  DEFAULT = 'DEFAULT',
}
