import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import { LockHistory } from '../shared';
import { MortgageApplicationService } from './mortgage-application.service';
import { ApplicationResetState } from '../shared/state.model';

export class FetchLockHistory {
  static readonly type = '@applicationLockHistory.FetchLockHistory';
  constructor(public applicationId: string) {}
}

export class AddToLockHistory {
  static readonly type = '@applicationLockHistory.AddToLockHistory';
  constructor(public locked: LockHistory) {}
}

@State<LockHistory[]>({
  name: 'applicationLockHistory',
  defaults: [],
})
@Injectable()
export class ApplicationLockHistoryState {
  constructor(private mortgageApplicationService: MortgageApplicationService) {}

  @Action(ApplicationResetState)
  reset(ctx: StateContext<LockHistory[]>) {
    ctx.setState([]);
  }

  @Selector() static lockHistory(state: LockHistory[]) {
    return state;
  }

  @Action(FetchLockHistory) getLockHistory(
    ctx: StateContext<LockHistory[]>,
    action: FetchLockHistory,
  ) {
    ctx.setState([]);

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageApplicationService.getApplicationLockHistory(action.applicationId).pipe(
      tap((lockHistory: LockHistory[]) => {
        ctx.setState(lockHistory);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(AddToLockHistory) addToLockHistory(
    ctx: StateContext<LockHistory[]>,
    action: AddToLockHistory,
  ) {
    const state = ctx.getState();
    ctx.setState([...state, action.locked]);
  }
}
