export interface Note {
  id: string;
  name: string;
  createdAt: Date;
  content: string;
  applicationIds: string[] | null;
  mappedValues: MappedValue[] | null;
}

export interface MappedValue {
  id: string;
  field: MappedValueField;
  value: string;
  details?: string;
}

export interface MappedValueField {
  value: string;
  category: string;
  path: string;
}

export const MVIDX = '$i';

export enum MappedValuePaths {
  PRIMARY_APPLICANT_NAME = 'primaryApplicant.name',
  PRIMARY_APPLICANT_SURNAME = 'primaryApplicant.surname',
  PRIMARY_APPLICANT_CELLPHONE = 'primaryApplicant.cellPhone',
  PRIMARY_APPLICANT_EMAIL = 'primaryApplicant.email',
  PRIMARY_APPLICANT_MARITAL_STATUS = 'primaryApplicant.maritalStatus',
  PRIMARY_APPLICANT_DEPENDANTS = 'primaryApplicant.dependants',
  PRIMARY_APPLICANT_REFERENCE = 'primaryApplicant.reference',
  PRIMARY_APPLICANT_ADDRESS_BASE = 'primaryApplicant.ApplicantAddressesExpanded',
  PRIMARY_APPLICANT_ADDRESS = 'primaryApplicant.ApplicantAddressesExpanded[0].address',
  PRIMARY_APPLICANT_TIME_AT_RESIDENCE_MONTHS = 'primaryApplicant.ApplicantAddressesExpanded[0].timeAtResidenceMonths',
  PRIMARY_APPLICANT_ADDRESS_TYPE = 'primaryApplicant.ApplicantAddressesExpanded[0].type',
  OTHER_APPLICANT_NAME = 'otherApplicants[$i].name',
  OTHER_APPLICANT_SURNAME = 'otherApplicants[$i].surname',
  OTHER_APPLICANT_EMAIL = 'otherApplicants[$i].email',
  OTHER_APPLICANT_CELLPHONE = 'otherApplicants[$i].cellPhone',
  OTHER_APPLICANT_CUSTOMER_TYPE = 'otherApplicants[$i].customerType',
  OTHER_APPLICANT = 'otherApplicants',
  PROPERTY_ADDRESS = 'properties[0].propertyAddress',
  PROPERTY_TYPE = 'properties[0].propertyType',
  PROPERTY_TYPE_BASE = 'properties[0].type',
  PROPERTY_BASE = 'properties[0]',
  PROPERTY_ZONING_TYPE = 'properties[0].zoningType',
  PROPERTY_PURCHASE_PRICE = 'properties[0].purchasePrice',
  MORTGAGE_APPLICATION_TYPE = 'type',
  MORTGAGE_PURPOSE = 'requestedMortgage.purpose',
  MORTGAGE_CLOSING_DATE = 'requestedMortgage.closingDate',
  MORTGAGE_NET_RATE = 'requestedMortgage.netRate',
  MORTGAGE_LOAN_TYPE = 'requestedMortgage.loanType',
  MORTGAGE_TOTAL_LOAN_AMOUNT = 'requestedMortgage.loanAmount',
  MORTGAGE_TERM_TYPE = 'requestedMortgage.termType',
  MORTGAGE_TERM = 'requestedMortgage.termMonths',
  MORTGAGE_RATE_TYPE = 'requestedMortgage.rateType',
  MORTGAGE_AMORTIZATION = 'requestedMortgage.amortizationMonths',
  MORTGAGE_REPAYMENT_TYPE = 'requestedMortgage.repaymentType',
  MORTGAGE_CLASSIFICATION = 'mortgageClassification',
  MORTGAGE_TYPE = 'requestedMortgage.mortgageType',
  MORTGAGE_PRODUCT = 'productId',
  MORTGAGE_DOWN_PAYMENTS = 'downPayments[$i]',
}
