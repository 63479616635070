import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalvertPackageService } from './services/calvert.package.service';
import { DefaultPackageService } from './services/default.package.service';
import { DocumentPackageService } from './services/document.package.service';
import { GentaiPackageService } from './services/gentai.package.service';
import { AmortizationScheduleService } from './services/amortization-schedule.service';
import { NgxsModule } from '@ngxs/store';
import { ChargesState } from './state/charges.state';
import { ChargesService } from './services/charges.service';
import { ConditionsOfApprovalService } from './services/conditions-of-approval.service';
import { DocumentTemplatesState } from './state/document-templates.state';
import { DocumentTemplatesService } from './services/document-templates.service';
import { EQPackageService } from './services/eq.package.service';
import { IncomeState } from '../../application/widgets/income/income.state';
import { FundsState } from 'src/app/portal/funds.state';
import { MortgagesV2State } from 'src/app/portal/mortgages-v2/mortgages-v2.state';
import { LawyersState } from 'src/app/portal/lawyers.state';
import { RoleState } from '../user/role.state';
import { InsuranceState } from 'src/app/portal/insurances.state';
import { PropertyAppraisalState } from 'src/app/portal/property-appraisals/property-appraisals.state';
import { ApplicationNotesState } from '../../application/notes/notes.state';
import { NotesModule } from '../../application/notes/notes.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    NgxsModule.forFeature([
      ChargesState,
      DocumentTemplatesState,
      IncomeState,
      FundsState,
      MortgagesV2State,
      LawyersState,
      RoleState,
      InsuranceState,
      PropertyAppraisalState,
      ApplicationNotesState,
    ]),
    NotesModule,
  ],
  providers: [
    AmortizationScheduleService,
    CalvertPackageService,
    ChargesService,
    ConditionsOfApprovalService,
    DefaultPackageService,
    DocumentPackageService,
    DocumentTemplatesService,
    GentaiPackageService,
    EQPackageService,
  ],
})
export class DocumentsModule {}
