export class ApproveRequest {
  static readonly type = '@applicationApproval.ApproveRequest';

  constructor(public applicationApprovalId: string, public comment: string) {}
}

export class CancelRequest {
  static readonly type = '@applicationApproval.CancelRequest';

  constructor(public applicationApprovalId: string, public comment: string) {}
}

export class DeclineRequest {
  static readonly type = '@applicationApproval.DeclineRequest';

  constructor(public applicationApprovalId: string, public comment: string) {}
}

export class FetchApplicationApprovals {
  static readonly type = '@applicationApproval.FetchApplicationApprovals';

  constructor(public applicationId: string) {}
}

export class RequestApproval {
  static readonly type = '@applicationApproval.RequestApproval';

  constructor(
    public applicationApproval: {
      approvalTypeIds: string[];
      approverUserIds: string[];
      comment: string;
      originalApprovalRequestId: string | null;
    },
    public applicationId: string,
  ) {}
}

export class RequestApprovalAgain {
  static readonly type = '@applicationApproval.RequestApprovalAgain';

  constructor(public applicationApprovalId: string, public comment: string) {}
}

export class RequestChanges {
  static readonly type = '@applicationApproval.RequestChanges';

  constructor(public applicationApprovalId: string, public comment: string) {}
}
