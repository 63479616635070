export interface NotificationSettings {
  applicationAssigned: boolean;
  applicationAssignedStageChanged: boolean;
  applicationDuplicate: boolean;
  taskAssigned: boolean;
  taskAssignedCompleted: boolean;
  chatMentioned: boolean;
  delegateAssigned: boolean;
  oooStatusChanged: boolean;
  conditionDocumentUploaded: boolean;
  conditionDocumentReviewed: boolean;
  approvalRequestUpdates: boolean;
  taskUpdated: boolean;
  applicationApprovalStatus: boolean;
  applicationConditionDocumentCommentAdded: boolean;
}

export interface RateChangeNotification {
  oldRate: number;
  newRate: number;
  oldModifier?: number;
  newModifier?: number;
  trend: RateChangeNotificationTrend;
  modifierTrend?: RateChangeNotificationTrend;
  rateType: RateChangeNotificationRateType;
  terms?: string;
  productName?: string;
}

export enum RateChangeNotificationRateType {
  FIXED = 'fixed',
  VARIABLE = 'variable',
}

export enum RateChangeNotificationTrend {
  UP = 'up',
  DOWN = 'down',
  SAME = 'same',
  CREATED = 'created',
  INCONCLUSIVE = 'inconclusive',
}
