import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FlipRenovationSchedule } from '@fundmoreai/models';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class FlipRenovationService {
  constructor(private http: HttpClient) {}

  createRenovationSchedule(renovationSchedulePayload: Partial<FlipRenovationSchedule>) {
    return this.http.post<FlipRenovationSchedule>(
      `${environment.api_url}/flipRenovationSchedules`,
      renovationSchedulePayload,
    );
  }

  updateRenovationSchedule(
    renovationScheduleId: string,
    renovationSchedulePayload: Partial<FlipRenovationSchedule>,
  ) {
    return this.http.patch<void>(
      `${environment.api_url}/flipRenovationSchedules/${renovationScheduleId}`,
      renovationSchedulePayload,
    );
  }

  deleteRenovationSchedule(renovationScheduleId: string) {
    return this.http.delete<void>(
      `${environment.api_url}/flipRenovationSchedules/${renovationScheduleId}`,
    );
  }
}
