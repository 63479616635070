export interface MMSNoteSendRequest {
  note: string;
  sentDate: string; // date-time
}

export interface MMSNotesFetchResponse {
  notes: {
    note: string;
    datePublished: string; // date-time
    UserName: string;
  }[];
}
