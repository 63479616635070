import { Injectable } from '@angular/core';
import {
  ApplicationApprovalAction,
  DefaultApprovalType,
  ApplicationApprovalStatus,
  DefaultApprovalTypeNameRecord,
  ApplicationAggregate,
  FutureAggregate,
} from '@fundmoreai/models';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { finalize, map, of, switchMap, tap } from 'rxjs';
import { AuthState } from 'src/app/auth/auth.state';
import { MortgagesV2State } from 'src/app/portal/mortgages-v2/mortgages-v2.state';
import { UserAccountsState } from 'src/app/portal/user-accounts.state';
import { User, UserAccount } from 'src/app/shared';
import { ApplicationApprovalActionRecord } from 'src/app/shared/enum-records';
import { ApprovalType } from '../../manager-portal/approval-types/approval-type.model';
import { ApprovalTypeState } from '../../manager-portal/approval-types/approval-type.state';
import { FetchNotes } from '../notes/notes.actions';

import {
  ApproveRequest,
  CancelRequest,
  DeclineRequest,
  FetchApplicationApprovals,
  RequestApproval,
  RequestApprovalAgain,
  RequestChanges,
} from './application-approval.actions';
import {
  ApplicationApproval,
  ApplicationRequiresDlaResult,
  UserRequiresDlaApprovalCheckOutput,
} from './application-approval.model';
import { ApplicationApprovalService } from './application-approval.service';
import { ApplicationResetState } from '../../../shared/state.model';
import { LoadingStart, LoadingEnd } from '../../../core/loading.state';
import { MortgageApplicationState } from 'src/app/portal/mortgage-application.state';
import { AppFeaturesState } from '../../../shared/app-features.state';
import { FeatureConfig } from '../../../../environments/environment.base';
// eslint-disable-next-line max-len
import { ApplicationAggregatesState } from '../widgets/loan-details/application-aggregates/application-aggregates.state';

interface ApplicationApprovalStateModel {
  approvals?: {
    [key: string]: ApplicationApproval;
  };
}

const defaults = {
  approvals: undefined,
  pendingApprovalsCount: 0,
};
@State<ApplicationApprovalStateModel>({
  name: 'applicationApproval',
  defaults: { ...defaults },
})
@Injectable()
export class ApplicationApprovalState {
  @Action(ApplicationResetState)
  reset(ctx: StateContext<ApplicationApprovalStateModel>) {
    ctx.setState({ ...defaults });
  }

  @Selector()
  private static approvals(
    state: ApplicationApprovalStateModel,
  ): ApplicationApproval[] | undefined {
    return state.approvals ? Object.values(state.approvals) : undefined;
  }

  @Selector([
    ApplicationApprovalState.approvals,
    UserAccountsState.userList,
    MortgagesV2State.totalLoanAmountForAllRequestedMortgages,
    ApplicationAggregatesState.futureAggregates,
    AppFeaturesState.isExpandedLendingLimitsEnabled,
  ])
  static approvalsList(
    approvals: ApplicationApproval[] | undefined,
    users: User[] | undefined,
    totalLoanAmountForAllRequestedMortgages: number,
    futureAggregates: FutureAggregate,
    isExpandedLendingLimitsEnabled: boolean,
  ) {
    return approvals?.map((approval) => {
      let isInvalid;
      if (approval.approvalType?.isDefault) {
        isInvalid = this.computeIsInvalid(
          approval,
          totalLoanAmountForAllRequestedMortgages,
          futureAggregates,
          isExpandedLendingLimitsEnabled,
        );
      }
      return this.mapUserToApproval({ ...approval, isInvalid }, users);
    });
  }

  static approval(applicationApprovalId: string) {
    return createSelector(
      [
        ApplicationApprovalState,
        UserAccountsState.userList,
        MortgagesV2State.totalLoanAmountForAllRequestedMortgages,
        ApplicationAggregatesState.futureAggregates,
        AppFeaturesState.isExpandedLendingLimitsEnabled,
      ],
      (
        state: ApplicationApprovalStateModel,
        users: User[] | undefined,
        totalLoanAmountForAllRequestedMortgages: number,
        futureAggregates: FutureAggregate | undefined,
        isExpandedLendingLimitsEnabled: boolean,
      ): ApplicationApproval | undefined => {
        const approval = state.approvals?.[applicationApprovalId];
        if (!approval) {
          return;
        }

        let isInvalid;
        if (approval.approvalType?.isDefault) {
          isInvalid = this.computeIsInvalid(
            approval,
            totalLoanAmountForAllRequestedMortgages,
            futureAggregates,
            isExpandedLendingLimitsEnabled,
          );
        }

        return this.mapUserToApproval({ ...approval, isInvalid }, users);
      },
    );
  }

  private static currentUserRequiresDlaApproval(
    users: User[] | undefined,
    currentUser: UserAccount,
    totalLoanAmount: number,
    configs?: { isExpandedLendingLimitsEnabled?: boolean },
    applicationAggregate?: ApplicationAggregate,
  ): UserRequiresDlaApprovalCheckOutput {
    const currentUserWithPreferences = users?.find((x) => x.id === currentUser?.user?.id);
    const userRequiresDlaApprovalCheck: UserRequiresDlaApprovalCheckOutput = {
      requiresDlaApproval: false,
      currentUserWithPreferences,
    };

    if (configs?.isExpandedLendingLimitsEnabled && applicationAggregate) {
      userRequiresDlaApprovalCheck.approvalDetails = {};

      userRequiresDlaApprovalCheck.approvalDetails.mortgageSecuredAmountUnderLimit =
        currentUserWithPreferences?.unlimitedMortgageSecured
          ? true
          : (currentUserWithPreferences?.maxMortgageSecured ?? 0) >=
            (applicationAggregate?.computedMortgageSecuredFuture ?? 0);

      userRequiresDlaApprovalCheck.approvalDetails.bridgeSecuredAmountUnderLimit =
        currentUserWithPreferences?.unlimitedBridgeSecured
          ? true
          : (currentUserWithPreferences?.maxBridgeSecured ?? 0) >=
            (applicationAggregate?.computedBridgeSecuredFuture ?? 0);

      userRequiresDlaApprovalCheck.approvalDetails.nonMortgageSecuredAmountUnderLimit =
        currentUserWithPreferences?.unlimitedNonMortgageSecured
          ? true
          : (currentUserWithPreferences?.maxNonMortgageSecured ?? 0) >=
            (applicationAggregate?.computedNonMortgageLiabilitiesFuture ?? 0);

      userRequiresDlaApprovalCheck.approvalDetails.totalConnectionAmountUnderLimit =
        currentUserWithPreferences?.unlimitedTotalConnection
          ? true
          : (currentUserWithPreferences?.maxTotalConnection ?? 0) >=
            (applicationAggregate?.computedTotalConnectionFuture ?? 0);

      userRequiresDlaApprovalCheck.requiresDlaApproval =
        !userRequiresDlaApprovalCheck.approvalDetails.mortgageSecuredAmountUnderLimit ||
        !userRequiresDlaApprovalCheck.approvalDetails.bridgeSecuredAmountUnderLimit ||
        !userRequiresDlaApprovalCheck.approvalDetails.nonMortgageSecuredAmountUnderLimit ||
        !userRequiresDlaApprovalCheck.approvalDetails.totalConnectionAmountUnderLimit;
    } else {
      userRequiresDlaApprovalCheck.requiresDlaApproval =
        !currentUserWithPreferences?.loanAmount ||
        (!!totalLoanAmount &&
          totalLoanAmount > currentUserWithPreferences.loanAmount &&
          !currentUserWithPreferences?.unlimitedLoanAmount);
    }
    return userRequiresDlaApprovalCheck;
  }

  @Selector([
    AuthState.currentUser,
    MortgagesV2State.totalLoanAmountForAllRequestedMortgages,
    UserAccountsState.userList,
    AppFeaturesState.features,
    ApplicationAggregatesState.aggregates,
    ApplicationApprovalState.applicationRequiresApprovedDla,
  ])
  static currentUserCanApproveDlaRequest(
    currentUser: UserAccount,
    totalLoanAmount: number,
    users: User[] | undefined,
    featureConfig: FeatureConfig | undefined,
    applicationAggregate: ApplicationAggregate | undefined,
  ): boolean {
    const { requiresDlaApproval } = ApplicationApprovalState.currentUserRequiresDlaApproval(
      users,
      currentUser,
      totalLoanAmount,
      {
        isExpandedLendingLimitsEnabled: featureConfig?.enableExpandedLendingLimits,
      },
      applicationAggregate,
    );

    return !requiresDlaApproval;
  }

  @Selector([
    MortgageApplicationState.applicationIsConditionallyApproved,
    ApplicationApprovalState.approvalsList,
    ApprovalTypeState.approvalTypesList,
    AuthState.currentUser,
    MortgagesV2State.totalLoanAmountForAllRequestedMortgages,
    UserAccountsState.userList,
    AppFeaturesState.features,
    ApplicationAggregatesState.aggregates,
  ])
  static applicationRequiresDla(
    applicationIsConditionallyApproved: boolean,
    approvals: ApplicationApproval[] | undefined,
    approvalTypes: ApprovalType[] | undefined,
    currentUser: UserAccount,
    totalLoanAmount: number,
    users: User[] | undefined,
    featureConfig: FeatureConfig | undefined,
    applicationAggregate: ApplicationAggregate | undefined,
  ): ApplicationRequiresDlaResult | undefined {
    if (applicationIsConditionallyApproved) {
      return;
    }

    const { requiresDlaApproval, currentUserWithPreferences, approvalDetails } =
      ApplicationApprovalState.currentUserRequiresDlaApproval(
        users,
        currentUser,
        totalLoanAmount,
        {
          isExpandedLendingLimitsEnabled: featureConfig?.enableExpandedLendingLimits,
        },
        applicationAggregate,
      );

    const dlaApprovalType = approvalTypes?.find(
      (approvalType) =>
        approvalType.isDefault &&
        approvalType.name === DefaultApprovalTypeNameRecord[DefaultApprovalType.DLA_APPROVAL],
    );

    if (!approvals) {
      return;
    }

    const hasActiveDlaApprovalRequest = !!approvals.some(
      (x) =>
        (x.status === ApplicationApprovalStatus.PENDING ||
          (x.status === ApplicationApprovalStatus.APPROVED && !x.isInvalid)) &&
        x.approvalTypeId === dlaApprovalType?.id,
    );

    if (requiresDlaApproval && !hasActiveDlaApprovalRequest) {
      return {
        requestedMortgageTotalLoanAmount: totalLoanAmount ?? 0,
        userPreferencesLoanAmount: currentUserWithPreferences?.loanAmount ?? 0,
        approvalDetails,
        maxBridgeSecured: currentUserWithPreferences?.maxBridgeSecured,
        maxMortgageSecured: currentUserWithPreferences?.maxMortgageSecured,
        maxNonMortgageSecured: currentUserWithPreferences?.maxNonMortgageSecured,
        maxTotalConnection: currentUserWithPreferences?.maxTotalConnection,
        unlimitedBridgeSecured: currentUserWithPreferences?.unlimitedBridgeSecured,
        unlimitedMortgageSecured: currentUserWithPreferences?.unlimitedMortgageSecured,
        unlimitedNonMortgageSecured: currentUserWithPreferences?.unlimitedNonMortgageSecured,
        unlimitedTotalConnection: currentUserWithPreferences?.unlimitedTotalConnection,
      };
    }
    return;
  }

  @Selector([
    MortgageApplicationState.applicationIsConditionallyApproved,
    ApplicationApprovalState.dlaApprovalRequests,
    AuthState.currentUser,
    MortgagesV2State.totalLoanAmountForAllRequestedMortgages,
    UserAccountsState.userList,
    AppFeaturesState.features,
    ApplicationAggregatesState.aggregates,
  ])
  static applicationRequiresApprovedDla(
    applicationIsConditionallyApproved: boolean,
    dlaApprovalRequests: ApplicationApproval[] | undefined,
    currentUser: UserAccount,
    totalLoanAmount: number,
    users: User[] | undefined,
    featureConfig: FeatureConfig | undefined,
    applicationAggregate: ApplicationAggregate | undefined,
  ): boolean {
    if (applicationIsConditionallyApproved) {
      return false;
    }
    const { requiresDlaApproval } = ApplicationApprovalState.currentUserRequiresDlaApproval(
      users,
      currentUser,
      totalLoanAmount,
      {
        isExpandedLendingLimitsEnabled: featureConfig?.enableExpandedLendingLimits,
      },
      applicationAggregate,
    );

    const hastAtLeastOneDlaApproved = dlaApprovalRequests?.some(
      (approval) => approval.status === ApplicationApprovalStatus.APPROVED,
    );

    if (requiresDlaApproval && !hastAtLeastOneDlaApproved) {
      return true;
    }
    return false;
  }

  @Selector([ApplicationApprovalState.applyDiscretionApprovalRequests])
  static applicationHasNoApprovedApplyDiscretion(
    applyDiscretionApprovalRequests: ApplicationApproval[] | undefined,
  ): boolean {
    const hasNoApplyDiscretionApproved =
      applyDiscretionApprovalRequests?.every(
        (approval) => approval.status !== ApplicationApprovalStatus.APPROVED,
      ) || false;

    return hasNoApplyDiscretionApproved;
  }

  @Selector([ApplicationApprovalState.approvalsList, ApprovalTypeState.approvalTypesList])
  static dlaApprovalRequests(
    approvals: ApplicationApproval[] | undefined,
    approvalTypes: ApprovalType[] | undefined,
  ): ApplicationApproval[] | undefined {
    const dlaApprovalType = approvalTypes?.find(
      (approvalType) =>
        approvalType.isDefault &&
        approvalType.name === DefaultApprovalTypeNameRecord[DefaultApprovalType.DLA_APPROVAL],
    );

    const dlaApprovals = approvals?.filter(
      (approval) => approval.approvalTypeId === dlaApprovalType?.id && !approval.isInvalid,
    );

    return dlaApprovals;
  }

  @Selector([ApplicationApprovalState.approvalsList, ApprovalTypeState.approvalTypesList])
  static applyDiscretionApprovalRequests(
    approvals: ApplicationApproval[] | undefined,
    approvalTypes: ApprovalType[] | undefined,
  ): ApplicationApproval[] | undefined {
    const applyDiscretionApprovalType = approvalTypes?.find(
      (approvalType) =>
        approvalType.isDefault &&
        approvalType.name === DefaultApprovalTypeNameRecord[DefaultApprovalType.APPLY_DISCRETION],
    );

    const applyDiscretionApprovals = approvals?.filter(
      (approval) =>
        approval.approvalTypeId === applyDiscretionApprovalType?.id && !approval.isInvalid,
    );

    return applyDiscretionApprovals;
  }

  @Selector([
    MortgageApplicationState.applicationIsConditionallyApproved,
    ApplicationApprovalState.approvalsList,
    ApprovalTypeState.approvalTypesList,
  ])
  static hasUnresolvedApprovals(
    applicationIsConditionallyApproved: boolean,
    approvals: ApplicationApproval[] | undefined,
    approvalTypes: ApprovalType[] | undefined,
  ) {
    if (!approvals || approvals?.length === 0) {
      return false;
    }

    const dlaApprovalType = approvalTypes?.find(
      (approvalType) =>
        approvalType.isDefault &&
        approvalType.name === DefaultApprovalTypeNameRecord[DefaultApprovalType.DLA_APPROVAL],
    );

    const dlaApprovals =
      approvals?.filter(
        (approval) =>
          approval.approvalTypeId === dlaApprovalType?.id &&
          (!approval.isInvalid || approval.status === ApplicationApprovalStatus.CANCELED),
      ) ?? [];

    const hasDlaApproved = dlaApprovals?.some(
      (approval) => approval.status === ApplicationApprovalStatus.APPROVED,
    );

    const allDlaCanceled = dlaApprovals?.every(
      (approval) => approval.status === ApplicationApprovalStatus.CANCELED,
    );

    const dlaResolved =
      dlaApprovals.length === 0 ||
      hasDlaApproved ||
      allDlaCanceled ||
      applicationIsConditionallyApproved;

    return (
      (!dlaResolved ||
        approvals?.some(
          (approval) =>
            [
              ApplicationApprovalStatus.CHANGES_REQUESTED,
              ApplicationApprovalStatus.DECLINED,
              ApplicationApprovalStatus.PENDING,
            ].includes(approval.status) &&
            approval.approvalType?.name !==
              DefaultApprovalTypeNameRecord[DefaultApprovalType.DLA_APPROVAL],
        )) ??
      false
    );
  }

  static isDlaApprovalRequest(applicationApprovalId: string) {
    return createSelector(
      [ApplicationApprovalState.dlaApprovalRequests],
      (dlaApprovalRequests: ApplicationApproval[] | undefined): boolean => {
        return !!dlaApprovalRequests?.find((x) => x.id === applicationApprovalId);
      },
    );
  }

  @Selector([ApplicationApprovalState.approvalsList])
  static pendingRequests(approvals: ApplicationApproval[] | undefined) {
    return approvals?.filter((approval) => approval.status === ApplicationApprovalStatus.PENDING);
  }

  @Selector([ApplicationApprovalState.pendingRequests, AuthState.currentUser])
  static userHasPendingRequests(
    pendingRequests: ApplicationApproval[] | undefined,
    currentUser: UserAccount,
  ) {
    return !!pendingRequests?.filter((approval) => approval.approverId === currentUser?.user?.id)
      .length;
  }

  constructor(
    private applicationApprovalService: ApplicationApprovalService,
    private store: Store,
  ) {}

  @Action(ApproveRequest)
  approveRequest(ctx: StateContext<ApplicationApprovalStateModel>, action: ApproveRequest) {
    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    const approval = state.approvals?.[action.applicationApprovalId];
    const formattedContent = this.createApprovalNote(
      ApplicationApprovalAction.APPROVE,
      approval?.details,
      action.comment,
      approval?.approvalType?.name ?? '',
    );

    const loanAmount = this.store.selectSnapshot(
      MortgagesV2State.totalLoanAmountForAllRequestedMortgages,
    );
    const applicationFutureAggregate = this.store.selectSnapshot(
      ApplicationAggregatesState.futureAggregates,
    );

    return this.applicationApprovalService
      .approveRequest({
        id: action.applicationApprovalId,
        comment: action.comment,
        formattedContent,
        loanAmount,
        applicationFutureAggregate,
      })
      .pipe(
        switchMap((applicationApproval) =>
          this.store.dispatch(new FetchNotes(applicationApproval.applicationId)).pipe(
            map(() => {
              return applicationApproval;
            }),
          ),
        ),
        tap((applicationApproval) => {
          const hasRecommendedApprovals = !!applicationApproval.recommendedApprovalRequests;

          if (hasRecommendedApprovals) {
            return;
          }

          ctx.patchState({
            approvals: {
              ...state.approvals,
              [applicationApproval.id]: applicationApproval,
            },
          });
        }),
        switchMap((applicationApproval) => {
          const hasRecommendedApprovals = !!applicationApproval.recommendedApprovalRequests;

          if (!hasRecommendedApprovals) {
            return of(null);
          }

          // refresh the approvals list when approving an approval and it has recommended approvals
          return this.store.dispatch(
            new FetchApplicationApprovals(applicationApproval.applicationId),
          );
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(CancelRequest)
  cancelRequest(ctx: StateContext<ApplicationApprovalStateModel>, action: CancelRequest) {
    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationApprovalService
      .cancelRequest(action.applicationApprovalId, action.comment)
      .pipe(
        tap((applicationApproval) => {
          const hasRecommendedOrOriginalApprovals =
            !!applicationApproval.recommendedApprovalRequests ||
            !!applicationApproval.originalApprovalRequest;

          if (hasRecommendedOrOriginalApprovals) {
            return;
          }

          ctx.patchState({
            approvals: {
              ...state.approvals,
              [applicationApproval.id]: applicationApproval,
            },
          });
        }),

        switchMap((applicationApproval) => {
          const hasRecommendedOrOriginalApprovals =
            !!applicationApproval.recommendedApprovalRequests ||
            !!applicationApproval.originalApprovalRequest;

          if (!hasRecommendedOrOriginalApprovals) {
            return of(null);
          }

          // refresh the approvals list when cancelling an approval that has either recommended approvals
          // or an original approval
          return this.store.dispatch(
            new FetchApplicationApprovals(applicationApproval.applicationId),
          );
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(DeclineRequest)
  declineRequest(ctx: StateContext<ApplicationApprovalStateModel>, action: DeclineRequest) {
    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    const approval = state.approvals?.[action.applicationApprovalId];
    const formattedContent = this.createApprovalNote(
      ApplicationApprovalAction.DECLINE,
      approval?.details,
      action.comment,
      approval?.approvalType?.name ?? '',
    );

    return this.applicationApprovalService
      .declineRequest({
        id: action.applicationApprovalId,
        comment: action.comment,
        formattedContent,
      })
      .pipe(
        switchMap((applicationApproval) =>
          this.store.dispatch(new FetchNotes(applicationApproval.applicationId)).pipe(
            map(() => {
              return applicationApproval;
            }),
          ),
        ),
        tap((applicationApproval) => {
          const hasRecommendedApprovals = !!applicationApproval.recommendedApprovalRequests;

          if (hasRecommendedApprovals) {
            return;
          }

          ctx.patchState({
            approvals: {
              ...state.approvals,
              [applicationApproval.id]: applicationApproval,
            },
          });
        }),
        switchMap((applicationApproval) => {
          const hasRecommendedApprovals = !!applicationApproval.recommendedApprovalRequests;

          if (!hasRecommendedApprovals) {
            return of(null);
          }

          // refresh the approvals list when declining an approval and it has recommended approvals
          return this.store.dispatch(
            new FetchApplicationApprovals(applicationApproval.applicationId),
          );
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(FetchApplicationApprovals)
  fetchApplicationApprovals(
    ctx: StateContext<ApplicationApprovalStateModel>,
    action: FetchApplicationApprovals,
  ) {
    ctx.patchState({
      approvals: undefined,
    });

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationApprovalService.getAll(action.applicationId).pipe(
      tap((approvals: ApplicationApproval[]) => {
        const approvalsEntities = approvals.reduce(
          (entities: { [key: string]: ApplicationApproval }, approval) => {
            entities[approval.id] = approval;

            return entities;
          },
          {},
        );

        ctx.patchState({ approvals: approvalsEntities });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(RequestApproval)
  requestApproval(ctx: StateContext<ApplicationApprovalStateModel>, action: RequestApproval) {
    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationApprovalService
      .requestApproval(action.applicationApproval, action.applicationId)
      .pipe(
        tap((applicationApprovals) => {
          const isRecommendedApproval = !!applicationApprovals[0].originalApprovalRequestId;

          if (isRecommendedApproval) {
            return;
          }

          const applicationApprovalEntities = applicationApprovals.reduce(
            (
              result: {
                [key: string]: ApplicationApproval;
              },
              applicationApproval,
            ) => {
              result[applicationApproval.id] = applicationApproval;

              return result;
            },
            {},
          );

          ctx.patchState({
            approvals: {
              ...state.approvals,
              ...applicationApprovalEntities,
            },
          });
        }),
        switchMap((applicationApprovals) => {
          const isRecommendedApproval = !!applicationApprovals[0].originalApprovalRequestId;

          if (!isRecommendedApproval) {
            return of(null);
          }

          // refresh the approvals list when adding a recommended approval so that the original approval get updated
          return this.store.dispatch(
            new FetchApplicationApprovals(applicationApprovals[0].applicationId),
          );
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(RequestApprovalAgain)
  requestApprovalAgain(
    ctx: StateContext<ApplicationApprovalStateModel>,
    action: RequestApprovalAgain,
  ) {
    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationApprovalService
      .requestApprovalAgain(action.applicationApprovalId, action.comment)
      .pipe(
        tap((applicationApproval) => {
          ctx.patchState({
            approvals: {
              ...state.approvals,
              [applicationApproval.id]: applicationApproval,
            },
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(RequestChanges)
  requestChanges(ctx: StateContext<ApplicationApprovalStateModel>, action: RequestChanges) {
    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.applicationApprovalService
      .requestChanges(action.applicationApprovalId, action.comment)
      .pipe(
        tap((applicationApproval) => {
          ctx.patchState({
            approvals: {
              ...state.approvals,
              [applicationApproval.id]: applicationApproval,
            },
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  private static mapUserToApproval(
    approval: ApplicationApproval,
    users: User[] | undefined,
  ): ApplicationApproval {
    return {
      ...approval,
      approverUser: users?.find((x) => x.id === approval.approverId),
      requestedByUser: users?.find((x) => x.id === approval.requestedById),
      actionHistory: approval.actionHistory.map((actionHistory) => ({
        ...actionHistory,
        originatorUser: users?.find((x) => x.id === actionHistory.originatorId),
      })),
    };
  }

  private static dlaApprovalIsValidWithApplicationAggregate(
    approval: ApplicationApproval,
    futureAggregates: FutureAggregate | undefined,
  ) {
    const bridgeSecuredAmountIsValid =
      (!approval.metadata.applicationFutureAggregate?.computedBridgeSecuredFuture &&
        !futureAggregates?.computedBridgeSecuredFuture) ||
      (approval.metadata.applicationFutureAggregate?.computedBridgeSecuredFuture ?? 0) >=
        (futureAggregates?.computedBridgeSecuredFuture ?? 0);

    const mortgageSecuredAmountIsValid =
      (!approval.metadata.applicationFutureAggregate?.computedMortgageSecuredFuture &&
        !futureAggregates?.computedMortgageSecuredFuture) ||
      (approval.metadata.applicationFutureAggregate?.computedMortgageSecuredFuture ?? 0) >=
        (futureAggregates?.computedMortgageSecuredFuture ?? 0);

    const nonMortgageLiabilitiesIsValid =
      (!approval.metadata.applicationFutureAggregate?.nonMortgageLiabilitiesFutureInput &&
        !futureAggregates?.nonMortgageLiabilitiesFutureInput) ||
      (approval.metadata.applicationFutureAggregate?.nonMortgageLiabilitiesFutureInput ?? 0) >=
        (futureAggregates?.nonMortgageLiabilitiesFutureInput ?? 0);

    const totalConnectionAmountIsValid =
      (!approval.metadata.applicationFutureAggregate?.computedTotalConnectionFuture &&
        !futureAggregates?.computedTotalConnectionFuture) ||
      (approval.metadata.applicationFutureAggregate?.computedTotalConnectionFuture ?? 0) >=
        (futureAggregates?.computedTotalConnectionFuture ?? 0);
    return (
      bridgeSecuredAmountIsValid &&
      mortgageSecuredAmountIsValid &&
      nonMortgageLiabilitiesIsValid &&
      totalConnectionAmountIsValid
    );
  }

  private static computeIsInvalid = (
    approval: ApplicationApproval,
    totalLoanAmountForAllRequestedMortgages: number,
    futureAggregates: FutureAggregate | undefined,
    isExpandedLendingLimitsSettingEnabled: boolean,
  ) => {
    switch (approval.approvalType.name) {
      case DefaultApprovalTypeNameRecord[DefaultApprovalType.DLA_APPROVAL]:
        if (approval.metadata?.isExpandedLendingLimitsSettingEnabled) {
          return !ApplicationApprovalState.dlaApprovalIsValidWithApplicationAggregate(
            approval,
            futureAggregates,
          );
        } else if (isExpandedLendingLimitsSettingEnabled) {
          return false;
        } else {
          return approval.metadata?.loanAmount
            ? approval.metadata.loanAmount < totalLoanAmountForAllRequestedMortgages
            : false;
        }

      default:
        return false;
    }
  };

  private createApprovalNote(
    action: ApplicationApprovalAction,
    details: string | undefined,
    comment: string | null,
    type: string | null,
  ): string {
    const currentUser = this.store.selectSnapshot(AuthState.currentUser);

    const textLines: string[] = [];

    textLines.push(
      `${currentUser?.user?.displayName} ` + $localize`reviewed an approval` + `: ${details}`,
    );

    textLines.push('');
    textLines.push($localize`Status` + `: ${ApplicationApprovalActionRecord[action]}`);

    textLines.push($localize`Approval type` + `: ${type}`);
    textLines.push('');
    if (comment) {
      textLines.push($localize`Comment` + `: ${comment}`);
    }

    return `<div>${textLines.map((text) => (text ? text : '&nbsp;')).join('</div><div>')}</div>`;
  }
}
