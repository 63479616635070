import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApprovalType } from './approval-type.model';

@Injectable({
  providedIn: 'root',
})
export class ApprovalTypeService {
  private base = `${environment.fundmore_api_url}/approvalType`;

  constructor(private http: HttpClient) {}

  add(approvalType: Partial<ApprovalType>): Observable<ApprovalType> {
    return this.http.post<ApprovalType>(this.base, approvalType);
  }

  delete(approvalTypeId: string) {
    return this.http.delete<void>(`${this.base}/${approvalTypeId}`);
  }

  getAll(): Observable<ApprovalType[]> {
    return this.http.get<ApprovalType[]>(this.base);
  }

  update(approvalType: Partial<ApprovalType>): Observable<ApprovalType> {
    return this.http.patch<ApprovalType>(`${this.base}/${approvalType.id}`, approvalType);
  }
}
