import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { finalize, tap } from 'rxjs/operators';
import { PropertyAppraisalsService } from './property-appraisals.service';
import {
  AppraisalStatus,
  CalvertIntegrationFields,
  FetchType,
  PropertyAppraisal,
  PropertyAppraisalKey,
} from '../../shared/model';
import { Property } from '../../shared';
import { LoadingEnd, LoadingStart } from '../../core/loading.state';
import { PatchProperty } from '../properties.actions';
import { ApplicationResetState } from '../../shared/state.model';
import { Column } from '../../features/manager-portal/condition-manage/model';
import { PropertyAppraisalKeyRecord } from '../../shared/enum-records-metadata';
import { AppFeaturesState, AppFeaturesStateModel } from '../../shared/app-features.state';

export type PropertyAppraisalStateModel = PropertyAppraisal[];

export class UpdateAppraisal {
  static readonly type = '@appraisals.updateAppraisal';
  constructor(
    public propertyId: string,
    public appraisalId: string,
    public updates: Partial<PropertyAppraisal>,
  ) {}
}

export class DeleteAppraisal {
  static readonly type = '@appraisals.deleteAppraisal';
  constructor(public propertyId: string, public appraisalId: string) {}
}

export class CreateAppraisal {
  static readonly type = '@appraisals.createAppraisal';
  constructor(
    public propertyId: string,
    public applicationId: string,
    public appraisal: PropertyAppraisal,
  ) {}
}

export class FetchAppraisals {
  static readonly type = '@appraisals.fetchAppraisals';
  constructor(public applicationId: string) {}
}

export class FetchAppraisalData {
  static readonly type = '@appraisals.fetchAppraisalData';
  constructor(public appraisalId: string, public force?: boolean) {}
}

export class SetPropertyValueAppraisal {
  static readonly type = '@appraisals.setPropertyValueAppraisal';
  constructor(public propertyId: string, public appraisal: PropertyAppraisal) {}
}

export class RemovePropertyValueAppraisals {
  static readonly type = '@appraisals.removePropertyValueAppraisals';
  constructor(public propertyId: string) {}
}

export class AddPropertyAppraisal {
  static readonly type = '@appraisals.addPropertyAppraisal';
  constructor(public appraisal: PropertyAppraisal) {}
}

export class SetFCTAppraisalCancellation {
  static readonly type = '@appraisals.setFCTAppraisalCancellation';
  constructor(public fctReferenceId?: string) {}
}

export class AddFCTAppraisalNote {
  static readonly type = '@appraisals.addFCTAppraisalNote';
  constructor(
    public fctReferenceId: string,
    public noteMessage: string,
    public recipients: string[],
  ) {}
}

const PROPERTY_APPRAISALS_SELECTABLE_COLUMNS: PropertyAppraisalKey[] = [
  PropertyAppraisalKey.APPRAISAL_DATE,
  PropertyAppraisalKey.APPRAISER,
  PropertyAppraisalKey.APPRAISER_COMPANY,
  PropertyAppraisalKey.APPRAISAL_TYPE,
  PropertyAppraisalKey.STATUS,
  PropertyAppraisalKey.SOURCE,
  PropertyAppraisalKey.APPRAISED_VALUE,
];

export const PROPERTY_APPRAISALS_DEFAULT_DISPLAY_COLUMNS: Column[] =
  PROPERTY_APPRAISALS_SELECTABLE_COLUMNS.map((value: PropertyAppraisalKey) => {
    const name = PropertyAppraisalKeyRecord[value] ?? value;

    return {
      field: value,
      name,
      isSelected: true,
      isFrozen: false,
    };
  });

@State<PropertyAppraisalStateModel>({
  name: 'appraisals',
  defaults: [],
})
@Injectable()
export class PropertyAppraisalState {
  constructor(private propertyAppraisalsService: PropertyAppraisalsService) {}

  @Action(ApplicationResetState)
  reset(ctx: StateContext<PropertyAppraisal[]>) {
    ctx.setState([]);
  }

  @Selector()
  static appraisals(state: PropertyAppraisal[]) {
    return state ?? [];
  }

  @Selector([AppFeaturesState])
  static propertyAppraisalsOptionalDisplayColumns(
    appFeaturesState: AppFeaturesStateModel,
  ): Column[] {
    const optionalFields = appFeaturesState?.features?.optionalFields ?? [];

    const valuationTimeFieldEnabled = optionalFields?.includes(
      CalvertIntegrationFields.VALUATION_TIME,
    );
    const confidenceScoreFieldEnabled = optionalFields?.includes(
      CalvertIntegrationFields.CONFIDENCE_SCORE,
    );
    const fctIntegrationEnabled = appFeaturesState?.features?.fctIntegrationEnabled ?? false;

    const optionalColumns: Column[] = [];
    if (valuationTimeFieldEnabled) {
      optionalColumns.push({
        field: PropertyAppraisalKey.VALUATION_TIME,
        name: PropertyAppraisalKeyRecord[PropertyAppraisalKey.VALUATION_TIME],
        isSelected: true,
        isFrozen: false,
        isOptional: true,
      });
    }

    if (confidenceScoreFieldEnabled) {
      optionalColumns.push({
        field: PropertyAppraisalKey.CONFIDENCE_SCORE,
        name: PropertyAppraisalKeyRecord[PropertyAppraisalKey.CONFIDENCE_SCORE],
        isSelected: true,
        isFrozen: false,
        isOptional: true,
      });
    }

    if (fctIntegrationEnabled) {
      optionalColumns.push({
        field: PropertyAppraisalKey.FCT_INSURANCE_STATUS,
        name: PropertyAppraisalKeyRecord[PropertyAppraisalKey.FCT_INSURANCE_STATUS],
        isSelected: true,
        isFrozen: false,
        isOptional: true,
      });
    }

    return optionalColumns;
  }

  static propertyValueAppraisal(
    propertyId: string | undefined,
  ): (state: PropertyAppraisal[]) => PropertyAppraisal | undefined {
    return createSelector([PropertyAppraisalState], (state: PropertyAppraisal[]) => {
      return state.find((a) => a.propertyId === propertyId && a.isPropertyValue === true);
    });
  }

  static propertyValueAppraisals(): (state: PropertyAppraisal[]) => PropertyAppraisal | undefined {
    return createSelector([PropertyAppraisalState], (state: PropertyAppraisal[]) => {
      return state.find((a) => a.isPropertyValue === true);
    });
  }

  static propertyValueIsAppraised(
    propertyId: string | undefined,
  ): (state: PropertyAppraisal[]) => boolean {
    return createSelector([PropertyAppraisalState], (state: PropertyAppraisal[]) => {
      return state.some((a) => a.propertyId === propertyId && a.isPropertyValue === true);
    });
  }

  static propertiesValueIsAppraised(
    properties: Property[] | undefined,
  ): (state: PropertyAppraisal[]) => boolean[] | undefined {
    return createSelector([PropertyAppraisalState], (state: PropertyAppraisal[]) => {
      return properties?.map((property) =>
        state.some((a) => a.propertyId === property.id && a.isPropertyValue === true),
      );
    });
  }

  @Action(CreateAppraisal)
  createAppraisal(ctx: StateContext<PropertyAppraisal[]>, action: CreateAppraisal) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();
    return this.propertyAppraisalsService
      .postAppraisal(action.propertyId, action.applicationId, action.appraisal)
      .pipe(
        tap((newAppraisal) => ctx.setState(this.sortAppraisalsByDate([...state, newAppraisal]))),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(FetchAppraisals)
  fetchAppraisals(ctx: StateContext<PropertyAppraisal[]>, action: FetchAppraisals) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.propertyAppraisalsService.fetchPropertiesAppraisals(action.applicationId).pipe(
      tap((appraisals) => {
        ctx.setState(this.sortAppraisalsByDate(appraisals));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateAppraisal)
  updateAppraisal(ctx: StateContext<PropertyAppraisal[]>, action: UpdateAppraisal) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();

    return this.propertyAppraisalsService
      .patchAppraisal(action.propertyId, action.appraisalId, action.updates as PropertyAppraisal)
      .pipe(
        tap((updatedAppraisal) => {
          ctx.setState(
            this.sortAppraisalsByDate([
              ...state.filter((a) => a.id !== action.appraisalId),
              updatedAppraisal,
            ]),
          );
          if (action.updates.isPropertyValue && action.updates.appraisedValue) {
            ctx.dispatch(
              new PatchProperty(action.propertyId, {
                estimatedValue: action.updates.appraisedValue,
                estimatedValueDate: new Date().toISOString(),
              }),
            );
          }
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(DeleteAppraisal)
  deleteAppraisal(ctx: StateContext<PropertyAppraisal[]>, action: DeleteAppraisal) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();
    return this.propertyAppraisalsService
      .deleteAppraisal(action.propertyId, action.appraisalId)
      .pipe(
        tap(() => ctx.setState([...state.filter((a) => a.id !== action.appraisalId)])),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(SetPropertyValueAppraisal)
  setPropertyValueAppraisal(
    ctx: StateContext<PropertyAppraisal[]>,
    action: SetPropertyValueAppraisal,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();

    return this.propertyAppraisalsService
      .setDefaultAppraisal(action.propertyId, action.appraisal)
      .pipe(
        tap(() => {
          ctx.setState(
            state.map((a) => {
              if (a.propertyId === action.propertyId && a.id == action.appraisal.id) {
                return Object.assign({}, a, { isPropertyValue: true });
              }
              return a;
            }),
          );

          ctx.dispatch(
            new PatchProperty(action.propertyId, {
              estimatedValue: state.find((a) => a.id == action.appraisal.id)?.appraisedValue,
              estimatedValueDate: new Date().toISOString(),
            }),
          );
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(RemovePropertyValueAppraisals)
  removePropertyValueAppraisals(
    ctx: StateContext<PropertyAppraisal[]>,
    action: RemovePropertyValueAppraisals,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();

    ctx.setState(
      state.map((a) => {
        if (a.propertyId == action.propertyId) {
          return Object.assign({}, a, { isPropertyValue: false });
        }
        return a;
      }),
    );

    ctx.dispatch(new LoadingEnd(this.constructor.name));
  }

  @Action(AddPropertyAppraisal)
  addPropertyAppraisal(ctx: StateContext<PropertyAppraisal[]>, action: AddPropertyAppraisal) {
    const state = ctx.getState();
    ctx.setState(this.sortAppraisalsByDate([...state, action.appraisal]));
  }

  @Action(SetFCTAppraisalCancellation)
  setFCTAppraisalCancellation(
    ctx: StateContext<PropertyAppraisal[]>,
    action: SetFCTAppraisalCancellation,
  ) {
    const state = ctx.getState();
    const appraisal = state.find((a) => a.FCTAppraisal?.fctReferenceId === action.fctReferenceId);

    if (!appraisal || !action.fctReferenceId) {
      return;
    }

    ctx.dispatch(
      new UpdateAppraisal(appraisal.propertyId, appraisal.id, {
        status: AppraisalStatus.CANCELLED,
      }),
    );
  }

  @Action(AddFCTAppraisalNote)
  addFCTAppraisalNote(ctx: StateContext<PropertyAppraisal[]>, action: AddFCTAppraisalNote) {
    const state = ctx.getState();
    const appraisal = state.find((a) => a.FCTAppraisal?.fctReferenceId === action.fctReferenceId);

    if (!appraisal || !action.fctReferenceId) {
      return;
    }

    ctx.setState(
      this.sortAppraisalsByDate([
        ...state.filter((a) => a.FCTAppraisal?.fctReferenceId !== action.fctReferenceId),
        Object.assign({}, appraisal, {
          FCTAppraisal: {
            ...appraisal.FCTAppraisal,
            notes: [
              ...(appraisal.FCTAppraisal?.notes ?? []),
              {
                Files: [],
                Sender: 'You',
                NewNote: true,
                Recipients: action.recipients,
                NoteMessage: action.noteMessage,
                CreatedTimestamp: new Date().toISOString(),
              },
            ],
          },
        }),
      ]),
    );
  }

  @Action(FetchAppraisalData)
  fetchAppraisalData(ctx: StateContext<PropertyAppraisal[]>, action: FetchAppraisalData) {
    const state = ctx.getState();
    const appraisal = state.find((a) => a.id === action.appraisalId);

    if (!appraisal || (appraisal.fetchType === FetchType.COMPLETE && !action.force)) {
      return; // already fetched or not found
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.propertyAppraisalsService.fetchAppraisalData(action.appraisalId).pipe(
      tap((appraisal) => {
        ctx.setState(
          this.sortAppraisalsByDate([
            ...state.filter((a) => a.id !== action.appraisalId),
            Object.assign({}, appraisal),
          ]),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  sortAppraisalsByDate(appraisals: PropertyAppraisal[]): PropertyAppraisal[] {
    // appraisalDate is the date-only field which is shown in UI, createdAt is the date-time field
    return appraisals.sort((a, b) =>
      (a.appraisalDate ? new Date(a.appraisalDate) : new Date()) >
      (b.appraisalDate ? new Date(b.appraisalDate) : new Date())
        ? 1
        : new Date(a.createdAt) > new Date(b.createdAt)
        ? 1
        : -1,
    );
  }
}
