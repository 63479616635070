import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { FetchGeneralPermissions } from '../auth/permissions.state';
import { Store } from '@ngxs/store';
import { GetCurrentUserParameters } from '../auth/auth.actions';
import { Observable } from 'rxjs';

export const PortalGateResolver: ResolveFn<unknown> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  store: Store = inject(Store),
): Observable<unknown> =>
  store.dispatch([new GetCurrentUserParameters(), new FetchGeneralPermissions()]);
