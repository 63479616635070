export interface ApplicationBrokerCommission {
  id: string;
  applicationId: string;
  mortgageId: string;
  newFunds?: number;
  standardCommission?: number;
  lessBuydown?: number;
  finalCommission?: number;
  brokerReductionFee?: number;
  totalFunds?: number;
}

export enum ApplicationBrokerCommissionKey {
  NEW_FUNDS = 'newFunds',
  STANDARD_COMMISSION = 'standardCommission',
  LESS_BUYDOWN = 'lessBuydown',
  FINAL_COMMISSION = 'finalCommission',
  BROKER_REDUCTION_FEE = 'brokerReductionFee',
  TOTAL_FUNDS = 'totalFunds',
}
