/**
 *  Convert input value that contains letter and number into just number
 * ex 1). 1 years 6 months => 18 (months)
 * ex 2). 12 years => 144 (months)
 * ex 3). 12 months => 12 (month)
 * @param value duration free text
 * @returns months (number) (0 when it can not be parsed)
 */
export function convertStringDurationToMonths(value?: string): number {
  const dataString = value?.trim().toLowerCase();
  const extractedNumber = dataString?.match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g)?.map(Number);
  let year, month;

  if (dataString && extractedNumber) {
    if (dataString.indexOf('y') !== -1) {
      const firstIndexYear = extractedNumber[0];

      if (extractedNumber[1]) {
        year = firstIndexYear + extractedNumber[1] / 12;
      } else {
        year = firstIndexYear;
      }
    } else if (dataString.indexOf('m') !== -1) {
      month = extractedNumber[0];
    }
  }

  return (year ? year * 12 : 0) + (month ?? 0);
}
