export interface AuditLog {
  id?: string;
  userId?: string;
  applicationId?: string;
  type?: AuditLogType;
  requestPayload?: unknown;
  responsePayload?: unknown;
  errorPayload?: unknown;
  isSuccessful?: boolean;
  isCanceled?: boolean;
  metadata?: unknown;
  sourceIp?: string;
  destinationIp?: string;
  requestUrl?: string;
}

export enum AuditLogType {
  OPTA_MARKET_VALUATION = 'OPTA_MARKET_VALUATION',
  OPTA_ADDRESS_SEARCH = 'OPTA_ADDRESS_SEARCH',
  OPTA_ICLARIFY = 'OPTA_ICLARIFY',
  EQUIFAX_AUTH = 'EQUIFAX_AUTH',
  EQUIFAX_CONSUMER_CREDIT_REPORT_PULL = 'EQUIFAX_CONSUMER_CREDIT_REPORT_PULL',
  CMHC_SUBMIT_APPLICATION = 'CMHC_SUBMIT_APPLICATION',
  CMHC_GET_APPLICATION = 'CMHC_GET_APPLICATION',
  SAGEN_ADD_APPLICATION = 'SAGEN_ADD_APPLICATION',
  SAGEN_UPDATE_APPLICATION = 'SAGEN_UPDATE_APPLICATION',
  SAGEN_CANCEL_APPLICATION = 'SAGEN_CANCEL_APPLICATION',
  SAGEN_INQUIRE_APPLICATION_STATUS = 'SAGEN_INQUIRE_APPLICATION_STATUS',
  CANADA_GUARANTY_AUTH = 'CANADA_GUARANTY_AUTH',
  CANADA_GUARANTY_ADD_APPLICATION = 'CANADA_GUARANTY_ADD_APPLICATION',
  CANADA_GUARANTY_UPDATE_APPLICATION = 'CANADA_GUARANTY_UPDATE_APPLICATION',
  CANADA_GUARANTY_DELETE_APPLICATION = 'CANADA_GUARANTY_DELETE_APPLICATION',
  CANADA_GUARANTY_INQUIRE_APPLICATION = 'CANADA_GUARANTY_INQUIRE_APPLICATION',
  INTELLIFI_SERVICING = 'INTELLIFI_SERVICING',
  SAGEN_CERTIFAX_PULLED = 'SAGEN_CERTIFAX_PULLED',
  EQ_CREATE_APPLICATION = 'EQ_CREATE_APPLICATION',
  EQ_LOAN_UPDATE = 'EQ_LOAN_UPDATE',
  EQ_LOAN_DECISION = 'EQ_LOAN_DECISION',
  FCT_CREATE_REQUEST = 'FCT_CREATE_REQUEST',
  FCT_UPDATE_REQUEST = 'FCT_UPDATE_REQUEST',
  OPEN_AI_CHAT_COMPLETION = 'OPEN_AI_CHAT_COMPLETION',
  MCU_CREATE_MEMBER_POSTBACK = 'MCU_CREATE_MEMBER_POSTBACK',
  MCU_CREATE_PRODUCTS_POSTBACK = 'MCU_CREATE_PRODUCTS_POSTBACK',
  MCU_CREDIT_CARD_FULFILL_POSTBACK = 'MCU_CREDIT_CARD_FULFILL_POSTBACK',
  IDS_BANKING_EXISTING_MEMBERS_SEARCH_REQUEST = 'IDS_BANKING_EXISTING_MEMBERS_SEARCH_REQUEST',
  IDS_BANKING_MEMBERS_ACCOUNT_REQUEST = 'IDS_BANKING_MEMBERS_ACCOUNT_REQUEST',
  IDS_BANKING_MEMBERSHIP_ASSOCIATIONS_REQUEST = 'IDS_BANKING_MEMBERSHIP_ASSOCIATIONS_REQUEST',
  IDS_BANKING_CREATE_PRODUCTS_REQUEST = 'IDS_BANKING_CREATE_PRODUCTS_REQUEST',
  IDS_BANKING_CREDIT_CARD_FULFILL_REQUEST = 'IDS_BANKING_CREDIT_CARD_FULFILL_REQUEST',
  IDS_BANKING_MEMBERS_CONNECTED_PARTY_REQUEST = 'IDS_BANKING_MEMBERS_CONNECTED_PARTY_REQUEST',
  MMS_GET_TOKEN = 'MMS_GET_TOKEN',
  MMS_CREATE_REQUEST = 'MMS_CREATE_REQUEST',
  MMS_UPDATE_REQUEST = 'MMS_UPDATE_REQUEST',
  MMS_GET_REQUEST_DATA = 'MMS_GET_REQUEST_DATA',
  MMS_SEND_DOCUMENTS = 'MMS_SEND_DOCUMENTS',
  MMS_GET_DOCUMENT_BY_ID = 'MMS_GET_DOCUMENT_BY_ID',
  MMS_GET_DOCUMENTS_FOR_REQUEST = 'MMS_GET_DOCUMENTS_FOR_REQUEST',
  MMS_SEND_NOTE = 'MMS_SEND_NOTE',
  MMS_GET_NOTES = 'MMS_GET_NOTES',
  MMS_SEND_MILESTONE = 'MMS_SEND_MILESTONE',
  MMS_GET_STATUS = 'MMS_GET_STATUS',
  MMS_GET_DISBURSEMENTS = 'MMS_GET_DISBURSEMENTS',
  MMS_GET_LENDER_PRODUCTS = 'MMS_GET_LENDER_PRODUCTS',
  MMS_SEND_FUNDING_CONFIRMATION = 'MMS_SEND_FUNDING_CONFIRMATION',
}
