// TODO check this as it might duplicate ConditionTableKey
export enum ConditionKey {
  INDEX = 'index',
  NAME = 'name',
  STATUS = 'status',
  TEXT = 'text',
  TEXT_FRENCH = 'text_french',
  PRODUCTS = 'products',
  IS_DEFAULT = 'isDefault',
  REQUIRED_STAGE = 'requiredStage',
  RESPONSIBLE = 'responsible',
  DOCUMENT = 'document',
  DM_DOCUMENT = 'dmDocument',
  APPLICATION_PURPOSE = 'applicationPurpose',
  CONDITION_TYPE = 'conditionType',
}
