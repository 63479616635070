import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { EMPTY, finalize, tap } from 'rxjs';
import { LoadingEnd, LoadingStart } from '../../core/loading.state';
import { FieldMetadataService } from './field-metadata.service';
import {
  FieldMetadata,
  FieldName,
  FieldOption,
  FieldOptionDetails,
  FormattedFieldMetadata,
  MortgageKey,
  SupportedCustomEntity,
} from '@fundmoreai/models';
import { formatFieldMetadata, unformatFieldMetadata } from '@fundmoreai/helpers';
import { CustomEntityRecord } from './field-metadata-options-record';
import { LOCKED_FIELDS_BY_DEFAULT } from './field-metadata.const';
import { AppFeaturesState } from '../app-features.state';

export type FieldMetadataStateModel = FormattedFieldMetadata;

export class GetAllFieldMetadata {
  static readonly type = '@fieldMetadata.getAllFieldMetadata';
}

export class CreateFieldMetadata {
  static readonly type = '@fieldMetadata.createFieldMetadata';
  constructor(public fieldMetadata: Partial<FieldMetadata>) {}
}

export class UpdateFieldMetadata {
  static readonly type = '@fieldMetadata.updateFieldMetadata';
  constructor(public id: string, public fieldMetadata: FieldMetadata) {}
}

export class DeleteFieldMetadata {
  static readonly type = '@fieldMetadata.deleteFieldMetadata';
  constructor(public id: string) {}
}

export class ResolveFieldMetadata {
  static readonly type = '@fieldMetadata.resolveFieldMetadata';
}

@State<FieldMetadataStateModel>({ name: 'fieldMetadataState', defaults: {} })
@Injectable()
export class FieldMetadataState {
  static tableDetails(tableName: string) {
    return createSelector([FieldMetadataState], (state: FormattedFieldMetadata) => {
      const fields = Object.values(state?.[tableName] ?? {})
        .sort((a, b) =>
          (a.fieldDisplayName?.toLocaleLowerCase() || '') >
          (b.fieldDisplayName?.toLocaleLowerCase() || '')
            ? 1
            : -1,
        )
        .reduce((result: FieldName, field: FieldMetadata) => {
          result[field.fieldName] = field;

          return result;
        }, {});

      return fields;
    });
  }

  static customFieldsExistOnTable(tableName: string) {
    return createSelector([FieldMetadataState], (state: FormattedFieldMetadata) => {
      return Object.values(state?.[tableName] ?? {}).some((f) => f?.isCustom);
    });
  }

  static getVisibleFieldOptions(tableName: SupportedCustomEntity, fieldName: string) {
    return createSelector([FieldMetadataState], (state: FormattedFieldMetadata) => {
      const options = state?.[tableName]?.[fieldName]?.options ?? {};

      const formattedOptions: { [key: string]: string } = {};
      for (const opt in options) {
        if (typeof options[opt] === 'string') {
          formattedOptions[opt] = options[opt] as string;
        } else if (
          typeof options[opt] === 'object' &&
          !(
            (options[opt] as FieldOptionDetails).disabled ||
            (options[opt] as FieldOptionDetails).deletedAt
          )
        ) {
          formattedOptions[opt] = (options[opt] as FieldOptionDetails).value;
        }
      }
      return formattedOptions;
    });
  }

  static getAllFieldOptions(tableName: string, fieldName: string) {
    return createSelector([FieldMetadataState], (state: FormattedFieldMetadata) => {
      const options = state?.[tableName]?.[fieldName]?.options ?? {};
      const formattedOptions: { [key: string]: string } = {};
      for (const opt in options) {
        if (typeof options[opt] === 'string') {
          formattedOptions[opt] = options[opt] as string;
        } else if (typeof options[opt] === 'object') {
          formattedOptions[opt] = (options[opt] as FieldOptionDetails).value;
        }
      }
      return formattedOptions;
    });
  }

  static tableCustomFields(tableName: string) {
    return createSelector([FieldMetadataState], (state: FormattedFieldMetadata) => {
      const tableFields = state?.[tableName] ?? {};

      return Object.values(tableFields).filter((x: FieldMetadata) => x.isCustom);
    });
  }

  @Selector() static fieldMetadata(ctx: FieldMetadataStateModel) {
    return ctx;
  }

  constructor(private fieldMetadataService: FieldMetadataService, private store: Store) {}

  @Action(GetAllFieldMetadata) getAllFieldMetadata(ctx: StateContext<FieldMetadataStateModel>) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.fieldMetadataService.getAllFieldMetadata().pipe(
      tap((fieldMetadata: FieldMetadata[]) => {
        const formattedFieldMetadata = formatFieldMetadata(fieldMetadata, CustomEntityRecord);
        if (
          this.store.selectSnapshot(AppFeaturesState.eqMapsEnabled) ||
          this.store.selectSnapshot(AppFeaturesState.iadByFpdEnabled)
        ) {
          const eqbIADLocked = {
            tableName: SupportedCustomEntity.MORTGAGE,
            fieldName: MortgageKey.INTEREST_ADJUSTMENT_DATE,
          };

          LOCKED_FIELDS_BY_DEFAULT.push(eqbIADLocked);
        }
        Object.keys(formattedFieldMetadata).forEach((tableName) => {
          Object.keys(formattedFieldMetadata[tableName]).forEach((fieldName) => {
            if (
              LOCKED_FIELDS_BY_DEFAULT.find(
                (x) => x.tableName === tableName && x.fieldName === fieldName,
              )
            ) {
              formattedFieldMetadata[tableName][fieldName].locked = true;
            }
          });
        });

        ctx.patchState(formattedFieldMetadata);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreateFieldMetadata) createFieldMetadata(
    ctx: StateContext<FieldMetadataStateModel>,
    action: CreateFieldMetadata,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.fieldMetadataService
      .createFieldMetadata({ ...action.fieldMetadata, id: undefined })
      .pipe(
        tap((createdFieldMetadata: FieldMetadata) => {
          const state = ctx.getState();
          // remove fields that are not accepted by the patch endpoint
          const { createdAt, updatedAt, tenantId, deletedAt, ...fieldMetadata } =
            createdFieldMetadata;

          ctx.patchState({
            ...state,
            [fieldMetadata.tableName]: {
              ...state[fieldMetadata.tableName],
              [fieldMetadata.isCustom ? fieldMetadata.id : fieldMetadata.fieldName]: fieldMetadata,
            },
          });
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(UpdateFieldMetadata) updateFieldMetadata(
    ctx: StateContext<FieldMetadataStateModel>,
    action: UpdateFieldMetadata,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.fieldMetadataService
      .updateFieldMetadata(action.id, { ...action.fieldMetadata, id: undefined })
      .pipe(
        tap(() => {
          const state = ctx.getState();
          const updatedField = action.fieldMetadata;
          const updatedState = {
            ...state,
            [updatedField.tableName]: {
              ...state[updatedField.tableName],
              [updatedField.isCustom ? updatedField.id : updatedField.fieldName]: updatedField,
            },
          };
          ctx.patchState(updatedState);
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(DeleteFieldMetadata) deleteFieldMetadata(
    ctx: StateContext<FieldMetadataStateModel>,
    action: DeleteFieldMetadata,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.fieldMetadataService.deleteFieldMetadata(action.id).pipe(
      tap(() => {
        const state = ctx.getState();
        const filteredState = unformatFieldMetadata(state)
          .filter((f) => f.id !== action.id)
          .map((x) => ({
            ...x,
            options: x.options
              ? Object.keys(x.options).reduce((options, key) => {
                  if (x.options) {
                    const option = x.options[key];

                    options[key] = typeof option === 'object' ? { ...option } : option;
                  }

                  return options;
                }, {} as FieldOption)
              : undefined,
          }));
        ctx.patchState(formatFieldMetadata(filteredState, CustomEntityRecord));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(ResolveFieldMetadata) resolveFieldMetadata(ctx: StateContext<FieldMetadataStateModel>) {
    const state = ctx.getState();

    if (Object.keys(state).length === 0) {
      return ctx.dispatch(new GetAllFieldMetadata());
    }

    return EMPTY;
  }
}
