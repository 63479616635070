export enum CmhcPropertyDwellingType {
  SINGLE = '001',
  SEMI_DETACHED = '002',
  DUPLEX = '003',
  ROW = '004',
  APARTMENT = '005',
  MOBILE = '006',
  TRI_PLEX = '008',
  STACKED = '010',
  MODULAR_HOME = '017',
  FOURPLEX = '018',
}
