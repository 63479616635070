import {
  Application,
  ApplicationApprove,
  ApplicationPending,
  ApplicationStage,
  ApplicationStatus,
  ExpandedLoanNumber,
  PipelineApplication,
  PriorityType,
  User,
} from '@fundmoreai/models';
import { Role } from '../features/shared/user';
import { LockHistory } from '../shared';

export class SetApplication {
  static readonly type = '@mortgageApplication.SetApplication';
  constructor(public application: Application | PipelineApplication) {}
}

export class CleanApplication {
  static readonly type = '@mortgageApplication.CleanApplication';
}

export class RefreshApplication {
  static readonly type = '@mortgageApplication.refreshApplication';
  constructor(public applicationId: string) {}
}

export class GetApplication {
  static readonly type = '@mortgageApplication.GetApplication';
  constructor(public applicationId: string) {}
}

export class LeaveApplication {
  static readonly type = '@mortgageApplication.LeaveApplication';
  constructor(public applicationId: string) {}
}

export class ApplicationResetState {
  static readonly type = '@mortgageApplication.ApplicationResetState';
}

export class MoveToStage {
  static readonly type = '@mortgageApplication.MoveToStage';
  constructor(
    public applicationId: string,
    public newStage: ApplicationStage,
    public locked?: boolean,
  ) {}
}

export class DeclineApplication {
  static readonly type = '@mortgageApplication.DeclineApplication';
  constructor(
    public applicationId: string,
    public declineReasonId: string,
    public declineComment: string | undefined,
    public declineDate: Date | undefined,
    public locked?: boolean,
  ) {}
}

export class CancelApplication {
  static readonly type = '@mortgageApplication.CancelApplication';
  constructor(
    public applicationId: string,
    public declineReasonId: string,
    public cancelComment: string | undefined,
    public cancelDate: Date | undefined,
    public locked?: boolean,
  ) {}
}

export class PatchApplication {
  static readonly type = '@mortgageApplication.PatchApplication';
  constructor(public applicationId: string, public application: Partial<Application>) {}
}

export class UpdateApplicationPriority {
  static readonly type = '@mortgageApplication.UpdateApplicationPriority';
  constructor(public applicationId: string, public priority: PriorityType | null) {}
}

export class SetArchived {
  static readonly type = '@mortgageApplication.SetArchived';
  constructor(
    public applicationId: string,
    public application?: Partial<Application>,
    public updateApplication = true,
  ) {}
}

export class SetLocked {
  static readonly type = '@mortgageApplication.SetLocked';
  constructor(
    public applicationId: string,
    public application: Partial<Application>,
    public lockDetails: Partial<LockHistory>,
  ) {}
}

export class UpdateEzidoxDocsDueDate {
  static readonly type = '@mortgageApplication.UpdateEzidoxDocsDueDate';
  constructor(
    public applicationId: string,
    public ezidoxApplicationId: number,
    public docsDueDate: string,
  ) {}
}

export class UpdateFund {
  static readonly type = '@mortgageApplication.UpdateFund';
  constructor(public applicationId: string, public fundId: string) {}
}

export class UpdateDeclineDate {
  static readonly type = '@mortgageApplication.UpdateDeclineDate';
  constructor(public applicationId: string, public declineDate: string | undefined) {}
}

export class DeleteApplication {
  static readonly type = '@mortgageApplication.DeleteApplication';
  constructor(public applicationId: string) {}
}

export class AssignUserRole {
  static readonly type = '@mortgageApplication.AssignUserRole';
  constructor(public users: User[] | undefined, public role: Role) {}
}

export class ApproveApplication {
  static readonly type = '@mortgageApplication.ApproveApplication';
  constructor(public applicationApprove: ApplicationApprove) {}
}

export class PendingApplication {
  static readonly type = '@mortgageApplication.PendingApplication';
  constructor(public applicationId: string, public applicationPending: ApplicationPending) {}
}

export class AssignApplicationUser {
  static readonly type = '@mortgageApplication.AssignApplicationUser';
  constructor(public users: User[] | undefined, public role: Role) {}
}

export class GetDocumentRequestStatus {
  static readonly type = '@mortgageApplication.GetDocumentRequestStatus';
  constructor(public applicationId: string) {}
}

export class SetDocumentRequestDialogCloseCallback {
  static readonly type = '@mortgageApplication.SetDocumentRequestDialogCloseCallback';
  constructor(public callback: () => void) {}
}

export class ResetDocumentRequestDialogCloseCallback {
  static readonly type = '@mortgageApplication.ResetDocumentRequestDialogCloseCallback';
}

export class CloseDocumentRequestDialog {
  static readonly type = '@mortgageApplication.CloseDocumentRequestDialog';
}

export class SetApplicationMemberFlags {
  static readonly type = '@mortgageApplication.SetApplicationMemberFlags';

  constructor(
    public restrictedPartyMember: boolean | undefined,
    public staffMember: boolean | undefined,
    public connectedParty: boolean | undefined,
  ) {}
}

export class RefreshApplicationRestrictedStatus {
  static readonly type = '@mortgageApplication.RefreshApplicationRestrictedStatus';
  constructor(public applicationId: string) {}
}

export class RefreshApplicationRestrictedStatusAndCreditOffers {
  static readonly type = '@mortgageApplication.RefreshApplicationRestrictedStatus';
  constructor(public applicationId: string) {}
}

export class RefreshDecisionEngine {
  static readonly type = '@mortgageApplication.RefreshDecisionEngine';

  constructor(public applicationId: string) {}
}

export class ApplicationPurposeChanged {
  static readonly type = '@mortgageApplication.ApplicationPurposeChanged';
  constructor() {}
}

export class ApplicationUpdateLoanNumber {
  static readonly type = '@mortgageApplication.updateApplicationLoanNumber';
  constructor(public expandedLoanNumber: Partial<ExpandedLoanNumber>) {}
}

export class MortgageRefreshRateHoldDate {
  static readonly type = '@mortgageApplication.mortgageRefreshRateHoldDate';
  constructor(public mortgageId: string) {}
}

export class MarkApplicationAsCommitted {
  static readonly type = '@mortgageApplication.markApplicationAsCommitted';
  constructor(public applicationId: string) {}
}

export class MarkApplicationAsAwaitingDocs {
  static readonly type = '@mortgageApplication.markApplicationAsAwaitingDocs';
  constructor(public applicationId: string) {}
}

export class PatchApplicationStatus {
  static readonly type = '@mortgageApplication.patchApplicationStatus';
  constructor(public applicationStatus: ApplicationStatus) {}
}
