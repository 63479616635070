import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { DrawSchedule } from '../../shared';

export interface HardCostToCompletePayload {
  id: string;
  applicationId: string;
  hardCostCompletedToDate: number;
}

export interface DrawDatePayload {
  id: string;
  applicationId: string;
  drawDate: string;
}

export interface availableAdvanceAmountPayload {
  id: string;
  applicationId: string;
  availableAdvanceAmount: number;
}

@Injectable({
  providedIn: 'root',
})
export class ConstructionDrawScheduleService {
  constructor(private http: HttpClient) {}

  getConstructionSchedules(applicationId: string) {
    return this.http.get<DrawSchedule[]>(
      `${environment.api_url}/constructionSchedules?applicationId=${applicationId}`,
    );
  }

  postConstructionSchedule(drawSchedule: Partial<DrawSchedule>) {
    return this.http.post<DrawSchedule>(
      `${environment.api_url}/constructionSchedules`,
      drawSchedule,
    );
  }

  deleteConstructionSchedule(drawScheduleId: string) {
    return this.http.delete<DrawSchedule[]>(
      `${environment.api_url}/constructionSchedules/${drawScheduleId}`,
    );
  }

  updateHardCostToComplete(hardCostToCompletePayload: HardCostToCompletePayload) {
    return this.http.put<DrawSchedule[]>(
      `${environment.api_url}/constructionSchedules/hardCostToComplete`,
      hardCostToCompletePayload,
    );
  }

  updateDrawDate(drawDatePayload: DrawDatePayload) {
    return this.http.put<DrawSchedule[]>(
      `${environment.api_url}/constructionSchedules/drawDate`,
      drawDatePayload,
    );
  }

  updateAvailableAdvanceAmount(availableAdvanceAmountPayload: availableAdvanceAmountPayload) {
    return this.http.put<DrawSchedule[]>(
      `${environment.api_url}/constructionSchedules/availableAdvanceAmount`,
      availableAdvanceAmountPayload,
    );
  }

  recalculateDrawSchedule(applicationId: string) {
    return this.http.put<DrawSchedule[]>(
      `${environment.api_url}/constructionSchedules/recalculateDrawSchedules`,
      { applicationId },
    );
  }
}
