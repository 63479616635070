import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EzidoxDocumentTemplate } from 'src/app/shared';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EzidoxService {
  constructor(private http: HttpClient) {}

  createEzidoxApplication(applicationId: string, applicationFile: Blob): Observable<void> {
    const formData = new FormData();
    formData.append('application', applicationFile);
    const params = new HttpParams().set('target', 'Application');
    return this.http.post<void>(
      `${environment.api_url}/applications/ezidox/${applicationId}`,
      formData,
      {
        params,
      },
    );
  }

  updateEzidoxApplication(applicationId: string): Observable<void> {
    return this.http.put<void>(`${environment.api_url}/applications/ezidox/${applicationId}`, {});
  }

  getApplicationEzidoxEmbeddingUrl(
    applicationId: string,
    viewId: string,
    isIFrame: boolean,
    documentId?: string,
  ): Observable<{ url: string }> {
    let params = new HttpParams();

    params = params.append('isIFrame', isIFrame.toString());
    return this.http.get<{ url: string }>(
      `${environment.api_url}/ezidox/embed/application/${viewId}/${applicationId}${
        documentId ? '?documentId=' + documentId : ''
      }`,
      {
        params,
      },
    );
  }

  getTemplateSets(): Observable<EzidoxDocumentTemplate[]> {
    return this.http.get<EzidoxDocumentTemplate[]>(
      `${environment.api_url}/applications/ezidox/templateset`,
    );
  }
}
