import {
  Applicant,
  Income,
  IncomePeriod,
  ApplicationOtherIncome,
  Property,
  RentalOffsetType,
  Mortgage,
  MortgageType,
  assertUnreachable,
  PayoffPaydownType,
} from '@fundmoreai/models';
import { FundmoreCalculator } from '..';
import {
  ComputeCostToCarryPropertyInput,
  computeCostToCarryForTDS,
} from './cost-to-carry.calculator';

export type ComputeTotalIncomeWithRentalOffsetPropertyInput = Pick<
  Property,
  | 'id'
  | 'annualTaxes'
  | 'condoFees'
  | 'condoFeesIncludeHeating'
  | 'condoFeesPercentInCalculation'
  | 'heatingCost'
  | 'includeExpensesInTDS'
  | 'includeAnnualTaxesInTDS'
  | 'includeCondoFeesInTDS'
  | 'includeHeatingCostInTDS'
  | 'rpeRentalOffsetOption'
  | 'rpeMonthlyRentalIncome'
  | 'rpeOffset'
  | 'rpeGeneralExpenses'
  | 'rpeHydro'
  | 'rpeInsurance'
  | 'rpeInterestCharges'
  | 'rpeManagementExpenses'
  | 'rpeRepairs'
>;
export type computeTotalIncomeWithRentalOffsetMortgageInput = Pick<
  Mortgage,
  'type' | 'monthlyPayment' | 'propertyId' | 'payoffPaydown'
>;

export function computeTotalMonthlyIncomeWithPropertyRentalIncome(
  totalIncome: number,
  costToCarry: number,
  primaryProperty: ComputeTotalIncomeWithRentalOffsetPropertyInput | undefined,
  otherProperties: ComputeTotalIncomeWithRentalOffsetPropertyInput[],
  existingMortgages: computeTotalIncomeWithRentalOffsetMortgageInput[],
): number {
  let newTotalIncome = totalIncome;

  newTotalIncome += getPrimaryPropertyRentalMonthlyIncome(
    primaryProperty,
    existingMortgages.filter((mortgage) => mortgage.propertyId === primaryProperty.id),
    costToCarry,
  );

  for (const otherProperty of otherProperties) {
    newTotalIncome += getOtherPropertyRentalMonthlyIncome(
      otherProperty,
      existingMortgages.filter((mortgage) => mortgage.propertyId === otherProperty.id),
    );
  }

  return newTotalIncome;
}

export function computeTotalMonthlyIncomeWithoutPropertyRentalIncome(
  applicants: Applicant[],
  otherIncomes: ApplicationOtherIncome[],
) {
  const allIncomes: FundmoreCalculator.ComputeTotalIncomeInput[] = [];

  applicants.forEach((applicant) => {
    const mappedJobs: FundmoreCalculator.ComputeTotalIncomeInput[] = applicant.Jobs.filter(
      (x) => x.isCurrent && !x.unableToVerify,
    ).map((j) => ({
      amount: j.annualIncome,
      period: IncomePeriod.YEARLY,
      toggled: false,
    }));
    allIncomes.push(...mappedJobs);
  });
  const mappedOtherIncome: FundmoreCalculator.ComputeTotalIncomeInput[] = otherIncomes
    .filter((x) => x.isCurrent && !x.unableToVerify)
    .map((otherIncome) => ({
      amount: otherIncome.amount,
      period: otherIncome.period,
      toggled: false,
    }));
  allIncomes.push(...mappedOtherIncome);

  const monthlyTotalIncome = computeTotalIncome(allIncomes, IncomePeriod.MONTHLY);
  return monthlyTotalIncome;
}

export type ComputeTotalIncomeInput = Pick<Income, 'amount' | 'period' | 'toggled'>;

export function computeTotalIncome(
  allIncomes: ComputeTotalIncomeInput[],
  incomeFrequency: IncomePeriod,
): number {
  return allIncomes.reduce((sum, income) => {
    if (!income.amount) {
      return sum;
    }

    const yearlyIncomeAmount = getYearlyIncomeAmount(income.amount, income.period);
    const incomeAmount = getIncomeAmountByFrequencyFromYearlyAmount(
      yearlyIncomeAmount,
      incomeFrequency,
    );

    return sum + (!income.toggled ? incomeAmount : 0.0);
  }, 0.0);
}

export function getIncomeAmountFromAnnualIncome(
  annualIncome: number,
  incomePaymentFrequency: IncomePeriod,
) {
  let amount = 0;

  switch (incomePaymentFrequency) {
    case IncomePeriod.BI_WEEKLY:
      amount = annualIncome / 26;
      break;

    case IncomePeriod.MONTHLY:
      amount = annualIncome / 12;
      break;

    case IncomePeriod.SEMI_MONTHLY:
      amount = annualIncome / 24;
      break;

    case IncomePeriod.WEEKLY:
      amount = annualIncome / 52;
      break;

    case IncomePeriod.YEARLY:
      amount = annualIncome;
      break;

    default:
      assertUnreachable(incomePaymentFrequency);
      break;
  }

  return amount;
}

export function getYearlyIncomeAmount(incomeAmount: number, incomeFrequency: IncomePeriod) {
  let yearlyIncomeAmount = 0;

  switch (incomeFrequency) {
    case IncomePeriod.BI_WEEKLY:
      yearlyIncomeAmount = incomeAmount * 26;
      break;

    case IncomePeriod.MONTHLY:
      yearlyIncomeAmount = incomeAmount * 12;
      break;

    case IncomePeriod.SEMI_MONTHLY:
      yearlyIncomeAmount = incomeAmount * 24;
      break;

    case IncomePeriod.WEEKLY:
      yearlyIncomeAmount = incomeAmount * 52;
      break;

    case IncomePeriod.YEARLY:
      yearlyIncomeAmount = incomeAmount;
      break;

    default:
      assertUnreachable(incomeFrequency);
      break;
  }

  return yearlyIncomeAmount;
}

export function getIncomeAmountByFrequencyFromYearlyAmount(
  yearlyIncomeAmount: number,
  incomeFrequency: IncomePeriod,
) {
  let incomeAmount = 0;

  switch (incomeFrequency) {
    case IncomePeriod.BI_WEEKLY:
      incomeAmount = yearlyIncomeAmount / 26;
      break;

    case IncomePeriod.MONTHLY:
      incomeAmount = yearlyIncomeAmount / 12;
      break;

    case IncomePeriod.SEMI_MONTHLY:
      incomeAmount = yearlyIncomeAmount / 24;
      break;

    case IncomePeriod.WEEKLY:
      incomeAmount = yearlyIncomeAmount / 52;
      break;

    case IncomePeriod.YEARLY:
      incomeAmount = yearlyIncomeAmount;
      break;

    default:
      assertUnreachable(incomeFrequency);
      break;
  }

  return incomeAmount;
}

type ComputePropertyRentalIncomeInput = Pick<
  Property,
  | 'id'
  | 'annualTaxes'
  | 'condoFees'
  | 'condoFeesIncludeHeating'
  | 'condoFeesPercentInCalculation'
  | 'heatingCost'
  | 'includeExpensesInTDS'
  | 'includeAnnualTaxesInTDS'
  | 'includeCondoFeesInTDS'
  | 'includeHeatingCostInTDS'
  | 'rpeRentalOffsetOption'
  | 'rpeMonthlyRentalIncome'
  | 'rpeOffset'
  | 'rpeGeneralExpenses'
  | 'rpeHydro'
  | 'rpeInsurance'
  | 'rpeInterestCharges'
  | 'rpeManagementExpenses'
  | 'rpeRepairs'
>;

export function getPrimaryPropertyRentalMonthlyIncome(
  property: ComputePropertyRentalIncomeInput,
  propertyMortgages:
    | Pick<Mortgage, 'type' | 'payoffPaydown' | 'monthlyPayment'>[]
    | null
    | undefined,
  primaryPropertyCostToCarry?: number,
): number {
  const rentalValueWithOffset = FundmoreCalculator.computeRentalOffset(property);

  if (!rentalValueWithOffset) {
    return 0;
  }

  switch (property.rpeRentalOffsetOption) {
    case RentalOffsetType.ADD_PERCENTAGE_TO_GROSS_INCOME: {
      // If rental offset type is add percentage to gross income, simply set the rental offset as an extra income
      return rentalValueWithOffset;
    }

    case RentalOffsetType.REDUCE_RENTAL_EXPENSES: {
      const totalMortgagePayments = propertyMortgages
        ?.filter((x) => x.type === MortgageType.EXISTING)
        .reduce(
          (totalMonthlyPayment, mortgage) => totalMonthlyPayment + (mortgage?.monthlyPayment ?? 0),
          0,
        );
      const rentalToCostDiff =
        rentalValueWithOffset - primaryPropertyCostToCarry - totalMortgagePayments;

      return rentalToCostDiff > 0 ? rentalToCostDiff : 0;
    }

    case RentalOffsetType.NONE:
      // no property rental in case of RentalOffsetType.NONE
      return 0;

    default:
      assertUnreachable(property.rpeRentalOffsetOption);
      return 0;
  }
}

export function getOtherPropertyRentalMonthlyIncome(
  property: ComputePropertyRentalIncomeInput,
  propertyMortgages:
    | Pick<Mortgage, 'type' | 'payoffPaydown' | 'monthlyPayment'>[]
    | null
    | undefined,
): number {
  const rentalValueWithOffset = FundmoreCalculator.computeRentalOffset(property);

  if (!rentalValueWithOffset) {
    return 0;
  }

  switch (property.rpeRentalOffsetOption) {
    case RentalOffsetType.ADD_PERCENTAGE_TO_GROSS_INCOME: {
      // If rental offset type is add percentage to gross income, simply set the rental offset as an extra income
      return rentalValueWithOffset;
    }

    case RentalOffsetType.REDUCE_RENTAL_EXPENSES: {
      // If rental offset type is reduce rental cost and add ballance to income
      // then we subtract first from the rental offset the cost of rental before setting it as the income
      const rentalOffsetDiff = computeRentalOffsetByReducingExpenses(
        propertyMortgages,
        property,
        rentalValueWithOffset,
      );

      return rentalOffsetDiff > 0 ? rentalOffsetDiff : 0;
    }

    case RentalOffsetType.NONE:
      // no property rental in case of RentalOffsetType.NONE
      return 0;

    default:
      assertUnreachable(property.rpeRentalOffsetOption);
      return 0;
  }
}
export function computeRentalOffsetByReducingExpenses(
  propertyMortgages: Pick<Mortgage, 'type' | 'monthlyPayment' | 'payoffPaydown'>[],
  property: ComputeCostToCarryPropertyInput,
  rentalValueWithOffset: number,
) {
  const totalMortgagePayments = propertyMortgages
    ?.filter((x) => x.type === MortgageType.EXISTING && x.payoffPaydown === PayoffPaydownType.NONE)
    .reduce(
      (totalMonthlyPayment, mortgage) => totalMonthlyPayment + (mortgage?.monthlyPayment ?? 0),
      0,
    );
  const costOfCarry = computeCostToCarryForTDS(property, totalMortgagePayments);
  return rentalValueWithOffset - costOfCarry;
}
