import { IncomePeriod } from './enums';
import { ApplicantOtherIncome } from './otherIncome';

export interface Income {
  id: string;
  type: string;
  description: string;
  amount: number;
  toggled: boolean;
  entityType: string;
  applicantId?: string;
  expanded: boolean;
  period: IncomePeriod;
  ApplicantOtherIncomes?: ApplicantOtherIncome[];
}

export enum IncomeType {
  EMPLOYED = 'EMPLOYED',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  OTHER = 'OTHER',
  CANADA_CHILD_BENEFIT = 'CANADA_CHILD_BENEFIT',
  CANADA_PENSION_PLAN = 'CANADA_PENSION_PLAN',
  CHILD_SUPPORT = 'CHILD_SUPPORT',
  DISABILITY = 'DISABILITY',
  FOREIGN = 'FOREIGN',
  INVESTMENT = 'INVESTMENT',
  OLD_AGE_SECURITY = 'OLD_AGE_SECURITY',
  PENSION = 'PENSION',
  RENTAL = 'RENTAL',
  SPOUSE_SUPPORT = 'SPOUSE_SUPPORT',
  SURVIVOR_BENEFIT_PENSION = 'SURVIVOR_BENEFIT_PENSION',
  UNEMPLOYED = 'UNEMPLOYED',
  BONUS = 'BONUS',
  OVERTIME = 'OVERTIME',
  COMMISSION = 'COMMISSION',
  OTHER_NON_EMPLOYED = 'OTHER_NON_EMPLOYED',
  TRUST = 'TRUST',
}
