import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { RecognitionService } from './recognition.service';
import { DocumentAnalysisKey } from './enums';
import { Applicant } from '../../../shared';
import { tap, catchError, switchMap, finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AnalysedMortgageApplicationModel, AnalysisModel, NewTable } from './recognitionModel';
import { LocalIdHandlerService } from '../../../shared/services/local-id-handler.service';
import { FilogixDealService } from './filogixDeal.service';
import { MortgageApplicationService } from '../../../portal/mortgage-application.service';
import {
  CompoundPeriod,
  DownPaymentKey,
  EmploymentType,
  FinancialAssetKey,
  FinancialLiabilityKey,
  IncomePeriod,
  Job,
  JobKey,
  Mortgage,
  MortgageKey,
  MortgagePosition,
  PaymentFrequency,
  Property,
  PropertyKey,
  PropertyTypes,
  RepaymentType,
  OccupationType,
  SupportedCustomEntity,
  GarageSize,
  RateType,
  TermType,
  LoanType,
  DwellingStyle,
  DwellingType,
  OccupancyType,
  HeatingType,
  GarageType,
  TaxesPaidByType,
  InsurerType,
  IncomeType,
  PropertyOwnerKey,
  MaritalType,
  RentalOffsetType,
} from '@fundmoreai/models';
import { FieldMetadataState } from 'src/app/shared/custom-fields/field-metadata.state';
import { findKeyForValue, handleTableDataMapping } from 'src/app/shared/functions';
import { fromDatePickerToShortISODate } from '@fundmoreai/helpers';
import { SetCustomers } from './manual-application/manual-application.actions';
import {
  AnalyseMortgageApplication,
  ParseDeal,
  PostAnalysisProcessing,
  PostAnalysisProcessingError,
  ResetUploadState,
  SetAnalysedApplication,
  SetApplicationFile,
  SetApplicationPurpose,
  SetClonedApplicationId,
  SetDealStatus,
  SetFileStatus,
  SetIsAnalysing,
  SetIsSelected,
  SetMortgageClassification,
  SetMortgageClassificationOptions,
  SetPrimaryPropertyType,
  SetPrimaryZoningType,
  SetStepIndex,
  UploadComponentStateModel,
} from './upload.actions';

export enum ButtonOption {
  Default = 'DEFAULT',
  UploadFile = 'UPLOAD',
  SelectDeal = 'DOLPHIN',
  CreateManually = 'MANUAL',
  Copy = 'COPY',
  Merge = 'MERGE',
}

const defaultState: UploadComponentStateModel = {
  isAnalysing: false,
  isNew: true,
  applicationFile: {} as Blob,
  isSelected: ButtonOption.Default,
  fileStatus: $localize`No chosen file`,
  dealStatus: $localize`No chosen deal`,
  stepIndex: 1,
  analysedApplication: {
    primaryApplicant: {} as Applicant,
    otherApplicants: [],
  } as Partial<AnalysedMortgageApplicationModel>,
  clonedApplicationId: '',
  mortgageClassificationOptions: undefined,
};

@State<UploadComponentStateModel>({
  name: 'uploadComponent',
  defaults: {
    ...defaultState,
  },
})
@Injectable()
export class UploadComponentState {
  constructor(
    private recognitionServices: RecognitionService,
    public filogixDealService: FilogixDealService,
    private mortgageApplicationServices: MortgageApplicationService,
    private store: Store,
  ) {}

  @Selector() static applicationFile(state: UploadComponentStateModel) {
    return state.applicationFile;
  }

  @Selector() static analysedApplication(state: UploadComponentStateModel) {
    return state.analysedApplication;
  }

  @Selector() static isAnalysing(state: UploadComponentStateModel) {
    return state.isAnalysing;
  }

  @Selector() static stepIndex(state: UploadComponentStateModel) {
    return state.stepIndex;
  }

  @Selector() static mortgageClassificationOptions(state: UploadComponentStateModel) {
    return state.mortgageClassificationOptions;
  }

  @Selector() static fileStatus(state: UploadComponentStateModel) {
    return state.fileStatus;
  }

  @Selector() static clonedApplicationId(state: UploadComponentStateModel) {
    return state.clonedApplicationId;
  }

  @Selector() static error(state: UploadComponentStateModel) {
    return state.error;
  }

  @Selector() static dealStatus(state: UploadComponentStateModel) {
    return state.dealStatus;
  }

  @Selector() static isSelected(state: UploadComponentStateModel) {
    return state.isSelected;
  }

  @Selector() static isNew(state: UploadComponentStateModel) {
    return state.isNew;
  }

  @Action(SetAnalysedApplication)
  public setAnalysedApplication(
    ctx: StateContext<UploadComponentStateModel>,
    { analysedApplication }: SetAnalysedApplication,
  ): void {
    ctx.patchState({
      isNew: false,
      analysedApplication,
    });
  }

  @Action(SetApplicationPurpose)
  public setApplicationPurpose(
    ctx: StateContext<UploadComponentStateModel>,
    { applicationPurpose }: SetApplicationPurpose,
  ) {
    return ctx.dispatch(new SetMortgageClassificationOptions(applicationPurpose)).pipe(
      tap(() => {
        const state = ctx.getState();
        ctx.patchState({
          isNew: false,
          analysedApplication: {
            ...state.analysedApplication,
            purpose: applicationPurpose,
            mortgageClassification: undefined,
          },
        });
      }),
    );
  }

  @Action(SetPrimaryPropertyType)
  public setPrimaryPropertyType(
    ctx: StateContext<UploadComponentStateModel>,
    { propertyType }: SetPrimaryPropertyType,
  ): void {
    const state = ctx.getState();
    let updatedAnalysedApplication = state.analysedApplication;
    let firstProperty =
      updatedAnalysedApplication &&
      updatedAnalysedApplication.properties &&
      updatedAnalysedApplication.properties[0];
    if (!firstProperty) {
      return;
    }
    updatedAnalysedApplication = {
      ...updatedAnalysedApplication,
      properties: [...(updatedAnalysedApplication.properties ?? [])],
    };
    firstProperty = { ...firstProperty, propertyType };
    ctx.patchState({
      analysedApplication: {
        ...updatedAnalysedApplication,
      },
    });
  }

  @Action(SetPrimaryZoningType)
  public setPrimaryZoningType(
    ctx: StateContext<UploadComponentStateModel>,
    { zoningType }: SetPrimaryZoningType,
  ): void {
    const state = ctx.getState();
    let updatedAnalysedApplication = state.analysedApplication;
    let firstProperty =
      updatedAnalysedApplication &&
      updatedAnalysedApplication.properties &&
      updatedAnalysedApplication.properties[0];
    if (!firstProperty) {
      return;
    }
    updatedAnalysedApplication = {
      ...updatedAnalysedApplication,
      properties: [...(updatedAnalysedApplication.properties ?? [])],
    };
    firstProperty = { ...firstProperty, zoningType };
    ctx.patchState({
      analysedApplication: {
        ...updatedAnalysedApplication,
      },
    });
  }

  @Action(SetMortgageClassificationOptions)
  public setMortgageClassificationOptions(
    ctx: StateContext<UploadComponentStateModel>,
    { applicationPurpose }: SetMortgageClassificationOptions,
  ): void {
    const mortgageClassificationOptions =
      this.mortgageApplicationServices.getMortgageClassificationOption(applicationPurpose);
    ctx.patchState({
      mortgageClassificationOptions,
    });
  }

  @Action(SetMortgageClassification)
  public setMortgageClassification(
    ctx: StateContext<UploadComponentStateModel>,
    { mortgageClassification }: SetMortgageClassification,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      analysedApplication: {
        ...state.analysedApplication,
        mortgageClassification,
      },
    });
  }

  @Action(SetFileStatus)
  public setFileStatus(
    ctx: StateContext<UploadComponentStateModel>,
    { fileStatus }: SetFileStatus,
  ): void {
    ctx.patchState({
      fileStatus,
    });
  }

  @Action(SetIsAnalysing)
  public setIsAnalysing(
    ctx: StateContext<UploadComponentStateModel>,
    { analysingStatus }: SetIsAnalysing,
  ): void {
    ctx.patchState({
      isAnalysing: analysingStatus,
    });
  }

  @Action(SetClonedApplicationId)
  public setClonedApplicationId(
    ctx: StateContext<UploadComponentStateModel>,
    { value }: SetClonedApplicationId,
  ): void {
    ctx.patchState({
      clonedApplicationId: value,
    });
  }

  @Action(SetStepIndex)
  public setStepIndex(
    ctx: StateContext<UploadComponentStateModel>,
    { stepIndex }: SetStepIndex,
  ): void {
    ctx.patchState({
      stepIndex,
    });
  }

  @Action(SetApplicationFile)
  public setApplicationFileAction(
    ctx: StateContext<UploadComponentStateModel>,
    action: SetApplicationFile,
  ): void {
    ctx.patchState({
      applicationFile: action.applicationFile,
      error: '',
    });
  }

  @Action(SetIsSelected)
  public setIsSelected(
    ctx: StateContext<UploadComponentStateModel>,
    { fileUploadingStatus }: SetIsSelected,
  ): void {
    ctx.patchState({
      isSelected: fileUploadingStatus,
    });
  }

  @Action(SetDealStatus)
  public setDealStatus(
    ctx: StateContext<UploadComponentStateModel>,
    { primaryApplicantFilogix }: SetDealStatus,
  ): void {
    const [primaryApplicant] = primaryApplicantFilogix.applicants;
    ctx.patchState({
      dealStatus: primaryApplicant.firstName + ' ' + primaryApplicant.lastName,
      stepIndex: 2,
    });
  }

  @Action(PostAnalysisProcessing)
  public postAnalysisProcessing(
    ctx: StateContext<UploadComponentStateModel>,
    { analysedApplication }: PostAnalysisProcessing,
  ) {
    const { model } = this.mapModelValues(analysedApplication);
    const primaryApplicant = Object.assign({}, model.primaryApplicant, {
      isPrimary: true,
      id: model.primaryApplicant?.id ?? LocalIdHandlerService.generateTempIdFromNumber(0),
      ApplicantProperties: undefined, // need to erase this because upload component does not
      // support applicant-property relationship. This is because the upload component
      // is using temp ids.
    });
    const applicants = [primaryApplicant];
    for (let index = 0; index < model.otherApplicants?.length; index += 1) {
      const applicant = Object.assign({}, model.otherApplicants[index], {
        isPrimary: false,
        id:
          model.otherApplicants[index].id ??
          LocalIdHandlerService.generateTempIdFromNumber(index + 1),
        ApplicantProperties: undefined,
      });
      applicants.push(applicant);
    }
    return ctx.dispatch(new SetCustomers(applicants)).pipe(
      tap(() => {
        ctx.patchState({
          analysedApplication: model,
          stepIndex: 2,
          isAnalysing: false,
        });
      }),
    );
  }

  @Action(PostAnalysisProcessingError)
  public postAnalysisProcessingError(
    ctx: StateContext<UploadComponentStateModel>,
    { error }: PostAnalysisProcessingError,
  ): void {
    const NOT_ANALYZE = $localize`Could not analyze this document!`;

    ctx.patchState({
      error: `${NOT_ANALYZE} ${error?.error?.message?.name}`,
      isAnalysing: false,
    });
  }

  @Action(AnalyseMortgageApplication)
  public analyseMortgageApplication(ctx: StateContext<UploadComponentStateModel>) {
    const state = ctx.getState();
    return this.recognitionServices
      .analyseMortgageApplication(state.applicationFile, DocumentAnalysisKey.APPLICATION)
      .pipe(
        switchMap((analysedApplication) => {
          ctx.patchState({});
          return ctx.dispatch(new PostAnalysisProcessing(analysedApplication));
        }),
        catchError((error) => {
          return ctx
            .dispatch(new PostAnalysisProcessingError(error))
            .pipe(finalize(() => throwError(() => new Error(error))));
        }),
      );
  }

  @Action(ParseDeal)
  public parseDeal(ctx: StateContext<UploadComponentStateModel>, { deal }: ParseDeal) {
    return this.filogixDealService.parseDeal(deal.id).pipe(
      switchMap((parsedApplication) => {
        parsedApplication.model.filogixDealId = deal.id;
        return ctx.dispatch(new PostAnalysisProcessing(parsedApplication));
      }),
      catchError((error) => {
        return ctx
          .dispatch(new PostAnalysisProcessingError(error))
          .pipe(finalize(() => throwError(() => new Error(error))));
      }),
    );
  }

  @Action(ResetUploadState) resetUploadState(ctx: StateContext<UploadComponentStateModel>) {
    ctx.patchState({
      ...defaultState,
    });
  }

  private mapModelValues(applicationData: AnalysisModel): AnalysisModel {
    const mappedModel: AnalysedMortgageApplicationModel = Object.assign({}, applicationData.model);

    // map down payment
    if (mappedModel.downPayments && mappedModel.downPayments.length) {
      mappedModel.downPayments = mappedModel.downPayments.map(this.mapDownPayments.bind(this));
    }

    // map financial asset
    if (mappedModel.financialAssets && mappedModel.financialAssets.length) {
      mappedModel.financialAssets = mappedModel.financialAssets.map(
        this.mapFinancialAssets.bind(this),
      );
    }

    // map financial liability
    if (mappedModel.liabilities && mappedModel.liabilities.length) {
      mappedModel.liabilities = mappedModel.liabilities.map(
        this.mapFinancialLiabilities.bind(this),
      );
    }

    // map property
    if (mappedModel.properties && mappedModel.properties.length) {
      mappedModel.properties = mappedModel.properties.map(this.mapProperty.bind(this));
    }

    // map applicant
    if (
      mappedModel.primaryApplicant ||
      (mappedModel.otherApplicants && mappedModel.otherApplicants.length)
    ) {
      mappedModel.primaryApplicant = this.mapApplicants(mappedModel.primaryApplicant);

      if (mappedModel.otherApplicants && mappedModel.otherApplicants.length) {
        mappedModel.otherApplicants = mappedModel.otherApplicants.map(
          this.mapApplicants.bind(this),
        );
      }
    }

    //map requestedMortgage
    if (mappedModel.requestedMortgage) {
      mappedModel.requestedMortgage = this.mapMortgage(mappedModel.requestedMortgage);
    }

    //map existingMortgage
    if (mappedModel.existingMortgage && mappedModel.existingMortgage.length) {
      mappedModel.existingMortgage = mappedModel.existingMortgage.map(this.mapMortgage.bind(this));
    }

    return { ...applicationData, model: mappedModel };
  }

  private mapMortgage(mortgage: Mortgage) {
    const updatedMortgage = Object.assign({}, mortgage);

    const loanTypeOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.MORTGAGE,
        MortgageKey.LOAN_TYPE,
      ),
    );
    const mortgageTypeOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.MORTGAGE,
        MortgageKey.MORTGAGE_TYPE,
      ),
    );
    const paymentFrequencyOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.MORTGAGE,
        MortgageKey.PAYMENT_FREQUENCY,
      ),
    );
    const repaymentTypeOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.MORTGAGE,
        MortgageKey.REPAYMENT_TYPE,
      ),
    );
    const compoundingOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.MORTGAGE,
        MortgageKey.COMPOUNDING,
      ),
    );
    const rateTypeOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.MORTGAGE,
        MortgageKey.RATE_TYPE,
      ),
    );
    const termTypeOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.MORTGAGE,
        MortgageKey.TERM_TYPE,
      ),
    );
    const insurerOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.MORTGAGE,
        MortgageKey.INSURER,
      ),
    );
    updatedMortgage.loanType =
      (findKeyForValue(
        updatedMortgage.loanType?.toString() ?? null,
        loanTypeOptions,
      ) as LoanType) ?? updatedMortgage.loanType;
    updatedMortgage.mortgageType =
      (findKeyForValue(
        updatedMortgage.mortgageType?.toString() ?? null,
        mortgageTypeOptions,
      ) as MortgagePosition) ?? updatedMortgage.mortgageType;
    updatedMortgage.paymentFrequency =
      (findKeyForValue(
        updatedMortgage.paymentFrequency?.toString() ?? null,
        paymentFrequencyOptions,
      ) as PaymentFrequency) ?? updatedMortgage.paymentFrequency;
    updatedMortgage.repaymentType =
      (findKeyForValue(
        updatedMortgage.repaymentType?.toString() ?? null,
        repaymentTypeOptions,
      ) as RepaymentType) ?? updatedMortgage.repaymentType;
    updatedMortgage.compounding =
      (findKeyForValue(
        updatedMortgage.compounding?.toString() ?? null,
        compoundingOptions,
      ) as CompoundPeriod) ?? updatedMortgage.compounding;
    updatedMortgage.rateType =
      (findKeyForValue(
        updatedMortgage.rateType?.toString() ?? null,
        rateTypeOptions,
      ) as RateType) ?? updatedMortgage.rateType;
    updatedMortgage.termType =
      (findKeyForValue(
        updatedMortgage.termType?.toString() ?? null,
        termTypeOptions,
      ) as TermType) ?? updatedMortgage.termType;
    updatedMortgage.insurer =
      (findKeyForValue(
        updatedMortgage.insurer?.toString() ?? null,
        insurerOptions,
      ) as InsurerType) ?? updatedMortgage.insurer;
    updatedMortgage.closingDate = updatedMortgage.closingDate
      ? fromDatePickerToShortISODate(new Date(updatedMortgage.closingDate)) || null
      : null;

    return updatedMortgage;
  }

  private mapApplicants(applicant: Applicant) {
    const updatedApplicant = Object.assign({}, applicant);

    const maritalStatusOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.PROPERTY_OWNER,
        PropertyOwnerKey.MARTIAL_STATUS,
      ),
    );

    updatedApplicant.dateOfBirth = updatedApplicant.dateOfBirth
      ? fromDatePickerToShortISODate(new Date(updatedApplicant.dateOfBirth))
      : undefined;

    updatedApplicant.maritalStatus =
      (findKeyForValue(updatedApplicant.maritalStatus, maritalStatusOptions) as MaritalType) ??
      updatedApplicant.maritalStatus;

    if (updatedApplicant.Jobs && updatedApplicant.Jobs.length) {
      updatedApplicant.Jobs = updatedApplicant.Jobs.map(this.mapJob.bind(this));
    }

    return updatedApplicant;
  }

  private mapJob(job: Job) {
    const occupationOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(SupportedCustomEntity.JOB, JobKey.OCCUPATION),
    );
    const employmentTypeOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(SupportedCustomEntity.JOB, JobKey.EMPLOYMENT_TYPE),
    );
    const incomeTypeOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(SupportedCustomEntity.JOB, JobKey.TYPE),
    );
    const incomePeriodOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.JOB,
        JobKey.INCOME_PAYMENT_FREQUENCY,
      ),
    );
    const updatedJob = Object.assign({}, job);

    updatedJob.occupation =
      (findKeyForValue(
        updatedJob.occupation?.toString() ?? null,
        occupationOptions,
      ) as OccupationType) ?? updatedJob.occupation;
    updatedJob.employmentType =
      (findKeyForValue(
        updatedJob.employmentType?.toString() ?? null,
        employmentTypeOptions,
      ) as EmploymentType) ?? updatedJob.employmentType;
    updatedJob.type =
      (findKeyForValue(updatedJob.type?.toString() ?? null, incomeTypeOptions) as IncomeType) ??
      updatedJob.type;
    updatedJob.incomePaymentFrequency =
      (findKeyForValue(
        updatedJob.incomePaymentFrequency?.toString() ?? IncomePeriod.YEARLY,
        incomePeriodOptions,
      ) as IncomePeriod) ?? updatedJob.incomePaymentFrequency;

    return updatedJob;
  }

  private mapProperty(property: Property) {
    const dwellingStyleOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.PROPERTY,
        PropertyKey.DWELLING_STYLE,
      ),
    );
    const dwellingTypeOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.PROPERTY,
        PropertyKey.DWELLING_TYPE,
      ),
    );
    const occupancyOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.PROPERTY,
        PropertyKey.OCCUPANCY,
      ),
    );
    const heatingTypeOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.PROPERTY,
        PropertyKey.HEATING_TYPE,
      ),
    );
    const garageTypeOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.PROPERTY,
        PropertyKey.GARAGE_TYPE,
      ),
    );
    const garageSizeOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.PROPERTY,
        PropertyKey.GARAGE_SIZE,
      ),
    );
    const TaxesPaidByOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.PROPERTY,
        PropertyKey.TAXES_PAID_BY,
      ),
    );

    const propertyTypeOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.PROPERTY,
        PropertyKey.PROPERTY_TYPE,
      ),
    );

    const rentalOffsetOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.PROPERTY,
        PropertyKey.RPE_RENTAL_OFFSET_OPTION,
      ),
    );

    const propertyToUpdate = Object.assign({}, property);

    propertyToUpdate.dwellingStyle =
      (findKeyForValue(propertyToUpdate.dwellingStyle, dwellingStyleOptions) as DwellingStyle) ??
      propertyToUpdate.dwellingStyle;
    propertyToUpdate.dwellingType =
      (findKeyForValue(propertyToUpdate.dwellingType, dwellingTypeOptions) as DwellingType) ??
      propertyToUpdate.dwellingType;
    propertyToUpdate.occupancy =
      (findKeyForValue(propertyToUpdate.occupancy, occupancyOptions) as OccupancyType) ??
      propertyToUpdate.occupancy;
    propertyToUpdate.heatingType =
      (findKeyForValue(propertyToUpdate.heatingType, heatingTypeOptions) as HeatingType) ??
      propertyToUpdate.heatingType;
    propertyToUpdate.garageType =
      (findKeyForValue(propertyToUpdate.garageType, garageTypeOptions) as GarageType) ??
      propertyToUpdate.garageType;
    propertyToUpdate.taxesPaidBy =
      (findKeyForValue(propertyToUpdate.taxesPaidBy, TaxesPaidByOptions) as TaxesPaidByType) ??
      propertyToUpdate.taxesPaidBy;
    propertyToUpdate.garageSize =
      (findKeyForValue(propertyToUpdate.garageSize, garageSizeOptions) as GarageSize) ??
      propertyToUpdate.garageSize;
    propertyToUpdate.propertyType =
      (findKeyForValue(propertyToUpdate.propertyType, propertyTypeOptions) as PropertyTypes) ??
      propertyToUpdate.propertyType;
    propertyToUpdate.rpeRentalOffsetOption =
      (findKeyForValue(
        propertyToUpdate.rpeRentalOffsetOption,
        rentalOffsetOptions,
      ) as RentalOffsetType) ?? propertyToUpdate.rpeRentalOffsetOption;

    return propertyToUpdate;
  }

  private mapFinancialAssets(assets: NewTable) {
    const ASSET_INDEX = 0;

    const financialAssetOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.FINANCIAL_ASSET,
        FinancialAssetKey.ASSET,
      ),
    );

    const updatedFinancialAssets = {
      ...assets,
      tableData: handleTableDataMapping(assets.tableData, financialAssetOptions, ASSET_INDEX),
    };

    return updatedFinancialAssets;
  }

  private mapFinancialLiabilities(liabilities: NewTable) {
    const LIABILITY_INDEX = 0;

    const liabilityTypeOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.FINANCIAL_LIABILITY,
        FinancialLiabilityKey.LIABILITY,
      ),
    );

    const updatedFinancialLiabilities = {
      ...liabilities,
      tableData: handleTableDataMapping(
        liabilities.tableData,
        liabilityTypeOptions,
        LIABILITY_INDEX,
      ),
    };

    return updatedFinancialLiabilities;
  }

  private mapDownPayments(downPayment: NewTable) {
    const SOURCE_INDEX = 0;

    const downPaymentSourceOptions = this.store.selectSnapshot(
      FieldMetadataState.getVisibleFieldOptions(
        SupportedCustomEntity.DOWN_PAYMENT,
        DownPaymentKey.SOURCE,
      ),
    );

    const updatedDownPayments = {
      ...downPayment,
      tableData: handleTableDataMapping(
        downPayment.tableData,
        downPaymentSourceOptions,
        SOURCE_INDEX,
      ),
    };

    return updatedDownPayments;
  }
}
