import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ApplicationApprovalAction,
  FutureAggregate,
  PipelineApplication,
} from '@fundmoreai/models';
import { Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationApproval } from './application-approval.model';

@Injectable({
  providedIn: 'root',
})
export class ApplicationApprovalService {
  private base = `${environment.fundmore_api_url}/applicationApproval`;

  constructor(private http: HttpClient, private store: Store) {}

  approveRequest(approvalReview: ReviewApprovalDto): Observable<ApplicationApproval> {
    return this.http.patch<ApplicationApproval>(`${this.base}/${approvalReview.id}/review`, {
      ...approvalReview,
      action: ApplicationApprovalAction.APPROVE,
    });
  }

  cancelRequest(applicationApprovalId: string, comment: string): Observable<ApplicationApproval> {
    return this.http.patch<ApplicationApproval>(`${this.base}/${applicationApprovalId}/cancel`, {
      comment,
    });
  }

  declineRequest(approvalReview: ReviewApprovalDto): Observable<ApplicationApproval> {
    return this.http.patch<ApplicationApproval>(`${this.base}/${approvalReview.id}/review`, {
      action: ApplicationApprovalAction.DECLINE,
      ...approvalReview,
    });
  }

  getAll(applicationId: string): Observable<ApplicationApproval[]> {
    return this.http.get<ApplicationApproval[]>(`${this.base}/${applicationId}`);
  }

  getPendingApprovalsCount(archived: boolean): Observable<number> {
    return this.http
      .get<PipelineApplication[]>(
        `${environment.fundmore_api_url}/applications/approvalRequested?archived=${archived}`,
      )
      .pipe(map((applications) => applications.length));
  }

  requestApproval(
    applicationApproval: { approvalTypeIds: string[]; approverUserIds: string[]; comment: string },
    applicationId: string,
  ): Observable<ApplicationApproval[]> {
    return this.http.post<ApplicationApproval[]>(this.base, {
      ...applicationApproval,
      applicationId,
    });
  }

  requestApprovalAgain(
    applicationApprovalId: string,
    comment: string,
  ): Observable<ApplicationApproval> {
    return this.http.patch<ApplicationApproval>(`${this.base}/${applicationApprovalId}/retry`, {
      comment,
    });
  }

  requestChanges(applicationApprovalId: string, comment: string): Observable<ApplicationApproval> {
    return this.http.patch<ApplicationApproval>(`${this.base}/${applicationApprovalId}/review`, {
      action: ApplicationApprovalAction.REQUEST_CHANGES,
      comment,
    });
  }
}

export interface ReviewApprovalDto {
  id: string;
  comment?: string;
  formattedContent: string;
  loanAmount?: number;
  applicationFutureAggregate?: FutureAggregate;
}
