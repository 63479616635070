import { MortgageComputedData } from '@fundmoreai/models';

export class PersistMortgageComputedData {
  static readonly type = '@mortgageComputedData.PersistMortgageComputedData';
  constructor() {}
}

export class SetMortgageComputedData {
  static readonly type = '@mortgageComputedData.SetMortgageComputedData';
  constructor(public data: MortgageComputedData[]) {}
}
