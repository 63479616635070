<div
  *ngFor="let note of notes; let last = last"
  class="p-16"
  [class.border-bottom]="!last"
  [class.bg-light-grey]="note.status === ApplicationNoteStatus.DRAFT"
  [class.bg-content]="note.status !== ApplicationNoteStatus.DRAFT"
  [class.pb-32]="last">
  <div class="d-flex align-items-center justify-content-between mb-8 note-header">
    <div class="d-flex align-items-center">
      <mat-icon class="mat-icon-sm ml-0 mr-5">account_circle</mat-icon>

      <span *ngIf="note.authorNames; else authorSystem">
        {{ note.authorNames[0] }}

        <span
          class="pointer"
          *ngIf="note.authorNames.length > 1"
          [matTooltip]="note.authorNames.slice(1).join(', ')">
          +{{ note.authorNames.length - 1 }}
        </span>
      </span>
      <ng-template #authorSystem>
        <span i18n>System</span>
      </ng-template>

      <div *ngIf="note.authorRoles" class="bg-grey border-rounded ml-8 note-author-role">
        {{ note.authorRoles[0] }}

        <span
          class="pointer"
          *ngIf="note.authorRoles.length > 1"
          [matTooltip]="note.authorRoles.slice(1).join(', ')">
          +{{ note.authorRoles.length - 1 }}
        </span>
      </div>

      <mat-icon class="mat-icon-sm">today</mat-icon>
      <span
        >{{ note.lastModifiedAt | date : 'mediumDate' }} <span i18n>at</span>
        {{ note.lastModifiedAt | date : 'shortTime' }}</span
      >
    </div>

    <div class="d-flex align-items-center" *ngIf="note.status === ApplicationNoteStatus.DRAFT">
      <mat-icon matTooltip="Draft Note" i18n-matTooltip>edit_document</mat-icon>

      <div
        i18n-matTooltip
        matTooltip="Only the note author can edit draft notes."
        [matTooltipDisabled]="!(note.editableById && note.editableById !== currentUserId)"
        matTooltipPosition="above">
        <button
          class="text-dark"
          mat-icon-button
          [matMenuTriggerFor]="optionsMenu"
          [disabled]="!canEdit || (note.editableById && note.editableById !== currentUserId)">
          <mat-icon>more_vert</mat-icon>
        </button>
      </div>

      <mat-menu #optionsMenu="matMenu">
        <button mat-menu-item (click)="editDraftNote.emit(note)">
          <mat-icon>edit</mat-icon>
          <ng-container i18n="@@action.edit">Edit</ng-container>
        </button>

        <button mat-menu-item (click)="deleteDraftNote(note)">
          <mat-icon>delete</mat-icon>
          <ng-container i18n="@@action.delete">Delete</ng-container>
        </button>
      </mat-menu>
    </div>
  </div>

  <div class="note-content">
    <fm-notes-read-more>
      <div [innerHTML]="note.content"></div>
    </fm-notes-read-more>
  </div>
</div>
