import { DashboardWidget } from './dashboard.state';

export class ExpandWidget {
  static readonly type = '@dashboard.ExpandWidget';
  constructor(public value: DashboardWidget) {}
}

export class DefaultWidget {
  static readonly type = '@dashboard.DefaultWidget';
  constructor(public value: DashboardWidget) {}
}

export class ShowEditMode {
  static readonly type = '@dashboard.ShowEditMode';
  constructor(public value: DashboardWidget) {}
}

export class SaveWidgets {
  static readonly type = '@dashboard.SaveWidgets';
  constructor(public value: DashboardWidget[]) {}
}
