// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';

import { environment as base } from './environment.base';
const enableLogger = (localStorage.getItem('logger')?.length ?? 0) > 0;
const enableReduxLogger = (localStorage.getItem('redux')?.length ?? 0) > 0;

export const environment = {
  ...base,
  production: false,
  // disable autoload of streetview
  disableStreetView: true,
  embedSourceWhitelisted: false,
  plugins: [
    NgxsLoggerPluginModule.forRoot({ collapsed: false, disabled: !enableLogger }),
    NgxsReduxDevtoolsPluginModule.forRoot({
      disabled: !enableReduxLogger,
      maxAge: 100,
      actionsBlacklist: ['@brokers.*'],
      stateSanitizer: (state) => {
        return {
          ...state,
          // loading: undefined,
          fieldMetadataState: undefined,
          brokers: undefined,
          pipeline: undefined,
          mortgageApplication: undefined,
        };
      },
      actionSanitizer: (action) => {
        if (action.type === '@brokers.FetchBrokers') {
          return { undefined };
        }
        return action;
      },
    }),
  ],
  cognito: {
    region: 'us-east-1',
    userPoolId: 'us-east-1_fuIaII5ZJ',
    clientId: '6bqi3p5duhg6umj0ngpvn60f0l',
    cognito_idp_endpoint: 'https://cognito-idp.us-east-1.amazonaws.com/us-east-1_fuIaII5ZJ',
    inject_context_header: false,
    defaultTenant: undefined,
    hostedUI: {
      domain: 'auth-dev.fundmore.ai',
    },
  },
  api_url: 'https://rbs9khirif.execute-api.us-east-1.amazonaws.com/dev/api',
  identity_api_url: 'https://rbs9khirif.execute-api.us-east-1.amazonaws.com/dev/identity/api',
  fundmore_api_url: 'https://rbs9khirif.execute-api.us-east-1.amazonaws.com/dev/fundmore/api/v1',
  fundmore_v2_api_url: 'https://rbs9khirif.execute-api.us-east-1.amazonaws.com/dev/v2/api',
  fundmore_ai_api_url: 'https://rbs9khirif.execute-api.us-east-1.amazonaws.com/dev/ai/api',
  fundmore_cmhc_api_url: 'https://rbs9khirif.execute-api.us-east-1.amazonaws.com/dev/cmhc/api',
  dolphin_api_url: 'https://rbs9khirif.execute-api.us-east-1.amazonaws.com/dev/dolphin/api',
  integrations_api_url:
    'https://rbs9khirif.execute-api.us-east-1.amazonaws.com/dev/integrations/api',
  eqMaps_api_url: 'https://rbs9khirif.execute-api.us-east-1.amazonaws.com/dev/eq-maps/api',
  velocity_api_url: 'https://rbs9khirif.execute-api.us-east-1.amazonaws.com/dev/velocity/api',
  lendesk_api_url: 'https://rbs9khirif.execute-api.us-east-1.amazonaws.com/dev/lendesk/api',
  filogix_api_url: 'https://rbs9khirif.execute-api.us-east-1.amazonaws.com/dev/filogix/api',
  intellifi_api_url: 'https://rbs9khirif.execute-api.us-east-1.amazonaws.com/dev/intellifi/api',
  reporting_api_url: 'https://rbs9khirif.execute-api.us-east-1.amazonaws.com/dev/reporting/api',
  mms_api_url: 'https://rbs9khirif.execute-api.us-east-1.amazonaws.com/dev/mms/api',
  public_bucket_url: 'https://fundmoreai-public-bucket.s3.ca-central-1.amazonaws.com',
  pubSub: {
    publishKey: 'pub-c-4338bbe5-4f5b-48e6-97e2-fc7898838666',
    subscribeKey: 'sub-c-1f4bb4d0-210d-11ea-9c02-4a7cb839a01e',
  },
  tinymce: {
    apiKey: 'zx6uz45l1w3mzga34h5444z3hmb32svpz7vi6uzpuw49fz7x',
  },
  sentry: {
    dsn: 'https://ff858a02ee43464ba1c9d2333589fae3@o656486.ingest.sentry.io/4505075155533824',
    tracesSampleRate: 0.1,
    environment: 'dev',
  },
  pendo: 'bbd338a4-32bf-4a2a-6251-0704dca43e17',
};
