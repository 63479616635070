export enum TownTypes {
  BigTown = 'Big Town',
  SmallTown = 'Small Town',
  Rural = 'Rural',
  OntarioUrban = 'Ontario Urban Area',
  Unknown = 'Unknown',
}

export enum DealTypes {
  Flip = 'Flip',
  CS = 'Construction Spec',
  ETO = 'ETO',
  CTO = 'CTO',
  TP = 'Term Purchase',
  DC = 'Debt Consolidation',
  B = 'Bridge',
  Unknown = 'Unknown',
}

export enum Areas {
  AB_Calgary = 'Calgary & Area',
  AB_Edmonton = 'Edmonton & Area',
  AB_Red_Deer = 'Red Deer & Area',
  AB_Small = 'Small Town',
  AB_Rural = 'Rural',
  ON_Toronto = 'Greater Toronto Area',
  ON_Hamilton = 'Hamilton & Area',
  ON_Kitchener = 'Kitchener, Waterloo, Guelph & Area',
  ON_London = 'London & Area',
  ON_Urban = 'Other Urban',
  Unknown = 'Unknown',
}

export enum MarketSize {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
  NonMarginable = 'Non-Marginable',
}

export enum HomeType {
  SFD = 'Detached', // both detached and semi-detached, because requirements are the same for both
  // SD = 'Semi-Detached',
  CONDO = 'Condo',
  CR = 'Country Residential', // currently not used, we do not support on our side
  AGRICULTURAL = 'Agricultural',
  COMMERCIAL = 'Commercial',
  INDUSTRIAL = 'Industrial',
  UNKNOWN = 'Unknown',
}
