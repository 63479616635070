import { PayoffPaydownType, PayoffType, Property, RentalOffsetType } from '@fundmoreai/models';
import { FundmoreCalculator } from '..';
import { ComputeCostToCarryPropertyInput } from './cost-to-carry.calculator';
import { ExistingMortgageForMonthlyCostToCarry } from './gds.calculator';

export type ComputeRentalOffsetPropertyInput = Pick<
  Partial<Property>,
  'rpeMonthlyRentalIncome' | 'rpeOffset' | 'rpeRentalOffsetOption'
>;

export type ComputeRentalIncomePropertyInput = ComputeCostToCarryPropertyInput &
  ComputeRentalOffsetPropertyInput &
  Pick<Property, 'id' | 'rpeRentalOffsetOption' | 'markAsSold' | 'isPrimaryResidence'>;

export interface RentalIncomeParameterValues {
  totalRentalIncome: number;
  totalCtc: number;
}

export function computeRentalOffset(
  property: ComputeRentalOffsetPropertyInput | undefined,
): number {
  let rentalOffset = 0;

  if (
    property?.rpeRentalOffsetOption &&
    property?.rpeRentalOffsetOption !== RentalOffsetType.NONE
  ) {
    rentalOffset = (property.rpeMonthlyRentalIncome * property.rpeOffset) / 100;
  }

  return rentalOffset;
}

export function computeRentalIncomeValues(
  otherProperties: ComputeRentalIncomePropertyInput[],
  existingMortgages: ExistingMortgageForMonthlyCostToCarry[],
  primaryProperty?: ComputeRentalIncomePropertyInput | null,
  primaryPropertyMortgagePayments?: number | null,
  existingMortgagePaymentsOnPrimaryProperty: number | null = 0,
  filterOutOtherPropertiesMarkedAsSold: boolean = true,
): RentalIncomeParameterValues {
  let totalCtc = 0;
  let totalRentalIncome = 0;

  const ctcPrimaryProperty = FundmoreCalculator.computeCostToCarryForTDS(
    primaryProperty,
    primaryPropertyMortgagePayments,
  );

  const rentalOffsetPrimaryProperty = FundmoreCalculator.computeRentalOffset(primaryProperty);
  const rentalOffsetTypePrimaryProperty = primaryProperty?.rpeRentalOffsetOption;

  if (
    rentalOffsetPrimaryProperty &&
    rentalOffsetTypePrimaryProperty &&
    rentalOffsetTypePrimaryProperty != RentalOffsetType.NONE
  ) {
    if (rentalOffsetTypePrimaryProperty == RentalOffsetType.ADD_PERCENTAGE_TO_GROSS_INCOME) {
      totalRentalIncome += rentalOffsetPrimaryProperty;
      totalCtc += ctcPrimaryProperty + existingMortgagePaymentsOnPrimaryProperty;
    }
    if (rentalOffsetTypePrimaryProperty == RentalOffsetType.REDUCE_RENTAL_EXPENSES) {
      const propertyBalance =
        ctcPrimaryProperty +
        existingMortgagePaymentsOnPrimaryProperty -
        rentalOffsetPrimaryProperty;
      if (propertyBalance > 0) {
        totalCtc += propertyBalance;
      } else {
        totalRentalIncome += Math.abs(propertyBalance);
      }
    }
  } else {
    totalCtc += ctcPrimaryProperty + existingMortgagePaymentsOnPrimaryProperty;
  }

  const filteredOtherProperties = filterOutOtherPropertiesMarkedAsSold
    ? otherProperties?.filter((op) => !op.markAsSold)
    : otherProperties;

  for (const property of filteredOtherProperties ?? []) {
    const totalMonthlyPayment = existingMortgages
      .filter(
        (mortgage) =>
          mortgage.propertyId === property.id && mortgage.payoffPaydown === PayoffPaydownType.NONE,
      )
      .reduce(
        (totalMonthlyPayment, mortgage) => totalMonthlyPayment + (mortgage?.monthlyPayment ?? 0),
        0,
      );
    const ctcProperty = FundmoreCalculator.computeCostToCarryForTDS(property, totalMonthlyPayment);
    const rentalIncomeProperty = FundmoreCalculator.computeRentalOffset(property);
    if (
      rentalIncomeProperty &&
      property?.rpeRentalOffsetOption &&
      property?.rpeRentalOffsetOption != RentalOffsetType.NONE
    ) {
      if (property?.rpeRentalOffsetOption == RentalOffsetType.ADD_PERCENTAGE_TO_GROSS_INCOME) {
        totalRentalIncome += rentalIncomeProperty;
        totalCtc += ctcProperty;
      }
      if (property?.rpeRentalOffsetOption == RentalOffsetType.REDUCE_RENTAL_EXPENSES) {
        const propertyBalance = ctcProperty - rentalIncomeProperty;
        if (propertyBalance > 0) {
          totalCtc += propertyBalance;
        } else {
          totalRentalIncome += Math.abs(propertyBalance);
        }
      }
    } else {
      totalCtc += ctcProperty;
    }
  }
  return { totalRentalIncome, totalCtc };
}
