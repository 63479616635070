import { ApplicationDocument } from '../applicationDocument';
import { FCTEnums, FCTRentalType, FCTServiceType } from './enums';
import { FCTSyncResponse, FCTDelta, FCTNote, FCTPropertyAddressExpanded } from './models';

export interface FCTAppraisal {
  id?: string;
  propertyAppraisalId?: string;
  fctReferenceId: string;
  response?: FCTSyncResponse;
  request: FCTAppraisalCreateRequest;
  lastStatus: FCTDelta;
  notes?: FCTNote[];
  reportDocumentId?: string;
  marketRentDocumentId?: string;
  reportDocument?: ApplicationDocument;
  marketRentDocument?: ApplicationDocument;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;
}

export interface FCTAppraisalCreateRequest {
  applicationId: string;
  propertyId: string;
  propertyAddressExpanded: FCTPropertyAddressExpanded;
  fctProduct: FCTServiceType;
  rushFlag: boolean;
  marketRentIndicator: boolean;
  rentalType: FCTRentalType | null;
  formattedAddressUsedForRequest?: string;
  metadata?: FCTFieldMetadata[];
}

export interface FCTFieldMetadata {
  field: string;
  name: string;
  value: string;
  entity: string | null;
  required: boolean;
  isMissing: boolean;
  isInvalid: boolean;
  options: string[] | null;
  enum: FCTEnums | null;
  humanizedValue: string | null;
  humanizedOptions: string[] | null;
}

export enum FCTIntegrationFields {
  FCT_INSURANCE_STATUS = 'FCTAppraisal?.lastStatus?.InsuranceStatus?.StatusCode',
}

export const FCTIntegrationFieldsList = [...Object.values(FCTIntegrationFields)];
