import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthComponent } from '../auth.component';
import { AuthService } from '../auth.service';
import { AuthResult, AuthResultStatus } from '../model';
import { MatButtonModule } from '@angular/material/button';

@Component({
  templateUrl: './totp-required.component.html',
  styleUrls: ['./totp-required.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, RouterLink, MatButtonModule],
})
export class TotpRequiredComponent implements AuthComponent {
  @Input() data: any;
  @Output() authResultEvent: EventEmitter<AuthResult> = new EventEmitter<AuthResult>();
  @Output() formSubmittingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  isSubmitting = false;
  totpForm: UntypedFormGroup;
  returnUrl: string | undefined;
  constructor(
    private fb: UntypedFormBuilder,
    private authApiService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.totpForm = this.fb.group({
      totpToken: ['', Validators.required],
    });
    this.returnUrl = this.route.snapshot.queryParams.returnUrl;
  }
  handleGoToLogin() {
    this.authResultEvent.next(new AuthResult(AuthResultStatus.LOGIN));
  }
  submitTotpForm() {
    this.isSubmitting = true;
    const { totpToken } = this.totpForm.value;
    const { cognitoUser } = this.data;
    this.authApiService.sendTOTPCode(cognitoUser, totpToken).subscribe(
      (res) => {
        res.returnUrl = this.returnUrl;
        this.authResultEvent.next(res);
      },
      (err) => {
        this.isSubmitting = false;
        this.formSubmittingEvent.next(false);
        this.authResultEvent.next(new AuthResult(AuthResultStatus.ERROR, err));
      },
    );
  }
}
