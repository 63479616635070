import { NgModule } from '@angular/core';

import { AuthRoutingModule } from './auth-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { LoginComponent } from './login/login.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { ScopeContextTokenInterceptor } from './scope-context-token.interceptor';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ConfirmNewPasswordComponent } from './confirm-new-password/confirm-new-password.component';
import { CommonModule } from '@angular/common';
import { AuthAnchorDirective } from './auth-anchor.directive';
import { AuthHostComponent } from './auth-host.component';
import { VerifyConfirmPasswordComponent } from './verify-confirm-password/verify-confirm-password.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MfaRequiredComponent } from './mfa-required/mfa-required.component';
import { TotpRequiredComponent } from './totp-required/totp-required.component';
import { NgxsModule } from '@ngxs/store';
import { PermissionsState } from './permissions.state';

@NgModule({
  imports: [
    CommonModule,
    AuthRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatButtonModule,
    MatProgressBarModule,
    NgxsModule.forFeature([PermissionsState]),
    LoginComponent,
    ForgotPasswordComponent,
    ConfirmNewPasswordComponent,
    MfaRequiredComponent,
    TotpRequiredComponent,
    VerifyConfirmPasswordComponent,
    AuthHostComponent,
    AuthAnchorDirective,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ScopeContextTokenInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {}
