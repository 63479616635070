import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ButtonText } from './enum';
import { MatButtonModule } from '@angular/material/button';
import { NgClass, NgIf } from '@angular/common';

@Component({
  selector: 'fm-dialogs',
  templateUrl: './dialogs.component.html',
  styleUrls: ['./dialogs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, MatDialogModule, MatButtonModule, NgIf],
})
export class DialogsComponent {
  title: string;
  message: string;
  messageHTML?: string;
  yesButtonText: string;
  noButtonText: string;
  declineModalClass: boolean;
  cancelModalClass: boolean;
  warningModalClass: boolean;
  alignButtonsCenter: boolean;
  actionModal: boolean;
  modalIcon: string;
  modalType: string;
  constructor(
    public dialogRef: MatDialogRef<DialogsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) {
    if (data) {
      this.title = data.title; // insert modal title
      this.message = data.message; // insert modal body text
      this.messageHTML = data.messageHTML?.replace(/(?:\r\n|\r|\n)/g, '<br>');
      this.modalType = data.modalType!; // check modal type
      this.alignButtonsCenter = data.alignButtonsCenter ?? false;
      if (data.buttonText && data.buttonText.no) {
        this.yesButtonText = data.buttonText.yes; // Change left button text
        this.noButtonText = data.buttonText.no; // Change right button text

        if (data.declineModalClass) {
          // Checking if the modal needs red color
          this.declineModalClass = true;
          this.modalIcon = 'error_outline';
          this.actionModal = true;
        } else if (data.declineModalClass === false) {
          // Checking if the modal needs green color
          if (data.modalType === 'review') {
            this.modalIcon = 'error_outline';
          } else {
            this.modalIcon = 'task_alt';
          }
          this.declineModalClass = false;
          this.actionModal = true;
        }

        if (data.cancelModalClass) {
          // Checking if the modal needs red color
          this.cancelModalClass = true;
          this.modalIcon = 'cancel';
          this.actionModal = true;
        }

        if (data.warningModalClass) {
          this.warningModalClass = true;
          this.modalIcon = 'warning';
          this.actionModal = true;
        }
      }
      this.modalIcon = data.modalIcon ?? this.modalIcon;
    }
  }

  onConfirm() {
    this.dialogRef.close(true);
  }

  onDismiss() {
    this.dialogRef.close(false);
  }
}

export interface DialogData {
  title: string;
  message: string;
  messageHTML?: string;
  buttonText: ButtonText;
  declineModalClass?: boolean;
  cancelModalClass?: boolean;
  warningModalClass?: boolean;
  modalType?: string;
  modalIcon?: string;
  alignButtonsCenter?: boolean;
}
