import { Injectable } from '@angular/core';
import { OutOfOfficeDetails, UserPreference } from '@fundmoreai/models';
import { State, Selector, Action, StateContext } from '@ngxs/store';
import { tap, finalize, switchMap, of } from 'rxjs';
import { LoadingEnd, LoadingStart } from 'src/app/core/loading.state';
import { DirectManagerChangeUserAccount } from '../user-manage.state';
import { UserPreferenceService } from './user-preferences.service';

export class GetUserPreferences {
  static readonly type = '@userPreferences.getUserPreferences';
}

export class GetUserPreference {
  static readonly type = '@userPreferences.getUserPreference';
  constructor(public userId: string) {}
}

export class CreateUserPreference {
  static readonly type = '@userPreferences.createUserPreference';
  constructor(public userPreference: UserPreference) {}
}

export class UpdateUserPreference {
  static readonly type = '@userPreferences.updateUserPreference';
  constructor(public userId: string, public userPreference: UserPreference) {}
}

export class UpdateOwnOutOfOfficeDetails {
  static readonly type = '@userPreferences.updateOwnOutOfOfficeDetails';
  constructor(public oooDetails: OutOfOfficeDetails) {}
}

export class ResetUserPreferenceState {
  static readonly type = '@userPreferences.resetUserPreferences';
}

@State<UserPreference>({
  name: 'userPreferences',
  defaults: { userId: '' },
})
@Injectable()
export class UserPreferencesState {
  constructor(private userPreferenceService: UserPreferenceService) {}

  @Selector()
  static userPreference(state: UserPreference) {
    return state ?? null;
  }

  @Action(GetUserPreferences)
  getUserPreferences(ctx: StateContext<UserPreference>) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.userPreferenceService
      .getUserPreferences()
      .pipe(finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))));
  }

  @Action(GetUserPreference) getUserPreference(
    ctx: StateContext<UserPreference>,
    action: GetUserPreference,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.userPreferenceService.getUserPreference(action.userId).pipe(
      tap((userPreference) => {
        ctx.setState(userPreference);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreateUserPreference) createUserPreference(
    ctx: StateContext<UserPreference>,
    action: CreateUserPreference,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.userPreferenceService
      .createUserPreference({ ...action.userPreference, id: undefined })
      .pipe(
        tap((userPreference) => {
          ctx.setState(userPreference);
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(UpdateUserPreference) updateUserPreference(
    ctx: StateContext<UserPreference>,
    action: UpdateUserPreference,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.userPreferenceService
      .updateUserPreference(action.userId, {
        ...action.userPreference,
        userId: action.userId,
        id: undefined,
      })
      .pipe(
        switchMap(() =>
          action.userPreference.directManager === undefined
            ? of(null)
            : ctx.dispatch(
                new DirectManagerChangeUserAccount(
                  action.userId,
                  action.userPreference.directManager,
                ),
              ),
        ),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(UpdateOwnOutOfOfficeDetails) updateOwnOutOfOfficeDetails(
    ctx: StateContext<UserPreference>,
    { oooDetails }: UpdateOwnOutOfOfficeDetails,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.userPreferenceService.updateOwnOutOfOfficeDetails(oooDetails).pipe(
      tap((result) => {
        ctx.setState(result);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(ResetUserPreferenceState) reset(ctx: StateContext<UserPreference>) {
    ctx.setState({ userId: '' });
  }
}
