import { Applicant, FinancialLiability, PayoffType } from '@fundmoreai/models';
import { computeTotalRentFromApplicantsAddresses } from './address-rent.calculator';

export function computeDebtFromProceeds(
  financialLiabilities: FinancialLiability[],
  applicants: Applicant[],
): number {
  const totalRent = computeTotalRentFromApplicantsAddresses(applicants);

  const debt =
    financialLiabilities
      .filter(
        (liability) =>
          liability.payoff === PayoffType.FROM_PROCEEDS && !liability.excludeFromCalculation,
      )
      .reduce((sum, liability) => sum + (liability.balance ? liability.balance : 0.0), 0.0) +
    totalRent;

  return debt;
}
