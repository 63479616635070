import { Property, PropertyAddressDetails } from '@fundmoreai/models';
import { ConfigurationState } from '../../configs/configuration-state';
import { isWithinKmFromCitiesOverPopulationOf } from '../location';
import { Areas, DealTypes, HomeType, MarketSize, TownTypes } from './enum';

export function isCalvertProvinceEligible(province: string): boolean {
  const eligibleProvinces = ['Alberta', 'Ontario'];
  return eligibleProvinces.includes(province);
}

export function isAlbertaBigTown(city: string): boolean {
  const eligibleBigTowns = ['Calgary', 'Edmonton', 'Red Deer'];
  return eligibleBigTowns.includes(city);
}

export function isAlbertaSmallTown(city: string): boolean {
  // consider small towns having less than 100,000 population and more than 50,000
  const eligibleSmallTowns = ConfigurationState.cities.filter(
    (x) => x.population < 100000 && x.population >= 50000,
  );
  return eligibleSmallTowns.map((x) => x.city).includes(city);
}

export function isAlbertaRural(city: string): boolean {
  const eligibleRural = ConfigurationState.cities.filter((x) => x.population < 50000);
  return eligibleRural.map((x) => x.city).includes(city);
}

export function isOntarioUrbanArea(city: string): boolean {
  const eligibleBigTowns = [
    'London',
    'Hamilton',
    'Kitchener',
    'Ottawa',
    'Toronto',
    'Waterloo',
    'Guelph',
    'Windsor',
  ];
  return eligibleBigTowns.includes(city);
}

export function getTownType(city: string): TownTypes {
  if (isAlbertaBigTown(city)) {
    return TownTypes.BigTown;
  } else if (isAlbertaSmallTown(city)) {
    return TownTypes.SmallTown;
  } else if (isAlbertaRural(city)) {
    return TownTypes.Rural;
  }
  return TownTypes.Unknown;
}

export function getTownTypeCalvert(province: string, city: string): TownTypes {
  if (province === 'Alberta') {
    if (isAlbertaBigTown(city)) {
      return TownTypes.BigTown;
    }
    if (isAlbertaSmallTown(city)) {
      return TownTypes.SmallTown;
    }
    if (isAlbertaRural(city)) {
      return TownTypes.Rural;
    }
    return TownTypes.Unknown;
  }
  if (province === 'Ontario') {
    if (isOntarioUrbanArea(city) || isInGTA(city)) {
      return TownTypes.OntarioUrban;
    }
    return TownTypes.Unknown;
  }
  return TownTypes.Unknown;
}

export function getAreaCalvert(province: string, city: string, townType: TownTypes): Areas {
  if (province === 'Alberta') {
    if (city.includes('Calgary')) {
      return Areas.AB_Calgary;
    }
    if (city.includes('Edmonton')) {
      return Areas.AB_Edmonton;
    }
    if (city.includes('Red Deer')) {
      return Areas.AB_Red_Deer;
    }
    if (townType === TownTypes.SmallTown) {
      return Areas.AB_Small;
    }
    if (townType === TownTypes.Rural) {
      return Areas.AB_Rural;
    }
    return Areas.Unknown;
  }
  if (province === 'Ontario') {
    if (isInGTA(city)) {
      return Areas.ON_Toronto;
    }
    if (city.includes('Hamilton')) {
      return Areas.ON_Hamilton;
    }
    if (['Kitchener', 'Waterloo', 'Guelph'].includes(city)) {
      return Areas.ON_Kitchener;
    }
    if (city.includes('London')) {
      return Areas.ON_London;
    }
    if (townType === TownTypes.OntarioUrban) {
      return Areas.ON_Urban;
    }
    return Areas.Unknown;
  }
  return Areas.Unknown;
}

function isInGTA(city: string) {
  const gtaCities = [
    'Ajax',
    'Aurora',
    'Brampton',
    'Brock',
    'Burlington',
    'Caledon',
    'Clarington',
    'East Gwillimbury',
    'Georgina',
    'Halton Hills',
    'King',
    'Markham',
    'Milton',
    'Mississauga',
    'Newmarket',
    'Oakville',
    'Oshawa',
    'Pickering',
    'Richmond Hill',
    'Scugog',
    'Toronto',
    'Uxbridge',
    'Vaughan',
    'Whitby',
    'Stouffville',
  ];

  return gtaCities.includes(city);
}

export function getHomeType(primaryProperty: Property): HomeType {
  return primaryProperty?.tenure?.toLowerCase().includes('condo')
    ? HomeType.CONDO
    : primaryProperty.zoningType?.toLowerCase().includes('commercial')
    ? HomeType.COMMERCIAL
    : primaryProperty.zoningType?.toLowerCase().includes('industrial')
    ? HomeType.INDUSTRIAL
    : primaryProperty.zoningType?.toLowerCase().includes('land')
    ? HomeType.AGRICULTURAL
    : primaryProperty.dwellingStyle?.toLowerCase().includes('detached') ||
      primaryProperty.dwellingType?.toLowerCase().includes('detached')
    ? HomeType.SFD
    : HomeType.UNKNOWN;
}

export function getDealType(productName: string): DealTypes {
  if (!productName) {
    return DealTypes.Unknown;
  }
  return productName.toLowerCase().includes('eto') ||
    (productName.toLowerCase().includes('equity') && productName.toLowerCase().includes('take'))
    ? DealTypes.ETO
    : productName.toLowerCase().includes('bridge')
    ? DealTypes.B
    : productName.toLowerCase().includes('purchase') ||
      productName.toLowerCase().includes('term') ||
      productName.toLowerCase().includes('tp')
    ? DealTypes.TP
    : productName.toLowerCase().includes('debt') ||
      productName.toLowerCase().includes('consolidation') ||
      productName.toLowerCase().includes('dc')
    ? DealTypes.DC
    : productName.toLowerCase().includes('flip')
    ? DealTypes.Flip
    : productName.toLowerCase().includes('cs') ||
      (productName.toLowerCase().includes('construction') &&
        productName.toLowerCase().includes('spec'))
    ? DealTypes.CS
    : productName.toLowerCase().includes('cto') ||
      (productName.toLowerCase().includes('construction') &&
        productName.toLowerCase().includes('take'))
    ? DealTypes.CTO
    : DealTypes.Unknown;
}

export function computeCalvertMarketSize(address: PropertyAddressDetails): MarketSize {
  if (isWithinKmFromCitiesOverPopulationOf(address, 25, 100000)) {
    return MarketSize.Large;
  }
  if (isWithinKmFromCitiesOverPopulationOf(address, 10, 50000)) {
    return MarketSize.Medium;
  }
  if (isWithinKmFromCitiesOverPopulationOf(address, 5, 10000)) {
    return MarketSize.Small;
  }
  return MarketSize.NonMarginable;
}
