/* eslint-disable max-classes-per-file */
import { FundmoreScoreContext } from '../models/models';
import { Octopus, Treshold } from '../interfaces/score.config';
import { buildSecureIfFunctionWithContext } from '../helpers/generic';
import { TresholdProcessor, TresholdResult } from './treshold-processor';
import { ConfigurationProcessor } from './configuration-processor';
import {
  FundmoreNarrative,
  FundmoreRecommendationModel,
  FundmoreResultType,
  OctopusResultModel,
} from '@fundmoreai/models';

export class OctopusProcessor {
  isActiveTresholdFilter = (context: FundmoreScoreContext) => (treshold: Treshold) => {
    const activateFunction = buildSecureIfFunctionWithContext(treshold.activateFn);
    return activateFunction.call(context);
  };

  public process = (context: FundmoreScoreContext, octopus: Octopus): OctopusResult => {
    let childResults: OctopusResult[] = [];
    if (octopus.children) {
      childResults = octopus.children
        .filter(ConfigurationProcessor.isActiveOctopusFilter(context))
        .map((octopus) => {
          const processor = new OctopusProcessor();
          return processor.process(context, octopus);
        });
    }
    if (octopus.tresholds) {
      const tresholdResult = octopus.tresholds
        .filter(this.isActiveTresholdFilter(context))
        .map((treshold) => {
          const tresholdProcessor = new TresholdProcessor();
          return tresholdProcessor.process(context, treshold);
        });

      return flattenResults([new OctopusResult(tresholdResult, octopus), ...childResults]);
    }

    return flattenResults(childResults);
  };
}

function flattenResults(results: OctopusResult[]): OctopusResult {
  let result: OctopusResult = {
    name: results[0]?.name,
    type: results[0]?.type,
    result: results[0]?.result,
    resultNarrative: [],
    recommendations: [],
    narratives: [],
    passedTresholds: 0,
    failedTresholds: 0,
    manualReviewTresholds: 0,
    unknownTresholds: 0,
    tresholdLength: 0,
  };

  results.map((r) => {
    /* gather narratives and recommendations from root and children and add up all 
       passed/failed/manualReview/unknown/total recommendations */

    result.narratives?.push(...r.narratives);
    result.recommendations?.push(...r.recommendations);

    result.passedTresholds += r.passedTresholds;
    result.failedTresholds += r.failedTresholds;
    result.manualReviewTresholds += r.manualReviewTresholds;
    result.unknownTresholds += r.unknownTresholds;
    result.tresholdLength += r.tresholdLength;
  });
  result = buildResultNarrative(result);
  return result;
}

function buildResultNarrative(result: OctopusResult): OctopusResult {
  if (result.passedTresholds != 0) {
    result.result = FundmoreResultType.Pass;
    result.resultNarrative.push({
      display: `Passed: ${result.passedTresholds}`,
      sentiment: FundmoreResultType.Pass,
    });
  }
  if (result.manualReviewTresholds != 0) {
    result.result = FundmoreResultType.ManualReview;
    result.resultNarrative.push({
      display: `Identified risks: ${result.manualReviewTresholds}`,
      sentiment: FundmoreResultType.ManualReview,
    });
  }
  if (result.unknownTresholds != 0) {
    result.result = FundmoreResultType.Unknown;
    result.resultNarrative.push({
      display: `Unknown: ${result.unknownTresholds}`,
      sentiment: FundmoreResultType.Unknown,
    });
  }
  if (result.failedTresholds != 0) {
    result.result = FundmoreResultType.Fail;
    result.resultNarrative.push({
      display: `Fail: ${result.failedTresholds}`,
      sentiment: FundmoreResultType.Fail,
    });
  }

  return result;
}
export class OctopusResult implements OctopusResultModel {
  name: string;
  type: string;
  result: FundmoreResultType;
  resultNarrative: FundmoreNarrative[];
  narratives: FundmoreNarrative[];
  recommendations: FundmoreRecommendationModel[];

  passedTresholds: number;
  manualReviewTresholds: number;
  unknownTresholds: number;
  failedTresholds: number;
  tresholdLength: number;

  constructor(tresholdResults: TresholdResult[], octopus: Octopus) {
    this.name = octopus.name;
    this.type = octopus.type;
    this.narratives = tresholdResults.map((x) => x.narrative);
    this.recommendations = tresholdResults
      .filter((x) => x.recommendation)
      .map((x) => x.recommendation);
    const groupedByResult = tresholdResults.reduce((a, b) => {
      (a[b.result] = a[b.result] || []).push(b);
      return a;
    }, {});

    const passedTresholds = groupedByResult[FundmoreResultType.Pass];
    const manualReviewTresholds = groupedByResult[FundmoreResultType.ManualReview];
    const unknownTresholds = groupedByResult[FundmoreResultType.Unknown];
    const failedTresholds = groupedByResult[FundmoreResultType.Fail];
    this.resultNarrative = [];

    this.passedTresholds = passedTresholds ? passedTresholds.length : 0;
    this.manualReviewTresholds = manualReviewTresholds ? manualReviewTresholds.length : 0;
    this.unknownTresholds = unknownTresholds ? unknownTresholds.length : 0;
    this.failedTresholds = failedTresholds ? failedTresholds.length : 0;
    this.tresholdLength = tresholdResults.length;
  }
}
