import { AddressExpanded, ResidentialStatusType } from './addressExpanded';
import { Broker } from './broker';
import { CountryCodes } from './country-code.enum';
import { CreditReportReference } from './creditReport';
import { ApplicantCreditCardOffer } from './decisionEngine/applicantCreditCardOffer';
import { EQFraudAMLRiskType } from './eqMaps';
import { ApplicantBankingSearchExistingMemberResult } from './idsGateway/applicantBankingSearchExistingMemberResult';
import { Job } from './job';

export enum ApplicantKey {
  CUSTOMER_TYPE = 'customerType',
  SURNAME = 'surname',
  NAME = 'name',
  EMAIL = 'email',
  CELL_PHONE = 'cellPhone',
  WORK_PHONE = 'workPhone',
  HOME_PHONE = 'homePhone',
  FAX_NO = 'faxNumber',
  DOB = 'dateOfBirth',
  POB = 'placeOfBirth',
  COR = 'countryOfResidence',
  MARITAL_STATUS = 'maritalStatus',
  DEPENDENTS = 'dependents',
  RESIDENTIAL_ADDRESS = 'address',
  RESIDENTIAL_STATUS_TYPE = 'residentialStatusType',
  TIME_AT_RESIDENCE_MONTHS = 'timeAtResidenceMonths',
  BROKERAGE = 'brokerage',
  IS_FIRST_TIME_HOME_BUYER = 'isFirstTimeHomeBuyer',
  COMPANY = 'company',
  COMPANY_ID = 'companyId',
  BUSINESS_NO = 'businessNumber',
  INCORPORATION_NO = 'incorporationNumber',
  SIN = 'sin',
  NETWORTH = 'networth',
  NATIONALITY = 'nationality',
  OCCUPATION = 'occupation',
  REFERENCE = 'reference',
  ROLE = 'role',
  CITIZENSHIP_STATUS = 'citizenshipStatus',
  CREDIT_SCORE = 'crScore',
  CREDIT_SCORE_CODE = 'crScoreCode',
  BNI_SCORE = 'bniScore',
  CREDIT_DESCRIPTION = 'crDescription',
  IS_UPLOADER = 'isUploader',
  TEMPLATE_SET_ID = 'templateSetId',
  FICO_SCORE_OVERRIDE = 'ficoScoreOverride',
  SALUTATION = 'salutation',
  MIDDLE_NAME = 'middleName',
  GENDER = 'gender',
  RELATIONSHIP_TO_PRIMARY_APPLICANT = 'relationshipToPrimaryApplicant',
  LANGUAGE_PREFERENCE = 'languagePreference',
  CONSENT_RECEIVED = 'consentReceived',
  NEW_TO_CANADA = 'newToCanada',
  DOCUMENT_REQUEST_NOTIFICATIONS = 'documentRequestNotifications',
  FIRM_CODE = 'firmCode',
  REGISTERED_COUNTRY = 'registeredCountry',
  POLITICALLY_EXPOSED_PERSON_STATUS = 'politicallyExposedPersonStatus',
  FRAUD_AML_RISK = 'fraudAMLRisk',
  THIRD_PARTY_DETERMINATION = 'thirdPartyDetermination',
  CLIENT_RISK_SCORE = 'clientRiskScore',
  REQUEST_NAME_SCREENING = 'requestNameScreening',
  CLIENT_IDENTIFIED_IN_NAME_SCREENING = 'clientIdentifiedInNameScreening',
  FINANCIAL_INSTITUTION = 'financialInstitution',
  TRANSIT_NUMBER = 'transitNumber',
  ACCOUNT_NUMBER = 'accountNumber',
  ACCOUNT_TYPE = 'accountType',
  CLIENT_ID = 'clientId',
  CLIENT_SCREENING_COMPLETED = 'clientScreeningCompleted',
  CLIENT_SCREENING_COMPLETED_DATE = 'clientScreeningCompletedDate',
  CLIENT_LAST_SCREENING_DATE = 'clientLastScreeningDate',
  DM_ATTRIBUTE_WARNINGS = 'dmAttributeWarnings',
  INCOME_VERIFICATION = 'incomeVerification',
  EQ_FRAUD_AML_RISK = 'eqFraudAMLRisk',
  RELATED_CIFS = 'relatedCIFs',
  MEMBER_NUMBER = 'memberNumber',
  RESTRICTED_PARTY_MEMBER = 'restrictedPartyMember',
  STAFF_MEMBER = 'staffMember',
  IS_STUDENT = 'isStudent',
  DURATION_IN_CANADA = 'durationInCanada',
  PERMANENT_RESIDENCE_FLAG = 'permanentResidenceFlag',
  PAYMENT_SOURCE = 'paymentSource',
  MEMBER_BANKING_NUMBER = 'memberBankingNumber',
  SUB_NUMBER = 'subNumber',
  FINANCIAL_INSTITUTION_NAME = 'financialInstitutionName',
  FINANCIAL_INSTITUTION_NUMBER = 'financialInstitutionNumber',
  CONNECTED_PARTY = 'connectedParty',
  CONNECTED_PARTY_CHECK_ERROR = 'connectedPartyCheckError',
}

export enum ApplicantCompanyDetailsKey {
  BUSINESS_NUMBER = 'businessNumber',
  INCORPORATION_NUMBER = 'incorporationNumber',
  COMPANY_NAME = 'companyName',
}

export enum ApplicantCompanyDirectorDetailsKey {
  ROLE = 'role',
  NETWORTH = 'networth',
}

export enum ApplicantExistingMemberOptionKey {
  MEMBER_NUMBER = 'memberNumber',
  NAME = 'name',
  SURNAME = 'surname',
  DOB = 'dob',
  SIN = 'sin',
  BRANCH_FULL_NAME = 'branchFullName',
  STATUS = 'status',
  STAFF_MEMBER = 'staffMember',
  RESTRICTED_PARTY = 'restrictedParty',
  CONNECTED_PARTY = 'connectedParty',
}

export enum IndustrySectorType {
  CONSTRUCTION = 'CONSTRUCTION',
  GOVERNMENT = 'GOVERNMENT',
  HEALTH = 'HEALTH',
  EDUCATION = 'EDUCATION',
  FARMING = 'FARMING',
  HI_TECH = 'HI_TECH',
  RETAIL_SALES = 'RETAIL_SALES',
  LEISURE_ENTERTAINMENT = 'LEISURE_ENTERTAINMENT',
  BANKING_FINANCE = 'BANKING_FINANCE',
  TRANSPORT = 'TRANSPORT',
  SERVICE = 'SERVICE',
  MANUFACTURING = 'MANUFACTURING',
  FARMING_NATURAL_RESOURCES = 'FARMING_NATURAL_RESOURCES',
  NATURAL_RESOURCES = 'NATURAL_RESOURCES',
  INFORMATION_TECHNOLOGY = 'INFORMATION_TECHNOLOGY',
  OIL_GAS = 'OIL_GAS',
  STUDENT = 'STUDENT',
  FOREIGN = 'FOREIGN',
  RETIRED = 'RETIRED',
  UNEMPLOYED = 'UNEMPLOYED',
  VARIES = 'VARIES',
  OTHER = 'OTHER',
}

export enum CitizenshipStatusType {
  CITIZEN = 'CITIZEN',
  RESIDENT = 'RESIDENT',
  LANDED_IMMIGRANT = 'LANDED_IMMIGRANT',
  FOREIGN_ALIEN = 'FOREIGN_ALIEN',
  STUDENT_VISA = 'STUDENT_VISA',
  WORK_VISA = 'WORK_VISA',
  NON_RESIDENT = 'NON_RESIDENT',
}

export enum IncomeVerificationType {
  NOT_APPLICABLE = 'NOT_APPLICABLE',
  BUSINESS_FINANCIAL_STATEMENT = 'BUSINESS_FINANCIAL_STATEMENT',
  COMMISSION_STATEMENT = 'COMMISSION_STATEMENT',
  LEASE_AGREEMENTS = 'LEASE_AGREEMENTS',
  LESS_THAN_12_MONTHS_BANK_STMT = 'LESS_THAN_12_MONTHS_BANK_STMT',
  EMPLOYMENT_LETTER_AND_PAYSTUB = 'EMPLOYMENT_LETTER_AND_PAYSTUB',
  EMPLOYMENT_LETTER = 'EMPLOYMENT_LETTER',
  MULTIPLE = 'MULTIPLE',
  NOTICE_OF_ASSESSMENT = 'NOTICE_OF_ASSESSMENT',
  OTHER = 'OTHER',
  PAY_STUB = 'PAY_STUB',
  PENSION_STATEMENT = 'PENSION_STATEMENT',
  SELF_DECLARED_LETTER = 'SELF_DECLARED_LETTER',
  T4 = 'T4',
}

export interface Applicant {
  age?: number;
  ApplicantCompanyDetails?: ApplicantCompanyDetailsType[];
  ApplicantCompanyDirectorDetails?: ApplicantCompanyDirectorDetailsType[];
  ApplicantCreditReports?: CreditReportReference[];
  ApplicantAddressesExpanded: AddressExpanded[];
  ApplicantCreditCardOffer?: ApplicantCreditCardOffer;
  applicationId: string;
  Broker?: Broker | null;
  brokerId?: string;
  brokerage: string | null;
  cellPhone: string | null;
  citizenshipStatus?: string;
  company?: string;
  companyId?: string;
  consentReceived: boolean;
  contacts: StakeholderContact[];
  countryOfResidence: CountryCodes;
  crDescription: string | null;
  createdAt: string;
  createDocumentRequest: boolean;
  customerType: CustomerType;
  crScore: number | null;
  crScoreCode: string | null;
  bniScore: number | null;
  currentResidence?: AddressExpanded;
  dateOfBirth?: string | null;
  dependents: number;
  email: string;
  externalWorkPhoneSequenceNumber?: number | null;
  externalCellPhoneSequenceNumber?: number | null;
  externalHomePhoneSequenceNumber?: number | null;
  externalFaxNumberSequenceNumber?: number | null;
  externalWebSequenceNumber?: number | null;
  externalEmailSequenceNumber?: number | null;
  externalStatusCode?: string | null;
  ezidoxStakeholderId: string | null;
  faxNumber: string;
  ficoScoreOverride: boolean;
  gender: Gender;
  homePhone: string;
  id: string;
  isCompany: boolean;
  isFirstTimeHomeBuyer: boolean;
  newToCanada: boolean;
  isPrimary: boolean;
  isUploader: boolean | null;
  Jobs: Job[];
  languagePreference: LanguagePreference;
  lawyerId?: string;
  maritalStatus: MaritalType;
  middleName: string;
  name: string;
  nationality: string;
  placeOfBirth: CountryCodes;
  preferredContact: ContactType;
  registeredCountry: CountryCodes;
  relationshipToPrimaryApplicant: RelationshipToPrimaryApplicant;
  salutation: Salutation;
  sin: string | null;
  surname: string;
  templateSetId: number;
  tenantId: string;
  updatedAt: string;
  web: string;
  workPhone: string;
  suffix: string;
  politicallyExposedPersonStatus: PoliticallyExposedPersonType | null;
  fraudAMLRisk: FraudAMLRiskType[] | null;
  eqFraudAMLRisk: EQFraudAMLRiskType | null;
  thirdPartyDetermination: ThirdPartyDeterminationType | null;
  clientRiskScore: number | null;
  requestNameScreening: RequestNameScreeningType | null;
  clientIdentifiedInNameScreening: boolean | null;
  financialInstitution: FinancialInstitutionType | null;
  transitNumber: string | null;
  accountNumber: string | null;
  accountType: AccountType | null;
  clientId: string | null;
  expandedClientId: ExpandedClientId | null;
  clientScreeningCompleted: boolean | null;
  clientScreeningCompletedDate: string | null;
  clientLastScreeningDate: string | null;
  dmAttributeWarnings: { [key in ApplicantKey as string]?: string };
  relatedCIFs: string | null;
  customFields: { [key: string]: string };
  incomeVerification?: IncomeVerificationType | null;
  existingMemberOptions: ApplicantExistingMemberOption[] | null;
  restrictedPartyMember: boolean | null;
  staffMember: boolean | null;
  memberNumber: string | null;
  existingMemberOptionPoolingResults?: ApplicantBankingSearchExistingMemberResult | null;
  connectedParty?: boolean | null;
  connectedPartyCheckError?: unknown | null;
  isStudent: boolean | null;
  durationInCanada?: number | null;
  permanentResidenceFlag?: boolean | null;
  paymentSource: PaymentSource | null;
  memberBankingNumber: string | null;
  subNumber: string | null;
  financialInstitutionName?: string;
  financialInstitutionNumber?: string;
}

export interface ApplicantExistingMemberOption {
  id: string;
  applicantId: string;
  memberNumber: string | null;
  name: string | null;
  surname: string | null;
  dob: string | null;
  sin: string | null;
  branchFullName: string | null;
  status: ApplicantExistingMemberStatus | null;
  staffMember: boolean | null;
  isStudent: boolean | null;
  restrictedParty: boolean | null;
  connectedParty: boolean | null;
  createdAt: string;
}

export enum ApplicantExistingMemberStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export const ApplicantExistingMemberStatusRecord: Record<string, ApplicantExistingMemberStatus> = {
  ['active']: ApplicantExistingMemberStatus.ACTIVE,
  ['inactive']: ApplicantExistingMemberStatus.INACTIVE,
};

export interface StakeholderContact {
  // id: string;
  type: ContactType;
  value: string;
}

export enum LanguagePreference {
  ENGLISH = 'en-CA',
  FRENCH = 'fr-CA',
}

export enum ContactType {
  email = 'email',
  cellPhone = 'cellPhone',
  workPhone = 'workPhone',
  homePhone = 'homePhone',
  faxNumber = 'faxNumber',
  web = 'web',
}

export enum CustomerType {
  CUSTOMER = 'CUSTOMER',
  COMPANY = 'COMPANY',
  CO_BORROWER = 'CO_BORROWER',
  DIRECTOR = 'DIRECTOR',
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
  GUARANTOR = 'GUARANTOR',
  POA = 'POA',
  SIGNING_OFFICER = 'SIGNING_OFFICER',
  THIRD_PARTY = 'THIRD_PARTY',
  BROKER = 'BROKER',
  LAWYER = 'LAWYER',
  SUBMITTING_AGENT = 'SUBMITTING_AGENT',
  BROKERAGE = 'BROKERAGE',
}

export const customerTypeOrder: Record<CustomerType, number> = {
  [CustomerType.CUSTOMER]: 1,
  [CustomerType.COMPANY]: 2,
  [CustomerType.CO_BORROWER]: 3,
  [CustomerType.DIRECTOR]: 4,
  [CustomerType.BENEFICIAL_OWNER]: 5,
  [CustomerType.GUARANTOR]: 6,
  [CustomerType.POA]: 7,
  [CustomerType.SIGNING_OFFICER]: 8,
  [CustomerType.THIRD_PARTY]: 9,
  [CustomerType.BROKER]: 10,
  [CustomerType.LAWYER]: 11,
  [CustomerType.SUBMITTING_AGENT]: 12,
  [CustomerType.BROKERAGE]: 13,
};

export interface AddApplicantModel extends Applicant {
  nextApplicantId: string; // id for the next applicant in the UI
  docsDueDate: string;
  docToCollect: any;
  collectDocumentFromNextApplicant: boolean;
  reference: string;
  seeAllDocs: boolean;
  customerSeeDocsFromOtherParty: boolean;
}

export enum ApplicantAddressResidentialType {
  RESIDENTIAL = 'RESIDENTIAL',
  BUSINESS = 'BUSINESS',
  TEMPORARY = 'TEMPORARY',
  MAIL = 'MAIL',
  OTHER = 'OTHER',
  RECREATION = 'RECREATION',
  ALTERNATIVE = 'ALTERNATIVE',
}

export enum LiabilityActiveStatus {
  CLOSED = 'CLOSED',
  INACTIVE = 'INACTIVE',
}

export interface ApplicantCompanyDetailsType {
  id?: string;
  companyName: string;
  businessNumber?: string;
  incorporationNumber?: string;
  applicantId?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ApplicantCompanyDirectorDetailsType {
  id?: string;
  role?: string;
  networth?: number;
  applicantId?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ApplicantsFilter {
  name: string;
  applicantId: string | null;
}

export enum ApplicantAddressType {
  CURRENT = 'CURRENT',
  MAILING = 'MAILING',
  PREVIOUS = 'PREVIOUS',
}

export interface ApplicantResidence {
  address: string;
  residentialStatusType?: ResidentialStatusType;
  timeAtResidenceMonths?: number;
  type: ApplicantAddressType;
}

export enum AdditionalPartyTypes {
  CO_BORROWER = 'CO_BORROWER',
  GUARANTOR = 'GUARANTOR',
  THIRD_PARTY = 'THIRD_PARTY',
}

export enum EntityType {
  JOB = 'applicantJob',
  OTHER_INCOME = 'otherIncome',
}

export enum MaritalType {
  MARRIED = 'MARRIED',
  WIDOWED = 'WIDOWED',
  SEPARATED = 'SEPARATED',
  DIVORCED = 'DIVORCED',
  SINGLE = 'SINGLE',
  COMMON_LAW = 'COMMON_LAW',
  ENGAGED = 'ENGAGED',
  OTHER = 'OTHER',
}

export enum EmployedPaymentType {
  HOURLY_NON_GUARANTEED = 'HOURLY_NON_GUARANTEED',
  HOURLY_GUARANTEED = 'HOURLY_GUARANTEED',
  SALARIED = 'SALARIED',
  COMMISION_ONLY = 'COMMISION_ONLY',
}

export enum OccupationType {
  ADMINISTRATOR = 'ADMINISTRATOR',
  CLERICAL = 'CLERICAL',
  GOVERNMENT = 'GOVERNMENT',
  LABOURER = 'LABOURER',
  MANAGER = 'MANAGER',
  MEDICAL = 'MEDICAL',
  PROFESSIONAL = 'PROFESSIONAL',
  RETIRED = 'RETIRED',
  SALES = 'SALES',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  SERVICE = 'SERVICE',
  UNSPECIFIED = 'UNSPECIFIED',
  OTHER = 'OTHER',
}

export enum SelfEmployedPaymentType {
  T4 = 'T4',
  DIVIDEND = 'DIVIDEND',
  BUSINESS_INCOME = 'BUSINESS_INCOME',
  COMMISSION = 'COMMISSION',
}

export enum RelationshipToPrimaryApplicant {
  CHILD = 'CHILD',
  COMMON_LAW = 'COMMON_LAW',
  GRANDCHILD = 'GRANDCHILD',
  GRANDPARENT = 'GRANDPARENT',
  PARENT = 'PARENT',
  RELATED = 'RELATED',
  SIBLING = 'SIBLING',
  SPOUSE = 'SPOUSE',
  OTHER = 'OTHER',
}

export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  OTHER = 'OTHER',
}

export enum Salutation {
  MR = 'MR',
  MRS = 'MRS',
  MISS = 'MISS',
  MS = 'MS',
  MADAM = 'MADAM',
  DR = 'DR',
  JUDGE = 'JUDGE',
  PROF = 'PROF',
  REV = 'REV',
  SIR = 'SIR',
  OTHER = 'OTHER',
}

export enum EmploymentType {
  FULL_TIME = 'FULL_TIME',
  CONTRACT = 'CONTRACT',
  PART_TIME = 'PART_TIME',
  SEASONAL = 'SEASONAL',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
}

export interface NetworthContext {
  networth: number;
  totalAssets: number;
  totalLiabilities: number;
}

export enum BrokerUserType {
  UNUSED = 'Unused',
  SUPPORT_USER = 'Support User',
  REGIONAL_MANAGER = 'Regional Manager',
  ADMINISTRATOR = 'Administrator',
  ASSOCIATE = 'Associate',
  BROKER_OWNER = 'Broker(Owner)',
  FIRM_ADMIN = 'Firm Admin',
  SYS_ADMIN = 'Sys Admin',
  PASSIVE = 'Passive',
  ASSISTANT = 'Assistant',
}

export enum PoliticallyExposedPersonType {
  HIO = 'HIO',
  NOT_A_PEP = 'NOT_A_PEP',
  PEDP_AND_HIO = 'PEDP_AND_HIO',
  PEFP_AND_HIO = 'PEFP_AND_HIO',
  PEDP = 'PEDP',
  PEFP = 'PEFP',
}

export enum FraudAMLRiskType {
  DEMARKETED_CLIENT = 'DEMARKETED_CLIENT',
  HIGH_RISK_CLIENT = 'HIGH_RISK_CLIENT',
  NO_NEW_BUSINESS = 'NO_NEW_BUSINESS',
  SATR_FILED = 'SATR_FIELD',
  STR_FILED = 'STR_FIELD',
}

export enum ThirdPartyDeterminationType {
  YES = 'YES',
  NO = 'NO',
}

export enum RequestNameScreeningType {
  YES = 'YES',
  NO = 'NO',
}

export enum AccountType {
  CHEQUING = 'CHEQUING',
  SAVINGS = 'SAVINGS',
  MAXI = 'MAXI',
  CHEQ = 'CHEQ',
  ADVNTGE = 'ADVNTGE',
  OADV = 'OADV',
}

export enum ExternalAccountType {
  CHEQUING = AccountType.CHEQUING,
  SAVINGS = AccountType.SAVINGS,
}

export enum MeridianAccountType {
  MAXI = AccountType.MAXI,
  CHEQ = AccountType.CHEQ,
  ADVNTGE = AccountType.ADVNTGE,
  OADV = AccountType.OADV,
}

export enum FinancialInstitutionType {
  BANK_OF_MONTREAL = '001',
  SCOTIABANK = '002',
  ROYAL_BANK_OF_CANADA = '003',
  THE_TORONTO_DOMINION_BANK = '004',
  NATIONAL_BANK_OF_CANADA = '006',
  CANADIAN_IMPERIAL_BANK_OF_COMMERCE = '010',
  HSBC_BANK_CANADA = '016',
  CANADIAN_WESTERN_BANK = '030',
  LAURENTIAN_BANK_OF_CANADA = '039',
  BANK_OF_CANADA = '177',
  ALBERTA_TREASURY_BRANCHES = '219',
  ROYAL_BANK_OF_SCOTLAND_NV_CANADA_BRANCH = '240',
  BANK_OF_AMERICA_NATIONAL_ASSOCIATION = '241',
  THE_BANK_OF_NEW_YORK_MELLON = '242',
  BANK_OF_TOKYO_MITSUBISHI_UFJ_CANADA = '245',
  BNP_PARIBAS_CANADA = '250',
  CITIBANK_CANADA = '260',
  DEUTSCHE_BANK_AG = '265',
  MEGA_INTERNATIONAL_COMMERCIAL_BANK_CANADA = '269',
  JPMORGAN_CHASE_BANK_NATIONAL_ASSOCIATION = '270',
  KOREA_EXCHANGE_BANK_OF_CANADA = '275',
  MIZUHO_CORPORATE_BANK_LTD_CANADA_BRANCH = '277',
  UBS_BANK_CANADA = '290',
  SOCIÉTÉ_GÉNÉRALE_CANADA_BRANCH = '292',
  STATE_BANK_OF_INDIA_CANADA_ALBERTA = '294',
  SUMITOMO_MITSUI_BANKING_CORPORATION_OF_CANADA = '301',
  AMEX_BANK_OF_CANADA = '303',
  INDUSTRIAL_AND_COMMERCIAL_BANK_OF_CHINA_CANADA = '307',
  BANK_OF_CHINA_CANADA = '308',
  CITIZENS_BANK_OF_CANADA = '309',
  FIRST_NATIONS_BANK_OF_CANADA = '310',
  BOFA_CANADA_BANK = '311',
  JP_MORGAN_BANK_CANADA = '314',
  CTC_BANK_OF_CANADA = '315',
  US_BANK_NATIONAL_ASSOCIATION = '318',
  HABIB_CANADIAN_BANK = '321',
  RABOBANK_NEDERLAND = '322',
  CAPITAL_ONE_BANK_CANADA_BRANCH = '323',
  PRESIDENTS_CHOICE_FINANCIAL = '326',
  STATE_STREET = '327',
  CITIBANK_NA = '328',
  COMERICA_BANK = '330',
  FIRST_COMMERCIAL_BANK = '332',
  HSBC_BANK_USA_NATIONAL_ASSOCIATION = '333',
  PACIFIC_WESTERN_BANK_OF_CANADA = '334',
  UNITED_OVERSEAS_BANK_LIMITED = '335',
  MAPLE_BANK = '336',
  CANADIAN_TIRE_BANK = '338',
  UBS_AG_CANADA_BRANCH = '339',
  ICICI_BANK_CANADA = '340',
  BANK_WEST = '342',
  DUNDEE_BANK_OF_CANADA = '343',
  GENERAL_BANK_OF_CANADA = '344',
  FIFTH_THIRD_BANK = '345',
  SOCIÉTÉ_GÉNÉRALE_CANADA_BRANCH_ONTARIO = '346',
  BRIDGEWATER_BANK = '347',
  THE_NORTHERN_TRUST_COMPANY_CANADA_BRANCH = '349',
  DIRECTCASH_BANK = '352',
  JAMESON_BANK = '354',
  SHINHAN_BANK_CANADA = '355',
  M_T_BANK = '357',
  HOMEQUITY_BANK = '358',
  WALMART_CANADA_BANK = '359',
  BARCLAYS_BANK_PLC_CANADA_BRANCH = '360',
  MONCANA_BANK_OF_CANADA = '361',
  COMMUNITY_TRUST_COMPANY = '507',
  THE_CANADA_TRUST_COMPANY = '509',
  LAURENTIAN_TRUST_OF_CANADA_INC = '522',
  EFFORT_TRUST_COMPANY = '532',
  INVESTORS_GROUP_TRUST_CO_LTD = '536',
  MANULIFE_BANK_OF_CANADA = '540',
  CIBC_TRUST_CORPORATION = '548',
  MONTREAL_TRUST_COMPANY_OF_CANADA = '550',
  SUN_LIFE_FINANCIAL_TRUST_INC = '551',
  PEACE_HILLS_TRUST_COMPANY = '568',
  ROYAL_TRUST_COMPANY = '570',
  ROYAL_TRUST_CORPORATION_OF_CANADA = '580',
  NATIONAL_TRUST_COMPANY = '590',
  ROYAL_BANK_MORTGAGE_CORPORATION = '592',
  TD_MORTGAGE_CORPORATION = '597',
  TD_PACIFIC_MORTGAGE_CORPORATION = '603',
  HSBC_MORTGAGE_CORPORATION_CANADA = '604',
  SCOTIA_MORTGAGE_CORPORATION = '606',
  CS_ALTERNA_BANK = '608',
  ING_BANK_OF_CANADA = '614',
  B2B_BANK_FORMERLY_B2B_TRUST = '618',
  RESMOR_TRUST_COMPANY = '620',
  PEOPLES_TRUST_COMPANY = '621',
  THE_EQUITABLE_TRUST_COMPANY = '623',
  INDUSTRIAL_ALLIANCE_TRUST_INC = '625',
  MANULIFE_TRUST_COMPANY = '626',
  HOUSEHOLD_TRUST_COMPANY = '630',
  LATVIAN_CREDIT_UNION_LIMITED = '803',
  COMMUNICATION_TECHNOLOGIES_CREDIT_UNION_LIMITED = '807',
  ARNSTEIN_COMMUNITY_CREDIT_UNION_LIMITED = '808',
  CENTRAL_1_CREDIT_UNION_BRITISH_COLUMBIA = '809',
  ALL_TRANS_FINANCIAL_SERVICES_CREDIT_UNION_LIMITED = '810',
  LA_CONFÉDÉRATION_DES_CAISSES_POPULAIRES_ET_DÉCONOMIE_DESJARDINS_DU_QUÉBEC = '815',
  LA_FÉDÉRATION_DES_CAISSES_POPULAIRES_DU_MANITOBA_INC = '819',
  CENTRAL_1_CREDIT_UNION_ONTARIO = '828',
  LA_FÉDÉRATION_DES_CAISSES_POPULAIRES_DE_LONTARIO_INC = '829',
  AIRLINE_FINANCIAL_CREDIT_UNION_LIMITED = '830',
  MERIDIAN_CREDIT_UNION = '837',
  ATLANTIC_CENTRAL = '839',
  DUNDALK_DISTRICT_CREDIT_UNION_LIMITED = '840',
  ALTERNA_SAVINGS_AND_CREDIT_UNION = '842',
  GODERICH_COMMUNITY_CREDIT_UNION_LIMITED = '844',
  ONTARIO_CIVIL_SERVICE_CREDIT_UNION_LIMITED = '846',
  CONCENTRA_FINANCIAL_SERVICES_ASSOCIATION = '853',
  GOLDEN_HORSESHOE_CREDIT_UNION_LIMITED = '854',
  LA_FÉDÉRATION_DES_CAISSES_POPULAIRES_ACADIENNES_LIMITÉE = '865',
  CREDIT_UNION_CENTRAL_OF_MANITOBA_LIMITED = '879',
  CREDIT_UNION_CENTRAL_OF_SASKATCHEWAN = '889',
  ALLIANCE_DES_CAISSES_POPULAIRES_DE_LONTARIO_LIMITEE = '890',
  CREDIT_UNION_CENTRAL_ALBERTA_LIMITED = '899',
  MOTUS_BANK = '374',
  COMMUNITY_FIRST_CREDIT_UNION = '834',
  DESJARDINS_CREDIT_UNION = '829_1',
  DUCA_FINANCIAL_SERVICES_CREDIT_UNION = '828_1',
  LIBRO_CREDIT_UNION = '828_2',
  MBNA_CANADA_BANK = '311_1',
  TANDIA_CREDIT_UNION = '828_3',
  OTHER = 'OTHER',
}

export enum PaymentSource {
  MERIDIAN_ACCOUNT = 'MERIDIAN_ACCOUNT',
  EXTERNAL_ACCOUNT = 'EXTERNAL_ACCOUNT',
}

export interface ExpandedClientId {
  prefix: string;
  suffix: string;
  number: number;
  unique: boolean;
}
