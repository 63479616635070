import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { UpsertUserProfile } from '../features/settings/settings.models';

@Injectable({
  providedIn: 'root',
})
export class ProfileDataService {
  constructor(private http: HttpClient) {}

  public accountOwnDataUpdate(accountOwnDataUpdate: UpsertUserProfile) {
    return this.http.patch(`${environment.api_url}/account/own`, {
      lastName: accountOwnDataUpdate.lastName,
      firstName: accountOwnDataUpdate.firstName,
    });
  }
}
