import { Fee, Mortgage } from '@fundmoreai/models';
import { computeCappedFees } from './capped-fees.calculator';

export function computeLoanAmountForLTV(
  requestedMortgages: (Partial<Mortgage> | undefined)[] | null,
  fees: Fee[],
  capFeesMaxPercentage: number | undefined,
): number | undefined {
  let totalLoanAmount = requestedMortgages?.reduce((sum, x) => sum + (x?.loanAmount || 0), 0);

  if (!totalLoanAmount) {
    return undefined;
  }

  const capFees = computeCappedFees(fees, capFeesMaxPercentage);
  if (capFees) {
    totalLoanAmount += capFees;
  }

  return totalLoanAmount;
}
