import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  CGApplicationAdd,
  CGApplicationUpdate,
  CmhcApplicationData,
  InsuranceQuote,
  InsurerType,
  SagenApplicationData,
} from '@fundmoreai/models';
import { CGInsuranceRequest } from '@fundmoreai/models/src/models/insurance/cg/cgInsuranceQuoteRequest';
import fileSaver from 'file-saver';
import { map } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { CmhcSubmitApplicationPayload } from './cmhc/cmhc.model';
import { SagenApplicationAdd, SagenApplicationUpdate } from './sagen/sagen-application.model';

@Injectable({
  providedIn: 'root',
})
export class MortgageInsuranceService {
  constructor(private http: HttpClient) {}

  addSagenApplication(applicationId: string, payload: SagenApplicationAdd) {
    return this.http.post<InsuranceQuote>(
      `${environment.api_url}/insurances/sagen?applicationId=${applicationId}`,
      payload,
    );
  }

  updateSagenApplication(quoteId: string, payload: SagenApplicationUpdate) {
    return this.http.put<InsuranceQuote>(
      `${environment.api_url}/insurances/sagen?quoteId=${quoteId}`,
      payload,
    );
  }

  cancelSagenApplication(quoteId: string) {
    return this.http.delete<InsuranceQuote>(
      `${environment.api_url}/insurances/sagen?quoteId=${quoteId}`,
    );
  }

  inquireSagenApplicationStatus(quoteId: string) {
    return this.http.get<InsuranceQuote>(
      `${environment.api_url}/insurances/sagen?quoteId=${quoteId}`,
    );
  }

  inquireSagenApplicationTestApproveStatus(quoteId: string) {
    return this.http.get<InsuranceQuote>(
      `${environment.api_url}/insurances/sagen/test?quoteId=${quoteId}`,
    );
  }

  getApplicationQuotes(applicationId: string) {
    return this.http.get<InsuranceQuote[]>(
      `${environment.api_url}/insurances/quotes?applicationId=${applicationId}`,
    );
  }

  reviewSagenApplicationMappings(applicationId: string) {
    return this.http.get<SagenApplicationData>(
      `${environment.api_url}/insurances/sagen/mappings?applicationId=${applicationId}`,
    );
  }

  submitCmhcApplication(applicationId: string, payload: CmhcSubmitApplicationPayload) {
    return this.http.post<InsuranceQuote>(
      `${environment.fundmore_cmhc_api_url}/insurances/cmhc/submit?applicationId=${applicationId}`,
      payload,
    );
  }

  resubmitCmhcApplication(insuranceQuoteId: string, payload: CmhcSubmitApplicationPayload) {
    return this.http.put<InsuranceQuote>(
      `${environment.fundmore_cmhc_api_url}/insurances/cmhc/${insuranceQuoteId}/resubmit`,
      payload,
    );
  }

  cancelCmhcApplication(insuranceQuoteId: string) {
    return this.http.post<InsuranceQuote>(
      `${environment.fundmore_cmhc_api_url}/insurances/cmhc/${insuranceQuoteId}/cancel`,
      {},
    );
  }

  getCmhcApplication(insuranceQuoteId: string) {
    return this.http.get<InsuranceQuote>(
      `${environment.fundmore_cmhc_api_url}/insurances/cmhc/${insuranceQuoteId}`,
    );
  }

  getCmhcTestApplication(insuranceQuoteId: string) {
    return this.http.get<InsuranceQuote>(
      `${environment.fundmore_cmhc_api_url}/insurances/cmhc/${insuranceQuoteId}/test`,
    );
  }

  reviewCmhcApplicationMappings(applicationId: string, payload: CmhcSubmitApplicationPayload) {
    return this.http.post<CmhcApplicationData>(
      `${environment.fundmore_cmhc_api_url}/insurances/cmhc/mappings?applicationId=${applicationId}`,
      payload,
    );
  }

  reviewCGApplicationMappings(applicationId: string) {
    return this.http.get<CGInsuranceRequest>(
      `${environment.api_url}/insurances/canadaguaranty/mappings?applicationId=${applicationId}`,
    );
  }

  addCGApplication(applicationId: string, payload: CGApplicationAdd) {
    return this.http.post<InsuranceQuote>(
      `${environment.api_url}/insurances/canadaguaranty?applicationId=${applicationId}`,
      payload,
    );
  }

  updateCGApplication(quoteId: string, payload: CGApplicationUpdate) {
    return this.http.put<InsuranceQuote>(
      `${environment.api_url}/insurances/canadaguaranty?quoteId=${quoteId}`,
      payload,
    );
  }

  inquireCGApplication(quoteId: string) {
    return this.http.get<InsuranceQuote>(
      `${environment.api_url}/insurances/canadaguaranty?quoteId=${quoteId}`,
    );
  }

  inquireCGApplicationTestApproveStatus(quoteId: string) {
    return this.http.get<InsuranceQuote>(
      `${environment.api_url}/insurances/canadaguaranty/test?quoteId=${quoteId}`,
    );
  }

  cancelCGApplication(quoteId: string) {
    return this.http.delete<InsuranceQuote>(
      `${environment.api_url}/insurances/canadaguaranty?quoteId=${quoteId}`,
    );
  }

  downloadCertifax(quoteId: string, insurerType: InsurerType) {
    return this.http
      .get(
        `${environment.integrations_api_url}/insurance/certifax?quoteId=${quoteId}&insurer=${insurerType}`,
        {
          responseType: 'blob',
          observe: 'response',
        },
      )
      .pipe(
        map((response) => {
          // get the filename from the response headers
          const contentDisposition = response.headers.get('Content-Disposition');
          const filename = contentDisposition?.split(';')[1].split('filename=')[1];
          // save the file
          if (response.body) {
            fileSaver.saveAs(response.body, filename);
          } else {
            throw new Error('Could not download Certifax file');
          }
        }),
      );
  }
}
