import { Injectable } from '@angular/core';
import { FlipRenovationSchedule } from '@fundmoreai/models';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch, updateItem } from '@ngxs/store/operators';
import { finalize, tap } from 'rxjs/operators';
import { FlipRenovationService } from './flip-renovation.service';
import {
  CreateRenovationSchedule,
  DeleteRenovationSchedule,
  UpdateRenovationSchedule,
} from './flip.state.action';
import { ApplicationResetState } from '../../shared/state.model';
import { LoadingStart, LoadingEnd } from '../../core/loading.state';

interface FlipRenovationStateModel {
  renovationSchedules: FlipRenovationSchedule[];
}

@State<FlipRenovationStateModel>({
  name: 'flipRenovationScheduleState',
  defaults: { renovationSchedules: [] },
})
@Injectable()
export class FlipRenovationScheduleState {
  constructor(private flipRenovationScheduleService: FlipRenovationService) {}

  @Action(ApplicationResetState)
  reset(ctx: StateContext<FlipRenovationStateModel>) {
    ctx.setState({ renovationSchedules: [] });
  }

  @Selector() static renovationSchedules(state: FlipRenovationStateModel) {
    return state.renovationSchedules;
  }

  @Selector([FlipRenovationScheduleState.renovationSchedules])
  static renovationSchedulesTotalMonths(schedules: FlipRenovationSchedule[]) {
    // eslint-disable-next-line no-param-reassign
    return schedules.reduce((sum, schedule) => (sum += schedule.numberOfMonths), 0);
  }

  @Selector([FlipRenovationScheduleState.renovationSchedulesTotalMonths])
  static renovationSchedulesValid(totalMonth: number) {
    if (totalMonth > 0) {
      return '';
    }

    return $localize`Add Renovation Months to View an Accurate Analysis`;
  }

  @Action(CreateRenovationSchedule)
  createRenovationSchedule(
    ctx: StateContext<FlipRenovationStateModel>,
    { schedule }: CreateRenovationSchedule,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, ...schedulePayload } = schedule;
    return this.flipRenovationScheduleService.createRenovationSchedule(schedulePayload).pipe(
      tap((newSchedule) => {
        const state = ctx.getState();
        ctx.patchState({
          renovationSchedules: [newSchedule, ...state.renovationSchedules],
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateRenovationSchedule)
  updateRenovationSchedule(
    ctx: StateContext<FlipRenovationStateModel>,
    { schedule }: UpdateRenovationSchedule,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const { id, ...schedulePayload } = schedule;
    return this.flipRenovationScheduleService.updateRenovationSchedule(id, schedulePayload).pipe(
      tap(() => {
        ctx.setState(
          patch({
            renovationSchedules: updateItem<FlipRenovationSchedule>(
              (schedule) => schedule?.id === id,
              patch(schedulePayload),
            ),
          }),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(DeleteRenovationSchedule)
  deleteRenovationSchedule(
    ctx: StateContext<FlipRenovationStateModel>,
    { id }: DeleteRenovationSchedule,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.flipRenovationScheduleService.deleteRenovationSchedule(id).pipe(
      tap(() => {
        const state = ctx.getState();
        ctx.patchState({
          renovationSchedules: state.renovationSchedules.filter((schedule) => schedule.id !== id),
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }
}
