// https://github.com/puppeteer/puppeteer/blob/v5.4.1/docs/api.md#pagepdfoptions
export interface PdfOptions {
  scale?: number;
  displayHeaderFooter: boolean;
  headerTemplate?: string | null;
  footerTemplate?: string | null;
  printBackground?: boolean;
  landscape?: boolean;
  pageRanges?: string;
  format: string;
  width?: string | number;
  height?: string | number;
  margin: Margin;
  preferCSSPageSize?: boolean;
}

export interface Margin {
  bottom: string | number;
  left: string | number;
  right: string | number;
  top: string | number;
}
