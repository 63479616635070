import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { NotFoundComponent } from './core';
import { PortalGateResolver } from './portal/portal-gate-resolver.service';
import { UnsavedChangesGuard } from './core/unsavedChanges.guard';

const routes: Routes = [
  {
    path: 'portal',
    loadChildren: () => import('./portal/portal.module').then((m) => m.PortalModule),
    canLoad: [AuthGuard],
    data: { breadcrumb: { skip: true } },
    resolve: {
      gate: PortalGateResolver,
    },
  },
  {
    path: '',
    redirectTo: 'portal/pipeline',
    pathMatch: 'full',
  },
  {
    path: 'sso',
    loadComponent: () => import('./sso/sso.component').then((m) => m.SsoComponent),
  },
  {
    path: 'sso-logout',
    loadComponent: () => import('./sso/sso-logout.component').then((m) => m.SsoLogoutComponent),
  },
  {
    path: 'document',
    loadChildren: () => import('./features/document/document.module').then((m) => m.DocumentModule),
  },
  { path: '404', component: NotFoundComponent },
  {
    path: 'approvals',
    loadChildren: () =>
      import('./features/application/approvals/approvals.module').then((m) => m.ApprovalsModule),
  },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { bindToComponentInputs: true })],
  exports: [RouterModule],
  providers: [UnsavedChangesGuard],
})
export class AppRoutingModule {}
