<h1 i18n>MultiFactor Authentication</h1>
<p i18n>Enter the code generated by your Authenticator App</p>
<form [formGroup]="totpForm" (ngSubmit)="submitTotpForm()">
  <fieldset class="default-field" [disabled]="isSubmitting">
    <fieldset class="default-field mb-20">
      <label class="mb-5" for="totpToken" i18n>TOTP Token</label>
      <input name="totpToken" formControlName="totpToken" type="text" aria-label="totpToken" />
    </fieldset>
  </fieldset>
  <fieldset class="default-field mb-50">
    <a [routerLink]="[]" (click)="handleGoToLogin()" [class.disabled]="isSubmitting ? true : null">
      <ng-container i18n="@@action.backToLogin">Back to Login</ng-container>
    </a>
  </fieldset>
  <fieldset class="default-field">
    <button
      mat-flat-button
      color="primary"
      class="primary-btn"
      [disabled]="!totpForm.valid || isSubmitting"
      type="submit">
      <ng-container i18n="@@action.send">Send</ng-container>
    </button>
  </fieldset>
</form>
