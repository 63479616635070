import {
  FlipCost,
  FlipCostOperating,
  FlipPropertyProvince,
  Province,
  State,
} from '@fundmoreai/models';

export function calculateTotalFlipCost(flipCosts: FlipCost[]) {
  // eslint-disable-next-line no-param-reassign
  const total = flipCosts.reduce((sum, flipCost) => (sum += flipCost.amount), 0);
  return total;
}

export function calculateFlipCostProjectTotal(flipCost: FlipCostOperating, totalMonths: number) {
  const total = flipCost.amount * totalMonths;
  return Math.round(total * 100) / 100;
}

export function calculateFlipCostTotalProjectTotal(flipCosts: FlipCostOperating[]) {
  // eslint-disable-next-line no-param-reassign
  const total = flipCosts.reduce((sum, cost) => (sum += cost.projectTotal), 0);
  return Math.round(total * 100) / 100;
}

export function calculateFlipCostPercentageOfTotal(flipCost: FlipCostOperating, totalCost: number) {
  if (totalCost === 0) {
    return 0;
  }
  const percentageOfTotal = (flipCost.amount / totalCost) * 100;
  return Math.round(percentageOfTotal * 100) / 100;
}

export const interestCostByMonthCalcTooltip =
  'Interest Costs (month) = Interest Rate * (Loan Amount + Financing Fees) / 365 * 31)';
export const interestCostByDayCalcTooltip =
  'Interest Costs (day) = Interest Rate * (Loan Amount + Financing Fees) / 365)';

/**
 * Related to Financing Costs
 * @param loanAmount
 * @param netRate
 * @param financingFeeAmount
 * @returns interestCostByDay
 */
export function computeInterestCostByDay(
  loanAmount: number | null,
  netRate: number | null,
  financingFeeAmount: number,
): number {
  const interestCostByDay = (((netRate ?? 0) / 100) * (loanAmount + financingFeeAmount)) / 365;

  if (!interestCostByDay) {
    return 0;
  }

  return Math.round(interestCostByDay * 100) / 100;
}

export function computeMonthlyInterestCost(
  propertyProvince: string | undefined,
  interestCostByDay: number,
): number {
  let interestCostByMonth = 0;
  if (propertyProvince?.toLocaleLowerCase() === FlipPropertyProvince.ALBERTA) {
    interestCostByMonth = (365 / 12) * interestCostByDay;
  } else {
    interestCostByMonth = 31 * interestCostByDay;
  }
  return Math.round(interestCostByMonth * 100) / 100;
}

/**
 * Related to Financing Costs
 * @param renovationSchedulesTotalMonths
 * @param interestCostByMonth
 * @returns totalInterestCosts
 */
export function computeTotalInterestCosts(
  propertyProvince: Province | State | null | undefined,
  renovationSchedulesTotalMonths: number | null | undefined,
  interestCostByDay: number,
): number {
  const interestCostByMonth = computeMonthlyInterestCost(propertyProvince, interestCostByDay);
  return Math.round(renovationSchedulesTotalMonths * interestCostByMonth * 100) / 100;
}

export function computeOntarioPurchaseLandTransferTax(purchasePrice: number): number {
  let cost = 0;
  if (purchasePrice > 55000) {
    cost += 55000 * 0.005;
  }
  if (purchasePrice >= 250000) {
    cost += (250000 - 55000) * 0.01;
  } else if (purchasePrice < 250000 && purchasePrice >= 55000) {
    cost += (purchasePrice - 55000) * 0.01;
  }

  if (purchasePrice >= 400000) {
    cost += (400000 - 250000) * 0.015;
  } else if (purchasePrice < 400000 && purchasePrice >= 250000) {
    cost += purchasePrice - 250000 + 0.015;
  }

  if (purchasePrice > 400000) {
    cost += (purchasePrice - 400000) * 0.02;
  }
  return cost;
}

export function computeOntarioSellingRealEstateAgentFee(afterRepairValue: number): number {
  return afterRepairValue * 0.05;
}

export function computeAlbertaSellingRealEstateAgentFee(afterRepairValue: number) {
  return ((afterRepairValue - 100000) * 0.03 + 100000 * 0.07) * 1.05;
}
