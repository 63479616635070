export interface IDSGatewayBlocklist {
  id: number | null;
  listType: string;
  firstName: string;
  lastName: string;
  dateBirth: string;
  memberNumber: number | null;
  sin: string | null;
  streetNumber: string | null;
  streetName: string | null;
  city: string | null;
  province: string | null;
  postalCode: string | null;
  phoneNumber: string | null;
  phoneType: string | null;
  reasonOnBlockList: string | null;
  status: string | null;
  whoAdded: string | null;
  dateAdded: string | null;
  whoUpdated: string | null;
  dateUpdated: string | null;
  whoRemoved: string | null;
  dateRemoved: string | null;
  reasonRemoved: string | null;
}
