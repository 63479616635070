export interface EzidoxDocumentTemplateResponse {
  Id: number;
  Name: string;
  Description: string | null;
  Position: string | null;
  IsCustom: boolean;
  IsVisibleToAggregator: boolean;
  IsDefault: boolean;
  WelcomeMessage: string | null;
  TemplateGroupDtos: object[];
  TermsAndConditionsDtos: object[];
  OutDocumentsDtos: object[];
  StakeholderTypeIds: string[];
  DepartmentIds: string[] | null;
  HasCustomStyle: number;
  ShareLevel: number;
  CloneForAllEntities: boolean;
  Onboarding: boolean;
}

export enum EzidoxApplicationStatusEnum {
  DRAFT = 'DRAFT',
  IN_PROGRESS = 'IN_PROGRESS',
  SUBMITTED = 'SUBMITTED',
  DECLINED = 'DECLINED',
  GRANTED = 'GRANTED',
  DELETED = 'DELETED',
  ON_HOLD = 'ON_HOLD',
  PURGED = 'PURGED',
  CUSTOM_DELETED = 'CUSTOM_DELETED',
  COMMITMENT = 'COMMITMENT',
  ARCHIVE = 'ARCHIVE',
  FUNDING = 'FUNDING',
  POST_FUNDING = 'POST_FUNDING',
  CANCELLED = 'CANCELLED',
  INSTRUCTING = 'INSTRUCTING',
  SIGNED_BACK = 'SIGNED_BACK',
  READY_FOR_INSTRUCTING = 'READY_FOR_INSTRUCTING',
  READY_FOR_FUNDING = 'READY_FOR_FUNDING',
  FUNDED = 'FUNDED',
  CLOSED = 'CLOSED',
  FULFILLMENT = 'FULFILLMENT',
  FULFILLMENT_AND_FUNDING_REVIEW = 'FULFILLMENT_AND_FUNDING_REVIEW',
}

export enum EzidoxApplicationStatusInDM {
  DRAFT = 1,
  IN_PROGRESS = 3,
  SUBMITTED = 4,
  DECLINED = 5,
  GRANTED = 6,
  DELETED = 7,
  ON_HOLD = 8,
  PURGED = 11,
  CUSTOM_DELETED = 15,
  COMMITMENT = 16,
  ARCHIVE = 17,
  FUNDING = 18,
  POST_FUNDING = 19,
  CANCELLED = 20,
  INSTRUCTING = 21,
  SIGNED_BACK = 22,
  READY_FOR_INSTRUCTING = 23,
  READY_FOR_FUNDING = 24,
  FUNDED = 25,
  CLOSED = 26,
  FULFILLMENT = 27,
  FULFILLMENT_AND_FUNDING_REVIEW = 28,
}

export const EzidoxApplicationStatusEnumMap: Record<number, EzidoxApplicationStatusEnum> = {
  1: EzidoxApplicationStatusEnum.DRAFT,
  3: EzidoxApplicationStatusEnum.IN_PROGRESS,
  4: EzidoxApplicationStatusEnum.SUBMITTED,
  5: EzidoxApplicationStatusEnum.DECLINED,
  6: EzidoxApplicationStatusEnum.GRANTED,
  7: EzidoxApplicationStatusEnum.DELETED,
  8: EzidoxApplicationStatusEnum.ON_HOLD,
  11: EzidoxApplicationStatusEnum.PURGED,
  15: EzidoxApplicationStatusEnum.CUSTOM_DELETED,
  16: EzidoxApplicationStatusEnum.COMMITMENT,
  17: EzidoxApplicationStatusEnum.ARCHIVE,
  18: EzidoxApplicationStatusEnum.FUNDING,
  19: EzidoxApplicationStatusEnum.POST_FUNDING,
  20: EzidoxApplicationStatusEnum.CANCELLED,
  21: EzidoxApplicationStatusEnum.INSTRUCTING,
  22: EzidoxApplicationStatusEnum.SIGNED_BACK,
  23: EzidoxApplicationStatusEnum.READY_FOR_INSTRUCTING,
  24: EzidoxApplicationStatusEnum.READY_FOR_FUNDING,
  25: EzidoxApplicationStatusEnum.FUNDED,
  26: EzidoxApplicationStatusEnum.CLOSED,
  27: EzidoxApplicationStatusEnum.FULFILLMENT,
  28: EzidoxApplicationStatusEnum.FULFILLMENT_AND_FUNDING_REVIEW,
};
