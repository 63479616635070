import { Injectable } from '@angular/core';
import { CGFieldViewModel, CGInsuranceRequest, InsuranceQuote } from '@fundmoreai/models';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { tap, finalize } from 'rxjs';
import { ApplicationResetState } from '../../../../../../shared/state.model';
import { MortgageInsuranceService } from '../../mortgage-insurance.service';
import { LoadingStart, LoadingEnd } from '../../../../../../core/loading.state';

export class MortgageInsuranceCGApplicationSetQuote {
  static readonly type = '@mortgageInsurance.CGApplicationSetQuote';
  constructor(public insuranceQuote: InsuranceQuote) {}
}

export class MortgageInsuranceCGApplicationReset {
  static readonly type = '@mortgageInsurance.CGApplicationReset';
}

export class MortgageInsuranceCGApplicationMappingsReview {
  static readonly type = '@mortgageInsurance.CGApplicationMappingsReview';
  constructor(public applicationId: string) {}
}

interface CGInsuranceRequestStateModel {
  quote: InsuranceQuote | undefined;
  request: CGInsuranceRequest | undefined;
}

@State<CGInsuranceRequestStateModel>({
  name: 'CGInsuranceRequest',
  defaults: {
    quote: undefined,
    request: undefined,
  },
})
@Injectable()
export class CGInsuranceRequestState {
  constructor(private store: Store, private mortgageInsuranceService: MortgageInsuranceService) {}

  @Selector()
  static responseQuote(state: CGInsuranceRequestStateModel): InsuranceQuote | undefined {
    return state.quote;
  }

  @Selector()
  static responseReviewMappings(
    state: CGInsuranceRequestStateModel,
  ): CGInsuranceRequest | undefined {
    return state.request;
  }

  @Selector()
  static responseReviewFieldMappings(
    state: CGInsuranceRequestStateModel,
  ): CGFieldViewModel[] | undefined {
    return state.request?.viewModels;
  }

  @Action(MortgageInsuranceCGApplicationSetQuote)
  setQuote(
    ctx: StateContext<CGInsuranceRequestStateModel>,
    action: MortgageInsuranceCGApplicationSetQuote,
  ) {
    ctx.patchState({
      quote: action.insuranceQuote,
    });
  }

  @Action(MortgageInsuranceCGApplicationReset)
  resetQuote(ctx: StateContext<CGInsuranceRequestStateModel>) {
    ctx.patchState({
      quote: undefined,
      request: undefined,
    });
  }

  @Action(ApplicationResetState)
  resetState(ctx: StateContext<CGInsuranceRequestStateModel>) {
    ctx.setState({
      quote: undefined,
      request: undefined,
    });
  }

  @Action(MortgageInsuranceCGApplicationMappingsReview)
  reviewApplicationMappings(
    ctx: StateContext<CGInsuranceRequestStateModel>,
    action: MortgageInsuranceCGApplicationMappingsReview,
  ) {
    this.store.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageInsuranceService.reviewCGApplicationMappings(action.applicationId).pipe(
      tap((response) => {
        ctx.patchState({
          request: response,
        });
      }),
      finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }
}
