export interface PropertyInsurance {
  id: string;
  propertyId: string;
  applicationId: string;
  amount: number;
  percent: number;
  type: PropertyInsuranceType | null;
  insuranceCompany: string | null;
  policyNumber: string | null;
  expirationDate: string | null;
}

export enum PropertyInsuranceKey {
  PERCENT = 'percent',
  AMOUNT = 'amount',
  TYPE = 'type',
  INSURANCE_COMPANY = 'insuranceCompany',
  POLICY_NUMBER = 'policyNumber',
  EXPIRATION_DATE = 'expirationDate',
}

export enum PropertyInsuranceType {
  FIRE = 'FIRE',
  FLOOD = 'FLOOD',
  HOMEOWNER = 'HOMEOWNER',
  RENTERS = 'RENTERS',
  EARTHQUAKE = 'EARTHQUAKE',
  COURSE_OF_CONSTRUCTION_AND_LIABILITY = 'COURSE_OF_CONSTRUCTION_AND_LIABILITY',
  CONDO_CORPORATION_INSURANCE_BINDER = 'CONDO_CORPORATION_INSURANCE_BINDER',
  BARELAND_LIABILITY = 'BARELAND_LIABILITY',
  PROPERTY = 'PROPERTY',
  COMMERCIAL = 'COMMERCIAL',
  CONTENT = 'CONTENT',
  COMPREHENSIVE_GENERAL_LIABILITY = 'COMPREHENSIVE_GENERAL_LIABILITY',
  BOILER_EQUIPMENT = 'BOILER_EQUIPMENT',
  CONDO = 'CONDO',
}
