import {
  ApplicationConditionDocumentFile,
  ApplicationConditionDocumentFileStatusUpdate,
} from '@fundmoreai/models';
import { ApplicationDocumentToDocumentType } from 'src/app/features/shared/documents/model';

export class FetchApplicationConditionDocuments {
  static readonly type = '@applicationConditionDocuments.FetchApplicationConditionDocuments';
  constructor(public applicationId: string) {}
}
export class FetchApplicationConditionDocumentComments {
  static readonly type = '@applicationConditionDocuments.FetchApplicationConditionDocumentComments';
  constructor(public applicationConditionDocumentFileId: string) {}
}

export class CreateApplicationConditionDocumentFileEntries {
  static readonly type =
    '@applicationConditionDocuments.CreateApplicationConditionDocumentFileEntries';
  constructor(
    public applicationId: string,
    public stakeholderId: string,
    public applicationDocumentToDocumentType: ApplicationDocumentToDocumentType[],
    public stage?: string,
  ) {}
}

export class DeleteApplicationConditionDocument {
  static readonly type = '@applicationConditionDocuments.DeleteApplicationConditionDocument';
  constructor(public applicationConditionDocumentId: string, public stakeholderId: string) {}
}

export class UpdateApplicationConditionDocumentFileDetails {
  static readonly type =
    '@applicationConditionDocuments.UpdateApplicationConditionDocumentFileDetails';
  constructor(
    public applicationConditionDocumentId: string,
    public applicationId: string,
    public documentUpdates: ApplicationConditionDocumentFile,
  ) {}
}

export class ReviewApplicationConditionDocumentFile {
  static readonly type =
    '@applicationConditionDocuments.UpdateApplicationConditionDocumentFileStatus';
  constructor(
    public selectedConditionId: string | null,
    public stakeholderId: string,
    public applicationConditionDocumentId: string,
    public applicationId: string,
    public documentUpdates: ApplicationConditionDocumentFileStatusUpdate,
  ) {}
}

export class CreateApplicationConditionDocumentFileComment {
  static readonly type =
    '@applicationConditionDocuments.CreateApplicationConditionDocumentFileComment';
  constructor(public commentDTO: CreateApplicationConditionDocumentFileCommentDTO) {}
}

export class SetDocumentReview {
  static readonly type = '@applicationDocuments.SetDocumentReview';
  constructor(
    public conditionId: string | null,
    public documentId: string | null,
    public defaultChat = false,
  ) {}
}

export class MarkCommentAsSeen {
  static readonly type = '@applicationDocuments.MarkCommentAsSeen';
  constructor(
    public applicationConditionDocumentFileStakeholderId: string,
    public applicationConditionDocumentFileId: string,
    public applicationConditionDocumentFileCommentId: string,
  ) {}
}

export interface CreateApplicationConditionDocumentFileCommentDTO {
  stakeholderId: string;
  applicationConditionDocumentId: string;
  applicationId: string;
  text: string;
  reviewStatus?: string;
  reviewReason?: string;
  reviewComment?: string;
  shareExternally?: boolean;
  authorName?: string;
}

export interface ApplicationConditionDocumentFileByType {
  [key: string]: ApplicationConditionDocumentFile[];
}

export interface ConditionDocumentPreview {
  conditionId: string | null;
  documentId: string | null;
  defaultChat: boolean;
}

export interface ApplicationDocumentByStakeholder {
  [key: string]: ApplicationConditionDocumentFile[];
}

export interface ApplicationConditionDocumentsStateModel {
  applicationDocumentsByStakeholder?: ApplicationDocumentByStakeholder;
  failedUploads: ApplicationDocumentToDocumentType[];
  review: ConditionDocumentPreview;
}
