<h1 i18n>Reset your new password</h1>
<form [formGroup]="verifyConfirmPasswordForm" (ngSubmit)="submitVerifyConfirmPasswordForm()">
  <fieldset class="default-field" [disabled]="isSubmitting">
    <fieldset class="default-field mb-50">
      <label class="mb-5" for="password" i18n>Verification code</label>
      <input
        name="verificationCode"
        formControlName="verificationCode"
        type="verificationCode"
        aria-label="VerificationCode" />
    </fieldset>
    <fieldset class="default-field mb-50">
      <label class="mb-5" for="password" i18n>Password</label>
      <input name="password" formControlName="password" type="password" aria-label="Password" />
      <!-- <div *ngIf="verifyConfirmPasswordForm.touched && verifyConfirmPasswordForm.get('password').errors">
        <span *ngIf="verifyConfirmPasswordForm.get('password').errors.required">Required Field</span>
        <span *ngIf="verifyConfirmPasswordForm.get('password').errors.notEquivalent">No match</span>
      </div> -->
    </fieldset>
    <fieldset class="default-field mb-50">
      <label class="mb-5" for="password" i18n>Confirm Password</label>
      <input
        name="confirmPassword"
        formControlName="confirmPassword"
        type="password"
        aria-label="ConfirmPassword" />
      <!-- <div *ngIf="verifyConfirmPasswordForm.touched && verifyConfirmPasswordForm.get('confirmPassword').errors">
        <span *ngIf="verifyConfirmPasswordForm.get('confirmPassword').errors.required">Required Field</span>
        <span *ngIf="verifyConfirmPasswordForm.get('confirmPassword').errors.notEquivalent">No match</span>
      </div> -->
    </fieldset>
  </fieldset>
  <fieldset class="default-field mb-50">
    <a [routerLink]="[]" (click)="handleGoToLogin()" [class.disabled]="isSubmitting ? true : null">
      <ng-container i18n="@@action.backToLogin">Back to Login</ng-container>
    </a>
  </fieldset>
  <fieldset class="default-field">
    <button
      mat-flat-button
      color="primary"
      class="primary-btn"
      [disabled]="!verifyConfirmPasswordForm.valid || isSubmitting"
      type="submit">
      <ng-container i18n="@@action.submit">Submit</ng-container>
    </button>
  </fieldset>
</form>
