export enum ApplicantBankingSearchExistingMemberResultStatus {
  COMPLETED_WITH_RESULTS = 'COMPLETED_WITH_RESULTS',
  COMPLETED_WITHOUT_RESULTS = 'COMPLETED_WITHOUT_RESULTS',
  APPLICANT_VALIDATION_ERROR = 'APPLICANT_VALIDATION_ERROR',
  IDS_GATEWAY_ERROR = 'IDS_GATEWAY_ERROR',
}

export interface ApplicantBankingSearchExistingMemberResult {
  existingMemberOptionResponseStatus?: ApplicantBankingSearchExistingMemberResultStatus;
  existingMemberOptionCompletedDate?: Date;
}
