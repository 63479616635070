import { MMSDealStatus, MMSTransactionStatus } from './enums';
import {
  MMSDisbursementFetchResponse,
  MMSFieldMetadata,
  MMSMilestone,
  MMSNotesFetchResponse,
  MMSRequest,
  MMSResponse,
} from './models';

export interface FCTMMSResponse {
  tenantId: string;
  applicationId: string;
  fctURN: string;
  request?: MMSRequest;
  response?: MMSResponse;
  notes?: MMSNotesFetchResponse;
  disbursements?: MMSDisbursementFetchResponse;
  milestones?: MMSMilestone[];
  dealStatus?: MMSDealStatus;
  transactionStatus?: MMSTransactionStatus;
  requestMetadata?: MMSFieldMetadata[];
  createdAt?: Date;
  updatedAt?: Date;
  deletedAt?: Date;
}
export interface MMSCreateRequest {
  applicationId: string;
  propertyId: string;
  mortgageId: string;
  lawyerId: string;
  productId?: string;
  lenderRemarks?: string;
  registeredAmount?: number;
  metadata?: MMSFieldMetadata[];
}
