import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Flip, Province, State } from '../../shared/model';

@Injectable({
  providedIn: 'root',
})
export class FlipService {
  constructor(private http: HttpClient) {}

  getFlipDetails(applicationId: string) {
    return this.http.get<Flip>(`${environment.api_url}/flips?applicationId=${applicationId}`);
  }

  updateFlipDetails(flipId: string, flipPayload: Partial<Flip>) {
    return this.http.patch<void>(`${environment.api_url}/flips/${flipId}`, flipPayload);
  }

  resetFlipDefaults(flipId: string, province: Province | State) {
    return this.http.put<Flip>(`${environment.api_url}/flips/${flipId}/reset`, { province });
  }
}
