import { Action, Selector, State, StateContext, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { ResetState } from 'src/app/shared';
import { DefaultWidget, ExpandWidget, SaveWidgets, ShowEditMode } from './dashboard.actions';
import { ResetEditModeState } from 'src/app/shared/state.model';

interface DashboardStateModel {
  version: number;
  loading: boolean;
  widgets: DashboardWidget[];
  widgetMobile: DashboardWidget[];
}

export interface DashboardCard {
  cols: number;
  rows: number;
  expanded: boolean;
  editMode: boolean;
  position: number;
}
export interface DashboardWidget extends DashboardCard {
  widget: Widget;
}

export type Widget =
  | 'income'
  | 'credit'
  | 'networth'
  | 'stakeholders'
  | 'loan'
  | 'results'
  | 'summary'
  | 'score'
  | 'construction'
  | 'flip'
  | 'conditions';

export const widgetConfig: {
  default: DashboardWidget[];
  large: DashboardWidget[];
  small: DashboardWidget[];
} = {
  default: [
    { widget: 'summary', cols: 1, rows: 2, expanded: true, editMode: false, position: 1 },
    { widget: 'score', cols: 1, rows: 2, expanded: true, editMode: false, position: 2 },
    { widget: 'results', cols: 1, rows: 2, expanded: true, editMode: false, position: 3 },
    { widget: 'flip', cols: 2, rows: 3, expanded: true, editMode: false, position: 4 },
    { widget: 'construction', cols: 2, rows: 3, expanded: true, editMode: false, position: 6 },
    { widget: 'loan', cols: 2, rows: 5, expanded: true, editMode: false, position: 7 },
    { widget: 'credit', cols: 1, rows: 2, expanded: false, editMode: false, position: 8 },
    { widget: 'income', cols: 1, rows: 2, expanded: false, editMode: false, position: 9 },
    { widget: 'networth', cols: 2, rows: 3, expanded: true, editMode: false, position: 10 },
    { widget: 'conditions', cols: 2, rows: 6, expanded: true, editMode: false, position: 12 },
    { widget: 'stakeholders', cols: 2, rows: 4, expanded: true, editMode: false, position: 11 },
  ],
  large: [
    { widget: 'summary', cols: 2, rows: 4, expanded: true, editMode: false, position: 1 },
    { widget: 'score', cols: 2, rows: 4, expanded: true, editMode: false, position: 2 },
    { widget: 'results', cols: 2, rows: 4, expanded: true, editMode: false, position: 3 },
    { widget: 'loan', cols: 2, rows: 4, expanded: true, editMode: false, position: 4 },
    { widget: 'credit', cols: 2, rows: 4, expanded: true, editMode: false, position: 5 },
    { widget: 'income', cols: 2, rows: 3, expanded: true, editMode: false, position: 6 },
    { widget: 'networth', cols: 2, rows: 5, expanded: true, editMode: false, position: 7 },
    { widget: 'construction', cols: 2, rows: 3, expanded: true, editMode: false, position: 9 },
    { widget: 'flip', cols: 2, rows: 3, expanded: true, editMode: false, position: 10 },
    { widget: 'conditions', cols: 2, rows: 6, expanded: true, editMode: false, position: 11 },
    { widget: 'stakeholders', cols: 2, rows: 4, expanded: true, editMode: false, position: 8 },
  ],
  small: [
    { widget: 'summary', cols: 2, rows: 2, expanded: false, editMode: false, position: 1 },
    { widget: 'score', cols: 2, rows: 2, expanded: false, editMode: false, position: 2 },
    { widget: 'results', cols: 2, rows: 2, expanded: false, editMode: false, position: 3 },
    { widget: 'loan', cols: 2, rows: 3, expanded: false, editMode: false, position: 4 },
    { widget: 'credit', cols: 2, rows: 2, expanded: false, editMode: false, position: 5 },
    { widget: 'income', cols: 2, rows: 2, expanded: false, editMode: false, position: 6 },
    { widget: 'networth', cols: 2, rows: 6, expanded: false, editMode: false, position: 7 },
    { widget: 'stakeholders', cols: 2, rows: 2, expanded: false, editMode: false, position: 8 },
    { widget: 'construction', cols: 2, rows: 4, expanded: false, editMode: false, position: 9 },
    { widget: 'flip', cols: 2, rows: 3, expanded: false, editMode: false, position: 10 },
    { widget: 'conditions', cols: 2, rows: 4, expanded: true, editMode: false, position: 11 },
  ],
};

@State<DashboardStateModel>({
  name: DashboardState.NAME,
  defaults: DashboardState.DEFAULT_STATE_VALUES,
})
@Injectable()
export class DashboardState {
  static NAME = 'dashboard';
  static DEFAULT_STATE_VALUES = {
    version: 19,
    loading: false,
    widgetMobile: widgetConfig.small,
    widgets: widgetConfig.default,
  };

  @Selector() static cards(state: DashboardStateModel) {
    return state.widgets;
  }

  @Selector() static cardsMobile(state: DashboardStateModel) {
    return state.widgetMobile;
  }

  static inEditMode(widget: Widget) {
    return createSelector([DashboardState], (state: DashboardStateModel) => {
      const stateWidget = state.widgets.find((w) => w.widget === widget);
      return stateWidget?.editMode;
    });
  }

  @Action(ExpandWidget)
  expandWidget(ctx: StateContext<DashboardStateModel>, { value }: ExpandWidget) {
    const widgetLarge = widgetConfig.large.find((a) => a.widget === value.widget);

    if (!widgetLarge) {
      return;
    }

    const state = ctx.getState();
    const widgets = state.widgets.map((widgetObj) =>
      widgetObj.widget === value.widget ? { ...widgetLarge } : widgetObj,
    );
    const widgetMobile = state.widgetMobile.map((widgetObj) =>
      widgetObj.widget === value.widget ? { ...widgetLarge } : widgetObj,
    );

    ctx.patchState({
      ...state,
      widgets,
      widgetMobile,
    });
  }

  @Action(DefaultWidget)
  defaultWidget(ctx: StateContext<DashboardStateModel>, { value }: DefaultWidget) {
    const widgetDefault = widgetConfig.default.find((a) => a.widget === value.widget);

    if (!widgetDefault) {
      return;
    }

    const state = ctx.getState();
    const widgets = state.widgets.map((widgetObj) =>
      widgetObj.widget === value.widget ? { ...widgetDefault } : widgetObj,
    );
    const widgetMobile = state.widgetMobile.map((widgetObj) =>
      widgetObj.widget === value.widget ? { ...widgetDefault } : widgetObj,
    );

    ctx.patchState({
      ...state,
      widgets,
      widgetMobile,
    });
  }

  @Action(ShowEditMode)
  showEditMode(ctx: StateContext<DashboardStateModel>, { value }: ShowEditMode) {
    const state = ctx.getState();
    const widgets = state.widgets.map((widgetObj) =>
      widgetObj.widget === value.widget
        ? { ...widgetObj, editMode: !widgetObj.editMode }
        : widgetObj,
    );
    const widgetMobile = state.widgetMobile.map((widgetObj) =>
      widgetObj.widget === value.widget
        ? { ...widgetObj, editMode: !widgetObj.editMode }
        : widgetObj,
    );

    ctx.patchState({
      ...state,
      widgets,
      widgetMobile,
    });
  }

  @Action(SaveWidgets)
  saveWidgets(ctx: StateContext<DashboardStateModel>, { value }: SaveWidgets) {
    // action dispatched when reordering widgets; reordering is enabled only for large screens
    if (value.length !== widgetConfig.default.length) {
      const state = ctx.getState();
      const defaultWidgets =
        state.widgets.length === widgetConfig.default.length ? state.widgets : widgetConfig.default;
      // add filtered/missing widgets back to state (in case they are removed on dashboard)
      const missingWidgets = [...defaultWidgets].filter(
        (a) => !value.find((v) => v.widget === a.widget),
      );
      let reorderedWidgets = this.regenerateWidgetsPosition(value);

      reorderedWidgets = missingWidgets.concat(reorderedWidgets);
      // order by position
      reorderedWidgets = reorderedWidgets.sort((a, b) => a.position - b.position);

      ctx.patchState({
        widgets: [...reorderedWidgets],
      });

      return;
    }

    ctx.patchState({
      widgets: [...value],
    });
  }

  @Action(ResetState)
  resetState(ctx: StateContext<DashboardStateModel>) {
    ctx.patchState({ ...DashboardState.DEFAULT_STATE_VALUES });
  }

  @Action(ResetEditModeState)
  resetEditModeState(ctx: StateContext<DashboardStateModel>) {
    const state = ctx.getState();
    const widgets = state.widgets.map((widget: DashboardWidget) => ({
      ...widget,
      editMode: false,
    }));
    const widgetMobile = state.widgetMobile.map((widget: DashboardWidget) => ({
      ...widget,
      editMode: false,
    }));

    ctx.patchState({ widgets });
    ctx.patchState({ widgetMobile });
  }

  // function to regenerate widget positions
  private regenerateWidgetsPosition(value: DashboardWidget[]) {
    let index = 1;
    const newWidgets: DashboardWidget[] = [];
    value.forEach((widget) => {
      newWidgets.push({ ...widget, position: index });
      index++;
    });

    return newWidgets;
  }
}
