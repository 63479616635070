import { Injectable, inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {
  catchError,
  debounceTime,
  Observable,
  of,
  retry,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { CognitoService } from './cognito.service';
import { Store } from '@ngxs/store';
import { FetchHistoryLogs } from '../features/application/history-logs/history-logs.actions';
import { SectionOpenedState } from '../core/section-open.state';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private patchPostInterceptedSubject = new Subject<void>();
  router = inject(Router);
  authService = inject(AuthService);
  constructor(private cognitoService: CognitoService, private store: Store) {
    this.patchPostInterceptedSubject
      .asObservable()
      .pipe(
        debounceTime(500),
        tap(() => this.store.dispatch(new FetchHistoryLogs())),
      )
      .subscribe();
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const headersConfig: { [key: string]: string } = {
      Accept: 'application/json',
    };

    return this.cognitoService.getIdToken().pipe(
      retry({ count: 3, delay: 1000 }),
      take(1),
      catchError((err) => {
        console.error('Error checking authentication status: ', err);
        this.authService.reLogin();
        return of('No token found');
      }),
      switchMap((token) => {
        if (token) {
          headersConfig.Authorization = `${token}`;
        }

        const requestClone = request.clone({ setHeaders: headersConfig });
        return next.handle(requestClone);
      }),
      tap(() => {
        // refresh history logs when patch/post requests are intercepted
        if (
          (request.method === 'POST' || request.method === 'PATCH') &&
          this.store.selectSnapshot(SectionOpenedState.isOpen('history-logs'))
        ) {
          this.patchPostInterceptedSubject.next();
        }
      }),
    );
  }
}
