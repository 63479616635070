import { ConditionKey } from '@fundmoreai/models';
import { ConditionKeyRecord } from 'src/app/shared/enum-records-metadata';
import { Widget } from '../../application/dashboard.state';
import { Condition } from '../../shared/documents';
export interface ManageConditionsModel {
  conditions?: {
    [key: string]: Condition;
  };
  loading: boolean;
}

export interface Column {
  field: string;
  name: string;
  isSelected: boolean;
  isFrozen: boolean;
  isOptional?: boolean;
}

// TODO check this as it might duplicate ConditionKey
export enum ConditionTableKey {
  INDEX = 'index',
  NAME = 'name',
  STATUS = 'status',
  TEXT = 'text',
  TEXT_FRENCH = 'text_french',
  DM_DOCUMENT = 'dmDocument',
  APPLICATION_PURPOSE = 'applicationPurpose',
  PRODUCTS = 'products',
  IS_DEFAULT = 'isDefault',
  RESPONSIBLE = 'responsible',
  CONDITION_TYPE = 'conditionType',
}

export const ConditionTableKeyRecord: Record<Partial<ConditionTableKey>, string> = {
  [ConditionTableKey.NAME]: ConditionKeyRecord[ConditionKey.NAME],
  [ConditionTableKey.STATUS]: ConditionKeyRecord[ConditionKey.STATUS],
  [ConditionTableKey.TEXT]: ConditionKeyRecord[ConditionKey.TEXT],
  [ConditionTableKey.TEXT_FRENCH]: ConditionKeyRecord[ConditionKey.TEXT_FRENCH],
  [ConditionTableKey.DM_DOCUMENT]: ConditionKeyRecord[ConditionKey.DM_DOCUMENT],
  [ConditionTableKey.PRODUCTS]: ConditionKeyRecord[ConditionKey.PRODUCTS],
  [ConditionTableKey.IS_DEFAULT]: ConditionKeyRecord[ConditionKey.IS_DEFAULT],
  [ConditionTableKey.RESPONSIBLE]: ConditionKeyRecord[ConditionKey.RESPONSIBLE],
  [ConditionTableKey.APPLICATION_PURPOSE]: ConditionKeyRecord[ConditionKey.APPLICATION_PURPOSE],
  [ConditionTableKey.CONDITION_TYPE]: ConditionKeyRecord[ConditionKey.CONDITION_TYPE],
  [ConditionTableKey.INDEX]: ConditionKeyRecord[ConditionKey.INDEX],
};

export const WidgetTypeRecord: Record<Widget, string> = {
  income: $localize`Income`,
  credit: $localize`Credit`,
  networth: $localize`Networth`,
  stakeholders: $localize`Stakeholders`,
  loan: $localize`Loan`,
  results: $localize`Results`,
  summary: $localize`Summary`,
  score: $localize`Score`,
  construction: $localize`Construction`,
  flip: $localize`Flip`,
  conditions: $localize`Conditions`,
};
