import { DownPayment, FinancialAsset } from '@fundmoreai/models';

export interface DownPaymentStateModel {
  downPayments: DownPayment[];
}

export interface DownPaymentStateActionOption {
  updateFlip: boolean;
}

export class AddLocalDownPayment {
  static readonly type = '@downPayments.AddLocalDownPayment';
  constructor(
    public applicationId: string,
    public downPayment: Partial<DownPayment>,
    public options?: DownPaymentStateActionOption,
  ) {}
}

export class RemoveDownPayment {
  static readonly type = '@downPayments.RemoveDownPayment';
  constructor(
    public applicationId: string,
    public downPayment: DownPayment,
    public options?: DownPaymentStateActionOption,
  ) {}
}

export class RemoveAssetDownPayment {
  static readonly type = '@downPayments.RemoveAssetDownPayment';
  constructor(public assetId: string) {}
}

export class AddDownPaymentLocal {
  static readonly type = '@downPayments.AddDownPaymentLocal';
  constructor(public downPayment: DownPayment) {}
}

export class UpdateAssetDownPayment {
  static readonly type = '@downPayments.UpdateAssetDownPayment';
  constructor(public asset: FinancialAsset) {}
}

export class AddDownPaymentsBulk {
  static readonly type = '@downPayments.AddDownPaymentsBulk';
  constructor(public applicationId: string, public downPayments: DownPayment[]) {}
}

export class UpsertDownPayment {
  static readonly type = '@downPayments.UpsertDownPayment';
  constructor(
    public applicationId: string,
    public downPayment: DownPayment,
    public options?: DownPaymentStateActionOption,
  ) {}
}

export class SetDownPayments {
  static readonly type = '@downPayments.SetDownPayments';
  constructor(public downPayments: DownPayment[]) {}
}

export class AddBulkDownPaymentsAndComputeFlip {
  static readonly type = '@downPayments.AddBulkDownPaymentsAndComputeFlip';
  constructor(public applicationId: string, public downPayments: DownPayment[]) {}
}
