/* eslint-disable @typescript-eslint/no-explicit-any */
import { KeyValue } from '@angular/common';
import { FieldOption } from '@fundmoreai/models';
import { MVIDX } from '../portal/notes/model';

// Accepts the array and key
export function groupBy(array: any, key: any) {
  // Return the end result
  return array.reduce((result: any[], currentValue: any) => {
    // If an array already present for key, push it to the array. Else create an array and push the object
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
    return result;
  }, {}); // empty object is the initial value for result object
}

// Replacement for lodash.set
// ref: https://stackoverflow.com/questions/54733539/javascript-implementation-of-lodash-set-method/54733755#54733755
export const setByPath = (obj: object, path: string, value: any) => {
  // Ignore paths that have not had the custom index replaced.
  if (path.includes(MVIDX)) {
    return obj;
  }

  // Turn the path into an array of keys.
  const keys = path.toString().match(/[^.[\]]+/g) || [];

  // Travel through the object. Either assign the value to an
  // existing key or create a new key. New keys either have
  // arrays or objects as values (key can be used as an index).
  // Assign the given value to the last key in the path.
  keys
    .slice(0, -1)
    .reduce(
      (a: any, c: any, i: number) =>
        Object(a[c]) === a[c]
          ? a[c]
          : (a[c] = Math.abs(Number(keys[i + 1])) >> 0 === +Number(keys[i + 1]) ? [] : {}),
      obj,
    )[keys[keys.length - 1]] = value;

  return obj;
};

// Value order sort for the keyvalue pipe
export const valueOrderSort = (
  a: KeyValue<string, string>,
  b: KeyValue<string, string>,
): number => {
  if (!isNaN(parseInt(a.value)) && !isNaN(parseInt(b.value))) {
    return parseInt(a.value) - parseInt(b.value);
  }
  return a.value?.localeCompare(b.value);
};

// order asc an array os string numbers; eg. ['2', '0', '5'] => ['0', '2', '5']
export function sortArrayOfStringNumbers(array: string[]): string[] {
  return array.sort((a, b) => {
    return +a - +b || (isNaN(+a) as any) - (isNaN(+b) as any);
  });
}

// Map a NewTable data item for usage with pdf upload.
// Take a value based input and try to find and replace a matching key.
export function handleTableDataMapping(
  dataInput: (string | null)[][],
  options: { [key: string]: string },
  index: number,
) {
  const data = [...dataInput];

  if (data.length < 3) {
    return data;
  }

  for (let i = 1; i < data.length - 1; i++) {
    data[i] = mapTableDataItem(data[i], options, index);
  }

  return data;
}

// In and array replace an index value with the key for a
// given value if possible.
export function mapTableDataItem(
  data: (string | null)[],
  options: { [key: string]: string },
  index: number,
): (string | null)[] {
  const updatedData = [...data];
  const value = updatedData[index];
  const mappedValue = findKeyForValue(value, options);

  updatedData[index] = mappedValue ?? value;

  return updatedData;
}

// Given some options find the key for the given value.
export function findKeyForValue(
  value: string | null,
  options: { [key: string]: string },
): string | undefined {
  if (value == null) {
    return;
  }

  const optionEntries = Object.entries(options);
  const matchingEntry = optionEntries.find(
    ([, optValue]) =>
      optValue?.toString().toLowerCase().trim() === value?.toString().toLowerCase().trim(),
  );

  if (!matchingEntry) {
    return;
  }

  const [key] = matchingEntry;
  return key;
}

// Given a string that is assumed to be a SQFT count, strip its units
export function stripSQFT(value: string | undefined | null) {
  return typeof value === 'string'
    ? value?.toLowerCase()?.replace(/\s/g, '')?.replace('sqft', '')?.replace('sq/ft', '')
    : value;
}

// For some options return subset of options based on particular enum
export function getOptionsByEnum(
  options: { [key: string]: string },
  enumValues: { [key: string]: string },
) {
  const updatedOptions: FieldOption = {};

  Object.entries(options).forEach(([optKey, optValue]) => {
    if (Object.keys(enumValues).includes(optKey)) {
      updatedOptions[optKey] = optValue;
    }
  });
  return updatedOptions;
}

// Compare two values given an order
export function compare(
  a: Date | number | string | null | undefined,
  b: Date | number | string | null | undefined,
  isAsc: boolean,
) {
  if (a === b) {
    return 0;
  }

  if (a == null || b == null) {
    return a == null ? (isAsc ? 1 : -1) : isAsc ? -1 : 1;
  }

  if (typeof a === 'number' && typeof b === 'number') {
    return (a - b) * (isAsc ? 1 : -1);
  }

  if (typeof a === 'string' && typeof b === 'string') {
    return a.localeCompare(b) * (isAsc ? 1 : -1);
  }

  if (a instanceof Date && b instanceof Date) {
    return (a.getTime() - b.getTime()) * (isAsc ? 1 : -1);
  }

  // If types are mixed or invalid, default to returning 0
  return 0;
}
