export function computeFinalCommission(
  standardCommission: number | null | undefined,
  lessBuydown: number | null | undefined,
): number | undefined {
  if (!standardCommission) {
    return undefined;
  }
  return Math.round((standardCommission - (lessBuydown ?? 0)) * 100) / 100;
}

export function computeTotalFunds(
  newFunds: number | null | undefined,
  brokerReductionFee: number | null | undefined,
  finalCommission: number | null | undefined,
): number | undefined {
  if (!newFunds || !finalCommission) {
    return undefined;
  }

  return Math.round((newFunds * finalCommission) / 100 - (brokerReductionFee ?? 0));
}

export function computeLessBuydown(buydown: number | null | undefined) {
  if (!buydown) {
    return 0;
  }

  return Math.round((buydown / 0.05) * 0.2 * 100) / 100;
}
