import { FCTLoanPriority } from '../enums/loanPriority';
import { FCTLoanPurpose } from '../enums/loanPurpose';
import { FCTSaleType } from '../enums/saleType';
import { FCTDocument } from './document';
import { FCTMarketRentInfo } from './marketRentInfo';
import { FCTNote } from './note';

export interface FCTDealInfo {
  LoanAmount: number;
  LoanPurpose: FCTLoanPurpose;
  LenderReference: string;
  CMHCReference: string;
  LoanPriority: FCTLoanPriority;
  SaleType: FCTSaleType;
  SpecialInstructions: string;
  Notes: FCTNote[];
  ExistingFCTReference: number; // bigint
  Documents: FCTDocument[];
  RushFlag: boolean;
  AdditionalNotificationEmail: string;
  MarketRentInfo: FCTMarketRentInfo;
}
