import { SupportedCustomEntity, ApplicationKey, MortgageKey } from '@fundmoreai/models';

export const LOCKED_FIELDS_BY_DEFAULT: {
  tableName: SupportedCustomEntity;
  fieldName: ApplicationKey | MortgageKey;
}[] = [
  { tableName: SupportedCustomEntity.MORTGAGE, fieldName: MortgageKey.PST },
  { tableName: SupportedCustomEntity.APPLICATION, fieldName: ApplicationKey.DOWN_PAYMENT_TOTAL },
  { tableName: SupportedCustomEntity.MORTGAGE, fieldName: MortgageKey.AMOUNT_TO_BE_ADVANCED },
  { tableName: SupportedCustomEntity.MORTGAGE, fieldName: MortgageKey.ORIGINAL_NET_RATE },
];
