/* eslint-disable max-len */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import {
  ApplicantFinancialLiability,
  FinancialLiability,
  FinancialLiabilityRecordType,
} from '../../../../shared';
import { LiabilityOverride } from '@fundmoreai/models';

@Injectable({
  providedIn: 'root',
})
export class FinancialLiabilitiesService {
  constructor(private http: HttpClient) {}

  getFinancialLiabilities(applicationId: string): Observable<FinancialLiability[]> {
    return this.http
      .get<FinancialLiability[]>(
        `${environment.api_url}/applications/${applicationId}/financialLiabilities`,
      )
      .pipe(catchError(this.formatErrors));
  }

  addFinancialLiabilities(
    applicationId: string,
    financialLiabilities: Partial<FinancialLiability>[],
  ): Observable<FinancialLiability[]> {
    return this.http
      .post<FinancialLiability[]>(
        `${environment.api_url}/applications/${applicationId}/financialLiabilities`,
        financialLiabilities,
      )
      .pipe(catchError(this.formatErrors));
  }

  updateFinancialLiability(
    applicationId: string,
    financialLiabilityId: string,
    financialLiability: FinancialLiability,
  ): Observable<void> {
    return this.http
      .patch<void>(
        `${environment.api_url}/applications/${applicationId}/financialLiabilities/${financialLiabilityId}`,
        financialLiability,
      )
      .pipe(catchError(this.formatErrors));
  }

  deleteFinancialLiability(applicationId: string, financialLiabilityId: string): Observable<void> {
    return this.http
      .delete<void>(
        `${environment.api_url}/applications/${applicationId}/financialLiabilities/${financialLiabilityId}`,
      )
      .pipe(catchError(this.formatErrors));
  }

  addApplicantFinancialLiabilityLink(
    applicationId: string,
    applicantFinancialLiability: ApplicantFinancialLiability[],
  ): Observable<ApplicantFinancialLiability[]> {
    return this.http
      .post<ApplicantFinancialLiability[]>(
        `${environment.api_url}/applications/${applicationId}/applicantFinancialLiabilities`,
        applicantFinancialLiability,
      )
      .pipe(catchError(this.formatErrors));
  }

  deleteApplicantFinancialLiabilityLink(
    applicationId: string,
    applicantFinancialLiabilityId: string,
  ): Observable<void> {
    return this.http
      .delete<void>(
        `${environment.api_url}/applications/${applicationId}/applicantFinancialLiabilities/${applicantFinancialLiabilityId}`,
      )
      .pipe(catchError(this.formatErrors));
  }

  addFinancialLiabilityRecordTypes(
    financialLiabilityId: string,
    applicationId: string,
    recordTypes: Partial<FinancialLiabilityRecordType>[],
  ): Observable<FinancialLiabilityRecordType[]> {
    return this.http
      .post<FinancialLiabilityRecordType[]>(
        `${environment.api_url}/financialLiabilities/${financialLiabilityId}/financialLiabilityRecordTypes?applicationId=${applicationId}`,
        recordTypes,
      )
      .pipe(catchError(this.formatErrors));
  }

  updateFinancialLiabilityRecordType(
    financialLiabilityId: string,
    recordType: FinancialLiabilityRecordType,
  ): Observable<void> {
    return this.http
      .patch<void>(
        `${environment.api_url}/financialLiabilities/${financialLiabilityId}/financialLiabilityRecordTypes/${recordType.id}`,
        recordType,
      )
      .pipe(catchError(this.formatErrors));
  }

  fetchLiabilityOverrides(): Observable<LiabilityOverride[]> {
    return this.http.get<LiabilityOverride[]>(`${environment.fundmore_api_url}/liabilityOverride`);
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }
}
