export interface IDSGatewayOnboardingResult {
  workflowId: number;
  type: string;
  status: string;
  statusMessage: string;
  traceId: string;
  executionDate: string;
  result: IDSGatewayOnboarding | null;
  resultType: string;
  postbackStatusCode: number;
  postbackDate: string;
  postbackUrl: string;
}
export interface IDSGatewayOnboarding {
  primaryMemberNumber: number;
  associations: IDSGatewayOnboardingAssociation[];
  guarantors: IDSGatewayOnboardingMember[];
  primaryMember: IDSGatewayOnboardingMember;
}

export interface IDSGatewayOnboardingAssociation {
  jointAccountNumber: number;
  jointMembers: IDSGatewayOnboardingMember[];
}

export interface IDSGatewayOnboardingMember {
  applicantId: string;
  memberNumber: number;
  name: string;
  surname: string;
  bdate: string;
}
