import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AddressExpanded, PropertyAddressDetails } from '@fundmoreai/models';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AddressExpandedService {
  private base = `${environment.fundmore_api_url}/propertyAddress`;

  constructor(private http: HttpClient) {}

  createAddressExpanded(addressExpanded: Partial<AddressExpanded>): Observable<AddressExpanded> {
    return this.http.post<AddressExpanded>(
      `${environment.fundmore_api_url}/addressExpanded`,
      addressExpanded,
    );
  }

  updateAddressExpanded(
    addressExpandedId: string,
    addressExpandedUpdates: Partial<AddressExpanded>,
  ): Observable<AddressExpanded> {
    return this.http.patch<AddressExpanded>(
      `${environment.fundmore_api_url}/addressExpanded/${addressExpandedId}`,
      addressExpandedUpdates,
    );
  }

  deleteAddressExpanded(addressExpandedId: string): Observable<void> {
    return this.http.delete<void>(
      `${environment.fundmore_api_url}/addressExpanded/${addressExpandedId}`,
    );
  }

  getPropertyAddress(propertyId: string): Observable<PropertyAddressDetails> {
    return this.http.get<PropertyAddressDetails>(`${this.base}/${propertyId}`);
  }
}
