import { Injectable, NgZone } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  AdvancedProduct,
  AdvancedProductDefaultValue,
  CreditTier,
  MortgageType,
  Rate,
  RateMatrix,
  RateMatrixTerm,
  AdvancedProductInUseError,
  ApplicationAdvancedProductMetadataPerMortgage,
  ActionType,
  Application,
  BrokerCommission,
  ApplicationAdvancedProductPaymentChangesPerMortgage,
} from '@fundmoreai/models';
import { Action, Selector, State, StateContext, Store, createSelector } from '@ngxs/store';
import {
  finalize,
  of,
  switchMap,
  tap,
  EMPTY,
  combineLatest,
  forkJoin,
  catchError,
  Observable,
} from 'rxjs';
import { LoadingEnd, LoadingStart } from 'src/app/core/loading.state';
import { DialogsComponent } from 'src/app/features/application/sidebar/dialogs/dialogs.component';
import { CUSTOM_RATE } from 'src/app/features/manager-portal/advanced-product-manage/models';
import { AppFeaturesState } from 'src/app/shared/app-features.state';
import { AdvancedProductInUseErrorMessageRecord } from 'src/app/shared/enum-records';
import { RefreshApplication } from '../mortgage-application.actions';
import { MortgageApplicationState } from '../mortgage-application.state';
import { AdvancedProductService } from './advanced-product.service';
import { CreditTierService } from './credit-tier.service';
// eslint-disable-next-line max-len
import { PreviewApplyProductChangesService } from '../preview-apply-product-changes/preview-apply-product-changes.service';
import { RateService } from './rate.service';
import { FetchRates } from './rate.state';

export class FetchAdvancedProducts {
  static readonly type = '@advancedProducts.fetchAdvancedProducts';
}

export class FetchAdvancedProductById {
  static readonly type = '@advancedProducts.fetchAdvancedProductById';
  constructor(public id: string) {}
}

export class CreateAdvancedProduct {
  static readonly type = '@advancedProducts.createAdvancedProduct';
}

export class UpdateAdvancedProduct {
  static readonly type = '@advancedProducts.updateAdvancedProduct';
  constructor(public advancedProduct: Partial<AdvancedProduct>) {}
}

export class DeleteAdvancedProduct {
  static readonly type = '@advancedProducts.deleteAdvancedProduct';
  constructor(public id: string) {}
}

export class CreateRateMatrix {
  static readonly type = '@advancedProducts.createRateMatrix';
  constructor(public creditTierId: string, public productId: string) {}
}

export class CreateRateMatrixWithCustomCreditTier {
  static readonly type = '@advancedProducts.createRateMatrixWithCustomCreditTier';
  constructor(public creditTier: CreditTier, public productId: string) {}
}

export class UpdateRateMatrixWithCustomCreditTier {
  static readonly type = '@advancedProducts.updateRateMatrixWithCustomCreditTier';
  constructor(public creditTier: Partial<CreditTier>, public rateMatrix: RateMatrix) {}
}

export class UpdateRateMatrix {
  static readonly type = '@advancedProducts.updateRateMatrix';
  constructor(public rateMatrix: RateMatrix) {}
}

export class DeleteRateMatrix {
  static readonly type = '@advancedProducts.deleteRateMatrix';
  constructor(public id: string) {}
}

export class ResetAdvancedProductDetails {
  static readonly type = '@advancedProduct.resetAdvancedProductDetails';
}

export class DeleteDefaultValue {
  static readonly type = '@advancedProducts.deleteDefaultValue';
  constructor(public value: AdvancedProductDefaultValue) {}
}

export class UpdateDefaultValue {
  static readonly type = '@advancedProducts.updateDefaultValue';
  constructor(public value: AdvancedProductDefaultValue) {}
}

export class AddDefaultValue {
  static readonly type = '@advancedProducts.addDefaultValue';
  constructor(public value: AdvancedProductDefaultValue) {}
}

export class CreateRateMatrixTerm {
  static readonly type = '@advancedProducts.createRateMatrixTerm';
  constructor(public rateMatrixTerm: RateMatrixTerm) {}
}

export class CreateRateMatrixTermWithCustomRate {
  static readonly type = '@advancedProducts.createRateMatrixTermWithCustomRate';
  constructor(public rateMatrixTerm: RateMatrixTerm) {}
}

export class UpdateRateMatrixTerm {
  static readonly type = '@advancedProducts.updateRateMatrixTerm';
  constructor(public rateMatrixTerm: RateMatrixTerm) {}
}

export class UpdateRateMatrixTermWithCustomRate {
  static readonly type = '@advancedProducts.updateRateMatrixTermWithCustomRate';
  constructor(public rateMatrixTerm: RateMatrixTerm) {}
}

export class UpdateRateMatrixTermWithCustomFixedRate {
  static readonly type = '@advancedProducts.updateRateMatrixTermWithCustomFixedRate';
  constructor(public rateMatrixTerm: RateMatrixTerm) {}
}

export class UpdateRateMatrixTermWithCustomVariableRate {
  static readonly type = '@advancedProducts.updateRateMatrixTermWithCustomVariableRate';
  constructor(public rateMatrixTerm: RateMatrixTerm) {}
}

export class DeleteRateMatrixTerm {
  static readonly type = '@advancedProducts.deleteRateMatrixTerm';
  constructor(public termId: string, public rateMatrixId: string) {}
}

export class PreviewMortgageAdvancedProductSelection {
  static readonly type = '@advancedProducts.previewMortgageAdvancedProductSelection';
  constructor(public productId: string, public applicationId: string, public mortgageId: string) {}
}

export class ClearMortgageAdvancedProduct {
  static readonly type = '@advancedProducts.clearMortgageAdvancedProduct';
  constructor(public applicationId: string, public mortgageId: string) {}
}

export class ApplyMortgageAdvancedProduct {
  static readonly type = '@advancedProducts.applyMortgageAdvancedProduct';
  constructor(
    public mortgagesAdvancedProducts: { [key: string]: AdvancedProduct },
    public metadataForMortgage: ApplicationAdvancedProductMetadataPerMortgage,
    public applicationId: string,
    public diff: object,
    public modules: object | null,
    public info: string[] | undefined,
    public paymentChangesPerMortgage: {
      [key: string]: ApplicationAdvancedProductPaymentChangesPerMortgage;
    },
  ) {}
}

export class ReapplyMortgageAdvancedProducts {
  static readonly type = '@advancedProducts.reapplyMortgageAdvancedProducts';
  constructor(
    public applicationId: string,
    public withPrompt: boolean,
    public applyOnEmptyDiff: boolean = false,
    public applyForMortgagesWithProductChanges: boolean = false,
    public requestedMortgageId: string | undefined = undefined,
  ) {}
}

export class CreateCommission {
  static readonly type = '@advancedProducts.createCommission';
  constructor(public productId: string, public commission: BrokerCommission) {}
}

export class UpdateCommission {
  static readonly type = '@advancedProducts.updateCommission';
  constructor(public commission: BrokerCommission) {}
}

export class DeleteCommission {
  static readonly type = '@advancedProducts.deleteCommission';
  constructor(public id: string) {}
}

export interface AdvancedProductStateModel {
  productList: Partial<AdvancedProduct>[];
  productDetails?: Partial<AdvancedProduct>;
}

export interface ProductPreview {
  diff?: object;
  errors?: string[];
  warnings?: string[];
  info?: string[];
}

@State<AdvancedProductStateModel>({
  name: 'advancedProducts',
  defaults: {
    productList: [],
    productDetails: undefined,
  },
})
@Injectable()
export class AdvancedProductState {
  @Selector() static products(state: AdvancedProductStateModel) {
    return state.productList;
  }

  @Selector() static activeProducts(state: AdvancedProductStateModel) {
    return state.productList.filter((p) => p.isActive);
  }

  @Selector() static productDetails(state: AdvancedProductStateModel) {
    return state.productDetails;
  }

  static allApplicableProducts(application: Application) {
    return createSelector(
      [AdvancedProductState.activeProducts],
      (activeProducts: AdvancedProduct[]) => {
        if (!application) {
          return [];
        }
        const snapshots = application.ApplicationAdvancedProducts?.filter(
          (aap) => !!aap.advancedProductSnapshot && aap.advancedProductSnapshot.isActive,
        ).map((aap) => ({ ...aap.advancedProductSnapshot, isActive: false }));

        const result = [...(activeProducts ?? []), ...(snapshots ?? [])].reduce(
          (accumulator: AdvancedProduct[], current) => {
            const exists = accumulator.some((item) => item.id === current.id);
            if (!exists) {
              accumulator.push(current);
            }
            return accumulator;
          },
          [],
        );

        return result;
      },
    );
  }

  constructor(
    private advancedProductService: AdvancedProductService,
    private creditTierService: CreditTierService,
    private rateService: RateService,
    private router: Router,
    private dialog: MatDialog,
    private store: Store,
    private zone: NgZone,
    private previewApplyProductChangesService: PreviewApplyProductChangesService,
  ) {}

  @Action(FetchAdvancedProducts) fetchAdvancedProducts(
    ctx: StateContext<AdvancedProductStateModel>,
  ) {
    ctx.patchState({ productList: [] });

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.advancedProductService.getAdvancedProducts().pipe(
      tap((advancedProducts) => {
        ctx.patchState({ productList: advancedProducts });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(FetchAdvancedProductById) fetchAdvancedProductById(
    ctx: StateContext<AdvancedProductStateModel>,
    action: FetchAdvancedProductById,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.advancedProductService.getAdvancedProductById(action.id).pipe(
      tap((advancedProduct) => {
        ctx.patchState({ productDetails: advancedProduct });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(CreateAdvancedProduct) createAdvancedProduct(
    ctx: StateContext<AdvancedProductStateModel>,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.advancedProductService.postAdvancedProduct().pipe(
      switchMap((advancedProduct) => {
        const advancedProducts = ctx.getState().productList;
        ctx.patchState({ productList: [...advancedProducts, { ...advancedProduct }] });
        return of(advancedProduct.id);
      }),
      tap((productId) => {
        this.router.navigateByUrl(`/portal/manager/advanced-products/summary/${productId}`);
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(UpdateAdvancedProduct) updateAdvancedProduct(
    ctx: StateContext<AdvancedProductStateModel>,
    action: UpdateAdvancedProduct,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.advancedProductService.patchAdvancedProduct(action.advancedProduct).pipe(
      tap(() => {
        const advancedProducts = ctx.getState().productList;
        const currentProduct = advancedProducts.find((p) => p.id === action.advancedProduct.id);
        const updatedProduct = {
          ...currentProduct,
          ...action.advancedProduct,
          createdAt: currentProduct?.createdAt,
          updatedAt: new Date(),
        };
        ctx.setState({
          productList: [
            ...advancedProducts.filter((r) => r.id !== action.advancedProduct.id),
            updatedProduct,
          ],
          productDetails: Object.assign({}, ctx.getState().productDetails, action.advancedProduct),
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(DeleteAdvancedProduct) deleteAdvancedProduct(
    ctx: StateContext<AdvancedProductStateModel>,
    action: DeleteAdvancedProduct,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.advancedProductService.deleteAdvancedProduct(action.id).pipe(
      tap(() => {
        const advancedProducts = ctx.getState().productList;
        ctx.patchState({ productList: [...advancedProducts.filter((r) => r.id !== action.id)] });
      }),
      catchError((e) => {
        let errorMessage = e.error.message;
        if (Object.values(AdvancedProductInUseError).some((x) => x === errorMessage)) {
          errorMessage =
            AdvancedProductInUseErrorMessageRecord[<AdvancedProductInUseError>errorMessage];
        }

        const advancedProducts = ctx.getState().productList;
        const advancedProduct = advancedProducts.find((ap) => ap.id === action.id);

        this.dialog.open(DialogsComponent, {
          data: {
            title: errorMessage,
            message: advancedProduct?.name,
            buttonText: {
              yes: $localize`:@@action.close:Close`,
              no: $localize`:@@action.cancel:Cancel`,
            },
            declineModalClass: true,
          },
          minWidth: '400px',
          maxWidth: '400px',
        });
        return EMPTY;
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(CreateRateMatrix) createRateMatrix(
    ctx: StateContext<AdvancedProductStateModel>,
    action: CreateRateMatrix,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const rateMatrixToCreate = {
      creditTierId: action.creditTierId,
      advancedProductId: action.productId,
    };

    return this.advancedProductService.createRateMatrix(rateMatrixToCreate).pipe(
      tap((rateMatrix) => {
        const state = ctx.getState();
        if (state.productDetails) {
          return of(
            ctx.patchState({
              productDetails: {
                ...state.productDetails,
                RateMatrices: [...(state.productDetails?.RateMatrices ?? []), rateMatrix],
              },
            }),
          );
        }
        return EMPTY;
      }),
      switchMap(() => {
        const state = ctx.getState();
        if (state.productDetails) {
          return of(ctx.dispatch(new FetchAdvancedProductById(state.productDetails.id ?? '')));
        }
        return EMPTY;
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(CreateRateMatrixWithCustomCreditTier) createRateMatrixWithCustomCreditTier(
    ctx: StateContext<AdvancedProductStateModel>,
    action: CreateRateMatrixWithCustomCreditTier,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.creditTierService.postCreditTier({ ...action.creditTier, id: undefined }).pipe(
      tap((creditTier) => {
        const state = ctx.getState();
        if (state.productDetails) {
          return of(
            ctx.dispatch(new CreateRateMatrix(creditTier.id, state.productDetails.id ?? '')),
          );
        }
        return EMPTY;
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateRateMatrixWithCustomCreditTier) UpdateRateMatrixWithCustomCreditTier(
    ctx: StateContext<AdvancedProductStateModel>,
    action: UpdateRateMatrixWithCustomCreditTier,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.creditTierService.postCreditTier({ ...action.creditTier }).pipe(
      tap((creditTier) => {
        const state = ctx.getState();
        if (state.productDetails) {
          ctx.dispatch(
            new UpdateRateMatrix({
              ...action.rateMatrix,
              creditTierId: creditTier.id,
              CreditTier: creditTier,
            }),
          );
        }
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateRateMatrix) updateRateMatrix(
    ctx: StateContext<AdvancedProductStateModel>,
    action: UpdateRateMatrix,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.advancedProductService
      .patchRateMatrix({ ...action.rateMatrix, CreditTier: undefined, RateMatrixTerms: undefined })
      .pipe(
        tap(() => {
          const state = ctx.getState();
          const rateMatrix = state.productDetails?.RateMatrices?.find(
            (r) => r.id === action.rateMatrix.id,
          );
          if (state.productDetails) {
            return of(
              ctx.patchState({
                productDetails: {
                  ...state.productDetails,
                  RateMatrices: [
                    ...(state.productDetails?.RateMatrices?.filter(
                      (r) => r.id !== action.rateMatrix.id,
                    ) ?? []),
                    { ...(rateMatrix ?? {}), ...action.rateMatrix },
                  ],
                },
              }),
            );
          }
          return EMPTY;
        }),
        switchMap(() => {
          const state = ctx.getState();
          if (state.productDetails) {
            return of(ctx.dispatch(new FetchAdvancedProductById(state.productDetails.id ?? '')));
          }
          return EMPTY;
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(DeleteRateMatrix) deleteRateMatrix(
    ctx: StateContext<AdvancedProductStateModel>,
    action: DeleteRateMatrix,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.advancedProductService.deleteRateMatrix(action.id).pipe(
      tap(() => {
        const state = ctx.getState();
        const advancedProduct = state.productDetails;
        if (advancedProduct) {
          const updatedProduct = {
            ...advancedProduct,
            RateMatrices: [
              ...(advancedProduct.RateMatrices ?? []).filter((r) => r.id !== action.id),
            ],
          };
          ctx.patchState({ productDetails: updatedProduct });
        }
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(ResetAdvancedProductDetails) resetProductDetails(
    ctx: StateContext<AdvancedProductStateModel>,
  ) {
    ctx.patchState({ productDetails: undefined });
  }

  @Action(DeleteDefaultValue) deleteDefaultValue(
    ctx: StateContext<AdvancedProductStateModel>,
    action: DeleteDefaultValue,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const productDetails = ctx.getState().productDetails;
    const defaultValues = productDetails?.defaultValues?.filter(
      (r) => !(r.tableName === action.value.tableName && r.fieldName === action.value.fieldName),
    );
    if (productDetails) {
      return this.advancedProductService
        .patchAdvancedProduct({
          id: productDetails.id,
          defaultValues,
        })
        .pipe(
          tap(() => {
            ctx.patchState({
              productDetails: {
                ...productDetails,
                defaultValues,
              },
            });
          }),
          finalize(() => {
            ctx.dispatch(new LoadingEnd(this.constructor.name));
          }),
        );
    } else {
      ctx.dispatch(new LoadingEnd(this.constructor.name));
      return of(null);
    }
  }

  @Action(UpdateDefaultValue) updateDefaultValue(
    ctx: StateContext<AdvancedProductStateModel>,
    action: UpdateDefaultValue,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const productDetails = ctx.getState().productDetails;
    const defaultValues = ctx.getState().productDetails?.defaultValues?.map((r) => {
      if (r.tableName === action.value.tableName && r.fieldName === action.value.fieldName) {
        return {
          ...r,
          defaultValue: action.value.defaultValue,
        };
      } else {
        return r;
      }
    });
    if (defaultValues && productDetails) {
      return this.advancedProductService
        .patchAdvancedProduct({
          id: productDetails.id,
          defaultValues,
        })
        .pipe(
          tap(() => {
            if (defaultValues && productDetails) {
              ctx.patchState({
                productDetails: {
                  ...productDetails,
                  defaultValues: defaultValues,
                },
              });
            }
          }),
          finalize(() => {
            ctx.dispatch(new LoadingEnd(this.constructor.name));
          }),
        );
    } else {
      ctx.dispatch(new LoadingEnd(this.constructor.name));
      return of(null);
    }
  }

  @Action(AddDefaultValue) addDefaultValue(
    ctx: StateContext<AdvancedProductStateModel>,
    action: AddDefaultValue,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const productDetails = ctx.getState().productDetails;
    const defaultValues = [...(ctx.getState().productDetails?.defaultValues ?? []), action.value];
    if (defaultValues && productDetails) {
      return this.advancedProductService
        .patchAdvancedProduct({
          id: productDetails.id,
          defaultValues,
        })
        .pipe(
          tap(() => {
            if (defaultValues && productDetails) {
              ctx.patchState({
                productDetails: {
                  ...productDetails,
                  defaultValues: defaultValues,
                },
              });
            }
          }),
          finalize(() => {
            ctx.dispatch(new LoadingEnd(this.constructor.name));
          }),
        );
    } else {
      ctx.dispatch(new LoadingEnd(this.constructor.name));
      return of(null);
    }
  }

  @Action(CreateRateMatrixTerm) createRateMatrixTerm(
    ctx: StateContext<AdvancedProductStateModel>,
    action: CreateRateMatrixTerm,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.advancedProductService
      .createRateMatrixTerm({
        ...action.rateMatrixTerm,
        duration: {
          durations: action.rateMatrixTerm.duration.durations.map((d) => {
            return d === -1 ? null : d;
          }),
        },
        VariableRate: undefined,
        FixedRate: undefined,
      })
      .pipe(
        switchMap(() => {
          const state = ctx.getState();
          if (state.productDetails) {
            return ctx.dispatch(new FetchAdvancedProductById(state.productDetails.id ?? ''));
          }
          return EMPTY;
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(CreateRateMatrixTermWithCustomRate) createRateMatrixTermWithCustomRate(
    ctx: StateContext<AdvancedProductStateModel>,
    action: CreateRateMatrixTermWithCustomRate,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const customVariableRate$ = this.getCustomVariableRate(action.rateMatrixTerm);
    const customFixedRate$ = this.getCustomFixedRate(action.rateMatrixTerm);

    return combineLatest([customVariableRate$, customFixedRate$]).pipe(
      switchMap(([variable, fixed]) => {
        return ctx.dispatch(
          new CreateRateMatrixTerm({
            ...action.rateMatrixTerm,
            variableRateId: variable.id ?? action.rateMatrixTerm.variableRateId,
            fixedRateId: fixed.id ?? action.rateMatrixTerm.fixedRateId,
          }),
        );
      }),
      switchMap(() => {
        return ctx.dispatch(new FetchRates());
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(UpdateRateMatrixTerm) updateRateMatrixTerm(
    ctx: StateContext<AdvancedProductStateModel>,
    action: UpdateRateMatrixTerm,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.advancedProductService
      .patchRateMatrixTerm({
        ...action.rateMatrixTerm,
        duration: {
          durations: action.rateMatrixTerm.duration.durations.map((d) => {
            return d === -1 ? null : d;
          }),
        },
        VariableRate: undefined,
        FixedRate: undefined,
      })
      .pipe(
        switchMap(() => {
          const state = ctx.getState();
          if (state.productDetails) {
            return ctx.dispatch(new FetchAdvancedProductById(state.productDetails.id ?? ''));
          }
          return EMPTY;
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(UpdateRateMatrixTermWithCustomRate) updateRateMatrixTermWithCustomRate(
    ctx: StateContext<AdvancedProductStateModel>,
    action: UpdateRateMatrixTerm,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const customVariableRate$ = this.getCustomVariableRate(action.rateMatrixTerm);
    const customFixedRate$ = this.getCustomFixedRate(action.rateMatrixTerm);

    return combineLatest([customVariableRate$, customFixedRate$]).pipe(
      switchMap(([variable, fixed]) => {
        return ctx.dispatch(
          new UpdateRateMatrixTerm({
            ...action.rateMatrixTerm,
            variableRateId: variable.id ?? action.rateMatrixTerm.variableRateId,
            fixedRateId: fixed.id ?? action.rateMatrixTerm.fixedRateId,
          }),
        );
      }),
      switchMap(() => {
        return ctx.dispatch(new FetchRates());
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(UpdateRateMatrixTermWithCustomFixedRate) updateRateMatrixTermWithCustomFixedRate(
    ctx: StateContext<AdvancedProductStateModel>,
    action: UpdateRateMatrixTerm,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const customFixedRate$ = this.getCustomFixedRate(action.rateMatrixTerm);

    return customFixedRate$.pipe(
      switchMap((fixed) => {
        return ctx.dispatch(
          new UpdateRateMatrixTerm({
            ...action.rateMatrixTerm,
            fixedRateId: fixed.id ?? action.rateMatrixTerm.fixedRateId,
          }),
        );
      }),
      switchMap(() => {
        return ctx.dispatch(new FetchRates());
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(UpdateRateMatrixTermWithCustomVariableRate) updateRateMatrixTermWithCustomVariableRate(
    ctx: StateContext<AdvancedProductStateModel>,
    action: UpdateRateMatrixTerm,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const customVariableRate$ = this.getCustomVariableRate(action.rateMatrixTerm);

    return customVariableRate$.pipe(
      switchMap((fixed) => {
        return ctx.dispatch(
          new UpdateRateMatrixTerm({
            ...action.rateMatrixTerm,
            variableRateId: fixed.id ?? action.rateMatrixTerm.variableRateId,
          }),
        );
      }),
      switchMap(() => {
        return ctx.dispatch(new FetchRates());
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(DeleteRateMatrixTerm) deleteRateMatrixTerm(
    ctx: StateContext<AdvancedProductStateModel>,
    action: DeleteRateMatrixTerm,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.advancedProductService.deleteRateMatrixTerm(action.termId).pipe(
      tap(() => {
        const state = ctx.getState();
        if (state.productDetails) {
          const rateMatrix = state.productDetails.RateMatrices?.find(
            (r) => r.id === action.rateMatrixId,
          );
          if (rateMatrix) {
            const updatedProduct: Partial<AdvancedProduct> = {
              ...state.productDetails,
              RateMatrices: [
                ...(state.productDetails.RateMatrices?.filter(
                  (r) => r.id !== action.rateMatrixId,
                ) ?? []),
                {
                  ...rateMatrix,
                  RateMatrixTerms: [
                    ...(rateMatrix.RateMatrixTerms?.filter((r) => r.id !== action.termId) ?? []),
                  ],
                },
              ],
            };

            ctx.patchState({ productDetails: updatedProduct });
          }
        }
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(PreviewMortgageAdvancedProductSelection) previewMortgageProduct(
    ctx: StateContext<AdvancedProductStateModel>,
    action: PreviewMortgageAdvancedProductSelection,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.advancedProductService.getAdvancedProductById(action.productId).pipe(
      switchMap((product) => {
        const mortgageAdvancedProduct: { [key: string]: AdvancedProduct } = {};
        mortgageAdvancedProduct[action.mortgageId] = product;

        return combineLatest([
          this.advancedProductService.getMortgageAdvancedProductPreview(
            mortgageAdvancedProduct,
            action.applicationId,
            {
              mortgagePatchTempChanges: {
                mortgageId: action.mortgageId,
                actionType: ActionType.UPDATE,
              },
            },
          ),
          of(mortgageAdvancedProduct),
        ]);
      }),
      switchMap(([preview, mortgageAdvancedProduct]) => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
        const isBlockApplyingProductsWhenWarningsEnabled = this.store.selectSnapshot(
          AppFeaturesState.isBlockApplyingProductsWhenWarningsEnabled,
        );
        if (
          (preview.errors && preview.errors.length > 0) ||
          (isBlockApplyingProductsWhenWarningsEnabled &&
            preview.warnings &&
            preview.warnings.length != 0)
        ) {
          return this.previewApplyProductChangesService.handleProductErrorDialog([
            ...new Set([...(preview?.warnings ?? []), ...(preview?.errors ?? [])]),
          ]);
        }
        return this.previewApplyProductChangesService.handleMortgageProductPreviewDialog(
          preview,
          action.applicationId,
          mortgageAdvancedProduct,
        );
      }),
    );
  }

  @Action(ClearMortgageAdvancedProduct) clearMortgageProduct(
    ctx: StateContext<AdvancedProductStateModel>,
    action: ClearMortgageAdvancedProduct,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.advancedProductService
      .clearMortgageAdvancedProduct(action.applicationId, action.mortgageId)
      .pipe(
        switchMap(() => {
          return ctx.dispatch(new RefreshApplication(action.applicationId));
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(ApplyMortgageAdvancedProduct) applyMortgageProduct(
    ctx: StateContext<AdvancedProductStateModel>,
    action: ApplyMortgageAdvancedProduct,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    action.metadataForMortgage.info = action.info;
    return this.advancedProductService
      .applyMortgageAdvancedProducts(
        action.mortgagesAdvancedProducts,
        action.applicationId,
        action.diff,
        action.modules,
        action.metadataForMortgage,
        action.paymentChangesPerMortgage,
      )
      .pipe(
        switchMap(() => {
          return ctx.dispatch(new RefreshApplication(action.applicationId));
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(ReapplyMortgageAdvancedProducts) reapplyMortgageProducts(
    ctx: StateContext<AdvancedProductStateModel>,
    action: ReapplyMortgageAdvancedProducts,
  ) {
    const application = this.store.selectSnapshot(MortgageApplicationState.application);
    const mortgageAdvancedProduct: { [key: string]: AdvancedProduct } = {};

    const requestedMortgages = application?.Mortgages?.filter(
      (m) =>
        m.type === MortgageType.REQUESTED &&
        (action.requestedMortgageId !== undefined ? action.requestedMortgageId === m.id : true),
    );
    if (!requestedMortgages || requestedMortgages.length === 0) {
      return;
    }
    const mortgageToProduct = requestedMortgages
      .filter((rm) => rm.applicationAdvancedProduct?.advancedProductSnapshot)
      .map((rm) => {
        return [rm.id, rm.applicationAdvancedProduct?.advancedProductSnapshot.id];
      });

    if (!mortgageToProduct || mortgageToProduct.length === 0) {
      return;
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));
    return forkJoin(
      mortgageToProduct.map(([mId, pId]) =>
        forkJoin([
          of(mId as string),
          pId ? this.advancedProductService.getAdvancedProductById(pId) : of(null),
        ]),
      ),
    ).pipe(
      switchMap((mToP) => {
        mToP.forEach(([mortgageId, advancedProduct]) => {
          if (advancedProduct) {
            if (action.applyForMortgagesWithProductChanges) {
              const productHasChanges =
                advancedProduct.updatedAt !==
                requestedMortgages.find((x) => x.id === mortgageId)?.applicationAdvancedProduct
                  ?.advancedProductSnapshot.updatedAt;

              if (productHasChanges) {
                mortgageAdvancedProduct[mortgageId] = advancedProduct;
              }
            } else {
              mortgageAdvancedProduct[mortgageId] = advancedProduct;
            }
          }
        });

        return combineLatest([
          this.advancedProductService.getMortgageAdvancedProductPreview(
            mortgageAdvancedProduct,
            action.applicationId,
          ),
          of(mortgageAdvancedProduct),
        ]);
      }),
      switchMap(([preview, mortgageAdvancedProduct]) => {
        preview.metadataForMortgage.isReapply = true;

        if (preview.errors && preview.errors.length != 0) {
          return this.previewApplyProductChangesService.handleProductErrorDialog(preview.errors);
        }
        if ((!preview.diff || this.isEmptyObject(preview.diff)) && !action.applyOnEmptyDiff) {
          return EMPTY;
        }

        if (action.withPrompt) {
          preview.metadataForMortgage.isReapply = true;
          return this.previewApplyProductChangesService.handleMortgageProductPreviewDialog(
            preview,
            application.id,
            null,
            action.applyForMortgagesWithProductChanges,
          );
        }

        return ctx.dispatch(
          new ApplyMortgageAdvancedProduct(
            mortgageAdvancedProduct,
            preview.metadataForMortgage,
            action.applicationId,
            preview.diff,
            preview.modules,
            preview.info,
            preview.paymentChangesPerMortgage,
          ),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreateCommission) createCommission(
    ctx: StateContext<AdvancedProductStateModel>,
    action: CreateCommission,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const commissionToCreate = {
      ...action.commission,
      duration: {
        durations: action.commission.duration.durations.map((d) => {
          return d === -1 ? null : d;
        }),
      },
      advancedProductId: action.productId,
    };

    return this.advancedProductService.createCommission(commissionToCreate).pipe(
      tap((commission) => {
        const state = ctx.getState();
        if (state.productDetails) {
          return of(
            ctx.patchState({
              productDetails: {
                ...state.productDetails,
                BrokerCommissions: [...(state.productDetails?.BrokerCommissions ?? []), commission],
              },
            }),
          );
        }
        return EMPTY;
      }),
      switchMap(() => {
        const state = ctx.getState();
        if (state.productDetails) {
          return of(ctx.dispatch(new FetchAdvancedProductById(state.productDetails.id ?? '')));
        }
        return EMPTY;
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(UpdateCommission) updateCommission(
    ctx: StateContext<AdvancedProductStateModel>,
    action: UpdateCommission,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.advancedProductService
      .patchCommission({
        ...action.commission,
        duration: {
          durations: action.commission.duration.durations.map((d) => {
            return d === -1 ? null : d;
          }),
        },
      })
      .pipe(
        tap(() => {
          const state = ctx.getState();
          const commission = state.productDetails?.BrokerCommissions?.find(
            (r) => r.id === action.commission.id,
          );
          if (state.productDetails) {
            return of(
              ctx.patchState({
                productDetails: {
                  ...state.productDetails,
                  BrokerCommissions: [
                    ...(state.productDetails?.BrokerCommissions?.filter(
                      (r) => r.id !== action.commission.id,
                    ) ?? []),
                    { ...(commission ?? {}), ...action.commission },
                  ],
                },
              }),
            );
          }
          return EMPTY;
        }),
        switchMap(() => {
          const state = ctx.getState();
          if (state.productDetails) {
            return of(ctx.dispatch(new FetchAdvancedProductById(state.productDetails.id ?? '')));
          }
          return EMPTY;
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(DeleteCommission) deleteCommission(
    ctx: StateContext<AdvancedProductStateModel>,
    action: DeleteCommission,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.advancedProductService.deleteCommission(action.id).pipe(
      tap(() => {
        const state = ctx.getState();
        const advancedProduct = state.productDetails;
        if (advancedProduct) {
          const updatedProduct = {
            ...advancedProduct,
            BrokerCommissions: [
              ...(advancedProduct.BrokerCommissions ?? []).filter((r) => r.id !== action.id),
            ],
          };
          ctx.patchState({ productDetails: updatedProduct });
        }
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  private getCustomFixedRate(rateMatrixTerm: RateMatrixTerm): Observable<Partial<Rate>> {
    const fixedRate: Partial<Rate> = {
      name: CUSTOM_RATE,
      value: rateMatrixTerm.FixedRate?.value,
      isCustom: true,
    };

    const customFixedRate$ =
      !rateMatrixTerm.fixedRateId && fixedRate.value !== null && fixedRate.value !== undefined
        ? this.rateService.postRate(fixedRate)
        : of({ id: undefined });

    return customFixedRate$;
  }

  private getCustomVariableRate(rateMatrixTerm: RateMatrixTerm): Observable<Partial<Rate>> {
    const variableRate: Partial<Rate> = {
      name: CUSTOM_RATE,
      value: rateMatrixTerm.VariableRate?.value,
      isCustom: true,
    };

    const customVariableRate$ =
      !rateMatrixTerm.variableRateId &&
      variableRate.value !== null &&
      variableRate.value !== undefined
        ? this.rateService.postRate(variableRate)
        : of({ id: undefined });

    return customVariableRate$;
  }

  private isEmptyObject(obj: object) {
    for (const _ in obj) {
      return false;
    }
    return true;
  }
}
