export enum OptaExteriorWallType {
  BRICK = 'Brick',
  BRICK_VENEER = 'Brick Veneer',
  CONCRETE = 'Concrete',
  FIBREBOARD = 'Fibreboard',
  LOGS = 'Logs',
  STONE = 'Stone',
  STONE_VENEER = 'Stone Veneer',
  STUCCO = 'Stucco',
  VINYL_SIDING = 'Vinyl Siding',
  WOOD_SIDING = 'Wood Siding',
}
