export class LocalIdHandlerService {
  static readonly TMP_TOKEN = 'TEMP_CLIENT_GENERATED_';

  public static isTempId(id: string): boolean {
    return id.match(new RegExp(`${this.TMP_TOKEN}.*`, 'g')) !== null;
  }

  public static generateTempId(): string {
    return this.getTempId();
  }

  public static generateTempIdFromNumber(number: number) {
    return this.TMP_TOKEN + number;
  }

  public static getTempId() {
    return this.TMP_TOKEN + new Date().getTime();
  }
}
