import { Pipe, PipeTransform } from '@angular/core';
import { CustomerType } from '@fundmoreai/models';

interface ApplicantName {
  name?: string;
  surname?: string;
  customerType?: CustomerType | string;
  company?: string;
  ApplicantCompanyDetails?: { companyName: string }[];
}

@Pipe({
  name: 'applicantName',
  standalone: true,
})
export class ApplicantNamePipe implements PipeTransform {
  transform(applicant: ApplicantName | undefined): string {
    return getApplicantName(applicant);
  }
}

export function getApplicantName(applicant: ApplicantName | undefined): string {
  if (applicant?.customerType === CustomerType.COMPANY) {
    const companyName = applicant?.ApplicantCompanyDetails?.find((x) => x)?.companyName;
    return companyName != undefined && companyName !== '' ? companyName : 'Unknown Name';
  }

  if (!applicant?.name && !applicant?.surname) {
    if (
      (applicant?.customerType === CustomerType.BROKERAGE ||
        applicant?.customerType === CustomerType.THIRD_PARTY) &&
      applicant?.company
    ) {
      return applicant?.company;
    }
    return $localize`Unknown Name`;
  }

  return `${applicant.name ?? ''} ${applicant.surname ?? ''}`.trim();
}
