import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AnalysisModel } from './recognitionModel';
import { pollUntil } from '../../../shared/services/poll-until-rxjs';

@Injectable({
  providedIn: 'root',
})
export class RecognitionService {
  constructor(private http: HttpClient) {}

  private getOcrAnalysedResult(jobId: string, type: string): Observable<AnalysisModel> {
    return this.http
      .get<AnalysisModel>(`${environment.api_url}/recognition/analysis/${jobId}?type=${type}`)
      .pipe(pollUntil(10000, 20, ({ status }) => status !== 'IN_PROGRESS'));
  }

  private requiresOcr(application: AnalysisModel): boolean {
    return application.jobData !== undefined;
  }

  public analyseMortgageApplication(data: Blob, type: string): Observable<AnalysisModel> {
    const formData = new FormData();
    formData.append('document', data);
    return this.http
      .post<AnalysisModel>(`${environment.api_url}/recognition/analyse/${type}`, formData)
      .pipe(
        switchMap((analysedApplication) => {
          if (this.requiresOcr(analysedApplication) && analysedApplication.jobData) {
            const jobId = analysedApplication.jobData.jobs.document[0].JobId;
            return this.getOcrAnalysedResult(jobId, type);
          }
          return of(analysedApplication);
        }),
      );
  }
}
