export enum CmhcViewModelFieldCategory {
  APPLICATION_DETAILS = 'Application Details',
  BORROWER = 'Borrower',
  COBORROWER_1 = 'Coborower 1',
  COBORROWER_2 = 'Coborower 2',
  COBORROWER_3 = 'Coborower 3',
  GUARANTOR = 'Guarantor',
  LENDER = 'Lender',
  LENDER_CLIENT_SERVICE_REPRESENTATIVE_NAME = 'Lender Client Service Representative Name',
  LENDER_SOURCE_REPRESENTATIVE = 'Lender Source Representative',
  LOAN_DETAILS = 'Loan Details',
  QUALIFYING_INFORMATION = 'Qualifying Information',
  QUALIFYING_INFORMATION_SOURCE_OF_EQUITY = 'Qualifying Information Source Of Equity',
  PROPERTY = 'Property',
  PROPERTY_ADDRESS = 'Property Address',
  BORROWER_ADDRESS = 'Borrower Address',
  COBORROWER_1_ADDRESS = 'Coborower 1 Address',
  BORROWER_BANKING = 'Borrower Banking',
  COBORROWER_1_BANKING = 'Coborower 1 Banking',
  BORROWER_EMPLOYMENT = 'Borrower Employment',
  COBORROWER_1_EMPLOYMENT = 'Coborower 1 Employment',
}
