import { Decrement } from '@fundmoreai/models';

export class GetDecrements {
  static readonly type = '@decrementsState.getDecrements';
}

export class CreateDecrement {
  static readonly type = '@decrementsState.createDecrement';
  constructor(public decrement: Partial<Decrement>) {}
}

export class UpdateDecrement {
  static readonly type = '@decrementsState.updateDecrement';
  constructor(public decrementId: string, public decrement: Partial<Decrement>) {}
}

export class DeleteDecrement {
  static readonly type = '@decrementsState.deleteDecrement';
  constructor(public decrementId: string) {}
}
