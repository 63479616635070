<h1 i18n="@@auth.forgot_password">Forgot Your Password?</h1>
<p i18n>Enter your email below and we will send a message to reset your password.</p>
<form [formGroup]="forgotPasswordForm" (ngSubmit)="submitForgotPasswordForm()">
  <fieldset class="default-field" [disabled]="isSubmitting">
    <fieldset class="default-field mb-20">
      <label class="mb-5" for="email" i18n>Email</label>
      <input name="email" formControlName="email" type="text" aria-label="Email" />
    </fieldset>
  </fieldset>
  <fieldset class="default-field mb-50">
    <a [routerLink]="[]" (click)="handleGoToLogin()" [class.disabled]="isSubmitting ? true : null">
      <ng-container i18n="@@action.backToLogin">Back to Login</ng-container>
    </a>
  </fieldset>
  <fieldset class="default-field">
    <button
      mat-flat-button
      color="primary"
      class="primary-btn"
      [disabled]="!forgotPasswordForm.valid || isSubmitting"
      type="submit">
      <ng-container i18n="@@action.send">Send</ng-container>
    </button>
  </fieldset>
</form>
