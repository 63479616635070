import { MortgagePosition } from '../../enums';

export enum FCTLoanPriority {
  FIRST_MORTGAGE = '001',
  SECOND_MORTGAGE = '002',
  THIRD_MORTGAGE = '004',
}

export const fctLoanPriorityMapping: Record<MortgagePosition, FCTLoanPriority | undefined> = {
  [MortgagePosition.FIRST]: FCTLoanPriority.FIRST_MORTGAGE,
  [MortgagePosition.SECOND]: FCTLoanPriority.SECOND_MORTGAGE,
  [MortgagePosition.THIRD]: FCTLoanPriority.THIRD_MORTGAGE,
  [MortgagePosition.UNKNOWN]: undefined,
};
