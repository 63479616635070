export enum PropertyOwnerKey {
  TITLE_NAME = 'individualNameOnTitle',
  SPOUSE_NAME = 'spouseName',
  SPOUSAL_CONSENT = 'spousalConsentRequired',
  IS_COMPANY = 'isCompany',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
  ADDRESS = 'address',
  MARTIAL_STATUS = 'maritalStatus',
}
