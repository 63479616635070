import { MortgageClosingInstruction } from './enums';
import { FCTMMSResponse } from './fct-mms';

export interface ClosingInstruction {
  id: string;
  applicationId: string;
  mortgageId: string;
  typeOfClosingInstruction: MortgageClosingInstruction;
  registrationNumber?: string;
  collateralAmount?: number;
  MMSRequest?: FCTMMSResponse;
  createdAt: Date;
  updatedAt?: Date;
}

export enum ClosingInstructionKey {
  TYPE_OF_CLOSING_INSTRUCTION = 'typeOfClosingInstruction',
  REGISTRATION_NUMBER = 'registrationNumber',
  COLLATERAL_AMOUNT = 'collateralAmount',
}
