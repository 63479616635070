import { FinancialAsset } from '@fundmoreai/models';

export class AddFinancialAsset {
  static readonly type = '@financialAssets.AddFinancialAsset';

  constructor(public financialAsset: Partial<FinancialAsset>, public applicationId: string) {}
}

export class AddFinancialAssetToDownPayment {
  static readonly type = '@financialAssets.AddFinancialAssetToDownPayment';

  constructor(public financialAssetId: string) {}
}

export class DeleteFinancialAsset {
  static readonly type = '@financialAssets.DeleteFinancialAsset';

  constructor(public applicationId: string, public financialAsset: FinancialAsset) {}
}

export class RemoveFinancialAssetToDownPayment {
  static readonly type = '@financialAssets.RemoveFinancialAssetToDownPayment';

  constructor(public financialAssetId: string) {}
}

export class SetFinancialAssets {
  static readonly type = '@financialAssets.SetFinancialAssets';

  constructor(public financialAssets: FinancialAsset[]) {}
}

export class UpdateFinancialAsset {
  static readonly type = '@financialAssets.UpdateFinancialAsset';

  constructor(
    public applicationId: string,
    public financialAssetId: string,
    public financialAsset: FinancialAsset,
  ) {}
}
