import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreditTier } from '@fundmoreai/models';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CreditTierService {
  constructor(private http: HttpClient) {}

  getCreditTiers(): Observable<CreditTier[]> {
    return this.http.get<CreditTier[]>(`${environment.fundmore_api_url}/rateMatrix/creditTiers`);
  }

  postCreditTier(creditTier: Partial<CreditTier>): Observable<CreditTier> {
    return this.http.post<CreditTier>(
      `${environment.fundmore_api_url}/rateMatrix/creditTiers`,
      creditTier,
    );
  }

  patchCreditTier(creditTier: CreditTier) {
    return this.http.patch<void>(
      `${environment.fundmore_api_url}/rateMatrix/creditTiers`,
      creditTier,
    );
  }

  deleteCreditTier(id: string) {
    return this.http.delete<void>(`${environment.fundmore_api_url}/rateMatrix/creditTiers/${id}`);
  }
}
