import { Action, NgxsOnInit, Selector, State, StateContext, Store } from '@ngxs/store';
import { catchError, finalize, switchMap, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { EzidoxDocumentTemplate, EzidoxViewType } from '../../shared';
import { of } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ApplicationResetState } from '../../shared/state.model';
import { UploadComponentState } from '../../features/pipeline/upload/upload.state';
import { EzidoxService } from '../services/ezidox.service';
import { AppFeaturesState } from 'src/app/shared/app-features.state';
import { SetApplicationFile } from 'src/app/features/pipeline/upload/upload.actions';
import {
  DetermineIframeStrategy,
  EzidoxIncrementLoading,
  EzidoxDecrementLoading,
  GetEzidoxUrl,
  CreateEzidoxApplication,
  GetDocumentTemplateTypes,
  EzidoxUrlReset,
  EzidoxRequestStatusReset,
  UpdateEzidoxApplication,
} from './ezidox.state.actions';
import { OneTimeLinkGenerationStrategy } from '@fundmoreai/models';

export interface EzidoxApplicationStateModel {
  loadingCount: number;
  ezidoxUrl: string;
  isIFrame: boolean;
  documentTemplateTypes: EzidoxDocumentTemplate[];
  requestDocumentsInProgress: boolean;
  updateRequestInProgress: boolean;
}

const DEFAULT_STATE: EzidoxApplicationStateModel = {
  loadingCount: 0,
  ezidoxUrl: '',
  isIFrame: false,
  documentTemplateTypes: [],
  requestDocumentsInProgress: false,
  updateRequestInProgress: false,
};
@State<EzidoxApplicationStateModel>({
  name: 'ezidoxApplication',
  defaults: DEFAULT_STATE,
})
@Injectable()
export class EzidoxApplicationState implements NgxsOnInit {
  constructor(private ezidoxService: EzidoxService, private store: Store) {}

  public ngxsOnInit(): void {
    this.store.dispatch(new DetermineIframeStrategy());
  }

  @Selector() static updateRequestInProgress(state: EzidoxApplicationStateModel) {
    return state.updateRequestInProgress;
  }

  @Selector() static requestDocumentsInProgress(state: EzidoxApplicationStateModel) {
    return state.requestDocumentsInProgress;
  }

  @Selector() static useIFrame(state: EzidoxApplicationStateModel) {
    return state.isIFrame;
  }

  @Selector() static url(state: EzidoxApplicationStateModel) {
    return state.ezidoxUrl;
  }

  @Selector() static loading$(state: EzidoxApplicationStateModel) {
    return state.loadingCount > 0;
  }

  @Selector() static ezidoxDocumentTemplates$(state: EzidoxApplicationStateModel) {
    return state.documentTemplateTypes;
  }

  @Action(DetermineIframeStrategy)
  private determineIframeStrategy(ctx: StateContext<EzidoxApplicationStateModel>) {
    let isIFrame = environment.embedSourceWhitelisted;

    if (localStorage?.getItem('iframeEzidox') == 'true') {
      isIFrame = true;
    }
    ctx.patchState({ isIFrame });
  }

  @Action(EzidoxIncrementLoading)
  private ezidoxIncrementLoading(ctx: StateContext<EzidoxApplicationStateModel>) {
    const state = ctx.getState();
    ctx.patchState({ loadingCount: state.loadingCount + 1 });
  }

  @Action(EzidoxDecrementLoading)
  private ezidoxDecrementLoading(ctx: StateContext<EzidoxApplicationStateModel>) {
    const state = ctx.getState();
    ctx.patchState({ loadingCount: state.loadingCount - 1 });
  }

  @Action(GetEzidoxUrl, { cancelUncompleted: true })
  private getEzidoxUrl(ctx: StateContext<EzidoxApplicationStateModel>, action: GetEzidoxUrl) {
    const dmOneTimeLinkGenerationStrategy = this.store.selectSnapshot(
      AppFeaturesState.dmOneTimeLinkGenerationStrategy,
    );
    if (dmOneTimeLinkGenerationStrategy !== OneTimeLinkGenerationStrategy.CACHED) {
      return;
    }

    const state = ctx.getState();
    ctx.patchState({ loadingCount: state.loadingCount + 1, ezidoxUrl: '' });
    const { isIFrame } = state;
    const { applicationId } = action;

    return this.ezidoxService
      .getApplicationEzidoxEmbeddingUrl(applicationId, EzidoxViewType.DETAILS, isIFrame)
      .pipe(
        tap((data) => {
          ctx.patchState({ loadingCount: state.loadingCount - 1, ezidoxUrl: data.url });
        }),
        catchError((error) => {
          console.error('getEzidoxUrl', error);
          ctx.patchState({ loadingCount: state.loadingCount - 1, ezidoxUrl: '' });

          return of(error); // eat the error so we dont display an error message
        }),
      );
  }

  @Action(CreateEzidoxApplication)
  private createEzidoxApplication(
    ctx: StateContext<EzidoxApplicationStateModel>,
    action: CreateEzidoxApplication,
  ) {
    const { applicationId } = action;
    const applicationFile: Blob = this.store.selectSnapshot(UploadComponentState.applicationFile);
    const state = ctx.getState();
    ctx.patchState({ loadingCount: state.loadingCount + 1, requestDocumentsInProgress: true });
    return this.ezidoxService.createEzidoxApplication(applicationId, applicationFile).pipe(
      switchMap(() => {
        return ctx.dispatch(new SetApplicationFile({} as Blob));
      }),
      finalize(() => {
        const state = ctx.getState();
        ctx.patchState({ loadingCount: state.loadingCount - 1 });
      }),
    );
  }

  @Action(UpdateEzidoxApplication)
  private updateEzidoxApplication(
    ctx: StateContext<EzidoxApplicationStateModel>,
    action: UpdateEzidoxApplication,
  ) {
    const { applicationId } = action;
    const state = ctx.getState();
    ctx.patchState({ loadingCount: state.loadingCount + 1, updateRequestInProgress: true });
    return this.ezidoxService.updateEzidoxApplication(applicationId).pipe(
      finalize(() => {
        const state = ctx.getState();
        ctx.patchState({ loadingCount: state.loadingCount - 1 });
      }),
    );
  }

  @Action(GetDocumentTemplateTypes)
  private getDocumentTemplateTypes({
    patchState,
    getState,
  }: StateContext<EzidoxApplicationStateModel>) {
    const isEzidoxEnabled = this.store.selectSnapshot(AppFeaturesState.isFundmoreDMEnabled);
    if (!isEzidoxEnabled) {
      return;
    }
    const state = getState();
    patchState({ loadingCount: state.loadingCount + 1, documentTemplateTypes: [] });
    return this.ezidoxService.getTemplateSets().pipe(
      tap((templates) => {
        const state = getState();
        patchState({ documentTemplateTypes: templates, loadingCount: state.loadingCount - 1 });
      }),
    );
  }

  @Action(ApplicationResetState)
  private reset({ setState, dispatch }: StateContext<EzidoxApplicationStateModel>) {
    setState(DEFAULT_STATE);
    dispatch(new DetermineIframeStrategy());
  }

  @Action(EzidoxUrlReset)
  private ezidoxUrlReset(ctx: StateContext<EzidoxApplicationStateModel>, action: EzidoxUrlReset) {
    ctx.patchState({ ezidoxUrl: '' });
  }

  @Action(EzidoxRequestStatusReset)
  private ezidoxRequestStatusReset(ctx: StateContext<EzidoxApplicationStateModel>) {
    ctx.patchState({ requestDocumentsInProgress: false, updateRequestInProgress: false });
  }
}
