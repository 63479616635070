export enum CmhcStatusCode {
  APPROVED = 'APPROVED',
  RISKED = 'RISKED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  ENTERED = 'ENTERED',
  CRITICAL = 'CRITICAL',
  INSURABLE = 'INSURABLE',
  NON_INSUR = 'NON-INSUR.',
  FINALIZED = 'FINALIZED',
  APPROUVÉ = 'APPROUVÉ',
  RISQUE = 'RISQUE',
  REJETÉ = 'REJETÉ',
  ANNULÉ = 'ANNULÉ',
  SAISI = 'SAISI',
  CRITIQUE = 'CRITIQUE',
  ASSURABLE = 'ASSURABLE',
  NON_ASSUR = 'NON ASSUR.',
  TERMINE = 'TERMINE',
}
