import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as HandleBars from 'handlebars/dist/cjs/handlebars';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { compile } from 'handlebars/dist/cjs/handlebars';
import { formatDate, registerLocaleData } from '@angular/common';
import { DocumentContext } from '../features/shared/documents';
import { numberToWords } from 'src/app/shared/number-to-words';
import { addBusinessDays, addDays, addMonths } from 'date-fns';
import frCaLocale from '@angular/common/locales/fr-CA';
import enCaLocale from '@angular/common/locales/en-CA';
registerLocaleData(frCaLocale);
registerLocaleData(enCaLocale);

export const NEW_LINE_SEPARATOR = '@newline';

export function formatAmount(amount: unknown, defaultValue: string) {
  if (amount === undefined || amount === 'undefined' || amount === null || amount === '') {
    return typeof defaultValue === 'string' ? defaultValue : '';
  }

  const valueNumber = parseFloat(amount as string);

  if (isNaN(valueNumber)) {
    console.error(amount, ' can not be parsed');
    return '';
  }

  return valueNumber.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
}

export function formatAmountToCAD(amount: unknown, locale: string) {
  if (amount === undefined || amount === 'undefined' || amount === null || amount === '') {
    return '';
  }

  const valueNumber = parseFloat(amount as string);

  if (isNaN(valueNumber)) {
    console.error(amount, ' can not be parsed');
    return '';
  }

  const localeToUse = typeof locale === 'string' ? locale : 'en-CA';

  return Intl.NumberFormat(localeToUse, { style: 'currency', currency: 'CAD' }).format(valueNumber);
}

let indexOfIfEqualValue = 0;
let counter = 0;

HandleBars.registerHelper('count', (value: []) => {
  // get an array length
  return value?.length || 0;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
HandleBars.registerHelper('setVariable', (varName: string, value: unknown, options: any) => {
  options.data.root[varName] = value;
});

HandleBars.registerHelper('exists', (value: unknown) => {
  // checks whether the value provided is either an empty array/object or it's a falsy value
  let exists: boolean;

  if (Array.isArray(value)) {
    exists = !!value.length;
  } else {
    if (typeof value === 'object' && value !== null) {
      exists = !!Object.keys(value).length;
    } else {
      exists = !!value;
    }
  }

  return exists;
});

HandleBars.registerHelper('formatDate', (date: string | number | Date, format: unknown) => {
  if (!date) {
    return '';
  }

  const dateFormat = typeof format === 'string' ? format : 'mediumDate';

  return formatDate(date, dateFormat, 'en-CA');
});

HandleBars.registerHelper('formatDateFr', (date: string | number | Date, format: unknown) => {
  if (!date) {
    return '';
  }

  const dateFormat = typeof format === 'string' ? format : 'MMM dd, yyyy';

  return formatDate(date, dateFormat, 'fr-CA');
});

HandleBars.registerHelper('formatAmount', (amount: unknown, defaultValue: string) => {
  return formatAmount(amount, defaultValue);
});

HandleBars.registerHelper('formatAmountToCAD', (amount: unknown, defaultValue: string) => {
  return formatAmountToCAD(amount, defaultValue);
});

HandleBars.registerHelper('formatDay', (number: number) => {
  const oneDigit = number % 10;
  const twoDigits = number % 100;
  if (oneDigit == 1 && twoDigits != 11) {
    return number + 'st';
  }
  if (oneDigit == 2 && twoDigits != 12) {
    return number + 'nd';
  }
  if (oneDigit == 3 && twoDigits != 13) {
    return number + 'rd';
  }
  return number + 'th';
});

HandleBars.registerHelper(
  'ifEqual',
  (
    arg1: unknown,
    arg2: unknown,
    returnOption?: unknown,
    options?: { fn: (arg0: undefined) => unknown; inverse: (arg0: undefined) => unknown },
  ) => {
    counter++;

    if (arg1 === arg2) {
      indexOfIfEqualValue = counter;
      if (returnOption) {
        return options?.fn(this);
      }
      return true;
    } else {
      if (returnOption) {
        return options?.inverse(this);
      }
      return false;
    }
  },
);

HandleBars.registerHelper(
  'ifNotEqual',
  (
    arg1: unknown,
    arg2: unknown,
    options: { fn: (arg0: undefined) => unknown; inverse: (arg0: undefined) => unknown },
  ) => {
    if (arg1 !== arg2) {
      return options?.fn(this);
    }
    return options?.inverse(this);
  },
);

HandleBars.registerHelper('toLowerCase', (value: unknown) => {
  return (value && typeof value === 'string' && value.toLowerCase()) || '';
});

HandleBars.registerHelper('equals', (arg1: unknown, arg2: unknown) => {
  return arg1 == arg2;
});

HandleBars.registerHelper('greater', (arg1: number, arg2: number) => {
  return arg1 > arg2;
});

HandleBars.registerHelper('greaterOrEqual', (arg1: number, arg2: number) => {
  return arg1 >= arg2;
});

HandleBars.registerHelper('less', (arg1: number, arg2: number) => {
  return arg1 < arg2;
});

HandleBars.registerHelper('lessOrEqual', (arg1: number, arg2: number) => {
  return arg1 <= arg2;
});

HandleBars.registerHelper('stopCounter', () => {
  counter = 0;
  const amountToShow = indexOfIfEqualValue;
  indexOfIfEqualValue = 0;
  return amountToShow - 1;
});

HandleBars.registerHelper('sum', (array: [], column: string, allowInvalidValues = true) => {
  const numbers = array.map((obj) => {
    if (allowInvalidValues) {
      return Number(obj[column]) || 0;
    }
    return Number(obj[column]);
  });

  const sum = numbers.reduce((acc, val) => acc + val, 0);

  return sum;
});

HandleBars.registerHelper('toNumber', (value: string) => {
  const num = Number(value);
  return isNaN(num) ? 0 : num;
});

HandleBars.registerHelper('add', (...numberArray: number[]) => {
  const newNumberArray = numberArray.slice(0, -1);
  const newNumber = newNumberArray.reduce((sum, number) => sum + number);

  return newNumber;
});

HandleBars.registerHelper('addDays', (startingDate: string | number | Date, daysToAdd: number) => {
  const date = new Date(startingDate);
  return addDays(date, daysToAdd);
});

HandleBars.registerHelper(
  'addBusinessDays',
  (startingDate: string | number | Date, daysToAdd: number) => {
    const date = new Date(startingDate);
    return addBusinessDays(date, daysToAdd);
  },
);

HandleBars.registerHelper(
  'addMonths',
  (startingDate: string | number | Date, monthsToAdd: number) => {
    const date = new Date(startingDate);
    return addMonths(date, monthsToAdd);
  },
);

HandleBars.registerHelper('sort', (array: [], key: string, reverse = false) => {
  if (!key || typeof key !== 'string') {
    const sorted = [...array].sort();
    return reverse == true ? sorted.reverse() : sorted;
  }
  const sorted = [...array].sort((a, b) => {
    const aKey = a[key];
    const bKey = b[key];

    const check = aKey < bKey ? -1 : aKey > bKey ? 1 : 0;
    return check;
  });
  return reverse == true ? sorted.reverse() : sorted;
});

HandleBars.registerHelper('filter', (array: [], key: string, value: unknown) => {
  if (!key || typeof key !== 'string') {
    return array;
  }
  if (!array || array.length === 0) {
    return array;
  }
  const results = array.filter(function (v) {
    return value === v[key];
  });

  return results;
});

HandleBars.registerHelper('contains', (array: [], value: never) => {
  if (!array || array.length === 0) {
    return false;
  }
  const results = array.includes(value);

  return results;
});

HandleBars.registerHelper('filterNested', (array: [], key: string, value: unknown) => {
  if (!key || typeof key !== 'string') {
    return array;
  }
  if (!array || array.length === 0) {
    return array;
  }
  const keys = key.split('.');
  const results = array.filter(function (v) {
    let val = v;
    for (let i = 0; i < keys.length; i++) {
      val = val[keys[i]];
    }
    return value === val;
  });

  return results;
});

HandleBars.registerHelper('union', (array1: [], array2: []) => {
  return (array1 ?? []).concat(array2 ?? []);
});

HandleBars.registerHelper('subtract', (...numberArray: number[]) => {
  const newNumberArray = numberArray.slice(0, -1);
  const newNumber = newNumberArray.reduce((sum, number) => sum - number);

  return newNumber;
});

HandleBars.registerHelper('divide', (...numberArray: number[]) => {
  const newNumberArray = numberArray.slice(0, -1);
  const newNumber = newNumberArray.reduce((result, number) => result / number);

  return newNumber;
});

HandleBars.registerHelper('multiply', (...numberArray: number[]) => {
  const newNumberArray = numberArray.slice(0, -1);
  const newNumber = newNumberArray.reduce((result, number) => result * number);

  return newNumber;
});

HandleBars.registerHelper('persistBreakLines', (text: string) => {
  let parseText = HandleBars.Utils.escapeExpression(text);
  parseText = parseText.replace(/(\r\n|\n|\r)/gm, '<br>');
  return new HandleBars.SafeString(parseText);
});

HandleBars.registerHelper('numberToDateWords', (value: number) => {
  // example
  // value  result
  // 0      zero (0) month
  // 24     one (1) year
  // 32     two (2) years and eight (8) months
  // 6      six (6) months
  const months = value % 12;
  const years = (value - months) / 12;
  const monthsInWords = `${numberToWords(months)} (${months}) month${months > 1 ? 's' : ''}`;
  const yearsInWords = `${numberToWords(years)} (${years}) year${years > 1 ? 's' : ''}`;

  return [years ? yearsInWords : null, !years || months ? monthsInWords : null]
    .filter((x) => !!x)
    .join(' and ');
});

HandleBars.registerHelper({
  eq: (v1: unknown, v2: unknown) => v1 === v2,
  ne: (v1: unknown, v2: unknown) => v1 !== v2,
  lt: (v1: number, v2: number) => v1 < v2,
  gt: (v1: number, v2: number) => v1 > v2,
  lte: (v1: number, v2: number) => v1 <= v2,
  gte: (v1: number, v2: number) => v1 >= v2,
  and(...args: unknown[]) {
    return Array.prototype.every.call(args, Boolean);
  },
  or(...args: unknown[]) {
    return Array.prototype.slice.call(args, 0, -1).some(Boolean);
  },
});

@Injectable({
  providedIn: 'root',
})
export class HandlebarsService {
  constructor(private http: HttpClient) {}

  static compileTemplate(
    htmlContent: string,
    context: DocumentContext | Record<string, unknown>,
  ): string {
    const templateDelegate = compile(htmlContent);

    return templateDelegate(context);
  }

  getContent(relativePath: string): Observable<string> {
    return this.http.get<string>(relativePath, {
      responseType: 'text' as 'json',
    });
  }
}
