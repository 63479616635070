export enum OptaCommercialExteriorWallType {
  AGGREGATE = 'Aggregate',
  ALUMINUM = 'Aluminum',
  ALUMINUM_SIDING = 'Aluminum Siding',
  BRICK = 'Brick',
  BRICK_MASONRY_VENEER = 'Brick / Masonry Veneer',
  CERAMIC = 'Ceramic',
  CONCRETE = 'Concrete',
  CONCRETE_BLOCK = 'Concrete Block',
  GLASS = 'Glass',
  METAL = 'Metal',
  METAL_SIDING = 'Metal Siding',
  STEEL = 'Steel',
  STONE = 'Stone',
  STUCCO = 'Stucco',
  VINYL_SIDING = 'Vinyl Siding',
  WOOD = 'Wood',
  OTHER = 'Other',
  UNKNOWN = 'Unknown',
}
