import { MaritalType } from './applicant';
import {
  CorroborationField,
  CorroborationSource,
  PropertyType,
  Province,
  StreetDirection,
  StreetType,
  RentalOffsetType,
  Country,
  ConstructionType,
  DwellingStyle,
  DwellingType,
  GarageType,
  HeatingType,
  OccupancyType,
  TaxesPaidByType,
  TenureType,
  WaterType,
  FoundationType,
  LocationTierType,
  State,
  CustomEQOccupancyType,
} from './enums';
import { RawGoogleGeocodeResponse } from './google';
import { Mortgage } from './mortgage';
import {
  OptaMarketValuationCorroborationValue,
  OptaPropertyDetailsCorroborationValue,
} from './opta/opta';
import { PropertyAppraisal } from './propertyAppraisal';
import { PropertyInsurance } from './propertyInsurance';

export enum PropertyKey {
  MORTGAGES = 'Mortgages',
  YEAR_BUILT = 'yearBuilt',
  ANNUAL_TAXES = 'annualTaxes',
  APPRAISED_DATE = 'appraisedDate',
  APPRAISED_VALUE = 'appraisedValue',
  BLOCK = 'block',
  CONCESSION_TOWNSHIP = 'concessionTownship',
  CONDO_FEES = 'condoFees',
  CONDO_FEES_INCLUDE_HEATING = 'condoFeesIncludeHeating',
  CONDO_FEES_PERCENT_IN_CALCULATION = 'condoFeesPercentInCalculation',
  CONSTRUCTION_TYPE = 'constructionType',
  CREATED_AT = 'createdAt',
  DWELLING_STYLE = 'dwellingStyle',
  DWELLING_TYPE = 'dwellingType',
  ENVIRONMENTAL_HAZARD = 'isEnvironmentalHazard',
  ESTIMATED_VALUE = 'estimatedValue',
  ESTIMATED_VALUE_DATE = 'estimatedValueDate',
  GARAGE_SIZE = 'garageSize',
  GARAGE_TYPE = 'garageType',
  HEATING_COST = 'heatingCost',
  HEATING_TYPE = 'heatingType',
  IMPROVEMENTS = 'improvements',
  INCLUDE_EXPENSES_IN_TDS = 'includeExpensesInTDS',
  LIVING_SPACE = 'livingSpace',
  LOT = 'lot',
  LOT_SIZE = 'lotSize',
  NO_OF_UNITS = 'noOfUnits',
  OCCUPANCY = 'occupancy',
  PROPERTY_ADDRESS = 'propertyAddress',
  STREET_NUMBER = 'streetNumber',
  STREET_NUMBER_SUFFIX = 'streetNumberSuffix',
  STREET_NAME = 'streetName',
  STREET_TYPE = 'streetType',
  STREET_DIRECTION = 'streetDirection',
  UNIT = 'unit',
  UNIT_TYPE = 'unitType',
  CITY = 'city',
  PROVINCE = 'province',
  COUNTRY = 'country',
  POSTAL_CODE = 'postalCode',
  LEGAL_DESCRIPTION = 'legalDescription',
  SHORT_LEGAL_DESCRIPTION = 'shortLegalDescription',
  PID = 'pid',
  PLAN = 'plan',
  ZONING_TYPE = 'zoningType',
  PROPERTY_TYPE = 'propertyType',
  PURCHASE_DATE = 'purchaseDate',
  PURCHASE_PRICE = 'purchasePrice',
  RPE_GENERAL_EXPENSES = 'rpeGeneralExpenses',
  RPE_HYDRO = 'rpeHydro',
  RPE_INSURANCE = 'rpeInsurance',
  RPE_INTEREST_CHARGES = 'rpeInterestCharges',
  RPE_MANAGEMENT_EXPENSES = 'rpeManagementExpenses',
  RPE_MONTHLY_RENTAL_INCOME = 'rpeMonthlyRentalIncome',
  RPE_OFFSET = 'rpeOffset',
  RPE_RENTAL_OFFSET_OPTION = 'rpeRentalOffsetOption',
  RPE_REPAIRS = 'rpeRepairs',
  SEWAGE_INFO = 'sewageInfo',
  TAXATION_YEAR = 'taxationYear',
  TAXES_PAID_BY = 'taxesPaidBy',
  TENURE = 'tenure',
  TOTAL_EXPENSES = 'totalExpenses',
  TYPE = 'type',
  UPDATED_AT = 'updatedAt',
  VALUE_OF_IMPROVEMENTS = 'valueOfImprovements',
  WATER_TYPE = 'waterType',
  FOUNDATION_TYPE = 'foundationType',
  CORROBORATIONS = 'corroborations',
  NAME_ON_TITLE = 'nameOnTitle',
  PROPERTY_ADDRESS_EXPANDED = 'propertyAddressExpanded',
  IS_COLLATERALIZED = 'isCollateralized',
  OTHER_SECURITY_CONDITIONS = 'otherSecurityConditions',
  HAS_OTHER_SECURITY_CONDITIONS = 'hasOtherSecurityConditions',
  INCLUDE_IN_CLTV = 'includeInCLTV',
  PROPERTY_VALUE = 'propertyValue',
  LOCATION_TIER = 'locationTier',
  APPLICANT_PROPERTIES = 'ApplicantProperties',
  IS_MLS_LISTING = 'isMlsListing',
  MLS_NUMBER = 'mlsNumber',
  TAX_INSTALLMENT = 'taxInstallment',
  TAX_ROLL_NUMBER = 'taxRollNumber',
  TAX_FIRST_PAYMENT_TYPE = 'taxFirstPaymentType',
  SECTION = 'section',
  PROPERTY_NUMBER = 'propertyNumber',
  PROPERTY_HOLDER = 'propertyHolder',
  ORIGINAL_PURCHASE_PRICE = 'originalPurchasePrice',
  MARK_AS_SOLD = 'markAsSold',
  SOLD_ON = 'soldOn',
  ESTIMATED_TAX_HOLDBACK = 'estimatedTaxHoldback',
  IS_ENERGY_EFFICIENT = 'isEnergyEfficient',
  BUILDER_NAME = 'builderName',
  IS_PRIMARY_RESIDENCE = 'isPrimaryResidence',
}

export enum PropertyMarketValuation {
  VALUE = 'value',
  CONFIDENCE_RATING = 'confidenceRating',
}

export interface Property {
  Mortgages?: Mortgage[] | null;
  yearBuilt?: number | null;
  annualTaxes: number;
  applicationId: string;
  appraisedDate: string | null;
  appraisedValue: string | null;
  block: string | null;
  concessionTownship: string | null;
  condoFees: number;
  condoFeesIncludeHeating: boolean;
  condoFeesPercentInCalculation: number;
  constructionType: ConstructionType | null;
  dwellingStyle: DwellingStyle;
  dwellingType: DwellingType;
  isEnvironmentalHazard: boolean;
  estimatedValue: number;
  estimatedValueDate: string;
  garageSize: GarageSize;
  garageType: GarageType | null;
  heatingCost: number | null;
  heatingType: HeatingType;
  id: string;
  improvements: string | null;
  includeExpensesInTDS?: boolean;
  includeAnnualTaxesInTDS?: boolean;
  includeCondoFeesInTDS?: boolean;
  includeHeatingCostInTDS?: boolean;
  includeRpeGeneralExpensesInTDS?: boolean;
  includeRpeInsuranceInTDS?: boolean;
  includeRpeHydroInTDS?: boolean;
  includeRpeManagementExpensesInTDS?: boolean;
  includeRpeRepairsInTDS?: boolean;
  includeRpeInterestChargesInTDS?: boolean;
  livingSpace: number;
  lot: string | null;
  lotSize: number;
  noOfUnits: number | null;
  occupancy: OccupancyType | CustomEQOccupancyType | null;
  propertyAddress?: string | null;
  legalDescription?: string;
  shortLegalDescription?: string;
  pid?: string;
  plan: string | null;
  zoningType: ZoningTypes | null;
  propertyType: PropertyTypes | null;
  purchasePrice: number | null;
  purchaseDate: string | null;
  rpeGeneralExpenses: number | null;
  rpeHydro: number | null;
  rpeInsurance: number | null;
  rpeInterestCharges: number | null;
  rpeManagementExpenses: number | null;
  rpeMonthlyRentalIncome: number | null;
  rpeOffset: number | null;
  rpeRentalOffsetOption: RentalOffsetType | null;
  rpeRepairs: number | null;
  sewageInfo: SewageType | null;
  taxationYear: number;
  taxesPaidBy: TaxesPaidByType;
  tenure: TenureType | null;
  totalExpenses: number | null;
  type: PropertyType;
  valueOfImprovements: number | null;
  waterType: WaterType | null;
  foundationType: FoundationType | null;
  corroborations: Corroboration[];
  nameOnTitle: string | null;
  propertyAddressExpanded: PropertyAddressDetails;
  isCollateralized: boolean;
  PropertyOwners: PropertyOwner[];
  otherSecurityConditions?: string;
  hasOtherSecurityConditions: boolean;
  includeInCLTV: boolean;
  locationTier?: LocationTierType;
  ApplicantProperties: ApplicantProperties[];
  isMlsListing?: boolean | null;
  mlsNumber: string | null;
  taxInstallment?: number | null;
  taxRollNumber?: string | null;
  taxFirstPaymentType?: TaxFirstPaymentType | null;
  section?: string | null;
  propertyNumber?: number | null;
  PropertyAppraisals: PropertyAppraisal[];
  PropertyInsurances: PropertyInsurance[];
  estimatedTaxHoldback?: number | null;
  originalPurchasePrice?: number | null;
  createdAt: string;
  updatedAt: string;
  externalSecuritySequenceNumber?: number | null;
  externalAssetSequenceNumber?: number | null;
  externalIncomeSequenceNumber?: number | null;
  annualTaxesExternalSequenceNumber?: number | null;
  heatingCostExternalSequenceNumber?: number | null;
  condoFeesExternalSequenceNumber?: number | null;
  rpeHydroExternalSequenceNumber?: number | null;
  rpeInsuranceExternalSequenceNumber?: number | null;
  rpeInterestChargesExternalSequenceNumber?: number | null;
  rpeGeneralExpensesExternalSequenceNumber?: number | null;
  rpeManagementExpensesExternalSequenceNumber?: number | null;
  rpeRepairsExternalSequenceNumber?: number | null;
  markAsSold?: boolean | null;
  soldOn?: string | null;
  customFields: { [key: string]: string };
  isEnergyEfficient?: boolean | null;
  builderName?: string | null;
  isPrimaryResidence?: boolean | null;
}

export interface PropertyMortgages {
  monthlyPayment: number | null;
  balance: number | null;
}
export interface PropertyViewModel extends Property {
  mortgages: PropertyMortgages;
  Mortgages: Mortgage[];

  isAppraised: boolean;
}

export enum PropertyHumanizedKey {
  propertyAddress = 'Property Address',
  streetNumber = 'Street Number',
  streetNumberSuffix = 'Street No. Suffix',
  streetName = 'Street Name',
  streetType = 'Street Type',
  streetDirection = 'Street Direction',
  unit = 'Unit, Apt, Suite #',
  unitType = 'Unit Type',
  city = 'City',
  province = 'Province',
  country = 'Country',
  postalCode = 'Postal Code',
  yearBuilt = 'Year Built',
  annualTaxes = 'Annual Taxes',
  block = 'Block',
  concessionTownship = 'Concession Township',
  condoFees = 'Condo Fees',
  condoFeesIncludeHeating = 'Condo Fees Include Heating',
  type = 'Type',
  constructionType = 'Construction Type',
  dwellingStyle = 'Dwelling Style',
  dwellingType = 'Dwelling Type',
  includeExpensesInTDS = 'Include Expenses In TDS',
  estimatedValueDate = 'Estimated Value Date',
  isEnvironmentalHazard = 'Environmental Hazard',
  estimatedValue = 'Estimated Value',
  garageSize = 'Garage Size',
  garageType = 'Garage Type',
  heatingCost = 'Heating Cost',
  heatingType = 'Heating Type',
  improvements = 'Improvements',
  livingSpace = 'Living Space',
  lot = 'Lot',
  lotSize = 'Lot Size',
  noOfUnits = 'Number of Units',
  occupancy = 'Occupancy',
  legalDescription = 'Legal Description',
  shortLegalDescription = 'Short Legal Description',
  pid = 'Pid',
  zoningType = 'Zoning Type',
  propertyType = 'Property Type',
  purchasePrice = 'Purchase Price',
  rpeGeneralExpenses = 'RPE General Expenses',
  rpeHydro = 'RPE Hydro',
  rpeInsurance = 'RPE Insurance',
  rpeInterestCharges = 'RPE Interest Charges',
  rpeManagementExpenses = 'RPE Management Expenses',
  rpeMonthlyRentalIncome = 'RPE Monthly Rental Income',
  rpeOffset = 'RPE Offset',
  rpeRentalOffsetOption = 'RPE Rental Offset Option',
  rpeRepairs = 'RPE Repairs',
  sewageInfo = 'Sewage Info',
  taxationYear = 'Taxation Year',
  taxesPaidBy = 'Taxes Paid By',
  tenure = 'Tenure',
  totalExpenses = 'Total Expenses',
  valueOfImprovements = 'Value of Improvements',
  waterType = 'Water Type',
  foundationType = 'Foundation Type',
  isCollateralized = 'Collateralized',
  hasOtherSecurityConditions = 'Has Other Security Conditions',
  otherSecurityConditions = 'Other Security Conditions',
  includeInCLTV = 'Include In CLTV',
  locationTier = 'Location Tier',
  isMlsListing = 'Is Mls Listing',
  mlsNumber = 'Mls Number',
  markAsSold = 'Property is sold',
  soldOn = 'Sold On',
  estimatedTaxHoldback = 'Estimated Tax Holdback',
  isEnergyEfficient = 'Is Energy Efficient',
  builderName = 'Builder Name',
  isPrimaryResidence = 'Property is Primary Residence',
}

export interface PropertyOwner {
  id: string;
  propertyId: string;
  individualNameOnTitle?: string;
  spouseName?: string;
  spousalConsentRequired: boolean;
  isCompany: boolean;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  address?: string;
  maritalStatus?: MaritalType;
}

export interface PropertyAddressDetails {
  id: string;
  propertyId: string;
  country: Country | null;
  streetNumber: string | null;
  streetNumberSuffix?: string | null;
  streetName: string | null;
  streetType: StreetType | null;
  streetDirection: StreetDirection | null;
  unit: string | null;
  unitType: string | null;
  formattedAddress: string | null;
  address: string | null | undefined;
  // address and formattedAddress are the same
  // formattedAddress is the one that is used by the PropertyAddress table
  // address is the one that is used by the (currently applicant only) AddressExpanded table
  province: Province | State | null;
  city: string | null;
  postalCodePrefix: string | null;
  postalCode: string | null;
  lat: number | null;
  lng: number | null;
  rawGoogleGeocodeResponse: RawGoogleGeocodeResponse | null;
  externalSequenceNumber?: number | null;
}

export enum ProvinceCode {
  Alberta = 'AB',
  British_Columbia = 'BC',
  Manitoba = 'MB',
  New_Brunswick = 'NB',
  Newfoundland_Labrador = 'NL',
  Northwest_Territories = 'NT',
  Nova_Scotia = 'NS',
  Nunavut = 'NU',
  Ontario = 'ON',
  Prince_Edward_Island = 'PE',
  Quebec = 'QC',
  Saskatchewan = 'SK',
  Yukon = 'YT',
}

export enum MapsState {
  Alaska = 'AK',
  Alabama = 'AL',
  Arkansas = 'AR',
  Arizona = 'AZ',
  California = 'CA',
  Colorado = 'CO',
  Connecticut = 'CT',
  DistrictOfColumbia = 'DC',
  Delaware = 'DE',
  Florida = 'FL',
  Georgia = 'GA',
  Hawaii = 'HI',
  Iowa = 'IA',
  Idaho = 'ID',
  Illinois = 'IL',
  Indiana = 'IN',
  Kansas = 'KS',
  Kentucky = 'KY',
  Louisiana = 'LA',
  Massachusetts = 'MA',
  Maryland = 'MD',
  Maine = 'ME',
  Michigan = 'MI',
  Minnesota = 'MN',
  Missouri = 'MO',
  Mississippi = 'MS',
  Montana = 'MT',
  NorthCarolina = 'NC',
  NorthDakota = 'ND',
  Nebraska = 'NE',
  NewHampshire = 'NH',
  NewJersey = 'NJ',
  NewMexico = 'NM',
  Nevada = 'NV',
  NewYork = 'NY',
  Ohio = 'OH',
  Oklahoma = 'OK',
  Oregon = 'OR',
  Pennsylvania = 'PA',
  RhodeIsland = 'RI',
  SouthCarolina = 'SC',
  SouthDakota = 'SD',
  Tennessee = 'TN',
  Texas = 'TX',
  Utah = 'UT',
  Virginia = 'VA',
  Vermont = 'VT',
  Washington = 'WA',
  Wisconsin = 'WI',
  WestVirginia = 'WV',
  Wyoming = 'WY',
}

export const provinceToProvinceCode: Record<
  Province | State,
  ProvinceCode | MapsState | undefined
> = {
  [Province.Alberta]: ProvinceCode.Alberta,
  [Province.British_Columbia]: ProvinceCode.British_Columbia,
  [Province.Manitoba]: ProvinceCode.Manitoba,
  [Province.New_Brunswick]: ProvinceCode.New_Brunswick,
  [Province.Newfoundland_Labrador]: ProvinceCode.Newfoundland_Labrador,
  [Province.Northwest_Territories]: ProvinceCode.Northwest_Territories,
  [Province.Nova_Scotia]: ProvinceCode.Nova_Scotia,
  [Province.Nunavut]: ProvinceCode.Nunavut,
  [Province.Ontario]: ProvinceCode.Ontario,
  [Province.Prince_Edward_Island]: ProvinceCode.Prince_Edward_Island,
  [Province.Quebec]: ProvinceCode.Quebec,
  [Province.Saskatchewan]: ProvinceCode.Saskatchewan,
  [Province.Yukon]: ProvinceCode.Yukon,
  [State.Alabama]: MapsState.Alabama,
  [State.Alaska]: MapsState.Alaska,
  [State.American_Samoa]: undefined,
  [State.Arizona]: MapsState.Arizona,
  [State.Arkansas]: MapsState.Arkansas,
  [State.California]: MapsState.California,
  [State.Colorado]: MapsState.Colorado,
  [State.Connecticut]: MapsState.Connecticut,
  [State.Delaware]: MapsState.Delaware,
  [State.District_of_Columbia]: MapsState.DistrictOfColumbia,
  [State.Federated_States_of_Micronesia]: undefined,
  [State.Florida]: MapsState.Florida,
  [State.Georgia]: MapsState.Georgia,
  [State.Guam]: undefined,
  [State.Hawaii]: MapsState.Hawaii,
  [State.Idaho]: MapsState.Idaho,
  [State.Illinois]: MapsState.Illinois,
  [State.Indiana]: MapsState.Indiana,
  [State.Iowa]: MapsState.Iowa,
  [State.Kansas]: MapsState.Kansas,
  [State.Kentucky]: MapsState.Kentucky,
  [State.Louisiana]: MapsState.Louisiana,
  [State.Maine]: MapsState.Maine,
  [State.Marshall_Islands]: undefined,
  [State.Maryland]: MapsState.Maryland,
  [State.Massachusetts]: MapsState.Massachusetts,
  [State.Michigan]: MapsState.Michigan,
  [State.Minnesota]: MapsState.Minnesota,
  [State.Mississippi]: MapsState.Mississippi,
  [State.Missouri]: MapsState.Missouri,
  [State.Montana]: MapsState.Montana,
  [State.Nebraska]: MapsState.Nebraska,
  [State.Nevada]: MapsState.Nevada,
  [State.New_Hampshire]: MapsState.NewHampshire,
  [State.New_Jersey]: MapsState.NewJersey,
  [State.New_Mexico]: MapsState.NewMexico,
  [State.New_York]: MapsState.NewYork,
  [State.North_Carolina]: MapsState.NorthCarolina,
  [State.North_Dakota]: MapsState.NorthDakota,
  [State.Northern_Mariana_Islands]: undefined,
  [State.Ohio]: MapsState.Ohio,
  [State.Oklahoma]: MapsState.Oklahoma,
  [State.Oregon]: MapsState.Oregon,
  [State.Palau]: undefined,
  [State.Pennsylvania]: MapsState.Pennsylvania,
  [State.Puerto_Rico]: undefined,
  [State.Rhode_Island]: MapsState.RhodeIsland,
  [State.South_Carolina]: MapsState.SouthCarolina,
  [State.South_Dakota]: MapsState.SouthDakota,
  [State.Tennessee]: MapsState.Tennessee,
  [State.Texas]: MapsState.Texas,
  [State.Utah]: MapsState.Utah,
  [State.Vermont]: MapsState.Vermont,
  [State.Virgin_Islands]: undefined,
  [State.Virginia]: MapsState.Virginia,
  [State.Washington]: MapsState.Washington,
  [State.West_Virginia]: MapsState.WestVirginia,
  [State.Wisconsin]: MapsState.Wisconsin,
  [State.Wyoming]: MapsState.Wyoming,
  [State.Armed_Forces_Americas]: undefined,
  [State.Armed_Forces_Europe]: undefined,
  [State.Armed_Forces_Pacific]: undefined,
};

export interface Corroboration {
  source: CorroborationSource;
  field: CorroborationField;
  value: OptaMarketValuationCorroborationValue | OptaPropertyDetailsCorroborationValue;
  addedAt: string;
}

export enum PropertyTypes {
  PRIMARY_RESIDENCE = 'PRIMARY_RESIDENCE',
  INVESTMENT_PROPERTY = 'INVESTMENT_PROPERTY',
  MULTI_UNIT = 'MULTI_UNIT',
  SECOND_HOME = 'SECOND_HOME',
  COTTAGE = 'COTTAGE',
  MOBILE_MODULAR = 'MOBILE_MODULAR',
}

export interface ApplicantProperties {
  applicantId: string;
  propertyId: string;
}

export enum ZoningTypes {
  RESIDENTIAL = 'RESIDENTIAL',
  COMMERCIAL = 'COMMERCIAL',
  RESCOMMERCIAL = 'RESCOMMERCIAL',
  LAND = 'LAND',
  INDUSTRIAL = 'INDUSTRIAL',
}

export enum SewageType {
  MUNICIPAL = 'MUNICIPAL',
  SEPTIC_SYSTEM = 'SEPTIC_SYSTEM',
  HOLDING_TANK = 'HOLDING_TANK',
  UNKNOWN = 'UNKNOWN',
}

export enum GarageSize {
  NONE = 'NONE',
  SINGLE = 'SINGLE',
  DOUBLE = 'DOUBLE',
  TRIPLE = 'TRIPLE',
}

export enum TaxFirstPaymentType {
  FINAL = 'FINAL',
  INTERIM = 'INTERIM',
  SUPPLEMENTARY = 'SUPPLEMENTARY',
}
