import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, of, switchMap } from 'rxjs';
import { OutOfOfficeDetails, UserPreference } from '@fundmoreai/models';

@Injectable({
  providedIn: 'root',
})
export class UserPreferenceService {
  constructor(private http: HttpClient) {}

  getUserPreferences(): Observable<UserPreference[]> {
    return this.http.get<UserPreference[]>(`${environment.api_url}/preferences`);
  }

  getUserPreference(userId: string): Observable<UserPreference> {
    return this.http.get<UserPreference>(`${environment.api_url}/preferences/${userId}`);
  }

  createUserPreference(preference: UserPreference): Observable<UserPreference> {
    return this.http.post<UserPreference>(`${environment.api_url}/preferences`, preference);
  }

  updateUserPreference(userId: string, preference: UserPreference): Observable<UserPreference> {
    return this.http.patch<UserPreference>(
      `${environment.api_url}/preferences/${userId}`,
      preference,
    );
  }

  updateOwnOutOfOfficeDetails(oooDetails: OutOfOfficeDetails): Observable<UserPreference> {
    return this.http.patch<UserPreference>(
      `${environment.api_url}/preferences/outOfOffice/own`,
      oooDetails,
    );
  }

  userExistenceVerification(userId: string, isManager: boolean): Observable<UserPreference> {
    return this.getUserPreference(userId).pipe(
      switchMap((result) => {
        if (result) {
          return of(result);
        } else {
          if (isManager) {
            return this.createUserPreference({ userId });
          } else {
            return of({ userId });
          }
        }
      }),
    );
  }
}
