import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GeneralAbstractPermissions } from './model';
import { ApplicationStage, MoveStageModel, PostFundingApplicationStage } from '@fundmoreai/models';

@Injectable({
  providedIn: 'root',
})
export class PermissionsService {
  constructor(private http: HttpClient) {}

  static getApplicationStageMovePermissionsMap(
    canMoveStage: MoveStageModel[],
    tenantStages: ApplicationStage[] | undefined,
  ) {
    const allStages = [
      ApplicationStage.ADJUDICATION,
      ApplicationStage.COMPLIANCE,
      ApplicationStage.DEAL_ACCEPTANCE,
      ApplicationStage.DEAL_SIGNED,
      ApplicationStage.DECLINED,
      ApplicationStage.DOCUMENT_REVIEW,
      ApplicationStage.FINAL_APPROVAL,
      ApplicationStage.FINAL_REVIEW,
      ApplicationStage.FORECLOSURE,
      ApplicationStage.FUNDED,
      ApplicationStage.LAWYER_INSTRUCTED,
      ApplicationStage.NEW_APPLICATION,
      ApplicationStage.OPERATIONS_FULFILLMENT,
      ApplicationStage.PAID_IN_FULL,
      ApplicationStage.PAID_OUT,
      ApplicationStage.PAST_DUE_UNDER_90,
      ApplicationStage.PAST_DUE_OVER_90,
      ApplicationStage.PRE_FUND,
      ApplicationStage.PRESENT_DEAL,
      ApplicationStage.PROPOSE_DEAL,
      ApplicationStage.RENEWAL,
      ApplicationStage.UNDERWRITING,
    ];

    const applicationStageMovePermissionsMap = canMoveStage.reduce((result, item) => {
      (item.from || allStages).forEach((fromStage) => {
        let permittedStageMoves = [...(result[fromStage] || []), ...(item.to || allStages)];
        let prevStage: ApplicationStage | undefined;
        let nextStage: ApplicationStage | undefined;

        // distinct permittedStageMoves values
        permittedStageMoves = Array.from(new Set(permittedStageMoves));

        if (tenantStages) {
          const fromStageIndex = tenantStages.indexOf(fromStage);

          if (fromStageIndex !== -1) {
            prevStage = tenantStages[fromStageIndex - 1];
            nextStage = tenantStages[fromStageIndex + 1];
          }
        }

        result[fromStage] = permittedStageMoves.filter(
          (x) =>
            // enforce moving only to the prev/next stages
            // therefore intersecting permittedStageMoves with [prevStage, nextStage]
            [prevStage, nextStage].includes(x) ||
            // if current stage is post funding, allow post funding
            (Object.keys(PostFundingApplicationStage).includes(fromStage) &&
              Object.keys(PostFundingApplicationStage).includes(x)) ||
            // if current stage is post funding, allow funded
            (Object.keys(PostFundingApplicationStage).includes(fromStage) &&
              x === ApplicationStage.FUNDED) ||
            // if current stage is funded, allow post funding
            (Object.keys(PostFundingApplicationStage).includes(x) &&
              fromStage === ApplicationStage.FUNDED),
        );
      });

      return result;
    }, {} as { [key: string]: ApplicationStage[] });

    // remove permission to move deals into the funded stage

    Object.keys(applicationStageMovePermissionsMap).forEach((fromStage) => {
      if (
        applicationStageMovePermissionsMap[fromStage].includes(ApplicationStage.FUNDED) &&
        !Object.keys(PostFundingApplicationStage).includes(fromStage)
      ) {
        applicationStageMovePermissionsMap[fromStage].splice(
          applicationStageMovePermissionsMap[fromStage].indexOf(ApplicationStage.FUNDED),
          1,
        );
      }
    });

    return applicationStageMovePermissionsMap;
  }

  loadGeneralPermissionsOwn() {
    return this.http.get<GeneralAbstractPermissions>(
      `${environment.fundmore_api_url}/permissions/abstractPermissions/own`,
    );
  }
}
