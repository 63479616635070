import {
  NgModule,
  ErrorHandler,
  SkipSelf,
  Optional,
  APP_INITIALIZER,
  DEFAULT_CURRENCY_CODE,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxsModule } from '@ngxs/store';
import { FmErrorHandlerService } from './fm-error-handler.service';
import { AuthModule } from '../auth/auth.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NotFoundComponent } from './not-found/not-found.component';
import { environment } from '../../environments/environment';
import { AppFeaturesState } from '../shared/app-features.state';
import { AuthState } from '../auth/auth.state';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './error-interceptor.service';
import { LoadingState } from './loading.state';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';
import { UserSettingsState } from '../shared/user-settings.state';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { PipelineState } from '../features/pipeline';
// eslint-disable-next-line max-len
import { DocumentButtonsState } from '../features/application/application-stage/generate-buttons-v2/document-buttons.state';
import { DashboardState } from '../features/application/dashboard.state';
import { ThemeState } from './store/theme.state';
import { CompanySettingsState } from './store/company-settings.state';
import { COMPANY_SETTINGS } from './store/company-default';
import { UserPreferencesState } from '../features/manager-portal/user-manage/user-preferences/user-preferences.state';
import { SectionOpenedState } from './section-open.state';
import { NotificationState } from '../portal/notification.state';
import {
  DatatableColumnsState,
  DatatableColumnsStateModel,
  DatatableName,
} from '../features/shared/edit-columns/datatable-columns.state';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxsModule.forRoot(
      [
        AppFeaturesState,
        AuthState,
        CompanySettingsState,
        LoadingState,
        SectionOpenedState,
        UserSettingsState,
        UserPreferencesState,
        ThemeState,
      ],
      {
        developmentMode: !environment.production,
        selectorOptions: {
          suppressErrors: false,
          injectContainerState: false,
        },
      },
    ),
    NgxsStoragePluginModule.forRoot({
      key: [
        PipelineState,
        DocumentButtonsState,
        DashboardState,
        ThemeState,
        CompanySettingsState,
        NotificationState,
        DatatableColumnsState,
      ],
      afterDeserialize: (obj, key) => {
        if (key === DashboardState.NAME) {
          if (obj?.version !== DashboardState.DEFAULT_STATE_VALUES.version) {
            return { ...DashboardState.DEFAULT_STATE_VALUES };
          }
        }
        if (key === CompanySettingsState.NAME) {
          if (obj?.version !== COMPANY_SETTINGS.version) {
            return { ...COMPANY_SETTINGS };
          }
        }

        if (key === DatatableColumnsState.NAME) {
          const displayColumns = (obj as DatatableColumnsStateModel).displayColumns;
          const overallVersion = (obj as DatatableColumnsStateModel).version;

          if (DatatableColumnsState.DEFAULT_STATE_VALUES.version !== overallVersion) {
            return DatatableColumnsState.DEFAULT_STATE_VALUES;
          }

          Object.keys(displayColumns).forEach((datatableName: DatatableName) => {
            const datatable =
              DatatableColumnsState.DEFAULT_STATE_VALUES.displayColumns[datatableName];

            if (displayColumns[datatableName].version !== datatable.version) {
              displayColumns[datatableName] = datatable;
            }
          });

          return { displayColumns, version: overallVersion };
        }

        if (key === NotificationState.NAME) {
          if (obj?.version !== NotificationState.DEFAULT_STATE_VALUES.version) {
            return { ...NotificationState.DEFAULT_STATE_VALUES };
          }
        }

        if (key === PipelineState.NAME) {
          if (obj?.version !== PipelineState.DEFAULT_STATE_VALUES.version) {
            return { ...PipelineState.DEFAULT_STATE_VALUES };
          }
        }

        return obj;
      },
      beforeSerialize: (obj, key) => {
        if (key === PipelineState.NAME && obj) {
          return { ...obj, applications: [], approvalApplications: [] };
        }
        return obj;
      },
    }),
    environment.plugins,
    AuthModule,
    MatSnackBarModule,
    NotFoundComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: FmErrorHandlerService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'CAD' },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
