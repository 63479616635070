import { Injectable } from '@angular/core';
import { InsuranceQuote, SagenApplicationData } from '@fundmoreai/models';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { finalize, tap } from 'rxjs';
import { ApplicationResetState } from '../../../../../../shared/state.model';
import { MortgageInsuranceService } from '../../mortgage-insurance.service';
import { SagenInsuranceService } from '../../sagen-insurance.service';
import { SagenFieldMetadataViewModel } from '../sagen-application.model';
import { LoadingStart, LoadingEnd } from '../../../../../../core/loading.state';

export class MortgageInsuranceSagenApplicationSetQuote {
  static readonly type = '@mortgageInsurance.SagenApplicationSetQuote';
  constructor(public insuranceQuote: InsuranceQuote) {}
}

export class MortgageInsuranceSagenApplicationReset {
  static readonly type = '@mortgageInsurance.SagenApplicationReset';
}

export class MortgageInsuranceSagenApplicationMappingsReview {
  static readonly type = '@mortgageInsurance.SagenApplicationMappingsReview';
  constructor(public applicationId: string) {}
}

interface SagenInsuranceRequestStateModel {
  responseQuote: InsuranceQuote | undefined;
  responseReviewMappings: SagenApplicationData | undefined;
}

@State<SagenInsuranceRequestStateModel>({
  name: 'SagenInsuranceRequest',
  defaults: {
    responseQuote: undefined,
    responseReviewMappings: undefined,
  },
})
@Injectable()
export class SagenInsuranceRequestState {
  constructor(private store: Store, private mortgageInsuranceService: MortgageInsuranceService) {}

  @Selector()
  static responseQuote(state: SagenInsuranceRequestStateModel): InsuranceQuote | undefined {
    return state.responseQuote;
  }

  @Selector()
  static responseReviewMappings(
    state: SagenInsuranceRequestStateModel,
  ): SagenApplicationData | undefined {
    return state.responseReviewMappings;
  }

  @Selector()
  static responseReviewFieldMappings(
    state: SagenInsuranceRequestStateModel,
  ): SagenFieldMetadataViewModel[] | undefined {
    return SagenInsuranceService.toMetadata(state.responseReviewMappings, true);
  }

  @Action(MortgageInsuranceSagenApplicationSetQuote)
  setQuote(
    ctx: StateContext<SagenInsuranceRequestStateModel>,
    action: MortgageInsuranceSagenApplicationSetQuote,
  ) {
    ctx.patchState({
      responseQuote: action.insuranceQuote,
    });
  }

  @Action(MortgageInsuranceSagenApplicationReset)
  resetQuote(ctx: StateContext<SagenInsuranceRequestStateModel>) {
    ctx.patchState({
      responseQuote: undefined,
      responseReviewMappings: undefined,
    });
  }

  @Action(ApplicationResetState)
  resetState(ctx: StateContext<SagenInsuranceRequestStateModel>) {
    ctx.setState({
      responseQuote: undefined,
      responseReviewMappings: undefined,
    });
  }

  @Action(MortgageInsuranceSagenApplicationMappingsReview)
  reviewApplicationMappings(
    ctx: StateContext<SagenInsuranceRequestStateModel>,
    action: MortgageInsuranceSagenApplicationMappingsReview,
  ) {
    this.store.dispatch(new LoadingStart(this.constructor.name));

    return this.mortgageInsuranceService.reviewSagenApplicationMappings(action.applicationId).pipe(
      tap((response) =>
        ctx.patchState({
          responseReviewMappings: response,
        }),
      ),
      finalize(() => this.store.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }
}
