export enum ProductMessageMatchingPrefix {
  MORTGAGE_IDENTIFICATION = 'REQUESTED_MORTGAGE_ID_',
}

const GUID_REGEX_STRING = '[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?';

export const GUID_REGEX = new RegExp(GUID_REGEX_STRING);

export const MORTGAGE_IDENTIFICATION_REGEX = new RegExp(
  `${ProductMessageMatchingPrefix.MORTGAGE_IDENTIFICATION}${GUID_REGEX_STRING}`,
  'g',
);
