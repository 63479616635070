import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  AdvancedProduct,
  ApplicationAdvancedProductMetadataPerMortgage,
  ApplicationAdvancedProductPaymentChangesPerMortgage,
  BrokerCommission,
  GUID_REGEX,
  MORTGAGE_IDENTIFICATION_REGEX,
  Mortgage,
  MortgageKey,
  PreviewMortgageProductsTempChanges,
  PreviewProductResponse,
  RateMatrix,
  RateMatrixTerm,
  SupportedCustomEntity,
} from '@fundmoreai/models';
import { Store } from '@ngxs/store';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MortgagesV2State } from '../mortgages-v2/mortgages-v2.state';
import { FieldMetadataState } from 'src/app/shared/custom-fields/field-metadata.state';

@Injectable({
  providedIn: 'root',
})
export class AdvancedProductService {
  constructor(private http: HttpClient, private store: Store) {}

  getAdvancedProducts(): Observable<AdvancedProduct[]> {
    return this.http.get<AdvancedProduct[]>(`${environment.fundmore_api_url}/rateMatrix/products`);
  }

  getAdvancedProductById(id: string): Observable<AdvancedProduct> {
    return this.http.get<AdvancedProduct>(
      `${environment.fundmore_api_url}/rateMatrix/products/${id}`,
    );
  }

  postAdvancedProduct(): Observable<AdvancedProduct> {
    return this.http.post<AdvancedProduct>(
      `${environment.fundmore_api_url}/rateMatrix/products`,
      null,
    );
  }

  patchAdvancedProduct(advancedProduct: Partial<AdvancedProduct>) {
    return this.http.patch<void>(
      `${environment.fundmore_api_url}/rateMatrix/products`,
      advancedProduct,
    );
  }

  deleteAdvancedProduct(id: string) {
    return this.http.delete<void>(`${environment.fundmore_api_url}/rateMatrix/products/${id}`);
  }

  createRateMatrix(rateMatrix: Partial<RateMatrix>) {
    return this.http.post<RateMatrix>(
      `${environment.fundmore_api_url}/rateMatrix/rateMatrix`,
      rateMatrix,
    );
  }

  patchRateMatrix(rateMatrix: Partial<RateMatrix>) {
    return this.http.patch<void>(
      `${environment.fundmore_api_url}/rateMatrix/rateMatrix`,
      rateMatrix,
    );
  }

  deleteRateMatrix(rateMatrixId: string) {
    return this.http.delete<void>(
      `${environment.fundmore_api_url}/rateMatrix/rateMatrix/${rateMatrixId}`,
    );
  }

  createRateMatrixTerm(rateMatrixTerm: Partial<RateMatrixTerm>) {
    return this.http.post<RateMatrixTerm>(
      `${environment.fundmore_api_url}/rateMatrix/rateMatrixTerm`,
      rateMatrixTerm,
    );
  }

  patchRateMatrixTerm(rateMatrixTerm: Partial<RateMatrixTerm>) {
    return this.http.patch<void>(
      `${environment.fundmore_api_url}/rateMatrix/rateMatrixTerm`,
      rateMatrixTerm,
    );
  }

  deleteRateMatrixTerm(id: string) {
    return this.http.delete<void>(
      `${environment.fundmore_api_url}/rateMatrix/rateMatrixTerm/${id}`,
    );
  }

  getMortgageAdvancedProductPreview(
    mortgagesAdvancedProducts: { [key: string]: AdvancedProduct },
    applicationId: string,
    previewMortgageProductsTempChanges: PreviewMortgageProductsTempChanges | null = null,
  ) {
    return this.http
      .post<PreviewProductResponse>(
        `${environment.fundmore_api_url}/rateMatrix/engine/previewMortgageProducts`,
        {
          applicationId,
          mortgagesAdvancedProducts,
          previewMortgageProductsTempChanges,
        },
      )
      .pipe(
        map((res) => {
          const processedRes = { ...res };
          const requestedMortgages = this.store.selectSnapshot(MortgagesV2State.requestedMortgages);

          processedRes.info = processedRes.info
            ? this.buildMessages(requestedMortgages, processedRes.info)
            : [];

          processedRes.warnings = processedRes.warnings
            ? this.buildMessages(requestedMortgages, processedRes.warnings)
            : [];

          processedRes.errors = processedRes.errors
            ? this.buildMessages(requestedMortgages, processedRes.errors)
            : [];

          return processedRes;
        }),
      );
  }

  clearMortgageAdvancedProduct(applicationId: string, mortgageId: string) {
    return this.http.post<void>(
      `${environment.fundmore_api_url}/rateMatrix/engine/clearMortgageProduct`,
      {
        applicationId,
        mortgageId,
      },
    );
  }

  applyMortgageAdvancedProducts(
    mortgagesAdvancedProducts: { [key: string]: AdvancedProduct },
    applicationId: string,
    diff: object,
    modules: object | null,
    metadataForMortgage: ApplicationAdvancedProductMetadataPerMortgage,
    paymentChangesPerMortgage: {
      [key: string]: ApplicationAdvancedProductPaymentChangesPerMortgage;
    },
  ) {
    return this.http.post<void>(
      `${environment.fundmore_api_url}/rateMatrix/engine/applyMortgageProducts`,
      {
        applicationId,
        mortgagesAdvancedProducts,
        diff,
        modules,
        metadataForMortgage,
        paymentChangesPerMortgage,
      },
    );
  }

  createCommission(commission: Partial<BrokerCommission>) {
    return this.http.post<BrokerCommission>(
      `${environment.fundmore_v2_api_url}/advanced-product/commission`,
      commission,
    );
  }

  patchCommission(commission: Partial<BrokerCommission>) {
    return this.http.patch<void>(
      `${environment.fundmore_v2_api_url}/advanced-product/commission`,
      commission,
    );
  }

  deleteCommission(commissionId: string) {
    return this.http.delete<void>(
      `${environment.fundmore_v2_api_url}/advanced-product/commission/${commissionId}`,
    );
  }

  private buildMessages(requestedMortgages: Mortgage[], messages: string[]) {
    return messages.map((message) => {
      const updatedMessage = message.replace(MORTGAGE_IDENTIFICATION_REGEX, (matchingText) => {
        // Find the corresponding mortgage by ID
        const matches = matchingText.match(GUID_REGEX);

        if (matches) {
          const extractedMortgageId = matches[0];
          const mortgage = requestedMortgages.find(
            (mortgage) => mortgage.id === extractedMortgageId,
          );

          if (mortgage) {
            return this.mortgageToIdentificationString(mortgage);
          }
        }

        // If mortgage is not found, keep the original ID
        return matchingText;
      });

      return updatedMessage;
    });
  }

  private mortgageToIdentificationString(mortgage: Mortgage) {
    let mortgageType = null;
    let loanType = null;
    const LOAN_TYPE = $localize`Loan Type`;
    const MORTGAGE_TYPE = $localize`Type`;
    const TERM = $localize`Term`;
    const table = SupportedCustomEntity.MORTGAGE;

    if (mortgage.mortgageType) {
      const mortgageTypeOptions = this.store.selectSnapshot(
        FieldMetadataState.getVisibleFieldOptions(table, MortgageKey.MORTGAGE_TYPE),
      );
      mortgageType = `${MORTGAGE_TYPE}: ${mortgageTypeOptions[mortgage.mortgageType]}`;
    }

    if (mortgage.loanType) {
      const loanTypeOptions = this.store.selectSnapshot(
        FieldMetadataState.getVisibleFieldOptions(table, MortgageKey.LOAN_TYPE),
      );
      loanType = `${LOAN_TYPE}: ${loanTypeOptions[mortgage.loanType]}`;
    }

    const term = mortgage.termMonths ? `${TERM}: ${mortgage.termMonths}` : null;

    return [mortgageType, loanType, term].filter((x) => x).join(', ');
  }
}
