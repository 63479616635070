/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-empty-function */
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import {
  CognitoUser,
  CognitoUserPool,
  CognitoUserSession,
  UserData,
} from 'amazon-cognito-identity-js';
import { Observable, ReplaySubject } from 'rxjs';
import { ChangeUserPasswordCommand, UpsertUserProfile } from '../features/settings/settings.models';
// import * as AWS from "aws-sdk/global";
// import * as awsservice from "aws-sdk/lib/service";
// import * as CognitoIdentity from "aws-sdk/clients/cognitoidentity";

/**
 * Created by Vladimir Budilov
 */

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  public static _REGION = environment.cognito.region;

  // public static _IDENTITY_POOL_ID = environment.identityPoolId;
  public static _USER_POOL_ID = environment.cognito.userPoolId;
  public static _CLIENT_ID = environment.cognito.clientId;

  public static _POOL_DATA: any = {
    UserPoolId: CognitoService._USER_POOL_ID,
    ClientId: CognitoService._CLIENT_ID,
  };

  // public cognitoCreds: AWS.CognitoIdentityCredentials;

  getUserPool() {
    if (environment.cognito.cognito_idp_endpoint) {
      CognitoService._POOL_DATA.endpoint = environment.cognito.cognito_idp_endpoint;
    }
    return new CognitoUserPool(CognitoService._POOL_DATA);
  }

  getCurrentUser() {
    return this.getUserPool().getCurrentUser();
  }

  getAccessToken(): Observable<any> {
    const result = new ReplaySubject<any>(1);

    if (this.getCurrentUser() != null) {
      this.getCurrentUser()?.getSession((err: any, session: any) => {
        if (err) {
          result.error(err);
        } else {
          if (session.isValid()) {
            result.next(session.getAccessToken().getJwtToken());
          } else {
            result.error(new Error('Invalid session'));
          }
        }
      });
    } else {
      result.error(new Error('No user context'));
    }

    return result.asObservable();
  }

  getIdToken(): Observable<any> {
    const result = new ReplaySubject<any>(1);
    const cognitoUser = this.getCurrentUser();

    if (cognitoUser != null) {
      cognitoUser.getSession((err: any, session: any) => {
        if (err) {
          result.error(err);
        } else {
          if (session.isValid()) {
            result.next(session.getIdToken().getJwtToken());
          } else {
            // sign out user on error to clear invalid data storage
            cognitoUser.signOut();
            result.error(new Error('Invalid session'));
          }
        }
      });
    } else {
      result.error(new Error('No user context'));
    }
    return result.asObservable();
  }

  getDecodedIdToken(): Observable<any> {
    const result = new ReplaySubject<any>(1);

    if (this.getCurrentUser() != null) {
      this.getCurrentUser()?.getSession((err: any, session: any) => {
        if (err) {
          result.error(err);
        } else {
          if (session.isValid()) {
            result.next(session.getIdToken().decodePayload());
          } else {
            result.error(new Error('Invalid session'));
          }
        }
      });
    } else {
      result.error(new Error('No user context'));
    }
    return result.asObservable();
  }

  getRefreshToken(): Observable<any> {
    const result = new ReplaySubject<any>(1);

    if (this.getCurrentUser() != null) {
      this.getCurrentUser()?.getSession((err: any, session: any) => {
        if (err) {
          result.error(err);
        } else {
          if (session.isValid()) {
            result.next(session.getRefreshToken().getToken());
          } else {
            result.error(new Error('Invalid session'));
          }
        }
      });
    } else {
      result.error(new Error('No user context'));
    }
    return result.asObservable();
  }

  updateUserAttributes(userProfile: UpsertUserProfile): Promise<any> {
    return new Promise((resolve, reject) => {
      const currentUser = this.getCurrentUser();
      if (currentUser != null) {
        currentUser.getSession((err: any) => {
          if (err) {
            reject(err);
          }
          currentUser.updateAttributes(
            [
              {
                Name: 'given_name',
                Value: userProfile.firstName || '',
              },
              {
                Name: 'family_name',
                Value: userProfile.lastName || '',
              },
              // {
              //   Name: 'phone_number',
              //   Value: userProfile.mobile,
              // },
            ],
            (err: any, data: any) => (err ? reject(err) : resolve(data)),
          );
        });
      }
    });
  }

  changePassword(command: ChangeUserPasswordCommand): Promise<any> {
    return new Promise((resolve, reject) => {
      const currentUser = this.getCurrentUser();
      if (currentUser != null) {
        currentUser.getSession((err: any) => {
          if (err) {
            reject(err);
          }
          currentUser.changePassword(
            command.oldPassword,
            command.newPassword,
            (err: any, data: any) => (err ? reject(err) : resolve(data)),
          );
        });
      }
    });
  }

  refreshSession() {
    const result = new ReplaySubject<any>(1);

    const currentUser = this.getCurrentUser();
    if (currentUser != null) {
      currentUser.getSession((err: any, session: CognitoUserSession) => {
        if (err) {
          result.error(err);
        } else {
          if (session.isValid()) {
            currentUser.refreshSession(session.getRefreshToken(), () => {});
          } else {
            result.error(new Error('Invalid session'));
          }
        }
      });
    } else {
      result.error(new Error('No user context'));
    }

    return result.asObservable();
  }

  getUserData(): Observable<UserData | null> {
    const result = new ReplaySubject<UserData | null>(1);
    const currentUser = this.getCurrentUser();
    if (currentUser === null) {
      result.error(new Error('No user context'));
      return result.asObservable();
    }

    currentUser.getSession((err: any, session: CognitoUserSession) => {
      this.cognitoUserData(err, result, session, currentUser);
    });

    return result.asObservable();
  }

  private cognitoUserData(
    err: any,
    result: ReplaySubject<UserData | null>,
    session: CognitoUserSession,
    currentUser: CognitoUser,
  ) {
    if (err) {
      result.error(err);
    } else {
      if (session.isValid()) {
        currentUser.getUserData(
          (err: any, userData: UserData) => {
            if (err) {
              result.error(err);
            } else {
              if (userData) {
                result.next(userData);
              } else {
                result.next(null);
              }
            }
          },
          { bypassCache: true },
        );
      } else {
        result.error(new Error('Invalid session'));
      }
    }
  }

  disableTotp(): Observable<any> {
    const result = new ReplaySubject<any>(1);

    const currentUser = this.getCurrentUser();
    if (currentUser != null) {
      currentUser.getSession((err: any, session: any) => {
        if (err) {
          result.error(err);
        } else {
          if (session.isValid()) {
            currentUser.setUserMfaPreference(
              null,
              {
                PreferredMfa: false,
                Enabled: false,
              },
              () => {},
            );
            result.next(true);
          } else {
            result.error(new Error('Invalid session'));
          }
        }
      });
    } else {
      result.error(new Error('No user context'));
    }

    return result.asObservable();
  }

  associateTotp(): Observable<any> {
    const result = new ReplaySubject<any>(1);

    const currentUser = this.getCurrentUser();
    if (currentUser != null) {
      currentUser.getSession((err: any, session: any) => {
        if (err) {
          result.error(err);
        } else {
          if (session.isValid()) {
            currentUser.associateSoftwareToken({
              associateSecretCode: (secretCode) => result.next(secretCode),
              onFailure: (err) => result.error(err),
            });
          } else {
            result.error(new Error('Invalid session'));
          }
        }
      });
    } else {
      result.error(new Error('No user context'));
    }

    return result.asObservable();
  }

  verifyTotp(totp: string, deviceName: string): Observable<any> {
    const result = new ReplaySubject<any>(1);

    const currentUser = this.getCurrentUser();
    if (currentUser != null) {
      currentUser.getSession((err: any, session: any) => {
        if (err) {
          result.error(err);
        } else {
          if (session.isValid()) {
            currentUser.verifySoftwareToken(totp, deviceName, {
              onSuccess: (session) => result.next(session),
              onFailure: (err) => result.error(err),
            });
          } else {
            result.error(new Error('Invalid session'));
          }
        }
      });
    } else {
      result.error(new Error('No user context'));
    }

    return result.asObservable();
  }

  preferTotp(): Observable<any> {
    const result = new ReplaySubject<any>(1);

    const currentUser = this.getCurrentUser();
    if (currentUser != null) {
      currentUser.getSession((err: any, session: any) => {
        if (err) {
          result.error(err);
        } else {
          if (session.isValid()) {
            currentUser.setUserMfaPreference(
              null,
              {
                PreferredMfa: true,
                Enabled: true,
              },
              () => {},
            );
            result.next(undefined);
          } else {
            result.error(new Error('Invalid session'));
          }
        }
      });
    } else {
      result.error(new Error('No user context'));
    }

    return result.asObservable();
  }

  preferSms(): Observable<any> {
    const result = new ReplaySubject<any>(1);

    const currentUser = this.getCurrentUser();
    if (currentUser != null) {
      currentUser.getSession((err: any, session: any) => {
        if (err) {
          result.error(err);
        } else {
          if (session.isValid()) {
            currentUser.setUserMfaPreference(
              {
                PreferredMfa: true,
                Enabled: true,
              },
              null,
              () => {},
            );
            result.next(undefined);
          } else {
            result.error(new Error('Invalid session'));
          }
        }
      });
    } else {
      result.error(new Error('No user context'));
    }

    return result.asObservable();
  }
}
