import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import { Fund } from '../shared';
import { FundsService } from './funds.service';

export class UpdateFund {
  static readonly type = '@funds.updateFund';
  constructor(public fund: Fund) {}
}

export class DeleteFund {
  static readonly type = '@funds.deleteFund';
  constructor(public id: string) {}
}

export class CreateFund {
  static readonly type = '@funds.createFund';
  constructor(public fund: Fund) {}
}

export class FetchFunds {
  static readonly type = '@funds.fetchFunds';
}

export class SetDefaultFund {
  static readonly type = '@funds.setDefault';
  constructor(public fundId: string) {}
}

@State<Fund[]>({
  name: 'funds',
  defaults: [],
})
@Injectable()
export class FundsState {
  constructor(private fundService: FundsService) {}

  @Selector()
  static funds(state: Fund[]) {
    return state ?? [];
  }

  @Action(UpdateFund)
  updateFund(ctx: StateContext<Fund[]>, action: UpdateFund) {
    const state = ctx.getState();
    const prevId = action.fund.id;
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.fundService.patchFund(action.fund.id, { ...action.fund, id: undefined }).pipe(
      tap(() => ctx.setState([...state.filter((f) => f.id !== prevId), action.fund])),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(DeleteFund)
  deleteFund(ctx: StateContext<Fund[]>, action: DeleteFund) {
    const state = ctx.getState();
    const prevId = action.id;
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.fundService.deleteFund(prevId).pipe(
      tap(() => ctx.setState([...state.filter((f) => f.id !== prevId)])),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreateFund)
  createFund(ctx: StateContext<Fund[]>, action: CreateFund) {
    const state = ctx.getState();
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.fundService.addFund({ ...action.fund, id: undefined }).pipe(
      tap((fund) => ctx.setState([...state, fund])),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(SetDefaultFund)
  setDefault(ctx: StateContext<Fund[]>, action: SetDefaultFund) {
    const state = ctx.getState();
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.fundService.setDefaultFund(action.fundId).pipe(
      tap(() =>
        ctx.setState(
          state.map<Fund>((x) => {
            const fund: Fund = {
              id: x.id,
              shortName: x.shortName,
              longName: x.longName,
              tenantId: '',
              isDefault: false,
            };
            if (fund.id === action.fundId) {
              fund.isDefault = true;
              return fund;
            }
            return fund;
          }),
        ),
      ),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(FetchFunds)
  getFundsObs(ctx: StateContext<Fund[]>) {
    ctx.setState([]);

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.fundService.getFunds().pipe(
      tap((funds) => {
        ctx.setState(
          funds.sort((a, b) => {
            const fundOne = a.longName ?? '';
            const fundTwo = b.longName ?? '';
            return fundTwo.localeCompare(fundOne);
          }),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }
}
