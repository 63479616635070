export interface AdvancedProductModulesActivation {
  flipModule: boolean;
  constructionModule: boolean;
}

export interface AdvancedProductParameters {
  modules: AdvancedProductModulesActivation;
  isEdge?: boolean;
  isCombo?: boolean;
  isInsured?: boolean;
  isLOC?: boolean;
  rateHoldDays?: number;
}
