import { ApplicationComputedData } from '../shared/model';

export class PersistApplicationComputedData {
  static readonly type = '@applicationComputedData.PersistApplicationComputedData';
  constructor(public applicationId: string) {}
}

export class PersistNetWorthData {
  static readonly type = '@applicationComputedData.PersistNetWorthData';
  constructor(public applicationId: string) {}
}

export class SetApplicationComputedData {
  static readonly type = '@applicationComputedData.SetApplicationComputedData';
  constructor(public data: ApplicationComputedData) {}
}
