import { Property } from '@fundmoreai/models';

export type ComputeExpensesPropertyInput = Pick<
  Partial<Property>,
  | 'annualTaxes'
  | 'condoFees'
  | 'condoFeesIncludeHeating'
  | 'condoFeesPercentInCalculation'
  | 'heatingCost'
  | 'rpeGeneralExpenses'
  | 'rpeHydro'
  | 'rpeInsurance'
  | 'rpeInterestCharges'
  | 'rpeManagementExpenses'
  | 'rpeRepairs'
  | 'includeAnnualTaxesInTDS'
  | 'includeCondoFeesInTDS'
  | 'includeHeatingCostInTDS'
  | 'includeRpeGeneralExpensesInTDS'
  | 'includeRpeHydroInTDS'
  | 'includeRpeInsuranceInTDS'
  | 'includeRpeInterestChargesInTDS'
  | 'includeRpeManagementExpensesInTDS'
  | 'includeRpeRepairsInTDS'
>;

//includes all expenses based on flags
export function computeAnnualExpensesForTDS(
  property: ComputeExpensesPropertyInput | undefined,
): number {
  if (property) {
    const annualTaxes =
      property.includeAnnualTaxesInTDS && property.annualTaxes ? property.annualTaxes : 0;
    const condoFees = property.includeCondoFeesInTDS && property.condoFees ? property.condoFees : 0;
    const condoFeesPercentInCalculation = property.condoFeesPercentInCalculation ?? 0;
    const condoFeesIncludeHeating = property.condoFeesIncludeHeating ?? false;
    const heatingCost =
      property.includeHeatingCostInTDS && property.heatingCost ? property.heatingCost : 0;
    const rpeGeneralExpenses =
      property.includeRpeGeneralExpensesInTDS && property.rpeGeneralExpenses
        ? property.rpeGeneralExpenses
        : 0;
    const rpeHydro = property.includeRpeHydroInTDS && property.rpeHydro ? property.rpeHydro : 0;
    const rpeInsurance =
      property.includeRpeInsuranceInTDS && property.rpeInsurance ? property.rpeInsurance : 0;
    const rpeInterestCharges =
      property.includeRpeInterestChargesInTDS && property.rpeInterestCharges
        ? property.rpeInterestCharges
        : 0;
    const rpeManagementExpenses =
      property.includeRpeManagementExpensesInTDS && property.rpeManagementExpenses
        ? property.rpeManagementExpenses
        : 0;
    const rpeRepairs =
      property.includeRpeRepairsInTDS && property.rpeRepairs ? property.rpeRepairs : 0;

    const annualExpenses =
      annualTaxes +
      condoFees * (condoFeesPercentInCalculation / 100) +
      (condoFeesIncludeHeating ? 0 : heatingCost) +
      rpeGeneralExpenses * 12 +
      rpeHydro * 12 +
      rpeInsurance * 12 +
      rpeInterestCharges * 12 +
      rpeManagementExpenses * 12 +
      rpeRepairs * 12;

    return Math.round(annualExpenses * 100) / 100;
  }

  return 0;
}

//includes only taxes, condo fees and heating cost, not based on the flags
export function computeAnnualExpensesForGDS(
  property: ComputeExpensesPropertyInput | undefined,
): number {
  if (property) {
    const annualTaxes = property.annualTaxes ?? 0;
    const condoFees = property.condoFees ?? 0;
    const condoFeesPercentInCalculation = property.condoFeesPercentInCalculation ?? 0;
    const condoFeesIncludeHeating = property.condoFeesIncludeHeating ?? false;
    const heatingCost = property.heatingCost ?? 0;
    const annualExpenses =
      annualTaxes +
      condoFees * (condoFeesPercentInCalculation / 100) +
      (condoFeesIncludeHeating ? 0 : heatingCost);
    return Math.round(annualExpenses * 100) / 100;
  }

  return 0;
}

export function computeAllAnnualExpenses(
  property: ComputeExpensesPropertyInput | undefined,
): number {
  if (property) {
    const annualAllExpenses =
      computeAnnualExpensesForGDS(property) + computeAnnualRentalPropertyExpenses(property);
    return Math.round(annualAllExpenses * 100) / 100;
  }
  return 0;
}

//includes all rental expenses, not based on flags
export function computeAnnualRentalPropertyExpenses(
  property: ComputeExpensesPropertyInput | undefined,
): number {
  if (property) {
    const annualAllExpenses =
      (property.rpeGeneralExpenses ?? 0) * 12 +
      (property.rpeHydro ?? 0) * 12 +
      (property.rpeInsurance ?? 0) * 12 +
      (property.rpeInterestCharges ?? 0) * 12 +
      (property.rpeManagementExpenses ?? 0) * 12 +
      (property.rpeRepairs ?? 0) * 12;
    return Math.round(annualAllExpenses * 100) / 100;
  }
  return 0;
}
