import { Applicant } from './applicant';
import { DocumentType } from './documentType';

export interface DocumentRequest {
  id: string;
  applicationId: string;
  stakeholderToNotifyId?: string;
  stakeholderToNotifyEmail?: string;
  requestNotes?: string;
  createdAt: Date;
  updatedAt?: Date | null;
  stakeholderToNotify?: Pick<Applicant, 'id' | 'name' | 'surname' | 'email'>;
  documentRequestLinkedDocumentTypes?: DocumentRequestLinkedDocumentType[];
  metadata?: { templateId?: string; envelopeId?: string };
  signatureStatus?: DocumentSignatureRequestStatus;
  signatureURL?: string;
}

export interface DocumentRequestLinkedDocumentType {
  documentRequestId?: string;
  documentTypeId: string;
  stakeholderId: string;
  status?: DocumentRequestDocumentTypeStatus;
  overwrittenDescription?: string;
  stakeholder?: Pick<Applicant, 'id' | 'name' | 'surname' | 'email'>;
  createdAt?: Date;
  updatedAt?: Date | null;
  DocumentType?: DocumentType;
  DocumentRequest?: DocumentRequest;
}

export enum DocumentRequestDocumentTypeStatus {
  PENDING = 'PENDING',
  RECEIVED = 'RECEIVED',
}

export enum DocumentRequestKey {
  ID = 'id',
  APPLICATION_ID = 'applicationId',
  STAKEHOLDER_TO_NOTIFY_ID = 'stakeholderToNotifyId',
  STAKEHOLDER_TO_NOTIFY_EMAIL = 'stakeholderToNotifyEmail',
  REQUEST_NOTES = 'requestNotes',
  CREATED_AT = 'createdAt',
  UPDATED_AT = 'updatedAt',
  STAKEHOLDER_TO_NOTIFY = 'stakeholderToNotify',
  DOCUMENT_REQUEST_LINKED_DOCUMENT_TYPES = 'documentRequestLinkedDocumentTypes',
  METADATA = 'metadata',
}

export enum DocumentSignatureRequestStatus {
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
}
