const deepValue = (obj, path) => {
  return path
    .replace(/\[|\]\.?/g, '.')
    .split('.')
    .filter((s) => s)
    .reduce((acc, val) => acc && acc[val], obj);
};
export function parseStringTemplate(expression, valueObj) {
  const templateMatcher = /\${\s?([^{}\s]*)\s?}/g;
  const text = expression.replace(templateMatcher, (_substring, value, _) => {
    return deepValue(valueObj, value);
  });
  return text;
}
