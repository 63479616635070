import { Component, ChangeDetectionStrategy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { NgIf } from '@angular/common';

@Component({
  selector: 'fm-error-details-dialog',
  templateUrl: './error-details-dialog.component.html',
  styleUrls: ['./error-details-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [MatDialogModule, MatIconModule, MatButtonModule, NgIf],
})
export class ErrorDetailsDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { message: string; isError: boolean },
    private dialogRef: MatDialogRef<ErrorDetailsDialogComponent>,
  ) {}

  onDismiss() {
    this.dialogRef.close(false);
  }
}
