import {
  ApplicationPurposeType,
  ApplicationStage,
  MortgageState,
  MortgageStatus,
  PriorityType,
} from '@fundmoreai/models';
import { NoMortgageStatusKey } from 'src/app/shared/enum-records';

export enum PipelineSortFilter {
  Default = 1,
  ApplicantName = 2,
  UpdatedDate = 3,
  CreationDate = 4,
  MortgageBroker = 5,
  ApplicationStage = 6,
  LoanNumber = 7,
  ClosingDate = 8,
  DeadlineToAccept = 9,
  ExternalApplicationId = 10,
}

export enum PipelineUserFilter {
  Assigned = 'My Requests',
  Overdue = 'Overdue',
  Approvals = 'Approvals',
}

export const PipelineUserFilterRecord: Record<PipelineUserFilter, string> = {
  [PipelineUserFilter.Assigned]: $localize`My Requests`,
  [PipelineUserFilter.Overdue]: $localize`Overdue`,
  [PipelineUserFilter.Approvals]: $localize`Approvals`,
};

export enum PipelineView {
  List = 'List',
  Board = 'Board',
}

export interface PipelineComposedFilter {
  stageFilter?: ApplicationStage[] | null;
  priorityFilter?: PriorityType[] | null;
  purposeFilter?: ApplicationPurposeType[] | null;
  productFilter?: string[] | null;
  closingDateFilter?: DateRangeFilter | null;
  deadlineDateFilter?: DateRangeFilter | null;
  cofDateFilter?: DateRangeFilter | null;
  creationDateFilter?: DateRangeFilter | null;
  lastModifiedDateFilter?: DateRangeFilter | null;
  mortgageStateStatusFilter?: (NoMortgageStatusKey | MortgageStatus | MortgageState)[] | null;
}

export interface DateRangeFilter {
  startDate?: string | null;
  endDate?: string | null;
}
