import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  throwError,
  tap,
  catchError,
  from,
  finalize,
  switchMap,
  EMPTY,
  take,
  combineLatest,
  of,
} from 'rxjs';
import { UserAccount, ResetState } from '../shared';
import { AuthService } from './auth.service';
import { AuthScopeContextService } from './auth-scope-context.service';
import { CognitoService } from './cognito.service';
import { ProfileImageService } from '../shared/services/profile-image.service';
import { FetchAndSetTenantSettings } from '../shared/app-features.state';
import { FetchAndSetUserSettings } from '../shared/user-settings.state';
import {
  ChangePassword,
  GetCurrentUserParameters,
  SetCurrentUserAccount,
  UpdateCurrentUserAccountProfilePicture,
  UpsertCurrentUserAccount,
  AuthStateModel,
} from './auth.actions';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import * as Sentry from '@sentry/angular-ivy';
import { ProfileDataService } from './profile-data.service';
import { FetchUserAccounts } from '../portal/user-accounts.actions';
import { UserManageService } from '../features/manager-portal/user-manage/user-manage.service';
import { PendoService } from '../shared/services/pendo.service';

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    currentUserAccount: {} as UserAccount,
    loginStatus: undefined,
    loading: false,
  },
})
@Injectable()
export class AuthState {
  constructor(
    private authService: AuthService,
    private authScopeContextService: AuthScopeContextService,
    private cognitoService: CognitoService,
    private profileImageService: ProfileImageService,
    private profileDataService: ProfileDataService,
    private userAccountService: UserManageService,
    private pendoService: PendoService,
  ) {}

  @Selector()
  static currentUser(state: AuthStateModel): UserAccount {
    return state.currentUserAccount;
  }

  @Selector()
  static loading(state: AuthStateModel): boolean {
    return state.loading;
  }

  @Selector() static loginStatus(state: AuthStateModel) {
    return state.loginStatus;
  }

  @Selector() static tenantCode(state: AuthStateModel) {
    if (!state.currentUserAccount || !state.currentUserAccount.tenant) {
      return null;
    }
    return state.currentUserAccount.tenant.code;
  }

  @Selector() static tenantId(state: AuthStateModel) {
    if (!state.currentUserAccount || !state.currentUserAccount.tenant) {
      return null;
    }
    return state.currentUserAccount.tenant.id;
  }

  @Action(ResetState)
  resetState(ctx: StateContext<AuthStateModel>) {
    ctx.setState({ currentUserAccount: {} as UserAccount, loading: false, loginStatus: undefined });
  }

  @Action(SetCurrentUserAccount)
  setCurrentUserAccount(
    ctx: StateContext<AuthStateModel>,
    { currentUserAccount }: SetCurrentUserAccount,
  ) {
    ctx.setState({
      loading: false,
      currentUserAccount,
    });

    Sentry.setContext('user', {
      id: currentUserAccount?.user?.id,
    });

    setTimeout(() => {
      this.pendoService.initialize(currentUserAccount);
    }, this.pendoService.INITIAL_DELAY_MS);

    return ctx.dispatch([
      new FetchAndSetTenantSettings(currentUserAccount),
      new FetchAndSetUserSettings(),
    ]);
  }

  @Action(UpsertCurrentUserAccount)
  upsertCurrentUserAccount(
    ctx: StateContext<AuthStateModel>,
    { profile }: UpsertCurrentUserAccount,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return from(this.cognitoService.updateUserAttributes(profile)).pipe(
      switchMap(() => {
        return this.profileDataService.accountOwnDataUpdate(profile);
      }),
      switchMap(() => {
        return ctx.dispatch(new FetchUserAccounts());
      }),
      tap(() => {
        const state = ctx.getState();
        ctx.patchState({
          currentUserAccount: {
            ...state.currentUserAccount,
            userData: {
              ...state.currentUserAccount.userData,
              firstName: profile.firstName,
              lastName: profile.lastName,
            },
            user: {
              ...state.currentUserAccount.user,
              firstName: profile.firstName,
              lastName: profile.lastName,
            },
          },
        });
      }),
      catchError((error) => {
        console.error('upsertCurrentUserAccount', error);
        return ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateCurrentUserAccountProfilePicture)
  updateCurrentUserAccountProfilePicture(ctx: StateContext<AuthStateModel>) {
    const state = ctx.getState();
    ctx.patchState({
      currentUserAccount: {
        ...state.currentUserAccount,
        userData: {
          ...state.currentUserAccount.userData,
          profileImageUrl:
            this.profileImageService.getImageUrlFromKey(
              state.currentUserAccount.user.profileImageKey,
            ) + `?ts=${new Date().getTime()}`,
        },
      },
    });
  }

  @Action(ChangePassword)
  changePassword(ctx: StateContext<AuthStateModel>, { command }: ChangePassword) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return from(this.cognitoService.changePassword(command)).pipe(
      tap(() => {
        ctx.patchState({
          loginStatus: {
            success: true,
            message: $localize`Password changed successfully`,
          },
        });
      }),
      catchError((error) => {
        console.error('changePassword', error);
        ctx.patchState({ loginStatus: { success: false, message: error.message } });
        // eat error; already displaying in form
        return of(undefined);
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(GetCurrentUserParameters)
  getCurrentUserParameters(ctx: StateContext<AuthStateModel>) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return combineLatest([
      this.authService.getUserParameters(),
      this.userAccountService.getOwnUserAccountDetails(),
    ]).pipe(
      take(1),
      switchMap(([attributeList, ownUserDetails]) => {
        if (!attributeList) {
          return EMPTY;
        }
        const scopeContext = this.authScopeContextService.currentScopeContext;
        const firstName = attributeList
          .find((attribute) => attribute.getName() === 'given_name')
          ?.getValue();
        const lastName = attributeList
          .find((attribute) => attribute.getName() === 'family_name')
          ?.getValue();
        const email = attributeList
          .find((attribute) => attribute.getName() === 'email')
          ?.getValue();
        const phoneNumber = attributeList
          .find((attribute) => attribute.getName() === 'phone_number')
          ?.getValue();
        const userId = scopeContext?.userId;
        const ezidoxCollectorId = scopeContext?.collectorId;
        const profileImageUrl = this.profileImageService.getImageUrlFromKey(
          ownUserDetails.user.profileImageKey,
        );
        const currentUserAccount = {
          user: {
            roles: scopeContext?.roles?.map((x: string) => ({
              name: x,
            })),
            id: userId,
            ezidoxCollectorId,
            firstName,
            lastName,
            email,
            phoneNumber,
            displayName: `${firstName} ${lastName}`,
          },
          userData: {
            firstName,
            lastName,
            email,
            phoneNumber,
            profileImageUrl,
          },
          tenant: {
            id: scopeContext?.tenantId,
            name: scopeContext?.tenantName,
            code: scopeContext?.tenantCode,
          },
        } as UserAccount;

        return ctx.dispatch(
          new SetCurrentUserAccount({
            ...currentUserAccount,
            user: { ...currentUserAccount.user, ...ownUserDetails.user },
          }),
        );
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      catchError((error) => {
        console.error('getCurrentUserParameters', error);
        ctx.dispatch(new LoadingEnd(this.constructor.name));
        return throwError(() => error);
      }),
    );
  }
}
