import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Job } from '../shared';

@Injectable({
  providedIn: 'root',
})
export class ApplicantJobsService {
  constructor(private http: HttpClient) {}

  getJobs(applicationId: string, applicantId: string): Observable<Job[]> {
    return this.http
      .get<Job[]>(
        `${environment.api_url}/applications/${applicationId}/applicants/${applicantId}/jobs`,
      )
      .pipe(catchError(this.formatErrors));
  }

  postJob(applicationId: string, applicantId: string, jobs: Partial<Job>[]): Observable<Job[]> {
    return this.http
      .post<Job[]>(
        `${environment.api_url}/applications/${applicationId}/applicants/${applicantId}/jobs`,
        jobs,
      )
      .pipe(catchError(this.formatErrors));
  }

  patchJob(applicationId: string, applicantId: string, jobId: string, job: Job): Observable<void> {
    return this.http
      .patch<void>(
        `${environment.api_url}/applications/${applicationId}/applicants/${applicantId}/jobs/${jobId}`,
        job,
      )
      .pipe(catchError(this.formatErrors));
  }

  deleteJob(applicationId: string, applicantId: string, jobId: string): Observable<void> {
    return this.http
      .delete<void>(
        `${environment.api_url}/applications/${applicationId}/applicants/${applicantId}/jobs/${jobId}`,
      )
      .pipe(catchError(this.formatErrors));
  }

  private formatErrors(error: any) {
    return throwError(error.error);
  }
}
