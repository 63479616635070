import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable, switchMap } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  AddDocumentTemplateContentModel,
  AddDocumentTemplateModel,
  DocumentTemplate,
  DocumentTemplateContent,
  UpdateDocumentTemplateContentModel,
  UpdateDocumentTemplateModel,
} from '../../../manager-portal/documents-management/model';

@Injectable({
  providedIn: 'root',
})
export class DocumentTemplatesService {
  private base = `${environment.fundmore_api_url}/documentTemplates`;

  constructor(private http: HttpClient) {}

  add(documentTemplate: AddDocumentTemplateModel) {
    return this.http
      .post<{
        documentTemplate: DocumentTemplate;
        documentTemplateContent: DocumentTemplateContent[];
      }>(this.base, documentTemplate)
      .pipe(
        map((result: any) => ({
          documentTemplate: result,
          documentTemplateContent: [result.documentTemplateContent],
        })),
      );
  }

  addContent(content: AddDocumentTemplateContentModel) {
    return this.http.post<DocumentTemplateContent>(`${this.base}/contents`, content);
  }

  delete(documentTemplateId: string): Observable<void> {
    if (!documentTemplateId) {
      throw new Error('Document Template id is required');
    }

    return this.http.delete<void>(`${this.base}/${documentTemplateId}`);
  }

  get(documentTemplateId: string): Observable<DocumentTemplate> {
    if (!documentTemplateId) {
      throw new Error('Document Template id is required');
    }
    let headers = new HttpHeaders();
    headers = headers.set('x-compression', 'true');

    return this.http.get<DocumentTemplate>(`${this.base}/${documentTemplateId}`, { headers });
  }

  getAll(): Observable<DocumentTemplate[]> {
    let headers = new HttpHeaders();
    headers = headers.set('x-compression', 'true');
    return this.http.get<DocumentTemplate[]>(this.base, { headers });
  }

  getAllContent(documentTemplateId: string): Observable<DocumentTemplateContent[]> {
    if (!documentTemplateId) {
      throw new Error('Document Template id is required');
    }

    let headers = new HttpHeaders();
    headers = headers.set('x-compression', 'true');

    return this.http
      .get<{ documentTemplateContents: DocumentTemplateContent[] }>(
        `${this.base}/${documentTemplateId}`,
        { headers },
      )
      .pipe(map((documentTemplate) => documentTemplate.documentTemplateContents));
  }

  update(documentTemplateId: string, documentTemplate: UpdateDocumentTemplateModel) {
    return this.http.patch<DocumentTemplate>(
      `${this.base}/${documentTemplateId}`,
      documentTemplate,
    );
  }

  updateContent(contentId: string, content: UpdateDocumentTemplateContentModel) {
    return this.http
      .patch<DocumentTemplateContent>(`${this.base}/contents/${contentId}`, content)
      .pipe(
        switchMap(() =>
          this.http.get<DocumentTemplateContent>(`${this.base}/contents/${contentId}`),
        ),
      );
  }
}
