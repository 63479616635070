import { FeePayer, FeeType } from './enums';

export interface Fee {
  id: string;
  type: FeeType;
  paidBy: FeePayer;
  percent: number;
  amount: number;
  subtractFromPrincipal: boolean | null;
  includeInApr: boolean;
  capFeesPercentage: number | null;
  createdAt: string;
  manuallyUpdated: boolean;
  description: string | null;
}

export enum FeeKey {
  ID = 'id',
  TYPE = 'type',
  PAID_BY = 'paidBy',
  PERCENT = 'percent',
  AMOUNT = 'amount',
  SUBTRACT_FROM_PRINCIPAL = 'subtractFromPrincipal',
  INCLUDE_IN_APR = 'includeInApr',
  CAP_FEES_PERCENTAGE = 'capFeesPercentage',
  DESCRIPTION = 'description',
}
