import {
  FlipCostType,
  FlipPaymentType,
  FlipAmountType,
  FlipDefaultCostItems,
  FlipDefaultsCostItemType,
} from '@fundmoreai/models';
import {
  computeAlbertaSellingRealEstateAgentFee,
  computeOntarioPurchaseLandTransferTax,
  computeOntarioSellingRealEstateAgentFee,
} from './flip-general.calculator';

export interface RenovationSchedule {
  item: string;
  numberOfMonths: number;
}

export interface FlipModuleDefault {
  flipCosts: FlipCost[];
  renovationSchedules: RenovationSchedule[];
}

interface FlipCostConstantAmount {
  type: FlipCostType;
  paymentType: FlipPaymentType;
  item: string;
  itemType: FlipDefaultsCostItemType;
  amountType: FlipAmountType.CONSTANT;
  amount: number;
}

interface FlipCostComputedAmount {
  type: FlipCostType;
  paymentType: FlipPaymentType;
  item: string;
  itemType: FlipDefaultsCostItemType;
  amountType: FlipAmountType.COMPUTED;
  getAmount: (options: any) => number;
}

type FlipCost = FlipCostComputedAmount | FlipCostConstantAmount;

export const flipModuleDefaults: { [key: string]: FlipModuleDefault } = {
  default: {
    flipCosts: [
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.LEGAL_COST,
        itemType: FlipDefaultsCostItemType.LEGAL_COST,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.REAL_ESTATE_AGENT_FEE,
        itemType: FlipDefaultsCostItemType.REAL_ESTATE_AGENT_FEE,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.TITLE_INSURANCE,
        itemType: FlipDefaultsCostItemType.TITLE_INSURANCE,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.TITLE_SEARCH,
        itemType: FlipDefaultsCostItemType.TITLE_SEARCH,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.APPRAISAL_FEE,
        itemType: FlipDefaultsCostItemType.APPRAISAL_FEE,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.INSPECTION_FEE,
        itemType: FlipDefaultsCostItemType.INSPECTION_FEE,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.LAND_TRANSFER_TAX,
        itemType: FlipDefaultsCostItemType.LAND_TRANSFER_TAX,
        amountType: FlipAmountType.CONSTANT,
        amount: 0,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.PROPERTY_TAX_ADJUSTMENT,
        itemType: FlipDefaultsCostItemType.PROPERTY_TAX_ADJUSTMENT,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.MUNICIPAL_LAND_TRANSFER_TAX,
        itemType: FlipDefaultsCostItemType.MUNICIPAL_LAND_TRANSFER_TAX,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },

      // selling costs
      {
        type: FlipCostType.SELLING_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.REAL_ESTATE_AGENT_FEE,
        itemType: FlipDefaultsCostItemType.REAL_ESTATE_AGENT_FEE,
        amountType: FlipAmountType.CONSTANT,
        amount: 0,
      },
      {
        type: FlipCostType.SELLING_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.LEGAL_COST,
        itemType: FlipDefaultsCostItemType.LEGAL_COST,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.SELLING_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.TITLE_INSURANCE_RPR,
        itemType: FlipDefaultsCostItemType.TITLE_INSURANCE_RPR,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.SELLING_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.APPRAISAL_FEE_SECOND,
        itemType: FlipDefaultsCostItemType.APPRAISAL_FEE_SECOND,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.SELLING_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.LAND_TRANSFER_TAX,
        itemType: FlipDefaultsCostItemType.LAND_TRANSFER_TAX,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },

      // operating costs
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.ELECTRICAL,
        itemType: FlipDefaultsCostItemType.ELECTRICAL,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.GAS,
        itemType: FlipDefaultsCostItemType.GAS,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.WATER_SEWER,
        itemType: FlipDefaultsCostItemType.WATER_SEWER,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.ALARM_SYSTEM,
        itemType: FlipDefaultsCostItemType.ALARM_SYSTEM,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.LAWN_CARE_SNOW_REMOVAL,
        itemType: FlipDefaultsCostItemType.LAWN_CARE_SNOW_REMOVAL,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.INSURANCE,
        itemType: FlipDefaultsCostItemType.INSURANCE,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.PROPERTY_TAXES,
        itemType: FlipDefaultsCostItemType.PROPERTY_TAXES,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.CONDO_FEES,
        itemType: FlipDefaultsCostItemType.CONDO_FEES,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
    ],
    renovationSchedules: [
      { item: 'Number of Months to Renovate', numberOfMonths: 2 },
      { item: 'Number of Months to Sell', numberOfMonths: 1 },
    ],
  },
  Ontario: {
    flipCosts: [
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.LEGAL_COST,
        itemType: FlipDefaultsCostItemType.LEGAL_COST,
        amount: 1000,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.REAL_ESTATE_AGENT_FEE,
        itemType: FlipDefaultsCostItemType.REAL_ESTATE_AGENT_FEE,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.TITLE_INSURANCE,
        itemType: FlipDefaultsCostItemType.TITLE_INSURANCE,
        amount: 185,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.TITLE_SEARCH,
        itemType: FlipDefaultsCostItemType.TITLE_SEARCH,
        amount: 10,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.APPRAISAL_FEE,
        itemType: FlipDefaultsCostItemType.APPRAISAL_FEE,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.INSPECTION_FEE,
        itemType: FlipDefaultsCostItemType.INSPECTION_FEE,
        amount: 425,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.LAND_TRANSFER_TAX,
        itemType: FlipDefaultsCostItemType.LAND_TRANSFER_TAX_ONTARIO,
        amountType: FlipAmountType.COMPUTED,
        getAmount: (options) => {
          if (!options || !options.purchasePrice) {
            return 0;
          }
          const { purchasePrice } = options;
          return computeOntarioPurchaseLandTransferTax(purchasePrice);
        },
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.PROPERTY_TAX_ADJUSTMENT,
        itemType: FlipDefaultsCostItemType.PROPERTY_TAX_ADJUSTMENT,
        amount: 600,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.MUNICIPAL_LAND_TRANSFER_TAX,
        itemType: FlipDefaultsCostItemType.MUNICIPAL_LAND_TRANSFER_TAX,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },

      // selling costs
      {
        type: FlipCostType.SELLING_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.REAL_ESTATE_AGENT_FEE,
        itemType: FlipDefaultsCostItemType.REAL_ESTATE_AGENT_FEE_ONTARIO,
        amountType: FlipAmountType.COMPUTED,
        getAmount: (options) => {
          if (!options || !options.afterRepairValue) {
            return 0;
          }
          return computeOntarioSellingRealEstateAgentFee(
            options.afterRepairValue
          );
        },
      },
      {
        type: FlipCostType.SELLING_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.LEGAL_COST,
        itemType: FlipDefaultsCostItemType.LEGAL_COST,
        amount: 1000,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.SELLING_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.TITLE_INSURANCE_RPR,
        itemType: FlipDefaultsCostItemType.TITLE_INSURANCE_RPR,
        amount: 300,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.SELLING_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.APPRAISAL_FEE_SECOND,
        itemType: FlipDefaultsCostItemType.APPRAISAL_FEE_SECOND,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.SELLING_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.LAND_TRANSFER_TAX,
        itemType: FlipDefaultsCostItemType.LAND_TRANSFER_TAX,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },

      // operating costs
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.ELECTRICAL,
        itemType: FlipDefaultsCostItemType.ELECTRICAL,
        amount: 100,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.GAS,
        itemType: FlipDefaultsCostItemType.GAS,
        amount: 100,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.WATER_SEWER,
        itemType: FlipDefaultsCostItemType.WATER_SEWER,
        amount: 45,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.ALARM_SYSTEM,
        itemType: FlipDefaultsCostItemType.ALARM_SYSTEM,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.LAWN_CARE_SNOW_REMOVAL,
        itemType: FlipDefaultsCostItemType.LAWN_CARE_SNOW_REMOVAL,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.INSURANCE,
        itemType: FlipDefaultsCostItemType.INSURANCE,
        amount: 100,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.PROPERTY_TAXES,
        itemType: FlipDefaultsCostItemType.PROPERTY_TAXES,
        amount: 150,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.CONDO_FEES,
        itemType: FlipDefaultsCostItemType.CONDO_FEES,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
    ],
    renovationSchedules: [
      { item: 'Number of Months to Renovate', numberOfMonths: 2 },
      { item: 'Number of Months to Sell', numberOfMonths: 1 },
    ],
  },
  Alberta: {
    flipCosts: [
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.LEGAL_COST,
        itemType: FlipDefaultsCostItemType.LEGAL_COST,
        amount: 1000,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.REAL_ESTATE_AGENT_FEE,
        itemType: FlipDefaultsCostItemType.REAL_ESTATE_AGENT_FEE,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.TITLE_INSURANCE,
        itemType: FlipDefaultsCostItemType.TITLE_INSURANCE,
        amount: 185,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.TITLE_SEARCH,
        itemType: FlipDefaultsCostItemType.TITLE_SEARCH,
        amount: 10,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.APPRAISAL_FEE,
        itemType: FlipDefaultsCostItemType.APPRAISAL_FEE,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.INSPECTION_FEE,
        itemType: FlipDefaultsCostItemType.INSPECTION_FEE,
        amount: 425,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.LAND_TRANSFER_TAX,
        itemType: FlipDefaultsCostItemType.LAND_TRANSFER_TAX,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.PURCHASE_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.PROPERTY_TAX_ADJUSTMENT,
        itemType: FlipDefaultsCostItemType.PROPERTY_TAX_ADJUSTMENT,
        amount: 600,
        amountType: FlipAmountType.CONSTANT,
      },

      // selling costs
      {
        type: FlipCostType.SELLING_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.REAL_ESTATE_AGENT_FEE,
        itemType: FlipDefaultsCostItemType.REAL_ESTATE_AGENT_FEE_ALBERTA,
        amountType: FlipAmountType.COMPUTED,
        getAmount: (options) => {
          if (!options || !options.afterRepairValue) {
            return 0;
          }
          return computeAlbertaSellingRealEstateAgentFee(
            options.afterRepairValue
          );
        },
      },
      {
        type: FlipCostType.SELLING_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.LEGAL_COST,
        itemType: FlipDefaultsCostItemType.LEGAL_COST,
        amount: 1000,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.SELLING_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.TITLE_INSURANCE_RPR,
        itemType: FlipDefaultsCostItemType.TITLE_INSURANCE_RPR,
        amount: 300,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.SELLING_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.APPRAISAL_FEE_SECOND,
        itemType: FlipDefaultsCostItemType.APPRAISAL_FEE_SECOND,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.SELLING_COST,
        paymentType: FlipPaymentType.ONE_TIME,
        item: FlipDefaultCostItems.LAND_TRANSFER_TAX,
        itemType: FlipDefaultsCostItemType.LAND_TRANSFER_TAX,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },

      // operating costs
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.ELECTRICAL,
        itemType: FlipDefaultsCostItemType.ELECTRICAL,
        amount: 100,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.GAS,
        itemType: FlipDefaultsCostItemType.GAS,
        amount: 100,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.WATER_SEWER,
        itemType: FlipDefaultsCostItemType.WATER_SEWER,
        amount: 45,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.ALARM_SYSTEM,
        itemType: FlipDefaultsCostItemType.ALARM_SYSTEM,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.LAWN_CARE_SNOW_REMOVAL,
        itemType: FlipDefaultsCostItemType.LAWN_CARE_SNOW_REMOVAL,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.INSURANCE,
        itemType: FlipDefaultsCostItemType.INSURANCE,
        amount: 120,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.PROPERTY_TAXES,
        itemType: FlipDefaultsCostItemType.PROPERTY_TAXES,
        amount: 155,
        amountType: FlipAmountType.CONSTANT,
      },
      {
        type: FlipCostType.OPERATING_COST,
        paymentType: FlipPaymentType.MONTHLY,
        item: FlipDefaultCostItems.CONDO_FEES,
        itemType: FlipDefaultsCostItemType.CONDO_FEES,
        amount: 0,
        amountType: FlipAmountType.CONSTANT,
      },
    ],
    renovationSchedules: [
      { item: 'Number of Months to Renovate', numberOfMonths: 2 },
      { item: 'Number of Months to Sell', numberOfMonths: 3 },
    ],
  },
};
