import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { filter, finalize, first, map, switchMap, tap } from 'rxjs/operators';
import { LoadingEnd, LoadingStart } from 'src/app/core/loading.state';
import { AppFeaturesState } from '../shared/app-features.state';
import { GeneralAbstractPermissions } from './model';
import { PermissionsService } from './permissions.service';
import { ApplicationStage } from '@fundmoreai/models';

export interface PermissionsStateModel {
  applicationStageMovePermissionsMap: {
    [key: string]: ApplicationStage[];
  };
  permissions: GeneralAbstractPermissions;
}

export class FetchGeneralPermissions {
  static readonly type = '@permissions.fetchGeneralPermissions';
}

const allStages = [
  ApplicationStage[ApplicationStage.ADJUDICATION],
  ApplicationStage[ApplicationStage.COMPLIANCE],
  ApplicationStage[ApplicationStage.DEAL_ACCEPTANCE],
  ApplicationStage[ApplicationStage.DEAL_SIGNED],
  ApplicationStage[ApplicationStage.DECLINED],
  ApplicationStage[ApplicationStage.DOCUMENT_REVIEW],
  ApplicationStage[ApplicationStage.FINAL_APPROVAL],
  ApplicationStage[ApplicationStage.FINAL_REVIEW],
  ApplicationStage[ApplicationStage.FORECLOSURE],
  ApplicationStage[ApplicationStage.FUNDED],
  ApplicationStage[ApplicationStage.LAWYER_INSTRUCTED],
  ApplicationStage[ApplicationStage.NEW_APPLICATION],
  ApplicationStage[ApplicationStage.OPERATIONS_FULFILLMENT],
  ApplicationStage[ApplicationStage.PAID_IN_FULL],
  ApplicationStage[ApplicationStage.PAID_OUT],
  ApplicationStage[ApplicationStage.PAST_DUE_UNDER_90],
  ApplicationStage[ApplicationStage.PAST_DUE_OVER_90],
  ApplicationStage[ApplicationStage.PRE_FUND],
  ApplicationStage[ApplicationStage.PRESENT_DEAL],
  ApplicationStage[ApplicationStage.PROPOSE_DEAL],
  ApplicationStage[ApplicationStage.RENEWAL],
  ApplicationStage[ApplicationStage.UNDERWRITING],
];

@State({
  name: 'permissions',
  defaults: {},
})
@Injectable()
export class PermissionsState {
  @Selector()
  static applicationStageMovePermissionsMap(state: PermissionsStateModel): {
    [key: string]: ApplicationStage[];
  } {
    return state.applicationStageMovePermissionsMap;
  }

  @Selector()
  static permissions(state: PermissionsStateModel): GeneralAbstractPermissions {
    return state.permissions;
  }

  @Selector()
  static canManageSystem(state: PermissionsStateModel): boolean {
    return state.permissions.general.canManageSystemConfiguration;
  }

  @Selector()
  static canManageBrokers(state: PermissionsStateModel): boolean {
    return state.permissions.general.manageBrokers;
  }

  constructor(private permissionsService: PermissionsService, private store: Store) {}

  @Action(FetchGeneralPermissions)
  fetchGeneralPermissions(ctx: StateContext<PermissionsStateModel>) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.permissionsService.loadGeneralPermissionsOwn().pipe(
      switchMap((permissions) => {
        return this.store.select(AppFeaturesState.tenantStages).pipe(
          filter((x) => !!x),
          first(),
          map((tenantStages) => ({ permissions, tenantStages })),
        );
      }),
      tap(({ permissions, tenantStages }) => {
        ctx.setState({
          applicationStageMovePermissionsMap:
            PermissionsService.getApplicationStageMovePermissionsMap(
              permissions.applicationList.canMoveStage,
              tenantStages,
            ),
          permissions: {
            ...permissions,
            computed: {
              canDeleteApplicationInStages: this.canDeleteApplicationInStages(permissions),
              canMergeApplications: this.canMergeApplications(permissions),
              canManageDuplicates: this.canManageDuplicates(permissions),
            },
          },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  private canMergeApplications(permissions: GeneralAbstractPermissions): {
    [key: string]: boolean;
  } {
    const { canCreate, canArchiveApplication } = permissions.applicationList;
    const canDeleteApplicationInStages = this.canDeleteApplicationInStages(permissions);

    return allStages.reduce((result, stage) => {
      result[stage] =
        canCreate && (canDeleteApplicationInStages.includes(stage) || canArchiveApplication);

      return result;
    }, {} as { [key: string]: boolean });
  }

  private canManageDuplicates(permissions: GeneralAbstractPermissions): {
    [key: string]: boolean;
  } {
    const { canDecline, canArchiveApplication } = permissions.applicationList;
    const canDeleteApplicationInStages = this.canDeleteApplicationInStages(permissions);

    return allStages.reduce((result, stage) => {
      result[stage] =
        canDecline || canDeleteApplicationInStages.includes(stage) || canArchiveApplication;

      return result;
    }, {} as { [key: string]: boolean });
  }

  private canDeleteApplicationInStages(permissions: GeneralAbstractPermissions) {
    return permissions.applicationList.canDeleteApplication.reduce((result, item) => {
      result.push(...item.stage);

      return result;
    }, [] as ApplicationStage[]);
  }
}
