import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CompanySettingsModel } from '../../core/store/company-settings.state';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CompanySettingsService {
  constructor(private http: HttpClient) {}

  getCompanySettings(): Observable<Partial<CompanySettingsModel>> {
    return this.http.get<CompanySettingsModel>(`${environment.api_url}/tenant/settings/company`);
  }

  updateCompanySettings(companySettings: Partial<CompanySettingsModel>): Observable<void> {
    return this.http.patch<void>(`${environment.api_url}/tenant/settings/company`, companySettings);
  }
}
