export interface IDSGatewayBankingExistingMemberSearch {
  memberNumber: number | null;
  name: string | null;
  surname: string | null;
  bdate: string | null;
  sin: string | null;
  branchFullname: string | null;
  status: string | null;
  connectedparty: boolean | null;
  restrictedparty: boolean | null;
  benefit: BenefitResponse | null;
  benefitType: string | null;
}

export enum BenefitResponse {
  EMPTY = 'Empty',
  EIGHT_FREE = '8free',
  AGRI = 'AGRI',
  AGRI_PKG = 'agripkg',
  AGRI_SPEC = 'agrispec',
  BUS_ASSOC = 'busassoc',
  BUS_CONS = 'buscons',
  BUSINESS = 'BUSINESS',
  BUS_PKG = 'buspkg',
  BUS_SPEC = 'busspec',
  CHIP = 'CHIP',
  CHURCH = 'CHURCH',
  ELEC_PKG = 'ELECPKG',
  EQUITY = 'equity',
  LAWYER = 'lawyer',
  LOC = 'LOC',
  LOC_BRK = 'LOCBrk',
  MEM_CASH = 'MEMCASH',
  NOSC = 'nosc',
  NTC = 'NTC',
  ORG_SPEC = 'ORGSPEC',
  PACKAGE = 'PACKAGE',
  PLUS = 'plus',
  PS_PLAN = 'psplan',
  REGULAR = 'REGULAR',
  SB_BE_PKG = 'SBBePkg',
  SB_B_FLAT = 'SBBFlat',
  SB_B_PLUS = 'SBBPlus',
  SB_FLAT_PL = 'SBFlatPl',
  SENIOR = 'SENIOR',
  SNR_PKG = 'SnrPkg',
  SNR_PLUS = 'SnrPlus',
  STAFF = 'STAFF',
  STUDENT = 'STUDENT',
  TFSA = 'TFSA',
  TRURLTY = 'trurlty',
  YOUTH = 'YOUTH',
  Z_RRIF = 'zRRIF',
  Z_RRSP = 'zRRSP',
}
