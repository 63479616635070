import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { finalize, tap } from 'rxjs/operators';
import { ConstructionMortgage } from '../../shared';
import { ConstructionMortgageService } from './construction-mortgage.service';
import {
  GetConstructionMortgage,
  UpdateBuildingBudget,
  UpdateConstructionMortgage,
} from './construction.actions';
import { ApplicationResetState } from '../../shared/state.model';
import { LoadingStart, LoadingEnd } from '../../core/loading.state';

export interface ConstructionModel {
  constructionMortgage: ConstructionMortgage | undefined;
}

@State<ConstructionModel>({
  name: 'constructionMortgageState',
  defaults: {
    constructionMortgage: undefined,
  },
})
@Injectable()
export class ConstructionMortgageState {
  constructor(private constructionMortgageService: ConstructionMortgageService) {}

  @Selector() static constructionMortgage(state: ConstructionModel) {
    return state.constructionMortgage;
  }

  @Action(ApplicationResetState)
  reset(ctx: StateContext<ConstructionModel>) {
    ctx.setState({ constructionMortgage: undefined });
  }

  @Action(GetConstructionMortgage)
  public getConstructionMortgage(
    ctx: StateContext<ConstructionModel>,
    { mortgageId }: GetConstructionMortgage,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    return this.constructionMortgageService.getConstructionMortgage(mortgageId).pipe(
      tap((mortgage) => {
        if (!mortgage) {
          return;
        }
        ctx.patchState({ constructionMortgage: mortgage });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateConstructionMortgage)
  updateConstructionMortgage(
    ctx: StateContext<ConstructionModel>,
    action: UpdateConstructionMortgage,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { id, buildingBudget, ...constructionMortgage } = action.constructionMortgage;

    return this.constructionMortgageService
      .patchConstructionMortgage(id, constructionMortgage)
      .pipe(
        tap(() => {
          ctx.patchState({ constructionMortgage: action.constructionMortgage });
        }),

        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(UpdateBuildingBudget)
  updateBuildingBudget(ctx: StateContext<ConstructionModel>, action: UpdateBuildingBudget) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const { constructionMortgageId, buildingBudgetPayload } = action;
    return this.constructionMortgageService
      .patchBuildingBudget(constructionMortgageId, buildingBudgetPayload)
      .pipe(
        tap(() => {
          const constructionMortgageSnapshot = ctx.getState().constructionMortgage;
          if (constructionMortgageSnapshot) {
            const updatedConstructionMortgage = { ...constructionMortgageSnapshot };
            updatedConstructionMortgage.buildingBudget = buildingBudgetPayload.buildingBudget;
            ctx.patchState({
              constructionMortgage: updatedConstructionMortgage,
            });
          }
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }
}
