import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { UserAccount } from '../shared';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserAccountsService {
  constructor(private http: HttpClient) {}

  getUserAccounts(): Observable<UserAccount[]> {
    return this.http.get<UserAccount[]>(`${environment.api_url}/account`).pipe(
      map((ua) => {
        return ua.map((u) => {
          return {
            ...u,
            user: { ...u.user, displayName: this.buildDisplayName(u) },
          };
        });
      }),
    );
  }

  private buildDisplayName(u: UserAccount): string {
    if (!u.user) {
      return '';
    }
    const { firstName, lastName, email } = u.user;
    if (!firstName || !u.user.lastName) {
      return email;
    }
    return `${firstName ?? ''} ${lastName ?? ''}`;
  }
}
