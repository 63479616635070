import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthScopeContextService } from '.';

@Injectable()
export class ScopeContextTokenInterceptor implements HttpInterceptor {
  constructor(private authScopeContextService: AuthScopeContextService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const currentScopeContext = this.authScopeContextService.currentScopeContextToken;
    if (currentScopeContext && currentScopeContext.scopeContextToken) {
      const scopedRequest = request.clone({
        setHeaders: {
          'x-scope-context': `${currentScopeContext.scopeContextToken}`,
        },
      });
      return next.handle(scopedRequest);
    }
    return next.handle(request);
  }
}
