import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, UrlTree, Router } from '@angular/router';
import { Observable, take, map, catchError, of } from 'rxjs';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanLoad {
  access_token: string | undefined;

  constructor(private router: Router, private authService: AuthService) {}
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isAuthenticated();
  }

  canLoad(): Observable<boolean> | Promise<boolean> | boolean {
    return this.isAuthenticated();
  }

  private isAuthenticated(): Observable<boolean> {
    return this.authService.isAuthenticated().pipe(
      take(1),
      catchError((err) => {
        console.error('Error checking authentication status: ', err);
        return of(false);
      }),
      map((isAuthenticated) => {
        if (!isAuthenticated) {
          this.authService.reLogin();
        }
        return isAuthenticated ?? false;
      }),
    );
  }
}
