import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CreditReportData, EquifaxCreditReportRequestPayload } from '@fundmoreai/models';
import { environment } from '../../../../../environments/environment';
import { CreditReportAppliedResponse, PulledCreditReportResponse } from './model';

@Injectable({
  providedIn: 'root',
})
export class CreditReportRequestsService {
  constructor(private http: HttpClient) {}

  requestCreditReportEquifax(payload: EquifaxCreditReportRequestPayload) {
    return this.http.post<PulledCreditReportResponse>(
      `${environment.api_url}/creditReport/equifax/pullCreditReport`,
      payload,
    );
  }

  getAllCreditReportRequest(applicationId: string) {
    return this.http.get<PulledCreditReportResponse[]>(
      `${environment.api_url}/creditReport/requests?applicationId=${applicationId}`,
    );
  }

  applyCreditReports(payload: { applicationId: string; creditReportRequestsIds: string[] }) {
    return this.http.put<CreditReportAppliedResponse | undefined>(
      `${environment.api_url}/creditReport/applyChanges`,
      payload,
    );
  }

  updateCreditReport(
    creditReportId: string,
    payload: { applicationId: string; creditReport: Partial<CreditReportData> },
  ) {
    return this.http.patch(`${environment.api_url}/creditReport/${creditReportId}`, payload);
  }
}
