import { Mortgage, MortgageCalculationAutomationSettings } from '@fundmoreai/models';

export function getEqSettingsPerMortgage(
  requestedMortgages: Pick<Mortgage, 'id' | 'loanType'>[],
  hasError: boolean,
): MortgageCalculationAutomationSettings {
  const mortgageCalculationAutomationSettings: MortgageCalculationAutomationSettings = {};

  const mortgages = requestedMortgages.sort((a, b) =>
    (a.loanType ?? '').localeCompare(b.loanType ?? ''),
  );
  if (mortgages.length > 0) {
    const [mortgage] = mortgages;
    mortgageCalculationAutomationSettings[mortgage.id] = {
      isInterestAdjustmentAmountDisabled: true,
      isInterestAdjustmentDateDisabled: true,
      isMaturityDateDisabled: true,
      excludeExtraPaymentInTerm: false,
      isMonthlyPaymentDisabled: true,
      isPaymentAmountDisabled: true,
      setGDSToZero: undefined,
      setTDSToZero: undefined,
    };
  }

  if (hasError) {
    mortgageCalculationAutomationSettings.ANY_MORTGAGE = {
      isInterestAdjustmentAmountDisabled: undefined,
      isInterestAdjustmentDateDisabled: undefined,
      isMaturityDateDisabled: undefined,
      excludeExtraPaymentInTerm: undefined,
      isMonthlyPaymentDisabled: undefined,
      isPaymentAmountDisabled: undefined,
      setGDSToZero: true,
      setTDSToZero: true,
    };
  }

  return mortgageCalculationAutomationSettings;
}
