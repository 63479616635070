import { FinancialLiability, LiabilityOverride } from '@fundmoreai/models';

/**
 * Applies liability overrides to a financial liability.
 * If the balance or type of the liability has changed, it recalculates the monthly payment
 * based on the override value, if it exists.
 * If the monthly payment is changed, it processes the change without any overrides.
 *
 * @param liabilityOverrides - An array of liability overrides.
 * @param currentLiability - The current financial liability.
 * @param previousLiability - The previous financial liability.
 * @returns The updated financial liability after applying the liability overrides.
 */
export function applyLiabilityOverrides(
  liabilityOverrides?: LiabilityOverride[],
  currentLiability?: Pick<FinancialLiability, 'liability' | 'monthlyPayment' | 'balance'>,
  previousLiability?: Pick<FinancialLiability, 'liability' | 'balance'>,
): Partial<FinancialLiability> {
  if (!currentLiability || !liabilityOverrides || !previousLiability) {
    return currentLiability;
  }
  if (
    (previousLiability?.balance !== currentLiability.balance ||
      previousLiability?.liability !== currentLiability.liability) &&
    currentLiability.balance !== undefined
  ) {
    currentLiability = computeMonthlyPaymentWithLiabilityOverride(
      liabilityOverrides,
      currentLiability,
    ) as FinancialLiability;
  }

  return currentLiability;
}

/**
 * Computes the monthly payment for a financial liability with liability overrides.
 * If a liability override is found for the given liability, the monthly payment is adjusted accordingly.
 *
 * @param liabilityOverrides - An array of liability overrides.
 * @param liability - The financial liability for which to compute the monthly payment.
 * @returns The financial liability with the updated monthly payment.
 */
export function computeMonthlyPaymentWithLiabilityOverride(
  liabilityOverrides: LiabilityOverride[],
  liability: Pick<FinancialLiability, 'liability' | 'monthlyPayment' | 'balance'>,
): Partial<FinancialLiability> {
  const override = liabilityOverrides?.find(
    (override) => override.liabilityType === liability.liability,
  );

  if (override) {
    if (override.useAsMinimumThreshold) {
      liability.monthlyPayment = Math.max(
        Math.round(override.overrideValue * liability.balance) / 100,
        liability.monthlyPayment || null,
      );
    } else {
      liability.monthlyPayment = Math.round(override.overrideValue * liability.balance) / 100;
    }
  }

  return liability;
}

/**
 * Computes the monthly payment for a financial liability with liability overrides applied during the ingestion process.
 * If no liability override is found or if the liability already has a monthly payment or missing required values,
 * it returns the original liability object.
 * If a liability override is found for the given liability type, it calculates the monthly payment
 * based on the override value and the liability balance.
 *
 * @param liabilityOverrides - An array of liability overrides.
 * @param liability - The financial liability object.
 * @returns The financial liability object with the computed monthly payment.
 */
export function computeMonthlyPaymentWithLiabilityOverrideForIngestion(
  liabilityOverrides: LiabilityOverride[],
  liability: Pick<FinancialLiability, 'liability' | 'monthlyPayment' | 'balance'>,
): Partial<FinancialLiability> {
  const override = liabilityOverrides.find((liabilityOverride) => {
    return liabilityOverride.liabilityType === liability.liability;
  });

  if (liability.monthlyPayment || !override?.overrideValue || !liability.balance) {
    return liability;
  }

  if (override.useAsMinimumThreshold) {
    return {
      ...liability,
      monthlyPayment: Math.max(
        Math.round(override.overrideValue * liability.balance) / 100,
        liability.monthlyPayment || null,
      ),
    };
  } else {
    return {
      ...liability,
      monthlyPayment: Math.round(override.overrideValue * liability.balance) / 100,
    };
  }
}
