import { Mortgage, PaymentFrequency } from '@fundmoreai/models';
import { compoundingMap, paymentFrequencyMap } from './mortgage-payment.calculator';

export type AmortizationPaymentsMortgageInput = Pick<
  Mortgage,
  'totalLoanAmount' | 'netRate' | 'paymentAmount' | 'paymentFrequency' | 'compounding'
>;

/**
 *
 * @param mortgage The mortgage input (with totalLoanAmount, netRate, paymentAmount, paymentFrequency, compounding)
 * @returns The computed number of payments required to pay off the mortgage
 */
export function computeAmortizationNumberOfPayments(
  mortgage: AmortizationPaymentsMortgageInput | undefined,
): number {
  if (!mortgage) {
    return Infinity;
  }

  const { totalLoanAmount, netRate, paymentAmount, paymentFrequency, compounding } = mortgage;

  const rateDecimal = netRate / 100;

  const paymentsPerYear =
    paymentFrequencyMap.get(paymentFrequency) ?? paymentFrequencyMap.get(PaymentFrequency.MONTHLY);

  const compoundsPerYear = compoundingMap.get(compounding) ?? 2;

  // Calculate the interest rate per payment period
  const periodRate =
    Math.pow(1 + rateDecimal / compoundsPerYear, compoundsPerYear / paymentsPerYear) - 1;

  // Calculate the total number of payments using the formula:
  // n = log(M / (M - P * r)) / log(1 + r)
  // where M is the payment amount, P is the principal, and r is the period rate
  if (paymentAmount <= totalLoanAmount * periodRate) {
    return Infinity; // This means the loan will never be paid off with the current setup
  }

  const numberOfPayments =
    Math.log(paymentAmount / (paymentAmount - totalLoanAmount * periodRate)) /
    Math.log(1 + periodRate);

  return numberOfPayments;
}

/**
 *
 * @param mortgage The mortgage input (with totalLoanAmount, netRate, paymentAmount, paymentFrequency, compounding)
 * @returns  The computed number of months required to pay off the mortgage. This is a floating point number.
 */
export function computeAmortizationMonthsExact(
  mortgage: AmortizationPaymentsMortgageInput | undefined,
): number {
  const numberOfPayments = computeAmortizationNumberOfPayments(mortgage);

  const paymentsPerYear =
    paymentFrequencyMap.get(mortgage.paymentFrequency) ??
    paymentFrequencyMap.get(PaymentFrequency.MONTHLY);

  return (numberOfPayments / paymentsPerYear) * 12;
}

/**
 *
 * @param mortgage The mortgage input (with totalLoanAmount, netRate, paymentAmount, paymentFrequency, compounding)
 * @returns The computed number of months required to pay off the mortgage. This is a rounded up integer.
 */
export function computeAmortizationMonths(
  mortgage: AmortizationPaymentsMortgageInput | undefined,
): number {
  return Math.ceil(computeAmortizationMonthsExact(mortgage));
}
