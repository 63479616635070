import { Action, createSelector, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Property, PropertyType, PropertyOwner } from '../shared';
import { of } from 'rxjs';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { PropertiesService } from './properties.service';
import { FundmoreCalculator } from '@fundmoreai/calculator';
import { PropertyOwnerState } from './property-owners/property-owner.state';
import { FlipPropertyDetails, FlipUpdatePropertyFields } from './flip/flip.state.action';
import { RemovePropertyValueAppraisals } from './property-appraisals/property-appraisals.state';
import {
  FetchPropertyAddress,
  SetPropertyAddress,
} from '../features/shared/address/address-details.state';
import { RecomputeLoanAmountOnRequestedMortgage } from './mortgages-v2/mortgages-v2.actions';
import { SetOwners } from './property-owners/property-owner.actions';
import {
  AddProperty,
  DeleteProperty,
  PatchProperty,
  PatchPropertyAddress,
  SetProperties,
  UpdateProperty,
  UpdatePropertyCollateralize,
} from './properties.actions';
import { LoadingEnd, LoadingStart } from '../core/loading.state';
import { ApplicationResetState } from '../shared/state.model';
import { MortgageKey, PropertyAddressDetails, PropertyKey } from '@fundmoreai/models';
import { Column } from '../features/manager-portal/condition-manage/model';
import { MortgageKeyRecord, PropertyKeyRecord } from '../shared/enum-records-metadata';
import { AppFeaturesState } from '../shared/app-features.state';

const PROPERTIES_NET_WORTH_SELECTABLE_COLUMNS: (PropertyKey | string)[] = [
  PropertyKey.PROPERTY_ADDRESS,
  'mortgageBalance',
  'monthlyPayment',
  PropertyKey.ESTIMATED_VALUE,
  PropertyKey.MARK_AS_SOLD,
  PropertyKey.SOLD_ON,
  PropertyKey.APPLICANT_PROPERTIES,
];

export const PROPERTIES_NET_WORTH_DEFAULT_DISPLAY_COLUMNS: Column[] =
  PROPERTIES_NET_WORTH_SELECTABLE_COLUMNS.map((value: PropertyKey | string) => {
    const name =
      value === 'mortgageBalance'
        ? $localize`:@@existingMortgagesTotal:Existing Mortgages Total`
        : value === 'monthlyPayment'
        ? MortgageKeyRecord[MortgageKey.MONTHLY_PAYMENT]
        : PropertyKeyRecord[<PropertyKey>value];

    return {
      field: value,
      name,
      isSelected: true,
      isFrozen: false,
    };
  });

export interface PropertiesStateModel {
  properties?: {
    [key: string]: Property;
  };
}

const defaults = {
  properties: undefined,
};
@State<PropertiesStateModel>({
  name: 'properties',
  defaults: { ...defaults },
})
@Injectable()
export class PropertiesState {
  @Selector()
  private static properties(state: PropertiesStateModel): Property[] | undefined {
    return state.properties ? Object.values(state.properties) : undefined;
  }

  static annualAllExpenses(propertyId: string) {
    return createSelector([PropertiesState], (state: PropertiesStateModel): number | undefined => {
      const property = state.properties?.[propertyId];

      if (property) {
        return FundmoreCalculator.computeAllAnnualExpenses(property);
      }

      return;
    });
  }

  static annualExpenses(propertyId: string) {
    return createSelector([PropertiesState], (state: PropertiesStateModel): number | undefined => {
      const property = state.properties?.[propertyId];

      if (property) {
        return FundmoreCalculator.computeAllAnnualExpenses(property);
      }

      return;
    });
  }

  @Selector([PropertiesState.properties])
  static borrowerProperties(properties: Property[]): Property[] | undefined {
    // primary and other properties
    return properties?.filter((property) => property.type !== PropertyType.EXTERNAL);
  }

  static networth(applicantId?: string) {
    return createSelector(
      [PropertiesState.otherProperties],
      (otherProperties: Property[] | undefined): number => {
        if (applicantId) {
          return (
            otherProperties
              ?.filter((property) =>
                property.ApplicantProperties.find((x) => x.applicantId === applicantId),
              )
              .reduce(
                (sum, property) =>
                  sum +
                  +(property.estimatedValue ? property.estimatedValue : 0.0) /
                    property.ApplicantProperties.length,
                0.0,
              ) ?? 0.0
          );
        }

        return (
          otherProperties?.reduce(
            (sum, property) => sum + +(property.estimatedValue ? property.estimatedValue : 0.0),
            0.0,
          ) ?? 0.0
        );
      },
    );
  }

  static futureNetworth(applicantId?: string) {
    return createSelector(
      [PropertiesState.otherProperties],
      (otherProperties: Property[] | undefined): number => {
        if (applicantId) {
          return (
            otherProperties
              ?.filter((property) => !property.markAsSold)
              .filter((property) =>
                property.ApplicantProperties.find((x) => x.applicantId === applicantId),
              )
              .reduce(
                (sum, property) =>
                  sum +
                  +(property.estimatedValue ? property.estimatedValue : 0.0) /
                    property.ApplicantProperties.length,
                0.0,
              ) ?? 0.0
          );
        }

        return (
          otherProperties
            ?.filter((property) => !property.markAsSold)
            .reduce(
              (sum, property) => sum + +(property.estimatedValue ? property.estimatedValue : 0.0),
              0.0,
            ) ?? 0.0
        );
      },
    );
  }

  static saleProceeds(applicantId?: string) {
    return createSelector(
      [PropertiesState.otherProperties],
      (otherProperties: Property[] | undefined): number => {
        if (applicantId) {
          return (
            otherProperties
              ?.filter((property) => property.markAsSold)
              .filter((property) =>
                property.ApplicantProperties.find((x) => x.applicantId === applicantId),
              )
              .reduce(
                (sum, property) =>
                  sum +
                  +(property.estimatedValue ? property.estimatedValue : 0.0) /
                    property.ApplicantProperties.length,
                0.0,
              ) ?? 0.0
          );
        }

        return (
          otherProperties
            ?.filter((property) => property.markAsSold)
            .reduce(
              (sum, property) => sum + +(property.estimatedValue ? property.estimatedValue : 0.0),
              0.0,
            ) ?? 0.0
        );
      },
    );
  }

  @Selector([PropertiesState.properties])
  static otherProperties(properties: Property[] | undefined): Property[] | undefined {
    return properties?.filter((property) => property.type === PropertyType.OTHER);
  }

  @Selector([PropertiesState.properties])
  static primaryProperty(properties: Property[] | undefined): Property | undefined {
    return properties?.find((property) => property.type === PropertyType.PRIMARY);
  }

  static primaryPropertyValue(applicantId?: string) {
    return createSelector(
      [PropertiesState.primaryProperty],
      (primaryProperty: Property): number => {
        if (!primaryProperty) {
          return 0;
        }

        return FundmoreCalculator.computePropertyValue(primaryProperty, applicantId);
      },
    );
  }

  @Selector([PropertiesState.properties])
  static propertiesList(properties: Property[] | undefined): Property[] | undefined {
    return properties;
  }

  @Selector([PropertiesState.properties])
  static propertyAddressesString(properties: Property[] | undefined): string {
    const records: Record<string, PropertyAddressDetails> = {};
    if (!properties) {
      return JSON.stringify(records);
    }

    for (const { id, propertyAddressExpanded } of properties) {
      records[id] = propertyAddressExpanded;
    }

    return JSON.stringify(records);
  }

  static sameAddressField(field1: unknown, field2: unknown) {
    if ((field1 === undefined && field2 === null) || (field1 === null && field2 === undefined)) {
      return true;
    }

    return field1 === field2;
  }

  @Selector([PropertiesState.propertyAddressesString])
  static duplicatePropertyAddressesString(propertyAddresses: string): string {
    const addressRecords: Record<string, PropertyAddressDetails> = JSON.parse(propertyAddresses);
    const duplicateAddressRecords: Record<string, boolean> = {};

    for (const propertyId in addressRecords) {
      const propertyAddress = addressRecords[propertyId];
      const rest = Object.entries(addressRecords)
        .map(([k]) => k)
        .filter((r) => r !== propertyId);
      const isDuplicate =
        rest?.find(
          (r) =>
            this.sameAddressField(addressRecords[r]?.unit, propertyAddress?.unit) &&
            this.sameAddressField(addressRecords[r]?.streetName, propertyAddress?.streetName) &&
            this.sameAddressField(addressRecords[r]?.streetNumber, propertyAddress?.streetNumber) &&
            this.sameAddressField(addressRecords[r]?.streetType, propertyAddress?.streetType) &&
            this.sameAddressField(addressRecords[r]?.postalCode, propertyAddress?.postalCode) &&
            this.sameAddressField(addressRecords[r]?.city, propertyAddress?.city) &&
            this.sameAddressField(addressRecords[r]?.province, propertyAddress?.province) &&
            this.sameAddressField(addressRecords[r]?.country, propertyAddress?.country),
        ) !== undefined;

      duplicateAddressRecords[propertyId] = isDuplicate;
    }

    return JSON.stringify(duplicateAddressRecords);
  }

  @Selector([PropertiesState.duplicatePropertyAddressesString])
  static duplicatePropertyAddresses(propertyAddresses: string): Record<string, boolean> {
    return JSON.parse(propertyAddresses);
  }

  @Selector([PropertiesState.properties, PropertyOwnerState.ownersList])
  static propertiesListWithOwners(
    properties: Property[] | undefined,
    owners: PropertyOwner[],
  ): Property[] | undefined {
    return properties?.map((property) => {
      const propertyOwners = owners.filter((owner) => owner.propertyId === property.id);

      return { ...property, PropertyOwners: propertyOwners };
    });
  }

  @Selector([PropertiesState.otherProperties])
  static propertiesToCollateralize(
    otherProperties: Property[] | undefined,
  ): Property[] | undefined {
    return otherProperties?.filter((property) => !property.isCollateralized);
  }

  static property(propertyId: string | null | undefined) {
    return createSelector(
      [PropertiesState.propertiesList],
      (properties: Property[] | undefined): Property | undefined => {
        if (!properties || !propertyId) {
          return undefined;
        }
        return properties?.find((property) => property.id === propertyId);
      },
    );
  }

  static linkedProperty(propertyId: string | null | undefined) {
    return createSelector(
      [PropertiesState.propertiesList, AppFeaturesState.isBlanketEnabled],
      (properties: Property[] | undefined, isBlanketEnabled: boolean): Property | undefined => {
        if (isBlanketEnabled) {
          return PropertiesState.primaryProperty(properties);
        } else {
          if (!properties || !propertyId) {
            return undefined;
          }
          return properties?.find((property) => property.id === propertyId);
        }
      },
    );
  }

  @Selector([PropertiesState.properties])
  static securities(properties: Property[] | undefined): Property[] | undefined {
    return properties?.filter(
      (property) => property.isCollateralized || property.type === PropertyType.PRIMARY,
    );
  }

  @Selector([PropertiesState.properties])
  static propertiesValuesForProductReapplyTriggers(properties: Property[] | undefined) {
    return properties?.map((property) => ({
      type: property.type,
      propertyType: property.propertyType,
      occupancy: property.occupancy,
      zoningType: property.zoningType,
      dwellingType: property.dwellingType,
      purchasePrice: property.purchasePrice,
      estimatedValue: property.estimatedValue,
      annualTaxes: property.annualTaxes,
      condoFees: property.condoFees,
      condoFeesIncludeHeating: property.condoFeesIncludeHeating,
      condoFeesPercentInCalculation: property.condoFeesPercentInCalculation,
      includeExpensesInTDS: property.includeExpensesInTDS,
      includeAnnualTaxesInTDS: property.includeAnnualTaxesInTDS,
      includeCondoFeesInTDS: property.includeCondoFeesInTDS,
      includeHeatingCostInTDS: property.includeHeatingCostInTDS,
      heatingCost: property.heatingCost,
      rpeGeneralExpenses: property.rpeGeneralExpenses,
      rpeHydro: property.rpeHydro,
      rpeInsurance: property.rpeInsurance,
      rpeInterestCharges: property.rpeInterestCharges,
      rpeManagementExpenses: property.rpeManagementExpenses,
      rpeRepairs: property.rpeRepairs,
      rpeRentalOffsetOption: property.rpeRentalOffsetOption,
      rpeOffset: property.rpeOffset,
      markAsSold: property.markAsSold,
      isPrimaryResidence: property.isPrimaryResidence,
      isCollateralized: property.isCollateralized,
      includeInCLTV: property.includeInCLTV,
    }));
  }

  constructor(private propertiesService: PropertiesService, private store: Store) {}

  @Action(AddProperty)
  addProperty(ctx: StateContext<PropertiesStateModel>, action: AddProperty) {
    const state = ctx.getState();
    const property = { ...action.property };

    ctx.dispatch(new LoadingStart(this.constructor.name));

    if (property.estimatedValue) {
      property.estimatedValueDate = new Date().toISOString();
    }

    return this.propertiesService.postProperties(action.applicationId, [property]).pipe(
      tap(([property]) => {
        ctx.patchState({
          properties: {
            ...state.properties,
            [property.id]: property,
          },
        });
      }),
      switchMap(([property]) => {
        return ctx.dispatch(new FetchPropertyAddress(property.id));
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(ApplicationResetState)
  reset(ctx: StateContext<PropertiesStateModel>) {
    ctx.setState({ ...defaults });
  }

  @Action(DeleteProperty)
  deleteProperty(ctx: StateContext<PropertiesStateModel>, action: DeleteProperty) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.propertiesService.deleteProperty(action.applicationId, action.propertyId).pipe(
      tap(() => {
        const state = ctx.getState();
        const properties = { ...state.properties };

        delete properties[action.propertyId];

        ctx.patchState({
          properties,
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(PatchProperty)
  patchProperty(ctx: StateContext<PropertiesStateModel>, action: PatchProperty) {
    const state = ctx.getState();
    const property = state.properties?.[action.propertyId];

    if (property) {
      ctx.patchState({
        properties: {
          ...state.properties,
          [property.id]: { ...property, ...action.property },
        },
      });
    }
  }

  @Action(PatchPropertyAddress)
  patchPropertyAddress(ctx: StateContext<PropertiesStateModel>, action: PatchPropertyAddress) {
    const state = ctx.getState();
    const property = state.properties?.[action.propertyAddressDetails?.propertyId];

    if (property) {
      ctx.patchState({
        properties: {
          ...state.properties,
          [property.id]: {
            ...property,
            propertyAddress: action.propertyAddressDetails?.formattedAddress,
            propertyAddressExpanded: action.propertyAddressDetails,
          },
        },
      });
    }
  }

  @Action(SetProperties)
  setProperties(ctx: StateContext<PropertiesStateModel>, action: SetProperties) {
    const propertiesEntities = action.properties.reduce(
      (entities: { [key: string]: Property }, property) => {
        entities[property.id] = property;

        return entities;
      },
      {},
    );

    ctx.patchState({ properties: propertiesEntities });

    const propertyOwners: PropertyOwner[] = [];

    action.properties.forEach((property) => {
      propertyOwners.push(...property.PropertyOwners);

      this.store.dispatch(new SetPropertyAddress(property.id, property.propertyAddressExpanded));
    });

    this.store.dispatch(new SetOwners(propertyOwners));
  }

  @Action(UpdateProperty)
  updateProperty(ctx: StateContext<PropertiesStateModel>, action: UpdateProperty) {
    const state = ctx.getState();
    const property = this.sanitizeProperty(action.property);

    if (!property.id) {
      return;
    }

    const propertyToUpdate = state.properties?.[property.id];

    ctx.dispatch(new LoadingStart(this.constructor.name));

    if (property.estimatedValue && property.estimatedValue !== propertyToUpdate?.estimatedValue) {
      property.estimatedValueDate = new Date().toISOString();

      this.store.dispatch(new RemovePropertyValueAppraisals(property.id));
    }

    const hasValueChangedMap = {
      purchasePrice:
        !!property.purchasePrice && property.purchasePrice !== propertyToUpdate?.purchasePrice,
      estimatedValue:
        !!property.estimatedValue && property.estimatedValue !== propertyToUpdate?.estimatedValue,
    };

    return this.propertiesService.patchProperty(action.applicationId, property.id, property).pipe(
      tap((updatedProperty) => {
        ctx.patchState({
          properties: {
            ...state.properties,
            // it seems that the patch response doesn't include (at least) the "ApplicantProperties" field
            // hence merging updatedProperty into the property already in the state
            [updatedProperty.id]: { ...propertyToUpdate, ...property, ...updatedProperty },
          },
        });
      }),
      switchMap((updatedProperty) => {
        let recomputeLoanAmountObservable;

        const isAutomaticLoanAmountCalculationDisabled = this.store.selectSnapshot(
          AppFeaturesState.isAutomaticLoanAmountCalculationDisabled,
        );

        if (
          !isAutomaticLoanAmountCalculationDisabled &&
          updatedProperty.type === PropertyType.PRIMARY &&
          (hasValueChangedMap.estimatedValue || hasValueChangedMap.purchasePrice)
        ) {
          recomputeLoanAmountObservable = this.store.dispatch(
            new RecomputeLoanAmountOnRequestedMortgage(),
          );
        } else {
          recomputeLoanAmountObservable = of(updatedProperty);
        }

        return recomputeLoanAmountObservable.pipe(map(() => updatedProperty));
      }),
      switchMap((updatedProperty) => {
        if (property.type !== PropertyType.PRIMARY) {
          return of(updatedProperty);
        }
        const flipAnalysisInput: Partial<FlipPropertyDetails> = {
          purchasePrice: property?.purchasePrice,
        };
        return this.store
          .dispatch(new FlipUpdatePropertyFields(flipAnalysisInput))
          .pipe(map(() => updatedProperty));
      }),
      tap(() => {
        if (!property.id) {
          return;
        }

        this.store.dispatch(new FetchPropertyAddress(property.id));
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  private sanitizeProperty(property: Partial<Property>): Partial<Property> {
    const ApplicantProperties = property.ApplicantProperties?.map((applicantProperty) => ({
      applicantId: applicantProperty.applicantId,
      propertyId: applicantProperty.propertyId,
    }));
    return { ...property, ApplicantProperties };
  }

  @Action(UpdatePropertyCollateralize)
  updatePropertyCollateralize(
    ctx: StateContext<PropertiesStateModel>,
    action: UpdatePropertyCollateralize,
  ) {
    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.propertiesService
      .patchCollateralize(action.propertyId, action.isCollateralized)
      .pipe(
        tap(() => {
          const property = state.properties?.[action.propertyId];

          if (property) {
            ctx.patchState({
              properties: {
                ...state.properties,
                [property.id]: { ...property, isCollateralized: action.isCollateralized },
              },
            });
          }
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }
}
