export enum CmhcStreetType {
  ABBEY = 'ABBEY',
  ACRES = 'ACRES',
  ALLÉE = 'ALLÉE',
  AUT = 'AUT',
  AV = 'AV',
  AVE = 'AVE',
  BAY = 'BAY',
  BEACH = 'BEACH',
  BEND = 'BEND',
  BLVD = 'BLVD',
  BOUL = 'BOUL',
  BYPASS = 'BYPASS',
  BYWAY = 'BYWAY',
  C = 'C',
  CAMPUS = 'CAMPUS',
  CAPE = 'CAPE',
  CAR = 'CAR',
  CARREF = 'CARREF',
  CDS = 'CDS',
  CERCLE = 'CERCLE',
  CH = 'CH',
  CHASE = 'CHASE',
  CIR = 'CIR',
  CIRCT = 'CIRCT',
  CLOSE = 'CLOSE',
  COMMON = 'COMMON',
  CONC = 'CONC',
  CÔTE = 'CÔTE',
  COUR = 'COUR',
  COVE = 'COVE',
  CRES = 'CRES',
  CRNRS = 'CRNRS',
  CROIS = 'CROIS',
  CROSS = 'CROSS',
  CRT = 'CRT',
  CTR = 'CTR',
  DALE = 'DALE',
  DELL = 'DELL',
  DIVERS = 'DIVERS',
  DOWNS = 'DOWNS',
  DR = 'DR',
  ÉCH = 'ÉCH',
  END = 'END',
  ESPL = 'ESPL',
  ESTATE = 'ESTATE',
  EXPY = 'EXPY',
  EXTEN = 'EXTEN',
  FARM = 'FARM',
  FIELD = 'FIELD',
  FOREST = 'FOREST',
  FRONT = 'FRONT',
  FWY = 'FWY',
  GATE = 'GATE',
  GDNS = 'GDNS',
  GLADE = 'GLADE',
  GLEN = 'GLEN',
  GREEN = 'GREEN',
  GRNDS = 'GRNDS',
  GROVE = 'GROVE',
  HARBR = 'HARBR',
  HGHLDS = 'HGHLDS',
  HILL = 'HILL',
  HOLLOW = 'HOLLOW',
  HTS = 'HTS',
  HWY = 'HWY',
  ÎLE = 'ÎLE',
  IMP = 'IMP',
  ISLAND = 'ISLAND',
  KEY = 'KEY',
  KNOLL = 'KNOLL',
  LANE = 'LANE',
  LINE = 'LINE',
  LINK = 'LINK',
  LKOUT = 'LKOUT',
  LMTS = 'LMTS',
  LANDNG = 'LANDNG',
  LOOP = 'LOOP',
  MALL = 'MALL',
  MANOR = 'MANOR',
  MAZE = 'MAZE',
  MEADOW = 'MEADOW',
  MEWS = 'MEWS',
  MONTÉE = 'MONTÉE',
  MOOR = 'MOOR',
  MOUNT = 'MOUNT',
  MTN = 'MTN',
  ORCH = 'ORCH',
  PARADE = 'PARADE',
  PARC = 'PARC',
  PASS = 'PASS',
  PATH = 'PATH',
  PINES = 'PINES',
  PK = 'PK',
  PKY = 'PKY',
  PL = 'PL',
  PLACE = 'PLACE',
  PLAT = 'PLAT',
  PLAZA = 'PLAZA',
  PORT = 'PORT',
  PROM = 'PROM',
  PT = 'PT',
  PTWAY = 'PTWAY',
  PVT = 'PVT',
  QUAI = 'QUAI',
  QUAY = 'QUAY',
  RANG = 'RANG',
  RD = 'RD',
  RDPT = 'RDPT',
  RG = 'RG',
  RIDGE = 'RIDGE',
  RISE = 'RISE',
  RLE = 'RLE',
  ROW = 'ROW',
  RTE = 'RTE',
  RUE = 'RUE',
  RUN = 'RUN',
  SENT = 'SENT',
  SQ = 'SQ',
  ST = 'ST',
  SUBDIV = 'SUBDIV',
  TERR = 'TERR',
  THICK = 'THICK',
  TLINE = 'TLINE',
  TOWERS = 'TOWERS',
  TRAIL = 'TRAIL',
  TRNABT = 'TRNABT',
  TSSE = 'TSSE',
  VALE = 'VALE',
  VIA = 'VIA',
  VIEW = 'VIEW',
  VILLGE = 'VILLGE',
  VISTA = 'VISTA',
  VOIE = 'VOIE',
  WALK = 'WALK',
  WAY = 'WAY',
  WHARF = 'WHARF',
  WOOD = 'WOOD',
  WYND = 'WYND',
}
