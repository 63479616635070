import { Province } from '../../enums';

export enum FCTProvince {
  AB = 'AB',
  NB = 'NB',
  BC = 'BC',
  NL = 'NL',
  NT = 'NT',
  NS = 'NS',
  NU = 'NU',
  ON = 'ON',
  PE = 'PE',
  QC = 'QC',
  SK = 'SK',
  YT = 'YT',
  MB = 'MB',
}

export const fctProvinceMappings: Record<Province, FCTProvince> = {
  [Province.Alberta]: FCTProvince.AB,
  [Province.British_Columbia]: FCTProvince.BC,
  [Province.Manitoba]: FCTProvince.MB,
  [Province.New_Brunswick]: FCTProvince.NB,
  [Province.Newfoundland_Labrador]: FCTProvince.NL,
  [Province.Northwest_Territories]: FCTProvince.NT,
  [Province.Nova_Scotia]: FCTProvince.NS,
  [Province.Nunavut]: FCTProvince.NU,
  [Province.Ontario]: FCTProvince.ON,
  [Province.Prince_Edward_Island]: FCTProvince.PE,
  [Province.Quebec]: FCTProvince.QC,
  [Province.Saskatchewan]: FCTProvince.SK,
  [Province.Yukon]: FCTProvince.YT,
};
