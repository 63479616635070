export enum ApplicationConditionDocumentStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export enum ApplicationConditionDocumentRejectReasons {
  INCORRECT_DOCUMENT = 'INCORRECT_DOCUMENT',
  TOO_OLD = 'TOO_OLD',
  MISSING_PAGES = 'MISSING_PAGES',
  LOW_QUALITY = 'LOW_QUALITY',
  OTHER = 'OTHER',
}
