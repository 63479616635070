import { MatTab } from '@angular/material/tabs';
import { ChatMessage } from 'src/app/features/chat/chat-message.model';

export class SendMessage {
  static readonly type = '@chat.sendMessage';
  constructor(public content: string, public mentions: string[]) {}
}

export class FetchMessages {
  static readonly type = '@chat.fetchMessages';
  constructor(public applicationId: string) {}
}

export class DeleteMessage {
  static readonly type = '@chat.deleteMessage';
  constructor(public messageId: string) {}
}

export class EditMessage {
  static readonly type = '@chat.editMessage';
  constructor(public message: ChatMessage) {}
}

export class FetchUnreadCount {
  static readonly type = '@chat.fetchUnreadCount';
  constructor(public applicationId: string) {}
}

export class UpdateLastRead {
  static readonly type = '@chat.updateLastRead';
  constructor(public lastRead: Date) {}
}

export class EventAddMessage {
  static readonly type = '@chat.eventAddMessage';
  constructor(public message: ChatMessage) {}
}

export class EventEditMessage {
  static readonly type = '@chat.eventEditMessage';
  constructor(public message: ChatMessage) {}
}

export class EventDeleteMessage {
  static readonly type = '@chat.eventDeleteMessage';
  constructor(public message: ChatMessage) {}
}

export class ToggleChatStatus {
  static readonly type = '@chat.toggleChatStatus';
  constructor(public tab: MatTab, public isCollapsed: boolean) {}
}
