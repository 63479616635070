import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { generateUUID } from 'pubnub';
import { EMPTY, finalize, switchMap, tap, catchError, throwError, map } from 'rxjs';
import { AuthState } from 'src/app/auth/auth.state';
import { LoadingEnd, LoadingStart } from 'src/app/core/loading.state';
import { MortgageApplicationState } from 'src/app/portal/mortgage-application.state';
import { SideBarTabNames } from 'src/app/shared';
import { ChatMessage } from 'src/app/features/chat/chat-message.model';
import { ApplicationResetState } from 'src/app/shared/state.model';
import {
  FetchMessages,
  SendMessage,
  DeleteMessage,
  EditMessage,
  FetchUnreadCount,
  UpdateLastRead,
  EventAddMessage,
  EventEditMessage,
  EventDeleteMessage,
  ToggleChatStatus,
} from './application-chat.actions';
import { ApplicationChatService } from './application-chat.service';

export interface ApplicationChatStateModel {
  messages: ChatMessage[];
  unreadCount: number;
  chatIsOpen: boolean;
  errors: Set<string>;
}

const defaults = {
  messages: [],
  unreadCount: 0,
  chatIsOpen: false,
  errors: new Set<string>(),
};

@State<ApplicationChatStateModel>({
  name: 'applicationChat',
  defaults: { ...defaults },
})
@Injectable()
export class ApplicationChatState {
  @Selector() static messages(state: ApplicationChatStateModel) {
    return state.messages;
  }

  @Selector() static unreadCount(state: ApplicationChatStateModel) {
    return state.unreadCount;
  }

  @Selector() static chatErrors(state: ApplicationChatStateModel) {
    return state.errors;
  }

  @Selector() static isOpen(state: ApplicationChatStateModel) {
    return state.chatIsOpen;
  }

  constructor(private store: Store, private applicationChatService: ApplicationChatService) {}

  @Action(FetchMessages) fetchMessages(
    ctx: StateContext<ApplicationChatStateModel>,
    action: FetchMessages,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    ctx.patchState({ messages: [], unreadCount: 0 });

    if (!action.applicationId) {
      return EMPTY;
    }

    return this.applicationChatService.getMessages(action.applicationId).pipe(
      tap((messages) => {
        ctx.patchState({
          messages: this.sortMessages(
            messages.map((m) => ({
              ...m,
              createdAt: new Date(m.createdAt),
              updatedAt: m.updatedAt ? new Date(m.updatedAt) : undefined,
            })),
          ),
        });
      }),
      switchMap((messages) => {
        return this.applicationChatService
          .markNotificationsAsSeen(
            messages.reduce((prev, curr) => {
              return curr.ChatNotifications
                ? prev.concat(curr.ChatNotifications.map((cn) => cn.id))
                : prev;
            }, [] as string[]),
          )
          .pipe(map(() => messages));
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(SendMessage) sendMessage(
    ctx: StateContext<ApplicationChatStateModel>,
    action: SendMessage,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const state = ctx.getState();
    const currentUserId = this.store.selectSnapshot(AuthState.currentUser)?.user.id;
    const currentAppId = this.store.selectSnapshot(MortgageApplicationState.application)?.id;
    const newMessage = {
      id: generateUUID(),
      userId: currentUserId,
      content: action.content,
      applicationId: currentAppId,
    };

    if (!currentUserId || !currentAppId) {
      return EMPTY;
    }

    ctx.patchState({
      messages: [
        ...state.messages,
        {
          ...newMessage,
          createdAt: new Date(),
        },
      ],
    });

    return this.applicationChatService
      .createMessage(newMessage.id, newMessage.content, newMessage.applicationId, action.mentions)
      .pipe(
        tap((message) => {
          ctx.patchState({
            messages: [
              ...state.messages.filter((m) => m.id !== newMessage.id),
              {
                ...newMessage,
                id: message.id,
                createdAt: new Date(message.createdAt),
              },
            ],
          });
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
        catchError(() => {
          ctx.patchState({
            errors: new Set(state.errors.add(newMessage.id)),
          });
          const MESSAGE_NOT_SENT = $localize`Message could not be sent.`;
          return throwError(() => new Error(MESSAGE_NOT_SENT));
        }),
      );
  }

  @Action(DeleteMessage) deleteMessage(
    ctx: StateContext<ApplicationChatStateModel>,
    action: DeleteMessage,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();
    return this.applicationChatService.deleteMessage(action.messageId).pipe(
      tap(() => {
        ctx.patchState({ messages: [...state.messages.filter((m) => m.id !== action.messageId)] });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(EditMessage) editMessage(
    ctx: StateContext<ApplicationChatStateModel>,
    action: EditMessage,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));
    const state = ctx.getState();
    return this.applicationChatService
      .editMessage(action.message.id, action.message.content, action.message.applicationId)
      .pipe(
        tap((updatedAt) => {
          ctx.patchState({
            messages: this.sortMessages([
              ...state.messages.filter((m) => m.id !== action.message.id),
              { ...action.message, updatedAt: new Date(updatedAt) },
            ]),
          });
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(FetchUnreadCount) fetchUnreadCount(
    ctx: StateContext<ApplicationChatStateModel>,
    action: FetchUnreadCount,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    if (!action.applicationId) {
      return EMPTY;
    }

    return this.applicationChatService.getUnreadCount(action.applicationId).pipe(
      tap((unreadCount) => {
        ctx.patchState({ unreadCount });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(UpdateLastRead) updateLastRead(
    ctx: StateContext<ApplicationChatStateModel>,
    action: UpdateLastRead,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    const currentAppId = this.store.selectSnapshot(MortgageApplicationState.application).id;

    if (!currentAppId) {
      return EMPTY;
    }

    return this.applicationChatService.updateLastRead(currentAppId, action.lastRead).pipe(
      tap(() => {
        ctx.patchState({ unreadCount: 0 });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(EventAddMessage) eventAddMessage(
    ctx: StateContext<ApplicationChatStateModel>,
    action: EventAddMessage,
  ) {
    const state = ctx.getState();
    const seen = state.messages.find((m) => m.id === action.message.id);

    ctx.patchState({
      messages: [
        ...state.messages.filter((m) => m.id !== action.message.id),
        {
          ...action.message,
          createdAt: new Date(action.message.createdAt),
          updatedAt: action.message.updatedAt ? new Date(action.message.updatedAt) : undefined,
        },
      ],
    });

    if (!seen && !state.chatIsOpen) {
      ctx.patchState({ unreadCount: state.unreadCount + 1 });
    }

    if (state.chatIsOpen) {
      ctx.dispatch(new UpdateLastRead(new Date()));
    }
  }

  @Action(EventEditMessage) eventEditMessage(
    ctx: StateContext<ApplicationChatStateModel>,
    action: EventEditMessage,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      messages: this.sortMessages([
        ...state.messages.filter((m) => m.id !== action.message.id),
        {
          ...action.message,
          createdAt: new Date(action.message.createdAt),
          updatedAt: action.message.updatedAt ? new Date(action.message.updatedAt) : undefined,
        },
      ]),
    });
  }

  @Action(EventDeleteMessage) eventDeleteMessage(
    ctx: StateContext<ApplicationChatStateModel>,
    action: EventDeleteMessage,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      messages: this.sortMessages([...state.messages.filter((m) => m.id !== action.message.id)]),
    });
  }

  @Action(ToggleChatStatus) toggleChatStatus(
    ctx: StateContext<ApplicationChatStateModel>,
    action: ToggleChatStatus,
  ) {
    if (action.tab?.textLabel === SideBarTabNames.CHAT && !action.isCollapsed) {
      ctx.patchState({ chatIsOpen: true });
      ctx.dispatch(new UpdateLastRead(new Date()));
      return;
    }

    ctx.patchState({ chatIsOpen: false });
  }

  @Action(ApplicationResetState)
  reset(ctx: StateContext<ApplicationChatStateModel>) {
    ctx.setState({ ...defaults });
  }

  private sortMessages(messages: ChatMessage[]) {
    return messages.sort((a, b) => {
      if (a.createdAt.getTime() < b.createdAt.getTime()) {
        return -1;
      }
      if (a.createdAt.getTime() > b.createdAt.getTime()) {
        return 1;
      }
      return 0;
    });
  }
}
