/* eslint-disable max-len */
import {
  AddressExpandedKey,
  ApplicantKey,
  ApplicationKey,
  ApplicationBrokerCommissionKey,
  ConditionKey,
  ConstructionKey,
  DownPaymentKey,
  FeeKey,
  FinancialAssetKey,
  FinancialLiabilityKey,
  JobKey,
  MortgageKey,
  PropertyAppraisalKey,
  PropertyInsuranceKey,
  PropertyKey,
  PropertyOwnerKey,
  SupportedCustomEntity,
  ClosingInstructionKey,
} from '@fundmoreai/models';

export const TableDisplayNames: Record<SupportedCustomEntity, string | null> = {
  [SupportedCustomEntity.MORTGAGE]: $localize`:@@TableDisplayNames.Mortgage:Mortgages`,
  [SupportedCustomEntity.APPLICATION]: $localize`:@@TableDisplayNames.Application:Applications`,
  [SupportedCustomEntity.PROPERTY]: $localize`:@@TableDisplayNames.Property:Properties`,
  [SupportedCustomEntity.FEE]: $localize`:@@TableDisplayNames.Fee:Fees`,
  [SupportedCustomEntity.DOWN_PAYMENT]: $localize`:@@TableDisplayNames.DownPayment:Down Payments`,
  [SupportedCustomEntity.PROPERTY_INSURANCE]: $localize`:@@TableDisplayNames.Insurance:Insurances`,
  [SupportedCustomEntity.PROPERTY_APPRAISAL]: $localize`:@@TableDisplayNames.Appraisal:Appraisals`,
  [SupportedCustomEntity.JOB]: $localize`:@@TableDisplayNames.Job:Employment`,
  [SupportedCustomEntity.PROPERTY_OWNER]: $localize`:@@TableDisplayNames.Owner:Owners`,
  [SupportedCustomEntity.FINANCIAL_LIABILITY]: $localize`:@@TableDisplayNames.Liability:Financial Liability`,
  [SupportedCustomEntity.CONDITION_OF_APPROVAL]: $localize`:@@TableDisplayNames.Condition:Conditions`,
  [SupportedCustomEntity.CONSTRUCTION_MORTGAGE]: $localize`:@@TableDisplayNames.Construction:Construction Mortgage`,
  [SupportedCustomEntity.APPLICANT]: $localize`:@@TableDisplayNames.Stakeholder:Stakeholder`,
  [SupportedCustomEntity.FINANCIAL_ASSET]: $localize`:@@TableDisplayNames.Asset:Financial Asset`,
  [SupportedCustomEntity.ADDRESS_EXPANDED]: $localize`:@@TableDisplayNames.AddressExpanded:Address Expandeeed`,
  [SupportedCustomEntity.BROKER_COMMISSION]: $localize`:@@TableDisplayNames.ApplicationBrokerCommission:Broker Commission`,
  [SupportedCustomEntity.CLOSING_INSTRUCTION]: $localize`:@@TableDisplayNames.ClosingInstruction:Closing Instruction`,
};

const FeeKeyRecord: Record<FeeKey, string> = {
  [FeeKey.ID]: $localize`:@@CustomFieldsFallback.FEE.ID:Id`,
  [FeeKey.TYPE]: $localize`:@@CustomFieldsFallback.FEE.TYPE:Type`,
  [FeeKey.PAID_BY]: $localize`:@@CustomFieldsFallback.FEE.PAID_BY:Paid by`,
  [FeeKey.PERCENT]: $localize`:@@CustomFieldsFallback.FEE.PERCENT:Percent`,
  [FeeKey.AMOUNT]: $localize`:@@CustomFieldsFallback.FEE.AMOUNT:Amount`,
  [FeeKey.SUBTRACT_FROM_PRINCIPAL]: $localize`:@@CustomFieldsFallback.FEE.SUBTRACT_FROM_PRINCIPAL:Subtract from principal`,
  [FeeKey.INCLUDE_IN_APR]: $localize`:@@CustomFieldsFallback.FEE.INCLUDE_IN_APR:Include in APR`,
  [FeeKey.CAP_FEES_PERCENTAGE]: $localize`:@@CustomFieldsFallback.FEE.CAP_FEES_PERCENTAGE:Cap Fees Percentage`,
  [FeeKey.DESCRIPTION]: $localize`:@@CustomFieldsFallback.FEE.DESCRIPTION:Description`,
};

const DownPaymentKeyRecord: Record<DownPaymentKey, string> = {
  [DownPaymentKey.APPLICATION_ID]: $localize`:@@CustomFieldsFallback.DOWN_PAYMENT.APPLICATION_ID:Application Id`,
  [DownPaymentKey.AMOUNT]: $localize`:@@CustomFieldsFallback.DOWN_PAYMENT.AMOUNT:Amount`,
  [DownPaymentKey.CREATED_AT]: $localize`:@@CustomFieldsFallback.DOWN_PAYMENT.CREATED_AT:Created At`,
  [DownPaymentKey.DESCRIPTION]: $localize`:@@CustomFieldsFallback.DOWN_PAYMENT.DESCRIPTION:Description`,
  [DownPaymentKey.ID]: $localize`:@@CustomFieldsFallback.DOWN_PAYMENT.ID:Id`,
  [DownPaymentKey.SOURCE]: $localize`:@@CustomFieldsFallback.DOWN_PAYMENT.SOURCE:Source`,
  [DownPaymentKey.UPDATED_AT]: $localize`:@@CustomFieldsFallback.DOWN_PAYMENT.UPDATED_AT:Updated at`,
  [DownPaymentKey.FINANCIAL_ASSET_ID]: $localize`:@@CustomFieldsFallback.DOWN_PAYMENT.FINANCIAL_ASSET_ID:Financial Asset Id`,
  [DownPaymentKey.FINANCIAL_ASSET]: $localize`:@@CustomFieldsFallback.DOWN_PAYMENT.FINANCIAL_ASSET:Financial Asset`,
  [DownPaymentKey.EXTERNAL_SEQUENCE_NUMBER]: $localize`:@@CustomFieldsFallback.DOWN_PAYMENT.EXTERNAL_SEQUENCE_NUMBER:External Sequence Number`,
};
const JobKeyRecord: Record<JobKey, string> = {
  [JobKey.IS_CURRENT]: $localize`:@@CustomFieldsFallback.JOB.IS_CURRENT:Current Income`,
  [JobKey.UNABLE_TO_VERIFY]: $localize`:@@CustomFieldsFallback.JOB.UNABLE_TO_VERIFY:Unable to Verify`,
  [JobKey.PROBATION]: $localize`:@@CustomFieldsFallback.JOB.PROBATION:Probation`,
  [JobKey.EMPLOYER_NAME]: $localize`:@@CustomFieldsFallback.JOB.EMPLOYER_NAME:Employer Name`,
  [JobKey.EMPLOYER_ADDRESS]: $localize`:@@CustomFieldsFallback.JOB.EMPLOYER_ADDRESS:Employer Address`,
  [JobKey.EMPLOYER_EMAIL_ADDRESS]: $localize`:@@CustomFieldsFallback.JOB.EMPLOYER_EMAIL_ADDRESS:Employer Email Address`,
  [JobKey.INCOME_PAYMENT_FREQUENCY]: $localize`:@@CustomFieldsFallback.JOB.INCOME_PAYMENT_FREQUENCY:Income Payment Frequency`,
  [JobKey.TIME_AT_JOB_MONTHS]: $localize`:@@CustomFieldsFallback.JOB.TIME_AT_JOB_MONTHS:Time at Job(Months)`,
  [JobKey.OCCUPATION]: $localize`:@@CustomFieldsFallback.JOB.OCCUPATION:Occupation`,
  [JobKey.JOB_TITLE]: $localize`:@@CustomFieldsFallback.JOB.JOB_TITLE:Job Title`,
  [JobKey.EMPLOYMENT_TYPE]: $localize`:@@CustomFieldsFallback.JOB.EMPLOYMENT_TYPE:Employment Type`,
  [JobKey.ANNUAL_INCOME]: $localize`:@@CustomFieldsFallback.JOB.ANNUAL_INCOME:Annual Income`,
  [JobKey.INDUSTRY_SECTOR]: $localize`:@@CustomFieldsFallback.JOB.INDUSTRY_SECTOR:Industry Sector`,
  [JobKey.EMPLOYMENT_START_DATE]: $localize`:@@CustomFieldsFallback.JOB.EMPLOYMENT_START_DATE:Start Date`,
  [JobKey.EMPLOYMENT_END_DATE]: $localize`:@@CustomFieldsFallback.JOB.EMPLOYMENT_END_DATE:End Date`,
  [JobKey.PHONE_NUMBER]: $localize`:@@CustomFieldsFallback.JOB.PHONE_NUMBER:Phone Number`,
  [JobKey.BONUS_OVERTIME_COMMISSIONS]: $localize`:@@CustomFieldsFallback.JOB.BONUS_OVERTIME_COMMISSIONS:Bonus Overtime Commissions`,
  [JobKey.BUSINESS_TYPE]: $localize`:@@CustomFieldsFallback.JOB.BUSINESS_TYPE:Business Type`,
  [JobKey.DESCRIPTION]: $localize`:@@CustomFieldsFallback.JOB.DESCRIPTION:Description`,
  [JobKey.PAYMENT_TYPE]: $localize`:@@CustomFieldsFallback.JOB.PAYMENT_TYPE:Payment Type`,
  [JobKey.TIME_AT_INDUSTRY_MONTHS]: $localize`:@@CustomFieldsFallback.JOB.TIME_AT_INDUSTRY_MONTHS:Time at Industry (Months)`,
  [JobKey.TYPE]: $localize`:@@CustomFieldsFallback.JOB.TYPE:Type`,
  [JobKey.APPLICANT_ID]: ':@@CustomFieldsFallback.JOB.APPLICANT_ID:Stakeholder',
};

export const PropertyOwnerKeyRecord: Record<PropertyOwnerKey, string> = {
  [PropertyOwnerKey.TITLE_NAME]: $localize`:@@CustomFieldsFallback.PROPERTY_OWNER.TITLE_NAME:Title Name`,
  [PropertyOwnerKey.SPOUSE_NAME]: $localize`:@@CustomFieldsFallback.PROPERTY_OWNER.SPOUSE_NAME:Spouse Name`,
  [PropertyOwnerKey.SPOUSAL_CONSENT]: $localize`:@@CustomFieldsFallback.PROPERTY_OWNER.SPOUSAL_CONSENT:Spousal Consent`,
  [PropertyOwnerKey.IS_COMPANY]: $localize`:@@CustomFieldsFallback.PROPERTY_OWNER.IS_COMPANY:Is Company`,
  [PropertyOwnerKey.FIRST_NAME]: $localize`:@@CustomFieldsFallback.PROPERTY_OWNER.FIRST_NAME:First Name`,
  [PropertyOwnerKey.LAST_NAME]: $localize`:@@CustomFieldsFallback.PROPERTY_OWNER.LAST_NAME:Last Name`,
  [PropertyOwnerKey.EMAIL]: $localize`:@@CustomFieldsFallback.PROPERTY_OWNER.EMAIL:Email`,
  [PropertyOwnerKey.PHONE_NUMBER]: $localize`:@@CustomFieldsFallback.PROPERTY_OWNER.PHONE_NUMBER:Phone Number`,
  [PropertyOwnerKey.ADDRESS]: $localize`:@@CustomFieldsFallback.PROPERTY_OWNER.ADDRESS:Address`,
  [PropertyOwnerKey.MARTIAL_STATUS]: $localize`:@@CustomFieldsFallback.PROPERTY_OWNER.MARTIAL_STATUS:Marital Status`,
};

export const PropertyAppraisalKeyRecord: Record<PropertyAppraisalKey, string> = {
  [PropertyAppraisalKey.APPRAISER]: $localize`:@@CustomFieldsFallback.PROPERTY_APPRAISAL.APPRAISER:Appraiser`,
  [PropertyAppraisalKey.APPRAISER_COMPANY]: $localize`:@@CustomFieldsFallback.PROPERTY_APPRAISAL.APPRAISER_COMPANY:Company`,
  [PropertyAppraisalKey.APPRAISAL_DATE]: $localize`:@@CustomFieldsFallback.PROPERTY_APPRAISAL.APPRAISAL_DATE:Date`,
  [PropertyAppraisalKey.APPRAISED_VALUE]: $localize`:@@CustomFieldsFallback.PROPERTY_APPRAISAL.APPRAISED_VALUE:Value`,
  [PropertyAppraisalKey.APPRAISAL_NOTES]: $localize`:@@CustomFieldsFallback.PROPERTY_APPRAISAL.APPRAISAL_NOTES:Appraisal Notes`,
  [PropertyAppraisalKey.APPRAISAL_TYPE]: $localize`:@@CustomFieldsFallback.PROPERTY_APPRAISAL.APPRAISAL_TYPE:Type`,
  [PropertyAppraisalKey.STATUS]: $localize`:@@CustomFieldsFallback.PROPERTY_APPRAISAL.STATUS:Status`,
  [PropertyAppraisalKey.IS_PROPERTY_VALUE]: $localize`:@@CustomFieldsFallback.PROPERTY_APPRAISAL.IS_PROPERTY_VALUE:Is property appraisal`,
  [PropertyAppraisalKey.SOURCE]: $localize`:@@CustomFieldsFallback.PROPERTY_APPRAISAL.SOURCE:Source`,
  [PropertyAppraisalKey.VALUATION_TIME]: $localize`:@@CustomFieldsFallback.PROPERTY_APPRAISAL.VALUATION_TIME:Valuation Time (minutes)`,
  [PropertyAppraisalKey.CONFIDENCE_SCORE]: $localize`:@@CustomFieldsFallback.PROPERTY_APPRAISAL.CONFIDENCE_SCORE:Confidence Score`,
  [PropertyAppraisalKey.FCT_INSURANCE_STATUS]: $localize`:@@CustomFieldsFallback.PROPERTY_APPRAISAL.FCT_INSURANCE_STATUS:Eligibility Status (FCT)`,
};

export const PropertyInsuranceKeyRecord: Record<PropertyInsuranceKey, string> = {
  [PropertyInsuranceKey.PERCENT]: $localize`:@@CustomFieldsFallback.PROPERTY_INSURANCE.PERCENT:Percent`,
  [PropertyInsuranceKey.AMOUNT]: $localize`:@@CustomFieldsFallback.PROPERTY_INSURANCE.AMOUNT:Amount`,
  [PropertyInsuranceKey.TYPE]: $localize`:@@CustomFieldsFallback.PROPERTY_INSURANCE.TYPE:Type`,
  [PropertyInsuranceKey.POLICY_NUMBER]: $localize`:@@CustomFieldsFallback.PROPERTY_INSURANCE.POLICY_NUMBER:Policy Number`,
  [PropertyInsuranceKey.INSURANCE_COMPANY]: $localize`:@@CustomFieldsFallback.PROPERTY_INSURANCE.INSURANCE_COMPANY:Insurance Company`,
  [PropertyInsuranceKey.EXPIRATION_DATE]: $localize`:@@CustomFieldsFallback.PROPERTY_INSURANCE.EXPIRATION_DATE:Expiration Date`,
};

export const FinancialLiabilityKeyRecord: Record<FinancialLiabilityKey, string> = {
  [FinancialLiabilityKey.PAYOFF]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.PAYOFF:Pay From`,
  [FinancialLiabilityKey.DESCRIPTION]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.DESCRIPTION:Description`,
  [FinancialLiabilityKey.LIABILITY]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.LIABILITY:Liability`,
  [FinancialLiabilityKey.SOURCE]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.SOURCE:Source`,
  [FinancialLiabilityKey.CURRENT_TYPE]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.CURRENT_TYPE:Current Type`,
  [FinancialLiabilityKey.VALUE]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.VALUE:Value`,
  [FinancialLiabilityKey.BALANCE]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.BALANCE:Balance`,
  [FinancialLiabilityKey.MONTHLY_PAYMENT]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.MONTHLY_PAYMENT:Monthly Payment`,
  [FinancialLiabilityKey.APPLICANT_ID]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.APPLICANT_ID:Credit Holder`,
  [FinancialLiabilityKey.APPLICANTS]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.APPLICANTS:Credit Holder`,
  [FinancialLiabilityKey.PAYOFF_PAYDOWN]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.PAYOFF_PAYDOWN:Payoff & Paydown`,
  [FinancialLiabilityKey.PAYDOWN_VALUE]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.PAYDOWN_VALUE:Paydown Value`,
  [FinancialLiabilityKey.PAYDOWN_VALUE_LIMIT]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.PAYDOWN_VALUE_LIMIT:Paydown Value Limit`,
  [FinancialLiabilityKey.PAYDOWN_BALANCE]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.PAYDOWN_BALANCE:Paydown Balance`,
  [FinancialLiabilityKey.PAYDOWN_BALANCE_LIMIT]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.PAYDOWN_BALANCE_LIMIT:Paydown Balance Limit`,
  [FinancialLiabilityKey.EXCLUDE_FROM_CALCULATION]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.EXCLUDE_FROM_CALCULATION:Exclude from Calculation`,
  [FinancialLiabilityKey.SECURITY_TYPE]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.SECURITY_TYPE:Security Type`,
  [FinancialLiabilityKey.FINANCIAL_INSTITUTION]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.FINANCIAL_INSTITUTION:Financial Institution`,
  [FinancialLiabilityKey.PAYMENT_FREQUENCY]: $localize`:@@CustomFieldsFallback.FINANCIAL_LIABILITY.PAYMENT_FREQUENCY:Payment Frequency`,
};

export const FinancialAssetKeyRecord: Record<FinancialAssetKey, string> = {
  [FinancialAssetKey.ID]: $localize`:@@CustomFieldsFallback.FINANCIAL_ASSET.ID:ID`,
  [FinancialAssetKey.APPLICATION_ID]: $localize`:@@CustomFieldsFallback.FINANCIAL_ASSET.APPLICATION_ID:Application ID`,
  [FinancialAssetKey.ASSET]: $localize`:@@CustomFieldsFallback.FINANCIAL_ASSET.ASSET:Type`,
  [FinancialAssetKey.CREATED_AT]: $localize`:@@CustomFieldsFallback.FINANCIAL_ASSET.CREATED_AT:Created At`,
  [FinancialAssetKey.DESCRIPTION]: $localize`:@@CustomFieldsFallback.FINANCIAL_ASSET.DESCRIPTION:Description`,
  [FinancialAssetKey.VALUE]: $localize`:@@CustomFieldsFallback.FINANCIAL_ASSET.VALUE:Value`,
  [FinancialAssetKey.UPDATED_AT]: $localize`:@@CustomFieldsFallback.FINANCIAL_ASSET.UPDATED_AT:Updated At`,
  [FinancialAssetKey.USE_AS_DOWN_PAYMENT]: $localize`:@@CustomFieldsFallback.FINANCIAL_ASSET.USE_AS_DOWN_PAYMENT:Use as Down Payment`,
  [FinancialAssetKey.APPLICANT_FINANCIAL_ASSET]: $localize`:@@CustomFieldsFallback.FINANCIAL_ASSET.APPLICANT_FINANCIAL_ASSET:Applicant Financial Assets`,
  [FinancialAssetKey.PURCHASE_PRICE]: $localize`:@@CustomFieldsFallback.FINANCIAL_ASSET.PURCHASE_PRICE:Purchase Price`,
  [FinancialAssetKey.EXTERNAL_SEQUENCE_NUMBER]: $localize`:@@CustomFieldsFallback.FINANCIAL_ASSET.EXTERNAL_SEQUENCE_NUMBER:External Sequence Number`,
  [FinancialAssetKey.EXCLUDE_FROM_CALCULATION]: $localize`:@@CustomFieldsFallback.FINANCIAL_ASSET.EXCLUDE_FROM_CALCULATION:Exclude from Calculation`,
};

export const ConditionKeyRecord: Record<ConditionKey, string> = {
  [ConditionKey.NAME]: $localize`:@@CustomFieldsFallback.CONDITION.NAME:Name`,
  [ConditionKey.STATUS]: $localize`:@@CustomFieldsFallback.CONDITION.STATUS:Status`,
  [ConditionKey.TEXT]: $localize`:@@CustomFieldsFallback.CONDITION.TEXT:Text`,
  [ConditionKey.TEXT_FRENCH]: $localize`:@@CustomFieldsFallback.CONDITION.TEXT_FRENCH:Text (French)`,
  [ConditionKey.DM_DOCUMENT]: $localize`:@@CustomFieldsFallback.CONDITION.DM_DOCUMENT:Linked Document`,
  [ConditionKey.PRODUCTS]: $localize`:@@CustomFieldsFallback.CONDITION.PRODUCTS:Products`,
  [ConditionKey.IS_DEFAULT]: $localize`:@@CustomFieldsFallback.CONDITION.IS_DEFAULT:Default`,
  [ConditionKey.REQUIRED_STAGE]: $localize`:@@CustomFieldsFallback.CONDITION.REQUIRED_STAGE:Required Stage`,
  [ConditionKey.RESPONSIBLE]: $localize`:@@CustomFieldsFallback.CONDITION.RESPONSIBLE:Responsible`,
  [ConditionKey.DOCUMENT]: $localize`:@@CustomFieldsFallback.CONDITION.DOCUMENT:Document`,
  [ConditionKey.APPLICATION_PURPOSE]: $localize`:@@CustomFieldsFallback.CONDITION.APPLICATION_PURPOSE:Application Purpose`,
  [ConditionKey.CONDITION_TYPE]: $localize`:@@CustomFieldsFallback.CONDITION.CONDITION_TYPE:Condition Type`,
  [ConditionKey.INDEX]: $localize`:@@CustomFieldsFallback.CONDITION.INDEX:Index`,
};

export const ConstructionKeyRecord: Record<ConstructionKey, string> = {
  [ConstructionKey.GROSS_MORTGAGE_AMOUNT]: $localize`:@@CustomFieldsFallback.CONSTRUCTION.GROSS_MORTGAGE_AMOUNT:Amount To Be Advanced`,
  [ConstructionKey.LAND_ADVANCE]: $localize`:@@CustomFieldsFallback.CONSTRUCTION.LAND_ADVANCE:Land Advance`,
  [ConstructionKey.NET_LOAN_LESS_ADVANCE]: $localize`:@@CustomFieldsFallback.CONSTRUCTION.NET_LOAN_LESS_ADVANCE:Net Loan Less Advance`,
  [ConstructionKey.LAND_COST]: $localize`:@@CustomFieldsFallback.CONSTRUCTION.LAND_COST:Land Cost`,
  [ConstructionKey.BUILDING_BUDGET]: $localize`:@@CustomFieldsFallback.CONSTRUCTION.BUILDING_BUDGET:Building Budget`,
  [ConstructionKey.SQFT_PROJECT]: $localize`:@@CustomFieldsFallback.CONSTRUCTION.SQFT_PROJECT:SQFT Project`,
  [ConstructionKey.COST_PER_SQFT]: $localize`:@@CustomFieldsFallback.CONSTRUCTION.COST_PER_SQFT:Cost Per SQFT`,
  [ConstructionKey.BUILDER_NAME]: $localize`:@@CustomFieldsFallback.CONSTRUCTION.BUILDER_NAME:Builder Name`,
};

export const PropertyKeyRecord: Record<PropertyKey, string> = {
  [PropertyKey.MORTGAGES]: $localize`:@@CustomFieldsFallback.PROPERTY.MORTGAGES:Mortgages`,
  [PropertyKey.YEAR_BUILT]: $localize`:@@CustomFieldsFallback.PROPERTY.YEAR_BUILT:Year Built`,
  [PropertyKey.ANNUAL_TAXES]: $localize`:@@CustomFieldsFallback.PROPERTY.ANNUAL_TAXES:Taxes`,
  [PropertyKey.APPRAISED_DATE]: $localize`:@@CustomFieldsFallback.PROPERTY.APPRAISED_DATE:Appraised Date`,
  [PropertyKey.APPRAISED_VALUE]: $localize`:@@CustomFieldsFallback.PROPERTY.APPRAISED_VALUE:Appraised Value`,
  [PropertyKey.BLOCK]: $localize`:@@CustomFieldsFallback.PROPERTY.BLOCK:Block`,
  [PropertyKey.CONCESSION_TOWNSHIP]: $localize`:@@CustomFieldsFallback.PROPERTY.CONCESSION_TOWNSHIP:Township`,
  [PropertyKey.CONDO_FEES]: $localize`:@@CustomFieldsFallback.PROPERTY.CONDO_FEES:Condo Fees`,
  [PropertyKey.CONDO_FEES_INCLUDE_HEATING]: $localize`:@@CustomFieldsFallback.PROPERTY.CONDO_FEES_INCLUDE_HEATING:Condo fees include heating`,
  [PropertyKey.CONDO_FEES_PERCENT_IN_CALCULATION]: $localize`:@@CustomFieldsFallback.PROPERTY.CONDO_FEES_PERCENT_IN_CALCULATION:% in calculations`,
  [PropertyKey.CONSTRUCTION_TYPE]: $localize`:@@CustomFieldsFallback.PROPERTY.CONSTRUCTION_TYPE:Construction Type`,
  [PropertyKey.CREATED_AT]: $localize`:@@CustomFieldsFallback.PROPERTY.CREATED_AT:Created At`,
  [PropertyKey.DWELLING_STYLE]: $localize`:@@CustomFieldsFallback.PROPERTY.DWELLING_STYLE:Dwelling Style`,
  [PropertyKey.DWELLING_TYPE]: $localize`:@@CustomFieldsFallback.PROPERTY.DWELLING_TYPE:Dwelling Type`,
  [PropertyKey.ENVIRONMENTAL_HAZARD]: $localize`:@@CustomFieldsFallback.PROPERTY.ENVIRONMENTAL_HAZARD:Environmental Hazard`,
  [PropertyKey.ESTIMATED_VALUE]: $localize`:@@CustomFieldsFallback.PROPERTY.ESTIMATED_VALUE:Estimated Value`,
  [PropertyKey.ESTIMATED_VALUE_DATE]: $localize`:@@CustomFieldsFallback.PROPERTY.ESTIMATED_VALUE_DATE:Estimated Value Date`,
  [PropertyKey.GARAGE_SIZE]: $localize`:@@CustomFieldsFallback.PROPERTY.GARAGE_SIZE:Garage Size`,
  [PropertyKey.GARAGE_TYPE]: $localize`:@@CustomFieldsFallback.PROPERTY.GARAGE_TYPE:Garage Type`,
  [PropertyKey.HEATING_COST]: $localize`:@@CustomFieldsFallback.PROPERTY.HEATING_COST:Heating Cost`,
  [PropertyKey.HEATING_TYPE]: $localize`:@@CustomFieldsFallback.PROPERTY.HEATING_TYPE:Heating Type`,
  [PropertyKey.IMPROVEMENTS]: $localize`:@@CustomFieldsFallback.PROPERTY.IMPROVEMENTS:Improvements`,
  [PropertyKey.INCLUDE_EXPENSES_IN_TDS]: $localize`:@@CustomFieldsFallback.PROPERTY.INCLUDE_EXPENSES_IN_TDS:Include expenses in TDS`,
  [PropertyKey.LIVING_SPACE]: $localize`:@@CustomFieldsFallback.PROPERTY.LIVING_SPACE:Living Space`,
  [PropertyKey.LOT]: $localize`:@@CustomFieldsFallback.PROPERTY.LOT:Lot`,
  [PropertyKey.LOT_SIZE]: $localize`:@@CustomFieldsFallback.PROPERTY.LOT_SIZE:Lot Size`,
  [PropertyKey.NO_OF_UNITS]: $localize`:@@CustomFieldsFallback.PROPERTY.NO_OF_UNITS:Number of Units`,
  [PropertyKey.OCCUPANCY]: $localize`:@@CustomFieldsFallback.PROPERTY.OCCUPANCY:Occupancy`,
  [PropertyKey.PROPERTY_HOLDER]: $localize`:@@CustomFieldsFallback.PROPERTY.PROPERTY_HOLDER:Property Holder`,
  [PropertyKey.PROPERTY_ADDRESS]: $localize`:@@CustomFieldsFallback.PROPERTY.PROPERTY_ADDRESS:Property Address`,
  [PropertyKey.LEGAL_DESCRIPTION]: $localize`:@@CustomFieldsFallback.PROPERTY.LEGAL_DESCRIPTION:Legal Description`,
  [PropertyKey.SHORT_LEGAL_DESCRIPTION]: $localize`:@@CustomFieldsFallback.PROPERTY.SHORT_LEGAL_DESCRIPTION:Short Legal Description`,
  [PropertyKey.PID]: $localize`:@@CustomFieldsFallback.PROPERTY.PID:PID`,
  [PropertyKey.PLAN]: $localize`:@@CustomFieldsFallback.PROPERTY.PLAN:Plan`,
  [PropertyKey.ZONING_TYPE]: $localize`:@@CustomFieldsFallback.PROPERTY.ZONING_TYPE:Zoning Type`,
  [PropertyKey.PROPERTY_TYPE]: $localize`:@@CustomFieldsFallback.PROPERTY.PROPERTY_TYPE:Property Type`,
  [PropertyKey.PURCHASE_DATE]: $localize`:@@CustomFieldsFallback.PROPERTY.PURCHASE_DATE:Purchase Date`,
  [PropertyKey.PURCHASE_PRICE]: $localize`:@@CustomFieldsFallback.PROPERTY.PURCHASE_PRICE:Purchase Price`,
  [PropertyKey.RPE_GENERAL_EXPENSES]: $localize`:@@CustomFieldsFallback.PROPERTY.RPE_GENERAL_EXPENSES:Other`,
  [PropertyKey.RPE_HYDRO]: $localize`:@@CustomFieldsFallback.PROPERTY.RPE_HYDRO:Hydro`,
  [PropertyKey.RPE_INSURANCE]: $localize`:@@CustomFieldsFallback.PROPERTY.RPE_INSURANCE:Insurance`,
  [PropertyKey.RPE_INTEREST_CHARGES]: $localize`:@@CustomFieldsFallback.PROPERTY.RPE_INTEREST_CHARGES:Interest`,
  [PropertyKey.RPE_MANAGEMENT_EXPENSES]: $localize`:@@CustomFieldsFallback.PROPERTY.RPE_MANAGEMENT_EXPENSES:Management`,
  [PropertyKey.RPE_MONTHLY_RENTAL_INCOME]: $localize`:@@CustomFieldsFallback.PROPERTY.RPE_MONTHLY_RENTAL_INCOME:Monthly Rental Income`,
  [PropertyKey.RPE_OFFSET]: $localize`:@@CustomFieldsFallback.PROPERTY.RPE_OFFSET:Offset`,
  [PropertyKey.RPE_RENTAL_OFFSET_OPTION]: $localize`:@@CustomFieldsFallback.PROPERTY.RPE_RENTAL_OFFSET_OPTION:Rental Offset Option`,
  [PropertyKey.RPE_REPAIRS]: $localize`:@@CustomFieldsFallback.PROPERTY.RPE_REPAIRS:Repairs`,
  [PropertyKey.SEWAGE_INFO]: $localize`:@@CustomFieldsFallback.PROPERTY.SEWAGE_INFO:Sewage Info`,
  [PropertyKey.TAXATION_YEAR]: $localize`:@@CustomFieldsFallback.PROPERTY.TAXATION_YEAR:Tax Year`,
  [PropertyKey.TAXES_PAID_BY]: $localize`:@@CustomFieldsFallback.PROPERTY.TAXES_PAID_BY:Paid By`,
  [PropertyKey.TENURE]: $localize`:@@CustomFieldsFallback.PROPERTY.TENURE:Tenure`,
  [PropertyKey.TOTAL_EXPENSES]: $localize`:@@CustomFieldsFallback.PROPERTY.TOTAL_EXPENSES:Total Expenses`,
  [PropertyKey.TYPE]: $localize`:@@CustomFieldsFallback.PROPERTY.TYPE:Property Type`,
  [PropertyKey.UPDATED_AT]: $localize`:@@CustomFieldsFallback.PROPERTY.UPDATED_AT:Updated At`,
  [PropertyKey.VALUE_OF_IMPROVEMENTS]: $localize`:@@CustomFieldsFallback.PROPERTY.VALUE_OF_IMPROVEMENTS:Value of Improvements`,
  [PropertyKey.WATER_TYPE]: $localize`:@@CustomFieldsFallback.PROPERTY.WATER_TYPE:Water Type`,
  [PropertyKey.FOUNDATION_TYPE]: $localize`:@@CustomFieldsFallback.PROPERTY.FOUNDATION_TYPE:Foundation Type`,
  [PropertyKey.CORROBORATIONS]: $localize`:@@CustomFieldsFallback.PROPERTY.CORROBORATIONS:Corroborations`,
  [PropertyKey.NAME_ON_TITLE]: $localize`:@@CustomFieldsFallback.PROPERTY.NAME_ON_TITLE:Name on Title`,
  [PropertyKey.PROPERTY_ADDRESS_EXPANDED]: $localize`:@@CustomFieldsFallback.PROPERTY.PROPERTY_ADDRESS_EXPANDED:Property Address Expanded`,
  [PropertyKey.IS_COLLATERALIZED]: $localize`:@@CustomFieldsFallback.PROPERTY.IS_COLLATERALIZED:Is Collateralized`,
  [PropertyKey.OTHER_SECURITY_CONDITIONS]: $localize`:@@CustomFieldsFallback.PROPERTY.OTHER_SECURITY_CONDITIONS:Other Security Conditions`,
  [PropertyKey.HAS_OTHER_SECURITY_CONDITIONS]: $localize`:@@CustomFieldsFallback.PROPERTY.HAS_OTHER_SECURITY_CONDITIONS:Assignment of Rent / Caveats`,
  [PropertyKey.INCLUDE_IN_CLTV]: $localize`:@@CustomFieldsFallback.PROPERTY.INCLUDE_IN_CLTV:Include in CLTV`,
  [PropertyKey.PROPERTY_VALUE]: $localize`:@@CustomFieldsFallback.PROPERTY.PROPERTY_VALUE:Property Value`,
  [PropertyKey.LOCATION_TIER]: $localize`:@@CustomFieldsFallback.PROPERTY.LOCATION_TIER:Location Tier`,
  [PropertyKey.APPLICANT_PROPERTIES]: $localize`:@@CustomFieldsFallback.PROPERTY.APPLICANT_PROPERTY:Applicant Properties`,
  [PropertyKey.IS_MLS_LISTING]: $localize`:@@CustomFieldsFallback.PROPERTY.IS_MLS_LISTING:Is Mls Listing`,
  [PropertyKey.STREET_NUMBER]: $localize`:@@CustomFieldsFallback.PROPERTY.STREET_NUMBER:Street Number`,
  [PropertyKey.STREET_NUMBER_SUFFIX]: $localize`:@@CustomFieldsFallback.PROPERTY.STREET_NUMBER_SUFFIX:Street No. Suffix`,
  [PropertyKey.STREET_NAME]: $localize`:@@CustomFieldsFallback.PROPERTY.STREET_NAME:Street Name`,
  [PropertyKey.STREET_TYPE]: $localize`:@@CustomFieldsFallback.PROPERTY.STREET_TYPE:Street Type`,
  [PropertyKey.STREET_DIRECTION]: $localize`:@@CustomFieldsFallback.PROPERTY.STREET_DIRECTION:Street Direction`,
  [PropertyKey.UNIT]: $localize`:@@CustomFieldsFallback.PROPERTY.UNIT:Unit, Apt, Suite #`,
  [PropertyKey.UNIT_TYPE]: $localize`:@@CustomFieldsFallback.PROPERTY.UNIT_TYPE:Unit Type`,
  [PropertyKey.CITY]: $localize`:@@CustomFieldsFallback.PROPERTY.CITY:City`,
  [PropertyKey.PROVINCE]: $localize`:@@CustomFieldsFallback.PROPERTY.PROVINCE:Province`,
  [PropertyKey.COUNTRY]: $localize`:@@CustomFieldsFallback.PROPERTY.COUNTRY:Country`,
  [PropertyKey.POSTAL_CODE]: $localize`:@@CustomFieldsFallback.PROPERTY.POSTAL_CODE:Postal Code`,
  [PropertyKey.MLS_NUMBER]: $localize`:@@CustomFieldsFallback.PROPERTY.MLS_NUMBER:MLS Number`,
  [PropertyKey.TAX_INSTALLMENT]: $localize`:@@CustomFieldsFallback.PROPERTY.TAX_INSTALLMENT:Tax Installment`,
  [PropertyKey.TAX_ROLL_NUMBER]: $localize`:@@CustomFieldsFallback.PROPERTY.TAX_ROLL_NUMBER:Tax Roll Number`,
  [PropertyKey.TAX_FIRST_PAYMENT_TYPE]: $localize`:@@CustomFieldsFallback.PROPERTY.TAX_FIRST_PAYMENT_TYPE:Tax First Payment Type`,
  [PropertyKey.SECTION]: $localize`:@@CustomFieldsFallback.PROPERTY.SECTION:Section`,
  [PropertyKey.PROPERTY_NUMBER]: $localize`:@@CustomFieldsFallback.PROPERTY.PROPERTY_NUMBER:Property Number`,
  [PropertyKey.ORIGINAL_PURCHASE_PRICE]: $localize`:@@CustomFieldsFallback.PROPERTY.ORIGINAL_PURCHASE_PRICE:Original Purchase Price`,
  [PropertyKey.MARK_AS_SOLD]: $localize`:@@CustomFieldsFallback.PROPERTY.MARK_AS_SOLD:Property Sold`,
  [PropertyKey.SOLD_ON]: $localize`:@@CustomFieldsFallback.PROPERTY.SOLD_ON:Property Sold On`,
  [PropertyKey.ESTIMATED_TAX_HOLDBACK]: $localize`:@@CustomFieldsFallback.PROPERTY.ESTIMATED_TAX_HOLDBACK:Estimated Tax Holdback`,
  [PropertyKey.IS_ENERGY_EFFICIENT]:
    '@@CustomFieldsFallback.PROPERTY.IS_ENERGY_EFFICIENT:Is Energy Efficient',
  [PropertyKey.BUILDER_NAME]: $localize`:@@CustomFieldsFallback.PROPERTY.BUILDER_NAME:Builder Name`,
  [PropertyKey.IS_PRIMARY_RESIDENCE]: $localize`:@@CustomFieldsFallback.PROPERTY.IS_PRIMARY_RESIDENCE:Is Primary Residence`,
};

export const ApplicantKeyRecord: Record<ApplicantKey, string> = {
  [ApplicantKey.CUSTOMER_TYPE]: $localize`:@@CustomFieldsFallback.APPLICANT.CUSTOMER_TYPE:Customer Type`,
  [ApplicantKey.SURNAME]: $localize`:@@CustomFieldsFallback.APPLICANT.SURNAME:Last Name`,
  [ApplicantKey.NAME]: $localize`:@@CustomFieldsFallback.APPLICANT.NAME:First Name`,
  [ApplicantKey.EMAIL]: $localize`:@@CustomFieldsFallback.APPLICANT.EMAIL:Email`,
  [ApplicantKey.CELL_PHONE]: $localize`:@@CustomFieldsFallback.APPLICANT.CELL_PHONE:Cell Phone`,
  [ApplicantKey.WORK_PHONE]: $localize`:@@CustomFieldsFallback.APPLICANT.WORK_PHONE:Work Phone`,
  [ApplicantKey.HOME_PHONE]: $localize`:@@CustomFieldsFallback.APPLICANT.HOME_PHONE:Home Phone`,
  [ApplicantKey.FAX_NO]: $localize`:@@CustomFieldsFallback.APPLICANT.FAX_NO:Fax No`,
  [ApplicantKey.DOB]: $localize`:@@CustomFieldsFallback.APPLICANT.DOB:Date of Birth`,
  [ApplicantKey.POB]: $localize`:@@CustomFieldsFallback.APPLICANT.POB:Place of Birth`,
  [ApplicantKey.COR]: $localize`:@@CustomFieldsFallback.APPLICANT.COR:Country of Residence`,
  [ApplicantKey.REGISTERED_COUNTRY]: $localize`:@@CustomFieldsFallback.APPLICANT.REGISTERED_COUNTRY:Registered Country`,
  [ApplicantKey.NATIONALITY]: $localize`:@@CustomFieldsFallback.APPLICANT.NATIONALITY:Nationality`,
  [ApplicantKey.MARITAL_STATUS]: $localize`:@@CustomFieldsFallback.APPLICANT.MARITAL_STATUS:Marital Status`,
  [ApplicantKey.DEPENDENTS]: $localize`:@@CustomFieldsFallback.APPLICANT.DEPENDENTS:Dependents`,
  [ApplicantKey.RESIDENTIAL_ADDRESS]: $localize`:@@CustomFieldsFallback.APPLICANT.RESIDENTIAL_ADDRESS:Residential Address`,
  [ApplicantKey.RESIDENTIAL_STATUS_TYPE]: $localize`:@@CustomFieldsFallback.APPLICANT.RESIDENTIAL_STATUS_TYPE:Residential Status Type`,
  [ApplicantKey.TIME_AT_RESIDENCE_MONTHS]: $localize`:@@CustomFieldsFallback.APPLICANT.TIME_AT_RESIDENCE_MONTHS:Time at Residence (months)`,
  [ApplicantKey.BROKERAGE]: $localize`:@@CustomFieldsFallback.APPLICANT.BROKERAGE:Brokerage`,
  [ApplicantKey.IS_FIRST_TIME_HOME_BUYER]: $localize`:@@CustomFieldsFallback.APPLICANT.IS_FIRST_TIME_HOME_BUYER:Is First Time Home Buyer`,
  [ApplicantKey.COMPANY]: $localize`:@@CustomFieldsFallback.APPLICANT.COMPANY:Company`,
  [ApplicantKey.COMPANY_ID]: $localize`:@@CustomFieldsFallback.APPLICANT.COMPANY_ID:Company ID`,
  [ApplicantKey.BUSINESS_NO]: $localize`:@@CustomFieldsFallback.APPLICANT.BUSINESS_NO:Business No`,
  [ApplicantKey.INCORPORATION_NO]: $localize`:@@CustomFieldsFallback.APPLICANT.INCORPORATION_NO:Incorporation No`,
  [ApplicantKey.SIN]: $localize`:@@CustomFieldsFallback.APPLICANT.SIN:SIN`,
  [ApplicantKey.NETWORTH]: $localize`:@@CustomFieldsFallback.APPLICANT.NETWORTH:Networth`,
  [ApplicantKey.OCCUPATION]: $localize`:@@CustomFieldsFallback.APPLICANT.OCCUPATION:Occupation`,
  [ApplicantKey.REFERENCE]: $localize`:@@CustomFieldsFallback.APPLICANT.REFERENCE:Reference`,
  [ApplicantKey.ROLE]: $localize`:@@CustomFieldsFallback.APPLICANT.ROLE:Role`,
  [ApplicantKey.CITIZENSHIP_STATUS]: $localize`:@@CustomFieldsFallback.APPLICANT.CITIZENSHIP_STATUS:Citizenship Status`,
  [ApplicantKey.CREDIT_SCORE]: $localize`:@@CustomFieldsFallback.APPLICANT.CREDIT_SCORE:Credit Score`,
  [ApplicantKey.BNI_SCORE]: $localize`:@@CustomFieldsFallback.APPLICANT.BNI_SCORE:BNI Score`,
  [ApplicantKey.CREDIT_DESCRIPTION]: $localize`:@@CustomFieldsFallback.APPLICANT.CREDIT_DESCRIPTION:Credit Description`,
  [ApplicantKey.IS_UPLOADER]: $localize`:@@CustomFieldsFallback.APPLICANT.IS_UPLOADER:Is Uploader`,
  [ApplicantKey.TEMPLATE_SET_ID]: $localize`:@@CustomFieldsFallback.APPLICANT.TEMPLATE_SET_ID:Template Set Id`,
  [ApplicantKey.FICO_SCORE_OVERRIDE]: $localize`:@@CustomFieldsFallback.APPLICANT.FICO_SCORE_OVERRIDE:FICO Score Override`,
  [ApplicantKey.SALUTATION]: $localize`:@@CustomFieldsFallback.APPLICANT.SALUTATION:Salutation`,
  [ApplicantKey.MIDDLE_NAME]: $localize`:@@CustomFieldsFallback.APPLICANT.MIDDLE_NAME:Middle Name`,
  [ApplicantKey.GENDER]: $localize`:@@CustomFieldsFallback.APPLICANT.GENDER:Gender`,
  [ApplicantKey.LANGUAGE_PREFERENCE]: $localize`:@@CustomFieldsFallback.APPLICANT.LANGUAGE_PREFERENCE:Language Preference`,
  [ApplicantKey.CONSENT_RECEIVED]: $localize`:@@CustomFieldsFallback.APPLICANT.CONSENT_RECEIVED:Consent Received`,
  [ApplicantKey.DOCUMENT_REQUEST_NOTIFICATIONS]: $localize`:@@CustomFieldsFallback.APPLICANT.DOCUMENT_REQUEST_NOTIFICATIONS:Document Request Notifications`,
  [ApplicantKey.RELATIONSHIP_TO_PRIMARY_APPLICANT]: $localize`:@@CustomFieldsFallback.APPLICANT.RELATIONSHIP_TO_PRIMARY_APPLICANT:Relationship to Primary Applicant`,
  [ApplicantKey.FIRM_CODE]: $localize`:@@CustomFieldsFallback.APPLICANT.FIRM_CODE:Firm Code`,
  [ApplicantKey.POLITICALLY_EXPOSED_PERSON_STATUS]: $localize`:@@CustomFieldsFallback.APPLICANT.POLITICALLY_EXPOSED_PERSON_STATUS:Politically Exposed Person`,
  [ApplicantKey.FRAUD_AML_RISK]: $localize`:@@CustomFieldsFallback.APPLICANT.FRAUD_AML_RISK:Fraud/AML Risk`,
  [ApplicantKey.THIRD_PARTY_DETERMINATION]: $localize`:@@CustomFieldsFallback.APPLICANT.THIRD_PARTY_DETERMINATION:Third Party Determination`,
  [ApplicantKey.CLIENT_RISK_SCORE]: $localize`:@@CustomFieldsFallback.APPLICANT.CLIENT_RISK_SCORE:Client Risk Score`,
  [ApplicantKey.REQUEST_NAME_SCREENING]: $localize`:@@CustomFieldsFallback.APPLICANT.REQUEST_NAME_SCREENING:Request Name Screening`,
  [ApplicantKey.CLIENT_IDENTIFIED_IN_NAME_SCREENING]: $localize`:@@CustomFieldsFallback.APPLICANT.CLIENT_IDENTIFIED_IN_NAME_SCREENING:Client Identified In Name Screening`,
  [ApplicantKey.FINANCIAL_INSTITUTION]: $localize`:@@CustomFieldsFallback.APPLICANT.FINANCIAL_INSTITUTION:Financial Institution`,
  [ApplicantKey.TRANSIT_NUMBER]: $localize`:@@CustomFieldsFallback.APPLICANT.TRANSIT_NUMBER:Transit Number`,
  [ApplicantKey.ACCOUNT_NUMBER]: $localize`:@@CustomFieldsFallback.APPLICANT.ACCOUNT_NUMBER:Account Number`,
  [ApplicantKey.ACCOUNT_TYPE]: $localize`:@@CustomFieldsFallback.APPLICANT.ACCOUNT_TYPE:Account Type`,
  [ApplicantKey.CLIENT_ID]: $localize`:@@CustomFieldsFallback.APPLICANT.CLIENT_ID:Client ID`,
  [ApplicantKey.NEW_TO_CANADA]: $localize`:@@CustomFieldsFallback.APPLICANT.NEW_TO_CANADA:New to Canada`,
  [ApplicantKey.CLIENT_SCREENING_COMPLETED]: $localize`:@@CustomFieldsFallback.APPLICANT.CLIENT_SCREENING_COMPLETED:Client Screening Completed`,
  [ApplicantKey.CLIENT_SCREENING_COMPLETED_DATE]: $localize`:@@CustomFieldsFallback.APPLICANT.CLIENT_SCREENING_COMPLETED_DATE:Client Screening Completed Date`,
  [ApplicantKey.CLIENT_LAST_SCREENING_DATE]: $localize`:@@CustomFieldsFallback.APPLICANT.CLIENT_LAST_SCREENING_DATE:Client Last Screening Date`,
  [ApplicantKey.DM_ATTRIBUTE_WARNINGS]: $localize`:@@CustomFieldsFallback.APPLICANT.DM_ATTRIBUTE_WARNINGS:DM Attribute Warnings`,
  [ApplicantKey.INCOME_VERIFICATION]: $localize`:@@CustomFieldsFallback.APPLICANT.INCOME_VERIFICATION:Income Verification`,
  [ApplicantKey.RELATED_CIFS]: $localize`:@@CustomFieldsFallback.APPLICANT.RELATED_CIFS:Related CIFs`,
  [ApplicantKey.EQ_FRAUD_AML_RISK]: $localize`:@@CustomFieldsFallback.APPLICANT.EQ_FRAUD_AML_RISK:Fraud/AML Risk`,
  [ApplicantKey.RESTRICTED_PARTY_MEMBER]:
    ':@@CustomFieldsFallback.APPLICANT.RESTRICTED_PARTY_MEMBER:Restricted Party Member',
  [ApplicantKey.CONNECTED_PARTY]: $localize`:@@CustomFieldsFallback.APPLICANT.CONNECTED_PARTY:Connected Party`,
  [ApplicantKey.CONNECTED_PARTY_CHECK_ERROR]: $localize`:@@CustomFieldsFallback.APPLICANT.CONNECTED_PARTY_CHECK_ERROR:Connected Party Check Error`,
  [ApplicantKey.STAFF_MEMBER]: ':@@CustomFieldsFallback.APPLICANT.STAFF_MEMBER:Staff Member',
  [ApplicantKey.MEMBER_NUMBER]: $localize`:@@CustomFieldsFallback.APPLICANT.MEMBER_NUMBER:Member Number`,
  [ApplicantKey.CREDIT_SCORE_CODE]: $localize`:@@CustomFieldsFallback.APPLICANT.CREDIT_SCORE_CODE:Credit Score Code`,
  [ApplicantKey.IS_STUDENT]: $localize`:@@CustomFieldsFallback.APPLICANT.IS_STUDENT:Is Student`,
  [ApplicantKey.DURATION_IN_CANADA]: $localize`:@@CustomFieldsFallback.APPLICANT.DURATION_IN_CANADA:Duration In Canada`,
  [ApplicantKey.PERMANENT_RESIDENCE_FLAG]: $localize`:@@CustomFieldsFallback.APPLICANT.PERMANENT_RESIDENCE_FLAG:Permanent Residence`,
  [ApplicantKey.PAYMENT_SOURCE]: $localize`:@@CustomFieldsFallback.APPLICANT.PAYMENT_SOURCE:Payment Source`,
  [ApplicantKey.MEMBER_BANKING_NUMBER]: $localize`:@@CustomFieldsFallback.APPLICANT.MEMBER_BANKING_NUMBER:Member Number`,
  [ApplicantKey.SUB_NUMBER]: $localize`:@@CustomFieldsFallback.APPLICANT.SUB_NUMBER:Sub Number`,
  [ApplicantKey.FINANCIAL_INSTITUTION_NAME]: $localize`:@@CustomFieldsFallback.APPLICANT.FINANCIAL_INSTITUTION_NAME:Financial Institution Name`,
  [ApplicantKey.FINANCIAL_INSTITUTION_NUMBER]: $localize`:@@CustomFieldsFallback.APPLICANT.FINANCIAL_INSTITUTION_NUMBER:Financial Institution Number`,
};

export const ApplicationKeyRecord: Record<ApplicationKey, string> = {
  [ApplicationKey.APPLICATION_NOTES]: $localize`:@@CustomFieldsFallback.APPLICATION.APPLICATION_NOTES:Application Notes`,
  [ApplicationKey.NAME]: $localize`:@@CustomFieldsFallback.APPLICATION.NAME:Application Name`,
  [ApplicationKey.APPLICATION_DATE]: $localize`:@@CustomFieldsFallback.APPLICATION.APPLICATION_DATE:Application Date`,
  [ApplicationKey.DOWN_PAYMENT_TOTAL]: $localize`:@@CustomFieldsFallback.APPLICATION.DOWN_PAYMENT_TOTAL:Down Payment Total`,
  [ApplicationKey.DOCS_DUE_DATE]: $localize`:@@CustomFieldsFallback.APPLICATION.DOCS_DUE_DATE:Documents Due Date`,
  [ApplicationKey.DECLINE_COMMENT]: $localize`:@@CustomFieldsFallback.APPLICATION.DECLINE_COMMENT:Decline Comment`,
  [ApplicationKey.PURPOSE]: $localize`:@@CustomFieldsFallback.APPLICATION.PURPOSE:Application Purpose`,
  [ApplicationKey.MORTGAGE_CLASSIFICATION]: $localize`:@@CustomFieldsFallback.APPLICATION.MORTGAGE_CLASSIFICATION:Mortgage Classification`,
  [ApplicationKey.CURRENT_STAGE]: $localize`:@@CustomFieldsFallback.APPLICATION.CURRENT_STAGE:Current Stage`,
  [ApplicationKey.DECLINE_REASON]: $localize`:@@CustomFieldsFallback.APPLICATION.DECLINE_REASON:Decline/Cancel Reason`,
  [ApplicationKey.TYPE]: $localize`:@@CustomFieldsFallback.APPLICATION.TYPE:Application Type`,
  [ApplicationKey.EXTERNAL_APPLICATION_ID]: $localize`:@@CustomFieldsFallback.APPLICATION.EXTERNAL_APPLICATION_ID:External Loan #`,
  [ApplicationKey.IS_COMBO]: $localize`:@@CustomFieldsFallback.APPLICATION.IS_COMBO:Application Is Combo`,
  [ApplicationKey.CLOSING_SERVICE]: $localize`:@@CustomFieldsFallback.APPLICATION.CLOSING_SERVICE:Closing Service`,
  [ApplicationKey.LENDING_TIER]: $localize`:@@CustomFieldsFallback.APPLICATION.LENDING_TIER:Lending Tier`,
  [ApplicationKey.COMMITMENT_ISSUE_DATE]: $localize`:@@CustomFieldsFallback.APPLICATION.COMMITMENT_ISSUE_DATE:Commitment Issue Date`,
  [ApplicationKey.COMMITMENT_EXPIRY_DATE]: $localize`:@@CustomFieldsFallback.APPLICATION.COMMITMENT_EXPIRY_DATE:Commitment Expiry Date`,
  [ApplicationKey.QUICK_CLOSE_FLAG]: $localize`:@@CustomFieldsFallback.APPLICATION.QUICK_CLOSE_FLAG:Quick Close`,
  [ApplicationKey.RATE_HOLD_EXPIRY_DATE]: $localize`:@@CustomFieldsFallback.APPLICATION.RATE_HOLD_EXPIRY_DATE:Rate Hold Expiry Date`,
  [ApplicationKey.SOURCE_OF_BUSINESS]: $localize`:@@CustomFieldsFallback.APPLICATION.SOURCE_OF_BUSINESS:Source of Business`,
  [ApplicationKey.PURPOSE_CODE]: $localize`:@@CustomFieldsFallback.APPLICATION.PURPOSE_CODE:Purpose Code`,
  [ApplicationKey.TOTAL_BUSINESS_CONNECTION]: $localize`:@@CustomFieldsFallback.APPLICATION.TOTAL_BUSINESS_CONNECTION:Total Business Connection`,
  [ApplicationKey.APPLICATION_LOAN_NUMBER]: $localize`:@@CustomFieldsFallback.APPLICATION.APPLICATION_LOAN_NUMBER:Loan Number`,
  [ApplicationKey.FUND_ID]: $localize`:@@fund:Fund`,
};

export const MortgageKeyRecord: Record<MortgageKey, string> = {
  [MortgageKey.AMORTIZATION]: $localize`:@@CustomFieldsFallback.MORTGAGE.AMORTIZATION:Amortization`,
  [MortgageKey.AMOUNT_TO_BE_ADVANCED]: $localize`:@@CustomFieldsFallback.MORTGAGE.AMOUNT_TO_BE_ADVANCED:Amount To Be Advanced`,
  [MortgageKey.APPLICANT_MORTGAGES]: $localize`:@@CustomFieldsFallback.MORTGAGE.APPLICANT_MORTGAGES:Mortgage Holders`,
  [MortgageKey.BALLOON_AMOUNT]: $localize`:@@CustomFieldsFallback.MORTGAGE.BALLOON_AMOUNT:Balloon Amount`,
  [MortgageKey.BORROWER]: $localize`:@@CustomFieldsFallback.MORTGAGE.BORROWER:Borrower`,
  [MortgageKey.CONFORMING]: $localize`:@@CustomFieldsFallback.MORTGAGE.CONFORMING:Conforming`,
  [MortgageKey.PAYOFF]: $localize`:@@CustomFieldsFallback.MORTGAGE.PAYOFF:Pay From`,
  [MortgageKey.PAYOFF_PAYDOWN]: $localize`:@@CustomFieldsFallback.MORTGAGE.PAYOFF_PAYDOWN:Payoff & Paydown`,
  [MortgageKey.CLOSING_DATE]: $localize`:@@CustomFieldsFallback.MORTGAGE.CLOSING_DATE:Closing Date`,
  [MortgageKey.COF_DATE]: $localize`:@@CustomFieldsFallback.MORTGAGE.COF_DATE:Condition of Financing Date`,
  [MortgageKey.COMPOUNDING]: $localize`:@@CustomFieldsFallback.MORTGAGE.COMPOUNDING:Compounding`,
  [MortgageKey.DEADLINE]: $localize`:@@CustomFieldsFallback.MORTGAGE.DEADLINE:Deadline`,
  [MortgageKey.FIRST_REGULAR_PAYMENT_DATE]: $localize`:@@CustomFieldsFallback.MORTGAGE.FIRST_REGULAR_PAYMENT_DATE:First Regular Payment Date`,
  [MortgageKey.FUNDING_DATE]: $localize`:@@CustomFieldsFallback.MORTGAGE.FUNDING_DATE:Funding Date`,
  [MortgageKey.HOLDER]: $localize`:@@CustomFieldsFallback.MORTGAGE.HOLDER:Holder`,
  [MortgageKey.INSURANCE_ACCOUNT_NUMBER]: $localize`:@@CustomFieldsFallback.MORTGAGE.INSURANCE_ACCOUNT_NUMBER:Insurance Account Number`,
  [MortgageKey.INSURANCE_AMOUNT]: $localize`:@@CustomFieldsFallback.MORTGAGE.INSURANCE_AMOUNT:Insurance Amount`,
  [MortgageKey.INSURANCE_PREMIUM]: $localize`:@@CustomFieldsFallback.MORTGAGE.INSURANCE_PREMIUM:Insurance Premium`,
  [MortgageKey.INSURANCE_PREMIUM_PROGRAM]: $localize`:@@CustomFieldsFallback.MORTGAGE.INSURANCE_PREMIUM_PROGRAM:Insurance Premium Program`,
  [MortgageKey.INSURER]: $localize`:@@CustomFieldsFallback.MORTGAGE.INSURER:Insurer`,
  [MortgageKey.INTEREST_ADJUSTMENT_DATE]: $localize`:@@CustomFieldsFallback.MORTGAGE.INTEREST_ADJUSTMENT_DATE:Interest Adjustment Date`,
  [MortgageKey.LENDER]: $localize`:@@CustomFieldsFallback.MORTGAGE.LENDER:Lender`,
  [MortgageKey.LOAN_NUMBER]: $localize`:@@CustomFieldsFallback.MORTGAGE.LOAN_NUMBER:Loan #`,
  [MortgageKey.LOAN_TYPE]: $localize`:@@CustomFieldsFallback.MORTGAGE.LOAN_TYPE:Loan Type`,
  [MortgageKey.MATURITY_DATE]: $localize`:@@CustomFieldsFallback.MORTGAGE.MATURITY_DATE:Maturity Date`,
  [MortgageKey.MONTHLY_PAYMENT]: $localize`:@@CustomFieldsFallback.MORTGAGE.MONTHLY_PAYMENT:Monthly Payment`,
  [MortgageKey.MORTGAGE_BALANCE]: $localize`:@@CustomFieldsFallback.MORTGAGE.MORTGAGE_BALANCE:Mortgage Balance`,
  [MortgageKey.MORTGAGE_NUMBER]: $localize`:@@CustomFieldsFallback.MORTGAGE.MORTGAGE_NUMBER:Mortgage Number`,
  [MortgageKey.MORTGAGE_TYPE]: $localize`:@@CustomFieldsFallback.MORTGAGE.MORTGAGE_TYPE:Mortgage Type`,
  [MortgageKey.NET_RATE]: $localize`:@@CustomFieldsFallback.MORTGAGE.NET_RATE:Net Rate`,
  [MortgageKey.BASE_RATE]: $localize`:@@CustomFieldsFallback.MORTGAGE.BASE_RATE:Base Rate`,
  [MortgageKey.BUYDOWN]: $localize`:@@CustomFieldsFallback.MORTGAGE.BUYDOWN:Buydown`,
  [MortgageKey.DISCOUNT]: $localize`:@@CustomFieldsFallback.MORTGAGE.DISCOUNT:Discount`,
  [MortgageKey.ORIGINAL_NET_RATE]: $localize`:@@CustomFieldsFallback.MORTGAGE.ORIGINAL_NET_RATE:Original Net Rate`,
  [MortgageKey.PAYMENT_FREQUENCY]: $localize`:@@CustomFieldsFallback.MORTGAGE.PAYMENT_FREQUENCY:Payment Frequency`,
  [MortgageKey.PAYOUT_BALANCE]: $localize`:@@CustomFieldsFallback.MORTGAGE.PAYOUT_BALANCE:Pay Out Balance`,
  [MortgageKey.PREPAYMENT_AMOUNT_IN_PAYMENTS]: $localize`:@@CustomFieldsFallback.MORTGAGE.PREPAYMENT_AMOUNT_IN_PAYMENTS:Prepayment Amount in Payments`,
  [MortgageKey.REPAYMENT_TYPE]: $localize`:@@CustomFieldsFallback.MORTGAGE.REPAYMENT_TYPE:Repayment Type`,
  [MortgageKey.PREPAYMENT_TYPE]: $localize`:@@CustomFieldsFallback.MORTGAGE.PREPAYMENT_TYPE:Prepayment Type`,
  [MortgageKey.PREPAYMENT_PENALTY_PERIOD]: $localize`:@@CustomFieldsFallback.MORTGAGE.PREPAYMENT_PENALTY_PERIOD:Prepayment Penalty Period`,
  [MortgageKey.PREPAYMENT_AMOUNT]: $localize`:@@CustomFieldsFallback.MORTGAGE.PREPAYMENT_AMOUNT:Prepayment Amount`,
  [MortgageKey.PST]: $localize`:@@CustomFieldsFallback.MORTGAGE.PST:PST`,
  [MortgageKey.PURCHASE_VALUE]: $localize`:@@CustomFieldsFallback.MORTGAGE.PURCHASE_VALUE:Purchase Value`,
  [MortgageKey.PURPOSE]: $localize`:@@CustomFieldsFallback.MORTGAGE.PURPOSE:Purpose`,
  [MortgageKey.RATE_TYPE]: $localize`:@@CustomFieldsFallback.MORTGAGE.RATE_TYPE:Rate Type`,
  [MortgageKey.TERM]: $localize`:@@CustomFieldsFallback.MORTGAGE.TERM:Term`,
  [MortgageKey.TERM_TYPE]: $localize`:@@CustomFieldsFallback.MORTGAGE.TERM_TYPE:Term Type`,
  [MortgageKey.TOTAL_MORTGAGE_AMOUNT]: $localize`:@@CustomFieldsFallback.MORTGAGE.TOTAL_MORTGAGE_AMOUNT:Loan Amount`,
  [MortgageKey.CASH_ADVANCE]: $localize`:@@CustomFieldsFallback.MORTGAGE.CASH_ADVANCE:Cash Advance`,
  [MortgageKey.APPLICATION_ADVANCED_PRODUCT]: $localize`:@@CustomFieldsFallback.MORTGAGE.APPLICATION_ADVANCED_PRODUCT:Product`,
  [MortgageKey.INCLUDE_PREMIUM_IN_MORTGAGE]: $localize`:@@CustomFieldsFallback.MORTGAGE.INCLUDE_PREMIUM_IN_MORTGAGE:Include Premium in Mortgage`,
  [MortgageKey.ORIGINAL_MI_PROVIDER]: $localize`:@@CustomFieldsFallback.MORTGAGE.ORIGINAL_MI_PROVIDER:Original MI Provider`,
  [MortgageKey.ORIGINAL_MI_NUMBER]: $localize`:@@CustomFieldsFallback.MORTGAGE.ORIGINAL_MI_NUMBER:Original MI Number`,
  [MortgageKey.INSURANCE_PAID_BY]: $localize`:@@CustomFieldsFallback.MORTGAGE.INSURANCE_PAID_BY:Insurance Paid By`,
  [MortgageKey.REFI_BLENDED_AMORTIZATION]: $localize`:@@CustomFieldsFallback.MORTGAGE.REFI_BLENDED_AMORTIZATION:Is Blended Amortization`,
  [MortgageKey.TOTAL_REGULAR_PAYMENT]: $localize`:@@CustomFieldsFallback.MORTGAGE.TOTAL_REGULAR_PAYMENT:Total Regular Payment`,
  [MortgageKey.PAYMENT_AMOUNT]: $localize`:@@CustomFieldsFallback.MORTGAGE.PAYMENT_AMOUNT:Payment Amount`,
  [MortgageKey.INTEREST_ADJUSTMENT_AMOUNT]: $localize`:@@CustomFieldsFallback.MORTGAGE.INTEREST_ADJUSTMENT_AMOUNT:Interest Adjustment Amount`,
  [MortgageKey.PARTNER_LOAN_NAME]: $localize`:@@CustomFieldsFallback.MORTGAGE.PARTNER_LOAN_NAME:Partner Loan Name`,
  [MortgageKey.PARTNER_LOAN_PORTION]: $localize`:@@CustomFieldsFallback.MORTGAGE.PARTNER_LOAN_PORTION:Partner Loan Portion`,
  [MortgageKey.PARTNER_LOAN_EQ_PORTION]: $localize`:@@CustomFieldsFallback.MORTGAGE.PARTNER_LOAN_EQ_PORTION:Partner Loan EQ Portion`,
  [MortgageKey.PARTNER_LOAN_YIELD]: $localize`:@@CustomFieldsFallback.MORTGAGE.PARTNER_LOAN_YIELD:Partner Loan Yield`,
  [MortgageKey.PROGRAM_CODE]: $localize`:@@CustomFieldsFallback.MORTGAGE.PROGRAM_CODE:Program Code`,
  [MortgageKey.PROPERTY_ID]: $localize`:@@CustomFieldsFallback.MORTGAGE.PROPERTY_ID:Property`,
  [MortgageKey.CONSTRUCTION_CASH]: $localize`:@@CustomFieldsFallback.MORTGAGE.CONSTRUCTION_CASH:Construction Cash`,
  [MortgageKey.CONSTRUCTION_COST]: $localize`:@@CustomFieldsFallback.MORTGAGE.CONSTRUCTION_COST:Construction Cost`,
  [MortgageKey.TYPE_OF_CLOSING_INSTRUCTION]: $localize`:@@CustomFieldsFallback.MORTGAGE.TYPE_OF_CLOSING_INSTRUCTION:Type of Closing`,
  [MortgageKey.REGISTRATION_NUMBER]: $localize`:@@CustomFieldsFallback.MORTGAGE.REGISTRATION_NUMBER:Registration Number`,
  [MortgageKey.COLLATERAL_AMOUNT]: $localize`:@@CustomFieldsFallback.MORTGAGE.COLLATERAL_AMOUNT:Collateral Amount ($)`,
  [MortgageKey.SECURITY_TYPE]: $localize`:@@CustomFieldsFallback.MORTGAGE.SECURITY_TYPE:Security Type`,
  [MortgageKey.SECURITY]: $localize`:@@CustomFieldsFallback.MORTGAGE.SECURITY:Security`,
  [MortgageKey.BORROWING_LIMIT]: $localize`:@@CustomFieldsFallback.MORTGAGE.BORROWING_LIMIT:Borrowing Limit`,
  [MortgageKey.MORTGAGE_PRODUCT_ID]: $localize`:@@CustomFieldsFallback.MORTGAGE.MORTGAGE_PRODUCT_ID:Product ID`,
  [MortgageKey.RATE_HOLD_DATE]: $localize`:@@CustomFieldsFallback.MORTGAGE.RATE_HOLD_DATE:Rate Hold`,
  [MortgageKey.STATUS]: $localize`:@@CustomFieldsFallback.MORTGAGE.STATUS:Status`,
  [MortgageKey.STATE]: $localize`:@@CustomFieldsFallback.MORTGAGE.STATE:State`,
  [MortgageKey.STATE_CHANGE_COMMENT]: $localize`:@@CustomFieldsFallback.MORTGAGE.STATE_CHANGE_COMMENT:State Change Comment`,
  [MortgageKey.STATE_CHANGE_DATE]: $localize`:@@CustomFieldsFallback.MORTGAGE.CHANGE_DATE:State Change Date`,
  [MortgageKey.INCREMENTS]: $localize`:@@CustomFieldsFallback.MORTGAGE.INCREMENTS:Increments`,
  [MortgageKey.INCREMENT_TOTAL]: $localize`:@@CustomFieldsFallback.MORTGAGE.INCREMENT_TOTAL:Increments (%)`,
  [MortgageKey.CUSTOM_INCREMENT_AMOUNT]: $localize`:@@CustomFieldsFallback.MORTGAGE.INCREMENTS_PERCENTAGE:Custom Increment (%)`,
  [MortgageKey.CUSTOM_INCREMENT_NOTE]: $localize`:@@CustomFieldsFallback.MORTGAGE.INCREMENTS_NOTES:Notes`,
  [MortgageKey.PRODUCT_MEMBER_NUMBER]: $localize`:@@CustomFieldsFallback.MORTGAGE.PRODUCT_MEMBER_NUMBER:Product Member Number`,
};

export const AddressExpandedKeyRecord: Record<AddressExpandedKey, string> = {
  [AddressExpandedKey.ADDRESS]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.INCLUDE_PREMIUM_IN_MORTGAGE:Address`,
  [AddressExpandedKey.CITY]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.CITY:City`,
  [AddressExpandedKey.PROVINCE]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.PROVINCE:Province`,
  [AddressExpandedKey.COUNTRY]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.COUNTRY:Country`,
  [AddressExpandedKey.LAT]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.LAT:Latitude`,
  [AddressExpandedKey.LONG]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.LONG:Longitude`,
  [AddressExpandedKey.STREET_NUMBER]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.STREET_NUMBER:Street Number`,
  [AddressExpandedKey.STREET_NUMBER_SUFFIX]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.STREET_NUMBER_SUFFIX:Street No. Suffix`,
  [AddressExpandedKey.STREET_NAME]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.STREET_NAME:Street Name`,
  [AddressExpandedKey.STREET_TYPE]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.STREET_TYPE:Street Type`,
  [AddressExpandedKey.STREET_DIRECTION]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.STREET_DIRECTION:Street Direction`,
  [AddressExpandedKey.UNIT]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.UNIT:Unit, Apt, Suite #`,
  [AddressExpandedKey.POSTAL_CODE]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.POSTAL_CODE:Postal Code`,
  [AddressExpandedKey.TYPE]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.TYPE:Type`,
  [AddressExpandedKey.RESIDENTIAL_TYPE]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.RESIDENTIAL_TYPE:Residential Type`,
  [AddressExpandedKey.RESIDENTIAL_STATUS_TYPE]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.RESIDENTIAL_STATUS_TYPE:Residential Status Type`,
  [AddressExpandedKey.TIME_AT_RESIDENCE_MONTHS]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.TIME_AT_RESIDENCE_MONTHS:Time At Residence (months)`,
  [AddressExpandedKey.RENT]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.RENT:Rent`,
  [AddressExpandedKey.INCLUDE_RENT_IN_CALCULATIONS]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.INCLUDE_RENT_IN_CALCULATIONS:Include rent in calculations`,
  [AddressExpandedKey.UNIT_TYPE]: $localize`:@@CustomFieldsFallback.ADDRESS_EXPANDED.UNIT_TYPE:Unit Type`,
};

export const ApplicationBrokerCommissionRecord: Record<ApplicationBrokerCommissionKey, string> = {
  [ApplicationBrokerCommissionKey.NEW_FUNDS]: $localize`:@@CustomFieldsFallback.BROKER_COMMISSION.NEW_FUNDS:New Funds ($)`,
  [ApplicationBrokerCommissionKey.STANDARD_COMMISSION]: $localize`:@@CustomFieldsFallback.BROKER_COMMISSION.STANDARD_COMMISSION:Standard Commission (%)`,
  [ApplicationBrokerCommissionKey.LESS_BUYDOWN]: $localize`:@@CustomFieldsFallback.BROKER_COMMISSION.LESS_BUYDOWN:Less Buydown (%)`,
  [ApplicationBrokerCommissionKey.FINAL_COMMISSION]: $localize`:@@CustomFieldsFallback.BROKER_COMMISSION.FINAL_COMMISSION:Final Commission (%)`,
  [ApplicationBrokerCommissionKey.BROKER_REDUCTION_FEE]: $localize`:@@CustomFieldsFallback.BROKER_COMMISSION.BROKER_REDUCTION_FEE:Broker Reduction Fee ($)`,
  [ApplicationBrokerCommissionKey.TOTAL_FUNDS]: $localize`:@@CustomFieldsFallback.BROKER_COMMISSION.TOTAL_FUNDS:Total ($)`,
};

export const ClosingInstructionKeyRecord: Record<ClosingInstructionKey, string> = {
  [ClosingInstructionKey.TYPE_OF_CLOSING_INSTRUCTION]: $localize`:@@CustomFieldsFallback.CLOSING_INSTRUCTION.TYPE_OF_CLOSING_INSTRUCTION:Type of Closing`,
  [ClosingInstructionKey.REGISTRATION_NUMBER]: $localize`:@@CustomFieldsFallback.CLOSING_INSTRUCTION.REGISTRATION_NUMBER:Registration Number`,
  [ClosingInstructionKey.COLLATERAL_AMOUNT]: $localize`:@@CustomFieldsFallback.CLOSING_INSTRUCTION.COLLATERAL_AMOUNT:Collateral Amount ($)`,
};

export const CustomEntityRecord: Record<SupportedCustomEntity, { [key: string]: string }> = {
  [SupportedCustomEntity.FEE]: FeeKeyRecord,
  [SupportedCustomEntity.DOWN_PAYMENT]: DownPaymentKeyRecord,
  [SupportedCustomEntity.JOB]: JobKeyRecord,
  [SupportedCustomEntity.PROPERTY_OWNER]: PropertyOwnerKeyRecord,
  [SupportedCustomEntity.PROPERTY_APPRAISAL]: PropertyAppraisalKeyRecord,
  [SupportedCustomEntity.PROPERTY_INSURANCE]: PropertyInsuranceKeyRecord,
  [SupportedCustomEntity.FINANCIAL_LIABILITY]: FinancialLiabilityKeyRecord,
  [SupportedCustomEntity.CONDITION_OF_APPROVAL]: ConditionKeyRecord,
  [SupportedCustomEntity.CONSTRUCTION_MORTGAGE]: ConstructionKeyRecord,
  [SupportedCustomEntity.PROPERTY]: PropertyKeyRecord,
  [SupportedCustomEntity.APPLICANT]: ApplicantKeyRecord,
  [SupportedCustomEntity.APPLICATION]: ApplicationKeyRecord,
  [SupportedCustomEntity.MORTGAGE]: MortgageKeyRecord,
  [SupportedCustomEntity.FINANCIAL_ASSET]: FinancialAssetKeyRecord,
  [SupportedCustomEntity.ADDRESS_EXPANDED]: AddressExpandedKeyRecord,
  [SupportedCustomEntity.BROKER_COMMISSION]: ApplicationBrokerCommissionRecord,
  [SupportedCustomEntity.CLOSING_INSTRUCTION]: ClosingInstructionKeyRecord,
};
