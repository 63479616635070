import { MMSDealSource } from '../../enums';
import { MMSBridgeLoan } from '../bridgeLoan';
import { MMSClosingConfirmation } from '../closingConfirmation';
import { MMSContact } from '../contact';
import { MMSGuarantor } from '../guarantor';
import { MMSInterestRate } from '../interestRate';
import { MMSLawyer } from '../lawyer';
import { MMSMortgage } from '../mortgage';
import { MMSMortgageDates } from '../mortgageDates';
import { MMSMortgagor } from '../mortgagor';
import { MMSPayment } from '../payment';
import { MMSProperty } from '../property';
import { MMSSaleProperty } from '../saleProperty';

export interface MMSRequest {
  dealSource: MMSDealSource;
  lenderReferenceNumber: string;
  fctURN?: string; // Required only for UPDATE
  lenderName: string;
  lenderCode: string;
  lenderRemarks: string;
  lenderContacts: MMSContact[]; // max 4
  brokerContact: MMSContact[]; // max 4
  mortgagors: MMSMortgagor[];
  guarantors: MMSGuarantor[];
  property: MMSProperty;
  lawyer: MMSLawyer;
  mortgage: MMSMortgage;
  payment?: MMSPayment;
  interestRate?: MMSInterestRate;
  mortgageDates?: MMSMortgageDates;
  bridgeLoan?: MMSBridgeLoan;
  saleProperty?: MMSSaleProperty;
  closingConfirmation: MMSClosingConfirmation;
}
