import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { PropertyInsurancesService } from './property-insurances.service';
import { PropertyInsurance } from '../../shared/model';
import { LoadingEnd, LoadingStart } from '../../core/loading.state';
import {
  CreateInsurance,
  FetchInsurances,
  RemoveInsurance,
  UpdateInsurance,
} from './property-insurances.actions';
import { ApplicationResetState } from 'src/app/shared/state.model';

export interface PropertyInsuranceStateModel {
  propertyInsurances: {
    [key: string]: PropertyInsurance;
  };
}

@State<PropertyInsuranceStateModel>({
  name: 'propertyInsurances',
  defaults: { propertyInsurances: {} },
})
@Injectable()
export class PropertyInsuranceState {
  constructor(private propertyInsurancesService: PropertyInsurancesService) {}

  static insurance(insuranceId: string) {
    return createSelector(
      [PropertyInsuranceState.propertyInsurances],
      (insurances: { [key: string]: PropertyInsurance }): PropertyInsurance | undefined => {
        return insurances[insuranceId];
      },
    );
  }

  @Selector()
  static propertyInsurances(state: PropertyInsuranceStateModel) {
    return state.propertyInsurances;
  }

  @Selector([PropertyInsuranceState.propertyInsurances])
  static applicationInsurancesList(propertyInsurances: { [key: string]: PropertyInsurance }) {
    return Object.values(propertyInsurances);
  }

  @Action(FetchInsurances)
  fetchInsurances(
    ctx: StateContext<PropertyInsuranceStateModel>,
    { applicationId }: FetchInsurances,
  ): Observable<PropertyInsurance[]> {
    ctx.patchState({ propertyInsurances: {} });

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.propertyInsurancesService.fetchApplicationInsurances(applicationId).pipe(
      tap((insurances) => {
        const insuranceEntities = insurances.reduce(
          (entities: { [key: string]: PropertyInsurance }, insurance) => {
            entities[insurance.id] = insurance;

            return entities;
          },
          {},
        );

        ctx.patchState({ propertyInsurances: insuranceEntities });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(CreateInsurance)
  createInsurance(
    ctx: StateContext<PropertyInsuranceStateModel>,
    { propertyId, applicationId, insuranceToCreate }: CreateInsurance,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.propertyInsurancesService
      .postInsurance(propertyId, applicationId, insuranceToCreate)
      .pipe(
        tap((newInsurance) => {
          const state = ctx.getState();

          ctx.patchState({ propertyInsurances: { ...state.propertyInsurances, newInsurance } });
        }),
        finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
      );
  }

  @Action(UpdateInsurance)
  updateInsurance(
    ctx: StateContext<PropertyInsuranceStateModel>,
    action: UpdateInsurance,
  ): Observable<void | PropertyInsurance> {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.propertyInsurancesService.patchInsurance(action.insurance).pipe(
      tap((insurance) => {
        const state = ctx.getState();

        ctx.patchState({
          propertyInsurances: {
            ...state.propertyInsurances,
            [action.insurance.id]: insurance,
          },
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(RemoveInsurance)
  removeInsurance(
    ctx: StateContext<PropertyInsuranceStateModel>,
    { propertyId, insurance }: RemoveInsurance,
  ) {
    if (!insurance.id) {
      const INSURANCE_ID_MISSING = $localize`Insurance id is missing`;

      throw new Error(INSURANCE_ID_MISSING);
    }

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.propertyInsurancesService.deleteInsurance(propertyId, insurance.id).pipe(
      tap(() => {
        const state = ctx.getState();
        const propertyInsurances = { ...state.propertyInsurances };

        delete propertyInsurances[insurance.id];

        ctx.patchState({
          propertyInsurances,
        });
      }),
      finalize(() => ctx.dispatch(new LoadingEnd(this.constructor.name))),
    );
  }

  @Action(ApplicationResetState) applicationReset(ctx: StateContext<PropertyInsuranceStateModel>) {
    ctx.patchState({ propertyInsurances: {} });
  }
}
