import {
  FlipAmountType,
  FlipCostType,
  FlipDefaultsCostItemType,
  FlipPaymentType,
} from '@fundmoreai/models';
import {
  FlipModuleDefault,
  RenovationSchedule,
  flipModuleDefaults,
} from './flip-defaults';

interface Property {
  purchasePrice?: number | undefined;
  province?: string | undefined;
  afterRepairValue?: number | undefined;
}

interface FlipDefaultsModel {
  renovationSchedules: RenovationSchedule[];
  flipCosts: {
    type: FlipCostType;
    paymentType: FlipPaymentType;
    item: string;
    itemType: FlipDefaultsCostItemType;
    amount: number;
  }[];
}

export class FlipDefaults {
  property: Property;
  flipDefaults: FlipModuleDefault;

  constructor(property: Property) {
    this.property = property;
    this.flipDefaults = flipModuleDefaults[property.province];
    if (!this.flipDefaults) {
      this.flipDefaults = flipModuleDefaults.default;
    }
  }

  getModel(): FlipDefaultsModel {
    const defaults = {
      flipCosts: [],
      renovationSchedules: this.flipDefaults.renovationSchedules,
    };
    for (const cost of this.flipDefaults.flipCosts) {
      const flipCost = {
        type: cost.type,
        paymentType: cost.paymentType,
        item: cost.item,
        itemType: cost.itemType,
        amount:
          cost.amountType === FlipAmountType.COMPUTED
            ? cost.getAmount(this.property)
            : cost.amount,
      };
      defaults.flipCosts.push(flipCost);
    }
    return defaults;
  }

  static getFlipDefaults(property: Property) {
    const flipDefaults = new FlipDefaults(property);
    return flipDefaults.getModel();
  }
}
