<div mat-dialog-title>
  <h3 class="title">
    <span i18n>Generate a note using AI</span>
  </h3>
  <button type="button" mat-icon-button [disableRipple]="false" (click)="onDismiss()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<mat-progress-bar class="loading" *ngIf="loading$ | async" mode="indeterminate"></mat-progress-bar>

<mat-dialog-content class="content">
  <div class="d-flex justify-content-between">
    <div class="d-flex align-items-center">
      <span class="pr-8">
        <ng-container i18n>Max Length</ng-container> ({{ maxTokens }} words):
      </span>
      <mat-slider min="100" max="1000" step="10" [disabled]="loading$ | async">
        <input matSliderThumb [(ngModel)]="maxTokens" />
      </mat-slider>
    </div>

    <div class="d-flex align-items-center justify-content-right">
      <span class="pr-8">
        <ng-container i18n>Creativity</ng-container> ({{ temperatureText[temperature] }}):
      </span>
      <mat-slider min="0.1" max="0.9" step="0.4" [disabled]="loading$ | async">
        <input matSliderThumb [(ngModel)]="temperature" />
      </mat-slider>
    </div>
  </div>
  <div class="w-100 position-relative note-content">
    <div
      class="note h-100 w-100 overflow-auto background-slightly-muted color-dark note-content"
      [ngClass]="{ 'background-muted': loading$ | async }"
      *ngIf="{ content: generatedAINote$ | async } as generatedAINote">
      <div *ngIf="generatedAINote.content" class="white-space-pre-wrap p-8">
        {{ generatedAINote.content }}
      </div>
      <div class="p-8 text-muted" *ngIf="!generatedAINote.content && (loading$ | async) === false">
        <ng-container i18n>No note to display.</ng-container>
      </div>
    </div>
    <div class="loading-placeholder" *ngIf="loading$ | async">
      <ng-container i18n>Generating new note (this can take up to 30 seconds)...</ng-container>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="onDismiss()">Cancel</button>

  <button
    mat-raised-button
    color="accent"
    class="ml-8"
    (click)="generateAINote()"
    [disableRipple]="true"
    [disabled]="loading$ | async">
    <span i18n>Generate Again</span>
  </button>

  <button
    name="dialog-confirm"
    mat-raised-button
    color="primary"
    class="ml-8"
    (click)="acceptNote()"
    [disableRipple]="true"
    [disabled]="loading$ | async">
    <span i18n>Accept</span>
  </button>
</mat-dialog-actions>
