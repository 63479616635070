import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { Fund } from '../shared/model';

@Injectable({
  providedIn: 'root',
})
export class FundsService {
  constructor(private http: HttpClient) {}

  getFunds(): Observable<Fund[]> {
    return this.http.get<Fund[]>(`${environment.api_url}/funds`);
  }

  addFund(fund: Partial<Fund>) {
    return this.http.post<Fund>(`${environment.api_url}/funds`, fund);
  }

  patchFund(fundId: string, fund: Partial<Fund>): Observable<void> {
    return this.http.patch<void>(`${environment.api_url}/funds/${fundId}`, fund);
  }

  deleteFund(fundId: string) {
    return this.http.delete<void>(`${environment.api_url}/funds/${fundId}`);
  }

  setDefaultFund(fundId: string) {
    return this.http.post<Fund>(`${environment.api_url}/funds/setDefault`, { fundId: fundId });
  }
}
