import { ApplicationStatus } from './application';
import { ApplicationCreateProductsState, ApplicationCreditCardFulfillState } from './enums';

export enum MCUIntegrationFields {
  CONFORMING = 'conforming',
  PURPOSE_CODE = 'purposeCode',
  PAYMENT_SOURCE = 'paymentSource',
  SECURITY = 'security',
}

export enum MCUPaymentSourceFields {
  PAYMENT_SOURCE = 'paymentSource',
  MEMBER_BANKING_NUMBER = 'memberBankingNumber',
  SUB_NUMBER = 'subNumber',
}

export enum MCUSecurityFields {
  SECURITY = 'security',
  SECURITY_TYPE = 'securityType',
}

export interface MCUGatewayError {
  message: string;
  type?: string;
  data?: {
    message: string;
  };
}

export type MCUCreateProductsError = MCUGatewayError;
export type MCUCreditCardFulfillError = MCUGatewayError;

export interface MCUCreateProductsResponse {
  createProductsState: ApplicationCreateProductsState;
  error?: MCUCreateProductsError;
}

export interface MCUCreditCardFulfillResponse {
  creditCardFulfillState: ApplicationCreditCardFulfillState;
  applicationStatus?: ApplicationStatus;
  error?: MCUCreditCardFulfillError;
}
