import { ApplicationStage, MoveStageModel } from '@fundmoreai/models';

export enum AuthResultStatus {
  NEW_PASSWORD_REQUIRED = 'newPasswordRequired',
  SUCCESS = 'success',
  MFA_REQUIRED = 'mfaRequired',
  TOTP_REQUIRED = 'totpRequired',
  CUSTOM_CHALLENGE = 'customChallenge',
  MFA_SETUP = 'mfaSetup',
  SELECT_MFA_TYPE = 'selectMFAType',
  INPUT_VERIFICATION_CODE = 'inputVerificationCode',
  ERROR = 'error',
  FORGOT_PASSWORD = 'forgotPassword',
  LOGIN = 'login',
}

export class AuthResult {
  status: AuthResultStatus;
  data: AuthResultData;
  returnUrl?: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(status: AuthResultStatus, data: any = {}, returnUrl?: string) {
    this.status = status;
    this.data = data;
    this.returnUrl = returnUrl;
  }
}

export interface AuthResultData {
  code: AuthResultDataCode;
  name: string;
}

export enum AuthResultDataCode {
  RESET_PASSWORD = 'PasswordResetRequiredException',
}

export interface GeneralAbstractPermissions {
  general: {
    canUpdateOwnOutOfOfficeDetails: boolean;
    canViewReports: boolean;
    canManageSystemConfiguration: boolean;
    editUserOutOfOffice: boolean;
    editUserPreferences: boolean;
    generateDocumentsOnApplication: boolean;
    manageNotes: { read: boolean; create: boolean; delete: boolean; update: boolean };
    userManagement: { create: boolean; update: boolean; delete: boolean };
    userSettings: { read: boolean; update: boolean };
    manageBrokers: boolean;
  };
  applicationList: {
    hierarchyUserIds: string[];
    canEdit: boolean;
    canEditAssigned: boolean;
    canEditInClosedStage: boolean;
    canEditInNewStage: boolean;
    canEditOwn: boolean;
    canFund: boolean;
    canLockApplication: boolean;
    canMoveStage: MoveStageModel[];
    canBypassTasks: boolean;
    canRevertDecision: boolean;
    listApplicationsInPipeline: boolean;
    listUnassignedApplicationsInPipeline: boolean;
    shouldConfirmApplicationStageMove: boolean;

    canArchiveApplication: boolean;
    canCreate: boolean;
    canDecline: boolean;
    canDeleteApplication: { stage: ApplicationStage[] }[];

    canEditPriority: boolean;
  };
  computed: {
    canDeleteApplicationInStages: ApplicationStage[];
    canMergeApplications: { [key: string]: boolean };
    canManageDuplicates: { [key: string]: boolean };
  };
}
