export const CanadaGuarantyBooleanFlag = {
  Y: 'Y',
  N: 'N',
};

export const canadaGuarantyBooleanFlag = {
  [CanadaGuarantyBooleanFlag.Y]: 'Yes',
  [CanadaGuarantyBooleanFlag.N]: 'No',
};

export enum CanadaGuarantyProgressAdvanceType {
  HomeBuilder = 'H',
  Contractor = 'C',
  SelfBuilt = 'S',
}

export const canadaGuarantyProgressAdvanceTypeDescription: Record<
  CanadaGuarantyProgressAdvanceType,
  string
> = {
  [CanadaGuarantyProgressAdvanceType.HomeBuilder]: 'Home Builder (Residential)',
  [CanadaGuarantyProgressAdvanceType.Contractor]: 'Contractor',
  [CanadaGuarantyProgressAdvanceType.SelfBuilt]: 'Self-Built',
};

export enum CanadaGuarantyInsuranceIndicator {
  I = 'I',
  U = 'U',
}

export const canadaGuarantyInsuranceIndicatorDescription: Record<
  CanadaGuarantyInsuranceIndicator,
  string
> = {
  [CanadaGuarantyInsuranceIndicator.I]: 'Insurance',
  [CanadaGuarantyInsuranceIndicator.U]: 'Low-Ratio Uninsured',
};

export enum CanadaGuarantyInsuranceProductIndicator {
  Insurance = 'INSD',
}

export enum CanadaGuarantySpecialProgramCodes {
  Standard = 1,
  LowDoc = 4,
}

export const canadaGuarantySpecialProgramCodesDescription: Record<
  CanadaGuarantySpecialProgramCodes,
  string
> = {
  [CanadaGuarantySpecialProgramCodes.Standard]: 'Standard',
  [CanadaGuarantySpecialProgramCodes.LowDoc]: 'Low Doc',
};

export enum CanadaGuarantyServiceType {
  CompleteFullHighRatio = 'F',
  NormalStandardLowRatio = 'S',
}

export interface CGFieldViewModel {
  field: string;
  category: string;
  required: boolean;
  name: string;
  isMissing: boolean;
  value: string | boolean | number;
  options?: string;
}

export interface CGInsuranceRequest extends CGApplicationModel {
  viewModels: CGFieldViewModel[];
}

export interface CGApplicationModel extends CGApplicationConfirm {
  comment: string | null;
}

export interface CGApplicationConfirm {
  codProgressAdv: boolean;
  codInsuredPAType: CanadaGuarantyProgressAdvanceType | undefined;
  insuranceIndicator: CanadaGuarantyInsuranceIndicator;
  codSpecialProg: CanadaGuarantySpecialProgramCodes;
  indPAInspection: boolean | null;
  indForceUWReview: boolean;
}

export type CGApplicationAdd = CGApplicationModel;

export type CGApplicationUpdate = CGApplicationModel;
