import { Injectable, NgZone } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { ConditionTableKey, ConditionTableKeyRecord, ManageConditionsModel } from './model';
import { ManageConditionsService } from './condition-manage.service';
import { Condition } from '../../shared/documents';
import { DialogsComponent } from '../../application/sidebar/dialogs/dialogs.component';
import { EMPTY } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ResetState } from 'src/app/shared';
import {
  AddCondition,
  DeleteCondition,
  FetchConditions,
  UpdateCondition,
  PublishCondition,
  InactivateCondition,
  AddAndPublishCondition,
  UpdateConditionIndex,
} from './condition-manage.actions';
import { LoadingStart, LoadingEnd } from '../../../core/loading.state';

export const MANAGE_CONDITIONS_DEFAULT_DISPLAY_COLUMNS = Object.values(ConditionTableKey).map(
  (value: ConditionTableKey, index) => {
    return {
      field: value,
      name: ConditionTableKeyRecord[value],
      isSelected: index < 5,
      isFrozen: value === ConditionTableKey.NAME || value === ConditionTableKey.INDEX,
    };
  },
);

@State<ManageConditionsModel>({
  name: 'manageConditions',
  defaults: {
    loading: false,
    conditions: undefined,
  },
})
@Injectable()
export class ManageConditionsState {
  static NAME = 'manageConditions';

  constructor(
    private manageConditionsService: ManageConditionsService,
    private dialog: MatDialog,
    private zone: NgZone,
    private router: Router,
  ) {}

  static condition(conditionId: string) {
    return createSelector(
      [ManageConditionsState],
      (state: ManageConditionsModel): Condition | undefined => {
        return state.conditions?.[conditionId];
      },
    );
  }

  @Selector([ManageConditionsState.conditions])
  static conditionTypes(conditions: Condition[] | undefined): string[] {
    const types = conditions
      ?.map((condition: Condition) => condition.conditionType)
      .filter((type): type is string => !!type);

    return [...new Set(types)].sort();
  }

  @Selector()
  private static conditions(state: ManageConditionsModel): Condition[] | undefined {
    return state.conditions ? Object.values(state.conditions) : undefined;
  }

  @Selector([ManageConditionsState.conditions])
  static conditionsList(conditions: Condition[] | undefined): Condition[] | undefined {
    return conditions?.sort((a, b) => {
      if (a.index === b.index) {
        return 0;
      }

      if (a.index === null) {
        return 1;
      }

      if (b.index === null) {
        return -1;
      }

      return a.index < b.index ? -1 : 1;
    });
  }

  @Action(AddCondition)
  addCondition(ctx: StateContext<ManageConditionsModel>, action: AddCondition) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.manageConditionsService
      .createCondition({
        ...action.condition,
        id: undefined,
      })
      .pipe(
        tap((condition: Condition) => {
          const state = ctx.getState();

          ctx.patchState({
            conditions: {
              ...state.conditions,
              [condition.id]: condition,
            },
          });

          this.router.navigate(['/portal/manager/conditions'], {
            queryParams: { sidenav: 'condition', conditionId: condition.id },
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(DeleteCondition)
  deleteCondition(ctx: StateContext<ManageConditionsModel>, action: DeleteCondition) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.manageConditionsService.deleteCondition(action.conditionId).pipe(
      tap(() => {
        const state = ctx.getState();
        const conditions = { ...state.conditions };

        delete conditions[action.conditionId];

        ctx.patchState({
          conditions,
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(FetchConditions)
  fetchConditions(ctx: StateContext<ManageConditionsModel>) {
    ctx.patchState({
      conditions: undefined,
    });

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.manageConditionsService.getConditions().pipe(
      tap((conditions) => {
        const conditionEntities = conditions
          .sort((a, b) => {
            const nameOne = a.name ?? '';
            const nameTwo = b.name ?? '';
            return nameOne.localeCompare(nameTwo);
          })
          .reduce((entities: { [key: string]: Condition }, condition) => {
            entities[condition.id] = condition;

            return entities;
          }, {});

        ctx.patchState({
          conditions: conditionEntities,
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(UpdateCondition)
  updateCondition(ctx: StateContext<ManageConditionsModel>, action: UpdateCondition) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    if (!action.condition.id) {
      return EMPTY;
    }

    return this.manageConditionsService
      .updateCondition(action.condition.id, {
        ...action.condition,
        id: undefined,
        createdAt: undefined,
      })
      .pipe(
        tap((condition) => {
          const state = ctx.getState();

          const id = action.condition.id;

          if (!id) {
            return;
          }

          ctx.patchState({
            conditions: {
              ...state.conditions,
              [id]: condition,
            },
          });
        }),
        finalize(() => {
          ctx.dispatch(new LoadingEnd(this.constructor.name));
        }),
      );
  }

  @Action(UpdateConditionIndex)
  updateConditionIndex(ctx: StateContext<ManageConditionsModel>, action: UpdateConditionIndex) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.manageConditionsService.updateConditionIndex(action.conditionId, action.index).pipe(
      tap((condition) => {
        const state = ctx.getState();

        ctx.patchState({
          conditions: {
            ...state.conditions,
            [condition.id]: condition,
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(PublishCondition) publishCondition(
    ctx: StateContext<ManageConditionsModel>,
    action: PublishCondition,
  ) {
    const DIALOG_TITLE = $localize`Change Published State`;
    const DIALOG_MESSAGE = $localize`This condition cannot be deleted, \
    modified or reverted to draft once published. Proceed?`;

    return this.zone.run(() => {
      const df = this.dialog.open(DialogsComponent, {
        data: {
          title: DIALOG_TITLE,
          message: DIALOG_MESSAGE,
          buttonText: {
            yes: $localize`:@@action.publish:Publish`,
            no: $localize`:@@action.cancel:Cancel`,
          },
          declineModalClass: false,
        },
        minWidth: '400px',
        maxWidth: '400px',
      });

      return df.afterClosed().pipe(
        switchMap((result) => {
          if (!result) {
            ctx.dispatch(new LoadingEnd(this.constructor.name));
            return EMPTY;
          }
          ctx.dispatch(new LoadingStart(this.constructor.name));

          return this.manageConditionsService.publishCondition(action.conditionId).pipe(
            tap((condition) => {
              const state = ctx.getState();

              ctx.patchState({
                conditions: {
                  ...state.conditions,
                  [condition.id]: condition,
                },
              });
            }),
            finalize(() => {
              ctx.dispatch(new LoadingEnd(this.constructor.name));
            }),
          );
        }),
      );
    });
  }

  @Action(InactivateCondition) inactivateCondition(
    ctx: StateContext<ManageConditionsModel>,
    action: InactivateCondition,
  ) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.manageConditionsService.inactivateCondition(action.conditionId).pipe(
      tap((condition) => {
        const state = ctx.getState();

        ctx.patchState({
          conditions: {
            ...state.conditions,
            [condition.id]: condition,
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(AddAndPublishCondition) addAndPublishCondition(
    ctx: StateContext<ManageConditionsModel>,
    action: AddAndPublishCondition,
  ) {
    const DIALOG_TITLE = $localize`Create and publish condition`;
    const DIALOG_MESSAGE = $localize`This condition cannot be deleted, \
    modified or reverted to draft once published. Proceed?`;

    return this.zone.run(() => {
      const df = this.dialog.open(DialogsComponent, {
        data: {
          title: DIALOG_TITLE,
          message: DIALOG_MESSAGE,
          buttonText: {
            yes: $localize`:@@action.publish:Publish`,
            no: $localize`:@@action.cancel:Cancel`,
          },
          declineModalClass: false,
        },
        minWidth: '400px',
        maxWidth: '400px',
      });

      return df.afterClosed().pipe(
        switchMap((result) => {
          if (!result) {
            ctx.dispatch(new LoadingEnd(this.constructor.name));
            return EMPTY;
          }
          ctx.dispatch(new LoadingStart(this.constructor.name));

          return this.manageConditionsService
            .createCondition({
              ...action.condition,
              id: undefined,
            })
            .pipe(
              switchMap((condition) => {
                const state = ctx.getState();

                ctx.patchState({
                  conditions: {
                    ...state.conditions,
                    [condition.id]: condition,
                  },
                });

                this.router.navigate(['/portal/manager/conditions'], {
                  queryParams: { sidenav: 'condition', conditionId: condition.id },
                });

                return EMPTY;
              }),
              finalize(() => {
                ctx.dispatch(new LoadingEnd(this.constructor.name));
              }),
            );
        }),
      );
    });
  }

  @Action(ResetState)
  resetState(ctx: StateContext<ManageConditionsModel>) {
    ctx.setState({
      loading: false,
      conditions: {},
    });
  }
}
