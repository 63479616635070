import { MMSPaymentPeriod } from '../enums';

export interface MMSPayment {
  monthlyPaymentAmount?: string; // decimal(17,2)
  maximumLoanAmount?: string; // decimal(17,2)
  paymentPeriod?: MMSPaymentPeriod;
  paymentAmount?: string; // decimal(17,2)
  taxPortion?: string; // decimal(17,2)
  totalPayment?: string; // decimal(17,2)
  paymentDay?: string;
}
