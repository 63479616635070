<h1 i18n>Login</h1>
<form [formGroup]="authSignInForm" (ngSubmit)="submitSignInForm()">
  <fieldset class="default-field" [disabled]="isSubmitting">
    <fieldset class="default-field mb-20">
      <label class="mb-5" for="email" i18n>Email</label>
      <input name="email" formControlName="email" type="text" aria-label="Email" />
    </fieldset>
    <fieldset class="default-field mb-50">
      <label class="mb-5" for="password" i18n>Password</label>
      <input name="password" formControlName="password" type="password" aria-label="Password" />
    </fieldset>
  </fieldset>
  <fieldset class="default-field mb-50">
    <a
      [routerLink]="[]"
      (click)="handleForgotPassword()"
      [class.disabled]="isSubmitting ? true : null"
      i18n="@@auth.forgot_password"
      >Forgot Your Password?</a
    >
  </fieldset>

  <fieldset class="default-field justify-content-between d-flex">
    <button
      mat-flat-button
      color="primary"
      class="primary-btn"
      [disabled]="!authSignInForm.valid || isSubmitting"
      type="submit">
      <ng-container i18n>Login</ng-container>
      <mat-progress-bar
        class="loading"
        *ngIf="isSubmitting"
        mode="indeterminate"></mat-progress-bar>
    </button>

    <ng-container *ngIf="ssoEnabled">
      <button
        routerLink="/sso"
        mat-flat-button
        color="accent"
        type="submit"
        [queryParams]="{ autoRedirect: true }">
        <ng-container i18n>Login with SSO</ng-container>
      </button>
    </ng-container>
  </fieldset>
</form>
