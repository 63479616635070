export enum DMDocumentStatusEnum {
  NO_REQUEST = 'NO_REQUEST',
  PENDING = 'PENDING',
  UPLOADED = 'UPLOADED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  DRAFT = 'DRAFT',
  NOT_RELEVANT = 'NOT_RELEVANT',
  NOT_LINKED = 'NOT_LINKED',
  UNDER_REVIEW = 'UNDER_REVIEW',
}

export const EzidoxDMDocumentStatusEnumMap: Record<number, DMDocumentStatusEnum> = {
  1: DMDocumentStatusEnum.PENDING,
  2: DMDocumentStatusEnum.UPLOADED,
  3: DMDocumentStatusEnum.REJECTED,
  4: DMDocumentStatusEnum.ACCEPTED,
  5: DMDocumentStatusEnum.DRAFT,
  6: DMDocumentStatusEnum.NOT_RELEVANT,
  9: DMDocumentStatusEnum.UNDER_REVIEW,
};

export enum DMDocumentRejectionReasonEnum {
  INCORRECT_DOCUMENT = 'INCORRECT_DOCUMENT',
  DOCUMENT_TOO_OLD = 'DOCUMENT_TOO_OLD',
  MISSING_PAGES = 'MISSING_PAGES',
  LOW_QUALITY = 'LOW_QUALITY',
  OTHER = 'OTHER',
}

export const EzidoxDMDocumentRejectionReasonEnumMap: Record<number, DMDocumentRejectionReasonEnum> =
  {
    1: DMDocumentRejectionReasonEnum.INCORRECT_DOCUMENT,
    2: DMDocumentRejectionReasonEnum.DOCUMENT_TOO_OLD,
    3: DMDocumentRejectionReasonEnum.MISSING_PAGES,
    4: DMDocumentRejectionReasonEnum.LOW_QUALITY,
    5: DMDocumentRejectionReasonEnum.OTHER,
  };
