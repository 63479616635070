export enum OptaArchitecturalStyleType {
  DETACHED = 'Detached',
  END_ROW = 'End Row',
  IN_ROW = 'In Row',
  SEMI_DETACHED = 'Semi Detached',
  DUPLEX = 'Duplex',
  TRIPLEX = 'Triplex',
  FOURPLEX = 'Fourplex',
  FIVEPLEX = 'Fiveplex',
  SIXPLEX = 'Sixplex',
}
