<mat-dialog-content>
  <div class="text-center">
    <mat-icon class="mat-icon-lg mb-20">info</mat-icon>

    <h2 i18n *ngIf="data.isError; else warningHeader">Error Details</h2>

    <ng-template #warningHeader>
      <h2 i18n>Warning Details</h2>
    </ng-template>

    <p>{{ data.message }}</p>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button class="ml-10" mat-button (click)="onDismiss()">
    <ng-container>Close</ng-container>
  </button>
</mat-dialog-actions>
