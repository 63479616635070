import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { AuthComponent } from '../auth.component';
import { AuthService } from '../auth.service';
import { AuthResult, AuthResultData, AuthResultStatus } from '../model';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { environment } from '../../../environments/environment';

@Component({
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterLink,
    MatButtonModule,
    NgIf,
    MatProgressBarModule,
  ],
})
export class LoginComponent implements AuthComponent {
  @Input() data: AuthResultData;
  @Output() authResultEvent: EventEmitter<AuthResult> = new EventEmitter<AuthResult>();
  @Output() formSubmittingEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

  public ssoEnabled = !!environment.cognito.hostedUI.domain;
  isSubmitting = false;
  authSignInForm: UntypedFormGroup;
  returnUrl: string | undefined;

  constructor(
    private fb: UntypedFormBuilder,
    private authApiService: AuthService,
    private route: ActivatedRoute,
  ) {
    this.authSignInForm = this.fb.group({
      email: ['', Validators.required],
      password: ['', Validators.required],
    });
    this.returnUrl = this.route.snapshot.queryParams.returnUrl;
  }
  handleForgotPassword() {
    this.authResultEvent.next(new AuthResult(AuthResultStatus.FORGOT_PASSWORD));
  }
  submitSignInForm() {
    this.isSubmitting = true;
    this.formSubmittingEvent.next(true);
    const credentials = this.authSignInForm.value;
    this.authApiService.authenticate(credentials.email, credentials.password).subscribe(
      (res) => {
        this.formSubmittingEvent.next(false);
        res.returnUrl = this.returnUrl;
        this.authResultEvent.next(res);
      },
      (err) => {
        this.isSubmitting = false;
        this.formSubmittingEvent.next(false);
        this.authResultEvent.next(
          new AuthResult(AuthResultStatus.ERROR, Object.assign(err, { email: credentials.email })),
        );
      },
    );
  }
}
