<div class="text-pre-wrap" #textElement [class.collapsed]="collapsed">
  <ng-content></ng-content>
</div>

<div *ngIf="collapsible" class="text-center">
  <button mat-button (click)="toggleReadMore($event)">
    <span class="d-flex align-items-center">
      <ng-container *ngIf="collapsed">
        <span i18n>View More</span>
        <mat-icon>keyboard_arrow_down</mat-icon>
      </ng-container>

      <ng-container *ngIf="!collapsed">
        <span i18n>View Less</span>
        <mat-icon>keyboard_arrow_up</mat-icon>
      </ng-container>
    </span>
  </button>
</div>
