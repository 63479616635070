import { Injectable } from '@angular/core';
import { Action, createSelector, State, StateContext } from '@ngxs/store';
import { UpsertDocumentButton } from './document-buttons.actions';

export interface DocumentButtonsModel {
  [key: string]: { ttl: number };
}

@State({
  name: 'documentButtons',
  defaults: {},
})
@Injectable()
export class DocumentButtonsState {
  // 1000 ms * 60 = 1 minute * y
  static DEFAULT_TTL = 1000 * 60 * 2;
  static ATTACH_DOCUMENTS = 'AttachDocuments';
  static SEND_DOCUMENTS = 'SendDocuments';

  static isValid(document: string) {
    return createSelector([DocumentButtonsState], (state: DocumentButtonsModel) => {
      const getDocument = state[document];
      const isExpired = new Date().getTime() > (getDocument?.ttl ?? 0);
      return isExpired;
    });
  }

  @Action(UpsertDocumentButton) upsertDocumentButton(
    ctx: StateContext<UpsertDocumentButton>,
    { document }: UpsertDocumentButton,
  ) {
    const state = ctx.getState();
    ctx.patchState({
      ...state,
      [document]: { ttl: new Date().getTime() + DocumentButtonsState.DEFAULT_TTL },
    });
  }
}
