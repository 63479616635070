/* eslint-disable max-classes-per-file */

import { FundmoreRecommendationType, FundmoreResultType } from '@fundmoreai/models';

export class ScoreConfig {
  name: string;
  octopuses: Octopus[];
}

export class RocketExtraConfig {
  ruralCodes: string[];
  approvedPostalCodesPrimary: string[];
  approvedPostalCodesSecondary: string[];
}

export class Octopus {
  name: string;
  type?: string;
  tresholds?: Treshold[];
  activateFn?: string;
  children?: Octopus[];
}

export class Treshold {
  activateFn: string;
  tresholdFn: string;
  exceptionFn?: string;
  unknownFn?: string;
  narratives: Narrative[];
  recommendations?: Recommendation[];
}

export class Narrative {
  case: FundmoreResultType;
  value: string;
}

export class Recommendation {
  activateFn: string;
  tresholdFn?: string;
  value: string;
  type: FundmoreRecommendationType;
  changeToNumber?: number;
  changeToDate?: Date;
}
