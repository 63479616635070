import { Injectable } from '@angular/core';
import { Action, createSelector, Selector, State, StateContext } from '@ngxs/store';
import { EMPTY, finalize, tap } from 'rxjs';
import {
  AddApprovalType,
  DeleteApprovalType,
  FetchApprovalTypes,
  UpdateApprovalType,
} from './approval-type.actions';
import { ApprovalType } from './approval-type.model';
import { ApprovalTypeService } from './approval-type.service';
import { LoadingStart, LoadingEnd } from '../../../core/loading.state';

interface ApprovalTypeStateModel {
  approvalTypes?: {
    [key: string]: ApprovalType;
  };
}

@State<ApprovalTypeStateModel>({
  name: 'approvalType',
  defaults: {
    approvalTypes: undefined,
  },
})
@Injectable()
export class ApprovalTypeState {
  @Selector()
  private static approvalTypes(state: ApprovalTypeStateModel): ApprovalType[] | undefined {
    return state.approvalTypes ? Object.values(state.approvalTypes) : undefined;
  }

  @Selector([ApprovalTypeState.approvalTypes])
  static approvalTypesList(approvalTypes: ApprovalType[] | undefined) {
    return approvalTypes;
  }

  @Selector([ApprovalTypeState.approvalTypes])
  static activeApprovalTypes(approvalTypes: ApprovalType[] | undefined) {
    return approvalTypes?.filter((at) => at.isActive);
  }

  static approvalType(approvalTypeId: string) {
    return createSelector(
      [ApprovalTypeState],
      (state: ApprovalTypeStateModel): ApprovalType | undefined => {
        return state.approvalTypes?.[approvalTypeId];
      },
    );
  }

  constructor(private approvalTypeService: ApprovalTypeService) {}

  @Action(AddApprovalType)
  addApprovalType(ctx: StateContext<ApprovalTypeStateModel>, action: AddApprovalType) {
    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.approvalTypeService.add(action.approvalType).pipe(
      tap((approvalType) => {
        ctx.patchState({
          approvalTypes: {
            ...state.approvalTypes,
            [approvalType.id]: approvalType,
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(DeleteApprovalType)
  deleteApprovalType(ctx: StateContext<ApprovalTypeStateModel>, action: DeleteApprovalType) {
    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.approvalTypeService.delete(action.approvalTypeId).pipe(
      tap(() => {
        const state = ctx.getState();
        const approvalTypes = { ...state.approvalTypes };

        delete approvalTypes[action.approvalTypeId];

        ctx.patchState({
          approvalTypes,
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(FetchApprovalTypes)
  fetchApprovalTypes(ctx: StateContext<ApprovalTypeStateModel>) {
    ctx.patchState({
      approvalTypes: undefined,
    });

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.approvalTypeService.getAll().pipe(
      tap((approvalTypes: ApprovalType[]) => {
        const approvalTypeEntities = approvalTypes.reduce(
          (entities: { [key: string]: ApprovalType }, approvalType) => {
            entities[approvalType.id] = approvalType;

            return entities;
          },
          {},
        );

        ctx.patchState({ approvalTypes: approvalTypeEntities });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }

  @Action(UpdateApprovalType)
  updateApprovalType(ctx: StateContext<ApprovalTypeStateModel>, action: UpdateApprovalType) {
    if (!action.approvalType.id) {
      return EMPTY;
    }

    const state = ctx.getState();

    ctx.dispatch(new LoadingStart(this.constructor.name));

    return this.approvalTypeService.update(action.approvalType).pipe(
      tap((approvalType) => {
        ctx.patchState({
          approvalTypes: {
            ...state.approvalTypes,
            [approvalType.id]: approvalType,
          },
        });
      }),
      finalize(() => {
        ctx.dispatch(new LoadingEnd(this.constructor.name));
      }),
    );
  }
}
